import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import moment from 'moment';

import {ProFormText, QueryFilter, ProFormDateRangePicker, ProFormSelect} from '@ant-design/pro-form';
import {Card, Typography} from 'antd';

import {handleErrorFetch, localeCurrencyFormat} from 'utils/utils';
import {getOutletData} from 'utils/request/outlet';
import {OUTLET_TYPE, USER_ROLES} from 'utils/constants';

const MetricFilter = forwardRef(({tableFormRef}, ref) => {
	const auth = useSelector(state => state.user);
	const queryRef = useRef();

	const [brandList, setBrandList] = useState([]);
	const [locationList, setLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

	const [metricData, setMetricData] = useState({
		transactionCount: 0,
		transactionAmount: 0,
		totalTax: 0,
	});

	const metricCards = [
		{label: 'Number of Transaction', value: localeCurrencyFormat({value: metricData.transactionCount, includeCurrency: false})},
		{label: 'Amount of Transaction', value: localeCurrencyFormat({value: metricData.transactionAmount, withDecimal: true})},
		{label: 'Total Tax', value: localeCurrencyFormat({value: metricData.totalTax, withDecimal: true})},
	];

	// Methods
	const refetchData = () => {
		tableFormRef.current?.reload?.();
	};
	const resetForm = () => {
		queryRef?.current?.resetFields?.();
		tableFormRef.current?.reloadAndRest();
	};
	const getFilterPayload = () => {
		return queryRef.current?.getFieldsValue?.();
	};

	const getDefaultDate = () => {
		const today = moment();
		const yesterday = moment(today).subtract(1, 'day');
		return [yesterday.startOf('day'), yesterday.endOf('day')];
	};

	const getLocation = async outletLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	const fetchBrand = async () => {
		try {
			const response = await getOutletData({
				search: {limit: 0},
			}, OUTLET_TYPE.BRAND);
			if (response.success) {
				setBrandList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)).map(brand => ({
					label: brand.label,
					value: brand.label,
				})));
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useImperativeHandle(ref, () => ({
		getMetricFilter() {
			return getFilterPayload();
		},
		updateMetricData(data) {
			setMetricData({
				transactionCount: data.count,
				transactionAmount: data.amount || 0,
				totalTax: data.tax || 0,
			});
		},
	}));

	useEffect(() => {
		fetchBrand();
	}, []);

	return (
		<div>
			<QueryFilter
				formRef={queryRef}
				className="p-6 pb-0 my-4 border border-antd-netural-4 bg-antd-netural-2 rounded-md"
				layout="vertical"
				onFinish={() => refetchData()}
				onReset={() => resetForm()}
				initialValues={{
					sales_date: getDefaultDate(),
				}}
			>
				<ProFormDateRangePicker
					name="sales_date"
					label="Sales Date"
				/>
				{
					[USER_ROLES.SUPERADMIN, USER_ROLES.FINANCE.EDITOR].some(role => auth.roles.includes(role))
						? (
							<ProFormText
								name="sales_number"
								label="Sales Number"
							/>
						)
						: null
				}
				{
					[USER_ROLES.SUPERADMIN, USER_ROLES.FINANCE.EDITOR].some(role => auth.roles.includes(role))
						? (
							<ProFormText
								name="bill_number"
								label="Bill Number"
							/>
						)
						: null
				}
				<ProFormText
					name="external_long_id"
					label="Long ID"
				/>
				<ProFormText
					name="external_short_id"
					label="Short ID"
				/>
				<ProFormSelect
					mode='multiple'
					name="branch"
					label="Branch"
					showSearch
					fieldProps={{
						onSearch: debounceFn,
						loading: locationLoading,
						showArrow: true,
					}}
					options={locationList.map(location => location.label)}
				/>
				<ProFormSelect
					showSearch
					name="brand"
					label="Brand"
					mode='multiple'
					fieldProps={{showArrow: true}}
					options={brandList}
				/>
				<ProFormSelect
					mode='multiple'
					name="platform"
					label="Platform"
					fieldProps={{showArrow: true}}
					options={['Grab', 'Gojek', 'Shopee'].map(platform => {
						return {
							label: platform,
							value: platform,
						};
					})}
				/>
			</QueryFilter>
			<div>
				<div className='flex gap-6'>
					{metricCards.map(({label, value}) => (
						<div
							className='flex-1'
							key={label}>
							<Card className="metricCard rounded-md">
								<Typography.Text
									className='text-sm'
									type="secondary">
									{label}
								</Typography.Text>
								<div className="h-2" />
								<Typography.Text className='text-2xl'>
									{value}
								</Typography.Text>
							</Card>
						</div>
					))}
				</div>
			</div>
		</div>
	);
});

MetricFilter.defaultProps = {
	tableFormRef: {},
};

MetricFilter.propTypes = {
	tableFormRef: PropTypes.object,
};

export default MetricFilter;