import React, {forwardRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import {Image, Select} from 'antd';
import ProTable from '@ant-design/pro-table';

import {ORDER_MERCHANT_STATUS_CONST} from 'utils/constants';
import {getPlatformImage, handleErrorFetch, merchantOrderStatusHandler, moneyFormat} from 'utils/utils';
import {getOrderHistory} from 'utils/request/watchtower';

import localization from 'localization';
import OrderDetailModal from 'pages/WatchTower/WatchTowerOrder/OrderDetailModal';
const locale = localization.Outlet.Training.Session.OrderHistory;

const MANUAL_FLAGS = [{label: 'POS', value: 'POS'}, {label: 'Manual', value: 'manual'}, {label: 'Robot', value: 'robot'}];
const initialOrderDetailState = {visible: false, data: {}};

const OrderHistory = forwardRef(({platformList, sessionData}, ref) => {
	const {trainingId} = useParams();

	const [orderDetailModal, setOrderDetailModal] = useState(initialOrderDetailState);

	const getDefaultDate = () => {
		const dateFrom = sessionData?.createdAt ? moment(sessionData?.createdAt) : moment();
		const dateTo = sessionData?.completedAt ? moment(sessionData?.completedAt) : moment();
		return [dateFrom.startOf('day'), dateTo.endOf('day')];
	};

	const columns = [
		{
			width: 170,
			title: locale.orderTime,
			key: 'createdAt',
			dataIndex: 'createdAt',
			render: (_, {createdAt}) => moment(createdAt).format('DD MMM YYYY - HH:mm'),
			defaultSortOrder: 'descend',
			valueType: 'dateRange',
			sorter: true,
			initialValue: getDefaultDate(),
		},
		{
			width: 170,
			title: locale.orderId,
			key: 'externalAltId',
			dataIndex: 'externalAltId',
		},
		{
			width: 170,
			title: locale.salesChannel,
			key: 'platformId',
			dataIndex: ['Merchant', 'Platform', 'label'],
			search: false,
			filters: platformList.map(platform => ({
				text: platform.label,
				value: platform.id,
			})),
			render: salesChannel => {
				const platformImage = getPlatformImage(salesChannel);
				return (
					<div className="flex gap-2">
						<Image
							width={22}
							height={22}
							src={platformImage?.default}
							preview={false}
						/>
						{salesChannel}
					</div>
				);
			},
		},
		{
			width: 170,
			key: 'type',
			title: locale.orderType,
			dataIndex: 'type',
			render: type => <span className='capitalize'>{type}</span>,
			search: false,
			filters: [
				{
					text: 'Delivery',
					value: 'delivery',
				},
				{
					text: 'Takeaway',
					value: 'takeaway',
				},
			],
		},
		{
			width: 170,
			key: 'status',
			title: locale.orderStatus,
			render: (_, {status, type, pickUpReadyAt}) => (
				<span>
					{merchantOrderStatusHandler({status, type, pickUpReadyAt})}
				</span>
			),
			search: false,
			filters: Object.values(ORDER_MERCHANT_STATUS_CONST).map(merchantStatus => {
				return {
					text: merchantStatus.label,
					value: merchantStatus.value,
				};
			}),
		},
		{
			width: 170,
			key: 'processedBy',
			title: locale.flag,
			dataIndex: 'processedBy',
			render: processedBy => processedBy ? <span className='capitalize'>{processedBy}</span> : '-',
			renderFormItem: () => (
				<Select
					mode="multiple"
					allowClear
					placeholder="Please select"
				>
					{MANUAL_FLAGS.map(({label, value}) => (
						<Select.Option
							key={value}
							value={value}>{label}</Select.Option>
					))}
				</Select>
			),
		},
		{
			width: 170,
			key: 'brand',
			title: locale.brand,
			render: ({Merchant}) => Merchant.Brand.label,
			search: false,
		},
		{
			width: 170,
			key: 'totalAmount',
			title: locale.totalAmount,
			render: (_, record) => moneyFormat({value: record?.grandTotal}),
			search: false,
		},
		{
			width: 170,
			key: 'action',
			title: locale.action,
			align: 'center',
			fixed: 'right',
			search: false,
			render: (_, record) => (
				<a
					className='text-antd-blue-6'
					onClick={() => setOrderDetailModal({visible: true, data: record})}
				>
					{locale.detail}
				</a>
			),
		},
	];

	const sortDirectionConverter = direction => {
		switch (direction) {
		case 'ascend':
			return 'asc';
		case 'descend':
			return 'desc';
		default:
			return 'desc';
		}
	};

	const fetchTableData = async (params, sorter, filters) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			if (!params?.processedBy?.length) delete params?.processedBy;
			const payload = {
				sortBy: 'createdAt',
				sortOrder: sortDirectionConverter(sorter.createdAt),
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				trainingId,
				createdAtFrom: params?.createdAt?.[0],
				createdAtTo: params?.createdAt?.[1],
				externalAltId: params?.externalAltId,
				processedBy: params?.processedBy || null,
				...filters,
			};

			const response = await getOrderHistory(payload);

			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<>
			<OrderDetailModal
				withoutModal={false}
				visible={orderDetailModal.visible}
				data={orderDetailModal.data}
				onClose={() => setOrderDetailModal(initialOrderDetailState)}
			/>
			<ProTable
				actionRef={ref}
				className='TrainingSessionList'
				rowKey='id'
				columns={columns}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</>
	);
});

OrderHistory.defaultProps = {
	platformList: [],
	sessionData: {},
};

OrderHistory.propTypes = {
	platformList: PropTypes.array,
	sessionData: PropTypes.object,
};

export default OrderHistory;