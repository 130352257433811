import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';

import SalesNumberCheckerModal from './SalesNumberCheckerModal';

import {Card, Typography} from 'antd';

import {localeCurrencyFormat} from 'utils/utils';


const Metric = forwardRef((_, ref) => {

	const [salesNumberCheckerVisible, setSalesNumberCheckerVisible] = useState(false);
	const [metricData, setMetricData] = useState({
		external_id_count: 0,
		duplicate_sales_number_count: 0,
		duplicate_sales_number_data: [],
	});

	useImperativeHandle(ref, () => ({
		updateMetricData(data) {
			setMetricData(data);
		},
	}));

	return (
		<div className='px-6'>
			<SalesNumberCheckerModal
				visible={salesNumberCheckerVisible}
				close={() => setSalesNumberCheckerVisible(false)}
				dataSource={metricData.duplicate_sales_number_data}
			/>
			<div className='flex gap-6'>
				<div className='flex-1'>
					<Card className="rounded-md">
						<Typography.Text
							className='text-sm'
							type="secondary">
							External ID Count
						</Typography.Text>
						<div className="h-2" />
						<Typography.Text className='text-2xl'>
							{localeCurrencyFormat({value: metricData.external_id_count, includeCurrency: false})}
						</Typography.Text>
					</Card>
				</div>
				<div className='flex-1'>
					<Card className="rounded-md">
						<Typography.Text
							className='text-sm'
							type="secondary">
							Duplicate Sales Number
						</Typography.Text>
						<div className="h-2" />
						<div className='flex justify-between items-center'>
							<Typography.Text className='text-2xl'>
								{localeCurrencyFormat({value: metricData.duplicate_sales_number_count, includeCurrency: false})}
							</Typography.Text>
							<div
								onClick={() => setSalesNumberCheckerVisible(true)}
								className='text-antd-blue-6 cursor-pointer'>Lihat Detail</div>
						</div>
					</Card>
				</div>
			</div>
		</div>
	);
});

Metric.defaultProps = {
	tableFormRef: {},
};

Metric.propTypes = {
	tableFormRef: PropTypes.object,
};

export default Metric;