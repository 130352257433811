import React from 'react';
import PropTypes from 'prop-types';

import {DatePicker, Tag} from 'antd';
import moment from 'moment';

const ScheduleTimeField = ({
	scheduleTimeFieldRef,
	showRequiredField,
	requestDetails,
	FORM_KEY,
	isDetail,
	selectedTimezone,
	setSelectedTimezone,
	handleOnChangeRequestDetail,
	locale,
	TIMEZONE,
}) => {
	const displayTimezone = TIMEZONE.find(tz => tz.key == selectedTimezone);

	return (
		<div
			ref={scheduleTimeFieldRef}
			className='flex flex-col gap-2'>
			<DatePicker
				status={(showRequiredField && !requestDetails[FORM_KEY.SCHEDULE_TIME]) && 'error'}
				showTime
				disabled={isDetail}
				disabledDate={current => {
					// Disable dates that have already passed
					return current && current < moment().startOf('day');
				}}
				renderExtraFooter={() => (
					<div className='flex gap-1 py-2'>
						{
							TIMEZONE.map((tzTag => (
								<Tag
									className='cursor-pointer'
									color={selectedTimezone == tzTag.key && 'processing'}
									onClick={e => {
										e.stopPropagation();
										setSelectedTimezone(tzTag.key);
									}}
									key={tzTag.key}>{tzTag.label}</Tag>
							)))
						}
					</div>
				)}
				disabledTime={current => {
					// Disable times that have already passed for today's date
					if (current && current.isSame(new Date(), 'day')) {
						const currentHour = new Date().getHours();
						const currentMinute = new Date().getMinutes();
						return {
							disabledHours: () => [...Array(currentHour).keys()],
							disabledMinutes: () => current.isSame(new Date(), 'hour')
								? [...Array(currentMinute + 10).keys()]
								: null,
							disabledSeconds: () => [],
						};
					}
					return {};
				}}
				value={
					moment.isMoment(requestDetails[FORM_KEY.SCHEDULE_TIME])
						? moment(requestDetails[FORM_KEY.SCHEDULE_TIME], 'YYYY MMM DD - HH:mm')
						: null
				}
				onChange={val => {
					if (moment.isMoment(val)) {
						handleOnChangeRequestDetail(
							FORM_KEY.SCHEDULE_TIME,
							val,
						);
					} else {
						handleOnChangeRequestDetail(FORM_KEY.SCHEDULE_TIME, null);
					}
				}}
				format={value => {
					// Format the date with timezone for display, without affecting stored data
					return `${value.format('DD MMM YYYY - HH:mm')}${displayTimezone?.label ? ` ${displayTimezone?.label}` : ''}`;
				}}
				placeholder={locale.scheduleAtPlaceholder}
			/>
			{(showRequiredField && !requestDetails[FORM_KEY.SCHEDULE_TIME]) && <span className='text-antd-red-6'>{locale.scheduleAtRequired}</span>}
		</div>
	);
};

ScheduleTimeField.defaultProps = {
	scheduleTimeFieldRef: null,
	showRequiredField: false,
	requestDetails: {},
	FORM_KEY: {},
	isDetail: false,
	selectedTimezone: null,
	setSelectedTimezone: () => null,
	handleOnChangeRequestDetail: () => null,
	locale: {},
	TIMEZONE: [],
};

ScheduleTimeField.propTypes = {
	scheduleTimeFieldRef: PropTypes.object,
	showRequiredField: PropTypes.bool,
	requestDetails: PropTypes.object,
	FORM_KEY: PropTypes.object,
	isDetail: PropTypes.bool,
	selectedTimezone: PropTypes.number,
	setSelectedTimezone: PropTypes.func,
	handleOnChangeRequestDetail: PropTypes.func,
	locale: PropTypes.object,
	TIMEZONE: PropTypes.array,
};

export default ScheduleTimeField;