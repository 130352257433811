import React, {useEffect, useRef, useState} from 'react';

import {Button} from 'antd';
import {ExportOutlined} from '@ant-design/icons';

import LocalPageHeader from 'components/LocalPageHeader';
import Metric from './Metric';
import FrestoTable from './FrestoTable';

import OrderDetailModal from 'pages/WatchTower/WatchTowerOrder/OrderDetailModal';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';

import {exportToTsvFn, handleErrorFetch, salesPayloadHandler} from 'utils/utils';
import {getTransaction} from 'utils/request/sales';

const initialOrderDetailState = {
	visible: false,
	data: {},
};

const FrestoxESB = () => {
	const metricFormRef = useRef();
	const tableFormRef = useRef();

	const [loadingDownload, setLoadingDownload] = useState(false);
	const [orderDetailModal, setOrderDetailModal] = useState(initialOrderDetailState);
	const [exportFileModalVisible, setExportFileModalVisible] = useState(false);

	const viewOrderDetail = payload => {
		setOrderDetailModal({
			visible: true,
			data: payload,
		});
	};

	const handleExport = async ({fileName, type = 'export'}) => {
		try {
			setLoadingDownload(true);
			const metricPayload = salesPayloadHandler(metricFormRef.current?.getMetricFilter?.());

			await exportToTsvFn({
				fileName,
				actionType: type,
				exportCall: () => getTransaction({
					params: metricPayload,
					isExport: true,
				}),
			});
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingDownload(false);
		}
	};

	const openExportFileModal = () => {
		setExportFileModalVisible(true);
	};

	const closeExportFileModal = () => {
		setExportFileModalVisible(false);
	};

	useEffect(() => {
	}, [tableFormRef]);

	return (
		<>
			<OrderDetailModal
				isFromSales
				withoutModal={false}
				visible={orderDetailModal.visible}
				data={orderDetailModal.data}
				onClose={() => setOrderDetailModal(initialOrderDetailState)}
			/>
			<ExportCSVOverlay
				loading={loadingDownload}
				providedFileName={`frest-x-pos-${new Date().valueOf()}.csv`}
				fileFormat='csv'
				handleExport={handleExport}
				visible={exportFileModalVisible}
				close={closeExportFileModal}
				type={'export'}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					headerTitle={'Fresto x POS'}
					customBreadcrumbLabel='Sales Dashboard - Fresto x POS'
					extra={[
						<Button
							onClick={openExportFileModal}
							icon={<ExportOutlined />}
							type='primary'
							key='export'>Export</Button>,
					]}
				/>
				<div className='px-6'>
					<Metric
						ref={metricFormRef}
						tableFormRef={tableFormRef}
					/>
					<FrestoTable
						ref={tableFormRef}
						metricFormRef={metricFormRef}
						viewOrderDetail={viewOrderDetail}
					/>
				</div>
			</div>
		</>
	);
};

export default FrestoxESB;