import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

// Payment
export const getPosPayment = async ({params, isExport}) => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/payment-methods${isExport ? '/export' : ''}`,
			params,
		});
		if (isExport) {
			if (data.success) {
				const blobResponse = await (await fetch(data.url)).blob();
				return blobResponse;
			}
		} else {
			return data;
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPaymentType = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/payment-methods/types`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPaymentGroup = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/payment-methods/groups`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createPosPayment = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/pos/payment-methods`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editPosPayment = async payload => {
	try {
		const {id, ...rest} = payload;
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/pos/payment-methods/${id}`,
			data: {...rest},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPosPaymentDetail = async paymentId => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/payment-methods/${paymentId}`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

// Sales Channel
export const getSalesChannel = async ({params, isExport = false}) => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/sales-channels${isExport ? '/export' : ''}`,
			params,
		});
		if (isExport) {
			if (data.success) {
				const blobResponse = await (await fetch(data.url)).blob();
				return blobResponse;
			}
		} else {
			return data;
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createSalesChannel = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/pos/sales-channels`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editSalesChannel = async payload => {
	try {
		const {id, ...rest} = payload;
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/pos/sales-channels/${id}`,
			data: {...rest},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getSalesChannelDetail = async paymentId => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/sales-channels/${paymentId}`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};