import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import {useSelector} from 'react-redux';

import {Badge, Image, Popconfirm, Table, notification} from 'antd';

import {authChecker, getPlatformImage, handleErrorFetch} from 'utils/utils';
import {updateTrainingOrder} from 'utils/request/outlet';
import {USER_ROLES} from 'utils/constants';

import localization from 'localization';
const locale = localization.Outlet.Training.Session.OrderTemplate;

const OrderTemplate = ({sessionData, template, refetchData, platformList}) => {
	const auth = useSelector(state => state.user);

	const orderActionHandler = async (actionKey, templateId) => {
		try {
			let status = null;

			switch (actionKey) {
			case 'StartOrder':
			case 'RestartOrder':
				status = 'processing';
				break;
			case 'CompleteOrder':
				status = 'completed';
				break;
			default:
				status = null;
			}

			const response = await updateTrainingOrder({
				id: templateId,
				status,
			});

			if (response.success && status) {
				refetchData();
				notification.success({
					message: locale[actionKey].successTitle,
					description: locale[actionKey].successMessage,
				});
			}

		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const statusRenderer = status => {
		let statusIndicator = 'default';

		switch (status) {
		case 'pending':
			statusIndicator = 'default';
			break;
		case 'completed':
			statusIndicator = 'success';
			break;
		case 'processing':
			statusIndicator = 'processing';
			break;
		case 'failed':
			statusIndicator = 'error';
			break;
		default:
			statusIndicator = 'default';
			break;
		}

		return (
			<span><Badge status={statusIndicator} />{capitalize(locale[status])}</span>
		);
	};

	const actionValidation = () => {
		// Validate if user is eligible to view action
		// Only superadmin / creator are eligible to view action

		const actionCol = [{
			title: locale.action,
			key: 'action',
			fixed: 'right',
			align: 'center',
			render: (_, record) => actionRenderer(record),
		}];

		if (sessionData?.createdBy == auth.id || authChecker({auth, requiredRolesArr: [USER_ROLES.TRAINER.ADMIN], children: true})) {
			return actionCol;
		} else {
			return [{}];
		}
	};

	const actionRenderer = record => {
		let key = null;
		switch (record.status) {
		case 'pending':
			key = 'StartOrder';
			break;
		case 'completed':
			key = 'RestartOrder';
			break;
		case 'processing':
		case 'failed':
			key = 'CompleteOrder';
			break;
		default:
			break;
		}

		const textLocale = locale[key];

		return (
			<Popconfirm
				placement="bottomRight"
				title={textLocale.title}
				okText={textLocale.okText}
				onConfirm={() => orderActionHandler(key, record.id)}
				cancelText={textLocale.cancelText}
			>
				<span className='text-antd-blue-6 cursor-pointer'>{textLocale.label}</span>
			</Popconfirm>
		);
	};

	const columns = [
		{
			title: locale.salesChannel,
			key: 'salesChannel',
			dataIndex: ['Platform', 'label'],
			filters: platformList.map(platform => ({
				text: platform.label,
				value: platform.id,
			})),
			onFilter: (value, record) => value === record?.platformId,
			render: salesChannel => {
				const platformImage = getPlatformImage(salesChannel);
				return (
					<div className="flex gap-2">
						<Image
							width={22}
							height={22}
							src={platformImage?.default}
							preview={false}
						/>
						{salesChannel}
					</div>
				);
			},
		},
		{
			title: locale.orderType,
			key: 'orderType',
			dataIndex: 'orderType',
			render: orderType => capitalize(orderType),
			filters: [
				{
					text: 'Delivery',
					value: 'delivery',
				},
				{
					text: 'Takeaway',
					value: 'takeaway',
				},
			],
			onFilter: (value, record) => value === record?.orderType,
		},
		{
			title: locale.injectionType,
			key: 'isAuto',
			dataIndex: 'isAuto',
			render: isAuto => isAuto ? locale.autoInjection : locale.manualInjection,
			filters: [
				{
					text: 'Auto Process',
					value: true,
				},
				{
					text: 'Manual',
					value: false,
				},
			],
			onFilter: (value, record) => value === record?.isAuto,
		},
		{
			title: locale.status,
			key: 'status',
			dataIndex: 'status',
			render: status => statusRenderer(status),
			filters: ['pending', 'processing', 'completed', 'failed'].map(statusKey => {
				return {
					text: locale[statusKey],
					value: statusKey,
				};
			}),
			onFilter: (value, record) => value === record?.status,
		},
		...(sessionData?.isCompleted
			? [{}]
			: actionValidation()
		),
	].filter(value => Object.keys(value).length !== 0);

	return (
		<Table
			className='pb-6'
			rowKey='id'
			id='material-parent-mapping-table'
			dataSource={template}
			pagination={false}
			columns={columns}
		/>
	);
};

OrderTemplate.defaultProps = {
	template: [],
	sessionData: {},
	refetchData: () => null,
	platformList: [],
};

OrderTemplate.propTypes = {
	template: PropTypes.array,
	sessionData: PropTypes.object,
	refetchData: PropTypes.func,
	platformList: PropTypes.array,
};

export default OrderTemplate;