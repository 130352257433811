import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const getUsers = async (query, type = 'resto') => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
			userType: type,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/user`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPosUsers = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/user`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPosRoles = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/auth/pos-roles`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createPosUser = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/pos/user`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editPosUser = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/pos/user`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

// Other user
export const updateUserDetails = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/user`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

// Self
export const updateUserProfile = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/user/update-profile`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const bulkAddRestoUser = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/user/resto/bulk`,
			data: {...payload, roles: ['resto_admin']},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const bulkResetRestoUserPin = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/user/resto/bulk/reset-pin`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const bulkResetAdminUserPassword = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/user/admin/bulk/reset-password`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getRolesList = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/auth/roles`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createNewUser = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/user`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};