import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Modal, Select} from 'antd';
import ManageCategory from 'pages/SalesChannel/MenuTemplateForm/MenuTemplateFormBody/ManageCategory';
import Context from 'pages/SalesChannel/MenuTemplateForm/MenuTemplateFormBody/Context';

const PromoReviewModal = ({visible, onClose, promoReviewData, menuTemplateLabel, providedLocation}) => {
	const [previewAs, setpreviewAs] = useState(null);

	return (
		<Modal
			visible={visible}
			title={menuTemplateLabel}
			footer={<Button onClick={onClose}>Close</Button>}
			onCancel={onClose}
			width={968}
			centered
			zIndex={3}
		>
			<Select
				allowClear
				showSearch
				value={previewAs}
				onChange={e => setpreviewAs(e)}
				placeholder='Select outlet to view'
				filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLocaleLowerCase())}
				className='w-96 mb-4'>
				{providedLocation?.map?.(location => (
					<Select.Option
						label={location.label}
						value={location.id}
						key={location.id}>{location.label}</Select.Option>
				))}
			</Select>
			<div className='overflow-auto'>
				<Context.Provider
					value={{
						locationList: providedLocation,
						previewAs,
						viewOnly: true,
					}}
				>
					<ManageCategory
						menuDraft={promoReviewData?.details}
						viewOnly
						promoView
					/>
				</Context.Provider>
			</div>
		</Modal>
	);
};

PromoReviewModal.defaultProps = {
	visible: false,
	onClose: () => null,
	promoReviewData: {},
	menuTemplateLabel: {},
	providedLocation: [],
};

PromoReviewModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	promoReviewData: PropTypes.object,
	menuTemplateLabel: PropTypes.object,
	providedLocation: PropTypes.array,
};

export default PromoReviewModal;