import {useState, forwardRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Modal, Typography, Dropdown, Button, Menu, notification} from 'antd';
import {DownOutlined} from '@ant-design/icons';

// Global utils
import localization from 'localization';

// Local styles
import './modalChangeReason.less';
import {handleErrorFetch} from 'utils/utils';
import {editInactiveReason} from 'utils/request/watchtower';

const ModalChangeReason = forwardRef(({fetchData, menuAvailabilityReason}, ref) => {
	const locale = localization.WatchTower.WatchTowerMenu.editReasonModal;
	const [isModalVisible, setModalVisibility] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedReason, changeReason] = useState(null);
	// Methods
	const handleEditReason = async () => {
		try {
			setLoading(true);

			const response = await editInactiveReason(ref.current.selectedId, selectedReason);
			if (response.success) {
				notification.success({
					message: locale.SuccessEdit.message,
					description: locale.SuccessEdit.description,
				});
				fetchData();
				ref.current.close();
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const setObjectRef = () => {
		const refObject = {
			open: requestId => {
				ref.current.selectedId = requestId;
				setModalVisibility(true);
			},
			close: () => {
				ref.current.selectedId = null;
				changeReason(null);
				setModalVisibility(false);
			},
		};
		if (ref && ref.hasOwnProperty('current')) {
			ref.current = refObject;
		} else if (typeof ref === 'function') {
			ref(refObject);
		}
	};
	// Hooks
	useEffect(() => setObjectRef(), []);
	// Render
	const renderMenu = (optionMenuArrayData, onClick) => {
		return (
			<Menu onClick={onClick}>
				{optionMenuArrayData.map(reason => (
					<Menu.Item
						key={reason}>{reason}</Menu.Item>
				))}
			</Menu>
		);
	};
	return (
		<Modal
			title={locale.title}
			visible={isModalVisible}
			okText={locale.confirmButton}
			className="ModalChangeReason"
			onOk={() => handleEditReason()}
			okButtonProps={{
				loading: loading,
				disabled: !selectedReason,
			}}
			onCancel={() => ref.current.close()}>
			<Typography.Text className="subtitle">{locale.subtitle}</Typography.Text>
			<Dropdown
				trigger="click"
				className="dropdown"
				placement="bottomCenter"
				overlay={renderMenu(menuAvailabilityReason, ({key}) => {
					changeReason(key);
				})}>
				<Button className="dropdownButton">
					{selectedReason ? selectedReason : 'Select Reason'}
					<DownOutlined />
				</Button>
			</Dropdown>
		</Modal>
	);
});

ModalChangeReason.defaultProps = {
	fetchData: () => null,
	menuAvailabilityReason: [],
};

ModalChangeReason.propTypes = {
	fetchData: PropTypes.func,
	menuAvailabilityReason: PropTypes.array,
};

export default ModalChangeReason;
