import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {USER_ROLES} from 'utils/constants';

// Reference: https://medium.com/trabe/flexible-authorization-component-in-react-using-functions-c1493bd7ab4
const ProtectedComponent = ({when, children, isRoute}) => {
	const auth = useSelector(state => state.user);
	return auth.roles.includes(USER_ROLES.SUPERADMIN) || when(auth)
		? children
		: isRoute
			? (
				<Navigate
					to={auth.loggedIn ? '/' : '/login'}
					replace />
			) : null;
};

ProtectedComponent.defaultProps = {
	when: () => null,
	isRoute: false,
};

ProtectedComponent.propTypes = {
	when: PropTypes.func,
	isRoute: PropTypes.bool,
};

export default ProtectedComponent;
