import React, {useCallback, useState} from 'react';
import debounce from 'lodash/debounce';

import LocalPageHeader from 'components/LocalPageHeader';

import {Button} from 'antd';

import {handleErrorFetch} from 'utils/utils';
import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';

import NewTrainingSessionModal from './NewTrainingSessionModal';
import Table from './Table';

import localization from 'localization';
const locale = localization.Outlet.Training.List;

const TrainingSessionList = () => {

	const [newSessionModalVisible, setNewSessionModalVisible] = useState(false);
	const [outletList, setOutletList] = useState([]);
	const [outletListLoading, setOutletlistLoading] = useState(false);

	const openModal = () => {
		setNewSessionModalVisible(true);
	};

	const closeModal = () => {
		setNewSessionModalVisible(false);
	};

	const getLocation = async outletLabel => {
		try {
			setOutletlistLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setOutletList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setOutletlistLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	return (
		<>
			<NewTrainingSessionModal
				debounceFn={debounceFn}
				outletList={outletList}
				outletListLoading={outletListLoading}
				visible={newSessionModalVisible}
				close={closeModal}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					headerTitle={locale.title}
					extra={[
						<Button
							onClick={openModal}
							type='primary'
							key='cta'>{locale.newTrainingCta}
						</Button>,
					]}
				/>
				<Table
					debounceFn={debounceFn}
					outletList={outletList}
					outletListLoading={outletListLoading}
				/>
			</div>
		</>
	);
};

export default TrainingSessionList;