import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import './PromoRequirement.less';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import clsx from 'clsx';

import ItemWrapper from '../ItemWrapper';

import {DeleteOutlined} from '@ant-design/icons';
import {
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Input,
	InputNumber,
	Menu,
	Radio,
	Row,
	Select,
	TimePicker,
	Transfer,
	Typography,
} from 'antd';

import {INTERNAL_PROMO_MENU_PICKER_MODAL_TYPE} from 'utils/constants';
import {handleErrorFetch, numberFormat, transferOutletFormatter} from 'utils/utils';
import {getBrandList, getOutletList, getPlatformList} from 'utils/request/global';

import localization from 'localization';
const locale = localization.Promo.CreateNewPromo.Internal.PromoRequirement;

const dayOptions = Object.values(localization.Global.DayOptions);

const activeDayConfig = {
	EVERY_DAY: {
		label: locale.everyDay,
	},
	SPECIFIC_DAY: {
		label: locale.specificDay,
	},
};

const activeHourConfig = {
	ALL_DAY: {
		label: locale.allDay,
	},
	SPECIFIC_TIME: {
		label: locale.specificTime,
	},
};

const quotaConfig = {
	NO_LIMIT: {
		label: locale.noLimit,
	},
	LIMITED: {
		label: locale.specific,
	},
};

const DAY_LABEL = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const PromoRequirement = ({openMenuPicker, rules, setRules, setFormFilled}) => {
	// State Section
	const location = useLocation();

	const viewOnly = location.state?.viewOnly;
	const rawCardData = location.state?.rawCardData;

	const [label, setLabel] = useState(null);
	const [outletLoading, setOutletLoading] = useState(false);
	const [outletList, setOutletList] = useState([]);
	const [merchantId, setMerchantId] = useState([]);
	const [selectedPromoPeriod, setSelectedPromoPeriod] = useState(null);

	const [platformList, setPlatformList] = useState([]);
	const [selectedPlatform, setSelectedPlatform] = useState([]);

	const [brandList, setBrandList] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState([]);

	const [activeDayType, setActiveDayType] = useState(null);
	const [activeDays, setActiveDays] = useState([]);

	const [activeHourType, setActiveHourType] = useState(null);
	const [activeHour, setActiveHour] = useState([]);

	const [quotaType, setQuotaType] = useState(null);
	const [quota, setQuota] = useState(null);
	// End of State Section

	// Fn Section
	const initialFetch = async () => {
		try {
			setOutletLoading(true);

			const platformResponse = await getPlatformList();
			if (platformResponse.success) {
				setPlatformList(platformResponse.data.rows);
				setSelectedPlatform(platformResponse.data.rows?.map?.(platform => {
					return platform.id;
				}));
			}

			const outletResponse = await getOutletList();
			if (outletResponse?.success) {
				const mappedResult = outletResponse.data.rows.map(e => ({
					...e,
					key: e.id,
				}));
				setOutletList(mappedResult);
			}

			const brandResponse = await getBrandList();
			if (brandResponse?.success) {
				setBrandList(brandResponse.data.rows);
				setSelectedBrand(brandResponse?.data?.rows?.map?.(brand => brand.id));
			}

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setOutletLoading(false);
		}
	};

	const skuSetterFn = (itemRecord, index) => {
		const tempDetails = cloneDeep(rules?.sku?.details);
		tempDetails[index] = {
			menuId: itemRecord.id,
			menuLabel: itemRecord.menuLabel,
			quantity: 1,
		};

		setRules({
			...rules,
			sku: {
				details: tempDetails,
			},
		});
	};

	const handleOpenMenuPicker = index => {
		openMenuPicker({
			type: INTERNAL_PROMO_MENU_PICKER_MODAL_TYPE.SKU,
			actionFn: record => skuSetterFn(record, index),
		});
	};

	const addAov = value => {
		setRules({
			...rules,
			aov: {
				minAmount: Number.isInteger(value) ? value : '',
			},
		});
	};

	const removeAov = () => {
		setRules({
			...rules,
			aov: {
				minAmount: null,
			},
		});
	};

	const addSku = () => {
		setRules({
			...rules,
			sku: {
				details: rules?.sku?.details.concat({
					menuId: null,
					menuLabel: null,
					quantity: 1,
				}),
			},
		});
	};

	const removeSku = index => {
		setRules({
			...rules,
			sku: {
				details: rules?.sku?.details?.filter?.((_, skuIndex) => skuIndex !== index),
			},
		});
	};

	const dayMapper = dayArray => {
		const output = {};

		dayArray.forEach(day => {
			output[DAY_LABEL[day]] = [
				{
					startTime: activeHourType === activeHourConfig.SPECIFIC_TIME.label ? activeHour[0] : '00:00',
					endTime: activeHourType === activeHourConfig.SPECIFIC_TIME.label ? activeHour[1] : '23:59',
				},
			];
		});

		return output;
	};

	const calculateLocationQty = () => {
		// Calculate numbers of unique location from selected merchants
		const selectedMerchantsTemp = outletList.filter(outlet => merchantId.includes(outlet.id)) || [];
		const uniqueLocation = [...new Set(selectedMerchantsTemp.map(outlet => outlet.locationId))];
		return uniqueLocation.length;
	};
	// End of Fn Section

	// Lifecycle Section
	useEffect(() => {
		initialFetch();
	}, []);

	useEffect(() => {
		if (!viewOnly) return;

		setLabel(rawCardData?.label);
		setMerchantId(rawCardData?.merchantId);
		setSelectedPromoPeriod({
			startDate: moment(rawCardData?.startDate),
			endDate: moment(rawCardData?.endDate),
		});

		const promoDetailsEntry = rawCardData?.details;

		const promoDays = Object.keys(promoDetailsEntry?.promoHours);
		setActiveDayType(promoDays?.length === 7 ? activeDayConfig.EVERY_DAY.label : activeDayConfig.SPECIFIC_DAY.label);

		const tempActiveDay = DAY_LABEL.map((availableDay, index) => {
			if (promoDays.includes(availableDay)) return index;
		}).filter(item => item !== undefined);
		setActiveDays(tempActiveDay);

		const promoTime = Object.values(promoDetailsEntry?.promoHours)?.[0]?.[0];
		setActiveHourType((promoTime?.startTime === '00:00' && promoTime?.endTime === '23:59') ? activeHourConfig.ALL_DAY.label : activeHourConfig.SPECIFIC_TIME.label);
		setActiveHour([
			moment(promoTime?.startTime, 'HH:mm'),
			moment(promoTime?.endTime, 'HH:mm'),
		]);

		setQuotaType(promoDetailsEntry?.quota === -1 ? quotaConfig.NO_LIMIT.label : quotaConfig.LIMITED.label);
		setQuota(promoDetailsEntry?.quota);

		setRules(promoDetailsEntry?.rules);

	}, [viewOnly]);

	useEffect(() => {
		if (viewOnly) return;	// Platform selection is not shown on review (view only) therefore the function below does not need to be executed

		// Remove merchant id from un checked platform
		const availableMerchant = outletList.filter(outlet => selectedPlatform.includes(outlet.platformId));
		setMerchantId(merchantId => merchantId.filter(merchantId => availableMerchant.some(availableMerchant => availableMerchant.id === merchantId)));
	}, [selectedPlatform]);

	useEffect(() => {
		if (viewOnly) return;	// Platform selection is not shown on review (view only) therefore the function below does not need to be executed

		// Remove merchant id from un checked platform
		const availableMerchant = outletList.filter(outlet => selectedBrand.includes(outlet.brandId));
		setMerchantId(merchantId => merchantId.filter(merchantId => availableMerchant.some(availableMerchant => availableMerchant.id === merchantId)));
	}, [selectedBrand]);

	useEffect(() => {
		let promoRequirementFilled = true;

		if (!label?.length) promoRequirementFilled = false;
		if (!merchantId?.length) promoRequirementFilled = false;
		if (selectedPromoPeriod === null || selectedPromoPeriod?.startDate === undefined) promoRequirementFilled = false;
		if (activeDayType === null || (activeDayType === activeDayConfig.SPECIFIC_DAY.label && !activeDays.length)) promoRequirementFilled = false;
		if (activeHourType === null || (activeHourType === activeHourConfig.SPECIFIC_TIME.label && activeHour[0] === undefined)) promoRequirementFilled = false;
		if (quotaType === null || (quotaType === quotaConfig.LIMITED.label && !quota)) promoRequirementFilled = false;

		const payloadTemp = promoRequirementFilled
			? {
				label,
				merchantId,
				quota: quotaType === quotaConfig.NO_LIMIT.label ? -1 : quota,
				startDate: selectedPromoPeriod.startDate,
				endDate: selectedPromoPeriod.endDate,
				promoHours: dayMapper(
					activeDayType === activeDayConfig.SPECIFIC_DAY.label
						? activeDays
						: [0, 1, 2, 3, 4, 5, 6],
				),
			}
			: {};

		setFormFilled(state => ({
			...state,
			promoRequirement: promoRequirementFilled,
			promoRequirementPayload: payloadTemp,
		}));
	}, [
		label, merchantId, selectedPromoPeriod,
		activeDayType, activeDays, activeHourType,
		activeHour, quotaType, quota,
	]);
	// End of Lifecycle Section

	return (
		<Card title={locale.title}>
			<div className='flex flex-col gap-6'>

				{/* Promo Label Section */}
				<ItemWrapper label={locale.promoLabel} >
					<Input
						disabled={viewOnly}
						onChange={e => setLabel(e.target.value)}
						value={label}
						placeholder={locale.promoLabelPlaceholder}
						style={{width: 400}}
					/>
				</ItemWrapper>
				{/* End of Promo Label Section */}

				{/* Outlet Platform Section */}
				{
					viewOnly ? null : (
						// Outlet platform selection is not shown on review page (view only)
						<div>
							<div className='pb-2'>{locale.outletPlatformLabel}</div>
							<Checkbox.Group
								onChange={checkedValue => setSelectedPlatform(checkedValue)}
								value={selectedPlatform}
								options={platformList.map(platform => {
									return {
										label: platform.label,
										value: platform.id,
									};
								})}
							/>
						</div>
					)
				}
				{/* End of Outlet Platform Section */}

				{/* Brand Section */}
				{
					viewOnly ? null : (
						// Brand selection is not shown on review page (view only)
						<div>
							<div className='pb-2'>{locale.outletBrandLabel}</div>
							<Checkbox.Group
								onChange={checkedValue => setSelectedBrand(checkedValue)}
								value={selectedBrand}
							>
								<Row gutter={[0, 8]}>
									{
										brandList.map(brand => (
											<Col
												key={brand.id}
												span={8}>
												<Checkbox value={brand.id}>{brand.label}</Checkbox>
											</Col>
										))
									}
								</Row>
							</Checkbox.Group>
						</div>
					)
				}
				{/* End of Brand Section */}

				{/* Outlet Selection Section */}
				<ItemWrapper
					label={locale.outletLabel}
				>
					<Transfer
						className='max-w-7xl'
						disabled={outletLoading || viewOnly}
						titles={[locale.allOutlet, locale.selectedOutlet]}
						listStyle={{flexGrow: '1', height: 350}}
						dataSource={
							outletList.filter(outlet => (selectedPlatform.includes(outlet.platformId) && selectedBrand.includes(outlet.brandId)))
						}
						render={item => transferOutletFormatter(item, true)}
						targetKeys={merchantId}
						onChange={(selectedKeys, direction) => {
							let tempArray = [...merchantId];

							if (direction === 'right') {
								selectedKeys.forEach(merchantId => {
									if (!tempArray.includes(merchantId)) {
										tempArray.push(merchantId);
									}
								});
							} else {
								tempArray = selectedKeys;
							}
							setMerchantId(tempArray);
						}}
						showSearch
						filterOption={(input, option) => {
							const labelFormat = transferOutletFormatter(option);
							return labelFormat.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
						}
						}
					/>
				</ItemWrapper>
				{/* End of Outlet Selection Section */}

				{/* Promo Period Section */}
				<ItemWrapper
					label={locale.promoPeriodLabel}
				>
					<DatePicker.RangePicker
						showTime
						disabled={viewOnly}
						placeholder={[locale.startDate, locale.endDate]}
						value={[selectedPromoPeriod?.startDate, selectedPromoPeriod?.endDate]}
						onChange={value => {
							setSelectedPromoPeriod({
								startDate: value?.[0]?.startOf('day'),
								endDate: value?.[1]?.endOf('day'),
							});
						}}
					/>
				</ItemWrapper>
				{/* End of Promo Period Section */}

				{/* Promo Active Day Section */}
				<ItemWrapper
					label={locale.activeDayLabel}
				>
					<div className='flex flex-col gap-3'>
						<Radio.Group
							disabled={viewOnly}
							value={activeDayType}
							onChange={e => setActiveDayType(e.target.value)}>
							{Object.values(activeDayConfig).map(activeDay => (
								<Radio
									key={activeDay.label}
									value={activeDay.label}>
									{activeDay.label}
								</Radio>
							))}
						</Radio.Group>
						{
							activeDayType === activeDayConfig.SPECIFIC_DAY.label && (
								<Checkbox.Group
									disabled={viewOnly}
									options={dayOptions.map((day, index) => {
										return {
											label: day,
											value: index,
										};
									})}
									value={activeDays}
									onChange={checkedValues => setActiveDays(checkedValues)}
								/>
							)
						}
					</div>
				</ItemWrapper>
				{/* End of Promo Active Day Section */}

				{/* Promo Active Hour Section */}
				<ItemWrapper
					label={locale.activeTimeLabel}
				>
					<div className='flex flex-col gap-3'>
						<Radio.Group
							disabled={viewOnly}
							value={activeHourType}
							onChange={e => setActiveHourType(e.target.value)}>
							{Object.values(activeHourConfig).map(activeHour => (
								<Radio
									key={activeHour.label}
									value={activeHour.label}>{activeHour.label}</Radio>
							))}
						</Radio.Group>
						{
							activeHourType === activeHourConfig.SPECIFIC_TIME.label && (
								<TimePicker.RangePicker
									disabled={viewOnly}
									className='max-w-sm'
									value={[activeHour?.[0], activeHour?.[1]]}
									onChange={value => setActiveHour([value?.[0], value?.[1]])}
									format={'HH:mm'}
								/>
							)
						}
					</div>
				</ItemWrapper>
				{/* End of Promo Active Hour Section */}

				{/* Promo Quota Section */}
				<ItemWrapper
					label={locale.quotaLabel}
				>
					<div className='flex flex-col gap-3'>
						<Radio.Group
							disabled={viewOnly}
							value={quotaType}
							onChange={e => setQuotaType(e.target.value)}
						>
							{Object.values(quotaConfig).map(quota => (
								<Radio
									key={quota.label}
									value={quota.label}>{quota.label}</Radio>
							))}
						</Radio.Group>
						{
							quotaType === quotaConfig.LIMITED.label && (
								<div>
									<InputNumber
										disabled={viewOnly}
										parser={value => value.replace(/[,.]/g, '')}
										formatter={value => {
											if (value) {
												return numberFormat(value);
											}
											return value;
										}}
										controls={false}
										placeholder={locale.specificPlaceholder}
										style={{width: 400}}
										value={quota}
										onChange={e => setQuota(e)}
									/>
									<Typography.Text
										className='block'
										type='secondary'>
										{locale.quotaAlert.replace('{{calculation}}', (calculateLocationQty() * quota))}
									</Typography.Text>
								</div>
							)
						}
					</div>
				</ItemWrapper>
				{/* End of Promo Quota Section */}

				{/* Promo Requirement Section */}
				<ItemWrapper
					label={locale.promoRequirementLabel}
				>
					<div className='flex flex-col gap-3'>
						{
							(rules?.aov?.minAmount || rules?.aov?.minAmount !== null) ? (
								<div className='flex gap-6'>
									<Input
										style={{width: 212, minWidth: 212, maxWidth: 212}}
										className='pointer-events-none'
										value={locale.minimumPurchase} />
									<InputNumber
										disabled={viewOnly}
										parser={value => value.replace(/[,.]/g, '')}
										formatter={value => {
											if (value) {
												return numberFormat(value);
											}
											return value;
										}}
										controls={false}
										value={rules?.aov?.minAmount}
										onChange={e => addAov(e)}
										style={{width: 212, minWidth: 212, maxWidth: 212}}
										placeholder={locale.minimumPurchasePlaceholder}
										min={0}
										addonBefore={localization.Global.Utils.currency}
									/>
									{
										!viewOnly && (
											<Button
												ghost
												danger
												onClick={removeAov}
											>
												<DeleteOutlined />
												{locale.delete}
											</Button>
										)
									}
								</div>
							) : null
						}
						{
							(rules?.sku?.details?.length) ? (
								<>
									{rules?.sku?.details.map((sku, index) => (
										<div
											key={index}
											className='flex gap-6'>
											<Input
												style={{width: 212, minWidth: 212, maxWidth: 212, height: 'fit-content'}}
												className='pointer-events-none'
												value={locale.specificMenu} />
											<div
												className={clsx('discounted__menu__name', viewOnly ? 'cursor-not-allowed' : 'cursor-pointer')}
												onClick={() => !viewOnly && handleOpenMenuPicker(index)}>
												<Select
													disabled={viewOnly}
													className='pointer-events-none'
													style={{width: 212, minWidth: 212, maxWidth: 212}}
													value={sku?.menuId && `${sku.menuLabel} [${sku.menuId}]`}
													placeholder={locale.specificMenuPlaceholder} />
											</div>
											{
												!viewOnly && (
													<Button
														ghost
														danger
														onClick={() => removeSku(index)}
													>
														<DeleteOutlined />
														{locale.delete}
													</Button>
												)
											}
										</div>
									))}
								</>
							) : null
						}
						{
							viewOnly
								? null
								: (
									<div>
										<Dropdown
											trigger={['click']}
											placement="bottomLeft"
											overlay={(
												<Menu>
													<Menu.Item
														disabled={rules?.aov?.minAmount || rules?.aov?.minAmount === 0}
														onClick={() => addAov()}
														key={locale.minimumPurchase}>{locale.minimumPurchase}</Menu.Item>
													<Menu.Item
														onClick={addSku}
														key={locale.specificMenu}>{locale.specificMenu}</Menu.Item>
												</Menu>
											)}
										>
											<Button type='primary'>{locale.promoRequirementAction}</Button>
										</Dropdown>
									</div>
								)
						}
					</div>
				</ItemWrapper>
				{/* End of Promo Requirement Section */}

			</div>
		</Card>
	);
};

PromoRequirement.defaultProps = {
	openMenuPicker: () => null,
	rules: {},
	setRules: () => null,
	setFormFilled: () => null,
};

PromoRequirement.propTypes = {
	openMenuPicker: PropTypes.func,
	rules: PropTypes.object,
	setRules: PropTypes.func,
	setFormFilled: PropTypes.func,
};

export default PromoRequirement;