import {useCallback, useEffect, useRef, useState} from 'react';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import moment from 'moment';

import ProTable from '@ant-design/pro-table';
import {Image} from 'antd';

import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';
import {getOutletRequest} from 'utils/request/watchtower';
import {getPlatformImage, handleErrorFetch} from 'utils/utils';

import localization from 'localization';
const locale = localization.WatchTower.WatchTowerOutlet;

const METHOD_LIST = ['auto', 'manual'];

const TabletOutletList = ({isHistory, brandList, platformList, reasonList}) => {
	const tableRef = useRef(null);
	const [locationList, setLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

	const durationFormatter = minutes => {
		const duration = moment.duration(minutes, 'minutes'); // Create a duration object with the given value
		const hours = duration.hours(); // Get the number of hours from the duration object
		const minutesLeft = duration.minutes(); // Get the remaining minutes from the duration object

		let output; // Declare a variable for the output string

		if (hours === 0) { // If the value is less than an hour
			output = `${minutes} minute(s)`; // Output the number of minutes with text
		} else if (minutesLeft === 0) { // If the value is a whole number of hours
			output = `${hours} hour(s)`; // Output the number of hours with text
		} else { // Otherwise (if the value is a combination of hours and minutes)
			output = `${hours} hour(s) and ${minutesLeft} minute(s)`; // Output the number of hours and minutes with text and formatted duration
		}

		return output;
	};

	const getTimeDiff = (timestamp1, timestamp2) => {
		const diff = moment.utc(moment(timestamp2).diff(moment(timestamp1))); // Get the time difference between the two timestamps as a duration object
		const hours = diff.hours(); // Get the number of hours from the duration object
		const minutesLeft = diff.minutes(); // Get the remaining minutes from the duration object

		let output; // Declare a variable for the output string

		if (hours === 0) { // If the difference is less than an hour
			output = `${minutesLeft} minute(s)`; // Output the number of minutes with text
		} else if (minutesLeft === 0) { // If the difference is a whole number of hours
			output = `${hours} hour(s)`; // Output the number of hours with text
		} else { // Otherwise (if the difference is a combination of hours and minutes)
			output = `${hours} hour(s) and ${minutesLeft} minute(s)`; // Output the number of hours and minutes with text and formatted duration
		}

		return output;
	};

	const getLocation = async outletLabel => {
		try {
			if (outletLabel.length === 0 && locationList.length !== 0) {
				return;
			}
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	const columns = [
		{
			key: 'locationId',
			dataIndex: 'outletName',
			title: locale.outletList.outletNameLabel,
			valueType: 'select',
			fieldProps: {
				onSearch: debounceFn,
				loading: locationLoading,
				showSearch: true,
				options: locationList.map(location => {
					return {
						label: location.label,
						value: location.id,
					};
				}),
			},
		},
		{
			key: 'totalOrderQueueOff',
			dataIndex: 'totalOrderQueueOff',
			title: locale.outletList.lastQueueOff,
			search: false,
		},
		...(
			isHistory ? [{
				key: 'totalOrderQueueOn',
				dataIndex: 'totalOrderQueueOn',
				title: locale.outletList.lastQueueOn,
				search: false,
			}] : []
		),
		{
			key: 'platformId',
			title: locale.outletList.platformLabel,
			render: (_, {platform}) => {
				const platformImage = getPlatformImage(platform);
				return (
					<div className="flex gap-2">
						<Image
							width={22}
							height={22}
							src={platformImage?.default}
							preview={false}
						/>
						{platform}
					</div>
				);
			},
			valueType: 'select',
			fieldProps: {
				showSearch: true,
				options: platformList.map(paltform => {
					return {
						label: paltform.label,
						value: paltform.id,
					};
				}),
			},
		},
		{
			key: 'brandId',
			dataIndex: 'brand',
			title: locale.outletList.brandLabel,
			valueType: 'select',
			fieldProps: {
				showSearch: true,
				options: brandList.map(brand => {
					return {
						label: brand.label,
						value: brand.id,
					};
				}),
			},
		},
		{
			key: 'turnOffBy',
			title: locale.outletList.turnOffBy,
			render: (_, {createdBy, offBy}) => isHistory ? offBy : createdBy,
			search: false,
		},
		{
			key: 'timestampOff',
			title: locale.outletList.timestampOffLabel,
			render: (_, {timestampOff}) => moment(timestampOff).format('D MMM YYYY - HH.mm'),
			valueType: 'dateTimeRange',
			fieldProps: {
				format: 'D MMM YYYY - HH.mm',
			},
		},
		...(
			isHistory ? [
				{
					key: 'turnOnBy',
					title: locale.outletList.turnOnBy,
					render: (_, {createdBy, onBy}) => isHistory ? onBy : createdBy,
					search: false,
				},
				{
					search: false,
					key: 'timestampOn',
					dataIndex: 'timestampOn',
					title: locale.outletList.timestampOnLabel,
					render: timestampOn => moment(timestampOn).format('D MMM YYYY - HH.mm'),
				},
			] : []
		),
		{
			key: 'methods',
			dataIndex: 'methods',
			title: locale.outletList.methodsLabel,
			valueType: 'select',
			render: (_, {methods}) => methods ? capitalize(methods) : '-',
			fieldProps: {
				showSearch: true,
				options: METHOD_LIST.map(method => {
					return {
						label: capitalize(method),
						value: method,
					};
				}),
			},
		},
		{
			key: 'initialDuration',
			dataIndex: 'initialDuration',
			title: locale.outletList.initialDurationLabel,
			search: false,
			render: initialDuration => initialDuration == -1 ? 'Tanpa batas waktu' : durationFormatter(initialDuration),
		},
		{
			key: 'reason',
			dataIndex: 'reason',
			title: locale.outletList.reasonLabel,
			valueType: 'select',
			fieldProps: {
				showSearch: true,
				options: reasonList.sort().map(reason => {
					return {
						label: reason,
						value: reason,
					};
				}),
			},
		},
		...(
			isHistory
				? [{
					key: 'offDuration',
					title: locale.outletList.offDurationLabel,
					search: false,
					render: (_, record) => getTimeDiff(record.timestampOff, record.timestampOn),
				}]
				: []
		),
	];

	const fetchTableData = async params => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				brandId: params?.brandId,
				locationId: params?.locationId,
				platformId: params?.platformId,
				reason: params?.reason,
				methods: params?.methods,
			};

			if (params?.timestampOff?.length) {
				payload.createdAtFrom = (moment(params?.timestampOff[0], 'D MMM YYYY - HH.mm').utc().format());
				payload.createdAtTo = (moment(params?.timestampOff[1], 'D MMM YYYY - HH.mm').utc().format());
			}

			const response = await getOutletRequest({params: payload, isHistory});

			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		tableRef.current.reset();
	}, [isHistory]);

	return (
		<ProTable
			actionRef={tableRef}
			className='ResetSearchTableMargin px-6'
			rowKey='id'
			columns={columns}
			request={fetchTableData}
			toolBarRender={false}
			scroll={{x: 'max-content'}}
			search={{
				layout: 'vertical',
			}}
			pagination={{
				defaultPageSize: 25,
				showSizeChanger: true,
				showQuickJumper: true,
				pageSizeOptions: ['10', '25', '50', '75', '100'],
			}}
		/>
	);
};

TabletOutletList.propTypes = {
	isHistory: PropTypes.bool,
	loading: PropTypes.bool,
	brandList: PropTypes.array,
	platformList: PropTypes.array,
	reasonList: PropTypes.array,
};

export default TabletOutletList;