import React, {useRef, useState} from 'react';
import {Tabs} from 'antd';

import Header from './Header';
import TaxFileUploadTable from './TaxFileUploadTable';
import ImportModal from './ImportModal';

const TABS = [
	{key: 'pos', label: 'Bulk Pairing'},
	{key: 'settlement', label: 'Settlement File'},
];

import './TaxFileUpload.less';

const TaxFileUpload = () => {
	const [activeTab, setActiveTab] = useState(TABS[0].key);
	const [importModalVisible, setImportModalVisible] = useState(false);

	const tableRef = useRef(null);

	const refetchTable = () => {
		tableRef?.current?.reload?.();
	};

	return (
		<div className='bg-white h-full overflow-auto'>
			<ImportModal
				refetchTable={refetchTable}
				activeTab={activeTab}
				visible={importModalVisible}
				close={() => setImportModalVisible(false)}
			/>
			<Header openImportModal={() => setImportModalVisible(true)} />
			<div className='TaxFileUpload'>
				<Tabs
					activeKey={activeTab}
					onChange={setActiveTab}>
					{
						TABS.map(tab => (
							<Tabs.TabPane
								key={tab.key}
								tab={tab.label}>

							</Tabs.TabPane>
						),
						)
					}
				</Tabs>
				<TaxFileUploadTable
					ref={tableRef}
					activeTab={activeTab}
				/>
			</div>
		</div>
	);
};

export default TaxFileUpload;