import React, {useState} from 'react';
import PropTypes from 'prop-types';

import capitalize from 'lodash/capitalize';

import {refreshPromoStatus} from 'utils/request/promo';
import LocalPageHeader from 'components/LocalPageHeader';

import {Button} from 'antd';
import {SyncOutlined} from '@ant-design/icons';

import {PROMO_CATEGORY} from 'utils/constants';
import {handleErrorFetch} from 'utils/utils';

import localization from 'localization';
const locale = localization.Promo.PromoStatus.Header;

const PromoStatusHeader = ({refetchTableRef, promoCategory}) => {
	const [loading, setLoading] = useState(false);

	const handleRefreshTable = async () => {
		try {
			setLoading(true);
			await refetchTableRef?.current?.();
			await refreshPromoStatus();
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<LocalPageHeader
				headerTitle={capitalize(`${locale.title} - ${promoCategory === PROMO_CATEGORY.EXTERNAL ? 'Price Cut' : 'Free Item'}`)}
				extra={(
					<Button
						loading={loading}
						type='primary'
						icon={<SyncOutlined />}
						onClick={handleRefreshTable}
					>{locale.refresh}</Button>
				)}
			/>
		</div>
	);
};

PromoStatusHeader.defaultProps = {
	refetchTableRef: {},
	promoCategory: null,
};

PromoStatusHeader.propTypes = {
	refetchTableRef: PropTypes.object,
	promoCategory: PropTypes.string,
};

export default PromoStatusHeader;
