import {forwardRef, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import ProTable from '@ant-design/pro-table';

import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';

import {isEmpty} from 'lodash';

const TemplateTable = forwardRef(({
	updateColumns, addColumns, rows, total, loading, setQueryParams, isHistory, defaultQueryParams,
	queryParams, fetchData, defaultColumns, defaultSearch,
}, ref) => {

	const tableRef = useRef();

	const columns = defaultColumns.map(each => {
		isArray(updateColumns) && updateColumns.forEach(eachUpdate => {
			if (each.key === eachUpdate.keyToUpdate) {
				each = {...eachUpdate};
				delete each.keyToUpdate;
			}
		});
		return each;
	});
	isArray(addColumns) && addColumns.forEach(each => {
		const duplicate = {...each};
		delete duplicate.index;
		columns.splice(each.index, 0, duplicate);
	});
	// Methods
	const setObjectRef = () => {
		const refObject = {
			getColumns: () => columns,
		};
		if (ref && ref.hasOwnProperty('current')) {
			ref.current = refObject;
		} else if (typeof ref === 'function') {
			ref(refObject);
		}
	};
	const handleChange = pagers => {
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams(state => ({
			...state,
			...variables,
		}));
	};
	// Hooks
	useEffect(() => {
		setObjectRef();
	}, []);
	useEffect(() => {
		tableRef?.current?.reset();
	}, [isHistory]);
	// Render
	return (
		<ProTable
			actionRef={tableRef}
			loading={loading}
			columns={columns}
			dataSource={rows}
			search={{layout: 'vertical'}}
			options={false}
			onChange={handleChange}
			onSubmit={params => {
				Object.keys(params).forEach(k => !params[k] && delete params[k]);

				if (!isEmpty(params.inactiveTime)) {
					params.inactiveTimeFrom = `${params.inactiveTime[0].split(' ')[0]} 00:00`;
					params.inactiveTimeTo = `${params.inactiveTime[1].split(' ')[0]} 23:59`;
					delete params.inactiveTime;
				}

				if (!isEmpty(params.reactiveTime)) {
					params.reactivatedAtFrom = `${params.reactiveTime[0].split(' ')[0]} 00:00`;
					params.reactivatedAtTo = `${params.reactiveTime[1].split(' ')[0]} 23:59`;
					delete params.reactiveTime;
				}

				const queryParamsTemp = cloneDeep(defaultQueryParams);
				queryParamsTemp.search = {...defaultSearch, ...params};
				setQueryParams(state => ({...queryParamsTemp, limit: state.limit}));
			}}
			onReset={() => fetchData({reset: true})}
			scroll={{x: 'max-content'}}
			pagination={{
				total: total,
				defaultPageSize: queryParams?.limit || 25,
				showSizeChanger: true,
				showQuickJumper: true,
				pageSizeOptions: ['5', '10', '20', '50', '100'],
			}}
		/>
	);
});

TemplateTable.defaultProps = {
	rows: [],
	total: 0,
	loading: false,
	addColumns: [],
	removeColumns: [],
	updateColumns: [],
	setQueryParams: () => null,
	fetchData: () => null,
	queryParams: {},
	defaultQueryParams: {},
	isHistory: false,
	menuAvailabilityReason: [],
	defaultColumns: [],
	defaultSearch: {},
};

TemplateTable.propTypes = {
	rows: PropTypes.array,
	loading: PropTypes.bool,
	total: PropTypes.number,
	addColumns: PropTypes.array,
	removeColumns: PropTypes.array,
	updateColumns: PropTypes.array,
	setQueryParams: PropTypes.func,
	fetchData: PropTypes.func,
	queryParams: PropTypes.object,
	defaultQueryParams: PropTypes.object,
	isHistory: PropTypes.bool,
	menuAvailabilityReason: PropTypes.array,
	defaultColumns: PropTypes.array,
	defaultSearch: PropTypes.object,
};

export default TemplateTable;
