import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Table} from 'antd';

const LinkedTemplateModal = ({visible, close, menuStructureData}) => {

	const tableColumn = [
		{
			title: 'Template ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Template Name',
			dataIndex: 'label',
			key: 'label',
		},
	];

	return (
		<Modal
			centered
			width={868}
			title={`Linked Template - ${menuStructureData?.MasterMenu?.menuLabel}`}
			onCancel={close}
			visible={visible}
			cancelButtonProps={{style: {display: 'none'}}}
			onOk={close}
		>
			<Table
				pagination={false}
				dataSource={menuStructureData?.linkedMenuTemplates}
				columns={tableColumn}
				scroll={{y: 400}}
			/>
		</Modal>
	);
};

LinkedTemplateModal.defaultProps = {
	visible: false,
	close: () => null,
	menuStructureData: {},
};

LinkedTemplateModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	menuStructureData: PropTypes.object,
};

export default LinkedTemplateModal;