import LocalPageHeader from 'components/LocalPageHeader';
import React from 'react';

const SyncStatusHeader = () => {
	return (
		<div>
			<LocalPageHeader
				headerTitle='Sync Status'
			/>
		</div>
	);
};

export default SyncStatusHeader;
