import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Modal, Select, Typography, notification} from 'antd';

import localization from 'localization';
const locale = localization.Outlet.Training.List.CreateNewSessionModal;

import {handleErrorFetch} from 'utils/utils';
import {createTrainingSession} from 'utils/request/outlet';

const NewTrainingSessionModal = ({visible, close, outletList, debounceFn, outletListLoading}) => {
	const navigate = useNavigate();

	const [selectedOutletId, setSelectedOutletId] = useState(null);
	const [loading, setLoading] = useState(false);

	const createSession = async () => {
		try {
			setLoading(true);
			const selectedOutletLabel = outletList.find(outlet => outlet.id === selectedOutletId)?.label;

			const response = await createTrainingSession(selectedOutletId);
			if (response.success) {
				notification.success({
					message: locale.successTitle,
					description: locale.successMessage.replace('{{outletLabel}}', selectedOutletLabel),
				});
				navigate(`/outlet/training-session/${response.data.training.id}`);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!visible) setSelectedOutletId(null);
	}, [visible]);

	return (
		<Modal
			destroyOnClose
			title={locale.title}
			visible={visible}
			onCancel={close}
			width={572}
			okButtonProps={{
				disabled: !selectedOutletId,
				loading: loading,
			}}
			cancelButtonProps={{
				loading: loading,
			}}
			onOk={() => createSession()}
			centered>
			<div>
				<div className='mb-2'>{locale.selectOutletLabel}</div>
				<Select
					showSearch
					loading={outletListLoading}
					onSearch={debounceFn}
					placeholder={locale.selectOutletPlaceholder}
					className='w-full'
					onChange={val => setSelectedOutletId(val)}
					filterOption={((_, option) => option)}
					options={outletList.map(outlet => {
						return {
							value: outlet.id,
							label: `${outlet.id} - ${outlet.label}`,
						};
					})}
				/>
				<Typography.Text type='secondary'>{locale.selectOutletInfo}</Typography.Text>
			</div>
		</Modal>
	);
};

NewTrainingSessionModal.defaultProps = {
	visible: false,
	close: () => null,
	debounceFn: () => null,
	outletList: [],
	outletListLoading: false,
};

NewTrainingSessionModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	debounceFn: PropTypes.func,
	outletList: PropTypes.array,
	outletListLoading: PropTypes.bool,
};

export default NewTrainingSessionModal;