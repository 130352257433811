import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Table} from 'antd';

const SalesNumberCheckerModal = ({dataSource, visible, close}) => {

	const columns = [
		{
			title: 'Sales Number',
			dataIndex: 'sales_number',
			key: 'sales_number',
		},
		{
			title: 'Record Count',
			dataIndex: 'count',
			key: 'count',
		},
	];

	return (
		<Modal
			title='Duplicate Sales Number'
			width={1022}
			centered
			visible={visible}
			okText='Save'
			onCancel={close}
			footer={false}
		>
			<Table
				className='pb-6'
				rowKey='sales_number'
				dataSource={dataSource}
				pagination={{
					defaultPageSize: 5,
				}}
				columns={columns}
			/>
		</Modal>
	);
};

SalesNumberCheckerModal.defaultProps = {
	dataSource: [],
	visible: false,
	close: () => null,
};

SalesNumberCheckerModal.propTypes = {
	dataSource: PropTypes.array,
	visible: PropTypes.bool,
	close: PropTypes.func,
};

export default SalesNumberCheckerModal;