import React from 'react';
import {useNavigate} from 'react-router-dom';

import LocalPageHeader from 'components/LocalPageHeader';

import {Button} from 'antd';
import {EditOutlined} from '@ant-design/icons';

import localization from 'localization';
const locale = localization.Admin.UserList.Header;

const UserListHeader = () => {
	const navigate = useNavigate();

	return (
		<div>
			<LocalPageHeader
				headerTitle={locale.title}
				extra={(
					<Button
						onClick={() => navigate('/admin/user-list/new')}
						type='primary'
						icon={<EditOutlined />}
					>{locale.add}</Button>
				)}
			/>
		</div>
	);
};

export default UserListHeader;