import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {notification, Skeleton} from 'antd';

import OrderDetailModal from '../OrderDetailModal';
import LocalPageHeader from 'components/LocalPageHeader';

import {getOrderHistory} from 'utils/request/watchtower';
import {handleErrorFetch} from 'utils/utils';

import localization from 'localization';
const locale = localization.WatchTower.WatchTowerOrder.orderDetailPage;

const OrderDetailPage = ({isPos}) => {
	const {orderId} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [orderDetails, setOrderDetails] = useState({});

	const initialFetch = async () => {
		try {
			setLoading(true);
			const response = await getOrderHistory({id: orderId, isPos});
			if (response.success) {
				if (response.data.rows.length) {
					setOrderDetails(response.data.rows[0]);
				} else {
					notification.open({
						message: locale.Notification.notFoundMessage,
						description: locale.Notification.notFoundDescription,
						type: 'warning',
					});
				}
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!orderId) navigate('/watchtower/order');
		else initialFetch();
	}, []);

	return (
		<div className="bg-white h-full overflow-auto">
			<LocalPageHeader
				headerTitle={`${locale.title} · ${orderDetails?.merchantOrderId || '-'}`}
				customBreadcrumbLabel={locale.breadcrumb}
			/>
			{
				loading
					? (
						<div className='px-6'>
							<Skeleton />
						</div>
					)
					: (
						<div className='pb-6'>
							<OrderDetailModal
								isPos={isPos}
								withoutModal={true}
								visible={true}
								data={orderDetails}
							/>
						</div>
					)
			}
		</div>
	);
};

OrderDetailPage.defaultProps = {
	isPos: false,
};

OrderDetailPage.propTypes = {
	isPos: PropTypes.bool,
};

export default OrderDetailPage;