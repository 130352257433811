import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import ChangePasswordModal from './ChangePasswordModal';

import {Dropdown, Layout, Menu} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faOutdent} from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import {useDispatch, useSelector} from 'react-redux';
import {setUserData} from 'store/actions/user';
import {userLogout} from 'utils/request/global';
import {AUTH} from 'utils/constants';

import {version} from '../../../package.json';

const profileImageURL = 'https://api.dicebear.com/7.x/initials/svg?seed={{username}}';

import localization from 'localization';
import {setSalesDropdownOption} from 'store/actions/salesDropdownOption';
const locale = localization.Global.GlobalHeader;

const GlobalHeader = ({sidebarCollapsed, toggleSidebar}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector(state => state.user);

	const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);

	const handleLogout = async () => {
		await userLogout();
		dispatch(setUserData({
			username: '',
			roles: [],
			userId: null,
			loggedIn: false,
		}));
		dispatch(setSalesDropdownOption({}));
		localStorage.removeItem(AUTH.ACCESS_TOKEN);
		navigate('/login', {replace: true});
	};

	const handleChangePassword = () => {
		setChangePasswordModalVisible(true);
	};

	return (
		<>
			<ChangePasswordModal
				visible={changePasswordModalVisible}
				onClose={() => setChangePasswordModalVisible(false)} />
			<Layout.Header
				className="h-14 bg-white px-4 flex items-center justify-between border-b-2 border-custom-grey-lighter"
			>
				<div
					onClick={toggleSidebar}
					className="bg-gray-200 cursor-pointer flex p-2 rounded-full bg-opacity-0
			hover:bg-opacity-100 transition-all">
					<FontAwesomeIcon
						className={clsx(
							'text-custom-black text-xl transition-transform duration-500 transform',
							sidebarCollapsed && '-rotate-180',
						)}
						icon={faOutdent} />
				</div>
				<Dropdown
					overlay={(
						<Menu>
							<Menu.Item onClick={handleChangePassword}>{locale.changePassword}</Menu.Item>
							<Menu.Item onClick={handleLogout}>{locale.logout}</Menu.Item>
							<Menu.Item className='pointer-events-none text-antd-netural-5'>
								<div className='flex justify-end select-none'>v{version}</div>
							</Menu.Item>
						</Menu>
					)}
					placement='bottomRight'
					trigger={['click']}
				>
					<div className="flex items-center justify-center space-x-3 cursor-pointer">
						<img
							className='h-6 w-6 rounded-full'
							src={profileImageURL.replace('{{username}}', auth.username)} />
						<div>{auth.username}</div>
					</div>
				</Dropdown>
			</Layout.Header>
		</>
	);
};

GlobalHeader.defaultProps = {
	sidebarCollapsed: false,
	toggleSidebar: () => null,
};

GlobalHeader.propTypes = {
	sidebarCollapsed: PropTypes.bool,
	toggleSidebar: PropTypes.func,
};

export default GlobalHeader;
