import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Select} from 'antd';

import SettingSelect from '../SettingSelect';
import Context from '../../../Context';

import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm.ManageCategory.OutletSettingDrawer.SettingCategoryLevel;

const SettingCategoryLevel = ({type, locationIds, handleSettingChange, categories, categoryUseExisting}) => {
	const {previewAs, viewOnly} = useContext(Context);
	const [validSettings, setValidSettings] = useState([]);

	const handleUseExistSetting = categoryId => {
		if (!categoryId) {
			categoryUseExisting({
				type: 'default',
				locationIds: [-1],
			});
		} else {
			const settingSource = validSettings.find(menu => menu.categoryId === categoryId)?.locationFilter;
			categoryUseExisting(settingSource);
		}
	};

	useEffect(() => {
		const tempValidSettings = [];
		categories.forEach(category => {
			if (['include', 'exclude'].includes(category.locationFilter.type)) {
				tempValidSettings.push(category);
			}
		});

		setValidSettings(tempValidSettings);
	}, [categories]);

	return (
		<div className='p-6 flex flex-1 w-full'>
			<div className='flex flex-col w-full gap-4'>
				{
					(previewAs || viewOnly) ? null : (
						<div>
							<div className='pb-2'>
								{locale.useExistingCategory}
							</div>
							<Select
								allowClear
								placeholder={locale.useExistingCategoryPlaceholder}
								className='w-full'
								onChange={e => handleUseExistSetting(e)}
								disabled={!validSettings.length}
							>
								{validSettings.map(category => (
									<Select.Option
										label={category.categoryLabel}
										value={category.categoryId}
										key={category.categoryId}>
										{category.categoryLabel}
									</Select.Option>
								))}
							</Select>
						</div>
					)
				}
				<SettingSelect
					typeValue={type}
					locationValue={locationIds}
					onChange={({value, field}) => handleSettingChange({value, field, level: 'category'})}
				/>
			</div>
		</div>
	);
};

SettingCategoryLevel.defaultProps = {
	type: null,
	handleSettingChange: () => null,
	categoryUseExisting: () => null,
	locationIds: [],
	categories: [],
};

SettingCategoryLevel.propTypes = {
	type: PropTypes.string,
	handleSettingChange: PropTypes.func,
	categoryUseExisting: PropTypes.func,
	locationIds: PropTypes.array,
	categories: PropTypes.array,
};

export default SettingCategoryLevel;