import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {Radio} from 'antd';
import Context from 'pages/SalesChannel/MenuTemplateForm/MenuTemplateFormBody/Context';

const OutletSettingLevel = ({settingLevel, setSettingLevel}) => {
	const {viewOnly} = useContext(Context);
	return (
		<div className='px-6 py-4'>
			<div>Select outlet settings</div>
			<Radio.Group
				disabled={viewOnly}
				className='pt-3'
				onChange={e => setSettingLevel(e.target.value)}
				value={settingLevel}>
				<Radio value={'menu'}>For Each Menu</Radio>
				<Radio value={'category'}>For All Menus</Radio>
			</Radio.Group>
		</div>
	);
};

OutletSettingLevel.defaultProps = {
	settingLevel: null,
	setSettingLevel: () => null,
};

OutletSettingLevel.propTypes = {
	settingLevel: PropTypes.string,
	setSettingLevel: PropTypes.func,
};

export default OutletSettingLevel;