import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Progress, Typography} from 'antd';
const {Title} = Typography;

const RefreshMappingLoadingBar = ({visible}) => {
	return (
		<Modal
			closable={false}
			footer={null}
			centered
			visible={visible}
		>
			<div className='flex flex-col gap-8'>
				<Title
					className='font-medium m-0'
					level={5}>Refresh Target Mapping</Title>
				 <Progress
					showInfo={false}
					percent={100}
					status="active" />
			</div>
		</Modal>
	);
};

RefreshMappingLoadingBar.defaultProps = {
	visible: false,
};

RefreshMappingLoadingBar.propTypes = {
	visible: PropTypes.bool,
};

export default RefreshMappingLoadingBar;