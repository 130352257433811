import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Divider, Drawer, Modal, notification} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';

import SettingCategoryLevel from './SettingCategoryLevel';
import OutletSettingLevel from './OutletSettingLevel';
import BulkSettingModal from './BulkSettingModal';
import SettingMenuLevel from './SettingMenuLevel';
import Context from '../../Context';

import './OutletSettingDrawer.less';

import cloneDeep from 'lodash/cloneDeep';

import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm.ManageCategory.OutletSettingDrawer;

const OutletSettingDrawer = ({categoryData, visible, close, setMenuDraft}) => {
	const {viewOnly} = useContext(Context);

	const [settingLevel, setSettingLevel] = useState(null);
	const [duplicateOfCategory, setDuplicateOfCategory] = useState(categoryData.activeCategory);
	const [checkedMenuIndex, setCheckedMenuIndex] = useState([]);

	const [bulkSettingModalVisible, setBulkSettingModalVisible] = useState(false);

	const currentCategory = categoryData.activeCategory;

	const Header = () => (
		<div>
			<div className='font-medium'>{locale.title.replace('{{categoryLabel}}', currentCategory.categoryLabel)}</div>
			<div className='text-overlay-40 font-normal'>{locale.description}</div>
		</div>
	);

	const initialLoad = () => {
		// Determine setting level
		let settingInMenuLevel = false;
		currentCategory.menus.forEach(menu => {
			if (['include', 'exclude'].some(settingType => settingType === menu?.locationFilter?.type)) settingInMenuLevel = true;
		});

		setSettingLevel(settingInMenuLevel ? 'menu' : 'category');
		setDuplicateOfCategory(categoryData.activeCategory);
	};

	const handleSettingChange = ({value, field, level, menuIndex}) => {
		/**
		 * value -> value from field
		 * field -> Changed field [type, locationIds]
		 * level -> where change happens [menu, category]
		 * menuIndex -> index where menu setting change (only for menu level)
		 */

		const newSetting = {};

		if (field === 'locationIds') {
			newSetting.locationIds = value;
		}

		if (field === 'type') {
			newSetting.type = value;
			if (value === 'default') newSetting.locationIds = [-1];
		}

		const duplicateOfCategoryTemp = cloneDeep(duplicateOfCategory);

		if (level === 'category') {
			setDuplicateOfCategory({
				...duplicateOfCategoryTemp,
				locationFilter: {
					...duplicateOfCategoryTemp.locationFilter,
					...newSetting,
				},
			});
		}

		if (level === 'menu') {
			duplicateOfCategoryTemp.menus[menuIndex] = {
				...duplicateOfCategoryTemp.menus[menuIndex],
				locationFilter: {
					...duplicateOfCategoryTemp.menus[menuIndex].locationFilter,
					...newSetting,
				},
			};

			setDuplicateOfCategory(duplicateOfCategoryTemp);
		}
	};

	const categoryUseExisting = locationFilterArgs => {
		setDuplicateOfCategory(state => ({
			...state,
			locationFilter: locationFilterArgs,
		}));
	};

	const handleSave = () => {
		// Reset setting on another level
		const defaultSetting = {
			type: 'default',
			locationIds: [-1],
		};

		let duplicateCategoryTemp = cloneDeep(duplicateOfCategory);

		// Replace other level with default setting
		if (settingLevel === 'category') {
			duplicateCategoryTemp.menus = duplicateCategoryTemp.menus.map(menu => {
				return {
					...menu,
					locationFilter: defaultSetting,
				};
			});
		} else {
			duplicateCategoryTemp = {
				...duplicateCategoryTemp,
				locationFilter: defaultSetting,
			};
		}


		const output = cloneDeep(categoryData.categories);
		const currentCategoryIndex = output.findIndex(category => category.categoryId === currentCategory.categoryId);
		output[currentCategoryIndex] = duplicateCategoryTemp;

		setMenuDraft(output);
		notification.open({
			type: 'success',
			message: locale.SaveSuccessAlert.message,
			description: locale.SaveSuccessAlert.description.replace('{{categoryLabel}}', currentCategory.categoryLabel),
		});
		close();
	};

	const beforeSave = () => {
		Modal.confirm({
			centered: true,
			title: locale.SaveConfirmation.title,
			content: locale.SaveConfirmation.description.replace('{{categoryLabel}}', currentCategory.categoryLabel),
			icon: <ExclamationCircleOutlined />,
			okText: locale.SaveConfirmation.okText,
			cancelText: locale.SaveConfirmation.cancelText,
			onOk() {
				handleSave();
			},
		});
	};

	const handleBulkSetting = setting => {
		/**
		 * settings consist of locationIds and type
		 */

		const duplicateOfCategoryTemp = cloneDeep(duplicateOfCategory);

		checkedMenuIndex.forEach(menuIndex => {
			duplicateOfCategoryTemp.menus[menuIndex] = {
				...duplicateOfCategoryTemp.menus[menuIndex],
				locationFilter: setting,
			};
		});

		setDuplicateOfCategory(duplicateOfCategoryTemp);
		setCheckedMenuIndex([]);
		closeBulkSettingModal();
	};

	const closeBulkSettingModal = () => {
		setBulkSettingModalVisible(false);
	};

	const openBulkSettingModal = () => {
		setBulkSettingModalVisible(true);
	};

	const renderSetting = () => {
		switch (settingLevel) {
		case 'menu':
			return (
				<SettingMenuLevel
					handleSettingChange={handleSettingChange}
					menus={duplicateOfCategory.menus}
					checkedMenuIndex={checkedMenuIndex}
					setCheckedMenuIndex={setCheckedMenuIndex}
				/>
			);
		case 'category':
			return (
				<SettingCategoryLevel
					handleSettingChange={handleSettingChange}
					type={duplicateOfCategory.locationFilter.type}
					locationIds={duplicateOfCategory.locationFilter.locationIds}
					categories={categoryData?.categories || []}
					categoryUseExisting={categoryUseExisting}
				/>
			);
		default:
			return null;
		}
	};

	useEffect(() => {
		initialLoad();
	}, [visible]);

	useEffect(() => {
		if (settingLevel === 'menu' && !viewOnly) {
			const savedSettingInCategory = currentCategory.locationFilter;
			const duplicateOfCategoryTemp = cloneDeep(duplicateOfCategory);

			duplicateOfCategoryTemp.menus = duplicateOfCategoryTemp.menus.map(menu => ({
				...menu,
				locationFilter: savedSettingInCategory,
			}));
			setDuplicateOfCategory(duplicateOfCategoryTemp);
		}
	}, [settingLevel]);

	return (
		<>
			<BulkSettingModal
				visible={bulkSettingModalVisible}
				menus={duplicateOfCategory.menus}
				close={closeBulkSettingModal}
				checkedMenuIndex={checkedMenuIndex}
				onOk={handleBulkSetting}
			/>
			<Drawer
				maskClosable={false}
				className='OutletSettingDrawer'
				title={Header()}
				closeIcon={false}
				visible={visible}
				width={515}
				onClose={close}
				zIndex={5}
			>
				<div className='h-full flex flex-col'>
					<OutletSettingLevel
						settingLevel={settingLevel}
						setSettingLevel={setSettingLevel}
					/>
					<Divider className='m-0' />
					{renderSetting()}
					<div className='flex gap-4 p-4'>
						<Button
							onClick={close}
							className='flex-1'>{locale.cancel}</Button>
						{
							!viewOnly && (
								<Button
									onClick={() => checkedMenuIndex.length ? openBulkSettingModal() : beforeSave()}
									type='primary'
									className='flex-1'>
									{checkedMenuIndex.length ? locale.settingSelected : locale.save}
								</Button>
							)
						}
					</div>
				</div>
			</Drawer>
		</>
	);
};

OutletSettingDrawer.defaultProps = {
	visible: false,
	close: () => null,
	categoryData: {},
	setMenuDraft: () => null,
};

OutletSettingDrawer.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	categoryData: PropTypes.object,
	setMenuDraft: PropTypes.func,
};

export default OutletSettingDrawer;