import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Tabs} from 'antd';
const {TabPane} = Tabs;

import './MenuTemplateBody.less';
import MenuTemplateTable from './MenuTemplateTable';

import localization from 'localization';
const locale = localization.MenuTemplate.Tabs;

const MenuTemplateBody = ({exportCSV, refetchTableRef, openCreateModal}) => {
	const location = useLocation();

	const [activeTabs, setActiveTabs] = useState(location?.state?.activeTabs || 'active');
	const [isArchived, setIsArchived] = useState(false);

	return (
		<div className='MenuTemplateBodyContainer'>
			<Tabs
				onTabClick={key => {
					switch (key) {
					case 'active':
						setActiveTabs('active');
						break;
					case 'draft':
						setActiveTabs('draft');
						break;
					case 'archive':
						setActiveTabs('archive');
						break;
					default:
						setActiveTabs('active');
						break;
					}
				}}
				activeKey={activeTabs}
				onChange={() => setIsArchived(!isArchived)}>
				<TabPane
					tab={locale.active}
					key="active">
					<MenuTemplateTable
						openCreateModal={openCreateModal}
						refetchTableRef={refetchTableRef}
						exportCSV={exportCSV}
						activeTabs={activeTabs}
						tabsKey={'active'}
						isArchived={false}
					/>
				</TabPane>
				<TabPane
					tab={locale.draft}
					key="draft">
					<MenuTemplateTable
						openCreateModal={openCreateModal}
						refetchTableRef={refetchTableRef}
						exportCSV={exportCSV}
						activeTabs={activeTabs}
						tabsKey={'draft'}
						isArchived={false}
					/>
				</TabPane>
				<TabPane
					tab={locale.archive}
					key="archive">
					<MenuTemplateTable
						activeTabs={activeTabs}
						tabsKey={'archive'}
						isArchived={true}
					/>
				</TabPane>
			</Tabs>
		</div>
	);
};

MenuTemplateBody.defaultProps = {
	exportCSV: () => null,
	refetchTableRef: {},
	openCreateModal: () => null,
};

MenuTemplateBody.propTypes = {
	exportCSV: PropTypes.func,
	refetchTableRef: PropTypes.object,
	openCreateModal: () => null,
};

export default MenuTemplateBody;
