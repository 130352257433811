import React, {forwardRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import ProTable from '@ant-design/pro-table';
import {Button, Modal, notification} from 'antd';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import {exportToTsvFn, handleErrorFetch, momentDateFormat, salesPayloadHandler} from 'utils/utils';
import {deleteSettlementFile, getSalesFileList} from 'utils/request/sales';
import {getOutletData} from 'utils/request/outlet';
import {OUTLET_TYPE} from 'utils/constants';
import {ExclamationCircleOutlined, InfoCircleFilled} from '@ant-design/icons';

const platformList = [
	{
		label: 'Grab',
		value: 'grab',
	},
	{
		label: 'Gojek',
		value: 'gojek',
	},
	{
		label: 'Shopee',
		value: 'shopee',
	},
];

const TaxFileUploadTable = forwardRef(({activeTab}, ref) => {
	const [brandList, setBrandList] = useState([]);
	const [selected, setSelected] = useState([]);
	const [loadingDelete, setLoadingDelete] = useState(false);

	const isBulkPairing = activeTab === 'pos';

	const getFileNameFromFileUrl = fileUrl => {
		const regex = /\/([^\\/]+)$/;
		const match = fileUrl.match(regex);
		const lastPath = match[1];
		return lastPath;
	};

	const handleDownloadFile = async record => {
		const fileUrl = get(record, 'BulkUpload.fileUrl');
		const fileName = getFileNameFromFileUrl(fileUrl);

		const blobResponse = await (await fetch(fileUrl)).blob();

		await exportToTsvFn({
			fileName,
			exportCall: () => blobResponse,
			actionType: 'export',
		});
	};

	const handleDeleteFile = async idToDelete => {
		try {
			setLoadingDelete(true);
			await Promise.all(
				idToDelete.map(id => deleteSettlementFile(id)),
			);
			notification.open({
				type: 'success',
				message: 'Settlement file has been successfully deleted',
			});
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingDelete(false);
			ref?.current?.reload?.();
			setSelected([]);
		}
	};

	const deleteConfirmation = settlementId => {
		const isSingleDownload = settlementId !== undefined;
		const idToDelete = isSingleDownload ? [settlementId] : selected.map(e => e.id);

		Modal.confirm({
			centered: true,
			title: 'Delete Settlement?',
			icon: <ExclamationCircleOutlined />,
			content: `You are about to delete ${idToDelete.length} settlement files and unpair all related transactions. Make sure to delete the right settlement as this can’t be undone.`,
			okButtonProps: {
				danger: true,
			},
			okText: 'Yes, Delete',
			onOk() {
				handleDeleteFile(idToDelete);
			},
		});
	};

	const getColumns = () => {
		const pairingColumn = [
			{
				key: 'id',
				dataIndex: 'id',
				title: 'Bulk File ID',
				width: 116,
			},
			{
				key: 'filename',
				dataIndex: 'filename',
				title: 'File Name',
				width: 360,
			},
			{
				key: 'createdBy',
				dataIndex: ['BulkUpload', 'User', 'username'],
				title: 'Created By',
			},
			{
				key: 'createdAt',
				dataIndex: 'createdAt',
				title: 'Created At',
				render: (_, {createdAt}) => momentDateFormat({dateString: createdAt}),
				valueType: 'dateRange',
			},
		];

		const settlementColumn = [
			{
				key: 'id',
				dataIndex: 'id',
				title: 'Settlement ID',
				width: 116,
			},
			{
				key: 'reportDate',
				dataIndex: 'reportDate',
				title: 'Report Date',
				valueType: 'date',
			},
			{
				key: 'createdAt',
				dataIndex: 'createdAt',
				title: 'Created At',
				valueType: 'date',
			},
			{
				key: 'platform',
				dataIndex: 'platform',
				title: 'Platform',
				valueType: 'select',
				render: (_, record) => startCase(record.platform),
				fieldProps: {
					options: platformList,
				},
			},
			{
				key: 'brand',
				dataIndex: 'brand',
				title: 'Brand',
				valueType: 'select',
				fieldProps: {
					options: brandList,
				},
			},
		];

		const actionColumn = [
			{
				key: 'action',
				dataIndex: 'action',
				title: 'Action',
				search: false,
				fixed: 'right',
				align: 'center',
				width: 156,
				render: (_, record) => (
					<div className='flex gap-4 justify-center'>
						<div
							onClick={() => handleDownloadFile(record)}
							className='text-antd-blue-6 cursor-pointer'>
							Download
						</div>
						{
							isBulkPairing ? null : (
								<div
									onClick={() => deleteConfirmation(record.id)}
									className='text-antd-blue-6 cursor-pointer'>
									Delete
								</div>
							)
						}
					</div>
				),
			},
		];

		const output = (isBulkPairing ? pairingColumn : settlementColumn).concat(actionColumn);
		return output;
	};

	const fetchTableData = async params => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			params.limit = params?.pageSize;
			params.offset = (params?.current - 1) * params?.pageSize;

			delete params.pageSize;
			delete params.current;

			const metricPayload = salesPayloadHandler(params);

			const response = await getSalesFileList({fileType: activeTab, params: metricPayload});

			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const initialFetch = async () => {
		try {
			const brandResponse = await getOutletData({
				search: {limit: 0},
			}, OUTLET_TYPE.BRAND);
			if (brandResponse.success) {
				setBrandList(brandResponse.data.rows.sort((a, b) => a.label.localeCompare(b.label)).map(brand => ({
					label: brand.label,
					value: brand.label,
				})));
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const handleOnSelect = (record, selected) => {
		if (selected) { // Check
			setSelected(state => state.concat(record));
		} else { // Uncheck
			setSelected(state => state.filter(item => item.id !== record.id));
		}
	};

	const handleSelectAll = (selected, _, changeRows) => {
		if (selected) {
			const tempSelectedMenu = [];

			changeRows.forEach(item => {
				tempSelectedMenu.push(item);
			});

			setSelected(state => state.concat(tempSelectedMenu));
		} else {
			const idOfChangeRows = changeRows.map(item => item.id);
			setSelected(state => state.filter(item => !idOfChangeRows.includes(item.id)));
		}
	};

	const rowSelection = {
		selectedRowKeys: selected.map(menu => menu.id),
		onSelectAll: handleSelectAll,
		onSelect: handleOnSelect,
		getCheckboxProps: record => ({
			name: record.id,
		}),
	};

	const renderTableAlert = ({selectedRowKeys}) =>
		selectedRowKeys.length > 0 && (
			<div className='flex gap-3 items-center'>
				<InfoCircleFilled className='text-antd-blue-6' />
				<div>
					<span className='font-semibold'>{selectedRowKeys.length}</span> items selected
				</div>
			</div>
		);

	const renderTableAlertOption = () => (
		<div className='flex gap-2 items-center'>
			<div
				onClick={() => {
					setSelected([]);
				}}
				className='text-antd-blue-6 px-2 cursor-pointer'>Clear</div>
			<Button
				onClick={() => deleteConfirmation()}
				size='small'
				type="primary"
				danger
				disabled={selected.length === 0}
			>
				Delete ({selected.length})
			</Button>
		</div>
	);

	useEffect(() => {
		initialFetch();
	}, []);

	useEffect(() => {
		ref?.current?.reset?.();
	}, [activeTab]);

	return (
		<ProTable
			loading={loadingDelete}
			actionRef={ref}
			rowKey='id'
			columns={getColumns()}
			search={{layout: 'vertical'}}
			options={false}
			request={fetchTableData}
			scroll={{x: 'max-content'}}
			rowSelection={!isBulkPairing && rowSelection}
			tableAlertRender={(renderTableAlert)}
			tableAlertOptionRender={(renderTableAlertOption)}
		/>
	);
});

TaxFileUploadTable.propTypes = {
	activeTab: PropTypes.string,
	type: PropTypes.string,
	refetchTableRef: PropTypes.func,
};

export default TaxFileUploadTable;