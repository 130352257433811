import {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Card, Select, Typography} from 'antd';
import {ProFormDateRangePicker, ProFormSelect, QueryFilter} from '@ant-design/pro-form';
import localization from 'localization';
import PropTypes from 'prop-types';
import moment from 'moment';

// Global utils
import {handleErrorFetch, numberFormat} from 'utils/utils';
import {getOrderHistoryMetric} from 'utils/request/watchtower';
import {getOutletData} from 'utils/request/outlet';
import {OUTLET_TYPE} from 'utils/constants';

import debounce from 'lodash/debounce';

const MetricCards = forwardRef(({tableFormRef, brandList, setDataCount, isPos}, ref) => {
	const locale = localization.WatchTower.WatchTowerOrder;
	const [location, setLocation] = useState([]);
	const [selectedLocationId, setSelectedLocationId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [metricData, setMetricData] = useState({
		failedPercentage: 0,
		successTotal: 0,
		total: 0,
	});

	const queryRef = useRef(null);

	const metricCards = isPos ?
		[
			{label: locale.metricCards.totalOrder, value: numberFormat(metricData.totalOrders), showPercent: false},
			{label: locale.metricCards.nettSalesOrder, value: numberFormat((metricData.totalOrders || 0) - metricData.totalCancelled || 0 - metricData.totalVoid || 0), showPercent: false},
			{label: locale.metricCards.cancelOrder, value: numberFormat(metricData.totalCancelled || 0), showPercent: false},
			{label: locale.metricCards.voidOrder, value: numberFormat(metricData.totalVoid || 0), showPercent: false},
		] : [
			{label: locale.metricCards.totalOrder, value: numberFormat(metricData.total), showPercent: false},
			{label: locale.metricCards.totalSuccessAutoInject, value: numberFormat(metricData.successTotal), showPercent: false},
			{label: locale.metricCards.failedInjectPercentage, value: metricData.failedPercentage, showPercent: true},
		];

	// Methods
	const refetchData = () => {
		tableFormRef.current?.submit?.();
	};
	const resetForm = () => {
		setSelectedLocationId(null);
		queryRef.current?.setFormFields?.({
			orderDate: [moment().startOf('day'), moment().endOf('day')],
		});
		refetchData();
	};
	const getFilterPayload = () => {
		const metricFilters = {
			locationId: selectedLocationId,
			...queryRef.current?.getFieldsValue?.(),
		};
		const output = {
			locationId: metricFilters?.locationId || null,
			brandId: metricFilters?.brandId || null,
		};

		if (isPos) {
			output.acceptedAtFrom = metricFilters.orderDate[0].format('YYYY-MM-DD');
			output.acceptedAtTo = metricFilters.orderDate[1].format('YYYY-MM-DD');
		} else {
			output.createdAtFrom = metricFilters.orderDate[0].format('YYYY-MM-DD');
			output.createdAtTo = metricFilters.orderDate[1].format('YYYY-MM-DD');
		}

		return output;
	};
	const fetchRangedData = async () => {
		try {
			setLoading(true);
			const payload = getFilterPayload();
			const response = await getOrderHistoryMetric({...payload, isPos});
			if (response.success) {
				setMetricData({
					...response.data,
					failedPercentage: response.data.failedPercentage === null
						? 0
						: response.data.failedPercentage,
				});
				setDataCount(isPos
					? {
						problematicCount: response.data?.problematicCount,
						notProblematicCount: response.data?.notProblematicCount,
					}
					: {
						injectedCount: response.data.injectedCount,
						notInjectedCount: response.data.notInjectedCount,
					});
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};
	const getLocation = async outletLabel => {
		try {
			setLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocation(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};
	const debounceFn = useCallback(debounce(getLocation, 1000), []);
	useImperativeHandle(ref, () => ({
		getMetricFilter() {
			return getFilterPayload();
		},
		fetchMetricData() {
			return fetchRangedData();
		},
	}));
	// Hooks
	useEffect(() => {
		fetchRangedData();
	}, []);
	// Render
	return (
		<>
			<QueryFilter
				onReset={resetForm}
				formRef={queryRef}
				className="mx-6 p-6 pb-0 mb-4 border border-antd-netural-4 bg-antd-netural-2"
				labelWidth="auto"
				layout="vertical"
				lang="en"
				submitter={{
					submitButtonProps: {
						loading,
					},
				}}
				initialValues={{
					orderDate: [moment().startOf('day'), moment().endOf('day')],
				}}
				onFinish={() => refetchData()}
			>
				<ProFormDateRangePicker
					allowClear={false}
					label={locale.orderList.orderDate}
					name="orderDate"
					ranges={{
						Today: [moment().startOf('day'), moment().endOf('day')],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
					}}
					placeholder={[locale.startDate, locale.endDate]}
				/>
				<div className='flex flex-1 flex-col'>
					<div>{locale.orderList.location}</div>
					<Select
						allowClear
						showSearch
						loading={loading}
						onSearch={debounceFn}
						value={selectedLocationId}
						onChange={value => setSelectedLocationId(value)}
						className='w-full pt-2'
						filterOption={((_, option) => option)}
						placeholder={locale.orderList.placeholder}>
						{location.map(location => (
							<Select.Option
								key={location.id}
								value={location.id}>{location.label}</Select.Option>
						))}
					</Select>
				</div>
				<ProFormSelect
					showSearch
					label={locale.orderList.brand}
					name="brandId"
					options={brandList.map(brand => ({
						label: brand.label,
						value: brand.id,
					}))}
				/>
			</QueryFilter>
			<div className="px-6">
				<div className='flex gap-6'>
					{metricCards.map(({label, value, showPercent}) => (
						<div
							className='flex-1'
							key={label}>
							<Card className="metricCard">
								<Typography.Text
									className='text-sm'
									type="secondary">
									{label}
								</Typography.Text>
								<div className="h-2" />
								<Typography.Text className='text-2xl'>
									{showPercent ? `${value}%` : value}
								</Typography.Text>
							</Card>
						</div>
					))}
				</div>
			</div>
		</>
	);
});

MetricCards.defaultProps = {
	tableFormRef: {},
	brandList: [],
	setDataCount: () => null,
	isPos: false,
};

MetricCards.propTypes = {
	tableFormRef: PropTypes.object,
	brandList: PropTypes.array,
	setDataCount: PropTypes.func,
	isPos: PropTypes.bool,
};

export default MetricCards;
