import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import {Transition} from 'react-transition-group';

import {Checkbox, Input, Modal, Radio, TimePicker, Typography} from 'antd';
import {PercentageOutlined} from '@ant-design/icons';

import localization from 'localization';
const locale = localization.Promo.CreateNewPromo.Form;

const activeHours = [
	{
		label: locale.promoActiveAllDay,
		value: 'allDay',
	},
	{
		label: locale.promoActiveSpecificTime,
		value: 'specificTime',
	},
];

const maxUsageConfig = [
	{
		label: 'No Limit',
		value: 'unlimited',
	},
	{
		label: 'Specific Number',
		value: 'limited',
	},
];

const activeDay = [
	{
		label: locale.promoActiveEveryDay,
		value: 'everyDay',
	},
	{
		label: locale.promoActiveSpecificDay,
		value: 'specificDay',
	},
];

const dayOptions = Object.values(locale.dayOptions);

const TRANSITION_STYLES = {
	entering: {maxHeight: 100},
	entered:  {maxHeight: 100},
	exiting:  {maxHeight: 0},
	exited:  {maxHeight: 0},
};


const ScheduleModal = ({visible, close, scheduleType, setSchedules, payload, promoConfig, fieldDisabledController}) => {
	// State Section

	const [selectedActiveDaysType, setSelectedActiveDayType] = useState(null);
	const [checkedActiveDays, setCheckedActiveDays] = useState([]);

	const [selectedActiveHours, setSelectedActiveHours] = useState(null);
	const [selectedActiveHoursTime, setSelectedActiveHoursTime] = useState([]);

	const [selectedMaxUsagePerUserType, setSelectedMaxUsagePerUserType] = useState(null);
	const [maxUsagePerUserCount, setMaxUsagePerUserCount] = useState(null);

	const [discountAmount, setDiscountAmount] = useState(null);
	const [discountedAmount, setDiscountedAmount] = useState(null);

	const startHourWatcher = useRef(null);
	const startMinuteWatcher = useRef(null);

	const {selectedDiscountType = {}, selectedFlashSaleMenu = {}} = promoConfig;
	/**
   * If payload is empty, its in Create / Add state
   * else its in Edit / Update state
   */
	const isEdit = !isEmpty(payload);

	// Fn Section
	const validateNumberInput = (e, setterFn, {allowDecimal = false}) => {
		const inputtedValue = e.target.value;
		const regexPattern = allowDecimal ? /^[0-9]*\.?[0-9]*$/ : /^(|[1-9]\d*)$/;

		if (regexPattern.test(inputtedValue)) {
			setterFn(inputtedValue);
		}
	};

	const handleSaveSchedule = () => {
		const tempSchedulePayload = {
			checkedActiveDays,
			selectedActiveHoursTime: [
				moment(selectedActiveHoursTime?.[0])?.format('HH:mm'),
				moment(selectedActiveHoursTime?.[1])?.format('HH:mm'),
			],
			maxUsagePerUserCount,
			discountAmount: Number(discountAmount),
		};

		setSchedules(state => {
			const tempSchedule = cloneDeep(state);
			tempSchedule[scheduleType] = tempSchedulePayload;
			return tempSchedule;
		});
		close();
	};

	const updateFormField = schedulePayload => {
		/**
		 * Fn to clear (new) / populate (edit) form field
		 */

		const isClear = isEmpty(schedulePayload);

		setSelectedActiveDayType(isClear ? null : 'specificDay');
		setCheckedActiveDays(isClear ? [] : schedulePayload?.checkedActiveDays);
		setSelectedActiveHours(isClear ? null : 'specificTime');
		setSelectedActiveHoursTime(
			isClear
				? []
				: [
					moment(schedulePayload?.selectedActiveHoursTime?.[0], 'HH:mm'),
					moment(schedulePayload?.selectedActiveHoursTime?.[1], 'HH:mm'),
				],
		);

		setSelectedMaxUsagePerUserType(
			isClear
				? null
				: schedulePayload?.maxUsagePerUserCount == 0
					? 'unlimited'
					: 'limited',
		);
		setMaxUsagePerUserCount(isClear ? null : schedulePayload?.maxUsagePerUserCount);

		setDiscountAmount(isClear ? null : schedulePayload?.discountAmount);
	};

	const range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	};

	// Lifecycle Section
	useEffect(() => {
		const tempDiscountedAmount = selectedDiscountType === 'net'
			? selectedFlashSaleMenu?.menuPrice - discountAmount
			: (selectedFlashSaleMenu?.menuPrice * (1 - (discountAmount || 0) /100)).toFixed();

		setDiscountedAmount(tempDiscountedAmount);
	}, [selectedDiscountType, discountAmount, selectedFlashSaleMenu]);

	useEffect(() => {
		if (selectedActiveDaysType === 'everyDay') setCheckedActiveDays(Array.from(Array(7).keys()));
		if (selectedActiveHours === 'allDay') setSelectedActiveHoursTime([moment().startOf('day'), moment().endOf('day')]);
		if (selectedMaxUsagePerUserType === 'unlimited') setMaxUsagePerUserCount(0);
	}, [selectedActiveDaysType, selectedActiveHours, selectedMaxUsagePerUserType]);

	useEffect(() => {
		if (!visible) return;
		updateFormField(payload);
	}, [visible]);

	return (
		<Modal
			destroyOnClose
			visible={visible}
			title={(
				<div className='capitalize'>
					{
						(fieldDisabledController
							? locale.ScheduleModal.viewTitle
							: isEdit
								? locale.ScheduleModal.editTitle
								: locale.ScheduleModal.title)
							.replace('{{scheduleType}}',
								scheduleType === 'regular'
									? locale.normalSchedule
									: locale.flashSaleSchedule)
					}
				</div>
			)}
			okText={isEdit ? locale.ScheduleModal.save : locale.ScheduleModal.add}
			onOk={() => handleSaveSchedule()}
			okButtonProps={{
				disabled:
				fieldDisabledController ||
        !checkedActiveDays?.length ||
        !selectedActiveHoursTime?.length ||
				!selectedMaxUsagePerUserType ||
        maxUsagePerUserCount < 0 ||
        !discountAmount ||
        discountedAmount < 0,
			}}
			onCancel={close}
			width={1048}
			centered
		>
			<div className='flex flex-col gap-6'>

				{/* Schedule Active Day Section */}
				<div className='flex'>
					<div className='space-y-2 flex-1 flex flex-col gap-2'>
						<div>{locale.promoActiveDayLabel}</div>
						<Radio.Group
							onChange={e => setSelectedActiveDayType(e.target.value)}
							disabled={fieldDisabledController}
							value={selectedActiveDaysType}
						>
							{activeDay.map(activeDay => (
								<Radio
									key={activeDay.value}
									value={activeDay.value}
								>{activeDay.label}</Radio>
							))}
						</Radio.Group>
						{
							selectedActiveDaysType === 'specificDay' && (
								<Checkbox.Group
									disabled={fieldDisabledController}
									options={dayOptions.map((day, index) => {
										return {
											label: day,
											value: index,
										};
									})}
									value={checkedActiveDays}
									onChange={checkedValues => setCheckedActiveDays(checkedValues)}
								/>
							)
						}
					</div>
				</div>
				{/* End of Schedule Active Day Section */}

				{/* Schedule Active Hour Section */}
				<>
					<div className='flex'>
						<div className='space-y-2 flex-1 flex flex-col gap-2'>
							<div>{locale.promoActiveHourLabel}</div>
							<Radio.Group
								onChange={e => setSelectedActiveHours(e.target.value)}
								value={selectedActiveHours}
								disabled={fieldDisabledController}
							>
								{activeHours.map(activeHour => (
									<Radio
										key={activeHour.value}
										value={activeHour.value}
									>{activeHour.label}</Radio>
								))}
							</Radio.Group>
							{
								selectedActiveHours === 'specificTime' && (
									<TimePicker.RangePicker
										disabledTime={(_, type) => {
											return {
												disabledHours: () => range(0, 24)
													.splice(
														type === 'start' ? 22 : 0,
														(startHourWatcher.current || 0) + 2,
													),
												disabledMinutes: () => type === 'start'
													? null
													: range(0, 60)
														.splice(
															0,
															startMinuteWatcher.current || 0,
														),
												disabledSeconds: (selectedHour, selectedMinute) => {
													if (type === 'start') {
														startHourWatcher.current = selectedHour;
														startMinuteWatcher.current = selectedMinute;
													}
												},
											};
										}}
										value={[selectedActiveHoursTime?.[0], selectedActiveHoursTime?.[1]]}
										disabled={fieldDisabledController}
										onChange={value => {
											if (value === null) {
												setSelectedActiveHoursTime(null);
												startHourWatcher.current = null;
												startMinuteWatcher.current = null;
											} else {
												if (value?.[0] && value?.[1]) {
													const hoursDiff = value[1].diff(value[0], 'hour');

													if (hoursDiff.toFixed() >= 2) {
														setSelectedActiveHoursTime(value);
													}
												}
											}
										}}
										allowClear
										format={'HH:mm'}
										className="w-full"
									/>
								)
							}
						</div>
						<div className='md:flex-1' />
					</div>
				</>
				{/* End of Schedule Active Hour Section */}

				{/* Schedule Max Usage Section */}
				<div className='flex'>
					<div className='space-y-2 flex-1 flex flex-col gap-2'>
						<div>{locale.maxUsagePerUserLabel}</div>
						<Radio.Group
							disabled={fieldDisabledController}
							onChange={e => setSelectedMaxUsagePerUserType(e.target.value)}
							value={selectedMaxUsagePerUserType}
						>
							{maxUsageConfig.map(option => (
								<Radio
									key={option.value}
									value={option.value}
								>{option.label}</Radio>
							))}
						</Radio.Group>
						{
							selectedMaxUsagePerUserType === 'limited' && (
								<Input
									disabled={fieldDisabledController}
									placeholder={locale.maxUsagePerUserPlaceholder}
									value={maxUsagePerUserCount}
									onChange={e => validateNumberInput(e, setMaxUsagePerUserCount, {allowDecimal: false})}
								/>
							)
						}
					</div>
					<div className='md:flex-1' />
				</div>
				{/* End of Schedule Max Usage Section */}

				{/* Scheduled Menu Section */}
				<div className='grid grid-cols-4 gap-6'>

					{/* Menu Name Section */}
					<div className='flex flex-col gap-2'>
						<div>{locale.ScheduleModal.menuNameLabel}</div>
						<div>
							<Input
								value={selectedFlashSaleMenu?.menuLabel}
								disabled
							/>
						</div>
					</div>
					{/* End of Menu Name Section */}

					{/* Menu Original Price Section */}
					<div className='flex flex-col gap-2'>
						<div>{locale.ScheduleModal.originalPriceLabel}</div>
						<div>
							<Input
								value={selectedFlashSaleMenu?.menuPrice}
								disabled
							/>
						</div>
					</div>
					{/* End of Menu Original Price Section */}

					{/* Menu Discount Amount Section */}
					<div className='flex flex-col gap-2'>
						<div>{locale.ScheduleModal.discountAmountLabel}</div>
						<div>
							<Input
								disabled={fieldDisabledController}
								value={discountAmount}
								onChange={e => validateNumberInput(e, setDiscountAmount, {allowDecimal: true})}
								placeholder={locale.ScheduleModal.discountAmountPlaceholder}
								addonAfter={selectedDiscountType === 'percentage' && <PercentageOutlined />}
								addonBefore={selectedDiscountType === 'net' && 'Rp'}
							/>
						</div>
					</div>
					{/* End of Menu Discount Amount Section */}

					{/* Menu Discounted Price Section */}
					<div className='flex flex-col gap-2'>
						<div>{locale.ScheduleModal.discountedPriceLabel}</div>
						<div>
							<Input
								value={discountedAmount}
								disabled
							/>
							<Transition
								in={discountedAmount < 0}
								timeout={500}>
								{state => (
									<div style={{
										transition: `max-height ${500}ms ease-in-out`,
										maxHeight: 0,
										overflow: 'hidden',
										...TRANSITION_STYLES[state],
									}}>
										<Typography.Text
											className='transition-all duration-1000'
											type='danger'
										>{locale.lessThanZeroError}</Typography.Text>
									</div>
								)}
							</Transition>
						</div>
					</div>
					{/* End of Menu Discounted Price Section */}

				</div>
				{/* End of Scheduled Menu Section */}

			</div>
		</Modal>
	);
};

ScheduleModal.defaultProps = {
	visible: false,
	close: () => null,
	setSchedules: () => null,
	payload: {},
	promoConfig: {},
	scheduleType: null,
	fieldDisabledController: false,
};

ScheduleModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	setSchedules: PropTypes.func,
	payload: PropTypes.object,
	promoConfig: PropTypes.object,
	scheduleType: PropTypes.string,
	fieldDisabledController: PropTypes.bool,
};

export default ScheduleModal;