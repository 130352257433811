import {useEffect, useState} from 'react';
import {Route, useLocation, Routes, useNavigate} from 'react-router-dom';

import LoadingPage from 'pages/LoadingPage';

import {Layout} from 'antd';

import Sidebar from 'components/Sidebar';
import GlobalHeader from 'components/GlobalHeader';
import ProtectedComponent from 'components/ProtectedComponent';

import {useDispatch, useSelector} from 'react-redux';
import {setUserData} from 'store/actions/user';
import {setSalesDropdownOption} from 'store/actions/salesDropdownOption';

import axios from 'utils/axios';
import {checkAuth} from 'utils/request/global';
import {handleErrorFetch} from 'utils/utils';
import {AUTH, STORAGE_KEY, USER_ROLES} from 'utils/constants';
import {getDropdownOption} from 'utils/request/sales';

import Moment from 'react-moment';
import jwt_decode from 'jwt-decode';

import isEmpty from 'lodash/isEmpty';

import routes from 'routes';

Moment.startPooledTimer(1000);

function App() {
	const salesDropdownOption = useSelector(state => state.salesDropdownOption);

	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [isFirstTime, setIsFirstTime] = useState(true);

	const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

	const renderRoutes = () => {
		return routes.map((route, i) => {
			const Element = route.element;
			return (
				<Route
					key={i}
					path={route.path}
					exact={route.exact}
					element={route.roles ? (
						<ProtectedComponent
							isRoute
							when={u => u.roles.some(
								role => route.roles.includes(role),
							)}>
							<Element {...route.props} />
						</ProtectedComponent>
					) : <Element {...route.props} />}
				/>
			);
		});
	};

	const handleRedirectToLogin = () => {
		if (location.pathname !== '/login') sessionStorage.setItem(STORAGE_KEY.REDIRECT_TO_PATH, location.pathname);
		navigate('/login', {replace: true});
	};

	const initialize = async () => {
		try {
			const access_token = localStorage.getItem(AUTH.ACCESS_TOKEN);

			if (access_token) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(AUTH.ACCESS_TOKEN)}`;
				isFirstTime && setLoading(true);
				const response = await checkAuth();
				if (!isEmpty(response)) {
					const decodedToken = jwt_decode(access_token);
					dispatch(setUserData({
						...decodedToken.session,
						loggedIn: true,
					}));

					const userRoles = decodedToken.session?.roles;

					if (
						window.location.pathname.includes('sales-dashboard') &&
						isEmpty(salesDropdownOption) &&
						[USER_ROLES.SUPERADMIN, USER_ROLES.FINANCE.EDITOR, USER_ROLES.FINANCE.VIEWER]
							.some(role => userRoles.includes(role))
					) {
						const dropdownOptionResponse = await getDropdownOption();
						dispatch(setSalesDropdownOption(dropdownOptionResponse?.data));
					}

					if (window.location.pathname === '/login') {
						navigate('/', {replace: true});
					}

				} else {
					localStorage.removeItem(AUTH.ACCESS_TOKEN);
					throw {
						message: 'Your session has expired, Please login to continue',
					};
				}
			} else {
				handleRedirectToLogin();
			}
		} catch (error) {
			if (!isEmpty(error) && window.location.pathname !== '/login') handleErrorFetch(error);
			handleRedirectToLogin();
		} finally {
			isFirstTime && setIsFirstTime(false);
			isFirstTime && setLoading(false);
		}
	};

	useEffect(() => {
		initialize();
	}, [location.pathname]);

	return loading
		? <LoadingPage />
		: (
			<div className="App">
				<Layout>
					{
						!['/login', '/preview-co-label'].includes(location.pathname) && (
							<Sidebar
								openSidebar={() => setSidebarCollapsed(true)}
								closeSidebar={() => setSidebarCollapsed(false)}
								sidebarCollapsed={sidebarCollapsed}
							/>
						)
					}
					<Layout className="h-screen">
						{
							!['/login', '/preview-co-label'].includes(location.pathname) && (
								<GlobalHeader
									sidebarCollapsed={sidebarCollapsed}
									toggleSidebar={() => setSidebarCollapsed(!sidebarCollapsed)}
								/>
							)
						}

						<Routes>
							{renderRoutes()}
						</Routes>
					</Layout>
				</Layout>
			</div>
		);
}

export default App;
