import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import LocalPageHeader from 'components/LocalPageHeader';
import PreviousMegaTemplateModal from './PreviousMegaTemplateModal';

import {Alert, Button, Card, DatePicker, Form, Input, Radio, Select, Tooltip, Transfer, notification} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

import {createInternalRequest} from 'utils/request/salesChannel';
import {getMasterlistData} from 'utils/request/masterlist';
import {getPlatformList} from 'utils/request/global';
import {getOutletData} from 'utils/request/outlet';
import {handleErrorFetch} from 'utils/utils';
import {OUTLET_TYPE} from 'utils/constants';

import debounce from 'lodash/debounce';

import localization from 'localization';
const locale = localization.SyncRequest.CreateNewSyncRequest;

const CreateInternalSyncRequest = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const groupingsValue = Form.useWatch('groupings', form);
	const platformsValue = Form.useWatch('platformId', form); // Used to provide value for targetKeys
	const merchantsValue = Form.useWatch('merchantId', form); // Used to provide value for targetKeys

	const [loadingMerchants, setLoadingMerchants] = useState(false);
	const [loading, setLoading] = useState(false);
	const [brandList, setBrandList] = useState([]);
	const [platformList, setPlatformList] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [templateList, setTemplateList] = useState([]);

	const [previousMegaTemplateVisible, setPreviousMegaTemplateVisible] = useState(false);
	const skipFieldValidation = useRef(null);

	const remainingBrands = brandList.filter(brand => {
		return !groupingsValue?.some?.(filter => filter?.brandId === brand.id);
	}).length;

	const handleUsePreviousTemplate = previousTemplateData => {

		const {requestData, templateData} = previousTemplateData;

		const tempTemplateList = [];

		templateData?.groupings.forEach((brand, index) => {
			const menuTemplateEntry = brand?.MenuTemplate;
			if (!tempTemplateList.some(template => template.id === menuTemplateEntry.id)) {
				tempTemplateList[index] = [menuTemplateEntry];
			}
		});

		skipFieldValidation.current = true;

		form.setFieldsValue({
			platformId: requestData?.platformId,
			merchantId: requestData?.merchantId,
			groupings: templateData?.groupings?.map(brand => {
				return {
					brandId: brand.brandId,
					locationFilter: {
						type: brand?.details?.locationFilter?.type,
						locationIds: brand?.details?.locationFilter?.type === 'default' ? [] : brand?.details?.locationFilter?.locationIds,
					},
					menuTemplateId: brand.menuTemplateId,
				};
			}),
		});

		setTemplateList(tempTemplateList);
		setPreviousMegaTemplateVisible(false);

		skipFieldValidation.current = false;
	};

	const adjustableLocations = () => {
		const uniqueLocations = [];

		for (const item of outletList) {
			if (merchantsValue.includes?.(item.id)) {
				const location = item.Location;
				if (!uniqueLocations.some(loc => loc.id === location.id)) {
					uniqueLocations.push(location);
				}
			}
		}

		return uniqueLocations;
	};

	const defaultLocationTypeHandler = payload => {
		payload.groupings.forEach(group => {
			if (group.locationFilter.type === 'default') {
				group.locationFilter.locationIds = [-1];
			}
		});

		return payload.groupings;
	};

	const handleSubmit = async e => {
		try {
			defaultLocationTypeHandler(e);
			e.syncDate = e.syncDate.startOf('minute'); // Set sync time second to 0
			setLoading(true);
			const response = await createInternalRequest(e);
			if (response.success) {
				navigate('/sales-channel/sync-request');
				notification.open({
					message: locale.Notification.Success.message,
					description: locale.Notification.Success.description,
					type: 'success',
				});
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchBrands = async () => {
		try {
			const response = await getOutletData({
				search: {limit: 0, isActive: true},
			}, OUTLET_TYPE.BRAND);
			if (response.success) {
				setBrandList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const fetchPlatform = async () => {
		try {
			const response = await getPlatformList();
			if (response.success) {
				setPlatformList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const updateStateAtIndex = (index, newValue) => {
		setTemplateList(prevData => {
			let newArray = [...prevData];
			if (index >= newArray.length) {
				newArray = newArray.concat(new Array(index + 1 - newArray.length).fill([]));
			}
			newArray[index] = newValue;
			return newArray;
		});
	};

	const searchMenuTemplate = async (label, index) => {
		try {
			if (!label) return;
			const response = await getMasterlistData({
				search: {label},
				limit: 10,
				sortOrder: 'desc',
				sortBy: 'createdAt',
				isDeleted: false,
				type: 'single',
			}, 'menu-template');
			if (response.success) {
				updateStateAtIndex(index, response.data.rows);
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const getMerchants = async () => {
		try {
			setLoadingMerchants(true);
			const response = await getOutletData({limit: 0, search: {platformId: platformsValue, isActive: true}}, OUTLET_TYPE.MERCHANT);
			if (response.success) {
				setOutletList(response.data.rows);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingMerchants(false);
		}
	};

	const validateSimilarValues = (array1, array2) => {
		if (array1.length !== array2.length) {
			return false; // Arrays have different lengths, not similar
		}

		const sortedArray1 = array1.sort();
		const sortedArray2 = array2.sort();

		for (let i = 0; i < sortedArray1.length; i++) {
			if (sortedArray1[i] !== sortedArray2[i]) {
				return false; // Values at corresponding indices are not equal, not similar
			}
		}

		return true; // All values match, arrays are similar
	};

	const validateMerchants = () => {
		// Check if currently selected merchantIds is exist in outletList
		if (merchantsValue?.length) {
			const ids = outletList.map(obj => obj.id);
			const validIds = merchantsValue.filter(obj => ids.includes(obj));

			if (!validateSimilarValues(merchantsValue, validIds)) {
				form.setFieldsValue({
					merchantId: validIds,
				});
			}
		}
	};

	const removeUnselectedLocation = arrayToFilter => {
		const flattenLocationSource = adjustableLocations();

		const validLocationIds = new Set(flattenLocationSource.map(item => item.id));

		arrayToFilter.forEach(item => {
			const locationFilter = item.locationFilter;
			locationFilter.locationIds = locationFilter.locationIds.filter(locationId => validLocationIds.has(locationId));
		});

		return arrayToFilter;
	};

	const removeBrandGroup = (removeFn, index) => {
		setTemplateList(prevData => prevData.filter((_, i) => i !== index));
		removeFn();
	};

	const clearSelectedMerchant = () => {
		// Clean up selected merchant when platform is changed
		form.setFieldsValue({
			groupings: groupingsValue?.map?.(group => {
				return {
					...group,
					locationFilter: {
						...group?.locationFilter,
						locationIds: [],
					},
				};
			}),
			merchantId: [],
		});
	};

	const debounceFn = useCallback(debounce(searchMenuTemplate, 500), []);

	const initialFetch = () => {
		fetchBrands();
		fetchPlatform();
	};

	useEffect(() => {
		if (!platformsValue) return;
		getMerchants();
	}, [platformsValue]);

	useEffect(() => {
		initialFetch();
	}, []);

	useEffect(() => {
		if (skipFieldValidation.current) return;
		validateMerchants();
		if (groupingsValue?.length) {
			form.setFieldsValue({
				groupings: removeUnselectedLocation(groupingsValue),
			});
		}
	}, [merchantsValue]);

	return (
		<div className="bg-white h-full overflow-auto pb-10">
			<PreviousMegaTemplateModal
				visible={previousMegaTemplateVisible}
				onClose={() => setPreviousMegaTemplateVisible(false)}
				onSelectTemplate={handleUsePreviousTemplate}
			/>
			<LocalPageHeader
				headerTitle={locale.headerInternal}
				showBackButton
			/>
			<Card
				title={locale.title}
				className='m-6'
			>
				<div className='flex flex-col gap-6'>
					<Alert
						message={locale.info}
						type="info"
						showIcon
					/>

					{/* Select Template */}
					<div className='flex'>
						<div className='space-y-2 flex-1'>
							<div>{locale.megaMenuTemplateLabel}</div>
							<Button
								onClick={() => setPreviousMegaTemplateVisible(true)}
								type='primary'
								className='px-8'>
								{locale.usePreviousApprovalId}
							</Button>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End of Select Template */}

					<Form
						form={form}
						layout="vertical"
						name="internalSyncForm"
						scrollToFirstError
						onFinish={e => handleSubmit(e)}
						onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
						requiredMark={false}
						initialValues={{
							locations: [],
							merchantId: [],
						}}
					>

						{/* Sync Time */}
						<Form.Item
							label={locale.syncTimeLabel}
							name="syncDate"
							rules={[{required: true}]}
						>
							<DatePicker
								format={['DD-MM-YYYY HH:mm']}
								showTime={{
									hideDisabledOptions: true,
									format: 'DD-MMM-YYYY HH:mm',
								}}
							/>
						</Form.Item>

						{/* Sync Platform */}
						<Form.Item
							label={locale.syncPlatformLabel}
							name="platformId"
							rules={[{required: true}]}
						>
							<Radio.Group onChange={() => clearSelectedMerchant()}>
								{
									platformList.map(platform => (
										<Radio
											key={platform.id}
											value={platform.id}>{platform.label}</Radio>
									))
								}
							</Radio.Group>
						</Form.Item>

						{/* Sync Location */}
						<Form.Item
							label={locale.outletLabel}
							name="merchantId"
							rules={[{required: true}]}
						>
							<Transfer
								disabled={loadingMerchants}
								listStyle={{flexGrow: '1', height: 350}}
								onChange={() => validateMerchants()}
								dataSource={outletList.map(e => ({
									...e,
									key: e.id,
								}))}
								targetKeys={merchantsValue}
								titles={[locale.allOutlet, locale.selectedOutlet]}
								render={item => item.label}
								showSearch
								filterOption={(input, option) => {
									return option.label.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
								}}
							/>
						</Form.Item>

						<div className='pb-6'>{locale.createMegaMenuTemplate}</div>

						{/* Sync Brand */}
						<Form.List
							name="groupings"
						>
							{(fields, {add, remove}) => (
								<>
									{fields.map(({key, name, ...restField}, index) => (
										<div
											key={key}
											className='grid sm:grid-cols-3 lg:grid-cols-5 gap-x-4'>
											<Form.Item
												{...restField}
												label='Brand Name'
												name={[name, 'brandId']}
												rules={[
													{
														required: true,
													},
												]}
											>
												<Select
													showSearch
													filterOption={(input, option) => {
														return option.label.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
													}}
													placeholder="Select brand"
													options={brandList.map(brand => {
														return {
															label: brand.label,
															value: brand.id,
															disabled: groupingsValue?.some?.(selectedBrand => selectedBrand?.brandId === brand.id),
														};
													})}
												/>
											</Form.Item>
											<Form.Item
												{...restField}
												label='Menu Template'
												name={[name, 'menuTemplateId']}
												rules={[
													{
														required: true,
														message: 'Please select menu template',
													},
												]}
											>
												<Select
													showSearch
													onSearch={val => debounceFn(val, index)}
													placeholder="Select menu template"
													filterOption={((_, option) => option)}
													options={templateList[index]?.map?.(template => {
														return {
															label: `[${template.id}] ${template.label}`,
															value: template.id,
														};
													})}
												/>
											</Form.Item>
											<Form.Item
												{...restField}
												label='Outlet Settings Type'
												name={[name, 'locationFilter', 'type']}
											>
												<Select
													showSearch
													placeholder="Select menu template"
													options={[
														{
															label: 'Default',
															value: 'default',
														},
														{
															label: 'Only on Outlet',
															value: 'include',
														},
														{
															label: 'Except from Outlet',
															value: 'exclude',
														},
													]}
												/>
											</Form.Item>
											<Form.Item
												{...restField}
												label='Selected Outlet'
												name={[name, 'locationFilter', 'locationIds']}
											>
												<Select
													mode='multiple'
													placeholder='Default'
													maxTagCount={2}
													disabled={groupingsValue?.[index]?.locationFilter?.type === 'default'}
													optionLabelProp="label"
													filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLocaleLowerCase())}
												>
													{adjustableLocations().map(location => (
														<Select.Option
															label={location.label}
															value={location.id}
															key={location.id}>
															{location.label}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
											<Form.Item
												label=' '
											>
												<Button
													icon={<DeleteOutlined />}
													onClick={() => removeBrandGroup(() => remove(name), index)}
													danger>Delete</Button>
											</Form.Item>
										</div>
									))}
									<Form.Item>
										<Tooltip title={merchantsValue?.length === 0 ? locale.addBrandTooltip : ''}>
											<Button
												disabled={merchantsValue?.length === 0 || remainingBrands === 0}
												onClick={() => add({
													brandId: undefined,
													menuTemplateId: undefined,
													locationFilter: {
														type: 'default',
														locationIds: [],
													},
												})}
											>
												Add Brand
											</Button>
										</Tooltip>
									</Form.Item>
								</>
							)}
						</Form.List>

						{/* Menu Menu Template Name */}
						<Form.Item
							className='w-80'
							label={locale.megaMenuTemplateName}
							name="menuTemplateLabel"
							rules={[{required: true}]}
						>
							<Input placeholder={locale.megaMenuTemplateNamePlaceholder} />
						</Form.Item>

						<div className='flex justify-end gap-2'>
							<Button htmlType='submit'>
								{locale.cancel}
							</Button>
							<Button
								disabled={loading}
								type='primary'
								htmlType='submit'>
								{locale.create}
							</Button>
						</div>
					</Form>
				</div>
			</Card>
		</div>
	);
};

export default CreateInternalSyncRequest;