import React, {forwardRef, useEffect, useState} from 'react';

import {Divider} from 'antd';
import ProTable from '@ant-design/pro-table';

import {PAYMENT_METHOD_ACTION_TYPE} from 'utils/constants';
import {getPaymentType, getPosPayment} from 'utils/request/posSetting';
import {ellipsisItem, handleErrorFetch, momentDateFormat, removeEmptyFields} from 'utils/utils';

import localization from 'localization';
const locale = localization.POSSetting.Payment.Table;

const PaymentTable = forwardRef((_, ref) => {
	const [paymentType, setPaymentType] = useState([]);

	const sortDirectionConverter = direction => {
		switch (direction) {
		case 'ascend':
			return 'asc';
		case 'descend':
			return 'desc';
		default:
			return 'desc';
		}
	};

	const columns = [
		{
			key: 'name',
			dataIndex: 'name',
			title: locale.paymentName,
			width: 135,
		},
		{
			key: 'type',
			dataIndex: 'type',
			title: locale.paymentType,
			width: 148,
			valueEnum: paymentType.reduce((acc, item) => {
				acc[item] = {text: item};
				return acc;
			}, {}),
		},
		{
			key: 'group',
			dataIndex: ['group', 'name'],
			title: locale.paymentGroup,
			width: 148,
			search: false,
		},
		{
			key: 'actionType',
			dataIndex: 'actionType',
			title: ellipsisItem(locale.actionType),
			search: false,
			width: 148,
			render: actionType => PAYMENT_METHOD_ACTION_TYPE
				.find(paymentMethodActionType => paymentMethodActionType.value == actionType)?.label || '-',
		},
		{
			key: 'isActive',
			dataIndex: 'isActive',
			title: locale.active,
			width: 109,
			valueEnum: {
				all: {text: 'All', status: 'Default'},
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isActive ? 'text-green-400' : 'text-red-400'}`
				}>{record.isActive ? 'Yes' : 'No'}</div>
			),
		},
		{
			key: 'createdBy',
			dataIndex: 'createdBy',
			title: locale.createdBy,
			width: 155,
		},
		{
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: locale.createdAt,
			width: 195,
			search: false,
			render: createdAt => momentDateFormat({dateString: createdAt, format: 'DD MMM YYYY - HH:mm'}),
			sorter: true,
			defaultSortOrder: 'descend',
		},
		{
			key: 'editedBy',
			dataIndex: 'editorBy',
			title: locale.editedBy,
			width: 155,
		},
		{
			key: 'editedAt',
			dataIndex: 'updatedAt',
			title: locale.editedAt,
			width: 195,
			search: false,
			render: updatedAt => momentDateFormat({dateString: updatedAt, format: 'DD MMM YYYY - HH:mm'}),
			sorter: true,
		},
		{
			key: 'action',
			title: locale.action,
			fixed: 'right',
			align: 'center',
			search: false,
			render: (_, record) => (
				<div className="text-center flex gap-2 items-center justify-center text-antd-blue-6">
					<a
						href={`/pos-settings/payment/${record.id}/edit`}
					>
						{locale.edit}
					</a>
					<Divider type='vertical' />
					<a
						href={`/pos-settings/payment/${record.id}/detail`}
					>
						{locale.detail}
					</a>
				</div>
			),
		},
	];

	const fetchTableData = async (params, sorter) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				sortBy: Object.keys(sorter)?.[0] || 'createdAt',
				sortOrder: sortDirectionConverter(Object.values(sorter)?.[0] || 'desc'),
				name: params.name,
				type: params.type,
				createdBy: params.createdBy,
				editedBy: params.editedBy,
			};

			if (params.isActive === 'active') {
				payload.isActive = true;
			} else if (params.isActive === 'inactive') {
				payload.isActive = false;
			}

			// if (params.traceNumberRequired === 'true') {
			// 	payload.traceNumberRequired = true;
			// } else if (params.traceNumberRequired === 'false') {
			// 	payload.traceNumberRequired = false;
			// }

			removeEmptyFields(payload);

			const response = await getPosPayment(({params: payload}));
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const initialFetch = async () => {
		try {
			const typeResponse = await getPaymentType();
			if (typeResponse.success) {
				setPaymentType(typeResponse.data);
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		initialFetch();
	}, []);

	return (
		<ProTable
			actionRef={ref}
			className='ResetSearchTableMargin px-6'
			rowKey='id'
			columns={columns}
			request={fetchTableData}
			toolBarRender={false}
			scroll={{x: 'max-content'}}
			search={{
				layout: 'vertical',
			}}
			pagination={{
				defaultPageSize: 25,
				showSizeChanger: true,
				showQuickJumper: true,
				pageSizeOptions: ['10', '25', '50', '75', '100'],
			}}
		/>
	);
});

export default PaymentTable;