import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import PromoForm from './PromoForm';
import SelectPromoType from './SelectPromoType';

const ExternalPromoForm = ({isEditing}) => {
	const location = useLocation();
	const initialPromoType = location.state?.promoType;
	const [promoType, setPromoType] = useState(initialPromoType || null);

	return promoType
		? (
			<PromoForm
				setPromoType={setPromoType}
				promoType={promoType}
				isEditing={isEditing}
			/>
		)
		: (
			<SelectPromoType
				setPromoType={setPromoType}
			/>
		);
};

ExternalPromoForm.defaultProps = {
	isEditing: false,
};

ExternalPromoForm.propTypes = {
	isEditing: PropTypes.bool,
};

export default ExternalPromoForm;