import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Input, Modal, Select, Typography, notification} from 'antd';

import localization from 'localization';
import {handleErrorFetch} from 'utils/utils';
import {voidPosOrder} from 'utils/request/watchtower';

const VOID_REASON = [
	{
		'value': 'DC-DSP',
		'desc': 'Order di-pick up lalu di-cancel oleh driver',
	},
	{
		'value': 'DC-DTD',
		'desc': 'Driver/Pelanggan tidak datang',
	},
	{
		'value': 'DC-DCQ',
		'desc': 'Driver cancel karena antrian',
	},
	{
		'value': 'DSH',
		'desc': 'Double struk (Hangry App error system)',
	},
	{
		'value': 'DSF',
		'desc': 'Double struk fresto',
	},
	{
		'value': 'OOS',
		'desc': 'Menu OOS sudah terlanjur di-input',
	},
	{
		'value': 'KOL',
		'desc': 'Perubahan/Cancel KOL',
	},
	{
		'value': 'KSI',
		'desc': 'Kasir salah input',
	},
	{
		'value': 'PSP',
		'desc': 'Pelanggan salah pesan',
	},
	{
		'value': 'Another Reason',
		'desc': 'Fill the reason manually',
	},
];

const VoidModal = ({open, orderData, onClose, tableFormRef}) => {
	const locale = localization.WatchTower.WatchTowerOrder.voidModal;

	const [voidCode, setVoidCode] = useState(null);
	const [voidReason, setVoidReason] = useState(null);
	const [loading, setLoading] = useState(false);

	const customReason = voidCode === 'Another Reason';

	const handleVoidOrder = async () => {
		try {
			setLoading(true);
			const response  = await voidPosOrder({id: orderData.id, reason: voidReason || voidCode});
			if (response.success) {
				tableFormRef.current?.submit?.();
				notification.success({
					message: locale.Notification.Success.message,
					description: locale.Notification.Success.description.replace('{{billNumber}}', orderData.billNumber),
				});
				onClose();
			}
		} catch (error) {
			handleErrorFetch(error, {
				message: locale.Notification.Failed.message,
				description: locale.Notification.Failed.description.replace('{{billNumber}}', orderData.billNumber),
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (open) {
			setVoidCode(null);
			setVoidReason(null);
		}
	}, [open]);

	return (
		<Modal
			visible={open}
			centered
			closable={false}
			width={572}
			onOk={handleVoidOrder}
			onCancel={onClose}
			cancelText={locale.cancel}
			okText={locale.ok}
			okButtonProps={{
				disabled: !voidCode || customReason && !voidReason,
				loading,
			}}
			cancelButtonProps={{
				loading,
			}}
		>
			<div className='flex flex-col gap-4'>
				<div>
					<Typography.Title
						level={5}
						className='m-0'>
						{locale.title}
					</Typography.Title>
					<Typography.Text type='secondary'>
						{locale.subtitle}
					</Typography.Text>
				</div>
				<div>
					<Typography.Text>
						{locale.voidReasonLabel}
					</Typography.Text>
					<Select
						className='w-full'
						placeholder={locale.voidReasonPlaceholder}
						onChange={e => setVoidCode(e)}
						options={VOID_REASON.map(reason => {
							return {
								value: reason.value,
								label: `${reason.value} · ${reason.desc}`,
							};
						})}
					/>
					{
						customReason && (
							<Input.TextArea
								className='mt-4'
								showCount
								maxLength={100}
								value={voidReason}
								onChange={e => setVoidReason(e.target.value)}
								placeholder={locale.voidReasonPlaceholder}
							/>
						)
					}
				</div>
			</div>
		</Modal>
	);
};

VoidModal.defaultProps = {
	open: false,
	orderData: {},
	onClose: () => {},
	tableFormRef: {},
};

VoidModal.propTypes = {
	open: PropTypes.bool,
	orderData: PropTypes.object,
	onClose: PropTypes.func,
	tableFormRef: PropTypes.object,
};


export default VoidModal;