import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {cancelApprovalRequest, getMenuSyncRequest} from 'utils/request/salesChannel';
import {requestCardPayloadConverter} from 'utils/utils';
import {STORAGE_KEY} from 'utils/constants';

import InfiniteScroll from 'react-infinite-scroller';
import {Empty, Skeleton, Tabs, Spin, Input, notification, Button} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import RequestCard from 'components/RequestCard';
const {TabPane} = Tabs;
const {Search} = Input;

const TABS_CONFIG = [
	{id: 'pending', label: 'Waiting Approval'},
	{id: 'processed', label: 'Approved'},
	{id: 'rejected', label: 'Rejected'},
];

import localization from 'localization';
const locale = localization.SyncRequest.ApprovalCard;

const SyncRequestBody = ({containerRef, refetchRequestRef}) => {
	const [activeTabs, setActiveTabs] = useState('pending');
	const [loading, setLoading] = useState(false);

	const [pendingReq, setPendingReq] = useState([]);
	const [pendingReqCount, setPendingReqCount] = useState(0);
	const [pendingReqHasMore, setPendingReqHasMore] = useState(true);

	const [approvedReq, setApprovedReq] = useState([]);
	const [approvedReqCount, setApprovedReqCount] = useState(0);
	const [approvedReqHasMore, setApprovedReqHasMore] = useState(true);

	const [rejectedReq, setRejectedReq] = useState([]);
	const [rejectedReqCount, setRejectedReqCount] = useState(0);
	const [rejectedReqHasMore, setRejectedReqHasMore] = useState(true);

	const [menuTemplateLabel, setMenuTemplateLabel] = useState(null);

	const sortOrder = activeTabs === 'pending' ? 'asc' : 'desc';

	const fetchCards = async searchLabel => {
		setLoading(true);
		const pendingItem = await getMenuSyncRequest({menuTemplateLabel: searchLabel, status: 'pending', sortOrder});
		const approvedItem = await getMenuSyncRequest({menuTemplateLabel: searchLabel, status: 'processed,approved'});
		const rejectedItem = await getMenuSyncRequest({menuTemplateLabel: searchLabel, status: 'rejected'});

		setPendingReqCount(pendingItem?.count);
		setApprovedReqCount(approvedItem?.count);
		setRejectedReqCount(rejectedItem?.count);

		setPendingReq(pendingItem?.rows);
		setApprovedReq(approvedItem?.rows);
		setRejectedReq(rejectedItem?.rows);

		let pendingHasMore = true;
		let approvedHasMore = true;
		let rejectedHasMore = true;

		if (pendingItem?.count === pendingItem?.rows.length) {
			pendingHasMore = false;
		}

		if (approvedItem?.count === approvedItem?.rows.length) {
			approvedHasMore = false;
		}

		if (rejectedItem?.count === rejectedItem?.rows.length) {
			rejectedHasMore = false;
		}

		setPendingReqHasMore(pendingHasMore);
		setApprovedReqHasMore(approvedHasMore);
		setRejectedReqHasMore(rejectedHasMore);

		setLoading(false);
	};

	const getCardState = tabId => {
		switch (tabId) {
		case TABS_CONFIG[0].id:
			return pendingReq;
		case TABS_CONFIG[1].id:
			return approvedReq;
		case TABS_CONFIG[2].id:
			return rejectedReq;
		default:
			return pendingReq;
		}
	};

	const getCardCountFromConfig = index => {
		switch (index) {
		case 0:
			return pendingReqCount;
		case 1:
			return approvedReqCount;
		case 2:
			return rejectedReqCount;
		default:
			return pendingReqCount;
		}
	};

	const getStateFromTabId = tabId => {
		switch (tabId) {
		case 'pending':
			return {
				item: {
					state: pendingReq,
					setter: setPendingReq,
				},
				count: {
					state: pendingReqCount,
					setter: setPendingReqCount,
				},
				hasMore: {
					state: pendingReqHasMore,
					setter: setPendingReqHasMore,
				},
			};
		case 'processed':
			return {
				item: {
					state: approvedReq,
					setter: setApprovedReq,
				},
				count: {
					state: approvedReqCount,
					setter: setApprovedReqCount,
				},
				hasMore: {
					state: approvedReqHasMore,
					setter: setApprovedReqHasMore,
				},
			};
		case 'rejected':
			return {
				item: {
					state: rejectedReq,
					setter: setRejectedReq,
				},
				count: {
					state: rejectedReqCount,
					setter: setRejectedReqCount,
				},
				hasMore: {
					state: rejectedReqHasMore,
					setter: setRejectedReqHasMore,
				},
			};
		default:
			return {
				item: {
					state: pendingReq,
					setter: setPendingReq,
				},
				count: {
					state: pendingReqCount,
					setter: setPendingReqCount,
				},
				hasMore: {
					state: pendingReqHasMore,
					setter: setPendingReqHasMore,
				},
			};
		}
	};

	const handleLoadMore = async tabId => {
		if (tabId !== activeTabs) return;
		const {item, count, hasMore} = getStateFromTabId(tabId);
		if (['approved', 'processed'].some(item => item === tabId)) tabId = 'processed,approved';

		const payload = {menuTemplateLabel: menuTemplateLabel, status: tabId, offset: item.state.length, sortOrder};
		const response = await getMenuSyncRequest(payload);

		const tempReqValue = item.state.concat(response.rows);

		if (tempReqValue.length >= count.state) {
			hasMore.setter(false);
		}

		item.setter(tempReqValue);
	};

	const getHasMoreInfo = tabId => {
		switch (tabId) {
		case 'pending':
			return pendingReqHasMore;
		case 'processed':
			return approvedReqHasMore;
		case 'rejected':
			return rejectedReqHasMore;
		default:
			return pendingReqHasMore;
		}
	};

	const handleCancel = async cardId => {
		try {
			const response = await cancelApprovalRequest(cardId);
			if (response.success) {
				notification.open({
					message: locale.Notification.Cancel.Success.message,
					description: locale.Notification.Cancel.Success.description,
					type: 'success',
				});
			}
			fetchCards();
		} catch (error) {
			notification.open({
				message: locale.Notification.Cancel.Failed.message,
				description: locale.Notification.Cancel.Failed.description,
				type: 'warning',
			});
		}
	};

	const handleReview = cardData => {
		if (cardData?.type === 'internal') {
			window.open(`/sales-channel/sync-request/free-item/${cardData.id}`, '_blank');
		} else {
			sessionStorage.setItem(STORAGE_KEY.MASTERLIST_MENU_REQUEST_DETAIL, JSON.stringify(cardData));
			window.open('/sales-channel/sync-request/review-template', '_blank');
		}

	};

	const handleCopyRequest = cardData => {
		sessionStorage.setItem(STORAGE_KEY.MASTERLIST_MENU_REQUEST_DETAIL, JSON.stringify(cardData));
		window.open('/sales-channel/sync-request/new', '_blank');
	};

	useEffect(async () => {
		await fetchCards(menuTemplateLabel);
	}, [menuTemplateLabel]);

	useEffect(() => {
		refetchRequestRef.current = () => {
			setActiveTabs('pending');
			fetchCards();
		};
	}, []);

	return (
		<div className='px-6 pb-20 flex-1'>
			<Tabs
				tabBarExtraContent={(
					<Search
						placeholder="Search by Menu Template Label"
						allowClear
						onSearch={value => setMenuTemplateLabel(value.length ? value : null)}
					/>
				)}
				onTabClick={key => {
					switch (key) {
					case 'pending':
						setActiveTabs('pending');
						break;
					case 'processed':
						setActiveTabs('processed');
						break;
					case 'rejected':
						setActiveTabs('rejected');
						break;
					default:
						setActiveTabs('pending');
						break;
					}
				}}
				activeKey={activeTabs}
			>
				{TABS_CONFIG.map((tab, index) => (
					<TabPane
						tab={(
							<div className='flex gap-2'>
								<div>{tab.label}</div>
								<span className="inline-flex items-center justify-center px-2 py-1 text-xs font-medium leading-none bg-antd-blue-6 text-blue-50 rounded-full">{getCardCountFromConfig(index)}</span>
							</div>
						)}
						key={tab.id}
					>
						{
							loading
								? (
									<div className='grid grid-cols-2 gap-6 pb-4'>
										<div className='border pt-6 px-8 pb-8 rounded-md overflow-hidden shadow-md'>
											<Skeleton
												active
												paragraph={{rows: 5}} />
										</div>
									</div>
								)
								:
								getCardState(tab.id).length
									?
									(
										<InfiniteScroll
											pageStart={0}
											threshold={0}
											loadMore={() => handleLoadMore(tab.id)}
											hasMore={getHasMoreInfo(tab.id)}
											loader={(
												<div
													className="text-center mt-6"
													key={0}>
													<Spin size='large' />
												</div>
											)}
											getScrollParent={() => containerRef.current}
											useWindow={false}
										>
											<div className='grid grid-cols-2 gap-6 pb-4'>
												{
													getCardState(tab.id).map((card,index) => (
														<RequestCard
															key={index}
															cardData={requestCardPayloadConverter(card)}
															cancelAction={() => handleCancel(card.id)}
															reviewAction={() => handleReview(card)}
															extra={() => {
																return card?.type === 'external'
																	? (
																		<Button
																			onClick={() => handleCopyRequest(card)}
																			icon={<EditOutlined />}>{locale.copy}</Button>
																	)
																	: null;
															}}
															localeConfig={{
																idType: locale.idType,
																actionType: locale.actionType,
																itemType: locale.itemType,
															}}
														/>
													))
												}
											</div>
										</InfiniteScroll>
									)
									: <div className='pt-12'><Empty /></div>
						}
					</TabPane>
				))}

			</Tabs>
		</div>
	);
};

SyncRequestBody.defaultProps = {
	containerRef: {},
	refetchRequestRef: {},
};

SyncRequestBody.propTypes = {
	containerRef: PropTypes.object,
	refetchRequestRef: PropTypes.object,
};

export default SyncRequestBody;
