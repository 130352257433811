import axios from 'utils/axios';
import qs from 'qs';
import {MASTERLIST_TYPE, URLS} from 'utils/constants';
import {determineCMSURL} from 'utils/utils';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const getMasterlistData = async (query, masterlistType) => {
	try {
		const {limit, page, search, sortBy, sortOrder, type = 'single'} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		if (masterlistType === 'menu-template') params.type = type;
		if (masterlistType === MASTERLIST_TYPE.MENU) params.isChildMenu = false;
		if (masterlistType === MASTERLIST_TYPE.CHILD_MENU) params.isChildMenu = true;
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/${determineCMSURL(masterlistType)}`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getMenuTemplateDraft = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/menu-template/draft`,
			params: {
				limit,
				offset: Number((page) * limit) || null,
				...search,
				sortBy,
				sortOrder,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getUploadSignedURL = async ({uploadType, filename, fileType, allowPublic = true, fixedName = false}) => {
	try {
		const params = {
			uploadType,
			filename,
			allowPublic,
			fixedName,
		};
		if (fileType) params.fileType = fileType;
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/upload/signedURL`,
			params: params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export function uploadMasterMenu(url, file) {
	return new Promise((res, rej) => {
		let percentComplete = 0;
		const xhr = new XMLHttpRequest();
		xhr.open('PUT', url, true);
		xhr.onload = () => {
			const {status, readyState} = xhr;
			if (readyState === XMLHttpRequest.DONE) {
				console.log(xhr);
			}
			if (status === 200) {
				res(file);
				console.log('uploaded');
			} else {
				console.error('Failed to upload');
				rej(new Error('Failed to upload'));
			}
		};
		xhr.onerror = e => {
			crashLogger.error(e);
			rej(e);
		};

		if (xhr.upload) {
			xhr.upload.onprogress= evt => {
				if (evt.lengthComputable) {
					percentComplete = Math.ceil((evt.loaded / evt.total) * 100);
					const progressbarEl = document.getElementById('upload-progressbar');
					if (progressbarEl) {
						progressbarEl.style.width = `${percentComplete}%`;
					}
				}
			};
		}
		xhr.setRequestHeader('Content-Type', file.type);
		xhr.send(file);
	});
}

export const getUploadFileSummary = async ({uploadId, masterlistType, isChild,  uploadParams}) => {
	try {
		let endpointUrl = '';
		switch (masterlistType) {
		case 'sync-request':
			endpointUrl = `${baseURL}/sync/request/upload`;
			break;
		case 'pos':
			endpointUrl = `${baseURL}/finance/transaction/pos/upload`;
			break;
		case 'settlement':
			endpointUrl = `${baseURL}/finance/transaction/settlement/upload`;
			break;
		case 'pos-settings-payment':
			endpointUrl = `${baseURL}/pos/payment-methods/upload`;
			break;
		case 'pos-settings-sales-channel':
			endpointUrl = `${baseURL}/pos/sales-channels/upload`;
			break;
		case 'pos_user':
			endpointUrl = `${baseURL}/pos/user/upload`;
			break;
		default:
			endpointUrl = `${baseURL}/cms/${determineCMSURL(masterlistType)}/${isChild ? 'child/' : ''}upload`;
			break;
		}
		const {data} = await axios({
			method: 'POST',
			headers: {'content-type': 'application/x-www-form-urlencoded'},
			url: endpointUrl,
			data: qs.stringify({
				uploadId,
				...uploadParams,
			}),
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const updateMasterlist = async ({uploadId, masterlistType, isChild, importType}) => {
	try {
		const {data} = await axios({
			method: 'POST',
			headers: {'content-type': 'application/x-www-form-urlencoded'},
			url: `
			${baseURL}/cms/
			${determineCMSURL(masterlistType)}/
			${(importType === 'update_menu' && masterlistType === MASTERLIST_TYPE.MATERIAL) ? 'menu/' : ''}
			${isChild ? 'child/' : ''}update
			${masterlistType === MASTERLIST_TYPE.MATERIAL ? '/upload' : ''}
			`,
			data: qs.stringify({
				uploadId,
			}),
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const submitToOrderhub = async (uploadId, masterlistType, importType, labelName, isChild) => {
	try {
		const basePayload = {uploadId};
		if (labelName) {
			basePayload.label = labelName;
		}
		let endpointUrl = '';
		switch (masterlistType) {
		case 'sync-request':
			endpointUrl = `${baseURL}/sync/request/process`;
			break;
		case 'pos-settings-payment':
			endpointUrl = `${baseURL}/pos/payment-methods`;
			break;
		case 'pos-settings-sales-channel':
			endpointUrl = `${baseURL}/pos/sales-channels`;
			break;
		case 'pos_user':
			endpointUrl = `${baseURL}/pos/user`;
			break;
		default:
			endpointUrl = `${baseURL}/cms/${determineCMSURL(masterlistType)}`;
			break;
		}
		if (isChild) endpointUrl+= '/child';
		if (importType === 'update_menu' && masterlistType === MASTERLIST_TYPE.MATERIAL) endpointUrl += '/menu';
		if (importType.includes('update')) endpointUrl += '/update/process';
		else endpointUrl += '/process';
		const {data} = await axios({
			method: 'POST',
			headers: {'content-type': 'application/x-www-form-urlencoded'},
			url: endpointUrl,
			data: qs.stringify(basePayload),
		});
		return data?.success;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const exportMasterlist = async ({sortBy, sortOrder, search, id}, masterlistType) => {
	try {
		const params = id ? {id} : {
			...search,
			sortBy,
			sortOrder,
		};
		if (masterlistType === 'child-menu') params.isChildMenu = true;
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/${determineCMSURL(masterlistType)}/export`,
			params: params,
		});
		if (data.success) {
			const blobResponse = await (await fetch(data.data.url)).blob();
			return blobResponse;
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteMenuTemplate = async menuTemplateId => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			headers: {'content-type': 'application/x-www-form-urlencoded'},
			url: `${baseURL}/cms/menu-template`,
			data: qs.stringify({
				id: menuTemplateId,
			}),
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteMenuTemplateDraft = async menuTemplateId => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			headers: {'content-type': 'application/x-www-form-urlencoded'},
			url: `${baseURL}/cms/menu-template/draft`,
			data: qs.stringify({
				id: menuTemplateId,
			}),
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createMasterlistItem = async (payload, type) => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/${type}`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editMasterlistItem = async (payload, type) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/cms/${type}`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getMasterlistItem = async (query, type) => {
	try {
		switch (type) {
		case 'menu':
			type = 'master-menu';
			break;
		case 'category':
			type = 'menu-category';
			break;
		case 'modifier':
			type = 'menu-modifier';
			break;
		case 'option group':
			type = 'option-group';
			break;
		default:
			type = 'master-menu';
			break;
		}

		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/${type}`,
			params: query,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteMaterial = async materialId => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/cms/material/remove`,
			data: {materialId},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const unarchiveTemplate = async menuTemplateId => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/cms/menu-template/restore`,
			data: {id: menuTemplateId},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};