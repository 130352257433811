import React from 'react';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';

import localization from 'localization';
const locale = localization.Admin.UserForm.Header;

const UserFormHeader = ({isEdit, userDetail}) => {
	return (
		<div>
			<LocalPageHeader
				capitalize={isEdit ? false : true}
				headerTitle={isEdit ? userDetail?.username : locale.title}
				showBackButton
			/>
		</div>
	);
};

UserFormHeader.defaultProps = {
	isEdit: false,
	userDetail: {},
};

UserFormHeader.propTypes = {
	isEdit: PropTypes.bool,
	userDetail: PropTypes.object,
};

export default UserFormHeader;