import React, {useRef, useState} from 'react';

import {Button} from 'antd';
import {ExportOutlined} from '@ant-design/icons';

// Global components
import LocalPageHeader from 'components/LocalPageHeader';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';

// Utils
import {getSettlement} from 'utils/request/sales';
import {ellipsisItem, momentDateFormat, localeCurrencyFormat, exportToTsvFn, salesPayloadHandler, handleErrorFetch} from 'utils/utils';

import Metric from './Metric';
import Table from './Table';

const OthersSettlement = () => {
	const [exportFileModalVisible, setExportFileModalVisible] = useState(false);
	const [loadingDownload, setLoadingDownload] = useState(false);

	const metricFormRef = useRef();
	const tableFormRef = useRef();

	const handleExport = async ({fileName, type = 'export'}) => {
		try {
			setLoadingDownload(true);
			const metricPayload = salesPayloadHandler(metricFormRef.current?.getMetricFilter?.());

			await exportToTsvFn({
				fileName,
				actionType: type,
				exportCall: () => getSettlement({
					params: metricPayload,
					platform: 'others',
					isExport: true,
				}),
			});
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingDownload(false);
		}
	};

	const openExportFileModal = () => {
		setExportFileModalVisible(true);
	};

	const closeExportFileModal = () => {
		setExportFileModalVisible(false);
	};

	const columns = [
		{
			width: 116,
			key: 'sales_date',
			dataIndex: 'sales_date',
			title: ellipsisItem('Sales Date'),
			render: sales_date => ellipsisItem(momentDateFormat({dateString: sales_date})),
		},
		{
			width: 202,
			key: 'sales_number',
			dataIndex: 'sales_number',
			title: ellipsisItem('Sales Number'),
			render: sales_number => ellipsisItem(sales_number),
		},
		{
			width: 181,
			key: 'bill_number',
			dataIndex: 'bill_number',
			title: ellipsisItem('Bill Number'),
			render: bill_number => ellipsisItem(bill_number),
		},
		{
			width: 267,
			key: 'branch',
			dataIndex: 'branch',
			title: ellipsisItem('Branch'),
			render: branch => ellipsisItem(branch),
		},
		{
			width: 209,
			key: 'payment_method_name',
			dataIndex: 'payment_method_name',
			title: ellipsisItem('Payment Method'),
			render: payment_method_name => ellipsisItem(payment_method_name),
		},
		{
			width: 157,
			key: 'total_normal_price',
			dataIndex: 'total_normal_price',
			title: ellipsisItem('Total Normal Price'),
			render: total_normal_price => ellipsisItem(localeCurrencyFormat({value: total_normal_price})),
		},
		{
			width: 121,
			key: 'subtotal',
			dataIndex: 'subtotal',
			title: ellipsisItem('Subtotal'),
			render: subtotal => ellipsisItem(localeCurrencyFormat({value: subtotal})),
		},
		{
			width: 137,
			key: 'sales_after_tax',
			dataIndex: 'sales_after_tax',
			title: ellipsisItem('Sales After Tax'),
			render: sales_after_tax => ellipsisItem(localeCurrencyFormat({value: sales_after_tax})),
		},
		{
			width: 171,
			key: 'sales_before_tax',
			dataIndex: 'sales_before_tax',
			title: ellipsisItem('Sales Before Tax'),
			render: sales_before_tax => ellipsisItem(localeCurrencyFormat({value: sales_before_tax})),
		},
		{
			width: 122,
			key: 'pb1',
			dataIndex: 'pb1',
			title: ellipsisItem('PB1'),
			render: pb1 => ellipsisItem(localeCurrencyFormat({value: pb1})),
		},
	];

	return (
		<>
			<ExportCSVOverlay
				providedFileName={`others-settlement-${new Date().valueOf()}.csv`}
				fileFormat='csv'
				loading={loadingDownload}
				handleExport={handleExport}
				visible={exportFileModalVisible}
				close={closeExportFileModal}
				type={'export'}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					headerTitle={'Other Sales Transaction'}
					customBreadcrumbLabel='Sales Dashboard - Other Sales Transaction'
					extra={[
						<Button
							onClick={openExportFileModal}
							icon={<ExportOutlined />}
							type='primary'
							key='export'>Export</Button>,
					]}
				/>
				<div className='px-6'>
					<Metric
						tableFormRef={tableFormRef}
						ref={metricFormRef} />
					<Table
						columns={columns}
						ref={tableFormRef}
						metricFormRef={metricFormRef} />
				</div>
			</div>
		</>
	);
};

export default OthersSettlement;