import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const createInternalPlatform = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/platforms`,
			data: {...payload, type: 'internal'},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editInternalPlatform = async payload => {
	try {
		const {id, ...rest} = payload;
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/cms/platforms/${id}`,
			data: {...rest, type: 'internal'},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};