import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {createMasterlistItem, editMasterlistItem} from 'utils/request/masterlist';

import {Button, Form, Input, notification, Select, Radio, Checkbox} from 'antd';

import localization from 'localization';
const locale = localization.MasterlistPage.MasterlistForm.CategoryForm;
const actionLocale = localization.MasterlistPage.MasterlistForm.action;

const dayOptions = Object.values(localization.Global.DayOptions);

const CategoryForm = ({isEdit, itemRecord}) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState(false);
	// const [activeTime, setActiveTime] = useState('allTime');

	const handleSubmit = async e => {
		try {
			setIsLoading(true);
			if (e.isActive === 1) {
				e.isActive = true;
			} else {
				e.isActive = false;
			}
			const fetchFn = isEdit ? editMasterlistItem : createMasterlistItem;
			if (isEdit) e.categoryId = itemRecord.id;
			const response = await fetchFn(e, 'menu-category');
			if (response.success) {
				const notificationLocale = isEdit ? actionLocale.EditSuccess : actionLocale.CreateSuccess;
				navigate('/masterlist/category');
				notification.open({
					message: notificationLocale.title,
					description: notificationLocale.description.replace('{{type}}', 'category'),
					type: 'success',
				});
			}
		} catch (error) {
			notification.open({
				message: actionLocale.Failed.title,
				description: actionLocale.Failed.description,
				type: 'warning',
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isEdit) {
			form.setFieldsValue({
				isActive: itemRecord.isActive ? 1 : 0,
				label: itemRecord.label,
				tags: itemRecord.tags,
				days: itemRecord.days,
				posName: itemRecord.posName,
			});
		}
	}, []);

	return (
		<Form
			form={form}
			layout="vertical"
			name="categoryForm"
			scrollToFirstError
			onFinish={e => !isLoading && handleSubmit(e)}
			onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
			requiredMark={false}
			initialValues={{
				isActive: 1,
				days: dayOptions.map((_, index) => index),
				selectedActiveTime: [{activeTime: undefined}],
			}}
		>
			<Form.Item
				label={locale.categoryLabel}
				name="label"
				rules={[{required: true, message: locale.categoryLabelRequired}]}
			>
				<Input
					className='w-96'
					placeholder={locale.categoryLabelPlaceholder}
				/>
			</Form.Item>
			<Form.Item
				label={locale.posNameLabel}
				name="posName"
				rules={[{required: true, message: locale.salesDayRequired}]}
			>
				<Input
					showCount
					maxLength={22}
					className='w-96'
					placeholder={locale.posNamePlaceholder}
					onInput={e => e.target.value = e.target.value.toUpperCase()}
				/>
			</Form.Item>
			<Form.Item
				label={locale.tagsLabel}
				name="tags">
				<Select
					placeholder={locale.tagsLabelPlaceholder}
					className='w-96'
					open={false}
					mode='tags'
					tokenSeparators={[',']}
				/>
			</Form.Item>
			<Form.Item
				label={locale.salesDayLabel}
				name="days"
				rules={[{required: true, message: locale.salesDayRequired}]}
			>
				<Checkbox.Group
					options={dayOptions.map((day, index) => {
						return {
							label: day,
							value: index,
						};
					})}
				/>
			</Form.Item>
			{/* Sales Hour Section */}
			{/* <Form.Item
				label={locale.salesHourLabel}
			>
				<Radio.Group
					value={activeTime}
					onChange={e => setActiveTime(e.target.value)}
				>
					<Radio
						key='allTime'
						value={'allTime'}>{locale.salesHourAllDay}</Radio>
					<Radio
						key='specificTime'
						value={'specificTime'}>{locale.salesHourSpecificTime}</Radio>
				</Radio.Group>
				{
					activeTime === 'specificTime' && (
						<Form.List
							name="selectedActiveTime"
						>
							{(fields, {add, remove}, {errors}) => (
								<div className='flex flex-col mt-4 gap-2'>
									{
										fields.map(({name, key},index) => (
											<div
												key={index}
											>
												<div className='flex flex-row gap-4'>
													<Form.Item
														className='mb-0'
														name={[name, 'activeTime']}
														fieldKey={[key, 'activeTime']}
													>
														<TimePicker.RangePicker format='HH:mm' />
													</Form.Item>
													<Button
														disabled={fields.length === 1}
														icon={<DeleteOutlined />}
														onClick={() => remove(name)}
														danger
														ghost
													>{'Delete'}</Button>
													{index === fields.length - 1 && (
														<Button
															onClick={() => add()}
															type='primary'
															ghost
														>
															{'Add Another Time'}
														</Button>
													)}
												</div>
											</div>
										))
									}
									<div className='mb-2'>
										<Form.ErrorList errors={errors} />
									</div>
								</div>
							)}
						</Form.List>
					)
				}
			</Form.Item> */}
			{/* End of Hour Section */}

			<Form.Item
				label={locale.statusLabel}
				name="isActive">
				<Radio.Group>
					<Radio
						key='active'
						value={1}>Yes</Radio>
					<Radio
						key='inactive'
						value={0}>No</Radio>
				</Radio.Group>
			</Form.Item>
			<Form.Item>
				<div className='flex gap-2 justify-end'>
					<Button
						onClick={() => navigate(-1)}
						disabled={isLoading}>
						{actionLocale.cancel}
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}>
						{actionLocale.submit}
					</Button>
				</div>
			</Form.Item>
		</Form>
	);
};

CategoryForm.defaultProps = {
	isEdit: false,
	itemRecord: {},
};

CategoryForm.propTypes = {
	isEdit: PropTypes.bool,
	itemRecord: PropTypes.object,
};

export default CategoryForm;