import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Input, Modal, Popconfirm, Table} from 'antd';
import localization from 'localization';
import {debounce} from 'lodash';
import {handleErrorFetch} from 'utils/utils';
import {getLocationGroup, getUploadedVersion} from 'utils/request/admin';
import moment from 'moment';

const TABLE_DATA_COUNT = 20;

const PickerModal = ({visible, onClose, actionFn, type}) => {
	const versionLocale = localization.Admin.ReleaseScheduler.Form.VersionModal;
	const locationGrouplocale = localization.Admin.ReleaseScheduler.Form.LocationGroupModal;

	const isVersion = type === 'version';

	const locale = isVersion ? versionLocale : locationGrouplocale;

	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tablePage, setTablePage] = useState(0); //starts from 0
	const [searchLabel, setSearchLabel] = useState(null);

	const debouncedChangeHandler = useCallback(
		debounce(inputtedLabel => {
			setTablePage(0);
			setSearchLabel(inputtedLabel);
		}, 500)
		, []);

	const handleTableChange = ({current}) => {
		setTablePage(current - 1);
	};

	// const columns = [];
	const getColumns = () => {
		let baseColumns = [];

		if (isVersion) {
			baseColumns = [
				{
					title: versionLocale.id,
					key: 'id',
					dataIndex: 'id',
					width: 130,
					fixed: 'left',
				},
				{
					title: versionLocale.channel,
					key: 'channel',
					dataIndex: 'channel',
					width: 100,
				},
				{
					title: versionLocale.createdAt,
					key: 'createdAt',
					dataIndex: 'createdAt',
					width: 185,
					render: createdAt => moment(createdAt).format('DD MMM YYYY - HH:mm'),
				},
				{
					title: versionLocale.changelog,
					key: 'changelog',
					dataIndex: 'changelog',
					width: 313,
					render: changelog => <pre className='max-h-24'>{changelog}</pre>,
				},
			];
		} else {
			baseColumns = [
				{
					title: locationGrouplocale.name,
					key: 'name',
					dataIndex: 'name',
					width: 313,
				},
				{
					title: locationGrouplocale.locationList,
					key: 'locations',
					dataIndex: 'locations',
					width: 218,
					render: locations => locale.locations.replace(
						'{{count}}',
						locations.length,
					),
				},
			];
		}

		baseColumns.push({
			title: 'Action',
			align: 'center',
			key: 'action',
			width: 130,
			fixed: 'right',
			render: record => (
				<div className="text-blue-400">
					<Popconfirm
						placement='topRight'
						title={locale.selectConfirmation}
						cancelText={locale.cancel}
						okText={locale.select}
						onConfirm={() => {
							actionFn(record);
							onClose();
						}}
					>
						<span className='cursor-pointer'>{locale.selectText}</span>
					</Popconfirm>
				</div>
			),
		});

		return baseColumns;
	};

	const fetchData = async () => {
		try {
			setLoading(true);
			const payload = {
				limit: TABLE_DATA_COUNT,
				sortBy: 'updatedAt',
				sortOrder: 'desc',
				offset: tablePage * TABLE_DATA_COUNT,
				search: {},
			};

			if (searchLabel) {
				if (isVersion) payload.search.id = searchLabel;
				else payload.search.name = searchLabel;
			}

			const fetchFn = isVersion ? getUploadedVersion : getLocationGroup;

			const response = await fetchFn(payload);
			if (response.success) {
				setTableData(response.data);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!visible) return setSearchLabel(null);
		fetchData();
	}, [visible, tablePage, searchLabel]);

	return (
		<Modal
			destroyOnClose={true}
			width={958}
			visible={visible}
			onCancel={onClose}
			title={locale.header}
			footer={null}
			centered
		>
			<div className='flex flex-col gap-4'>
				<Input.Search
					loading={loading}
					placeholder={locale.placeholder}
					onSearch={debouncedChangeHandler}
					style={{width: 264}}
				/>
				<Table
					loading={loading}
					columns={getColumns()}
					dataSource={tableData?.rows}
					scroll={{x: 'max-content', y: 400}}
					pagination={{
						defaultPageSize: 20,
						total: tableData?.count,
						showSizeChanger: false,
						current: tablePage + 1,
					}}
					onChange={handleTableChange}
				/>
				<div className='flex justify-end'>
					<Button onClick={onClose}>{locale.cancel}</Button>
				</div>
			</div>
		</Modal>
	);
};

PickerModal.defaultProps = {
	visible: false,
	onClose: () => null,
	actionFn: () => null,
	type: null,
};

PickerModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	actionFn: PropTypes.func,
	type: PropTypes.string,
};

export default PickerModal;