import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import LocalPageHeader from 'components/LocalPageHeader';

import {createNewRequest} from 'utils/request/salesChannel';
import {getMasterlistData} from 'utils/request/masterlist';
import {getOutletList, getPlatformList} from 'utils/request/global';
import {handleErrorFetch, transferOutletFormatter} from 'utils/utils';
import {STORAGE_KEY} from 'utils/constants';
import useUnload from 'hooks/useUnload';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {Alert, Button, Card, DatePicker, Modal, notification, Radio, Select, Transfer} from 'antd';
const {Option} = Select;
const {confirm} = Modal;

import localization from 'localization';
const locale = localization.SyncRequest.CreateNewSyncRequest;

const taxConfig = [
	{
		label: locale.taxExactPrice,
		value: 'exactPrice',
	},
	{
		label: locale.taxSubtractPrice,
		value: 'subtractPrice',
	},
];

const syncOptionConfig = [
	{
		label: locale.turnOnAll,
		value: false,
	},
	{
		label: locale.useExistingStatus,
		value: true,
	},
];

const CreateExternalSyncRequest = () => {
	const navigate = useNavigate();
	const [fetchingTemplate, setFetchingTemplate] = useState(false);

	const [templateOptions, setTemplateOptions] = useState([]);
	const [platformList, setPlatformList] = useState([]);
	const [outletList, setOutletList] = useState([]);

	const [selectedMenuTemplateID, setSelectedMenuTemplateID] = useState(null);
	const [selectedPlatformID, setSelectedPlatformID] = useState(null);
	const [taxOption, setTaxOption] = useState(null);
	const [useExistingStatus , setUseExistingStatus ] = useState(null);
	const [selectedMerchantId, setSelectedMerchantId] = useState([]);
	const [syncDate, setSyncDate] = useState(null);

	const [outletLoading, setOutletLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const onChangeMenuTemplate = value => {
		setSelectedMenuTemplateID(value);
	};

	const getTemplateList = async value => {
		setFetchingTemplate(true);
		const response = await getMasterlistData({
			search: {
				label: value,
				isDeleted: false,
			},
		}, 'menu-template');
		setFetchingTemplate(false);
		if (response?.success) {
			setTemplateOptions(response.data.rows);
		} else {
			setTemplateOptions([]);
		}
	};

	const debouncedChangeHandler = useCallback(
		debounce(getTemplateList, 500)
		, []);

	const onSearchMenuTemplate = value => {
		if (!value) {
			setTemplateOptions([]);
		}
		if (value.length >= 3) {
			debouncedChangeHandler(value);
		}
	};

	const handleCreateNewRequest = async () => {
		try {
			const payload = {
				menuTemplateId: selectedMenuTemplateID,
				merchantId: selectedMerchantId,
				platformId: selectedPlatformID,
				syncDate,
				exactPrice: taxOption === 'exactPrice' ? true : false,
				useExistingStatus,
			};
			setLoading(true);
			const response = await createNewRequest(payload);
			if (response.success) {
				navigate('/sales-channel/sync-request');
				notification.open({
					message: locale.Notification.Success.message,
					description: locale.Notification.Success.description,
					type: 'success',
				});
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSelectDate = date => {
		setSyncDate(new Date(date));
	};

	const initialFetch = async () => {
		try {
			setLoading(true);
			const response = await getPlatformList({type: 'external'});
			if (response?.success) {
				setPlatformList(response.data.rows);

				// Populate data (Copy Request Function)
				const requestDetail = JSON.parse(sessionStorage.getItem(STORAGE_KEY.MASTERLIST_MENU_REQUEST_DETAIL));
				if (!isEmpty(requestDetail)) {
					const platformDataTemp = response.data.rows.find(platform => platform.label === requestDetail.Platform.label);
					setSelectedPlatformID(platformDataTemp.id);
					setTaxOption(requestDetail.taxAddedPercentage === -10 ? 'subtractPrice' : 'exactPrice');
					setSelectedMerchantId(requestDetail.merchantId);
				}
			}
			const templateListResponse = await getMasterlistData({
				limit: 5,
				sortBy: 'updatedAt',
				sortOrder: 'desc',
				search: {
					isDeleted: false,
				},
			}, 'menu-template');
			if (templateListResponse?.success) {
				setTemplateOptions(templateListResponse.data.rows);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useUnload(() => {
		sessionStorage.removeItem(STORAGE_KEY.MASTERLIST_MENU_REQUEST_DETAIL);
	});

	useEffect(() => {
		if (selectedMerchantId.length) {
			const availableMerchant = new Set(outletList.map(outlet => outlet.id));

			const validMerchant = selectedMerchantId.filter(id => availableMerchant.has(id));

			setSelectedMerchantId(validMerchant);
		}
	}, [outletList]);

	useEffect(() => {
		initialFetch();
		return () => sessionStorage.removeItem(STORAGE_KEY.MASTERLIST_MENU_REQUEST_DETAIL);
	}, []);

	useEffect(async () => {
		try {
			setOutletLoading(true);
			if (Number(selectedPlatformID)) {
				const response = await getOutletList(selectedPlatformID);
				if (response?.success) {
					const mappedResult = response.data.rows.map(e => ({
						...e,
						key: e.id,
					}));
					setOutletList(mappedResult);
				}
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setOutletLoading(false);
		}
	}, [selectedPlatformID]);

	function range(start, end) {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	}

	return (
		<div className="bg-white h-full overflow-auto pb-10">
			<LocalPageHeader
				headerTitle="Create New Request"
				showBackButton
			/>

			<Card
				title={locale.title}
				className='m-6' >

				<div className='space-y-6'>
					<Alert
						message={locale.info}
						type="info"
						showIcon
					/>

					{/* Select Template */}
					<div className='flex'>
						<div className='space-y-2 flex-1'>
							<div>{locale.templateNameLabel}</div>
							<Select
								showSearch
								placeholder={locale.templateNamePlaceholder}
								optionFilterProp="children"
								onChange={onChangeMenuTemplate}
								onSearch={onSearchMenuTemplate}
								className="w-full"
								loading={fetchingTemplate}
							>
								{templateOptions.map(item => (
									<Option
										key={item.id}
										value={item.id}
									>{item.label}</Option>
								))}
							</Select>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End of Select Template */}

					{/* Select Sync Date */}
					<div className='flex'>
						<div className='space-y-2 flex-1'>
							<div>{locale.syncTimeLabel}</div>
							<DatePicker
								disabledDate={current => {
									const yesterday = new Date();
									yesterday.setDate(yesterday.getDate() - 1);
									return current && current.valueOf() <= yesterday;
								}}
								showTime={{
									hideDisabledOptions: true,
									format: 'DD-MMM-YYYY HH:mm',
								}}
								disabledTime={curr => {
									const now = moment();
									const resp = {};

									const currDate = moment(curr).get('date');
									if (currDate && currDate === now.get('date')) {
										Object.assign(resp, {
											disabledHours: () => range(0, 25).splice(0, now.get('hour')),
										});
										const curHour = moment(curr).get('hour');
										if (curHour && curHour === now.get('hour')) {
											Object.assign(resp, {
												disabledMinutes: () => range(0, 61).splice(0, now.get('minute')),
											});
										}
									}

									return resp;
								}}
								onChange={handleSelectDate}
								className='w-full'
								placeholder={locale.syncTimePlaceholder}
							/>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End of Select Sync Date */}

					{/* Select Sync Merchant */}
					<div className='flex'>
						<div className='space-y-2 flex-1'>
							<div>{locale.syncPlatformLabel}</div>
							<Radio.Group
								onChange={e => {
									setSelectedPlatformID(e.target.value);
									setSelectedMerchantId([]);
								}}
								value={selectedPlatformID}
							>
								{platformList.map(platform => (
									<Radio
										key={platform.id}
										value={platform.id}
									>{platform.label}</Radio>
								))}
							</Radio.Group>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End Of Select Sync Merchant */}

					{/* Tax Setting */}
					<div className='flex'>
						<div className='space-y-2 flex-1'>
							<div>{locale.taxLabel}</div>
							<Radio.Group
								onChange={e => setTaxOption(e.target.value)}
								value={taxOption}
							>
								{taxConfig.map(option => (
									<Radio
										key={option.value}
										value={option.value}
									>{option.label}</Radio>
								))}
							</Radio.Group>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End Of Tax Setting */}

					{/* Sync Option */}
					<div className='flex'>
						<div className='space-y-2 flex-1'>
							<div>{locale.syncOptionLabel}</div>
							<Radio.Group
								onChange={e => setUseExistingStatus(e.target.value)}
								value={useExistingStatus}
							>
								{syncOptionConfig.map(option => (
									<Radio
										key={option.value}
										value={option.value}
									>{option.label}</Radio>
								))}
							</Radio.Group>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End Of Sync Option */}

					{/* Pick Outlet Section */}
					<div className='w-full'>
						<Transfer
							disabled={outletLoading || !outletList.length}
							titles={[locale.allOutlet, locale.selectedOutlet]}
							listStyle={{width: '100%', height: 350}}
							dataSource={outletList}
							render={item => transferOutletFormatter(item)}
							targetKeys={selectedMerchantId}
							onChange={(selectedKeys, direction) => {
								let tempArray = [...selectedMerchantId];
								if (direction === 'right') {
									selectedKeys.forEach(merchantId => {
										if (!tempArray.includes(merchantId)) {
											tempArray.push(merchantId);
										}
									});
								} else {
									tempArray = selectedKeys;
								}
								setSelectedMerchantId(tempArray);
							}}
							showSearch
							filterOption={(input, option) => {
								const labelFormat = transferOutletFormatter(option);
								return labelFormat.toLowerCase().indexOf(input.toLowerCase()) >= 0;
							}
							}
						/>
					</div>
					{/* End of Pick Outlet Section */}
				</div>

				{/* Action Section */}
				<div className='flex justify-end pt-12 gap-2'>
					<Button onClick={() => navigate('/sales-channel/sync-request')} >{locale.cancel}</Button>
					<Button
						type='primary'
						onClick={() => confirm({
							title: locale.ConfirmationModal.title,
							content: locale.ConfirmationModal.description,
							okText: locale.ConfirmationModal.confirm,
							onOk() {
								handleCreateNewRequest();
							},
						})}
						loading={loading}
						disabled={
							!selectedMenuTemplateID || !selectedPlatformID || !selectedMerchantId.length || !syncDate || !taxOption || useExistingStatus === null
						}
					>{loading ? locale.loadingCreate : locale.create}</Button>
				</div>
				{/* End of Action Section */}

			</Card>
		</div>
	);
};

export default CreateExternalSyncRequest;
