import {ACTIONS} from 'store/actions/actionKeys';

const initialState = {};

export default function salesDropdownOption(state = initialState, {type, payload}) {
	switch (type) {
	case ACTIONS.SET_SALES_DROPDOWN_OPTION:
		return {
			...state,
			...payload,
		};
	default:
		return state;
	}
}