import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import Header from './Header';

import TabletOutletList from './TableOutletList';
import ModalDownloadReport from '../components/ModalDownloadReport';

import {getBrandList, getPlatformList} from 'utils/request/global';
import {downloadOutletRequestReport, getOutletOffReason} from 'utils/request/watchtower';
import {handleErrorFetch} from 'utils/utils';

import localization from 'localization';
const locale = localization.WatchTower.WatchTowerOutlet;

const WatchtowerOutlet = ({isHistory}) => {
	const [platformList, setPlatformList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [reasonList, setReasonList] = useState([]);
	const [loading, setLoading] = useState(false);

	const downloadReportModalRef = useRef(null);

	const downloadFn = async ({createdAtFrom, createdAtTo}) => {
		return await downloadOutletRequestReport({
			createdAtFrom,
			createdAtTo,
			isHistory,
		});
	};

	const initialFetch = async () => {
		try {
			setLoading(true);
			const platformResponse = await getPlatformList();
			const brandResponse = await getBrandList();
			const reasonResponse = await getOutletOffReason();
			if (platformResponse.success) setPlatformList(platformResponse.data.rows?.sort?.((a, b) => a.label.localeCompare(b.label)));
			if (brandResponse.success) setBrandList(brandResponse.data.rows?.sort?.((a, b) => a.label.localeCompare(b.label)));
			if (reasonResponse.success) setReasonList(reasonResponse.reasons);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		initialFetch();
	}, []);

	return (
		<>
			<ModalDownloadReport
				isHistory={isHistory}
				ref={downloadReportModalRef}
				downloadFn={downloadFn}
			/>
			<div className='bg-white h-full overflow-auto'>
				<Header
					isHistory={isHistory}
					customBreadcrumbLabel={isHistory && locale.header.breadcrumbHistory}
					headerLabel={isHistory ? locale.header.history : locale.header.watchtower}
					openDownloadReportModal={() => downloadReportModalRef.current?.open()}
				/>
				<TabletOutletList
					isHistory={isHistory}
					loading={loading}
					brandList={brandList}
					platformList={platformList}
					reasonList={reasonList}
				/>
			</div>
		</>
	);
};

WatchtowerOutlet.defaultProps = {
	isHistory: false,
};

WatchtowerOutlet.propTypes = {
	isHistory: PropTypes.bool,
};

export default WatchtowerOutlet;