import React, {useEffect, useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import OutletTable from 'pages/Outlet/components/Table';
import EditOutletModal from './EditOutletModal';

import {activateOutlet, deactivateOutlet, getOutletData} from 'utils/request/outlet';
import {getBrandList, getPlatformList} from 'utils/request/global';
import {OUTLET_TYPE, USER_ROLES} from 'utils/constants';
import {authChecker, handleErrorFetch} from 'utils/utils';

import {Badge, Divider, notification, Popconfirm, Select, Skeleton, Switch, Tabs, Typography} from 'antd';
const {Text} = Typography;
const {Option} = Select;

import {useSelector} from 'react-redux';

const ACTIVATION_STATUS = {
	SUCCESS: 'Success',
	PENDING: 'Pending',
	INACTIVE: 'Inactive',
};

const DEFAULT_EDIT_MODAL_CONFIG = {
	visible: false,
	outletData: {},
};

import localization from 'localization';
const locale = localization.Outlet.Activation.Table;

const OutletActivationBody = ({refetchTableRef, setExportQuery, scrollToTop, platformType}) => {
	const [loading, setLoading] = useState(true);
	const [loadingActivation, setLoadingActivation] = useState(false);
	const [locationLoading, setLocationLoading] = useState(false);

	const [platformList, setPlatformList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [locationList, setLocationList] = useState([]);

	const [editModalConfig, setEditModalConfig] = useState(DEFAULT_EDIT_MODAL_CONFIG);
	const auth = useSelector(state => state.user);

	const tableRef = useRef(null);

	const isInternal = platformType === 'internal';

	const initialFetch = async () => {
		try {
			setLoading(true);
			const platformResponse = await getPlatformList({type: platformType});
			const brandResponse = await getBrandList();
			if (platformResponse.success) setPlatformList(platformResponse.data.rows?.sort((a, b) => a.id - b.id));
			if (brandResponse.success) setBrandList(brandResponse.data.rows);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const decideSyncStatusColor = status => {
		let badgeColor;
		switch (status) {
		case ACTIVATION_STATUS.SUCCESS:
			badgeColor = 'success';
			break;
		case ACTIVATION_STATUS.PENDING:
			badgeColor = 'processing';
			break;
		default:
			badgeColor = 'default';
			break;
		}
		return (<div className='capitalize'><Badge status={badgeColor} /> {status}</div>);
	};

	const handleOutletActivation = async record => {
		const isActive = record?.isActive;
		try {
			setLoadingActivation(true);
			const payload = {
				merchantId: record.id,
			};
			const callFn = isActive ? deactivateOutlet : activateOutlet;
			const response = await callFn(payload);
			if (response.success) {
				refetchTableRef.current();
				notification.open({
					message: locale[`${isActive ? 'Deactivate' : 'Activation'}Notification`].Success.message,
					description: locale[`${isActive ? 'Deactivate' : 'Activation'}Notification`].Success.description,
					type: 'success',
				});
			} else {
				notification.open({
					message: locale[`${isActive ? 'Deactivate' : 'Activation'}Notification`].Failed.message,
					description: response.error,
					type: 'warning',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingActivation(false);
		}
	};

	const openEditModal = selectedOutlet => {
		setEditModalConfig({
			visible: true,
			outletData: selectedOutlet,
		});
	};

	const closeEditModal = () => {
		setEditModalConfig(DEFAULT_EDIT_MODAL_CONFIG);
	};

	const getLocation = async outletLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	const tableColumn = [
		{
			title: locale.uuid,
			dataIndex: 'externalId',
			key: 'externalId',
			fixed: 'left',
			render: item => (
				<Text
					className='w-36'
					ellipsis={true}
					copyable>{item}</Text>
			),
		},
		{
			title: isInternal ? locale.id : locale.internalId,
			dataIndex: 'id',
			key: 'id',
			valueType: 'digit',
			fieldProps: {
				controls: false,
			},
			render: (_, record) => (
				<Text
					className='w-36'
					ellipsis={true}
					copyable>{record.id}
				</Text>
			),
		},
		{
			title: locale.externalMerchantId,
			dataIndex: 'externalMerchantId',
			key: 'externalMerchantId',
			render: item => (
				<Text
					className='w-36'
					ellipsis={true}
					copyable>{item}</Text>
			),
		},
		{
			title: locale.location,
			dataIndex: ['Location'],
			key: 'locationId',
			render: location => (
				<div>{location.id} - {location.label}</div>
			),
			renderFormItem: () => (
				<Select
					allowClear
					showSearch
					loading={locationLoading}
					onSearch={debounceFn}
					filterOption={((_, option) => option)}>
					{locationList.map(location => (
						<Option
							key={location.id}
							value={location.id}>{location.id} - {location.label}</Option>
					))}
				</Select>
			),
		},
		{
			title: locale.brand,
			dataIndex: ['Brand'],
			key: 'brandId',
			renderFormItem: () => (
				<Select
					allowClear
					showSearch
					filterOption={((val, option) => {
						const formattedLabel = option?.children?.join(' ')?.toLocaleLowerCase() || '';
						return formattedLabel.includes(val?.toLocaleLowerCase?.() || '');
					})}
					placeholder={locale.selectPlaceholder}>
					{
						brandList.sort((a, b) => a.label.localeCompare(b.label)).map(brand => (
							<Select.Option
								key={brand.id}
								value={brand.id}>{brand.id} - {brand.label}</Select.Option>
						))
					}
				</Select>
			),
			render: brand => (
				<div>{brand.id} - {brand.label}</div>
			),
		},
		{
			title: locale.outlet,
			dataIndex: 'label',
			key: 'label',
		},
		{
			title: locale.autoInject,
			dataIndex: 'autoInjectFlag',
			key: 'autoInjectFlag',
			renderFormItem: () => (
				<Select
					allowClear
					placeholder={locale.selectPlaceholder}>
					<Select.Option value={1}>{locale.on}</Select.Option>
					<Select.Option value={-1}>{locale.off}</Select.Option>
				</Select>
			),
			render: (_, record) => (
				<Popconfirm
					disabled={!authChecker({
						auth,
						requiredRolesArr: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR, USER_ROLES.TRAINER.ADMIN,
							USER_ROLES.MARKETING.EDITOR, USER_ROLES.FINANCE.VIEWER, USER_ROLES.FINANCE.EDITOR],
					})}
					onConfirm={() => tableRef?.current?.handleToggleAutoInject?.(record)}
					placement='topRight'
					title={locale.AutoInjectAction.Confirmation.Single.title.replace('{{state}}', record.autoInjectFlag ? 'off' : 'on')}
				>
					<Switch
						disabled={!authChecker({
							auth,
							requiredRolesArr: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR, USER_ROLES.TRAINER.ADMIN,
								USER_ROLES.MARKETING.EDITOR, USER_ROLES.FINANCE.VIEWER, USER_ROLES.FINANCE.EDITOR],
						})}
						checkedChildren={locale.on}
						unCheckedChildren={locale.off}
						checked={record.autoInjectFlag} />
				</Popconfirm>
			),
		},
		{
			title: locale.status,
			dataIndex: 'activationStatus',
			key: 'activationStatus',
			renderFormItem: () => (
				<Select placeholder={locale.selectPlaceholder}>
					{
						Object.values(ACTIVATION_STATUS).map(status => (
							<Option
								key={status}
								value={status}>{status}</Option>
						))
					}
				</Select>
			),
			render: status => (
				<div className='flex gap-2 items-center'>
					{decideSyncStatusColor(status)}
				</div>
			),
		},
		{
			title: locale.username,
			dataIndex: 'merchantAppUsername',
			key: 'merchantAppUsername',
			search: false,
		},
		{
			title: locale.password,
			dataIndex: 'merchantAppPassword',
			key: 'merchantAppPassword',
			search: false,
		},
		...(authChecker({
			auth,
			requiredRolesArr: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR, USER_ROLES.TRAINER.ADMIN,
				USER_ROLES.MARKETING.EDITOR, USER_ROLES.FINANCE.VIEWER, USER_ROLES.FINANCE.EDITOR],
		}) ? [{
				title: locale.action,
				align: 'center',
				key: 'action',
				fixed: 'right',
				search: false,
				render: (_, record) => (
					<div className='flex gap-2 items-center justify-center text-antd-blue-6'>
						{(
							<>
								<Popconfirm
									placement="topRight"
									title={record.isActive ? locale.deactivateConfirmation : locale.activateConfirmation}
									onConfirm={() => handleOutletActivation(record)}>
									<span className='cursor-pointer'>{record.isActive ? locale.deactivate : locale.activate}</span>
								</Popconfirm>
								<Divider type='vertical' />
							</>
						)}
						<span
							onClick={() => openEditModal(record)}
							className='cursor-pointer'>
							{locale.edit}
						</span>
					</div>
				),
			}] : []),
	];

	const determineColumn = () => {
		if (!isInternal) return tableColumn;

		const includedKeys = [
			'id', 'locationId', 'label',
			'autoInjectFlag', 'megaMenuTemplateId', 'activationStatus',
			'action',
		];

		return tableColumn.filter(data => includedKeys.includes(data.key));
	};

	useEffect(() => {
		initialFetch();
	}, []);

	const fetchOutletData = async (query, type, platformId) => {
		query.search.platformId = platformId;
		if (query?.search?.autoInjectFlag === 1) query.search.autoInjectFlag = true;
		if (query?.search?.autoInjectFlag === -1) query.search.autoInjectFlag = false;
		return await getOutletData(query, type);
	};

	return loading
		? (
			<div className='px-6 flex flex-col gap-8'>
				<div className='flex gap-4'>
					{
						new Array(3).fill(null).map((_, index) => (
							<Skeleton.Button
								key={index}
								active
							/>
						))
					}
				</div>
				<div className='flex flex-col gap-4'>
					{
						new Array(5).fill(null).map((_, index) => (
							<Skeleton.Button
								key={index}
								block
								active
							/>
						))
					}
				</div>
			</div>
		)
		: (
			<div className='px-6'>
				<EditOutletModal
					brandList={brandList}
					refetch={() => refetchTableRef.current()}
					visible={editModalConfig.visible}
					outletData={editModalConfig.outletData}
					close={closeEditModal}
				/>
				<Tabs
					defaultActiveKey="1"
				>
					{platformList.map(platform => (
						<Tabs.TabPane
							tab={(
								<span className='flex gap-1 items-center'>
									<span
										className='m-0 font-medium text-base'
										level={5}>{platform.label}</span> <span className='text-xs'>{`[Platform ID: ${platform.id}]`}</span>
								</span>
							)}
							key={platform.id}>
							<OutletTable
								brandList={brandList}
								tableColumn={determineColumn()}
								fetchFn={async (query, type) => await fetchOutletData(query, type, platform.id)}
								tableType={OUTLET_TYPE.MERCHANT}
								refetchTableRef={refetchTableRef}
								scrollToTop={scrollToTop}
								setExportQuery={setExportQuery}
								parentLoading={loadingActivation}
								ref={tableRef}
							/>
						</Tabs.TabPane>
					))}
				</Tabs>
			</div>
		);
};

OutletActivationBody.defaultProps = {
	scrollToTop: () => null,
	setExportQuery: () => null,
	refetchTableRef: {},
	platformType: null,
};

OutletActivationBody.propTypes = {
	scrollToTop: PropTypes.func,
	setExportQuery: PropTypes.func,
	refetchTableRef: PropTypes.object,
	platformType: PropTypes.string,
};

export default OutletActivationBody;