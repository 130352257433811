import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Modal, Select, Typography} from 'antd';
import Context from '../../Context';

const MenuTags = ({visible, menuTagData, onSave, close}) => {
	const {menuTagList} = useContext(Context);
	const [selectedTags, setSelectedTags] = useState([]);

	// Menu LTO Tag cant be combined with other tag

	useEffect(() => {
		if (visible) setSelectedTags(menuTagData.menuTags || []);
		else setSelectedTags([]);
	}, [visible]);

	return (
		<Modal
			destroyOnClose
			centered
			width={572}
			visible={visible}
			onOk={() => onSave(selectedTags)}
			onCancel={close}
			closable={false}
			maskClosable={false}
		>
			<div>
				<Typography.Title
					className='m-0'
					level={5}>
					{menuTagData.menuLabel}
				</Typography.Title>
				<Typography.Text type='secondary'>
					Select menu tag for this menu
				</Typography.Text>
				<div className='flex flex-col mt-4 gap-1'>
					<div>Menu Tag</div>
					<Select
						allowClear
						value={selectedTags}
						onChange={e => setSelectedTags(e)}
						mode='multiple'
						placeholder='Select menu tag'
						options={menuTagList?.map?.(tag => {
							return {
								...tag,
								disabled: tag.value === 'limited'
									? selectedTags.length && selectedTags.some(selectedTag => selectedTag !== 'limited')
									: selectedTags.includes('limited'),
							};
						})}
					/>
				</div>
			</div>
		</Modal>
	);
};

MenuTags.defaultProps = {
	visible: false,
	menuTagData: {},
	onSave: () => null,
	close: () => null,
};

MenuTags.propTypes = {
	visible: PropTypes.bool,
	menuTagData: PropTypes.object,
	onSave: PropTypes.func,
	close: PropTypes.func,
};

export default MenuTags;