import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {createNewOutletItem, editOutletItem} from 'utils/request/outlet';
import {CLOUDINARY_DIR, OUTLET_TYPE} from 'utils/constants';
import {uploadImage} from 'utils/cloudinary';
import {isURL} from 'utils/utils';

import {UploadOutlined} from '@ant-design/icons';
import {Button, Card, Form, Input, Modal, Radio, Typography, Upload, notification} from 'antd';

import isEmpty from 'lodash/isEmpty';

import localization from 'localization';
const locale = localization.Outlet.BrandForm.Form;

const OutletBrandFormBody = ({isEdit, itemRecord}) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const logoUrlValue = Form.useWatch('logoUrl', form);
	const backgroundUrlValue = Form.useWatch('backgroundUrl', form);

	const [isLoading, setIsLoading] = useState(false);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);

	const imagePopulator = (payload, imagePropertyString, urlSrc) => {
		payload[imagePropertyString] = {
			file: {},
			fileList: [{
				uid: Date.now().toString(36),
				name: urlSrc?.split?.('/')?.pop?.(),
				url: urlSrc,
			}],
		};
	};

	const imageValidator = async (e, imagePropertyString) => {
		const imageProperty = e[imagePropertyString];
		if (imageProperty?.fileList?.length === 0) {
			e[imagePropertyString] = null;
		} else {
			const imageFile = imageProperty?.fileList[0];

			if (imageFile?.url && isURL(imageFile?.url)) {
				e[imagePropertyString] = imageFile?.url;
			} else if (imageProperty.file.status !== 'removed') {
				const response = await uploadImage(imageProperty.file, CLOUDINARY_DIR.BRAND);
				e[imagePropertyString] = response.secure_url || response.url;
			}
		}
	};

	const handleSubmit = async e => {
		try {
			setIsLoading(true);

			if (!isEmpty(e.logoUrl)) await imageValidator(e, 'logoUrl');
			if (!isEmpty(e.backgroundUrl)) await imageValidator(e, 'backgroundUrl');

			const fetchFn = isEdit ? editOutletItem : createNewOutletItem;
			if (isEdit) e.brandId = itemRecord.id;
			const response = await fetchFn(e, OUTLET_TYPE.BRAND);
			if (response.success) {
				const notificationLocale = isEdit ? locale.action.EditSuccess : locale.action.CreateSuccess;
				navigate('/outlet/brand');
				notification.open({
					message: notificationLocale.title,
					description: notificationLocale.description,
					type: 'success',
				});
			}
		} catch (error) {
			notification.open({
				message: locale.action.Failed.title,
				description: locale.action.Failed.description,
				type: 'warning',
			});
		} finally {
			setIsLoading(false);
		}
	};

	function getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}

	const handlePreview = async file => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
	};

	useEffect(() => {
		if (isEdit) {
			const formsVal = {
				label: itemRecord.label,
				shortName: itemRecord.shortName,
				shortDescription: itemRecord.shortDescription,
				description: itemRecord.description,
				isActive: itemRecord.isActive,
				isNew: itemRecord.isNew,
				isSubBrand: itemRecord.isSubBrand,
				isPublic: itemRecord.isPublic,
			};

			if (itemRecord.logoUrl) imagePopulator(formsVal, 'logoUrl', itemRecord.logoUrl);
			if (itemRecord.backgroundUrl) imagePopulator(formsVal, 'backgroundUrl', itemRecord.backgroundUrl);

			form.setFieldsValue(formsVal);
		}
	}, []);

	return (
		<>
			<Modal
				visible={previewVisible}
				onCancel={() => setPreviewVisible(false)}
				footer={null}
			>
				<img
					alt="example"
					style={{width: '100%'}}
					src={previewImage} />
			</Modal>
			<div className='px-6 pb-6'>
				<Card
					title={isEdit ? locale.editTitle : locale.defaultTitle}
				>
					<div>
						<Form
							form={form}
							layout="vertical"
							name="brandForm"
							scrollToFirstError
							onFinish={e => !isLoading && handleSubmit(e)}
							onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
							requiredMark={false}
							initialValues={{
								isActive: true,
							}}
						>
							{/* Brand Label */}
							<Form.Item
								className='w-96'
								label={locale.brandNameLabel}
								name="label"
								rules={[{required: true, message: locale.brandNameRequired}]}
							>
								<Input
									placeholder={locale.brandNamePlaceholder}
								/>
							</Form.Item>

							{/* Brand Short Name */}
							<Form.Item
								className='w-96'
								label={locale.brandShortNameLabel}
								name="shortName"
								rules={[{required: true, message: locale.brandShortNameRequired}]}
							>
								<Input
									placeholder={locale.brandShortNamePlaceholder}
								/>
							</Form.Item>

							{/* Brand Tagline */}
							<Form.Item
								className='w-96'
								label={locale.taglineLabel}
								name="shortDescription"
								rules={[{required: true, message: locale.taglineRequired}]}
							>
								<Input.TextArea
									maxLength={100}
									showCount
									placeholder={locale.taglinePlaceholder}
								/>
							</Form.Item>

							{/* Brand Description */}
							<Form.Item
								className='w-96'
								label={locale.descriptionLabel}
								name="description"
								rules={[{required: true, message: locale.descriptionRequired}]}
							>
								<Input.TextArea
									showCount
									placeholder={locale.descriptionPlaceholder}
								/>
							</Form.Item>

							{/* Brand isActive */}
							{
								isEdit && (
									<Form.Item
										label={locale.activeLabel}
										name="isActive"
										rules={[{required: true}]}
									>
										<Radio.Group>
											<Radio value={true}>{locale.yes}</Radio>
											<Radio value={false}>{locale.no}</Radio>
										</Radio.Group>
									</Form.Item>
								)
							}

							{/* Brand isNew */}
							<Form.Item
								label={locale.markAsNew}
								name="isNew"
								rules={[{required: true, message: locale.optionRequred}]}
							>
								<Radio.Group>
									<Radio value={true}>{locale.yes}</Radio>
									<Radio value={false}>{locale.no}</Radio>
								</Radio.Group>
							</Form.Item>

							{/* Brand isSubBrand */}
							<Form.Item
								label={locale.isSubBrand}
								name="isSubBrand"
								rules={[{required: true, message: locale.optionRequred}]}
							>
								<Radio.Group>
									<Radio value={true}>{locale.yes}</Radio>
									<Radio value={false}>{locale.no}</Radio>
								</Radio.Group>
							</Form.Item>

							{/* Show in web */}
							<Form.Item
								label={locale.isPublic}
								name="isPublic"
								rules={[{required: true, message: locale.optionRequred}]}
							>
								<Radio.Group>
									<Radio value={true}>{locale.yes}</Radio>
									<Radio value={false}>{locale.no}</Radio>
								</Radio.Group>
							</Form.Item>

							{/* Brand Logo */}
							<Form.Item
								className='w-96'
								label={locale.brandLogoLabel}
								name="logoUrl"
								// rules={[{required: true}]}
							>
								<Upload
									fileList={logoUrlValue?.fileList || []}
									accept="image/*"
									multiple={false}
									maxCount={1}
									beforeUpload={() => false}
									listType="picture"
									onPreview={handlePreview}
								>
									<Button icon={<UploadOutlined />}>{locale.uploadBtn}</Button>
									<Typography.Text
										className='block'
										type="secondary">{locale.brandLogoInfo}
									</Typography.Text>
								</Upload>
							</Form.Item>


							{/* Brand backgroundUrl */}
							<Form.Item
								className='w-96'
								label={locale.brandBackgroundLabel}
								name="backgroundUrl"
								// rules={[{required: true}]}
							>
								<Upload
									fileList={backgroundUrlValue?.fileList || []}
									accept="image/*"
									multiple={false}
									maxCount={1}
									beforeUpload={() => false}
									listType="picture"
									onPreview={handlePreview}
								>
									<Button icon={<UploadOutlined />}>{locale.uploadBtn}</Button>
									<Typography.Text
										className='block'
										type="secondary">{locale.brandBackgroundInfo}
									</Typography.Text>
								</Upload>
							</Form.Item>

							{/* Action */}
							<Form.Item>
								<div className='flex gap-2 justify-end'>
									<Button
										onClick={() => navigate('/outlet/brand')}
										disabled={isLoading}>
										{locale.cancel}
									</Button>
									<Button
										type="primary"
										htmlType="submit"
										loading={isLoading}>
										{locale.submit}
									</Button>
								</div>
							</Form.Item>

						</Form>
					</div>
				</Card>
			</div>
		</>
	);
};

OutletBrandFormBody.defaultProps = {
	isEdit: false,
	itemRecord: {},
};

OutletBrandFormBody.propTypes = {
	isEdit: PropTypes.bool,
	itemRecord: PropTypes.object,
};

export default OutletBrandFormBody;