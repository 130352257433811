import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate, useParams} from 'react-router-dom';

import {Button, notification} from 'antd';
import {FormOutlined, PrinterOutlined, ReloadOutlined} from '@ant-design/icons';

import LocalPageHeader from 'components/LocalPageHeader';
import {AVAILABLE_POS, MASTERLIST_TYPE} from 'utils/constants';
import {refreshMapping} from 'utils/request/mapping';

import {isEmpty} from 'lodash';

import localization from 'localization';
import {handleErrorFetch} from 'utils/utils';
import RefreshMappingLoadingBar from './RefreshMappingLoadingBar';
const locale = localization.MasterlistPage.MasterlistForm.Header;

const MasterlistFormHeader = ({masterlistType, isEdit, setMappingRecord, previewLabelRef, autoFillDummyRef}) => {
	const navigate = useNavigate();
	const {posId, hash} = useParams();
	const [loadingRefreshMapping, setLoadingRefreshMapping] = useState(false);

	const isMenuStructure = masterlistType === MASTERLIST_TYPE.MENU_STRUCTURE;

	const getPosLabel = () => {
		const item = Object.values(AVAILABLE_POS).find(value => value.id == posId);
		return item ? item.label : null;
	};

	const onRefreshMapping = async () => {
		try {
			setLoadingRefreshMapping(true);
			const response = await refreshMapping({posId, hash});
			if (!isEmpty(response.data)) {
				setMappingRecord(state => ({
					...state,
					mappingDetails: response.data,
				}));
			}
			notification.success({
				message: locale.RefreshMappingNotification.success.message,
				description: locale.RefreshMappingNotification.success.description,
			});
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingRefreshMapping(false);
		}
	};

	return (
		<>
			<RefreshMappingLoadingBar visible={loadingRefreshMapping} />
			<LocalPageHeader
				headerTitle={(isEdit ? locale.titleEdit : locale.title).replace('{{masterlistType}}', masterlistType)}
				showBackButton={masterlistType === MASTERLIST_TYPE.MENU_STRUCTURE ? false : true}
				backButtonAction={() => navigate(`/masterlist/${masterlistType}`)}
				extra={isMenuStructure ? [
					<Button
						key='refresh'
						onClick={() => autoFillDummyRef?.current?.()}
						icon={<FormOutlined />}
					>{locale.autoFill}</Button>,
					<Button
						onClick={() => previewLabelRef?.current?.()}
						icon={<PrinterOutlined />}
						key='preview'>
						{locale.previewLabel}
					</Button>,
					...(hash ? [<Button
						key='refresh'
						onClick={onRefreshMapping}
						icon={<ReloadOutlined />}
					>{locale.refresh}</Button>] : []),
				] : []}
				customBreadcrumbLabel={
					masterlistType === MASTERLIST_TYPE.MENU_STRUCTURE
						? `${isEdit ? 'Edit' : 'Create New'} Mapping - ${getPosLabel()}`
						: null
				}
			/>
		</>
	);
};

MasterlistFormHeader.defaultProps = {
	masterlistType: 'menu',
	isEdit: false,
	setMappingRecord: () => null,
	previewLabelRef: () => null,
	autoFillDummyRef: {},
};

MasterlistFormHeader.propTypes = {
	masterlistType: PropTypes.string,
	isEdit: PropTypes.bool,
	setMappingRecord: PropTypes.func,
	previewLabelRef: PropTypes.func,
	autoFillDummyRef: PropTypes.object,
};

export default MasterlistFormHeader;