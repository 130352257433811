import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import ManageCondition from './ManageCondition';
import ManageMapping from './ManageMapping';
import {getMappingConfig, getMappingRawConfig} from 'utils/utils';

const MenuStructureForm = ({itemRecord, isEdit, goBack, previewLabelRef, autoFillDummyRef}) => {
	const [mappingPhase, setMappingPhase] = useState(1);
	const [conditionConfig, setConditionConfig] = useState({});

	const [mappingDetails, setMappingDetails] = useState({});

	const goToManageCondition = () => {
		setMappingPhase(1);
	};

	const goToManageMapping = configs => {
		const payload = getMappingRawConfig(configs, itemRecord?.menuDetails);
		setConditionConfig(payload);
		setMappingPhase(2);
	};

	const handlePopulateData = structureData => {
		const {
			selectedOptionGroupsId,
			selectedOptionGroupId,
			selectedParentMenu,
		} = getMappingConfig(structureData.mappingDetails);

		setMappingDetails(structureData.mappingDetails);

		goToManageMapping({
			selectedOptionGroupsId,
			selectedOptionGroupId,
			selectedParentMenu,
		});
	};

	const renderForm = () => {
		switch (mappingPhase) {
		case 1:
			return (
				<ManageCondition
					goBack={goBack}
					menuData={itemRecord?.menuDetails}
					handlePopulateData={handlePopulateData}
					nextPhase={goToManageMapping} />
			);
		case 2:
			return (
				<ManageMapping
					hash={itemRecord?.hash}
					goBack={goBack}
					menuData={itemRecord?.menuDetails}
					menuMaster={itemRecord?.MasterMenu}
					mappingDetails={mappingDetails}
					conditionConfig={conditionConfig}
					previousPhase={goToManageCondition}
					isEdit={isEdit}
					previewLabelRef={previewLabelRef}
					autoFillDummyRef={autoFillDummyRef}
				/>
			);
		default:
			return (
				<ManageCondition
					goBack={goBack}
					menuData={itemRecord?.menuDetails}
					handlePopulateData={handlePopulateData}
					nextPhase={goToManageMapping} />
			);
		}
	};

	useEffect(() => {
		if (!itemRecord) return goBack();
		if (isEmpty(itemRecord.mappingDetails)) return;

		handlePopulateData(itemRecord);
	}, [itemRecord]);

	return renderForm();
};

MenuStructureForm.defaultProps = {
	itemRecord: {},
	goBack: () => null,
	autoFillDummyRef: {},
};

MenuStructureForm.propTypes = {
	itemRecord: PropTypes.object,
	goBack: PropTypes.func,
	autoFillDummyRef: PropTypes.object,
};

export default MenuStructureForm;