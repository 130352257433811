import React, {useEffect, useState} from 'react';
import {Form, Modal} from 'antd';
import {get, isEmpty, set} from 'lodash';
import {PACKAGING_OPTIONS} from 'utils/constants';
import PropTypes from 'prop-types';

const PreviewLabelModal = ({open, close, form, menuData}) => {
	const optionGroupsWatch = Form.useWatch('optionGroups', form);

	const [labels, setLabels] = useState([]);

	const handleInsertChildren = (labelArr, index, data) => {
		if (labelArr[index]?.children) {
			labelArr[index].children.push(data);
		} else {
			set(labelArr, [index, 'children'], [data]);
		}
	};

	const getOptionGroupLabel = id => {
		const foundOptionGroup = menuData?.optionGroups?.find(item => item.optionGroupId == id);
		return foundOptionGroup?.optionGroupLabel || '';
	};

	const handleGenerateLabel = () => {
		const output = [
			{
				parentId: menuData?.menuId,
				parentLabel: menuData?.menuLabel,
			},
		];

		const pendingLinks = [];

		for (const key in optionGroupsWatch) {
			const complexPackagingOption = get(optionGroupsWatch, [key, 'packaging']);

			if (complexPackagingOption?.type == PACKAGING_OPTIONS.DEFAULT) {
				handleInsertChildren(output, 0, {
					childId: key,
					childLabel: getOptionGroupLabel(key),
				});
			}

			if (complexPackagingOption?.type == PACKAGING_OPTIONS.SEPARATE) {
				output.push({
					parentId: key,
					parentLabel: getOptionGroupLabel(key),
				});
			}

			if (complexPackagingOption?.type == PACKAGING_OPTIONS.LINK && complexPackagingOption?.refId) {
				pendingLinks.push({
					childId: key,
					childLabel: getOptionGroupLabel(key),
					refId: complexPackagingOption.refId,
				});
			}
		}

		// Process pending links
		for (const link of pendingLinks) {
			const targetIndex = output.findIndex(el => el.parentId == link.refId);
			if (targetIndex !== -1) {
				handleInsertChildren(output, targetIndex, {
					childId: link.childId,
					childLabel: link.childLabel,
				});
			} else {
				console.warn(`No parent found for ${link.childId} with refId ${link.refId}`);
			}
		}

		setLabels(output);
	};

	useEffect(() => {
		if (open && !isEmpty(form)) {
			handleGenerateLabel();
		} else {
			setLabels([]);
		}
	}, [open]);

	return (
		<Modal
			title='Label Preview'
			width={400}
			onCancel={close}
			cancelButtonProps={{style: {display: 'none'}}}
			onOk={close}
			centered
			open={open}>
			<div className='flex flex-col gap-2 justify-center'>
				{
					labels.map((label, index) => {
						return index === 0 && isEmpty(label.children)
							? null
							:  (
								<div
									className='border border-antd-netural-5 rounded-lg p-4'
									key={label.parentId}>
									<span className='font-bold'>{label.parentLabel}</span>
									<div>
										{label?.children?.map(child => (
											<div key={child.childId}>{`-> ${child.childLabel}`}</div>
										))}
									</div>
								</div>
							);
					})
				}
			</div>
		</Modal>
	);
};

PreviewLabelModal.defaultProps = {
	open: () => null,
	close: () => null,
	form: {},
	menuData: {},
};

PreviewLabelModal.propTypes = {
	open: PropTypes.func,
	close: PropTypes.func,
	form: PropTypes.object,
	menuData: PropTypes.object,
};

export default PreviewLabelModal;