import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {ExclamationCircleFilled, MenuOutlined} from '@ant-design/icons';
import {Tag, Typography} from 'antd';
const {Text} = Typography;

const dayOptions = Object.values(localization.Global.DayOptions);

import localization from 'localization';

const CategoryItem = forwardRef((
	{category, onClickItem, dragHandleProps, selectedCategoryId, viewOnly, alertVisible,
		...restProps}, ref) => {

	return (
		<div
			ref={ref}
			onClick={onClickItem}
			{...restProps}
			className={clsx(
				'border border-antd-netural-4 border-collapse box-border hover:bg-antd-blue-1 bg-white cursor-pointer flex items-center',
				selectedCategoryId === category.categoryId && 'bg-antd-blue-1',
			)}
		>
			{
				!viewOnly && (
					<MenuOutlined
						{...dragHandleProps}
						className='text-page cursor-move p-4' />
				)
			}
			<div className='flex flex-1 items-center justify-between p-4'>
				<div className='flex items-center'>
					<div className='flex gap-4 pl-2 pr-6 items-center'>
						<div className='
					flex items-center justify-center px-2 py-1 text-xs leading-none bg-antd-blue-6 text-blue-50 rounded-full
					'>
							{category?.menus?.length}
						</div>
						<div className='flex flex-col'>
							<Text className='font-medium'>{`${category?.categoryLabel}${category.posName ? ` · ${category.posName}` : ''}`}</Text>
							<Text type="secondary">Sales Day: {
								Object.keys(dayOptions)?.length === category.categoryDays?.length
									? 'Every Day'
									: category.categoryDays?.map(day => dayOptions[day]).join(', ')
							}</Text>
							<Text type="secondary">Sales Hour: All Day</Text>
							{
								category.menus.filter(menu => menu.menuPrice == 0).length
									? (
										<div>
											<Tag className='mt-2'>{category.menus.filter(menu => menu.menuPrice == 0).length} menu with Rp0 price</Tag>
										</div>
									)
									: null
							}
						</div>
					</div>
				</div>
			</div>
			{
				alertVisible
					? (
						<div className='px-4 text-antd-red-5 text-base'>
							<ExclamationCircleFilled />
						</div>
					)
					:null
			}
		</div>
	);
});

CategoryItem.defaultProps = {
	category: {},
	onClickItem: () => null,
	dragHandleProps: {},
	selectedCategoryId: null,
	viewOnly: false,
	alertVisible: false,
};

CategoryItem.propTypes = {
	category: PropTypes.object,
	onClickItem: PropTypes.func,
	dragHandleProps: PropTypes.func,
	selectedCategoryId: PropTypes.number,
	viewOnly: PropTypes.bool,
	alertVisible: PropTypes.bool,
};

export default CategoryItem;