import React, {useRef, useState} from 'react';
import {Button, Tabs} from 'antd';

// Global components
import LocalPageHeader from 'components/LocalPageHeader';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';

// Local components
import GojekSettlement from './Platforms/GojekSettlement';
import GrabSettlement from './Platforms/GrabSettlement';
import ShopeeSettlement from './Platforms/ShopeeSettlement';

import './PlatformSettlement.less';

// Utils
import {exportToTsvFn, handleErrorFetch, salesPayloadHandler} from 'utils/utils';
import {ExportOutlined} from '@ant-design/icons';
import {getSettlement} from 'utils/request/sales';

const TABS = [
	{key: 'gojek-settlement', label: 'Gojek', component: GojekSettlement},
	{key: 'grab-settlement', label: 'Grab', component: GrabSettlement},
	{key: 'shopee-settlement', label: 'Shopee', component: ShopeeSettlement},
];

const PlatformSettlement = () => {
	const [activeTab, setActiveTab] = useState(TABS[0].key);
	const [exportFileModalVisible, setExportFileModalVisible] = useState(false);
	const [loadingDownload, setLoadingDownload] = useState(false);

	const filterRef = useRef();

	const handleExport = async ({fileName, type = 'export'}) => {
		try {
			setLoadingDownload(true);
			const metricPayload = salesPayloadHandler(filterRef?.current?.current?.getMetricFilter?.());

			const currentPlatform = TABS.find(platformSettlement => platformSettlement.key === activeTab);
			await exportToTsvFn({
				fileName,
				actionType: type,
				exportCall: () => getSettlement({
					params: metricPayload,
					platform: currentPlatform.label.toLowerCase(),
					isExport: true,
				}),
			});
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingDownload(false);
		}
	};

	const openExportFileModal = () => {
		setExportFileModalVisible(true);
	};

	const closeExportFileModal = () => {
		setExportFileModalVisible(false);
	};

	return (
		<>
			<ExportCSVOverlay
				providedFileName={`${activeTab}-${new Date().valueOf()}.csv`}
				fileFormat='csv'
				loading={loadingDownload}
				handleExport={handleExport}
				visible={exportFileModalVisible}
				close={closeExportFileModal}
				type={'export'}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					headerTitle={'Platform Settlement'}
					extra={[
						<Button
							onClick={openExportFileModal}
							icon={<ExportOutlined />}
							type='primary'
							key='export'>Export</Button>,
					]}
				/>
				<div className='PlatformSettlement'>
					<Tabs
						activeKey={activeTab}
						onChange={setActiveTab}>
						{TABS.map(platform => (
							<Tabs.TabPane
								tab={platform.label}
								key={platform.key}>
								<platform.component
									inView={activeTab === platform.key}
									filterRef={filterRef} />
							</Tabs.TabPane>
						))}
					</Tabs>
				</div>
			</div>
		</>
	);
};

export default PlatformSettlement;