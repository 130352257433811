import React from 'react';
import {Spin} from 'antd';

const LoadingPage = () => {
	return (
		<div className='min-h-screen flex items-center justify-center bg-antd-page-background'>
			<Spin size="large" />
		</div>
	);
};

export default LoadingPage;
