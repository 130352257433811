import React, {useState, useRef} from 'react';
import {OUTLET_TYPE, STORAGE_KEY, USER_ROLES} from 'utils/constants';
import {exportOutletData, getOutletData} from 'utils/request/outlet';
import {authChecker, exportToTsvFn, generateOutletCSVTemplate} from 'utils/utils';
import OutletTable from '../components/Table';

import OutletBrandHeader from './OutletBrandHeader';
import {useSelector} from 'react-redux';

const OutletBrand = () => {
	const containerRef = useRef(null);
	const refetchTableRef = useRef(null);
	const auth = useSelector(state => state.user);

	const [exportQuery, setExportQuery] = useState(null);

	const scrollToTop = () => {
		if (containerRef.current) {
			containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
		}
	};

	const goToEditPage = record => {
		sessionStorage.setItem(STORAGE_KEY.OUTLET_ITEM_DETAIL, JSON.stringify(record));
		window.open('/outlet/brand/edit', '_blank');
	};

	const tableColumn = [
		{
			title: 'Brand ID',
			dataIndex: 'id',
			key: 'id',
			fixed: 'left',
			width: 100,
		},
		{
			title: 'Brand Name',
			dataIndex: 'label',
			key: 'label',
		},
		{
			title: 'Brand Short Name',
			dataIndex: 'shortName',
			key: 'shortName',
		},
		{
			title: 'Active',
			dataIndex: 'isActive',
			key: 'isActive',
			valueEnum: {
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isActive ? 'text-green-400' : 'text-red-400'}`
				}>{record.isActive ? 'Yes' : 'No'}</div>
			),
		},
		{
			title: 'New Brand',
			dataIndex: 'isNew',
			key: 'isNew',
			valueEnum: {
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isNew ? 'text-green-400' : 'text-red-400'}`
				}>{record.isNew ? 'Yes' : 'No'}</div>
			),
		},
		{
			title: 'Sub Brand',
			dataIndex: 'isSubBrand',
			key: 'isSubBrand',
			valueEnum: {
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isSubBrand ? 'text-green-400' : 'text-red-400'}`
				}>{record.isSubBrand ? 'Yes' : 'No'}</div>
			),
		},
		{
			title: 'Show on Web',
			dataIndex: 'isPublic',
			key: 'isPublic',
			valueEnum: {
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isPublic ? 'text-green-400' : 'text-red-400'}`
				}>{record.isPublic ? 'Yes' : 'No'}</div>
			),
		},
		...(
			authChecker({
				auth,
				requiredRolesArr: [USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR],
			}) ? [{
					title: 'Action',
					align: 'center',
					key: 'action',
					fixed: 'right',
					search: false,
					render: (_, record) => (
						<span
							onClick={() => goToEditPage(record)}
							className='cursor-pointer text-blue-400'
						>
							Edit
						</span>
					),
				}] : []
		),
	];

	const handleExport = async ({fileName, type = 'template'}) => {
		await exportToTsvFn({
			fileName,
			actionType: type,
			exportCall: () => exportOutletData(exportQuery, OUTLET_TYPE.BRAND),
			tsvTemplate: generateOutletCSVTemplate(OUTLET_TYPE.BRAND),
		});
	};

	return (
		<div
			ref={containerRef}
			className="bg-white h-full overflow-auto">
			<OutletBrandHeader
				refetchTableRef={refetchTableRef}
				handleExport={handleExport}
			/>
			<OutletTable
				tableColumn={tableColumn}
				fetchFn={getOutletData}
				tableType={OUTLET_TYPE.BRAND}
				refetchTableRef={refetchTableRef}
				scrollToTop={scrollToTop}
				setExportQuery={setExportQuery}
			/>
		</div>
	);
};

export default OutletBrand;