import {useState, useEffect} from 'react';
import moment from 'moment';
import ReactJSON from 'react-json-view';
import PropTypes from 'prop-types';
import {Descriptions, Timeline, Typography} from 'antd';
import {CheckCircleFilled, ClockCircleOutlined, CloseCircleFilled} from '@ant-design/icons';
import localization from 'localization';

// Local styles
import './orderDetailModal.less';

const {Item} = Descriptions;

const INJECTION_KEY = {
	ACCEPTED_AT: 'acceptedAt',
	FAILED_AT: 'failedAt',
	MAPPING_INITIATE: 'initiateMapping',
	MAPPING_SUCCESS: 'mappingSuccess',
	MAPPING_FAILED: 'mappingFailed',
	INJECTION_INITIATE: 'initiateInjection',
	INJECTION_SUCCESS: 'injectionSuccess',
	INJECTION_FAILED: 'injectionFailed',
};

const PAIR_KEY = {
	ACCEPTED_AT: 'acceptedAt',
	PAIRED_AT: 'pairedAt',
	UNPAIRED_AT: 'unpairedAt',
	MARKDONE_AT: 'markDoneAt',
	VOIDED_AT: 'voidedAt',
};

const OrderTimeline = ({data, isPos}) => {
	const locale = localization.WatchTower.WatchTowerOrder.orderDetailModal.orderTimeline;
	const {isInjected, processedBy, posPayload} = data;
	const InjectionIcon = isPos && data.pairedAt
		? CheckCircleFilled
		: isInjected
			? CheckCircleFilled
			: CloseCircleFilled;
	const [injectionTimeline, setInjectionTimeline] = useState([]);
	// Fn
	const errorCodeMapper = errorCode => {
		switch (errorCode) {
		case 11:
			return 'Beda Harga';
		case 12:
			return 'Robot Offline';
		case 13:
			return 'POS Offline';
		case 14:
			return 'Menu Error';
		case 15:
			return 'Mapping Not Found';
		case 10:
		default:
			return 'Bermasalah';
		}
	};

	const generatePosTimeline = () => {
		const timelineOutput = [
			{timestamp: data.acceptedAt, key: PAIR_KEY.ACCEPTED_AT, label: locale.orderAccepted},
			{timestamp: data.pairedAt, key: PAIR_KEY.PAIRED_AT, label: locale.orderPaired.replace('{{method}}', data.pairMethod)},
			{timestamp: data.unpairedAt, key: PAIR_KEY.UNPAIRED_AT, label: locale.orderUnpaired},
			{timestamp: data.markDoneAt, key: PAIR_KEY.MARKDONE_AT, label: locale.markDoneAt},
			{timestamp: data.voidedAt, key: PAIR_KEY.VOIDED_AT, label: locale.voidedAt.replace('{{voider}}', data?.voider?.username || '')},
		  ];


		// Remove null or undefined values from the timestamps array
		const validTimestamps = timelineOutput.filter(data => data.timestamp !== null && data.timestamp !== undefined);

		// Sort the valid timestamps in ascending order
		validTimestamps.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

		setInjectionTimeline(validTimestamps);
	};
	const generateTimeline = () => {
		const tempInjectionTimeline = [
			{
				key: INJECTION_KEY.ACCEPTED_AT,
				label: locale.orderAccepted,
				timestamp: data?.acceptedAt || data?.createdAt,
			},
			{
				key: INJECTION_KEY.MAPPING_INITIATE,
				label: locale.initiateMapping,
				timestamp: data?.created_at,
			},
		];

		// Check if order is problematic (problematic code exist)
		if (data?.problematicCode) {
			tempInjectionTimeline.push(
				{
					key: INJECTION_KEY.MAPPING_FAILED,
					label: locale.mappingFailed.replace('{{reason}}', errorCodeMapper(data?.problematicCode)),
					timestamp: data?.failedAt,
				},
			);
		} else {
			tempInjectionTimeline.push(
				{
					key: INJECTION_KEY.MAPPING_SUCCESS,
					label: locale.mappingSuccess,
					timestamp: null,
				},
			);

			if (data?.OrderInjections?.length) {
				const sortedInjectionTime = data?.OrderInjections.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
				sortedInjectionTime.forEach(injectionAttemp => {
					if (injectionAttemp.status === 'success') {
						tempInjectionTimeline.push(
							{
								key: INJECTION_KEY.INJECTION_INITIATE,
								label: locale.initiateInjection,
								timestamp: injectionAttemp.createdAt,
							},
							{
								key: INJECTION_KEY.INJECTION_SUCCESS,
								label: locale.injectionSuccess,
								timestamp: injectionAttemp.successAt,
							},
						);
					} else if (injectionAttemp.status === 'failed') {
						tempInjectionTimeline.push(
							{
								key: INJECTION_KEY.INJECTION_INITIATE,
								label: locale.initiateInjection,
								timestamp: injectionAttemp.createdAt,
							},
							{
								key: INJECTION_KEY.INJECTION_FAILED,
								label: locale.injectionFailed.replace('{{reason}}', errorCodeMapper(injectionAttemp.injectErrorCode)),
								timestamp: injectionAttemp.failedAt,
							},
						);
					}
				});
			}
		}

		setInjectionTimeline(tempInjectionTimeline.filter(item => item.timestamp));
	};
	const getTimelineStyle = (timelineKey, index) => {
		const TimelineStyle = {
			dot: <ClockCircleOutlined />,
			color: null,
		};
		switch (timelineKey) {
		case INJECTION_KEY.INJECTION_SUCCESS:
		case INJECTION_KEY.MAPPING_SUCCESS:
		case PAIR_KEY.PAIRED_AT:
		case PAIR_KEY.MARKDONE_AT:
			if (timelineKey === PAIR_KEY.MARKDONE_AT) {
				if (((index + 1) > [PAIR_KEY.ACCEPTED_AT, PAIR_KEY.PAIRED_AT].length)) {
					TimelineStyle.dot = <CheckCircleFilled />;
					TimelineStyle.color = 'green';
				}
			} else {
				TimelineStyle.dot = <CheckCircleFilled />;
				TimelineStyle.color = 'green';
			}
			break;
		case INJECTION_KEY.INJECTION_FAILED:
		case INJECTION_KEY.MAPPING_FAILED:
		case PAIR_KEY.UNPAIRED_AT:
		case PAIR_KEY.VOIDED_AT:
			TimelineStyle.dot = <CloseCircleFilled />;
			TimelineStyle.color = 'red';
			break;
		}
		return TimelineStyle;
	};
	// Lifecycle
	useEffect(() => {
		isPos ? generatePosTimeline() : generateTimeline();
	}, [data]);
	// Render
	return (
		<Descriptions
			bordered
			className="WatchTowerOrderInfo">
			{
				isPos
					? (
						<>
							<Item label={locale.paired}>
								<InjectionIcon className={`text-2xl ${data.pairedAt ? 'text-antd-green-6' : 'text-antd-red-5'}`} />
							</Item>
							<Item
								label={locale.processBy}
								className='capitalize'
								span={2}>
								{data.isManualInput ? 'POS' : 'Robot'}
							</Item>
						</>
					)
					: (
						<>
							<Item label={locale.posInject}>
								<InjectionIcon className={`text-2xl ${isInjected ? 'text-antd-green-6' : 'text-antd-red-5'}`} />
							</Item>
							<Item
								label={locale.manualFlag}
								className='capitalize'
								span={2}>
								{processedBy || '-'}
							</Item>
						</>
					)
			}
			<Item
				label={locale.timeline}
				span={3}>
				{
					injectionTimeline.length
						? (
							<Timeline
								reverse
							>
								{
									injectionTimeline.map((timeline, index) => (
										<Timeline.Item
											key={timeline?.key}
											{...getTimelineStyle(timeline.key, index)}
										>
											<div>
												<div>
													<Typography.Text className='font-medium'>{timeline.label}</Typography.Text>
												</div>
												{
													timeline?.timestamp
														? (
															<div>
																<Typography.Text type='secondary'>
																	{
																		moment(timeline?.timestamp).format('YYYY-MM-DD HH:mm:ss')
																	}
																</Typography.Text>
															</div>
														)
														: null
												}
											</div>
										</Timeline.Item>
									))
								}
							</Timeline>
						)
						: null
				}
			</Item>
			{
				isPos ? null : (
					<Item
						label={locale.payload}
						span={3}>
						{
							isInjected ? <ReactJSON src={posPayload} /> : '-'
						}
					</Item>
				)
			}
		</Descriptions>
	);
};

OrderTimeline.defaultProps = {
	data: {},
	isPos: false,
};

OrderTimeline.propTypes = {
	data: PropTypes.object,
	isPos: PropTypes.bool,
};

export default OrderTimeline;
