import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Form, Input, InputNumber, Modal, notification, Popconfirm, Radio, Select} from 'antd';

import {handleErrorFetch, numberFormat} from 'utils/utils';
import {editOutletItem, getOutletData} from 'utils/request/outlet';
import {OUTLET_TYPE} from 'utils/constants';

import localization from 'localization';
import {debounce} from 'lodash';
const locale = localization.Outlet.Activation.Table.EditModal;

const EditOutletModal = ({visible, close, outletData, refetch, brandList}) => {
	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState(false);
	const [locationList, setLocationList] = useState([]);

	const handleSubmit = async e => {
		try {
			setIsLoading(true);
			const payload = {
				merchantId: outletData.id,
				locationId: e.locationId,
				brandId: e.brandId,
				platformId: outletData.platformId,
				autoInjectFlag: e.autoInjectFlag === 1 ? true : false,
				orderFee: e.orderFee,
				merchantAppUsername: e.merchantAppUsername,
				merchantAppPassword: e.merchantAppPassword,
			};

			const response = await editOutletItem(payload, OUTLET_TYPE.MERCHANT);
			if (response.success) {
				notification.open({
					message: locale.Success.message,
					description: locale.Success.description,
					type: 'success',
				});
				close();
				refetch();
			} else throw {};

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}
	};

	const searchOutletData = async ({value, type}) => {
		try {
			setIsLoading(true);
			const response = await getOutletData({limit: 5, page: 0, search: {label: value}}, type);
			if (response.success) {
				const setterFn = type === OUTLET_TYPE.LOCATION ? setLocationList : null;
				setterFn(response.data.rows);
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(searchOutletData, 1000), []);

	useEffect(() => {
		if (!visible) return;
		setLocationList([outletData.Location]);
		form.setFieldsValue({
			locationId: outletData.locationId,
			brandId: outletData.brandId,
			autoInjectFlag: outletData.autoInjectFlag ? 1 : 0,
			orderFee: outletData.orderFee,
			merchantAppUsername: outletData.merchantAppUsername,
			merchantAppPassword: outletData.merchantAppPassword,
		});
	}, [visible]);

	return (
		<Modal
			visible={visible}
			width={572}
			title={outletData?.label}
			centered
			onCancel={close}
			footer={(
				<div>
					<Popconfirm
						placement='topRight'
						onConfirm={close}
						okText={locale.yes}
						cancelText={locale.no}
						title={locale.cancelConfirmation} >
						<Button>{locale.cancelText}</Button>
					</Popconfirm>
					<Button
						loading={isLoading}
						onClick={() => form.submit()}
						htmlType='submit'
						type='primary'>{locale.okText}</Button>
				</div>
			)}
		>
			<Form
				form={form}
				layout="vertical"
				name="outletForm"
				scrollToFirstError
				onFinish={e => handleSubmit(e)}
				requiredMark={false}
			>
				{/* Location */}
				<Form.Item
					label={locale.locationLabel}
					name="locationId"
					rules={[{required: true, message: locale.locationLabelRequired}]}
				>
					<Select
						showSearch
						onSearch={value => debounceFn({value, type: OUTLET_TYPE.LOCATION})}
						placeholder={locale.locationLabelPlaceholder}
						filterOption={((_, option) => option)}
					>
						{
							locationList.map(location => (
								<Select.Option
									key={location.id}
									value={location.id}>{location.id} - {location.label}</Select.Option>
							))
						}
					</Select>
				</Form.Item>

				{/* Brand */}
				<Form.Item
					label={locale.brandLabel}
					name="brandId"
					rules={[{required: true, message: locale.brandLabelRequired}]}
				>
					<Select
						showSearch
						placeholder={locale.brandLabelPlaceholder}
						filterOption={(input, option) => option.children?.join?.(' ')?.toLowerCase?.().includes(input.toLowerCase())}
					>
						{
							brandList.map(brand => (
								<Select.Option
									key={brand.id}
									value={brand.id}>{brand.id} - {brand.label}</Select.Option>
							))
						}
					</Select>
				</Form.Item>

				{/* Auto Inject */}
				<Form.Item
					label={locale.autoInjectLabel}
					name="autoInjectFlag"
				>
					<Radio.Group>
						<Radio value={1}>{locale.on}</Radio>
						<Radio value={0}>{locale.off}</Radio>
					</Radio.Group>
				</Form.Item>

				{/* Packaging Fee */}
				<Form.Item
					label={locale.packagingFeeLabel}
					name="orderFee"
					rules={[{required: true, message: locale.packagingFeeLabelRequired}]}
				>
					<InputNumber
						className='w-full'
						parser={value => value.replace(/[,.]/g, '')}
						formatter={value => {
							if (value) {
								return numberFormat(value);
							}
							return value;
						}}
						placeholder={locale.packagingFeeLabelPlaceholder}
						controls={false}
						addonBefore='Rp' />
				</Form.Item>

				{/* Merchant Username */}
				<Form.Item
					label={locale.merchantUsername}
					name="merchantAppUsername"
				>
					<Input
						className='w-full'
						placeholder={locale.merchantUsernamePlaceholder} />
				</Form.Item>

				{/* Merchant Password */}
				<Form.Item
					label={locale.merchantPassword}
					name="merchantAppPassword"
				>
					<Input
						className='w-full'
						placeholder={locale.merchantPasswordPlaceholder} />
				</Form.Item>


			</Form>
		</Modal>
	);
};

EditOutletModal.defaultProps = {
	visible: false,
	close: () => null,
	outletData: {},
	refetch: () => null,
	brandList: [],
};

EditOutletModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	outletData: PropTypes.object,
	refetch: PropTypes.func,
	brandList: PropTypes.array,
};

export default EditOutletModal;