import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {ExclamationCircleFilled, MenuOutlined} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import {getStationLabel, moneyFormat} from 'utils/utils';
import {Tag} from 'antd';

const OptionGroupItem = forwardRef(({optionGroup, dragHandleProps, viewOnly, duplicateChildsId,
	...restProps}, ref) => {
	const modifiers = optionGroup?.menus || optionGroup?.details.menus;

	return (
		<div
			ref={ref}
			{...restProps}
			className='flex bg-white border border-antd-netural-4 items-center'>
			{
				!viewOnly && (
					<div
						{...dragHandleProps}
						className='self-stretch flex items-center p-6'
					><MenuOutlined /></div>
				)
			}
			<div className={clsx('flex py-4',
				viewOnly
					? 'px-6'
					: 'pr-6',
			)}>
				<div>
					<div>
						<Text type="secondary">{`[${optionGroup.optionGroupId}]`}</Text> <Text strong>{optionGroup.label || optionGroup.optionGroupLabel}</Text> <Text type="secondary">(Min {optionGroup.minSelection}, Max {optionGroup.maxSelection})</Text></div>
					<div className='pt-1 flex flex-col gap-3'>
						<div className='flex flex-col'>
							{
								modifiers.map(el => (
									<span
										key={el.menuId}
										className='flex gap-2 items-center'>
										<Text
											type={duplicateChildsId[el.menuId] ? 'danger' :'secondary'}
										>{`[${el.menuId}] `}{el.menuStation ? `[${getStationLabel(el.menuStation)?.split?.(' ')?.[0]}] - ` : ''}{el.menuLabel} - {moneyFormat({value: el.menuPrice})}</Text>
										{
											duplicateChildsId[el.menuId] && <ExclamationCircleFilled className='text-antd-red-5' />
										}
									</span>
								),
								)
							}
						</div>
						{
							optionGroup.tags?.length ? (
								<div className='flex flex-wrap gap-y-2'>
									{optionGroup.tags.map((tag, index) => (
										<Tag
											className='uppercase'
											key={index}
											color="blue">{tag}</Tag>
									))}
								</div>
							) : null
						}
					</div>
				</div>
			</div>
		</div>
	);
});

OptionGroupItem.defaultProps = {
	optionGroup: {},
	dragHandleProps: {},
	duplicateChildsId: {},
	viewOnly: false,
};

OptionGroupItem.propTypes = {
	optionGroup: PropTypes.object,
	dragHandleProps: PropTypes.object,
	duplicateChildsId: PropTypes.object,
	viewOnly: PropTypes.bool,
};

export default OptionGroupItem;