import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Table, Typography} from 'antd';
const {Paragraph} = Typography;

import localization from 'localization';
const locale = localization.WatchTower.WatchTowerMenu.relatedMenuModal;

const ModalRelatedMenu = ({visible, close, data, parentMenuLabel}) => {

	const columns = [
		{
			title: locale.menuId,
			dataIndex: 'menuId',
			key: 'menuId',
			width: 150,
			render: menuId => <Paragraph copyable>{menuId}</Paragraph>,
		},
		{
			title: locale.menuLabel,
			dataIndex: 'menuLabel',
			key: 'menuLabel',
		},
	];

	return (
		<Modal
			title={locale.title.replace('{{menuLabel}}', parentMenuLabel)}
			visible={visible}
			width={868}
			centered
			onOk={close}
			onCancel={close}
			cancelButtonProps={{style: {display: 'none'}}}
		>
			<Table
				dataSource={data}
				columns={columns}
				pagination={false}
				scroll={{y: 400}}
			/>
		</Modal>
	);
};

ModalRelatedMenu.defaultProps = {
	visible: false,
	close: () => null,
	data: {},
	parentMenuLabel: '',
};

ModalRelatedMenu.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
	parentMenuLabel: PropTypes.string,
};

export default ModalRelatedMenu;