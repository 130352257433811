import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Modal} from 'antd';
import ProTable from '@ant-design/pro-table';

import localization from 'localization';
const locale = localization.POSSetting.SalesChannel.Table;

const LocationsModal = ({visible, salesChannelData, close}) => {
	const [filterBy, setFilterBy] = useState('');
	const tableRef = useRef(null);

	const columns = [
		{
			key: 'label',
			dataIndex: 'label',
			title: locale.locationName,
		},
	];

	const filteredData = () => {
		const output = filterBy
			? salesChannelData?.locations?.filter?.(location => location.label.toLowerCase().includes(filterBy.toLocaleLowerCase()))
			: salesChannelData.locations;

		return output?.sort?.((a, b) => {
			const labelA = a.label.toUpperCase();
			const labelB = b.label.toUpperCase();

			if (labelA < labelB) {
				return -1;
			}
			if (labelA > labelB) {
				return 1;
			}
			return 0;
		});
	};

	useEffect(() => {
		if (visible) {
			tableRef?.current?.reset?.();
			setFilterBy('');
		}
	}, [visible]);

	return (
		<Modal
			destroyOnClose={true}
			title={salesChannelData.name}
			visible={visible}
			width={868}
			centered
			onOk={close}
			onCancel={close}
			cancelButtonProps={{style: {display: 'none'}}}
		>
			<ProTable
				actionRef={tableRef}
				className='ResetSearchTableMargin'
				rowKey='id'
				columns={columns}
				toolBarRender={false}
				dataSource={filteredData()}
				scroll={{x: 'max-content'}}
				search={{
					layout: 'vertical',
				}}
				onReset={() => {
					tableRef?.current?.reset?.();
					setFilterBy('');
				}}
				onSubmit={data => setFilterBy(data.label || '')}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</Modal>
	);
};

LocationsModal.defaultProps = {
	salesChannelData: {},
	visible: false,
	close: () => null,
};

LocationsModal.propTypes = {
	salesChannelData: PropTypes.object,
	visible: PropTypes.bool,
	close: PropTypes.func,
};

export default LocationsModal;