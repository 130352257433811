import React from 'react';
import PropTypes from 'prop-types';

import {Button, Modal} from 'antd';
import {getLabel} from 'utils/utils';
import {MASTERLIST_TYPE} from 'utils/constants';

import MaterialDetail from './MaterialDetail';
import MenuDetail from './MenuDetail';

const DetailModal = ({visible, content, close, masterlistType}) => {

	const renderContent = () => {
		switch (masterlistType) {
		case MASTERLIST_TYPE.MENU:
			return <MenuDetail content={content} />;
		case MASTERLIST_TYPE.MATERIAL:
			return <MaterialDetail content={content} />;
		default:
			return <DetailModal content={content} />;
		}
	};

	return (
		<Modal
			id="modal-detail"
			title={(
				<span className='leading-6'>
					{`Detail ${getLabel(masterlistType, ' ', true)} - ${content.menuLabel || content.materialLabel}`}
				</span>
			)}
			visible={visible}
			onCancel={close}
			width={1200}
			footer={(
				<Button
					type='primary'
					onClick={close}>OK</Button>
			)}
			cancelButtonProps={null}
			centered
		>
			{renderContent()}
		</Modal>
	);
};

DetailModal.defaultProps = {
	visible: false,
	content: {},
	close: () => null,
	masterlistType: null,
};

DetailModal.propTypes = {
	visible: PropTypes.bool,
	content: PropTypes.object,
	close: PropTypes.func,
	masterlistType: PropTypes.string,
};

export default DetailModal;
