import React, {useRef, useState} from 'react';

import ImportCSVOverlay from 'components/Overlay/ImportCSVOverlay';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';
import LocalPageHeader from 'components/LocalPageHeader';
import SalesChannelTable from './SalesChannelTable';

import {useNavigate} from 'react-router-dom';

import {Button, Upload} from 'antd';
import {ExportOutlined, FormOutlined, ImportOutlined} from '@ant-design/icons';

import {getSalesChannel} from 'utils/request/posSetting';
import {exportToTsvFn, handleErrorFetch} from 'utils/utils';

import localization from 'localization';
const locale = localization.POSSetting.SalesChannel;

const SalesChannel = () => {
	const navigate = useNavigate();
	const tableRef = useRef(null);

	const refetchTable = () => {
		tableRef?.current?.reload?.();
	};

	const [loadingDownload, setLoadingDownload] = useState(false);
	const [exportFileModalVisible, setExportFileModalVisible] = useState(false);
	const [importFileModalVisible, setImportFileModalVisible] = useState(false);

	const [uploadedFile, setUploadedFile] = useState(null);

	const handleExport = async ({fileName, type = 'export'}) => {
		try {
			setLoadingDownload(true);
			await exportToTsvFn({
				fileName,
				actionType: type,
				exportCall: () => getSalesChannel({
					isExport: true,
				}),
			});
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingDownload(false);
		}
	};

	const closeImportCSVOverlay = () => {
		setUploadedFile(null);
		setImportFileModalVisible(false);
	};

	const handleFileUpload = async e => {
		if (!e.fileList.length) return;

		const uploadedFileTemp = e.fileList[0].originFileObj;

		setUploadedFile(uploadedFileTemp);
		setImportFileModalVisible(true);
	};

	return (
		<div className='bg-white h-full overflow-auto'>
			<ImportCSVOverlay
				masterlistType={'pos-settings-sales-channel'}
				refetchTable={refetchTable}
				handleFileUpload={handleFileUpload}
				uploadedFile={uploadedFile}
				visible={importFileModalVisible}
				importType={'add'}
				close={closeImportCSVOverlay}
			/>
			<ExportCSVOverlay
				loading={loadingDownload}
				providedFileName={`sales-channel-fresto-${new Date().valueOf()}.csv`}
				fileFormat='csv'
				handleExport={handleExport}
				visible={exportFileModalVisible}
				close={() => setExportFileModalVisible(false)}
				type={'export'}
			/>
			<LocalPageHeader
				headerTitle={locale.title}
				customBreadcrumbLabel={locale.breadcrumb}
				extra={[
					<Button
						onClick={() => setExportFileModalVisible(true)}
						icon={<ExportOutlined />}
						key='export'>{locale.export}
					</Button>,
					<Upload
						key="import"
						accept=".csv"
						className='inline-block'
						showUploadList={false}
						beforeUpload={() => false}
						maxCount={1}
						onChange={e => handleFileUpload(e)}
					>
						<Button
							icon={<ImportOutlined />}
							key='import'>{locale.import}
						</Button>
					</Upload>,
					<Button
						onClick={() => navigate('/pos-settings/sales-channel/new')}
						icon={<FormOutlined />}
						type='primary'
						key='new'>{locale.new}
					</Button>,
				]}
			/>
			<SalesChannelTable ref={tableRef} />
		</div>
	);
};

export default SalesChannel;