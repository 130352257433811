import moment from 'moment';
import PropTypes from 'prop-types';
import {Descriptions} from 'antd';
import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import localization from 'localization';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import {merchantOrderStatusHandler, moneyFormat} from 'utils/utils';

// Local components
import OrderStatus from '../OrderStatus';

// Local styles
import './orderDetailModal.less';
import {ORDER_MERCHANT_STATUS_CONST} from 'utils/constants';

const {Item} = Descriptions;

const OrderInfo = ({data, isPos}) => {
	const locale = localization.WatchTower.WatchTowerOrder.orderDetailModal.orderInfo;
	const {
		createdAt, externalId, Merchant, posStatus, status, type,
		isInjected, OrderInjections, grandTotal, orderDetails,
	} = data;
	const customerPosDetails = OrderInjections?.find(injectAttempt => injectAttempt?.status === 'success')?.injectDetails;
	const InjectionIcon = isInjected ? CheckCircleFilled : CloseCircleFilled;
	// Render
	return (
		<Descriptions
			bordered
			className="WatchTowerOrderInfo">
			{
				isPos ? (
					<Item
						span={3}
						label={locale.mergeLink}>
						{
							data?.pairOrderId
								? (
									<span
										className='text-antd-blue-6 cursor-pointer'
										onClick={() => {
											window.open(`${window.location.origin}/watchtower/order/pos/${data?.pairOrderId}`);
										}}>{`${window.location.origin}/watchtower/order/pos/${data?.pairOrderId}`}</span>
								)
								: '-'
						}
					</Item>
				) : null
			}
			<Item label={locale.orderTime}>
				{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}
			</Item>
			<Item label={locale.externalId}>
				{externalId || orderDetails?.externalId || '-'}
			</Item>
			<Item label={locale.customer}>
				{data?.inputDetails?.customerName || '-'}
			</Item>
			<Item label={locale.location}>
				{Merchant?.Location.label}
			</Item>
			<Item label={locale.salesChannel}>
				{Merchant?.Platform.label}
			</Item>
			<Item label={locale.brand}>
				{Merchant?.Brand.label}
			</Item>
			<Item
				label={locale.status}
				span={isPos ? 1 : 3}
			>
				<OrderStatus status={isPos ? status : posStatus} />
			</Item>
			{
				isPos ? (
					<>
						<Item
							label={locale.reason}>
							{data?.voidReason || data?.orderDetails?.cancelledReason || '-'}
						</Item>
						<Item
							label={locale.formSubmitted}>
							{isEmpty(data.reimbursement)
								? '-'
								: (
									<div className={clsx(data.reimbursement.formSubmitted ? 'text-antd-green-6' : 'text-antd-red-6')}>
										{data.reimbursement.formSubmitted ? locale.yes : locale.no}
									</div>
								)}
						</Item>
					</>
				) : null
			}
			<Item
				className='capitalize'
				label={locale.orderType} >
				{type}
			</Item>
			<Item label={locale.totalAmount}>
				{moneyFormat({value: grandTotal})}
			</Item>
			{
				isPos ? (
					<Item
						className='capitalize'
						label={locale.paymentMethod}>
						{data?.PosPaymentMethod?.name}
					</Item>
				) : null
			}
			<Item label={locale.queueNumber}>
				{customerPosDetails?.queueNum || data?.orderCode || '-'}
			</Item>
			{
				isPos
					? (
						<Item
							label={locale.merchantStatus}
							span={2}>
							{merchantOrderStatusHandler({status: orderDetails?.status, type, pickUpReadyAt: orderDetails?.pickUpReadyAt})}
						</Item>
					)
					: (
						<Item
							label={locale.salesNumber}
							span={1}>
							{customerPosDetails?.salesNum || orderDetails?.salesNum || '-'}
						</Item>
					)
			}
			{
				isPos
					? null
					: (
						<Item label={locale.posInject}>
							<InjectionIcon className={`text-2xl ${isInjected ? 'text-antd-green-6' : 'text-antd-red-5'}`} />
						</Item>
					)
			}
			{
				isPos ? (
					<>
						<Item
							span={1}
							className='capitalize'
							label={locale.pairingStatus}>
							{data.pairStatus}
						</Item>
						<Item
							className='capitalize'
							label={locale.pairingMethod}
							span={1}
						>
							{data?.pairMethod || '-'}
						</Item>
						<Item
							span={1}
							label={locale.pairReason}>
							{data?.pairReason || '-'}
						</Item>
					</>
				) : null
			}
			{
				isPos
					? null
					: (
						<Item
							label={locale.merchantStatus}
							span={2}>
							{
								Object.values(ORDER_MERCHANT_STATUS_CONST)
									.find(MERCHANT_STATUS => (MERCHANT_STATUS.value === status))
									?.label
							}
						</Item>
					)
			}
			<Item
				label={locale.billNum}
				span={3}>
				{customerPosDetails?.billNum || data?.billNumber || '-'}
			</Item>
			{
				isPos ? (
					<>
						<Item
							label={locale.traceNumber}
							span={3}>
							{data?.inputDetails?.additionalInfo || '-'}
						</Item>
						<Item
							label={locale.bookingId}
							span={3}>
							{data?.merchantRefId || '-'}
						</Item>
						<Item
							label={locale.cashier}
							span={3}>
							{data?.creator?.username || '-'}
						</Item>
					</>
				) : null
			}
		</Descriptions>
	);
};

OrderInfo.defaultProps = {
	data: {},
	isPos: false,
};

OrderInfo.propTypes = {
	data: PropTypes.object,
	isPos: PropTypes.bool,
};

export default OrderInfo;