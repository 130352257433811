import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, DatePicker, Form, Modal, Radio, Transfer, Typography, notification} from 'antd';

import localization from 'localization';
import {useNavigate} from 'react-router-dom';
import {handleErrorFetch} from 'utils/utils';
import {getOutletData} from 'utils/request/outlet';
import {OUTLET_TYPE} from 'utils/constants';
import {createReleaseSchedule} from 'utils/request/admin';
import PickerModal from './PickerModal';
import {get, isEmpty} from 'lodash';
import {FileDoneOutlined} from '@ant-design/icons';
const locale = localization.Admin.ReleaseScheduler.Form;

import moment from 'moment';

const pickerModalInitialState = {
	visible: false,
	type: null,
};

const ReleaseSchedulerFormBody = ({isDetail, scheduleData}) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const selectedLocations = Form.useWatch('locationIds', form); // Used to provide value for targetKeys

	const [loading, setLoading] = useState(false);
	const [defaultLocationList, setDefaultLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);
	const [pickerModalConfig, setPickerModalConfig] = useState(pickerModalInitialState);

	const [selectedVersion, setSelectedVersion] = useState({});
	const [selectedLocationGroup, setSelectedLocationGroup] = useState({});

	const handleSubmit = async e => {
		try {
			setLoading(true);

			const payload = {
				posVersionId: selectedVersion.id,
				releaseDate: e.releaseDate.startOf('minute').toISOString(),
				forceUpdate: e.forceUpdate,
				locationIds: e.locationIds,
			};

			const response = await createReleaseSchedule(payload);
			if (response.success) {
				notification.success({
					message: locale.CreateSuccessNotification.message,
					description: locale.CreateSuccessNotification.description,
				});
			}
		} catch (error) {
			notification.error({
				message: locale.CreateFailNotification.message,
				description: locale.CreateFailNotification.description,
			});
		} finally {
			setLoading(false);
			navigate('/admin/version-settings/release-scheduler');
		}
	};

	const beforeSubmit = e => {
		Modal.confirm({
			centered: true,
			title: locale.CreateNewConfirmation.message,
			content: locale.CreateNewConfirmation.description,
			okText: locale.CreateNewConfirmation.okText,
			cancelText: locale.CreateNewConfirmation.cancelText,
			onOk() {
				handleSubmit(e);
			},
		});
	};

	const initialFetch = async () => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {limit: 0, filterGroup: true},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setDefaultLocationList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const openPickerModal = type => {
		setPickerModalConfig({
			visible: true,
			type,
		});
	};

	const pickVersionHandler = record => {
		setSelectedVersion(record);
	};

	const pickLocationGroupHandler = record => {
		setSelectedLocationGroup(record);
		form.setFieldsValue({
			locationIds: record?.locations?.map(location => location.id),
		});
	};

	const pickerAction = record => {
		if (pickerModalConfig.type === 'version') pickVersionHandler(record);
		else pickLocationGroupHandler(record);
	};

	const closePickerModal = () => setPickerModalConfig(pickerModalInitialState);

	useEffect(() => {
		initialFetch();
		if (!isEmpty(scheduleData) && (isDetail)) {

			const locationGroupData = get(scheduleData, ['locations', 0, 'PosVersionLocationGroups', [0]], {});

			if (!isEmpty(locationGroupData)) {
				const tempLocationGroupData = {
					name: locationGroupData.name,
					id: locationGroupData.id,
					locations: scheduleData.locations,
				};

				setSelectedLocationGroup(tempLocationGroupData);
			}

			setSelectedVersion(scheduleData.PosVersion);
			form.setFieldsValue({
				releaseDate: moment(scheduleData.releaseDate),
				forceUpdate: scheduleData.forceUpdate,
				locationIds: scheduleData.locations.map(location => location.id),
			});
		}
	}, [scheduleData]);

	return (
		<>
			<PickerModal
				visible={pickerModalConfig.visible}
				type={pickerModalConfig.type}
				actionFn={pickerAction}
				onClose={closePickerModal}
			/>
			<div className='px-6 pb-12'>
				<Card
					title={isDetail ? locale.detailTitle : locale.title}
				>
					<Form
						form={form}
						layout="vertical"
						name="adminUserForm"
						scrollToFirstError
						onFinish={e => !loading && beforeSubmit(e)}
						onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
						requiredMark={false}
					>
						{/* Release Date */}
						<Form.Item
							label={locale.releaseDate}
							name="releaseDate"
							rules={[{required: true, message: locale.releaseDateRequired}]}
						>
							<DatePicker
								minuteStep={5}
								format='DD MMM YYYY - HH:mm'
								disabled={isDetail}
								showTime
								showNow={false}
								placeholder={locale.releaseDatePlaceholder}
								disabledDate={current => {
									// Disable dates that have already passed
									return current && current < moment().startOf('day');
								}}
								disabledTime={current => {
									// Disable times that have already passed for today's date
									if (current && current.isSame(new Date(), 'day')) {
										const currentHour = new Date().getHours();
										const currentMinute = new Date().getMinutes();
										return {
											disabledHours: () => [...Array(currentHour).keys()],
											disabledMinutes: () => current.isSame(new Date(), 'hour')
												? [...Array(currentMinute + 1).keys()]
												: null,
											disabledSeconds: () => [],
										};
									}
									return {};
								}}
							/>
						</Form.Item>
						<div className='pb-6'>
							{
								isEmpty(selectedVersion)
									? (
										<Button
											onClick={() => openPickerModal('version')}
											type='primary'>
											{locale.selectVersion}
										</Button>
									)
									: (
										<div className='flex flex-col gap-2'>
											<Typography.Text type='secondary'>{locale.version}</Typography.Text>
											<div className='flex items-center gap-2'>
												<FileDoneOutlined />
												<Typography.Text strong>{selectedVersion.id}</Typography.Text>
											</div>
											{
												!isDetail && (
													<div className='flex gap-2'>
														<Typography
															onClick={() => openPickerModal('version')}
															className='text-antd-blue-6 cursor-pointer'>{locale.change}</Typography>
														<Typography
															onClick={() => setSelectedVersion({})}
															className='text-antd-red-6 cursor-pointer'>{locale.remove}</Typography>
													</div>
												)
											}
										</div>
									)
							}
						</div>

						{/* Force update */}
						<Form.Item
							label={locale.forceUpdate}
							name="forceUpdate"
							rules={[{required: true, message: locale.forceUpdateRequired}]}
						>
							<Radio.Group disabled={isDetail}>
								<Radio value={true}>{locale.yes}</Radio>
								<Radio value={false}>{locale.no}</Radio>
							</Radio.Group>
						</Form.Item>

						<div className='flex flex-col gap-4 pb-4'>
							<Typography>{locale.selectLocation}</Typography>
							<div>
								{
									isEmpty(selectedLocationGroup)
										? (
											<Button
												disabled={isDetail}
												onClick={() => openPickerModal('location')}
												type='primary'>
												{locale.useLocationGroup}
											</Button>
										)
										: (
											<div className='flex flex-col gap-2'>
												<Typography.Text type='secondary'>{locale.locationGroup}</Typography.Text>
												<div className='flex items-center gap-2'>
													<FileDoneOutlined />
													<Typography.Text strong>{selectedLocationGroup.name}</Typography.Text>
												</div>
												{
													!isDetail && (
														<div className='flex gap-2'>
															<Typography
																onClick={() => openPickerModal('location')}
																className='text-antd-blue-6 cursor-pointer'>{locale.change}</Typography>
															<Typography
																onClick={() => {
																	setSelectedLocationGroup({});
																	form.setFieldsValue({locationIds: []});
																}}
																className='text-antd-red-6 cursor-pointer'>{locale.remove}</Typography>
														</div>
													)
												}
											</div>
										)
								}
							</div>
						</div>

						{/* Locations */}
						<Form.Item
							name="locationIds"
							rules={[{required: true, message: locale.locationsRequired}]}
						>
							<Transfer
								disabled={isDetail}
								loading={locationLoading}
								listStyle={{flexGrow: '1', height: 382}}
								dataSource={(
									isEmpty(selectedLocationGroup)
										? defaultLocationList
										: selectedLocationGroup.locations
								).map(e => ({
									...e,
									key: e.id,
								}))}
								targetKeys={selectedLocations}
								titles={[locale.allLocation, locale.selectedLocation]}
								render={item => item.label}
								showSearch
								filterOption={(input, option) => {
									return option.label.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
								}}
							/>
						</Form.Item>

						{/* Actions */}
						{
							!isDetail && (
								<div className='flex gap-2 justify-end'>
									<Button
										onClick={() => navigate('/admin/version-settings/release-scheduler')}
									>
										{locale.cancel}
									</Button>
									<Button
										disabled={isEmpty(selectedVersion)}
										type="primary"
										htmlType='submit'
									>
										{locale.submit}
									</Button>
								</div>
							)
						}
					</Form>
				</Card>
			</div>
		</>
	);
};

ReleaseSchedulerFormBody.defaultProps = {
	isDetail: false,
	scheduleData: {},
};

ReleaseSchedulerFormBody.propTypes = {
	isDetail: PropTypes.bool,
	scheduleData: PropTypes.object,
};

export default ReleaseSchedulerFormBody;