import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {debounce, isEmpty} from 'lodash';
import PropTypes from 'prop-types';

import MenuTemplateCart from 'components/MenuTemplateCart';

import {Divider, Input, Modal, Popconfirm, Typography} from 'antd';
import {FormOutlined} from '@ant-design/icons';
import ProTable from '@ant-design/pro-table';

import {handleErrorFetch, moneyFormat, sortDirectionConverter} from 'utils/utils';
import {getOrderTemplates} from 'utils/request/internalOrdering';
import {USE_LATEST_MENU_COMBINATION} from 'utils/constants';

import localization from 'localization';
const locale = localization.InternalOrdering.CampaignRequest.MenuCombinationSelectorModal;

const MenuCombinationSelectorModal = ({open, onClose, onSelect}) => {
	const location = useLocation();
	const campaignType = location?.state?.campaignType || null;

	const tableRef = useRef(null);
	const menuTemplateCartRef = useRef(null);

	const [loading, setLoading] = useState(false);
	const [searchLabel, setSearchLabel] = useState(null);

	const [cart, setCart] = useState([]);
	const [previewData, setPreviewData] = useState(0);

	const newMenuCombinationWatcherRef = useRef(null);

	const fetchTableData = async (params, sorter) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */
			setLoading(true);
			const sortOrderKey = isEmpty(sorter) ? 'descend' : Object.values?.(sorter)?.[0];
			const sortByKey = isEmpty(sorter) ? 'createdAt' : Object.keys?.(sorter)?.[0];

			const payload = {
				limit: params?.pageSize,
				page: params?.current - 1,
				sortBy: sortByKey,
				sortOrder: sortDirectionConverter(sortOrderKey),
				search: {
					name: searchLabel || null,
					type: campaignType,
				},
			};
			const response = await getOrderTemplates(payload);
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const debouncedChangeHandler = useCallback(debounce(inputEvent => {
		setSearchLabel(inputEvent.target.value);
	}, 1000), []);

	const onClickDetail = record => {
		const itemsData = record?.items || [];
		const tempCart = itemsData.map(item => {
			return {
				dataOrigin: {},
				menuItem: item,
				mappingHash: item.mappingHash,
			};
		});
		setCart(tempCart);
		setPreviewData(record);
		menuTemplateCartRef?.current?.openDrawer?.();
	};

	const columns = [
		{
			title: locale.menuCombinationId,
			width: 200,
			key: 'id',
			dataIndex: 'id',
			render: id => (
				<Typography.Text
					copyable
				>{id?.toUpperCase?.()}</Typography.Text>
			),
		},
		{
			title: locale.menuCombinationName,
			width: 222,
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: locale.qty,
			width: 78,
			key: 'qty',
			search: false,
			render: (_, record) => {
				let qtyCount = 0;
				record.items.forEach(item => qtyCount+= item.quantity);
				return qtyCount;
			},
		},
		{
			title: locale.price,
			width: 175,
			key: 'price',
			search: false,
			sorter: true,
			dataIndex: 'subtotal',
			render: subtotal => moneyFormat({value: subtotal}),
		},
		{
			title: locale.action,
			width: 197,
			key: 'action',
			search: false,
			fixed: 'right',
			align: 'center',
			render: (_, record) => (
				<div className='flex gap-2 justify-center items-center'>
					<div
						onClick={() => onClickDetail(record)}
						className='text-antd-blue-6 cursor-pointer'>{locale.detail}</div>
					<Divider
						className='m-0 p-0'
						type='vertical' />
					<Popconfirm
						placement='topRight'
						title={locale.selectConfirmation}
						cancelText={locale.cancelText}
						okText={locale.select}
						onConfirm={() => onSelect(record)}
					>
						<div
							className='text-antd-blue-6 cursor-pointer'>{locale.select}</div>
					</Popconfirm>
				</div>
			),
		},
	];

	const onClickCreateNew = () => {
		newMenuCombinationWatcherRef.current = true;
		window.open(`/internal-ordering/menu-combination/${campaignType}/new`, '_blank');
	};

	const handleUseNewMenuCombination = async () => {
		try {
			setLoading(true);
			const response = await getOrderTemplates({
				sortBy: 'createdAt',
				sortOrder: 'desc',
				search: {
					type: campaignType,
				},
			});

			if (response.success && response.data.count) {
				onSelect(response.data.rows[0]);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const listenMappingSubmit = event => {
		if (event.data === USE_LATEST_MENU_COMBINATION && newMenuCombinationWatcherRef.current) handleUseNewMenuCombination();
	};

	useEffect(() => {
		if (searchLabel !== null) tableRef?.current?.reload?.();
		window.addEventListener('message', listenMappingSubmit, false);

		return () => {
			window.removeEventListener('message', listenMappingSubmit);
		};
	}, [searchLabel]);

	useEffect(() => {
		if (!open) {
			setSearchLabel(null);
			newMenuCombinationWatcherRef.current = false;
		}
	}, [open]);

	return (
		<>
			<MenuTemplateCart
				ref={menuTemplateCartRef}
				viewOnly
				title={`${previewData.id?.toUpperCase?.()} ∙ ${previewData.name}`}
				cart={cart}
				hideButton
			/>
			<Modal
				destroyOnClose={true}
				open={open}
				width={982}
				onCancel={onClose}
				centered
				title={locale.title}
				cancelText={locale.cancelText}
				okText={locale.createNew}
				onOk={onClickCreateNew}
				okButtonProps={{
					icon: <FormOutlined />,
					type: 'default',
				}}
			>
				<Input.Search
					loading={loading}
					placeholder={locale.searchPlaceholder}
					onChange={debouncedChangeHandler}
					style={{width: 264}}
				/>
				<ProTable
					actionRef={tableRef}
					className="mt-4 ResetSearchTableMargin"
					rowKey='id'
					columns={columns}
					request={fetchTableData}
					toolBarRender={false}
					scroll={{x: 'max-content'}}
					onReset={() => tableRef?.current?.reset()}
					search={false}
					pagination={{
						defaultPageSize: 5,
						showSizeChanger: true,
						showQuickJumper: true,
						pageSizeOptions: ['5', '10', '25'],
					}}
				/>
			</Modal>
		</>
	);
};

MenuCombinationSelectorModal.defaultProps = {
	open: false,
	onClose: () => null,
	onSelect: () => null,
};

MenuCombinationSelectorModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSelect: PropTypes.func,
};

export default MenuCombinationSelectorModal;