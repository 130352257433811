import React from 'react';

import LocalPageHeader from 'components/LocalPageHeader';

import localization from 'localization';
const locale = localization.Admin.VersionTracker.Header;

const VersionTrackerHeader = () => {

	return (
		<div>
			<LocalPageHeader headerTitle={locale.title} />
		</div>
	);
};

export default VersionTrackerHeader;