import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const getUploadedVersion = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/version`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const uploadNewVersion = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/pos/version`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editVersion = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/pos/version`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteVersion = async payload => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/pos/version`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const downloadPos = async params => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/version/download`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getLocationGroup = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/version/location-group`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteLocationGroup = async payload => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/pos/version/location-group`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createLocationGroup = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/pos/version/location-group`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editLocationGroup = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/pos/version/location-group`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getReleaseSchedule = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/version/release-schedule`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteReleaseSchedule = async payload => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/pos/version/release-schedule`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createReleaseSchedule = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/pos/version/release-schedule`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getVersionTracker = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder} = query;
		const params = {
			limit,
			offset: Number((page) * limit) || null,
			...search,
			sortBy,
			sortOrder,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/version/tracker`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getVersionHistory = async query => {
	try {
		const {search} = query;
		const params = {
			limit: 5,
			...search,
		};
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/version/history`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};