import React, {useRef, useState} from 'react';

import ReceiptDrawer from './ReceiptDrawer';
import MetricFilter from './MetricFilter';
import FinalDashboardTable from './FinalDashboardTable';

import LocalPageHeader from 'components/LocalPageHeader';

import localization from 'localization';
import {Button} from 'antd';
import {ExportOutlined} from '@ant-design/icons';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';
import {exportToTsvFn, handleErrorFetch, salesPayloadHandler} from 'utils/utils';
import {getFinalTax} from 'utils/request/sales';
const locale = localization.Sales.TaxDashboard;

const drawerInitialState = {
	visible: false,
	data: {},
};

const FinalDashboard = () => {
	const [drawerConfig, setDrawerConfig] = useState(drawerInitialState);

	const metricFormRef = useRef();
	const tableFormRef = useRef();
	const [loadingDownload, setLoadingDownload] = useState(false);
	const [exportFileModalVisible, setExportFileModalVisible] = useState(false);

	const openDetailDrawer = data => {
		setDrawerConfig({
			visible: true,
			data,
		});
	};

	const handleExport = async ({fileName, type = 'export'}) => {
		try {
			setLoadingDownload(true);
			const metricPayload = salesPayloadHandler(metricFormRef.current?.getMetricFilter?.());

			await exportToTsvFn({
				fileName,
				actionType: type,
				exportCall: () => getFinalTax({
					params: metricPayload,
					isExport: true,
				}),
			});
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingDownload(false);
		}
	};

	const closeDetailDrawer = () => {
		setDrawerConfig(drawerInitialState);
	};

	const openExportFileModal = () => {
		setExportFileModalVisible(true);
	};

	const closeExportFileModal = () => {
		setExportFileModalVisible(false);
	};

	return (
		<>
			<ReceiptDrawer
				visible={drawerConfig.visible}
				drawerData={drawerConfig.data}
				close={closeDetailDrawer}
			/>
			<ExportCSVOverlay
				loading={loadingDownload}
				providedFileName={`sales-transaction-${new Date().valueOf()}.csv`}
				fileFormat='csv'
				handleExport={handleExport}
				visible={exportFileModalVisible}
				close={closeExportFileModal}
				type={'export'}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					headerTitle={locale.title}
					extra={[
						<Button
							onClick={openExportFileModal}
							icon={<ExportOutlined />}
							type='primary'
							key='export'>Export</Button>,
					]}
				/>
				<div className='px-6'>
					<MetricFilter
						ref={metricFormRef}
						tableFormRef={tableFormRef} />
					<FinalDashboardTable
						ref={tableFormRef}
						metricFormRef={metricFormRef}
						openDetailDrawer={openDetailDrawer} />
				</div>
			</div>
		</>
	);
};

export default FinalDashboard;