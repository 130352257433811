import lodash from 'lodash';
import ejs from 'ejs';
import dayjs from 'dayjs';
import JSONParse from 'json-parse-better-errors';

import definedTemplates from './definedTemplates.json';

const getStructure = (mainTemplate, childTemplates = [], data, config) => {
	const params = {data, config, lodash, dayjs, sanitizeString};

	// Convert array of childTemplates into object
	const depTemplates = childTemplates.reduce((acc, next) =>
		Object.assign(acc, {[next.name]: next.template})
	, {});

	let json = ejs.render(mainTemplate, {...params, childTemplates: depTemplates});

	// If there are childTemplates, re-run the templating to inject data into those child templates
	if (childTemplates.length) {
		json = ejs.render(json, params);
	}

	return JSONParse(json, null, 100);
};

const sanitizeString = value => {
	return value
		?.toString()
		?.replace(/\\/g, '\\\\')
		?.replace(/\n|\r|\t|\f/g, ' ')
		?.replace(/"|“|”+/g, '\\"')
		// eslint-disable-next-line no-control-regex
		?.replace(/[^\x00-\x7F]+/g, '')
		.trim();
};

export const getEjsStructure = salesData => {
	const marketingMessage = `Berikan rating & ulasanmu di
    Grab/Go/Shopee Food agar pelayanan
    kami jadi lebih baik
    
    Pesan dalam jumlah besar dan dapatkan
    promo menarik. WA kami di:
    0811 1301 1541`;
	const hangryAppMarketingMessage = `Berikan rating & ulasanmu agar
		pelayanan kami jadi lebih baik
    
    Pesan dalam jumlah besar dan dapatkan
    promo menarik. WA kami di:
    0811 1301 1541`;
	const subBrandMarketingMessage = `Berikan rating & ulasanmu di
	Grab/Go/Shopee Food agar pelayanan
	kami jadi lebih baik
	
	Ada Keluhan?
	WA kami di nomor 0811 1923 9090`;

	const mainTemplate = definedTemplates.find(template => template.type === 'parent').template;

	const config = {
		outletName: salesData.branch?.split(' - ').pop(),
		outletAddress: salesData.short_address,
		marketingStrings: marketingMessage.split('\n').map(e => e.trim()),
		hangryAppMarketingStrings: hangryAppMarketingMessage.split('\n').map(e => e.trim()),
		subBrandMarketingStrings: subBrandMarketingMessage.split('\n').map(e => e.trim()),
	};

	const childTemplates = definedTemplates.filter(template => template.type === 'child');

	const structure = getStructure(mainTemplate, childTemplates, salesData, config);
	return structure;
};