import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams, useLocation, useNavigate} from 'react-router-dom';

import MasterlistFormHeader from './MasterlistFormHeader';
import MasterlistFormBody from './MasterlistFormBody';

import useUnload from 'hooks/useUnload';
import {MASTERLIST_TYPE, STORAGE_KEY} from 'utils/constants';
import {handleErrorFetch} from 'utils/utils';
import {getMappings} from 'utils/request/mapping';

import {Spin} from 'antd';
import isEmpty from 'lodash/isEmpty';

const MasterlistForm = ({isEdit}) => {
	const {state} = useLocation();
	const navigate = useNavigate();
	const {masterlistType, hash, posId} = useParams();
	const previewLabelRef = useRef(null);
	const autoFillDummyRef = useRef(null);

	const [mappingRecord, setMappingRecord] = useState({});
	const [loading, setLoading] = useState(false);

	const itemRecord = state?.itemRecord || JSON.parse(sessionStorage.getItem(STORAGE_KEY.MASTERLIST_ITEM_DETAIL));

	const getMenuStructureData = async () => {
		try {
			setLoading(true);
			const response = await getMappings({search: {hash: hash?.trim()}, posId});
			if (response?.success && response.data.count) {
				setMappingRecord({
					hash: response.data.rows[0].hash,
					menuDetails: response.data.rows[0].menuDetails,
					mappingDetails: response.data.rows[0].mappingDetails,
					MasterMenu: response.data.rows[0].MasterMenu,
				});
			} else {
				navigate('/masterlist/menu-structure');
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const cleanUp = () => {
		sessionStorage.removeItem(STORAGE_KEY.MASTERLIST_ITEM_DETAIL);
		sessionStorage.removeItem(STORAGE_KEY.MENU_STRUCTURE);
	};

	useUnload(() => cleanUp());

	useEffect(() => {
		if (hash?.length) getMenuStructureData();
		if (posId && (isEmpty(itemRecord) && !hash)) {
			navigate('/masterlist/menu-structure');
		}
		return () => cleanUp();
	}, []);

	return (
		<div className='bg-white h-full overflow-auto'>
			<MasterlistFormHeader
				isEdit={isEdit}
				masterlistType={posId ? MASTERLIST_TYPE.MENU_STRUCTURE : masterlistType}
				setMappingRecord={setMappingRecord}
				previewLabelRef={previewLabelRef}
				autoFillDummyRef={autoFillDummyRef}
			/>
			{
				loading
					? (
						<div className='flex items-center justify-center pt-12'>
							<Spin size='large' />
						</div>
					)
					: (
						<MasterlistFormBody
							isEdit={isEdit}
							itemRecord={hash ? mappingRecord : itemRecord}
							masterlistType={posId ? MASTERLIST_TYPE.MENU_STRUCTURE : masterlistType}
							previewLabelRef={previewLabelRef}
							autoFillDummyRef={autoFillDummyRef}
						/>
					)
			}
		</div>
	);
};

MasterlistForm.defaultProps = {
	isEdit: false,
};

MasterlistForm.propTypes = {
	isEdit: PropTypes.bool,
};

export default MasterlistForm;