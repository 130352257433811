import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import PromoStatusHeader from './PromoStatusHeader';
import PromoStatusTable from './PromoStatusTable';

const PromoStatus = ({promoCategory}) => {
	const refetchTableRef = useRef(null);

	return (
		<div className="bg-white h-full overflow-auto">
			<PromoStatusHeader
				promoCategory={promoCategory}
				refetchTableRef={refetchTableRef}
			/>
			<PromoStatusTable
				promoCategory={promoCategory}
				refetchTableRef={refetchTableRef}
			/>
		</div>
	);
};

PromoStatus.defaultProps = {
	promoCategory: null,
};

PromoStatus.propTypes = {
	promoCategory: PropTypes.string,
};

export default PromoStatus;
