import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import ProTable from '@ant-design/pro-table';

import {handleErrorFetch} from 'utils/utils';

const PromoUsageBody = forwardRef(({metricFormRef}, ref) => {
	// State
	const columns = [
		{
			key: 'location',
			title: 'locale.location',
			// render: ({Merchant}) => Merchant.Location.label,
			// search: false,
		},
	];
	// Function
	const fetchTableData = async () => {
		try {
			const metricPayload = metricFormRef.current?.getMetricFilter?.();
			console.log(metricPayload, 'metric payload');
		} catch (error) {
			handleErrorFetch(error);
		}
	};
	// Lifecycle
	// Render

	return (
		<div className="tableCard my-4 mx-6 border border-antd-netural-4">
			<ProTable
				formRef={ref}
				className="mt-2"
				rowKey='id'
				columns={columns}
				search={{layout: 'vertical'}}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				layout="vertical"
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</div>
	);
});

PromoUsageBody.defaultProps = {
	metricFormRef: {},
};

PromoUsageBody.propTypes = {
	metricFormRef: PropTypes.object,
};

export default PromoUsageBody;