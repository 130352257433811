import React from 'react';
import PropTypes from 'prop-types';

import {Modal} from 'antd';
import UploadProgress from 'components/UploadProgress';

const VersionUploaderFormLoading = ({visible, uploadingTitle, completeTitle}) => {
	return (
		<Modal
			centered
			closable={false}
			footer={null}
			visible={visible}
		>
			<UploadProgress
				uploadingTitle={uploadingTitle}
				completeTitle={completeTitle}
			/>
		</Modal>
	);
};

VersionUploaderFormLoading.defaultProps = {
	visible: false,
	uploadingTitle: PropTypes.string,
	completeTitle: PropTypes.string,
};

VersionUploaderFormLoading.propTypes = {
	visible: PropTypes.bool,
	uploadingTitle: PropTypes.string,
	completeTitle: PropTypes.string,
};

export default VersionUploaderFormLoading;