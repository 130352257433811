import React from 'react';

import UserListHeader from './UserListHeader';
import UserListBody from './UserListBody';

const UserList = () => {
	return (
		<div className='bg-white h-full overflow-auto'>
			<UserListHeader />
			<UserListBody />
		</div>
	);
};

export default UserList;