import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import ProTable from '@ant-design/pro-table';

import {getSettlement} from 'utils/request/sales';
import {salesPayloadHandler, handleErrorFetch} from 'utils/utils';

import './SettlementTable.less';

// eslint-disable-next-line react/prop-types, no-unused-vars
const SettlementTable = forwardRef(({platform, metricFormRef, columns}, ref) => {

	const metricUpdateHandler = (trxData = {
		count: 0,
		summary: {},
		transactions: [],
	}) => {
		let metricPayload = {
			count: 0,
		};
		switch (platform) {
		case 'gojek':
			metricPayload = {
				count: trxData.count,
				sum_net_sales: trxData.summary?.sum_net_sales || 0,
				sum_cart_discount: trxData.summary?.sum_cart_discount,
				sum_mdr_fees: trxData.summary?.sum_mdr_fees,
				sum_pb1: trxData.summary?.sum_pb1,
				sum_promo_by_hangry: trxData.summary?.sum_promo_by_hangry,
			};
			break;
		case 'grab':
			metricPayload = {
				count: trxData.count,
				sum_net_sales: trxData.summary?.sum_net_sales,
				sum_cart_discount: trxData.summary?.sum_cart_discount,
				sum_promo_by_hangry: trxData.summary?.sum_promo_by_hangry,
				sum_mdr_fees: trxData.summary?.sum_mdr_fees,
				sum_cofunding: trxData.summary?.sum_cofunding,
				sum_marketing_success_fee: trxData.summary?.sum_marketing_success_fee,
				sum_pb1: trxData.summary?.sum_pb1,
			};
			break;
		case 'shopee':
			metricPayload = {
				count: trxData.count,
				sum_promo_by_platform: trxData.summary?.sum_promo_by_platform,
				sum_promo_by_hangry: trxData.summary?.sum_promo_by_hangry,
				sum_cart_discount: trxData.summary?.sum_cart_discount,
				sum_net_sales: trxData.summary?.sum_net_sales,
				sum_cofunding: trxData.summary?.sum_cofunding || 0,
				sum_pb1: trxData.summary?.sum_pb1,
			};
			break;
		default:
			break;
		}
		metricFormRef.current?.updateMetricData?.(metricPayload);
	};

	const fetchTableData = async params => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */
			const metricPayload = salesPayloadHandler(metricFormRef.current?.getMetricFilter?.());

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				...metricPayload,
			};

			const response = await getSettlement({params: payload, platform});

			metricUpdateHandler(response.data);
			return {
				data: response.data.transactions,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<ProTable
			actionRef={ref}
			className='SettlementTable py-4'
			columns={columns}
			search={false}
			options={false}
			scroll={{x: 'max-content'}}
			request={fetchTableData}
		/>
	);
});

SettlementTable.defaultProps = {
	platform: null,
	metricFormRef: {},
	columns: [],
};

SettlementTable.propTypes = {
	platform: PropTypes.string,
	metricFormRef: PropTypes.object,
	columns: PropTypes.array,
};

export default SettlementTable;