import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Button} from 'antd';
import {ExportOutlined, FormOutlined} from '@ant-design/icons';

import LocalPageHeader from 'components/LocalPageHeader';
import InternalPlatformTable from './components/InternalPlatformTable';

import localization from 'localization';
const locale = localization.POSSetting.InternalPlatform;

const InternalPlatform = () => {
	const navigate = useNavigate();

	return (
		<div className='bg-white h-full overflow-auto'>
			<LocalPageHeader
				headerTitle={locale.title}
				extra={[
					<Button
						// onClick={() => setExportFileModalVisible(true)}
						icon={<ExportOutlined />}
						key='export'>{locale.export}
					</Button>,
					<Button
						onClick={() => navigate('/pos-settings/platform-internal/new')}
						icon={<FormOutlined />}
						type='primary'
						key='new'>{locale.new}
					</Button>,
				]}
			/>
			<InternalPlatformTable />
		</div>
	);
};

export default InternalPlatform;