import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import LocalPageHeader from 'components/LocalPageHeader';
import {Typography} from 'antd';

const MenuTemplateDetailHeader = ({menuTemplateLabel, menuTemplateId}) => {
	const navigate = useNavigate();
	return (
		<div>
			<LocalPageHeader
				headerTitle={(
					<div className='flex gap-1 items-center'>
						<Typography.Text>{menuTemplateLabel}</Typography.Text>
						{
							menuTemplateId && (<Typography.Text type='secondary'>{`[${menuTemplateId}]`}</Typography.Text>)
						}
					</div>
				)}
				capitalize={false}
				showBackButton
				backButtonAction={() => navigate('/sales-channel/menu-template', {state: {activeTabs: 'active'}})}
			/>
		</div>
	);
};

MenuTemplateDetailHeader.defaultProps = {
	menuTemplateLabel: null,
	menuTemplateId: null,
};

MenuTemplateDetailHeader.propTypes = {
	menuTemplateLabel: PropTypes.string,
	menuTemplateId: PropTypes.string,
};

export default MenuTemplateDetailHeader;
