import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

// Package
import {Alert, Button, Dropdown, notification, Spin, Tabs} from 'antd';
import {EditOutlined, FormOutlined} from '@ant-design/icons';

// Components
import SaveConfirmationModal from 'pages/InternalOrdering/MenuCreator/MenuCreatorForm/SaveConfirmationModal';
import LocalPageHeader from 'components/LocalPageHeader';
import RequestListTable from './RequestListTable';

// Utils
import {handleErrorFetch} from 'utils/utils';
import {editCampaign, getCampaignDetail} from 'utils/request/internalOrdering';
import {INTERNAL_ORDERING_TYPE, REFETCH_CAMPAIGN_LIST_TABLE, REQUEST_TABS} from 'utils/constants';

import localization from 'localization';
const locale = localization.InternalOrdering.CampaignDetail;
const menuCreatorFormLocale = localization.InternalOrdering.MenuCreatorForm;

const CAMPAIGN_TYPE = [
	{
		key: 'delivery',
		label: locale.delivery,
	},
	{
		key: 'takeaway',
		label: locale.takeaway,
	},
];

const CampaignDetail = ({internalOrderType}) => {
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();

	const searchInfo = Object.fromEntries(new URLSearchParams(location.search).entries());

	const isKol = internalOrderType == INTERNAL_ORDERING_TYPE.kol.key;
	const campaignId = isKol ? params?.campaignId || null : internalOrderType;

	const [loading, setLoading] = useState(false);
	const [campaignData, setCampaignData] = useState({});

	const [loadingSave, setLoadingSave] = useState(false);
	const [saveConfirmationModalOpen, setSaveConfirmationModalOpen] = useState(false);

	const dropdownItems = CAMPAIGN_TYPE.map(type => {
		return {
			...type,
			onClick: () => navigate(`/internal-ordering/order-creator/campaign-detail/${campaignId}/new`, {
				state: {
					orderType: type.key,
					campaignType: internalOrderType || campaignData?.type,
				},
			}),
		};
	});

	const initialFetch = async () => {
		try {
			setLoading(true);
			const response = await getCampaignDetail(campaignId);
			if (response.success) {
				setCampaignData(response.data);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const refreshDetailAndList = () => {
		initialFetch();
		window.postMessage(REFETCH_CAMPAIGN_LIST_TABLE);
	};

	const handleSaveMenuCombination = async menuCombinationName => {
		try {
			setLoadingSave(true);

			const payload = {
				id: campaignId,
				name: menuCombinationName,
			};

			const response = await editCampaign(payload);

			if (response.success) {
				notification.success({
					message: menuCreatorFormLocale['EditSuccessNotification'].message,
					description: menuCreatorFormLocale['EditSuccessNotification'].description,
				});
				refreshDetailAndList();
			}
		} catch (error) {
			handleErrorFetch(error, {
				message: menuCreatorFormLocale['EditFailedNotification'].message,
				description: menuCreatorFormLocale['EditFailedNotification'].description,
			});
		} finally {
			setLoadingSave(false);
			setSaveConfirmationModalOpen(false);
		}
	};

	useEffect(() => {
		initialFetch();
	}, [campaignId]);

	return (
		<>
			<SaveConfirmationModal
				open={saveConfirmationModalOpen}
				onSave={handleSaveMenuCombination}
				loadingSave={false}
				onClose={() => !loadingSave && setSaveConfirmationModalOpen(false)}
				isEdit
				currentMenuCombinationName={campaignData?.name}
			/>
			<div className='bg-white h-full overflow-auto'>
				<LocalPageHeader
					showBackButton={isKol}
					capitalize={isKol ? false : true}
					headerTitle={isKol ? campaignData?.name : `${internalOrderType} Order Request`}
					extra={[
						...(isKol ? [<Button
							onClick={() => setSaveConfirmationModalOpen(true)}
							icon={<EditOutlined />}
							key='edit'>{locale.edit}
						</Button>] : []),
						<Dropdown
							trigger={['click']}
							menu={{items: dropdownItems}}
							key='create'>
							<Button
								icon={<FormOutlined />}
								type='primary'
								key='new'>{locale.create}
							</Button>
						</Dropdown>,
					]}
				/>
				{
					loading
						? (
							<div className='flex justify-center items-center mt-5'>
								<Spin size='large' />
							</div>
						)
						: (
							<Tabs
								onChange={tabVal => {
									navigate(`?tab=${tabVal}`);
								}}
								defaultActiveKey={searchInfo?.tab || null}
								tabBarStyle={{paddingInline: '24px'}}
							>
								{
									Object.values(REQUEST_TABS).map(tabKey => (
										<Tabs.TabPane
											key={tabKey}
											tab={(
												<div>
													{locale[`${tabKey}Request`]}
													<span className='ml-1 px-2 bg-antd-blue-1 text-antd-blue-6 rounded-lg'>
														{campaignData[`${tabKey}Count`] || 0}
													</span>
												</div>
											)}>
											{tabKey === REQUEST_TABS.ACTIVE && (
												<Alert
													className='mb-4 mx-6'
													message={locale.activeRequestInfo}
													type={'info'}
													showIcon />
											)}
											<div className='px-6'>
												<RequestListTable
													campaignId={campaignId}
													refreshDetailAndList={refreshDetailAndList}
													tabKey={tabKey}
													campaignType={internalOrderType}
												/>
											</div>
										</Tabs.TabPane>
									))
								}
							</Tabs>
						)
				}
			</div>
		</>
	);
};

CampaignDetail.defaultProps = {
	internalOrderType: null,
};

CampaignDetail.propTypes = {
	internalOrderType: PropTypes.string,
};

export default CampaignDetail;