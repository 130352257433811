import localization from 'localization';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import {Button} from 'antd';
import {DownloadOutlined, HistoryOutlined} from '@ant-design/icons';

// Global utils
import {getLabel} from 'utils/utils';

// Global components
import LocalPageHeader from 'components/LocalPageHeader';

const locale = localization.WatchTower.WatchTowerMenu.header;

const Header = props => {
	const navigate = useNavigate();
	// Render
	const renderHeaderButtons = () => {
		return (
			<div className='flex gap-2'>
				{
					props?.isHistory
						? null
						: (
							<Button
								onClick={() => navigate('/watchtower/outlet/history')}
								icon={<HistoryOutlined />}>
								{locale.history}
							</Button>
						)
				}
				<Button
					onClick={() => props?.openDownloadReportModal?.()}
					icon={<DownloadOutlined />}>
					{locale.downloadReport}
				</Button>
			</div>
		);
	};
	return (
		<LocalPageHeader
			customBreadcrumbLabel={props.customBreadcrumbLabel}
			extra={renderHeaderButtons()}
			headerTitle={getLabel(props.headerLabel)}
			showBackButton={props.isHistory}
			backButtonAction={() => navigate('/watchtower/outlet')}
		/>
	);
};

Header.defaultProps = {
	headerLabel: locale.watchtower,
	headerButtonLabel: locale.history,
	headerButtonProps: {},
	isHistory: false,
	openDownloadReportModal: () => null,
};

Header.propTypes = {
	customBreadcrumbLabel: PropTypes.string,
	headerLabel: PropTypes.string,
	headerButtonLabel: PropTypes.string,
	headerButtonProps: PropTypes.object,
	isHistory: PropTypes.bool,
	openDownloadReportModal: PropTypes.func,
};

export default Header;