import React from 'react';
import PropTypes from 'prop-types';

import {Button} from 'antd';
import {ImportOutlined} from '@ant-design/icons';

import LocalPageHeader from 'components/LocalPageHeader';

import localization from 'localization';

const Header = ({openImportModal}) => {
	const locale = localization.Sales.FileUpload.Header;
	return (
		<LocalPageHeader
			headerTitle={locale.title}
			extra={(
				<Button
					onClick={openImportModal}
					icon={<ImportOutlined />}
					type='primary'>{locale.import}</Button>
			)}
		/>
	);
};

Header.defaultProps = {
	openImportModal: () => null,
};

Header.propTypes = {
	openImportModal: PropTypes.func,
};

export default Header;