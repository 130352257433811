import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Divider, Typography} from 'antd';

import Metric from './Metric';
import Filter from './Filter';
import Table from './Table';
import {handleErrorFetch} from 'utils/utils';
import {getBrandList, getPlatformList} from 'utils/request/global';

const PromoOff = ({metricValue, isActive}) => {
	const metricFormRef = useRef();
	const tableFormRef = useRef();

	const [brandList, setBrandList] = useState([]);
	const [platformList, setPlatformList] = useState([]);

	const initialLoad = async () => {
		try {
			const brandResponse = await getBrandList({search: {limit: 0}});
			const platformResponse = await getPlatformList({limit: 0});

			setBrandList(brandResponse.data.rows.sort((a, b) => a.label.localeCompare(b.label)).map(brand => ({
				label: brand.label,
				value: brand.id,
			})));
			setPlatformList(platformResponse.data.rows.sort((a, b) => a.label.localeCompare(b.label)).map(platform => ({
				text: platform.label,
				value: platform.id,
			})));
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		initialLoad();
	}, []);

	useEffect(() => {
		if (isActive) {
			tableFormRef.current?.reload?.();
		}
	}, [isActive]);

	return (
		<div className='px-6 pb-12'>
			<Metric metricValue={metricValue} />
			<div className='border border-antd-netural-4 rounded-md'>
				<div className='flex items-center justify-between p-4'>
					<Typography.Title level={5}>
						Menu Promo Off
					</Typography.Title>
					{/* <Button
						icon={<ExportOutlined />}
						type='default'>Export</Button> */}
				</div>
				<div className='px-4'>
					<Divider className='m-0' />
				</div>
				<Filter
					ref={metricFormRef}
					tableFormRef={tableFormRef}
					brandList={brandList}
				/>
				<Table
					ref={tableFormRef}
					metricFormRef={metricFormRef}
					platformList={platformList}
				/>
			</div>
		</div>
	);
};

PromoOff.defaultProps = {
	metricValue: {},
	isActive: false,
};

PromoOff.propTypes = {
	metricValue: PropTypes.object,
	isActive: PropTypes.bool,
};

export default PromoOff;