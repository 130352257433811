import React  from 'react';
import PropTypes from 'prop-types';

import {Form, Input} from 'antd';

import localization from 'localization';
const locale = localization.Outlet.UserActivation.UserEditModal;

const ResetPinForm = ({form}) => {
	const newPinWatcher = Form.useWatch('newPin', form);
	const confirmNewPinWatcher = Form.useWatch('confirmNewPin', form);

	return (
		<div className='flex flex-col gap-4'>
			<Form.Item
				label={locale.pinLabel}
				name="newPin"
				help={locale.pinLabelInfo}
				rules={[
					{required: true},
					{min: 6},
				]}
			>
				<Input.Password
					placeholder={locale.newPinLabelPlaceholder}
				/>
			</Form.Item>
			<Form.Item
				label={locale.confirmPinLabel}
				name="confirmNewPin"
				help={locale.confirmPinLabelInfo}
				rules={[
					{
						message: 'this is custom',
						validator: () => {
							if (confirmNewPinWatcher == newPinWatcher) {
                	return Promise.resolve();
							} else {
								return Promise.reject();
							}
						},
					},
				]}
			>
				<Input.Password
					placeholder={locale.confirmPinLabelPlaceholder}
				/>
			</Form.Item>
		</div>
	);
};

ResetPinForm.defaultProps = {
	form: {},
};

ResetPinForm.propTypes = {
	form: PropTypes.object,
};

export default ResetPinForm;