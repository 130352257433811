import {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Button, message, Modal, Tabs} from 'antd';
import {CopyFilled} from '@ant-design/icons';
import clsx from 'clsx';

// Global utils
import localization from 'localization';
const locale = localization.WatchTower.WatchTowerOrder.orderDetailModal;

// Local components
import OrderInfo from './OrderInfo';
import OrderItems from './OrderItems';
import OrderTimeline from './OrderTimeline';
import MerchantStatus from './MerchantStatus';

const OrderDetailModal = ({visible, data, onClose, withoutModal, isFromSales, isPos}) => {
	const [activeTabs, setActiveTabs] = useState(isFromSales ? '2' : '1');

	// eslint-disable-next-line react/prop-types
	const DefaultWrapper = useCallback(({children}) => (
		withoutModal? (
			<div className='px-6'>
				{children}
			</div>
		)
			: (
				<Modal
					title={locale.title
						.replace(
							'{{orderId}}', isFromSales
								? `${data?.external_long_id} / ${data?.external_short_id}`
								: data.externalAltId ||
								data?.merchantOrderId ||
								data?.inputDetails?.customerName ||
								'',
						)}
					visible={visible}
					width={908}
					centered
					okText={locale.confirmButton}
					onOk={onClose}
					onCancel={onClose}
					cancelButtonProps={{style: {display: 'none'}}}
					footer={[
						...(isFromSales ?
							[] :
							[
								<Button
									icon={<CopyFilled />}
									key='copy'
									onClick={handleCopyLink}
								>
									{locale.copyButton}
								</Button>,
							]),
						<Button
							key='ok'
							type='primary'
							onClick={onClose}
						>
							{locale.okButton}
						</Button>,
					]}
				>
					{children}
				</Modal>
			)
	), [visible]);

	const handleCopyLink = () => {
		navigator.clipboard.writeText(`${window.location.origin}/watchtower/order/${isPos ? 'pos/' : ''}${data.id}`);
		message.success(locale.copied);
	};
	// Lifecycle
	useEffect(() => {
		if (!visible) return;
		setActiveTabs(isFromSales ? '2' : '1');
	}, [visible]);
	// Render
	return (
		<DefaultWrapper
			onClose={onClose}
			withoutModal={withoutModal}
			visible={visible}
			handleCopyLink={handleCopyLink}>
			<Tabs
				onTabClick={key => setActiveTabs(key)}
				activeKey={activeTabs}
			>
				{
					isFromSales ? null : (
						<Tabs.TabPane
							tab={locale.orderInfo.title}
							key="1">
							<OrderInfo
								isPos={isPos}
								data={data}
							/>
						</Tabs.TabPane>
					)
				}
				<Tabs.TabPane
					tab={locale.orderItems.title}
					key="2">
					<OrderItems
						isPos={isPos}
						data={data} />
				</Tabs.TabPane>
				{
					isFromSales ? null : (
						<Tabs.TabPane
							tab={locale.orderTimeline.title}
							key="3">
							<div className={clsx('overflow-auto', !withoutModal && 'max-h-96')}>
								<OrderTimeline
									isPos={isPos}
									data={data}
								/>
							</div>
						</Tabs.TabPane>
					)
				}
				{
					isFromSales ? null : (
						<Tabs.TabPane
							tab={locale.merchantStatus.title}
							key="4">
							<MerchantStatus
								data={data}
								isPos={isPos}
							/>
						</Tabs.TabPane>
					)
				}
				<Tabs.TabPane
					tab={locale.orderItems.posFormatTitle}
					key="5">
					<OrderItems
						isPos={isPos}
						data={data}
						posFormat={true} />
				</Tabs.TabPane>
			</Tabs>
		</DefaultWrapper>
	);
};

OrderDetailModal.defaultProps = {
	data: {},
	onClose: () => {},
	withoutModal: false,
	isFromSales: false,
	isPos: false,
};

OrderDetailModal.propTypes = {
	data: PropTypes.object,
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	withoutModal: PropTypes.bool,
	isFromSales: PropTypes.bool,
	isPos: PropTypes.bool,
};

export default OrderDetailModal;
