import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Input, Modal, Transfer, notification} from 'antd';
import PropTypes from 'prop-types';

import localization from 'localization';
import {useNavigate} from 'react-router-dom';
import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';
import {handleErrorFetch} from 'utils/utils';
import {createLocationGroup, editLocationGroup} from 'utils/request/admin';
import {isEmpty} from 'lodash';

const LocationGroupFormBody = ({isEdit, locationGroupData}) => {
	const locale = localization.Admin.LocationGroup.Form;
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const selectedLocations = Form.useWatch('locationIds', form); // Used to provide value for targetKeys

	const [loading, setLoading] = useState(false);
	const [locationList, setLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

	const handleSubmit = async e => {
		try {
			setLoading(true);
			if (isEdit) e.id = locationGroupData.id;

			const fetchFn = isEdit ? editLocationGroup : createLocationGroup;
			const response = await fetchFn(e);
			if (response.success) {
				const notificationLocale = isEdit ? locale.EditSuccessNotification : locale.CreateSuccessNotification;
				notification.success({
					message: notificationLocale.message,
					description: notificationLocale.description,
				});
			}
		} catch (error) {
			notification.error({
				message: locale.CreateFailNotification.message,
				description: locale.CreateFailNotification.description,
			});
		} finally {
			setLoading(false);
			navigate('/admin/version-settings/location-group');
		}
	};

	const beforeSubmit = e => {
		const confirmationLocale = isEdit ? locale.EditConfirmation : locale.CreateNewConfirmation;
		Modal.confirm({
			centered: true,
			title: confirmationLocale.message,
			content: confirmationLocale.description,
			okText: confirmationLocale.okText,
			cancelText: confirmationLocale.cancelText,
			onOk() {
				handleSubmit(e);
			},
		});
	};

	const initialFetch = async () => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {limit: 0, filterGroup: true},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				let locationListTemp = response.data.rows;
				if (isEdit) {
					locationListTemp = locationListTemp.concat(locationGroupData.locations);
				}
				setLocationList(locationListTemp.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	useEffect(() => {
		initialFetch();
		if (isEdit && !isEmpty(locationGroupData)) {
			form.setFieldsValue({
				name: locationGroupData.name,
				locationIds: locationGroupData.locations.map(location => location.id),
			});
		}
	}, [locationGroupData]);

	return (
		<div className='px-6 pb-12'>
			<Card
				title={isEdit ? locale.editTitle : locale.title}
			>
				<Form
					form={form}
					layout="vertical"
					name="adminUserForm"
					scrollToFirstError
					onFinish={e => !loading && beforeSubmit(e)}
					onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
					requiredMark={false}
				>
					<div className='max-w-7xl'>
						<Form.Item
							label={locale.name}
							name="name"
							rules={[{required: true, message: locale.nameRequired}]}
						>
							<Input
								className='max-w-md'
								placeholder={locale.namePlaceholder} />
						</Form.Item>
						<Form.Item
							label={locale.locations}
							name="locationIds"
							rules={[{required: true, message: locale.locationsRequired}]}
						>
							<Transfer
								loading={locationLoading}
								listStyle={{flexGrow: '1', height: 382}}
								dataSource={locationList.map(e => ({
									...e,
									key: e.id,
								}))}
								targetKeys={selectedLocations}
								titles={[locale.allLocation, locale.selectedLocation]}
								render={item => item.label}
								showSearch
								filterOption={(input, option) => {
									return option.label.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
								}}
							/>
						</Form.Item>
						<div className='flex gap-2 justify-end'>
							<Button
								onClick={() => navigate('/admin/version-settings/location-group')}
							>
								{locale.cancel}
							</Button>
							<Button
								type="primary"
								htmlType='submit'
							>
								{isEdit ? locale.save : locale.submit}
							</Button>
						</div>
					</div>
				</Form>
			</Card>
		</div>
	);
};

LocationGroupFormBody.defaultProps = {
	isEdit: false,
	locationGroupData: {},
};

LocationGroupFormBody.propTypes = {
	isEdit: PropTypes.bool,
	locationGroupData: PropTypes.object,
};

export default LocationGroupFormBody;