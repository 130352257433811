import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {addTagToString} from 'utils/utils';

import {Button, message, Modal, Steps, Image} from 'antd';
import isEmpty from 'lodash/isEmpty';

const grabDeveloperLink = 'https://developer-beta.stg-myteksi.com/dashboard/grab-platform/projects/1a3f937b-3527-4389-84fe-8a27d55ef031/menu-simulator';

import step4Preview from 'assets/images/PreviewGuide/step-4.png';
import step5Preview from 'assets/images/PreviewGuide/step-5.png';
import step6Preview from 'assets/images/PreviewGuide/step-6.png';

import localization from 'localization';
const locale = localization.Global.GrabMockupPreview;

const GrabMockupPreview = ({visible, close, jsonCode}) => {
	const [jsonString, setJSONString] = useState('');

	const copyToClipboard = () => {
		navigator.clipboard.writeText(jsonString);
		message.success(locale.copied);
	};

	useEffect(() => {
		if (isEmpty(jsonCode)) return;
		const stringifiedResponse = JSON.stringify(jsonCode, null, 2);
		setJSONString(stringifiedResponse);
	}, [jsonCode]);

	const imagePreviewHandler = stepNumber => {
		const imageElement = document.getElementById(`step-${stepNumber}-img`);
		imageElement.click();
	};

	return (
		<Modal
			visible={visible}
			title={locale.title}
			footer={null}
			onCancel={close}
			width={600}
		>
			<div>
				<Steps
					direction="vertical"
					current={0}>
					<Steps.Step
						status='process'
						title={locale.step1}
					/>
					<Steps.Step
						status='process'
						title={addTagToString(locale.step2, '{{link}}', <a
							className='text-antd-blue-6'
							href={grabDeveloperLink}
							target='_blank'
							rel='noreferrer'>link</a>)}
					/>
					<Steps.Step
						status='process'
						title={locale.step3}
					/>
					<Steps.Step
						status='process'
						title={locale.step4}
						description={(
							<>
								<div className='hidden'>
									<Image
										id="step-4-img"
										src={step4Preview}
									/>
								</div>
								<div
									className='text-antd-blue-6 cursor-pointer'
									onClick={() => imagePreviewHandler(4)}
								>{locale.previewImage}</div>
							</>
						)}
					/>
					<Steps.Step
						status='process'
						title={locale.step5}
						description={(
							<>
								<div className='hidden'>
									<Image
										id="step-5-img"
										src={step5Preview}
									/>
								</div>
								<div
									className='text-antd-blue-6 cursor-pointer'
									onClick={() => imagePreviewHandler(5)}
								>{locale.previewImage}</div>
							</>
						)}
					/>
					<Steps.Step
						status='process'
						title={locale.step6}
						description={(
							<>
								<div className='hidden'>
									<Image
										id="step-6-img"
										src={step6Preview}
									/>
								</div>
								<div
									className='text-antd-blue-6 cursor-pointer'
									onClick={() => imagePreviewHandler(6)}
								>{locale.previewImage}</div>
							</>
						)}
					/>
				</Steps>
				<div className='flex justify-center pt-4'>
					<Button
						type='primary'
						onClick={copyToClipboard} >{locale.action}</Button>
				</div>
				<div className='hidden'>
					<Image
						id="step-4-img"
						width={200}
						src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
					/>
				</div>
			</div>
		</Modal>
	);
};

GrabMockupPreview.defaultProps = {
	visible: false,
	close: () => null,
	jsonCode: {},
};

GrabMockupPreview.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	jsonCode: PropTypes.object,
};

export default GrabMockupPreview;