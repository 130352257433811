import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {Button, Modal, notification} from 'antd';
import {CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, ReloadOutlined} from '@ant-design/icons';

import LocalPageHeader from 'components/LocalPageHeader';

import {handleErrorFetch} from 'utils/utils';
import {completeTraining, deleteTrainingSession} from 'utils/request/outlet';

import localization from 'localization';
const locale = localization.Outlet.Training.Session;

const Header = ({sessionData, refetchData}) => {
	const navigate = useNavigate();

	const goToSessionList = () => {
		navigate('/outlet/training-session');
	};

	const showDelete = () => {
		Modal.confirm({
			centered: true,
			title: locale.OrderTemplate.DeleteSession.title,
			icon: <ExclamationCircleOutlined />,
			content: locale.OrderTemplate.DeleteSession.message,
			okButtonProps: {
				danger: true,
				icon: <DeleteOutlined />,
			},
			onOk () {
				const apiCall = async () => {
					try {
						const response = await deleteTrainingSession({id: String(sessionData.id)});
						if (response.success) {
							goToSessionList();
							notification.success({
								message: locale.OrderTemplate.DeleteSession.successTitle,
							});
						}
					} catch (error) {
						handleErrorFetch(error);
					}
				};

				apiCall();
			},
			okText: locale.OrderTemplate.DeleteSession.okText,
			cancelText: locale.OrderTemplate.DeleteSession.cancelText,
		});
	};

	const showComplete = () => {
		Modal.confirm({
			centered: true,
			title: locale.OrderTemplate.EndSession.title,
			icon: <ExclamationCircleOutlined />,
			content: locale.OrderTemplate.EndSession.message,
			onOk () {
				const apiCall = async () => {
					try {
						const response = await completeTraining({id: String(sessionData.id)});
						if (response.success) {
							refetchData();
							notification.success({
								message: locale.OrderTemplate.EndSession.successTitle,
								description: locale.OrderTemplate.EndSession.successTitle,
							});
						}
					} catch (error) {
						handleErrorFetch(error);
					}
				};

				apiCall();
			},
			okText: locale.OrderTemplate.EndSession.okText,
			cancelText: locale.OrderTemplate.EndSession.cancelText,
		});
	};

	return (
		<LocalPageHeader
			showBackButton
			customBreadcrumbLabel={isEmpty(sessionData) ? '' : `${sessionData?.Location?.label} - ${moment(sessionData.createdAt).format('DD MMM YYYY')}`}
			backButtonAction={() => goToSessionList()}
			headerTitle={
				locale.title
					.replace('{{outletLabel}}', sessionData?.Location?.label)
					.replace('{{time}}', moment(sessionData.createdAt).format('DD MMM YYYY'))
			}
			subtitle={
				locale.subtitle
					.replace('{{createdBy}}', sessionData?.creator?.username)
			}
			extra={[
				...(sessionData?.isCompleted)
					? [
						<Button
							icon={<CheckOutlined />}
							disabled
							type='primary'
							key='complete'>{
								locale.completeAt
									.replace(
										'{{timestamp}}',
										moment(sessionData?.completedAt)
											.format('DD MMM YYYY'),
									)
							}
						</Button>,
					]
					: [
						<Button
							danger
							onClick={() => showDelete()}
							icon={<DeleteOutlined />}
							key='delete'>{locale.deleteTraining}
						</Button>,
						<Button
							type='primary'
							onClick={() => showComplete()}
							key='complete'>{locale.completeTraining}
						</Button>,
						<Button
							icon={<ReloadOutlined />}
							type='default'
							onClick={() => refetchData()}
							key='refresh'>{locale.refresh}
						</Button>,
					],
			]}
		/>
	);
};

Header.defaultProps = {
	sessionData: {},
	refetchData: () => null,
};

Header.propTypes = {
	sessionData: PropTypes.object,
	refetchData: PropTypes.func,
};

export default Header;