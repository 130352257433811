import {Tabs} from 'antd';
import React, {useState} from 'react';

import {AVAILABLE_POS} from 'utils/constants';
import MenuStructureTable from '../MenuStructureTable';

const MenuStructureTabs = () => {
	const [activeTabs, setActiveTabs] = useState('1');

	const getTabLabel = posLabel => {
		switch (posLabel) {
		case AVAILABLE_POS.ESB.label:
			return 'ERP';
		case AVAILABLE_POS.ODOO.label:
			return 'KDS';
		default:
			return posLabel;
		}
	};

	return (
		<>
			<div className='px-6'>
				<Tabs
					activeKey={activeTabs}
					onChange={e => setActiveTabs(e)}>
					{
						Object.values(AVAILABLE_POS).map(pos => (
							<Tabs.TabPane
								tab={getTabLabel(pos.label)}
								key={pos.id}
						 />
						))
					}
				</Tabs>
			</div>
			<MenuStructureTable
				posId={activeTabs}
			/>
		</>
	);
};

export default MenuStructureTabs;