import React from 'react';
import PropTypes from 'prop-types';

import MaterialForm from 'pages/Masterlist/MasterlistForm/MasterlistFormBody/MaterialForm';

const MaterialDetail = ({content}) => {
	return (
		<div
			style={{maxHeight: 500}}
			className='overflow-auto'
		>
			<MaterialForm
				itemRecord={content}
				viewOnly />
		</div>
	);
};

MaterialDetail.defaultProps = {
	content: {},
};

MaterialDetail.propTypes = {
	content: PropTypes.object,
};

export default MaterialDetail;