import React from 'react';
import PropTypes from 'prop-types';

import {Alert, Button, Input, Modal} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';

import {exportToTsvFn} from 'utils/utils';

import localization from 'localization';
const locale = localization.Admin.UserPasswordModal;

const UserPasswordModal = ({visible, isReset, onClose, userDetail}) => {
	// State
	const actionLocale = isReset ? locale.ResetPassword : locale.CreateUser;

	const handleDownloadToTsv = async () => {
		let tsvContent = `username	password\n${userDetail.username}	${userDetail.password}`;

		const today = new Date();
		const yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();

		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;

		const dateString = `${dd}${mm}${yyyy}`;

		const fileName =
		isReset
			? `PASSWORD_RESET_${userDetail.username}_${dateString}.tsv`
			: `USER_${userDetail.username}_${dateString}.tsv`;

		await exportToTsvFn({
			fileName,
			actionType: 'template',
			tsvTemplate: tsvContent,
		});
	};

	return (
		<Modal
			title={actionLocale.title}
			closable={false}
			visible={visible}
			width={572}
			centered
			footer={(
				<div className='flex gap-2 justify-end'>
					<Button
						onClick={handleDownloadToTsv}
						icon={<DownloadOutlined />} >{locale.downloadTsv}</Button>
					<Button
						onClick={onClose}
						type='primary'>{locale.done}</Button>
				</div>
			)}
		>
			<div className='flex flex-col gap-4'>
				<Alert
					message={actionLocale.info}
					type='success'
					showIcon />
				<div className='flex flex-col gap-2'>
					<div className='body-regular-14'>{locale.usernameLabel}</div>
					<Input value={userDetail.username} />
				</div>
				<div className='flex flex-col gap-2'>
					<div className='body-regular-14'>{locale.passwordLabel}</div>
					<Input.Password
						value={userDetail.password} />
				</div>
			</div>
		</Modal>

	);
};

UserPasswordModal.defaultProps = {
	visible: true,
	onClose: () => null,
	isReset: false,
	userDetail: {},
};

UserPasswordModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	isReset: PropTypes.bool,
	userDetail: PropTypes.object,
};

export default UserPasswordModal;