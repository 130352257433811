import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Input, Modal, Typography} from 'antd';
const {Title, Text} = Typography;

const ModalWithInput = ({
	visible,
	close,
	onOk,
	inputDefaultValue,
	localization,
	loading,
}) => {
	const [inputField, setInputField] = useState(inputDefaultValue || '');

	useEffect(() => {
		if (visible) setInputField(inputDefaultValue || '');
	}, [visible]);

	return (
		<Modal
			title=''
			visible={visible}
			closable={false}
			centered
			footer={[
				<Button
					onClick={close}
					disabled={loading}
					key="cancel">{localization?.cancelText}</Button>,
				<Button
					disabled={!inputField.length}
					onClick={() => onOk(inputField)}
					key="ok"
					loading={loading}
					type='primary'>{localization?.okText}</Button>,
			]}
		>
			<div className='flex flex-col'>
				<Title
					className='mb-1'
					level={5}>{localization?.title}</Title>
				<Text>{localization?.description}</Text>
				<Input
					value={inputField}
					onChange={e => setInputField(e.target.value)}
					className='mt-4'
					addonBefore={localization?.inputAddOn}
					placeholder={localization?.inputPlaceholder}
				/>
			</div>
		</Modal>
	);
};

ModalWithInput.defaultProps = {
	visible: false,
	close: () => null,
	onOk: () => null,
	localization: {},
	inputDefaultValue: '',
	loading: false,
};

ModalWithInput.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	onOk: PropTypes.func,
	localization: PropTypes.object,
	inputDefaultValue: PropTypes.string,
	loading: PropTypes.bool,
};

export default ModalWithInput;