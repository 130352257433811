import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Checkbox, Divider, Image, Input, Typography} from 'antd';
import SettingSelect from '../SettingSelect';
import clsx from 'clsx';
import Context from 'pages/SalesChannel/MenuTemplateForm/MenuTemplateFormBody/Context';

const SettingMenuLevel = ({menus, handleSettingChange, checkedMenuIndex, setCheckedMenuIndex}) => {
	const {viewOnly} = useContext(Context);
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollRef = useRef(null);

	const menuChangeHandler = ({value, field, menuIndex}) => {
		handleSettingChange({value, field, level: 'menu', menuIndex});
	};

	useEffect(() => {
		const handleScroll = () => {
			const position = scrollRef.current.scrollTop;
			setScrollPosition(position);
		};

		scrollRef?.current?.addEventListener?.('scroll', handleScroll);
		return () => {
			scrollRef?.current?.removeEventListener?.('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<div className={clsx('px-6 pt-3 pb-6', scrollPosition > 0 && 'shadow-lg')}>
				<Input placeholder='Search menu name' />
			</div>
			<div
				ref={scrollRef}
				className='flex-1 overflow-auto'>
				<Checkbox.Group
					className='w-full'
					disabled={viewOnly}
					value={checkedMenuIndex}
					onChange={selectedMenu => setCheckedMenuIndex(selectedMenu)}
				>
					{menus.map((menu, index) => (
						<div key={menu.menuId} >
							<div className={clsx(
								'flex gap-4',
								index === 0 ? 'px-6 pb-6' : 'p-6',
							)}>
								<Checkbox
									value={index}
									className='mt-6 self-start'
								/>
								<div className='flex flex-col gap-4 w-full'>
									{/* Menu Info */}
									<div className='flex flex-row gap-6 items-center'>
										{
											menu.imageUrl
												? (
													<Image
														width={80}
														src={menu.imageUrl}
													/>
												)
												: (
													<div
														className='w-20 h-20 rounded-md bg-antd-netural-4 flex flex-col gap-2 items-center justify-center text-gray-500 select-none'>
														{'No Image'}
													</div>
												)
										}
										<div>
											<div><Typography.Text type='secondary'>{menu.menuId}</Typography.Text></div>
											<div>{menu.menuLabel}</div>
										</div>
									</div>

									{/* Setting Info */}
									<SettingSelect
										typeValue={menu.locationFilter.type}
										locationValue={menu.locationFilter.locationIds}
										onChange={({value, field}) => menuChangeHandler({value, field, menuIndex: index})}
									/>
								</div>
							</div>
							<Divider className='m-0' />
						</div>
					))}
				</Checkbox.Group>
			</div>
		</>
	);
};

SettingMenuLevel.defaultProps = {
	menus: [],
	handleSettingChange: () => null,
	checkedMenuIndex: [],
	setCheckedMenuIndex: () => null,
};

SettingMenuLevel.propTypes = {
	menus: PropTypes.array,
	handleSettingChange: PropTypes.func,
	checkedMenuIndex: PropTypes.array,
	setCheckedMenuIndex: PropTypes.func,
};

export default SettingMenuLevel;