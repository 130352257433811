import React from 'react';
import PropTypes from 'prop-types';

import {useLocation} from 'react-router-dom';

import OutletBranchFormHeader from './OutletBranchFormHeader';
import OutletBranchFormBody from './OutletBranchFormBody';

const OutletBranchForm = ({isEdit}) => {
	const {state} = useLocation();
	const itemRecord = state?.itemRecord;

	return (
		<div className="bg-white h-full overflow-auto">
			<OutletBranchFormHeader
				isEdit={isEdit}
				itemRecord={itemRecord}
			/>
			<OutletBranchFormBody
				isEdit={isEdit}
				itemRecord={itemRecord}
			/>
		</div>
	);
};

OutletBranchForm.defaultProps = {
	isEdit: false,
};

OutletBranchForm.propTypes = {
	isEdit: PropTypes.bool,
};

export default OutletBranchForm;