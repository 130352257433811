import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Modal} from 'antd';

import localization from 'localization';
import ChooseFile from './ChooseFile';

import ImportCSVOverlay from 'components/Overlay/ImportCSVOverlay';

const ImportModal = ({visible, activeTab, close, refetchTable}) => {
	const locale = localization.Sales.FileUpload.ImportModal;

	const isSettlement = activeTab === 'settlement';
	const actionLocale = isSettlement ? locale.Settlement : locale.Pairing;

	const [uploadedFile, setUploadedFile] = useState(null);
	const [selectedPlatform, setSelectedPlatform] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);

	const [importFileHandler, setImportFileHandler] = useState({visible: false});

	const handleImport = () => {
		setImportFileHandler({visible: true});
	};

	const ModalFooter = () => [
		<Button
			key="cancel"
			onClick={() => close()}
		>
			{locale.cancel}
		</Button>,
		<Button
			type='primary'
			key="ok"
			onClick={() => handleImport(2)}
		>
			{locale.ok}
		</Button>,
	];

	const closeHandler = () => {
		refetchTable();
		close();
		setImportFileHandler({visible: false});
	};

	useEffect(() => {
		if (visible) {
			// Cleanup
			setUploadedFile(null);
			setSelectedDate(null);
			setSelectedPlatform(null);
		}
	}, [visible]);

	return (
		<>
			<ImportCSVOverlay
				masterlistType={activeTab}
				uploadParams={isSettlement ? {platform: selectedPlatform, reportDate: selectedDate} : {}}
				uploadedFile={uploadedFile}
				visible={importFileHandler.visible}
				importType={'add'}
				close={() => closeHandler()}
			/>
			<Modal
				destroyOnClose
				title={actionLocale.title}
				centered
				visible={visible && !importFileHandler.visible}
				onCancel={close}
				width={572}
				footer={ModalFooter()}
			>
				<ChooseFile
					selectedPlatform={selectedPlatform}
					setSelectedPlatform={setSelectedPlatform}
					activeTab={activeTab}
					uploadedFile={uploadedFile}
					setUploadedFile={setUploadedFile}
					setSelectedDate={setSelectedDate}
				/>
			</Modal>
		</>
	);
};

ImportModal.defaultProps = {
	visible: false,
	activeTab: null,
	close: () => null,
	refetchTable: {},
};

ImportModal.propTypes = {
	visible: PropTypes.bool,
	activeTab: PropTypes.string,
	close: PropTypes.func,
	refetchTable: PropTypes.object,
};

export default ImportModal;