import PropTypes from 'prop-types';

import {Card, Form, Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {PACKAGING_OPTIONS, PARENT_MAPPING_OPTIONS} from 'utils/constants';
import {isEmpty} from 'lodash';

const PARENT_CARD_BG_COLOR = '#E6F7FF';

import localization from 'localization';
const locale = localization.MasterlistPage.MasterlistForm.MenuStructureForm.ManageMapping;

const CardWrapper = ({
	title, type, selectedParentMenu, isNone, previousPhase, form, children, separatable,
	footer,
}) => {
	const optionGroupsWatch = Form.useWatch('optionGroups', form);
	const isParent = selectedParentMenu === type;

	const parentDoesntPrint = () => {
		if (!separatable) return false;
		if (type === PARENT_MAPPING_OPTIONS.OPTION_GROUP && !isEmpty(optionGroupsWatch)) {

			const defaultPackagings = Object.keys(optionGroupsWatch)
				.filter(id => optionGroupsWatch[id]?.packaging?.type == PACKAGING_OPTIONS.DEFAULT);

			return isEmpty(defaultPackagings) ? true : false;
		} else {
			return false;
		}
	};

	return (
		<>
			<Card
				headStyle={isParent ? {backgroundColor: PARENT_CARD_BG_COLOR} : {}}
				title={(
					<div className='text-sm font-normal flex items-center justify-between'>
						<div className='flex items-center gap-1 text-base font-bold'>
							{title}
							<div className='font-normal text-antd-warning-6'>
								{
									parentDoesntPrint()
										? (
											<>
												{' · '}
												{'Parent menu label will not be printed'}
											</>
										)
										: null
								}
							</div>
							{
								isParent && (
									<span className='text-sm font-normal'>{locale.isParent}</span>
								)
							}
						</div>
						{
							(isParent || isNone && type === PARENT_MAPPING_OPTIONS.MENU) && (
								<div
									onClick={() => {
										Modal.confirm({
											icon: <ExclamationCircleOutlined />,
											title: locale.EditConditionConfirmationModal.title,
											content: locale.EditConditionConfirmationModal.content,
											cancelText: locale.EditConditionConfirmationModal.cancel,
											okText: locale.EditConditionConfirmationModal.ok,
											onOk: () => previousPhase(),
											okButtonProps: {danger: true},
											centered: true,
										});
									}}
									className='text-antd-blue-6 cursor-pointer'>{locale.editCondition}</div>
							)
						}
					</div>
				)}>
				{children}
			</Card>
			{
				footer && (
					<div
						style={{paddingInline: '10px'}}
						className='py-4 border bg-antd-netural-2 rounded-b-md flex justify-end'>
						{footer()}
					</div>
				)
			}
		</>
	);
};

CardWrapper.defaultProps = {
	title: '',
	type: null,
	selectedParentMenu: undefined,
	isNone: false,
	previousPhase: () => null,
	children: null,
	form: {},
	separatable: false,
	footer: null,
};

CardWrapper.propTypes = {
	title: PropTypes.string,
	type: PropTypes.string,
	selectedParentMenu: PropTypes.string,
	isNone: PropTypes.bool,
	previousPhase: PropTypes.func,
	children: PropTypes.any,
	form: PropTypes.object,
	separatable: PropTypes.bool,
	footer: PropTypes.func,
};

export default CardWrapper;