import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import UserFormHeader from './UserFormHeader';
import UserFormBody from './UserFormBody';

const UserForm = ({isEdit}) => {
	const {state} = useLocation();

	return (
		<div className='bg-white h-full overflow-auto'>
			<UserFormHeader
				isEdit={isEdit}
				userDetail={{
					username: state?.username,
					roles: state?.roles,
				}} />
			<UserFormBody
				isEdit={isEdit}
				userDetail={{
					id: state?.id,
					username: state?.username,
					roles: state?.roles,
					name: state?.name,
					email: state?.email,
					phoneNumber: state?.phoneNumber,
				}} />
		</div>
	);
};

UserForm.defaultProps = {
	isEdit: false,
};

UserForm.propTypes = {
	isEdit: PropTypes.bool,
};

export default UserForm;