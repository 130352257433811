import React, {forwardRef, useState} from 'react';
import capitalize from 'lodash/capitalize';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {InfoCircleFilled} from '@ant-design/icons';
import ProTable from '@ant-design/pro-table';
import {Button, Popconfirm} from 'antd';

import {getEjsStructure} from 'utils/printer';
import {getFinalTax} from 'utils/request/sales';
import {handleErrorFetch, localeCurrencyFormat, momentDateFormat, ellipsisItem, salesPayloadHandler} from 'utils/utils';
import {USER_ROLES} from 'utils/constants';

const FinalDashboardTable = forwardRef(({openDetailDrawer, metricFormRef}, ref) => {
	const auth = useSelector(state => state.user);

	const [selected, setSelected] = useState([]);

	const isInElectron = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

	const columns = [
		{
			width: 111,
			key: 'sales_date',
			dataIndex: 'sales_date',
			title: ellipsisItem('Sales Date'),
			render: sales_date => ellipsisItem(momentDateFormat({dateString: sales_date})),
		},
		{
			width: 190,
			key: 'sales_number',
			dataIndex: 'sales_number',
			title: ellipsisItem('Sales Number'),
			render: sales_number => ellipsisItem(sales_number),
		},
		{
			width: 177,
			key: 'bill_number',
			dataIndex: 'bill_number',
			title: ellipsisItem('Bill Number'),
			render: bill_number => ellipsisItem(bill_number),
		},
		{
			width: 250,
			key: 'external_long_id',
			dataIndex: 'external_long_id',
			title: ellipsisItem('Long ID'),
			render: external_long_id => ellipsisItem(external_long_id),
		},
		{
			width: 100,
			key: 'external_short_id',
			dataIndex: 'external_short_id',
			title: ellipsisItem('Short ID'),
			render: external_short_id => ellipsisItem(external_short_id),
		},
		{
			width: 150,
			key: 'platform_transaction_id',
			dataIndex: 'platform_transaction_id',
			title: ellipsisItem('Settlement ID'),
			render: platform_transaction_id => ellipsisItem(platform_transaction_id),
		},
		{
			key: 'sales_status',
			dataIndex: 'sales_status',
			title: ellipsisItem('Sales Status'),
			render: sales_status => ellipsisItem(sales_status),
		},
		{
			key: 'branch',
			dataIndex: 'branch',
			title: ellipsisItem('Branch'),
			render: branch => ellipsisItem(branch),
		},
		{
			key: 'brand',
			dataIndex: 'brand',
			title: ellipsisItem('Brand'),
			render: brand => ellipsisItem(brand),
		},
		{
			key: 'platform',
			dataIndex: 'platform',
			title: ellipsisItem('Platform'),
			render: platform => ellipsisItem(capitalize(platform)),
		},
		{
			key: 'total_normal_price',
			dataIndex: 'total_normal_price',
			title: ellipsisItem('Total'),
			render: total_normal_price => ellipsisItem(localeCurrencyFormat({value: total_normal_price})),
		},
		{
			key: 'price_cut',
			dataIndex: 'price_cut',
			title: ellipsisItem('Price Cut'),
			render: price_cut => ellipsisItem(localeCurrencyFormat({value: price_cut})),
		},
		{
			key: 'subtotal',
			dataIndex: 'subtotal',
			title: ellipsisItem('Subtotal'),
			render: subtotal => ellipsisItem(localeCurrencyFormat({value: subtotal})),
		},
		{
			key: 'promo_by_hangry',
			dataIndex: 'promo_by_hangry',
			title: ellipsisItem('Diskon Voucher'),
			render: promo_by_hangry => ellipsisItem(localeCurrencyFormat({value: promo_by_hangry})),
		},
		{
			key: 'sales_after_tax',
			dataIndex: 'sales_after_tax',
			title: ellipsisItem('Sales After Tax'),
			render: sales_after_tax => ellipsisItem(localeCurrencyFormat({value: sales_after_tax})),
		},
		{
			key: 'sales_before_tax',
			dataIndex: 'sales_before_tax',
			title: ellipsisItem('Sales Before Tax'),
			render: sales_before_tax => ellipsisItem(localeCurrencyFormat({value: sales_before_tax})),
		},
		{
			key: 'pb1',
			dataIndex: 'pb1',
			title: ellipsisItem('PB 1'),
			render: pb1 => ellipsisItem(localeCurrencyFormat({value: pb1})),
		},
		{
			key: 'cashier',
			dataIndex: 'cashier',
			title: ellipsisItem('Cashier'),
			render: cashier => ellipsisItem(cashier),
		},
		{
			key: 'action',
			title: 'Action',
			fixed: 'right',
			align: 'center',
			render: (_, record) => (
				<span
					onClick={() => openDetailDrawer(record)}
					className='text-antd-blue-6 cursor-pointer'>See Order Details</span>
			),
		},
	];

	const getColumns = () => {
		if ([USER_ROLES.SUPERADMIN, USER_ROLES.FINANCE.EDITOR].some(role => auth.roles.includes(role))) {
			return columns;
		} else if (auth.roles.includes(USER_ROLES.FINANCE.VIEWER)) {
			const validColForViewer = [
				'sales_date', 'external_long_id', 'external_short_id',
				'branch', 'brand', 'platform', 'subtotal', 'promo_by_hangry',
				'total_normal_price', 'sales_before_tax', 'pb1', 'cashier', 'action'];
			return columns.filter(col => validColForViewer.includes(col.key));
		} else return [];
	};

	const fetchTableData = async params => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */
			const metricPayload = salesPayloadHandler(metricFormRef.current?.getMetricFilter?.());

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				...metricPayload,
			};
			const response = await getFinalTax(payload);
			metricFormRef.current?.updateMetricData?.({
				count: response.data.count,
				amount: response?.data?.summary?.sum_sales_before_tax,
				tax: response?.data?.summary?.sum_pb1,
			});
			return {
				data: response.data.transactions,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const handlePrint = () => {
		const dataStructures = [];
		selected.forEach(item => (
			dataStructures.push(getEjsStructure(item))
		));

		window.api?.send?.('printTaxes', {
			dataStructures,
		});

		setSelected([]);
	};

	const handleOnSelect = (record, selected) => {
		if (selected) { // Check
			setSelected(state => state.concat(record));
		} else { // Uncheck
			setSelected(state => state.filter(item => item.external_long_id !== record.external_long_id));
		}
	};

	const handleSelectAll = (selected, _, changeRows) => {
		if (selected) {
			const tempSelectedMenu = [];

			changeRows.forEach(item => {
				tempSelectedMenu.push(item);
			});

			setSelected(state => state.concat(tempSelectedMenu));
		} else {
			const idOfChangeRows = changeRows.map(item => item.external_long_id);
			setSelected(state => state.filter(item => !idOfChangeRows.includes(item.external_long_id)));
		}
	};

	const rowSelection = {
		selectedRowKeys: selected.map(menu => menu.external_long_id),
		onSelectAll: handleSelectAll,
		onSelect: handleOnSelect,
		getCheckboxProps: record => ({
			name: record.external_long_id,
		}),
	};

	const renderTableAlert = ({selectedRowKeys}) =>
		selectedRowKeys.length > 0 && (
			<div className='flex gap-3 items-center'>
				<InfoCircleFilled className='text-antd-blue-6' />
				<div>
					<span className='font-semibold'>{selectedRowKeys.length}</span> items selected
				</div>
			</div>
		);

	const renderTableAlertOption = () => (
		<div className='flex gap-2 items-center'>
			<div
				onClick={() => {
					setSelected([]);
				}}
				className='text-antd-blue-6 px-2 cursor-pointer'>Clear</div>

			<Popconfirm
				placement='topRight'
				title={'Are you sure you want to print these transactions?'}
				onConfirm={() => handlePrint()}
				okText={'Yes'}
				cancelText={'No'}
				disabled={selected.length === 0}
			>
				<Button
					size='small'
					type="primary"
					disabled={selected.length === 0}
				>
					Print Struk ({selected.length})
				</Button>
			</Popconfirm>
		</div>
	);

	return (
		<div>
			<ProTable
				actionRef={ref}
				className="mt-4"
				rowKey='external_long_id'
				columns={getColumns()}
				search={false}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				layout="vertical"
				tableAlertRender={(renderTableAlert)}
				tableAlertOptionRender={(renderTableAlertOption)}
				rowSelection={isInElectron && rowSelection}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>

		</div>
	);
});

FinalDashboardTable.defaultProps = {
	metricFormRef: {},
	openDetailDrawer: () => null,
};

FinalDashboardTable.propTypes = {
	metricFormRef: PropTypes.object,
	openDetailDrawer: PropTypes.func,
};

export default FinalDashboardTable;