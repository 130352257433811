import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Modal, Typography, Input, Button} from 'antd';
import {getLabel} from 'utils/utils';

const {Title, Text} = Typography;

import localization from 'localization';
const locale = localization.Global.ExportCSVOverlay;

const ExportCSVOverlay = ({visible, close, type, handleExport, masterlistType, providedFileName, fileFormat, loading}) => {
	const isExport = type === 'export';

	const decideName = () => {
		if (providedFileName) {
			return providedFileName;
		} else {
			const baseFileName = window.location.pathname.includes('masterlist') ? 'masterlist-' : '';
			if (isExport) return (`${baseFileName}${getLabel(masterlistType, '-')}-orderhub-${new Date().valueOf()}.${fileFormat}`);
			else return (`${baseFileName}${getLabel(masterlistType, '-')}-template-default.${fileFormat}`);
		}
	};

	const [fileName, setFileName] = useState('');

	const handleFileNameChange = e => {
		/* eslint-disable no-control-regex */
		const regex = /[<>:"/\\|?*\u0000-\u001F]/g;
		const fileNameValid = !regex.test(e.target.value);
		if (fileNameValid) setFileName(e.target.value);
	};

	const trimFileName = fileName => {
		const splittedFileName = fileName.split('.');
		const fileFormat = splittedFileName.pop();
		if (fileFormat === fileFormat) {
			return `${splittedFileName.join('.')}.${fileFormat}`;
		} else {
			return `${splittedFileName.join('.')}.${fileFormat}.${fileFormat}`;
		}
	};

	const beforeExport = () => {
		handleExport({
			fileName: trimFileName(fileName) || decideName(),
			type,
		});
	};

	useEffect(() => {
		visible && setFileName(decideName());
	}, [visible]);

	return (
		<Modal
			visible={visible}
			title=''
			footer={null}
			closable={false}
			onCancel={close}
			width={572}
			centered
		>
			<div>
				<div className='space-y-2'>
					<Title level={4}>{isExport ? locale.ExportData.title : locale.DownloadTemplate.title}</Title>
					<Text>{
						isExport
							? locale.ExportData.description
							: locale.DownloadTemplate.description.replace('{{type}}', getLabel(masterlistType))
					}</Text>
				</div>
				<Input
					className='pt-9'
					addonBefore={locale.inputAddOn}
					value={fileName}
					onChange={handleFileNameChange}
					onPressEnter={beforeExport}
				/>
			</div>
			<div className='flex space-x-2 justify-end pt-8'>
				<Button onClick={close}>{locale.cancel}</Button>
				<Button
					loading={loading}
					onClick={beforeExport}
					type="primary">
					{locale.export.replace('{{action}}', isExport ? locale.ExportData.action : locale.DownloadTemplate.action)}
				</Button>
			</div>
		</Modal>
	);
};

ExportCSVOverlay.defaultProps = {
	visible: false,
	close: () => null,
	type: 'export',
	handleExport: () => null,
	masterlistType: 'menu',
	providedFileName: '',
	fileFormat: 'tsv',
	loading: false,
};

ExportCSVOverlay.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	type: PropTypes.oneOf(['export', 'download-template']),
	handleExport: PropTypes.func,
	masterlistType: PropTypes.string,
	providedFileName: PropTypes.string,
	fileFormat: PropTypes.string,
	loading: PropTypes.bool,
};

export default ExportCSVOverlay;
