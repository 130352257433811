import React from 'react';
import PropTypes from 'prop-types';

import {Button, Input} from 'antd';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {calculateCartMenuprice, moneyFormat} from 'utils/utils';
import {isEmpty} from 'lodash';
import localization from 'localization';

const FooterAction = ({cartItemData, setCartItemData, onSave, isEdit}) => {
	const locale = localization.MenuTemplate.MenuTemplateForm.OrderMenuDrawer;

	const quantity = cartItemData?.menuItem?.quantity || 1;

	const onChangeQuantity = inputtedQty => {
		if (/^\d*$/.test(inputtedQty)) {
			const qtyVal = inputtedQty === '' ? 1 : parseInt(inputtedQty, 10);
			setCartItemData(prevValue => ({
				...prevValue,
				menuItem: {
					...prevValue.menuItem,
					quantity: qtyVal,
				},
			}));
		}
	};

	const handleDecrement = () => {
		setCartItemData(prevValue => ({
			...prevValue,
			menuItem: {
				...prevValue.menuItem,
				quantity: Math.max(quantity - 1, 1),
			},
		}));
	};

	const handleIncrement = () => {
		setCartItemData(prevValue => ({
			...prevValue,
			menuItem: {
				...prevValue.menuItem,
				quantity: quantity + 1,
			},
		}));
	};

	const minSelectionFullfilled = () => {
		if (isEmpty(cartItemData)) return false;
		const menuItem = cartItemData?.menuItem;
		const dataOrigin = cartItemData?.dataOrigin;
		// Create a mapping of optionGroupId to minSelection from dataOrigin
		const minSelectionsMap = {};
		dataOrigin.optionGroups.forEach(group => {
			minSelectionsMap[group.optionGroupId] = parseInt(group.minSelection, 10);
		});

		// Check if options length inside menuItem is >= minSelection
		return menuItem.optionGroups.every(group => {
			const minSelection = minSelectionsMap[group.optionGroupId] || 0;
			return group.options.length >= minSelection;
		});
	};

	return (
		<div className='flex p-6 border-t gap-6'>

			{/* Quantity */}
			<div className="flex items-center border w-32 flex-shrink-0">
				<div
					onClick={handleDecrement}
					className='px-3 py-1 border-0 hover:bg-gray-100 cursor-pointer hover:text-antd-blue-6'>
					<MinusOutlined />
				</div>
				<Input
					value={quantity}
					onChange={e => onChangeQuantity(e.target.value)}
					className="text-center border-0 hover:border-0 focus:border-0 focus:shadow-none px-0"
				/>
				<div
					onClick={handleIncrement}
					className='px-3 py-1 border-0 hover:bg-gray-100 cursor-pointer hover:text-antd-blue-6'>
					<PlusOutlined />
				</div>
			</div>

			{/* Add to cart */}
			<Button
				onClick={onSave}
				disabled={!minSelectionFullfilled()}
				type='primary'
				className='flex-1'>
				{
					(isEdit ? locale.editMenu : locale.addMenu).replace('{{price}}', moneyFormat({value: calculateCartMenuprice(cartItemData, quantity)}))
				}
			</Button>
		</div>
	);
};

FooterAction.defaultProps = {
	cartItemData: {},
	setCartItemData: () => null,
	onSave: () => null,
	isEdit: false,
};

FooterAction.propTypes = {
	cartItemData: PropTypes.object,
	setCartItemData: PropTypes.func,
	onSave: PropTypes.func,
	isEdit: PropTypes.fucn,
};

export default FooterAction;