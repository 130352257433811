import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import {Typography} from 'antd';
import localization from 'localization';

import moment from 'moment';

const OrderInfo = ({infoData}) => {
	const locale = localization.Sales.TaxDashboard.ReceiptDrawer;

	const infoItems = [
		{
			label: locale.date,
			value: moment(infoData?.sales_date).format('DD-MM-YYYY HH:mm'),
		},
		{
			label: locale.method,
			value: capitalize(infoData?.method),
		},
		{
			label: locale.from,
			value: `${infoData?.brand} ${infoData?.platform}`,
		},
		{
			label: locale.cashier,
			value: infoData?.cashier,
		},
		{
			label: locale.longId,
			value: infoData?.external_long_id,
		},
		{
			label: locale.shortId,
			value: infoData?.external_short_id,
		},
	];

	return (
		<div className='pt-6 pb-8 px-6'>
			<div className='grid grid-cols-2 gap-x-3 gap-y-6'>
				{infoItems.map((item, i) => (
					<div
						className='flex flex-col'
						key={i}>
						<Typography.Text type='secondary'>
							{item.label}
						</Typography.Text>
						<Typography.Text>
							{item.value}
						</Typography.Text>
					</div>
				))}
			</div>
		</div>
	);
};

OrderInfo.defaultProps = {
	infoData: {},
};

OrderInfo.propTypes = {
	infoData: PropTypes.object,
};

export default OrderInfo;