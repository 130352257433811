import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {getLabel} from 'utils/utils';
// import {MASTERLIST_TYPE} from 'utils/constants';

import {Result, Button, Typography, Input} from 'antd';
import {CloseCircleOutlined} from '@ant-design/icons';

const {Paragraph, Title} = Typography;

const UploadResults = ({
	success, uploadData, close, submit, handleFileUpload, masterlistType, importType, loading, displayLabelInput, fileType,
}) => {
	const [inputLabel, setInputLabel] = useState('');

	const isEdit = importType !== 'add';

	if (!success) {
		displayLabelInput = false;
	}

	// const handlePluralText = (text, dataQty) => {
	// 	if (text === MASTERLIST_TYPE.CATEGORY) {
	// 		if (dataQty > 1) return 'categories';
	// 		else return 'category';
	// 	} else {
	// 		return `${getLabel(text)}${dataQty > 1 ? 's' : ''}`;
	// 	}
	// };

	return (
		<>
			<Result
				className='p-0 pb-6'
				status={success ? 'success' : 'error'}
				title={(
					<Title
						className='font-medium m-0'
						level={3}>{
							success
								? displayLabelInput
									? 'All content in this template is valid'
									: `Continue ${isEdit ? 'Updatting' : 'Adding'}?`
								: masterlistType === 'menu-template'
									? `${uploadData.errorRows.length} contents in this template are invalid`
									: 'CSV file is invalid'
						}</Title>
				)}
				subTitle={
					success
						? displayLabelInput
							? 'You can now naming this template and submit to Orderhub'
							: `All imported ${getLabel(masterlistType)} are valid. ${uploadData.rows.length} ${isEdit ? 'existing' : 'new'} ${getLabel(masterlistType)} will be ${isEdit ? 'updated in' : 'added to'} Fresto`
						: `${uploadData.errorRows.length} items are invalid. Please fix the errors in your csv file`
				}
			>

				{/* Error Summary */}
				{
					!success && (
						<div className='max-h-48 overflow-auto'>
							<Paragraph>
								<Title
									className='m-0 font-bold'
									level={5}>
									The content you submitted has the following error:
								</Title>
								<div className='space-y-3.5 pt-3.5'>
									{
										uploadData.errorRows.map(data => (
											<Paragraph
												className='m-0 flex items-center gap-1'
												key={data.rowNumber}>
												<CloseCircleOutlined /> {data.errorType} <span className='text-antd-blue-6'>In Row {data.rowNumber}</span>
											</Paragraph>
										))
									}
								</div>
							</Paragraph>
						</div>
					)
				}
				{/* End of Error Summary */}

			</Result>
			{
				displayLabelInput && (
					<div className='pb-6 px-32'>
						<Input
							addonBefore='Template Name'
							placeholder='Input the name'
							value={inputLabel}
							onChange={e => setInputLabel(e.target.value)}
						/>
					</div>
				)
			}
			<div className='space-x-2 flex items-center justify-center'>
				<Button
					key="cancel"
					onClick={close}
					disabled={loading}
				>
					Cancel
				</Button>
				<Button
					key="submit"
					type="primary"
					disabled={displayLabelInput ? !inputLabel : false}
					loading={loading}
					onClick={() => success ? submit(displayLabelInput && inputLabel) : close()}
				>
					<label className='cursor-pointer'>
						{success
							? isEdit
								? 'Update'
								: 'Add'
							: 'Reupload'
						}
						<input
							disabled={success}
							className="hidden"
							type="file"
							accept={`.${fileType || 'tsv'}`}
							onChange={handleFileUpload} />
					</label>
				</Button>
			</div>
		</>
	);
};

UploadResults.defaultProps = {
	success: false,
	uploadData: {},
	close: () => null,
	submit: () => null,
	handleFileUpload: () => null,
	masterlistType: 'menu',
	importType: 'add',
	loading: false,
	displayLabelInput: false,
	fileType: undefined,
};

UploadResults.propTypes = {
	success: PropTypes.bool,
	uploadData: PropTypes.object,
	close: PropTypes.func,
	submit: PropTypes.func,
	handleFileUpload: PropTypes.func,
	masterlistType: PropTypes.string,
	importType: PropTypes.string,
	loading: PropTypes.bool,
	displayLabelInput: PropTypes.bool,
	fileType: PropTypes.string,
};

export default UploadResults;
