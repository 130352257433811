import React, {useRef} from 'react';

import SyncRequestHeader from './SyncRequestHeader';
import SyncRequestBody from './SyncRequestBody';

const SyncRequest = () => {
	const containerRef = useRef(null);
	const refetchRequestRef = useRef(null);

	return (
		<div
			ref={containerRef}
			className="bg-white h-full overflow-auto flex flex-col">
			<SyncRequestHeader
				refetchRequestRef={refetchRequestRef}
			/>
			<SyncRequestBody
				containerRef={containerRef}
				refetchRequestRef={refetchRequestRef} />
		</div>
	);
};

export default SyncRequest;
