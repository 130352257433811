import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Typography} from 'antd';
const {Title, Text} = Typography;

import clsx from 'clsx';

let progressBarWatcher = null;

import localization from 'localization';
const locale = localization.Global.UploadProgress;

const UploadProgress = ({uploadingTitle, completeTitle}) => {
	const progressBarRef = useRef(null);

	const [barPercentage, setBarPercentage] = useState('0%');

	useEffect(() => {
		if (progressBarRef.current) {
			progressBarWatcher = setInterval(() => {
				setBarPercentage(progressBarRef.current.style.width);
			}, 100);
		}
		if (barPercentage === '100%') {
			clearInterval(progressBarWatcher);
		}
		return () => clearInterval(progressBarWatcher);
	}, [progressBarRef.current, barPercentage]);

	return (
		<div className="space-y-6">
			<Title
				className={clsx(barPercentage === '100%' && 'animate-pulse')}
				level={5}>
				{
					barPercentage === '100%'
						?	completeTitle || locale.checking
						: uploadingTitle || locale.uploading
				}
			</Title>
			<div className='flex space-between space-x-4'>
				<div className='w-full border rounded-lg overflow-hidden'>
					<div
						ref={progressBarRef}
						style={{width: '0%'}}
						id='upload-progressbar'
						className='bg-aqua-dark h-full transition-all duration-500'
					/>
				</div>
				<Text>{barPercentage}</Text>
			</div>
		</div>
	);
};

UploadProgress.defaultProps = {
	uploadingTitle: null,
	completeTitle: null,
};

UploadProgress.propTypes = {
	uploadingTitle: PropTypes.string,
	completeTitle: PropTypes.string,
};

export default UploadProgress;
