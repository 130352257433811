import React, {useRef, useState} from 'react';

import ModalWithInput from 'components/ModalWithInput';
import MenuTemplateHeader from './MenuTemplateHeader';
import MenuTemplateBody from './MenuTemplateBody';

import {exportToTsvFn, generateMasterlistCSVTemplate, handleErrorFetch, menuTemplateBodyFormatter} from 'utils/utils';
import {exportMasterlist} from 'utils/request/masterlist';

import isEmpty from 'lodash/isEmpty';

import {createMenuTemplateDraft, getMenuTemplateDetail} from 'utils/request/salesChannel';
import {STORAGE_KEY} from 'utils/constants';

import localization from 'localization';
const locale = localization.MenuTemplate;

const MenuTemplate = () => {

	const [createTemplateModalVisible, setCreateTemplateModalVisible] = useState(false);
	const [templateToCopy, setTemplateToCopy] = useState({});
	const [loading, setLoading] = useState(false);
	const getCSVOverlayRef = useRef(null);
	const refetchTableRef = useRef(null);

	const handleExport = async ({fileName, type = 'template'}, id) => {
		await exportToTsvFn({
			fileName,
			actionType: type,
			exportCall: () => exportMasterlist({id}, 'menu-template'),
			tsvTemplate: generateMasterlistCSVTemplate('menu-template'),
		});
	};

	const handleExportMenuTemplate = id => {
		getCSVOverlayRef.current(id);
	};

	const gotoCreatePage = async menuTemplateLabel => {
		try {
			setLoading(true);
			const isCopy = !isEmpty(templateToCopy);

			let menuTemplateObj = templateToCopy.body || templateToCopy.obj || [];

			// Remove mappingHash object to prevent error when creating template
			menuTemplateObj.forEach(category => {
				category.menus.forEach(menu => {
					delete menu.mappingHash;
				});
			});

			if (menuTemplateObj.length) {
				menuTemplateObj = menuTemplateObj.map(category => {
					const categoryOutput = {
						...category,
						menus: category.menus.map(menu => {
							const menuOutput = {...menu};

							menuOutput.locationFilter = {
								type: menuOutput?.locationFilter?.type || 'default',
								locationIds: menuOutput?.locationFilter?.locationIds || menuOutput?.locationFilter?.locationId || [-1],
							};

							return menuOutput;
						}),
					};

					categoryOutput.locationFilter = {
						type: categoryOutput?.locationFilter?.type || 'default',
						locationIds: categoryOutput?.locationFilter?.locationIds || categoryOutput?.locationFilter?.locationId || [-1],
					};

					return categoryOutput;
				});
			}

			const response = await createMenuTemplateDraft({label: menuTemplateLabel, obj: isCopy ? menuTemplateObj : []});
			if (response.success) {
				sessionStorage.setItem(STORAGE_KEY.MENU_TEMPLATE_STATE, JSON.stringify({
					menuTemplateLabel,
					draftId: response?.data?.templateId,
					menuTemplateObj,
				}));
				window.open('/sales-channel/menu-template/new', '_blank');
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const openCreateModal = async (config = {isCopy: false, payload: {}, useLatest: false}) => {
		try {
			if (config.isCopy) {
				const isDraft = 'obj' in config.payload;
				let menuTemplateObj = config?.payload?.body || config?.payload?.obj || [];
				menuTemplateObj = menuTemplateBodyFormatter(menuTemplateObj);

				if (isDraft) config.payload.obj = menuTemplateObj;
				else {
					const response = await getMenuTemplateDetail({id: config.payload.id, useLatest: config.useLatest});
					config.payload = response.data.details;
				}

				setTemplateToCopy(config.payload);
			}
			setCreateTemplateModalVisible(true);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const CreateModal = (
		<ModalWithInput
			loading={loading}
			visible={createTemplateModalVisible}
			close={() => {
				setCreateTemplateModalVisible(false);
				setTemplateToCopy({});
			}}
			onOk={gotoCreatePage}
			localization={{
				title: isEmpty(templateToCopy)
					? locale.CreateTemplateModal.title
					: locale.EditTemplateModal.title
						.replace('{{templateLabel}}', templateToCopy.label),
				description: locale.CreateTemplateModal.description,
				inputPlaceholder: locale.CreateTemplateModal.inputPlaceholder,
				inputAddOn: locale.CreateTemplateModal.inputAddOn,
				cancelText: locale.CreateTemplateModal.cancelText,
				okText: isEmpty(templateToCopy) ? locale.CreateTemplateModal.okText: locale.EditTemplateModal.okText,
			}}
		/>
	);

	return (
		<div className="bg-white h-full overflow-auto">
			{CreateModal}
			<MenuTemplateHeader
				openCreateModal={openCreateModal}
				refetchTableRef={refetchTableRef}
				getCSVOverlayRef={getCSVOverlayRef}
				handleExport={handleExport} />
			<MenuTemplateBody
				openCreateModal={openCreateModal}
				refetchTableRef={refetchTableRef}
				exportCSV={handleExportMenuTemplate}
			/>
		</div>
	);
};

export default MenuTemplate;
