import React, {useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import clsx from 'clsx';

import {Alert, Button, Input, List, Modal, Popconfirm, Select, Tooltip, Typography} from 'antd';
import {CloseCircleOutlined, DownloadOutlined} from '@ant-design/icons';
const {Title} = Typography;
const {Option} = Select;

import {exportToTsvFn, handleErrorFetch} from 'utils/utils';
import {OUTLET_TYPE, OUTLET_USER_MODAL_ACTION_TYPE} from 'utils/constants';
import {bulkAddRestoUser, bulkResetRestoUserPin} from 'utils/request/user';
import {getOutletData} from 'utils/request/outlet';

import localization from 'localization';
const locale = localization.Outlet.UserActivation.UserActionModal;

const UserActionModal = ({visible, type, onClose, usersToReset, refetchTableRef}) => {
	// State
	const isCreate = type === OUTLET_USER_MODAL_ACTION_TYPE.CREATE ? true : false;
	const actionLocale = isCreate ? locale.CreateUser : locale.ResetUser;

	const [fetchLocation, setFetchLocation] = useState(false);
	const [locationList, setLocationList] = useState([]);

	const [userInput, setUserInput] = useState('');
	const [userInputError, setUserInputError] = useState(false);

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedLocationId, setSelectedLocationId] = useState(null);

	const [actionDone, setActionDone] = useState(false);
	const [invalidUser, setInvalidUser] = useState([]);

	const [loading, setLoading] = useState(false);

	// Function
	const addSelectedUser = () => {
		if (!userInput.length) return;
		if (selectedUsers.some(user => user.username === userInput)) setUserInputError(true);
		else {
			setSelectedUsers(state => state.concat({username: userInput}));
			setUserInput('');
		}
	};

	const deleteSelectedUser = selectedUsers => {
		setSelectedUsers(state => state.filter(user => user.username !== selectedUsers.username));
	};

	const createUser = async () => {
		try {
			setLoading(true);
			const payload = {
				username: selectedUsers.map(user => user.username),
				roles: ['resto_admin'],
				locations: [selectedLocationId],
			};
			const response = await bulkAddRestoUser(payload);
			if (response.success === false) {
				// Invalid username (has been registered)
				if (response?.data?.username?.length) setInvalidUser(response.data.username);
			} else {
				// If success
				setSelectedUsers(response.data.users);
				handleDownloadToTsv(response.data.users);
				setActionDone(true);
				refetchTableRef?.current?.();
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const resetUser = async () => {
		try {
			setLoading(true);
			const payload = {userId: selectedUsers.map(user => user.id)};
			const response = await bulkResetRestoUserPin(payload);
			if (response.success) {
				setSelectedUsers(response.data.users);
				handleDownloadToTsv(response.data.users);
				setActionDone(true);
				refetchTableRef?.current?.({clearSelected: true});
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleClose = () => {
		// Reset modal state
		setUserInput('');
		setUserInputError(false);
		setSelectedUsers([]);
		setActionDone(false);
		setSelectedLocationId(null);
		setInvalidUser([]);

		onClose();
	};

	const getLocation = async outletLabel => {
		try {
			setFetchLocation(true);
			const response = await getOutletData({
				search: {label: outletLabel},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setFetchLocation(false);
		}
	};

	const handleDownloadToTsv = async usersDataPayload => {
		const usersData = usersDataPayload || selectedUsers;
		let tsvContent = 'username	pin';
		usersData.forEach(user => {tsvContent+= `\n${user.username}	${user.pinNumber}`;});

		const today = new Date();
		const yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();

		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;

		const dateString = `${dd}${mm}${yyyy}`;

		const locationLabel =
		locationList
			.find(location => location.id === selectedLocationId)
			?.label
			.split(' ')
			.join('_')
			.toUpperCase();

		const fileName =
		isCreate
			? `USER_${locationLabel}_${dateString}.tsv`
			: `PINRESET_${dateString}.tsv`;

		await exportToTsvFn({
			fileName,
			actionType: 'template',
			tsvTemplate: tsvContent,
		});
	};

	const debounceFn = useCallback(debounce(getLocation, 1000), []);

	// Component
	const DefaultFooter = () => (
		<div className='flex gap-2 justify-end'>
			<Button onClick={handleClose}>{locale.cancel}</Button>
			<Popconfirm
				disabled={
					isCreate
						? (!selectedUsers.length || !selectedLocationId || loading)
						: (!selectedUsers.length || loading)
				}
				className='max-w-xs'
				placement='topRight'
				onConfirm={() => type === OUTLET_USER_MODAL_ACTION_TYPE.CREATE ? createUser() : resetUser()}
				title={() => (
					<div className='w-72'>
						{actionLocale.confirmation.replace('{{userCount}}', selectedUsers.length)}
					</div>
				)}
			>
				<Button
					disabled={
						isCreate
							? (!selectedUsers.length || !selectedLocationId|| loading)
							: (!selectedUsers.length || loading)
					}
					type='primary'>{actionLocale.modalAction}</Button>
			</Popconfirm>
		</div>
	);

	const ActionDoneFooter = () => (
		<div className='flex gap-2 justify-end'>
			<Button
				onClick={handleDownloadToTsv}
				icon={<DownloadOutlined />} >{locale.downloadTsv}</Button>
			<Button
				onClick={handleClose}
				type='primary'>{locale.done}</Button>
		</div>
	);

	// Lifecycle
	useEffect(() => {
		if (!visible) return;
		getLocation();
		if (!isCreate && usersToReset.length) setSelectedUsers(usersToReset);
	}, [visible]);

	return (
		<Modal
			title={actionLocale.title}
			closable={false}
			visible={visible}
			width={572}
			centered
			footer={actionDone ? ActionDoneFooter() : DefaultFooter()}
		>
			<div>
				<Alert
					className='mb-4'
					message={actionDone ? actionLocale.successInfoText.replace('{{userCount}}', selectedUsers.length) : actionLocale.defaultInfoText}
					type={actionDone ? 'success' : 'info'}
					showIcon />

				{/* Form Section */}
				{
					isCreate ? (
						<div className='flex flex-col gap-4'>
							<div>
								<div className='body-regular-14'>{locale.selectOutletLabel}</div>
								<Select
									showSearch
									loading={fetchLocation}
									disabled={actionDone}
									onSearch={debounceFn}
									value={selectedLocationId}
									onChange={value => setSelectedLocationId(value)}
									className='w-full pt-2'
									filterOption={((_, option) => option)}
									placeholder={locale.selectOutletPlaceholder}>
									{locationList.map(location => (
										<Option
											key={location.id}
											value={location.id}>{location.label}</Option>
									))}
								</Select>
							</div>
							{
								actionDone
									? null
									: (
										<div>
											<div className='body-regular-14'>{locale.usernameInputLabel}</div>
											<div className='flex pt-2 gap-4'>
												<div className='flex-1'>
													<Input
														status={userInputError ? 'error' : null}
														value={userInput}
														onChange={e => {
															userInputError && setUserInputError(false);
															setUserInput(e.target.value.toLowerCase().trim());
														}}
														onPressEnter={addSelectedUser}
														placeholder={locale.usernameInputPlaceholder} />
													<span className={clsx(
														'text-antd-red-5',
														!userInputError && 'invisible',
													)}>{locale.usernameDuplicateAlert}</span>
												</div>
												<Button
													disabled={!userInput.length}
													onClick={addSelectedUser}
													type='primary'>{locale.add}</Button>
											</div>
										</div>
									)
							}
						</div>
					) : null
				}
				{/* End of Form Section */}

				{/* User Section */}
				{
					selectedUsers.length
						? (
							<div>
								<div className='body-regular-14'>{locale.usernamesLabel}</div>
								<List
									className='overflow-y-auto max-h-64'
									itemLayout="horizontal"
									dataSource={selectedUsers}
									renderItem={user => (
										<List.Item className='px-6 pt-2 py-4 flex justify-between items-center w-full gap-4'>
											<Tooltip title={user.username}>
												<Title
													className={clsx('m-0')}
													ellipsis={{rows: 1}}
													level={5}>{user.username}</Title>
											</Tooltip>
											<div className='flex gap-4 flex-shrink-0'>
												{
													invalidUser.includes(user.username)
														? (
															<div className='
												text-antd-red-6 px-2 bg-antd-red-1 border border-antd-red-3 rounded-sm
												flex items-center justify-center gap-2'>
																<CloseCircleOutlined />
																{locale.usernameNotAvailable}
															</div>
														) : null
												}
												{
													actionDone
														? <div className='text-antd-blue-6'>{actionLocale.userPin.replace('{{userPin}}', user.pinNumber)}</div>
														: isCreate
															? (
																<div
																	onClick={() => deleteSelectedUser(user)}
																	className='text-antd-red-5 cursor-pointer'>{locale.delete}</div>
															)
															: null
												}
											</div>
										</List.Item>
									)}
								/>
							</div>
						)
						: null
				}
				{/* End of User Section */}

			</div>
		</Modal>
	);
};

UserActionModal.defaultProps = {
	visible: true,
	type: OUTLET_USER_MODAL_ACTION_TYPE.CREATE,
	onClose: () => null,
	usersToReset: [],
	refetchTableRef: {},
};

UserActionModal.propTypes = {
	visible: PropTypes.bool,
	type: PropTypes.oneOf([OUTLET_USER_MODAL_ACTION_TYPE.CREATE, OUTLET_USER_MODAL_ACTION_TYPE.RESET]),
	onClose: PropTypes.func,
	usersToReset: PropTypes.array,
	refetchTableRef: PropTypes.object,
};

export default UserActionModal;