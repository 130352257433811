import React from 'react';
import PropTypes from 'prop-types';

import {Collapse, Descriptions, Modal, Tag, Timeline, Typography} from 'antd';
import {DownOutlined, UpOutlined} from '@ant-design/icons';

import {get, startCase} from 'lodash';
import moment from 'moment';
import clsx from 'clsx';

import './VersionTrackerModal.less';

import localization from 'localization';
const locale = localization.Admin.VersionTracker.List;

const VersionTrackerModal = ({visible, data, versionHistory, close}) => {

	const splitChangelogLine = changelogStr => {
		// Replace "\t" with a single space character
		const spacedStr = changelogStr.replace(/\t/g, '  ');

		// Split the string by newline characters
		const output = spacedStr.split(/\r?\n/);

		// Filter out any empty strings
		return output.filter(item => item.trim() !== '');
	};

	const getDescriptionItem = () => {
		return [
			{
				label: locale.location,
				key: 'locationGroup',
				span: 2,
				value: get(data, ['label'], '-'),
			},
			{
				label: locale.locationGroup,
				key: 'locationGroup',
				span: 2,
				value: get(data, ['locationGroup', 'name'], '-'),
			},
			{
				label: locale.history,
				key: 'locationGroup',
				span: 4,
				value: (
					<div className='VersionTrackerModal overflow-auto flex flex-col max-h-96 w-full'>
						<Timeline
							className='overflow-auto mt-1'
						>
							{
								versionHistory.map((ver, index) => {
									const isCurrent = get(data, ['latestVersionUpdate', 'posVersionId']) == ver.posVersionId;
									return (
										<Timeline.Item
											key={index}
											color={isCurrent ? 'green' : 'gray'}>

											<div className={clsx('font-medium text-sm opacity-80 flex gap-1', index == 0 && 'mt-2')}>
												<span>{`${ver.posVersionId} - ${startCase(ver?.PosVersion?.channel)}`}</span>
												{isCurrent && (
													<Tag
														color='green'>
														Current
													</Tag>
												)}
											</div>

											<div className='flex flex-col'>
												<Typography.Text type='secondary'>
													{
														locale.releaseInfo
															.replace('{{user}}', (ver?.PosVersionSchedule?.creator?.username || '-'))
															.replace('{{timestamp}}', ver?.PosVersionSchedule?.releaseDate
																? moment(ver?.PosVersionSchedule?.releaseDate).format('DD MMM YYYY - HH:mm')
																: '-',
															)
													}
												</Typography.Text>
												<Typography.Text type='secondary'>
													{
														locale.updateInfo
															.replace('{{timestamp}}', ver?.updatedAt
																? moment(ver?.updatedAt).format('DD MMM YYYY - HH:mm')
																: '-',
															)
													}
												</Typography.Text>
											</div>

											<Collapse
												expandIcon={
													({isActive}) => isActive
														? <UpOutlined className='text-antd-blue-6' />
														: <DownOutlined className='text-antd-blue-6' />
												}
												ghost>
												<Collapse.Panel
													header={<span className='text-antd-blue-6'>{locale.changelog}</span>}
													key="1">
													<div>
														{
															splitChangelogLine(ver?.PosVersion?.changelog).map((str, index) => (
																<div
																	style={{whiteSpace: 'pre-wrap'}}
																	dangerouslySetInnerHTML={{__html: str}}
																	key={index}></div>
															),
															)
														}
													</div>
													{/* <p>{ver?.PosVersion?.changelog}</p> */}
												</Collapse.Panel>
											</Collapse>

										</Timeline.Item>
									);
								})
							}
						</Timeline>
					</div>
				),
			},
		];
	};

	return (
		<Modal
			width={868}
			// height={400}
			centered
			title={locale.modalTitle.replace('{{location}}', data?.label)}
			open={visible}
			onCancel={close}
			onOk={close}
			cancelButtonProps={{style: {display: 'none'}}}
		>
			<Descriptions
				bordered
			>
				{
					getDescriptionItem().map(descItem => (
						<Descriptions.Item
							labelStyle={{
								width: '80px',
							}}
							key={descItem.key}
							label={descItem.label}
							span={descItem.span}
						>
							{descItem.value}
						</Descriptions.Item>
					))
				}
			</Descriptions>
		</Modal>
	);
};

VersionTrackerModal.defaultProps = {
	visible: false,
	close: () => null,
	data: {},
	versionHistory: [],
};

VersionTrackerModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
	versionHistory: PropTypes.array,
};

export default VersionTrackerModal;