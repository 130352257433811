import React, {useRef, useState} from 'react';

import {Button, Tabs, notification} from 'antd';
import {SyncOutlined} from '@ant-design/icons';

import LocalPageHeader from 'components/LocalPageHeader';
import PromoCheck from './components/PromoCheck';
import PromoOff from './components/PromoOff';

import {handleErrorFetch} from 'utils/utils';
import {triggerManualCheckPromo} from 'utils/request/watchtower';

// Local styles
import './watchTower.less';

const WatchTowerPromo = () => {
	const promoCheckTableRef = useRef();

	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState('promoCheck');
	const [metricValue, setMetricValue] = useState({
		lastCheckBy: null,
		lastCheckJobId: null,
		lastCheckTime: null,
		lastTotalPromoOff: null,
	});

	const tabs = [
		{
			key: 'promoCheck',
			label: 'Promo Check',
			element: <PromoCheck
				setMetricValue={setMetricValue}
				isActive={activeTab === 'promoCheck'}
				promoCheckTableRef={promoCheckTableRef}
			/>,
		},
		{
			key: 'promoOff',
			label: 'Promo Menu Off',
			element: <PromoOff
				metricValue={metricValue}
				isActive={activeTab === 'promoOff'}
			/>,
		},
	];

	const handleManualCheck = async () => {
		try {
			setLoading(true);
			const response = await triggerManualCheckPromo();
			if (response.success) {
				promoCheckTableRef?.current?.reload?.();
				notification.open({
					type: 'info',
					message: 'Starting Manual Check',
					description: 'System will inform if the check succeed or failed',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="WatchtowerPromo bg-white h-full overflow-auto">
			<LocalPageHeader
				headerTitle='Promo Watchtower'
				extra={[
					<Button
						loading={loading}
						icon={<SyncOutlined />}
						type='primary'
						key='action'
						onClick={() => handleManualCheck()}
					>
						Manual Promo Check
					</Button>,
				]} />
			<Tabs onChange={setActiveTab}>
				{tabs.map(each => (
					<Tabs.TabPane
						key={each.key}
						tab={each.label}>
						{each.element}
					</Tabs.TabPane>
				))}
			</Tabs>
		</div>
	);
};

export default WatchTowerPromo;