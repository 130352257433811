import React, {useState} from 'react';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';
import ImportCSVOverlay from 'components/Overlay/ImportCSVOverlay';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';

import {OUTLET_TYPE} from 'utils/constants';
import {Button, Upload} from 'antd';
import {DownloadOutlined, ExportOutlined, ImportOutlined} from '@ant-design/icons';

import localization from 'localization';
const locale = localization.Outlet.Company.Header;

const OutletCompanyHeader = ({handleExport, refetchTableRef}) => {

	const [uploadedFile, setUploadedFile] = useState(null);
	const [importCSV, setImportCSV] = useState({visible: false, type: 'add'});
	const [getCSV, setGetCSV] = useState({visible: false, type: 'export'});

	const openGetCSVOverlay = type => {
		setGetCSV({visible: true, type});
	};

	const closeGetCSVOverlay = () => {
		setGetCSV({...getCSV, visible: false});
	};

	const openImportCSVOverlay = type => {
		setImportCSV({visible: true, type});
	};

	const closeImportCSVOverlay = () => {
		setUploadedFile(null);
		setImportCSV({...importCSV, visible: false});
	};

	const handleFileUpload = async (e, type = 'add') => {
		if (!e.fileList.length) return;

		const uploadedFileTemp = e.fileList[0].originFileObj;

		setUploadedFile(uploadedFileTemp);
		openImportCSVOverlay(type);
	};

	return (
		<div>
			<ImportCSVOverlay
				masterlistType={OUTLET_TYPE.COMPANY}
				refetchTable={() => refetchTableRef?.current?.({reset: true, highlightNewData: true})}
				handleFileUpload={handleFileUpload}
				uploadedFile={uploadedFile}
				visible={importCSV.visible}
				importType={importCSV.type}
				close={closeImportCSVOverlay}
				fileType='csv'
			/>
			<ExportCSVOverlay
				fileFormat='csv'
				masterlistType={OUTLET_TYPE.COMPANY}
				handleExport={handleExport}
				visible={getCSV.visible}
				close={closeGetCSVOverlay}
				type={getCSV.type}
			/>
			<LocalPageHeader
				headerTitle={locale.title}
				customBreadcrumbLabel={locale.breadcrumb}
				extra={(
					<>
						<Button
							onClick={() => openGetCSVOverlay('download-template')}
							icon={<DownloadOutlined />}
						>{locale.downloadTemplate}</Button>
						<Button
							onClick={() => openGetCSVOverlay('export')}
							icon={<ExportOutlined />}
						>{locale.export}</Button>
						<Upload
							accept=".csv"
							className='inline-block'
							showUploadList={false}
							beforeUpload={() => false}
							maxCount={1}
							onChange={e => handleFileUpload(e, 'add')}
						>
							<Button
								type="primary"
								icon={<ImportOutlined />}>
								{locale.import}
							</Button>
						</Upload>
					</>
				)}
			/>
		</div>
	);
};

OutletCompanyHeader.defaultProps = {
	handleExport: () => null,
	refetchTableRef: {},
};

OutletCompanyHeader.propTypes = {
	handleExport: PropTypes.func,
	refetchTableRef: PropTypes.object,
};

export default OutletCompanyHeader;