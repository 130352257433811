import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import PromoRequestHeader from './PromoRequestHeader';
import PromoRequestBody from './PromoRequestBody';

const PromoRequest = ({promoCategory}) => {
	const containerRef = useRef(null);

	return (
		<div
			ref={containerRef}
			className="bg-white h-full overflow-auto flex flex-col">
			<PromoRequestHeader promoCategory={promoCategory} />
			<PromoRequestBody
				promoCategory={promoCategory}
				containerRef={containerRef} />
		</div>
	);
};

PromoRequest.defaultProps = {
	promoCategory: null,
};

PromoRequest.propTypes = {
	promoCategory: PropTypes.string,
};

export default PromoRequest;
