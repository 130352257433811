import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import ProTable from '@ant-design/pro-table';

import {getPlatformImage, handleErrorFetch, localeCurrencyFormat, momentDateFormat} from 'utils/utils';
import {getPromoCheckResult} from 'utils/request/watchtower';
import {Image} from 'antd';

const Table = forwardRef(({metricFormRef, platformList}, ref) => {
	const columns = [
		{
			key: 'wtPromoJobId',
			dataIndex: 'wtPromoJobId',
			title: 'Promo Check ID',
		},
		{
			width: 217,
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: 'Time Check',
			sorter: true,
			defaultSortOrder: 'descend',
			render: createdAt => momentDateFormat({dateString: createdAt, format: 'DD MMM YYYY - HH:mm'}),
		},
		{
			key: 'promoId',
			dataIndex: ['ExternalCampaignRequest', 'CampaignRequest', 'id'],
			title: 'Promo ID',
		},
		{
			key: 'menuLabel',
			dataIndex: ['ExternalCampaignRequest', 'MasterMenu', 'menuLabel'],
			title: 'Menu Name',
		},
		{
			key: 'salesChannel',
			dataIndex: ['ExternalCampaignRequest', 'Merchant', 'Platform', 'label'],
			title: 'Sales Channel',
			filters: platformList,
			render: salesChannel => {
				const platformImage = getPlatformImage(salesChannel);
				return (
					<div className="flex gap-2">
						<Image
							width={22}
							height={22}
							src={platformImage?.default}
							preview={false}
						/>
						{salesChannel}
					</div>
				);
			},
		},
		{
			key: 'outlet',
			dataIndex: ['ExternalCampaignRequest', 'Merchant', 'Location', 'label'],
			title: 'Outlet',
		},
		{
			key: 'brand',
			dataIndex: ['ExternalCampaignRequest', 'Merchant', 'Brand', 'label'],
			title: 'Brand',
		},
		{
			key: 'promoName',
			dataIndex: ['ExternalCampaignRequest', 'CampaignRequest', 'label'],
			title: 'Promo Name',
		},
		{
			key: 'startDate',
			dataIndex: ['ExternalCampaignRequest', 'campaignDetails', 'startDate'],
			title: 'Start Date',
			render: startDate => momentDateFormat({dateString: startDate, format: 'DD MMM YYYY - HH:mm'}),
		},
		{
			key: 'endDate',
			dataIndex: ['ExternalCampaignRequest', 'campaignDetails', 'endDate'],
			title: 'End Date',
			render: endDate => momentDateFormat({dateString: endDate, format: 'DD MMM YYYY - HH:mm'}),
		},
		{
			key: 'promoPrice',
			title: 'Promo Price',
			fixed: 'right',
			align: 'center',
			render: (_, record) => {
				// Get promo menu id then find it in campaignDetails menus
				const campaignMenus = record?.ExternalCampaignRequest?.campaignDetails?.menus;
				const promoMenuId = record?.ExternalCampaignRequest?.MasterMenu?.id;

				const discountedPrice = campaignMenus?.find?.(menu => menu.menuId == promoMenuId)?.discountPrice || null;
				if (discountedPrice === null) return '-';
				return localeCurrencyFormat({value: discountedPrice});
			},
		},
	];

	const sortDirectionConverter = direction => {
		switch (direction) {
		case 'ascend':
			return 'asc';
		case 'descend':
			return 'desc';
		default:
			return 'desc';
		}
	};

	const fetchTableData = async (params, sorter, filters) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */
			const metricPayload = metricFormRef.current?.getMetricFilter?.();
			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				sortBy: 'createdAt',
				sortOrder: sortDirectionConverter(sorter.createdAt),
				wtPromoJobId: metricPayload?.wtPromoJobId,
				promoId: metricPayload?.promoId,
				menuLabel: metricPayload?.menuLabel,
				locationId: metricPayload?.locationId,
				brandId: metricPayload?.brandId,
				promoName: metricPayload?.promoName,
			};
			if (metricPayload.dateRange?.length) {
				payload.createdAtFrom = metricPayload.dateRange[0].format('YYYY-MM-DD');
				payload.createdAtTo = metricPayload.dateRange[1].format('YYYY-MM-DD');
			}

			if (filters?.salesChannel?.length) {
				payload.platformId = filters?.salesChannel.join(',');
			}

			Object.keys(payload).forEach(k => !payload[k] && delete payload[k]);

			const response = await getPromoCheckResult(payload);
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<ProTable
			actionRef={ref}
			className="mt-2"
			rowKey='id'
			columns={columns}
			search={false}
			request={fetchTableData}
			toolBarRender={false}
			scroll={{x: 'max-content'}}
			layout="vertical"
			pagination={{
				defaultPageSize: 25,
				showSizeChanger: true,
				showQuickJumper: true,
				pageSizeOptions: ['10', '25', '50', '75', '100'],
			}}
		/>
	);
});

Table.defaultProps = {
	metricFormRef: {},
	platformList: [],
};

Table.propTypes = {
	metricFormRef: PropTypes.object,
	platformList: PropTypes.array,
};

export default Table;