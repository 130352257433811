import React from 'react';
import PropTypes from 'prop-types';

import {Checkbox, Divider, Image, Radio, Typography} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {moneyFormat} from 'utils/utils';
import {cloneDeep, get, set} from 'lodash';
import localization from 'localization';

// eslint-disable-next-line react/prop-types
const PaddedContainer = ({children}) => (
	<div className='p-6'>
		{children}
	</div>
);

const ContainerDivider = () => (
	<div className='h-1 bg-antd-netural-2' />
);

const BodyAction = ({categoryData, cartItemData, setCartItemData}) => {
	const locale = localization.MenuTemplate.MenuTemplateForm.OrderMenuDrawer;

	const menuData = cartItemData?.dataOrigin;

	const isOptionSelected = (selectedOptions, option) => {
		return selectedOptions.some(selectedOption => selectedOption.menuId == option.menuId);
	};

	const getOptionGroupIndex = optionGroupId => {
		return cartItemData.menuItem.optionGroups.findIndex(item => item.optionGroupId == optionGroupId);
	};

	const handleClickCheckbox = (option, optionGroupId) => {
		const selectedOptions = getCartOptions(optionGroupId);

		const alreadySelected = isOptionSelected(selectedOptions, option);

		const cartItemDataTemp = cloneDeep(cartItemData);
		const optionGroupIndex = getOptionGroupIndex(optionGroupId);

		if (alreadySelected) {
			const targetOptionGroup = get(cartItemDataTemp, ['menuItem', 'optionGroups', optionGroupIndex, 'options'], []);
			const filteredTargetOptionGroup = targetOptionGroup.filter(childMenu => childMenu.menuId != option.menuId);

			set(cartItemDataTemp, ['menuItem', 'optionGroups', optionGroupIndex, 'options'], filteredTargetOptionGroup);
			setCartItemData(cartItemDataTemp);
		} else {
			selectedOptions.push({
				menuId: JSON.stringify(option.menuId),
				menuLabel: option.menuLabel,
				menuPrice: option.menuPrice || 0,
				quantity: 1,
			});

			if (optionGroupIndex !== -1) {
				set(
					cartItemDataTemp,
					['menuItem', 'optionGroups', optionGroupIndex, 'options'],
					selectedOptions.sort((a, b) => a.menuLabel.localeCompare(b.menuLabel)),
				);
				setCartItemData(cartItemDataTemp);
			}
		}
	};

	const handleClickRadioButton = (option, optionGroupId) => {
		const selectedOptions = getCartOptions(optionGroupId);

		const alreadySelected = isOptionSelected(selectedOptions, option);

		const cartItemDataTemp = cloneDeep(cartItemData);
		const optionGroupIndex = getOptionGroupIndex(optionGroupId);

		if (alreadySelected) {
		} else {
			if (optionGroupIndex !== -1) {
				set(cartItemDataTemp, ['menuItem', 'optionGroups', optionGroupIndex, 'options'], [{
					menuId: JSON.stringify(option.menuId),
					menuLabel: option.menuLabel,
					menuPrice: option.menuPrice || 0,
					quantity: 1,
				}]);
				setCartItemData(cartItemDataTemp);
			}
		}
	};

	const getCartOptions = optionGroupId => {
		const cartOptionGroup = cartItemData.menuItem.optionGroups.find(option => {
			return option.optionGroupId == optionGroupId;
		});

		return get(cartOptionGroup, ['options'], []);
	};

	const CheckboxSelection = (complexOptions, optionGroupId) => {
		const selectedOptions = getCartOptions(optionGroupId);

		return (
			<div className='flex flex-col gap-2'>
				{
					complexOptions.map(option => (
						<Checkbox
							onClick={() => handleClickCheckbox(option, optionGroupId)}
							checked={isOptionSelected(selectedOptions, option)}
							className='m-0'
							key={option.menuId}
							value={option.menuId}>
							<div className='flex gap-1'>
								<div>{option.menuLabel}</div>
								{(option.menuPrice && option.menuPrice >= 0) ? <div className='font-bold'>{`(+${moneyFormat({value: option.menuPrice})})`}</div> : null}
							</div>
						</Checkbox>
					))
				}
			</div>
		);
	};

	const RadioSelection = (complexOptions, optionGroupId) => {
		const selectedOptions = getCartOptions(optionGroupId);

		return (
			<div
				className='flex flex-col gap-2'
			>
				{
					complexOptions.map(option => (
						<Radio
							onClick={() => handleClickRadioButton(option, optionGroupId)}
							checked={isOptionSelected(selectedOptions, option)}
							key={option.menuId}
							value={option.menuId}>
							<div className='flex gap-1'>
								<div>{option.menuLabel}</div>
								{(option.menuPrice && option.menuPrice >= 0) ? <div className='font-bold'>{`(+${moneyFormat({value: option.menuPrice})})`}</div> : null}
							</div>
						</Radio>
					))
				}
			</div>
		);
	};

	return (
		<div className='flex-1 overflow-auto'>
			{/* Menu */}
			<PaddedContainer>
				<div className='flex flex-col gap-4'>

					{/* Top Section */}
					<div className='flex items-center gap-6'>
						<Image
							width={128}
							src={menuData?.imageUrl}
						/>
						<div>
							{categoryData?.categoryLabel && <Typography.Text type='secondary'>{categoryData?.categoryLabel}</Typography.Text>}
							<div className='font-medium'>{menuData?.menuLabel}</div>
							<div className='pt-1'>{moneyFormat({value: menuData?.menuPrice})}</div>
						</div>
					</div>

					{/* Bottom Section */}
					<div className='flex flex-col gap-2'>
						<div>{locale.description}</div>
						<TextArea
							readOnly
							disabled
							value={menuData?.description}
						/>
					</div>

				</div>
			</PaddedContainer>
			<Divider className='m-0' />
			{/* OptionGroup */}
			{
				menuData?.optionGroups?.length
					? menuData?.optionGroups.map(optionGroup => {
						const isMinMax1 = optionGroup?.minSelection == 1 && optionGroup?.maxSelection == 1;
						return (
							<React.Fragment key={optionGroup.optionGroupId}>
								<PaddedContainer>

									{/* Option Group Title */}
									<div className='flex gap-1 pb-2'>
										<div className='font-bold'>
											{optionGroup.optionGroupLabel}
										</div>
										{(optionGroup.minSelection == 0 && optionGroup.maxSelection == optionGroup.menus.length) &&(
											<Typography.Text type='secondary'>
												{locale.everythingSelectable}
											</Typography.Text>
										)}
										{optionGroup.minSelection != 0 && (
											<Typography.Text type='secondary'>
												{locale.minSelectionRequired.replace('{{minSelection}}', optionGroup.minSelection)}
											</Typography.Text>
										)}
									</div>

									{
										isMinMax1
											? RadioSelection(optionGroup.menus, optionGroup.optionGroupId)
											: CheckboxSelection(optionGroup.menus, optionGroup.optionGroupId)
									}

								</PaddedContainer>
								<ContainerDivider />
							</React.Fragment>
						);
					})
					: null

			}
			{/* Notes */}
			<PaddedContainer>
				<div className='flex flex-col gap-2'>
					<div>{locale.notes}</div>
					<TextArea
						value={cartItemData?.menuItem?.notes}
						onChange={e => {
							setCartItemData(prevVal => ({
								...prevVal,
								menuItem: {
									...prevVal.menuItem,
									notes: e.target.value,
								},
							}));
						}}
						maxLength={150}
						showCount
						placeholder={locale.notesPlaceholder}
					/>
				</div>
			</PaddedContainer>


		</div>
	);
};

BodyAction.defaultProps = {
	cartItemData: {},
	categoryData: {},
	setCartItemData: () => null,
};

BodyAction.propTypes = {
	cartItemData: PropTypes.object,
	categoryData: PropTypes.object,
	setCartItemData: PropTypes.func,
};

export default BodyAction;