import React, {useState} from 'react';

import {Tabs} from 'antd';

import LocalPageHeader from 'components/LocalPageHeader';
import CheckerBody from './CheckerBody';

const TABS = [
	{key: 'gojek', label: 'Gojek'},
	{key: 'grab', label: 'Grab'},
	{key: 'shopee', label: 'Shopee'},
];

const Checker = () => {
	const [activeTab, setActiveTab] = useState(TABS[0].key);

	return (
		<div className='bg-white h-full overflow-auto'>
			<LocalPageHeader
				headerTitle='Checker'
			/>
			<div className='px-6'>
				<Tabs
					activeKey={activeTab}
					onChange={setActiveTab}>
					{TABS.map(platform => (
						<Tabs.TabPane
							tab={platform.label}
							key={platform.key} />
					))}
				</Tabs>
			</div>
			<CheckerBody platform={activeTab} />
		</div>
	);
};

export default Checker;