import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';

import './MasterlistTable.less';

import DetailModal from '../DetailModal';

import enUS from 'antd/lib/locale/en_US';
import ProTable from '@ant-design/pro-table';
import {ConfigProvider} from 'antd';

import cloneDeep from 'lodash/cloneDeep';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSort, faSortDown, faSortUp} from '@fortawesome/pro-solid-svg-icons';

import {deleteMaterial, getMasterlistData} from 'utils/request/masterlist';
import {handleErrorFetch} from 'utils/utils';

import usePrevious from 'hooks/usePrevious';
import {STORAGE_KEY} from 'utils/constants';
import masterlistTableColumnConfig from './masterlistTableColumnConfig';

const defaultSearch = {
	menuLabel: null,
	menuCode: null,
	menuId: null,
	tags: null,
	createdBy: null,
	updatedBy: null,
	isActive: null,
};

const defaultQueryParams = {
	search: defaultSearch,
	page: 0,
	sortBy: 'updatedAt',
	sortOrder: 'desc',
	limit: 25,
};

let highlightNewDataTimeout = null;

const MasterlistTable = ({
	setExportQuery, scrollToTop, refetchTableRef, masterlistType, setLatestUpdateInfo,
}) => {
	const [masterlistData, setMasterlistData] = useState([]);
	const [loading, setLoading] = useState(false);
	const previousMasterlistData = usePrevious(masterlistData);
	const [highlightNewData, setHighlightNewData] = useState(false);

	const [modalDetailVisible, setModalDetailVisible] = useState(false);
	const [modalDetailContent, setModalDetailContent] = useState({});

	const tableRef = useRef(null);
	const resetRefFlag = useRef(false);

	const [queryParams, setQueryParams] = useState(defaultQueryParams);

	const {search, page, sortBy, sortOrder, limit} = queryParams;

	// Component Local Function
	const deleteItem = async record => {
		try {
			setLoading(true);
			const response = await deleteMaterial(record.id);
			if (response.success) fetchTableData();
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchTableData = async (config = {
		reset: false,
		highlightNewData: false,
		captureLatestUpdateTime: false,
	}) => {
		try {
			const {reset, highlightNewData, captureLatestUpdateTime} = config;
			setLoading(true);
			let masterlistDataResponse;
			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit});
				masterlistDataResponse = await getMasterlistData({...defaultQueryParams, limit}, masterlistType);
			} else {
				masterlistDataResponse = await getMasterlistData(queryParams, masterlistType);
			}
			setMasterlistData(masterlistDataResponse.data);
			if (captureLatestUpdateTime && masterlistDataResponse.data?.rows?.length) {
				const lastEditerData = masterlistDataResponse.data.rows[0];
				setLatestUpdateInfo({
					updatedAt: lastEditerData?.updatedAt,
					updater: lastEditerData?.updater?.username || lastEditerData?.creator?.username,
				});
			}
			highlightNewData && setHighlightNewData(true);
			scrollToTop();
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			resetRefFlag.current = false;
			setLoading(false);
		}
	};

	const handleChange = pagers => {
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams({...queryParams, ...variables});
	};

	const handleSort = dataIndex => {
		const queryParamsTemp = cloneDeep(queryParams);

		let sortOrderTemp = '';
		if (queryParamsTemp.sortBy === dataIndex) {
			switch (queryParams.sortOrder) {
			case null:
				sortOrderTemp = 'asc';
				break;
			case 'asc':
				sortOrderTemp = 'desc';
				break;
			case 'desc':
				if (dataIndex === 'updatedAt') {
					sortOrderTemp = 'asc';
				} else {
					sortOrderTemp = null;
				}
				break;
			default:
				sortOrderTemp = null;
				break;
			}
		} else {
			sortOrderTemp = 'asc';
			queryParamsTemp.page = 0;
		}

		queryParamsTemp.sortOrder = sortOrderTemp;
		queryParamsTemp.sortBy = sortOrderTemp ? dataIndex : 'updatedAt';

		setQueryParams(queryParamsTemp);
	};

	const sortTableHeader = (text, dataIndex) => (
		<div className='flex items-center justify-between'>
			<div>{text}</div>
			<FontAwesomeIcon
				onClick={() => handleSort(dataIndex)}
				className='cursor-pointer text-custom-grey-light hover:text-aqua-dark transition-colors duration-300'
				icon={
					dataIndex === queryParams.sortBy
						? queryParams.sortOrder === 'asc' ? faSortUp : faSortDown
						: faSort
				} />
		</div>
	);

	const openDetailModal = index => {
		setModalDetailContent(masterlistData.rows[index]);
		setModalDetailVisible(true);
	};

	const closeDetailModal = () => {
		setModalDetailVisible(false);
		setModalDetailContent({});
	};

	const goToEditPage = record => {
		sessionStorage.setItem(STORAGE_KEY.MASTERLIST_ITEM_DETAIL, JSON.stringify(record));
		window.open(`/masterlist/${masterlistType}/edit`, '_blank');
	};

	// End of Component Local Function

	// Component Lifecycle Section

	useEffect(async() => {
		// Initial action
		refetchTableRef.current = fetchTableData;
		await fetchTableData({reset: true, captureLatestUpdateTime: true});
		tableRef?.current?.reset?.();
	}, [window.location.pathname]);

	useEffect(() => {
		// To record export query
		setExportQuery({search: queryParams.search, sortBy, sortOrder});
		refetchTableRef.current = fetchTableData;
	}, [search, sortBy, sortOrder, window.location.pathname]);

	useEffect(() => {
		// To handle query changes
		if (resetRefFlag.current) return;
		(async () => await fetchTableData())();
	}, [search, page, sortBy, sortOrder, limit]);

	useEffect(() => {
		// To highlight new added data
		if (highlightNewData) {
			highlightNewDataTimeout = setTimeout(() => {
				setHighlightNewData(false);
			}, 2000);
		}
		return () => clearTimeout(highlightNewDataTimeout);
	}, [highlightNewData]);

	// End of Component Lifecycle Section

	return (
		<>
			<div className="pb-8 MasterlistTable">
				<ConfigProvider locale={enUS}>
					<ProTable
						rowKey='id'
						actionRef={tableRef}
						id='masterlist-table'
						loading={loading}
						pagination={{
							defaultPageSize: limit,
							current: page + 1,
							total: masterlistData?.count,
							showSizeChanger: true,
							pageSizeOptions: ['5', '10', '20', '50', '100'],
							showQuickJumper: true,
						}}
						onChange={handleChange}
						onSubmit={params => {
							Object.keys(params).forEach(k => !params[k] && delete params[k]);

							// Convert filter select value to boolean
							['isActive', 'isFreeItem'].forEach(key => {
								if (params[key]) {
									switch (params[key]) {
									case 'active':
										params[key] = true;
										break;
									case 'inactive':
										params[key] = false;
										break;
									case 'all':
									default:
										params[key] = null;
										break;
									}
								}
							});

							if (Array.isArray(params.tags)) {
								params.tags = params.tags.join(',').toLowerCase();
							}
							const queryParamsTemp = cloneDeep(defaultQueryParams);
							queryParamsTemp.search = {...defaultSearch, ...params};
							queryParamsTemp.limit = queryParams.limit;
							setQueryParams(queryParamsTemp);
						}}
						onReset={() => fetchTableData({reset: true})}
						options={false}
						search={{
							layout: 'vertical',
						}}
						dataSource={masterlistData?.rows}
						columns={masterlistTableColumnConfig({
							sortTableHeader,
							masterlistType,
							goToEditPage,
							openDetailModal,
							deleteItem,
						})}
						scroll={{x: 'max-content'}}
						className='transition-colors duration-1000'
						rowClassName={(_, index) => {
							const newDataCount = masterlistData?.count - previousMasterlistData?.count;
							const rowTableOrder = (index + 1) + (page * limit);
							if (rowTableOrder <= newDataCount && highlightNewData) return 'row-highlight transition-colors duration-1000';
						}}
					/>
				</ConfigProvider>
			</div>
			<DetailModal
				masterlistType={masterlistType}
				visible={modalDetailVisible}
				content={modalDetailContent}
				close={closeDetailModal}
			/>
		</>
	);
};

MasterlistTable.defaultProps = {
	setExportQuery: () => null,
	scrollToTop: () => null,
	refetchTableRef: {},
	masterlistType: 'menu',
	setLatestUpdateInfo: () => null,
};

MasterlistTable.propTypes = {
	setExportQuery: PropTypes.func,
	scrollToTop: PropTypes.func,
	refetchTableRef: PropTypes.object,
	masterlistType: PropTypes.string,
	setLatestUpdateInfo: PropTypes.func,
};

export default MasterlistTable;
