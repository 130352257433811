import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import enUS from 'antd/lib/locale/en_US';
import ProTable from '@ant-design/pro-table';
import {Button, ConfigProvider, Popconfirm} from 'antd';
import {InfoCircleFilled} from '@ant-design/icons';

import {cancelPromo, cancelPromoSyncRequest, getPromoStatusRequest, resyncPromo, toggleInternalPromo} from 'utils/request/promo';
import {getBrandList, getPlatformList} from 'utils/request/global';
import {getOutletData} from 'utils/request/outlet';
import {authChecker, handleErrorFetch} from 'utils/utils';
import {OUTLET_TYPE, PROMO_CATEGORY, USER_ROLES} from 'utils/constants';

import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';

import promoTableColumnConfig from './promoTableColumnConfig';

const PROMO_STATUS = {
	ACTIVE: 'active',
	SCHEDULED: 'scheduled',
	EXPIRED: 'expired',
	INACTIVE: 'inactive',
	PENDING: 'pending',
	SUCCESS: 'success',
	ERROR: 'error',
	RESYNC: 'resync',
	FAILED: 'failed',
	CANCELLED: 'cancelled',
};


const PromoStatusTable = ({refetchTableRef, promoCategory}) => {
	const defaultSearch = {
		id: null,
		templateId: null,
		status: null,
	};

	const defaultQueryParams = {
		search: defaultSearch,
		page: 0,
		sortBy: 'updatedAt',
		sortOrder: 'desc',
		limit: 10,
	};

	const isInternal = promoCategory === PROMO_CATEGORY.INTERNAL;
	const EDITOR_ROLES = [...(isInternal ? [USER_ROLES.RESTOADMIN] : []), USER_ROLES.SYNC_PROMO.ADMIN, USER_ROLES.SYNC_PROMO.EDITOR];

	const auth = useSelector(state => state.user);

	const [loading, setLoading] = useState(false);
	const [syncStatusData, setSyncStatusData] = useState([]);

	const [location, setLocation] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const {search, page, sortOrder, limit} = queryParams;

	const [platformList, setPlatformList] = useState([]);
	const [brandList, setBrandList] = useState([]);

	const [selected, setSelected] = useState([]);
	const [terminatable, setTerminatable] = useState([]);
	const [resyncAble, setResyncAble] = useState([]);
	const [cancellable, setCancellable] = useState([]);

	const [activable, setActiveable] = useState([]);
	const [deactiveable, setDeactiveable] = useState([]);

	const resetRefFlag = useRef(false);
	const tableRef = useRef(null);

	const getLocation = async locationLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: locationLabel},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocation(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};
	const debounceFn = useCallback(debounce(getLocation, 1000), []);

	const handleSelectAll = (selected, _, changeRows) => {
		if (selected) {
			const tempSelected = [];
			const tempTerminatable = [];
			const tempResyncable = [];
			const tempCancellable = [];

			const tempDeactiveable = [];
			const tempActivable = [];

			changeRows.forEach(item => {
				if (isInternal) {
					if (item.status === PROMO_STATUS.INACTIVE) tempActivable.push(item.id);
					if ([PROMO_STATUS.PENDING, PROMO_STATUS.ACTIVE].some(el => el === item.status)) tempDeactiveable.push(item.id);
				} else {
					if (item.status === PROMO_STATUS.ERROR) tempResyncable.push(item.id);
					if ([PROMO_STATUS.ERROR, PROMO_STATUS.SCHEDULED].some(el => el === item.status)) tempCancellable.push(item.id);
					if ([PROMO_STATUS.ACTIVE, PROMO_STATUS.SUCCESS].some(el => el === item.status)) tempTerminatable.push(item.id);
				}
				tempSelected.push(item.id);
			});

			setTerminatable(state => state.concat(tempTerminatable));
			setResyncAble(state => state.concat(tempResyncable));
			setCancellable(state => state.concat(tempCancellable));
			setSelected(state => state.concat(tempSelected));
			setDeactiveable(state => state.concat(tempDeactiveable));
			setActiveable(state => state.concat(tempActivable));
		} else {
			const idOfChangeRows = changeRows.map(item => item.id);
			setSelected(state => state.filter(item => !idOfChangeRows.includes(item)));
			setTerminatable(state => state.filter(item => !idOfChangeRows.includes(item)));
			setResyncAble(state => state.filter(item => !idOfChangeRows.includes(item)));
			setCancellable(state => state.filter(item => !idOfChangeRows.includes(item)));
			setDeactiveable(state => state.filter(item => !idOfChangeRows.includes(item)));
			setActiveable(state => state.filter(item => !idOfChangeRows.includes(item)));
		}
	};

	const handleOnSelect = (record, selected) => {
		if (selected) { // Check
			if (isInternal) {
				if (record.status === PROMO_STATUS.INACTIVE) setActiveable(state => state.concat(record.id));
				if ([PROMO_STATUS.PENDING, PROMO_STATUS.ACTIVE].some(el => el === record.status)) setDeactiveable(state => state.concat(record.id));
			} else {
				if (record.status === PROMO_STATUS.ERROR) setResyncAble(state => state.concat(record.id));
				if ([PROMO_STATUS.ERROR, PROMO_STATUS.SCHEDULED].some(el => el === record.status)) setCancellable(state => state.concat(record.id));
				if ([PROMO_STATUS.ACTIVE, PROMO_STATUS.SUCCESS].some(el => el === record.status)) setTerminatable(state => state.concat(record.id));
			}
			setSelected(state => state.concat(record.id));
		} else { // Uncheck
			setSelected(state => state.filter(item => item !== record.id));
			setTerminatable(state => state.filter(item => item !== record.id));
			setCancellable(state => state.filter(item => item !== record.id));
			setResyncAble(state => state.filter(item => item !== record.id));
			setDeactiveable(state => state.filter(item => item !== record.id));
			setActiveable(state => state.filter(item => item !== record.id));
		}
	};

	const onSelectAllData = async () => {
		try {
			setLoading(true);
			const response = await getPromoStatusRequest({...queryParams, limit: syncStatusData.count, page: 0, idOnly: true}, promoCategory);
			if (response.rows.length) {
				// const filteredRows = response.rows.filter(item => [PROMO_STATUS.ERROR, PROMO_STATUS.ACTIVE, PROMO_STATUS.SUCCESS, PROMO_STATUS.SCHEDULED].some(el => el === item.status));
				const filteredRows = response.rows.filter(item => [
					...(isInternal
						? [PROMO_STATUS.PENDING, PROMO_STATUS.ACTIVE, PROMO_STATUS.INACTIVE]
						: [PROMO_STATUS.ACTIVE, PROMO_STATUS.ERROR, PROMO_STATUS.SUCCESS, PROMO_STATUS.SCHEDULED]),
				].some(el => el === item.status));

				const tempSelected = [];
				const tempTerminatable = [];
				const tempResyncable = [];
				const tempCancellable = [];

				const tempDeactiveable = [];
				const tempActivable = [];

				filteredRows.forEach(item => {
					if (isInternal) {
						if (item.status === PROMO_STATUS.INACTIVE) tempActivable.push(item.id);
						if ([PROMO_STATUS.PENDING, PROMO_STATUS.ACTIVE].some(el => el === item.status)) tempDeactiveable.push(item.id);
					} else {
						if (item.status === PROMO_STATUS.ERROR) tempResyncable.push(item.id);
						if ([PROMO_STATUS.ERROR, PROMO_STATUS.SCHEDULED].some(el => el === item.status)) tempCancellable.push(item.id);
						if ([PROMO_STATUS.ACTIVE, PROMO_STATUS.SUCCESS].some(el => el === item.status)) tempTerminatable.push(item.id);
					}
					tempSelected.push(item.id);
				});

				setSelected(tempSelected);
				setTerminatable(tempTerminatable);
				setCancellable(tempCancellable);
				setResyncAble(tempResyncable);
				setDeactiveable(tempDeactiveable);
				setActiveable(tempActivable);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleClearSelection = () => {
		setSelected([]);
		setTerminatable([]);
		setResyncAble([]);
		setCancellable([]);
		setActiveable([]);
		setDeactiveable([]);
	};

	const disable = r => ![
		...(isInternal
			? [PROMO_STATUS.PENDING, PROMO_STATUS.ACTIVE, PROMO_STATUS.INACTIVE]
			: [PROMO_STATUS.ACTIVE, PROMO_STATUS.ERROR, PROMO_STATUS.SUCCESS, PROMO_STATUS.SCHEDULED]),
	].some(status => status === r.status);

	const rowSelection = {
		selectedRowKeys: selected,
		onSelectAll: handleSelectAll,
		onSelect: handleOnSelect,
		getCheckboxProps: record => ({
			disabled: disable(record),
			name: record.name,
		}),
	};

	const handleBatchAction = async (getter, setter, actionFn, dependencyStateSetter = () => null) => {
		try {
			setLoading(true);
			const tempValue = [...getter];
			await actionFn(getter);
			setter([]);
			setSelected(state => state.filter(item => !tempValue.includes(item)));

			if (dependencyStateSetter) dependencyStateSetter(state => state.filter(item => !tempValue.includes(item)));
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const activateInternalPromo = async requestId => {
		try {
			setLoading(true);
			const response = await toggleInternalPromo({updateId: requestId, updateTo: 'active'});
			if (response.success) {
				handleClearSelected(requestId);
				await fetchTableData();
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const inactivateInternalPromo = async requestId => {
		try {
			setLoading(true);
			const response = await toggleInternalPromo({updateId: requestId, updateTo: 'inactive'});
			if (response.success) {
				handleClearSelected(requestId);
				await fetchTableData();
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const renderTableAlert = ({selectedRowKeys}) =>
		selectedRowKeys.length > 0 && (
			<div className='flex gap-3 items-center'>
				<InfoCircleFilled className='text-antd-blue-6' />
				<div>
					<span className='font-semibold'>{selectedRowKeys.length}</span> items selected
				</div>
				<div>·</div>
				<a
					className='text-antd-blue-6'
					onClick={onSelectAllData} >
					Select all {syncStatusData.count || 0} items
				</a>
			</div>
		);

	const renderTableAlertOption = () => (
		<div className='flex gap-2 items-center'>
			<div
				onClick={handleClearSelection}
				className='text-antd-blue-6 px-2 cursor-pointer'>Clear</div>
			{
				isInternal
					?	(
						<>
							<Popconfirm
								title={`Are you sure want to activate all ${activable.length} rows?`}
								onConfirm={() => handleBatchAction(activable, setActiveable, activateInternalPromo)}
								okText="Yes"
								cancelText="No"
								disabled={loading || activable.length === 0}
							>
								<Button
									size='small'
									type="primary"
									disabled={loading || activable.length === 0}
								>
									Activable selected ({activable.length})
								</Button>
							</Popconfirm>
							<Popconfirm
								title={`Are you sure want to deactivate all ${deactiveable.length} rows?`}
								onConfirm={() => handleBatchAction(deactiveable, setDeactiveable, inactivateInternalPromo)}
								okText="Yes"
								cancelText="No"
								disabled={loading || deactiveable.length === 0}
							>
								<Button
									danger
									size='small'
									type="primary"
									disabled={loading || deactiveable.length === 0}
								>
									Deactiveable selected ({deactiveable.length})
								</Button>
							</Popconfirm>
						</>
					)
					: (
						<>
							<Popconfirm
								title={`Are you sure want to resync all ${resyncAble.length} rows?`}
								onConfirm={() => handleBatchAction(resyncAble, setResyncAble, handleResync)}
								okText="Yes"
								cancelText="No"
								disabled={loading || resyncAble.length === 0}
							>
								<Button
									size='small'
									type="primary"
									disabled={loading || resyncAble.length === 0}
								>
									Resync selected ({resyncAble.length})
								</Button>
							</Popconfirm>
							<Popconfirm
								title={`Are you sure want to cancel all ${cancellable.length} rows?`}
								onConfirm={() => handleBatchAction(cancellable, setCancellable, handleCancel, setResyncAble)}
								okText="Yes"
								cancelText="No"
								disabled={loading || cancellable.length === 0}
							>
								<Button
									size='small'
									disabled={loading || cancellable.length === 0}
								>
									Cancel selected ({cancellable.length})
								</Button>
							</Popconfirm>
							<Popconfirm
								title={`Are you sure want to terminate all ${terminatable.length} rows?`}
								disabled={loading || terminatable.length === 0}
								onConfirm={() => handleBatchAction(terminatable, setTerminatable, handleCancelSync)}
								okText="Yes"
								cancelText="No"
							>
								<Button
									size='small'
									disabled={loading || terminatable.length === 0}
									type="primary"
									danger
								>
									Terminate selected ({terminatable.length})
								</Button>
							</Popconfirm>
						</>
					)
			}
		</div>
	);

	const handleToggleInternalPromo = async ({updateId, updateTo}) => {
		try {
			setLoading(true);
			const response = await toggleInternalPromo({updateId, updateTo});
			if (response.success) {
				handleClearSelected(updateId);
				await fetchTableData();
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancelSync = async requestId => {
		try {
			setLoading(true);
			const response = await cancelPromoSyncRequest(requestId, promoCategory);
			if (response.success) {
				handleClearSelected(requestId);
				await fetchTableData();
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleResync = async requestId => {
		try {
			setLoading(true);
			const response = await resyncPromo(requestId);
			if (response.success) {
				handleClearSelected(requestId);
				await fetchTableData();
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = async requestIds => {
		try {
			setLoading(true);
			const response = await cancelPromo(Array.isArray(requestIds) ? requestIds : [requestIds]);
			if (response.success) {
				handleClearSelected(requestIds);
				await fetchTableData();
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = pagers => {
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams({...queryParams, ...variables});
	};

	const fetchTableData = async (config = {
		reset: false,
		highlightNewData: false,
	}) => {
		try {
			const {reset} = config;
			setLoading(true);
			let response;
			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit});
				response = await getPromoStatusRequest({...defaultQueryParams, limit}, promoCategory);
			} else {
				response = await getPromoStatusRequest(queryParams, promoCategory);
			}
			setSyncStatusData(response);
			resetRefFlag.current = false;
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleClearSelected = ids => {
		let tempIds = ids;
		if (!Array.isArray(ids)) tempIds = [ids];
		setSelected(state => state.filter(item => !tempIds.includes(item)));
		setCancellable(state => state.filter(item => !tempIds.includes(item)));
		setResyncAble(state => state.filter(item => !tempIds.includes(item)));
		setTerminatable(state => state.filter(item => !tempIds.includes(item)));
		setActiveable(state => state.filter(item => !tempIds.includes(item)));
		setDeactiveable(state => state.filter(item => !tempIds.includes(item)));
	};

	const setToInitialState = () => {
		setSyncStatusData([]);

		setQueryParams(defaultQueryParams);

		setSelected([]);
		setTerminatable([]);
		setResyncAble([]);
		setCancellable([]);
		setActiveable([]);
		setDeactiveable([]);

		tableRef?.current?.reset?.();
	};

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);

				const platformListResponse = await getPlatformList();
				if (platformListResponse?.data?.rows?.length) {
					setPlatformList(platformListResponse.data.rows);
				}

				const brandListResponse = await getBrandList();
				if (brandListResponse?.data?.rows?.length) {
					setBrandList(brandListResponse.data.rows);
				}
			} catch (error) {
				handleErrorFetch(error);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				refetchTableRef.current = fetchTableData;
				setLoading(true);
				if (resetRefFlag.current) return;
				await fetchTableData();
			} catch (error) {
				handleErrorFetch(error);
			} finally {
				setLoading(false);
			}
		})();
	}, [search, page, sortOrder, limit]);

	useEffect(() => {
		setToInitialState();
	}, [promoCategory]);

	return (
		<div>
			<ConfigProvider locale={enUS}>
				<ProTable
					rowKey='id'
					id='promo-status-table'
					actionRef={tableRef}
					dataSource={syncStatusData?.rows}
					loading={loading}
					pagination={{
						defaultPageSize: limit,
						current: page + 1,
						total: syncStatusData?.count,
						showSizeChanger: true,
						pageSizeOptions: ['5', '10', '20', '50', '100'],
						showQuickJumper: true,
					}}
					onChange={handleChange}
					onSubmit={params => {
						Object.keys(params).forEach(k => !params[k] && delete params[k]);
						if (Array.isArray(params.tags)) {
							params.tags = params.tags.join(',').toLowerCase();
						}
						if (params?.startDate?.length) {
							params.startDate.forEach((date, index) => {
								const parsedDate = date.split(' ')[0];
								if (index === 0) {
									params.startDateFrom = parsedDate;
								} else if (index === 1) {
									params.startDateTo = parsedDate;
								}
							});
							delete params.startDate;
						}
						const queryParamsTemp = cloneDeep(defaultQueryParams);
						queryParamsTemp.search = {...defaultSearch, ...params};
						queryParamsTemp.limit = queryParams.limit;
						setQueryParams(queryParamsTemp);
					}}
					onReset={() => fetchTableData({reset: true})}
					scroll={{x: 'max-content'}}
					options={false}
					search={{
						layout: 'vertical',
					}}
					tableAlertRender={(renderTableAlert)}
					tableAlertOptionRender={(renderTableAlertOption)}
					rowSelection={
						authChecker({
							auth,
							requiredRolesArr: EDITOR_ROLES,
							children: true,
						})
							? rowSelection
							: false
					}
					columns={promoTableColumnConfig({
						promoCategory,
						handleResync,
						handleCancel,
						handleCancelSync,
						brandList,
						platformList,
						locationLoading,
						debounceFn,
						location,
						auth,
						handleToggleInternalPromo,
					})}
				/>
			</ConfigProvider>
		</div>
	);
};

PromoStatusTable.defaultProps = {
	refetchTableRef: {},
	promoCategory: null,
};

PromoStatusTable.propTypes = {
	refetchTableRef: PropTypes.object,
	promoCategory: PropTypes.string,
};

export default PromoStatusTable;
