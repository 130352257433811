import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const getMappings = async query => {
	try {
		const {limit, page, search, posId} = query;
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/mappings/menu-pos`,
			params: {
				limit,
				offset: Number((page) * limit) || null,
				posId,
				...search,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const checkMappings = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/menu-template/check-mappings`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const checkSingleMenuMapping = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/mappings/check`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createMapping = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/mappings/menu-pos`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editMapping = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/mappings/menu-pos`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPosMenus = async params => {
	try {
		/**
		 * Search params :
		 * searchString can be used to search id, menuName and menuCode
		 * limit
		 */
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/menus`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const refreshMapping = async ({hash, posId}) => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/pos/menus/refresh`,
			params: {hash, posId},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};