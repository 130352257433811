import React from 'react';
import PropTypes from 'prop-types';

const ItemWrapper = ({label, children}) => (
	<div>
		<div className='pb-2'>{label}</div>
		{children}
	</div>
);

ItemWrapper.defaultProps = {
	label: null,
	children: null,
};

ItemWrapper.propTypes = {
	label: PropTypes.string,
	children: PropTypes.any,
};

export default ItemWrapper;