import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {PROMO_TYPE} from 'utils/constants';

import {ScheduleOutlined, TagOutlined, TagsOutlined} from '@ant-design/icons';
import {Button, Card, Typography} from 'antd';
const {Text, Title} = Typography;

import localization from 'localization';
const locale = localization.Promo.CreateNewPromo.ChoosePromoType;

const SelectPromoType = ({setPromoType}) => {
	const navigate = useNavigate();

	const [selectedType, setSelectedType] = useState(null);

	const getPromoCardLocale = ({cardType}) => {
		let cardLocale = {
			title: '',
			description: '',
		};

		switch (cardType) {
		case PROMO_TYPE.SINGLE:
			cardLocale = {
				title: locale.SingleDiscount.title,
				description: locale.SingleDiscount.description,
			};
			break;
		case PROMO_TYPE.MULTIPLE:
			cardLocale = {
				title: locale.MultipleDiscount.title,
				description: locale.MultipleDiscount.description,
			};
			break;
		case PROMO_TYPE.FLASH_SALE:
			cardLocale = {
				title: locale.FlashSale.title,
				description: locale.FlashSale.description,
			};
			break;
		default:
			break;
		}

		return cardLocale;
	};

	const getCardIcon = ({cardType}) => {
		const cardProps = {
			className: 'text-5xl text-antd-blue-6',
		};
		switch (cardType) {
		case PROMO_TYPE.SINGLE:
			return <TagOutlined {...cardProps} />;
		case PROMO_TYPE.MULTIPLE:
			return <TagsOutlined {...cardProps} />;
		case PROMO_TYPE.FLASH_SALE:
			return <ScheduleOutlined {...cardProps} />;
		default:
			return null;
		}
	};

	// eslint-disable-next-line react/prop-types
	const PromoCard = ({cardType}) => {
		const cardLocale = getPromoCardLocale({cardType});

		return (
			<Card
				onClick={() => setSelectedType(cardType)}
				className={
			`flex-1 flex flex-col items-center justify-center text-center cursor-pointer rounded-md border-2 transition-colors duration-300 lg:p-12
			${selectedType === cardType && 'bg-antd-blue-1 border-antd-blue-6'}
			`
				}
			>
				{getCardIcon({cardType})}
				<div className='flex flex-col gap-2 pt-6'>
					<div className='font-bold'>{cardLocale.title}</div>
					<Text
						className='text-sm'
						type="secondary"
					>{cardLocale.description}</Text>
				</div>
			</Card>
		);
	};

	return (
		<div>
			<Card
				className='w-full'
				title={(
					<div className='flex flex-col'>
						<Title
							className='font-bold'
							level={5}
						>{locale.title}</Title>
						<Text
							className='text-sm font-normal'
							type="secondary"
						>{locale.description}</Text>
					</div>
				)}
			>

				{/* Promo Type Section */}
				<div className='grid grid-cols-2 gap-6'>
					{
						Object.values(PROMO_TYPE).map(promoType => (
							<PromoCard
								key={promoType}
								cardType={promoType}
							/>
						))
					}
				</div>
				{/* End of Promo Type Section */}

				{/* Action Button Section */}
				<div className='flex justify-end gap-2 pt-6'>
					<Button
						onClick={() => navigate(-1)}
					>{locale.cancel}</Button>
					<Button
						onClick={() => setPromoType(selectedType)}
						disabled={!selectedType}
						type='primary'
					>{locale.next}</Button>
				</div>
				{/* End of Action Button Section */}

			</Card>
		</div>
	);
};

SelectPromoType.defaultProps = {
	setPromoType: () => null,
};

SelectPromoType.propTypes = {
	setPromoType: PropTypes.func,
};

export default SelectPromoType;
