import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

// Global Component
import ImportCSVOverlay from 'components/Overlay/ImportCSVOverlay';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';
import LocalPageHeader from 'components/LocalPageHeader';

// Library
import {DownloadOutlined, EditOutlined, ImportOutlined} from '@ant-design/icons';
import {Button} from 'antd';

import {USER_ROLES} from 'utils/constants';

import localization from 'localization';
import {authChecker} from 'utils/utils';
const locale = localization.MenuTemplate.Header;

const MenuTemplateHeader = ({handleExport, getCSVOverlayRef, refetchTableRef, openCreateModal}) => {
	const auth = useSelector(state => state.user);

	const [uploadedFile, setUploadedFile] = useState(null);
	const [importMenuTemplate, setImportMenuTemplate] = useState(false);
	const [getCSV, setGetCSV] = useState({visible: false, type: 'download-template', templateId: null});

	const templateId = useRef(null);
	const selectedMenuTemplateIdRef = useRef(null);

	const handleFileUpload = async e => {
		if (!e.target.files.length) return;

		const uploadedFileTemp = e.target.files[0];
		e.target.value = null;

		setUploadedFile(uploadedFileTemp);
		setImportMenuTemplate(true);
	};

	const closeImportCSVOverlay = () => {
		setUploadedFile(null);
		setImportMenuTemplate(false);
	};

	const openGetCSVOverlay = (type = 'export') => {
		setGetCSV({...getCSV, visible: true, type});
	};

	const closeGetCSVOverlay = () => {
		setGetCSV({...getCSV, visible: false});
		templateId.current = null;
	};

	useEffect(() => {
		getCSVOverlayRef.current = id => {
			selectedMenuTemplateIdRef.current = id;
			templateId.current = id;
			openGetCSVOverlay();
		};
	}, []);

	const headerButtons = [
		<Button
			key='download-template'
			onClick={() => openGetCSVOverlay('download-template')}
			icon={<DownloadOutlined />}>
			{locale.downloadTemplate}
		</Button>,
		<Button
			key='import'
			type="primary"
		>
			<label className='cursor-pointer flex gap-x-2 items-center'>
				<ImportOutlined />
				{locale.import}
				<input
					className="hidden"
					type="file"
					accept=".tsv"
					onChange={handleFileUpload} />
			</label>
		</Button>,
		<Button
			key='create'
			type="primary"
			onClick={() => openCreateModal()}
			icon={<EditOutlined />}>
			{locale.create}
		</Button>,
	];

	return (
		<div>
			<ImportCSVOverlay
				masterlistType={'menu-template'}
				refetchTable={() => refetchTableRef?.current?.({reset: true, highlightNewData: true})}
				handleFileUpload={handleFileUpload}
				uploadedFile={uploadedFile}
				visible={importMenuTemplate}
				importType={'add'}
				close={closeImportCSVOverlay}
			/>
			<ExportCSVOverlay
				masterlistType={'menu-template'}
				handleExport={props => handleExport(props, selectedMenuTemplateIdRef.current)}
				visible={getCSV.visible}
				close={closeGetCSVOverlay}
				type={getCSV.type}
				providedFileName={getCSV.type === 'export' ? `menu-template-orderhub-${templateId.current}.tsv` : ''}
			/>
			<LocalPageHeader
				// latestUpdateInfo={latestUpdateInfo}
				headerTitle='Menu Template'
				extra={authChecker({
					auth,
					requiredRolesArr: [USER_ROLES.MENU_TEMPLATE.ADMIN, USER_ROLES.MENU_TEMPLATE.EDITOR],
					children: headerButtons,
				})}
			/>
		</div>
	);
};

MenuTemplateHeader.defaultProps = {
	handleExport: () => null,
	getCSVOverlayRef: {},
	refetchTableRef: {},
	openCreateModal: () => null,
};

MenuTemplateHeader.propTypes = {
	handleExport: PropTypes.func,
	getCSVOverlayRef: PropTypes.object,
	refetchTableRef: PropTypes.object,
	openCreateModal: PropTypes.func,
};

export default MenuTemplateHeader;
