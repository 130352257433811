import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './OutletActivation.css';

import OutletActivationHeader from './OutletActivationHeader';
import OutletActivationBody from './OutletActivationBody';

import {exportOutletData} from 'utils/request/outlet';
import {exportToTsvFn, generateOutletCSVTemplate} from 'utils/utils';
import {OUTLET_TYPE} from 'utils/constants';

const OutletActivation = ({platformType}) => {
	const containerRef = useRef(null);
	const refetchTableRef = useRef(null);

	const [exportQuery, setExportQuery] = useState(null);

	const scrollToTop = () => {
		if (containerRef.current) {
			containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
		}
	};

	const handleExport = async ({fileName, type = 'template'}) => {
		await exportToTsvFn({
			fileName,
			actionType: type,
			exportCall: () => exportOutletData(exportQuery, OUTLET_TYPE.MERCHANT),
			tsvTemplate: generateOutletCSVTemplate(OUTLET_TYPE.MERCHANT),
		});
	};

	return (
		<div
			ref={containerRef}
			className="bg-white h-full overflow-auto OutletActivation">
			<OutletActivationHeader
				refetchTableRef={refetchTableRef}
				handleExport={handleExport}
			/>
			<OutletActivationBody
				platformType={platformType}
				refetchTableRef={refetchTableRef}
				scrollToTop={scrollToTop}
				setExportQuery={setExportQuery}
			/>
		</div>
	);
};

OutletActivation.defaultProps = {
	platformType: null,
};

OutletActivation.propTypes = {
	platformType: PropTypes.string,
};

export default OutletActivation;