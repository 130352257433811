import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useSelector} from 'react-redux';

import enUS from 'antd/lib/locale/en_US';
import ProTable from '@ant-design/pro-table';
import {InfoCircleFilled} from '@ant-design/icons';
import {Badge, ConfigProvider, Modal, Select, Steps, DatePicker, notification, Typography, Button, Popconfirm} from 'antd';

const {RangePicker} = DatePicker;
const {Paragraph, Text} = Typography;
const {Option} = Select;

import {OUTLET_TYPE, USER_ROLES} from 'utils/constants';
import {authChecker, dateFormat, handleErrorFetch} from 'utils/utils';
import {getOutletData} from 'utils/request/outlet';
import {getBrandList, getPlatformList} from 'utils/request/global';
import {cancelMenuSync, getSyncStatusRequest, reSyncApproval} from 'utils/request/salesChannel';

import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';


const PROMO_STATUS = {
	SCHEDULED: 'scheduled',
	REQUESTED: 'requested',
	PROCESSED: 'processed',
	SUCCESS: 'success',
	FAILED: 'failed',
	RESYNC: 'resync',
	CANCELLED: 'cancelled',
};

const EDITOR_ROLES = [USER_ROLES.SYNC_MENU.ADMIN, USER_ROLES.SYNC_MENU.EDITOR];

const SyncStatusTable = () => {
	const defaultSearch = {
		id: null,
		templateId: null,
		status: null,
	};

	const defaultQueryParams = {
		search: defaultSearch,
		page: 0,
		sortBy: 'updatedAt',
		sortOrder: 'desc',
		limit: 10,
	};

	const auth = useSelector(state => state.user);

	const [loading, setLoading] = useState(false);
	const [syncStatusData, setSyncStatusData] = useState([]);
	const [platformList, setPlatformList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [location, setLocation] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

	const [selected, setSelected] = useState([]);
	const [cancelable, setCancelable] = useState([]);
	const [resyncable, setResyncable] = useState([]);

	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const {search, page, sortOrder, limit} = queryParams;

	const resetRefFlag = useRef(false);

	const getLocation = async locationLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: locationLabel},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocation(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};
	const debounceFn = useCallback(debounce(getLocation, 1000), []);

	const errorNotificationHandler = error => {
		notification.open({
			message: 'Error',
			description: error?.response?.data?.message || error?.message ||'Please Contact Tech Team',
			type: 'warning',
		});
	};

	const fetchTableData = async (config = {
		reset: false,
		highlightNewData: false,
	}) => {
		try {
			const {reset} = config;
			setLoading(true);
			let response;
			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit});
				response = await getSyncStatusRequest({...defaultQueryParams, limit});
			} else {
				response = await getSyncStatusRequest(queryParams);
			}
			setSyncStatusData(response);
		} catch (error) {
			errorNotificationHandler(error);
		} finally {
			resetRefFlag.current = false;
			setLoading(false);
		}
	};

	const decideSyncStatusColor = status => {
		let badgeColor;
		let statusClassName = 'capitalize';
		switch (status) {
		case PROMO_STATUS.SCHEDULED:
			badgeColor = 'default';
			statusClassName += ' text-antd-netural-12';
			break;
		case PROMO_STATUS.REQUESTED:
			badgeColor = 'warning';
			statusClassName += ' text-antd-warning-6';
			break;
		case PROMO_STATUS.RESYNC:
			badgeColor = 'orange';
			statusClassName += ' text-mentai';
			break;
		case PROMO_STATUS.PROCESSED:
			badgeColor = 'processing';
			statusClassName += ' text-antd-blue-6';
			break;
		case PROMO_STATUS.SUCCESS:
			badgeColor = 'success';
			statusClassName += ' text-antd-green-6';
			break;
		case PROMO_STATUS.FAILED:
		case PROMO_STATUS.CANCELLED:
			badgeColor = 'error';
			statusClassName += ' text-antd-red-5';
			break;
		default:
			badgeColor = 'warning';
			statusClassName += ' text-antd-warning-6';
			break;
		}
		return (<div className={statusClassName}><Badge status={badgeColor} /> {status}</div>);
	};

	const handleResync = async id => {
		const response = await reSyncApproval(id);
		if (response.success) {
			fetchTableData({reset: true});
		}
	};

	const handleCancelSync = async requestId => {
		try {
			setLoading(true);
			const response = await cancelMenuSync(requestId);
			if (response.success) {
				await fetchTableData();
			} else {
				if (response?.error?.length) {
					notification.open({
						message: 'Error',
						description: response?.error ||'Please Contact Tech Team',
						type: 'warning',
					});
				}
			}
		} catch (error) {
			errorNotificationHandler(error);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = pagers => {
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams({...queryParams, ...variables});
	};

	const getColumns = () => {
		const defaultColumns = [
			{
				title: 'Approval ID',
				dataIndex: 'syncRequestId',
				key: 'syncRequestId',
				render: syncRequestId => syncRequestId === '-' ? <span>-</span> : (
					<Paragraph
						className='m-0'
						copyable>{syncRequestId}</Paragraph>
				),
			},
			{
				title: 'Brand',
				dataIndex: ['Brand', 'label'],
				key: 'brandLabel',
				renderFormItem: () => (
					<Select placeholder='Please select'>
						{
							[...brandList].sort((a, b) => a.label.localeCompare(b.label)).map(brand => (
								<Option
									key={brand.id}
									value={brand.label}>{brand.label}</Option>
							))
						}
					</Select>
				),
			},
			{
				title: 'UUID',
				dataIndex: ['Merchant', 'externalId'],
				key: 'externalId',
				render: item => (
					<Text
						className='w-36'
						ellipsis={true}
						copyable>{item}</Text>
				),
			},
			{
				title: 'Location',
				dataIndex: ['Location', 'label'],
				// key: 'locationLabel',
				key: 'locationId',
				renderFormItem: () => (
					<Select
						allowClear
						showSearch
						loading={locationLoading}
						onSearch={debounceFn}
						filterOption={((_, option) => option)}
					>
						{location.map(location => (
							<Select.Option
								key={location.id}
								value={location.id}>{location.label}</Select.Option>
						))}
					</Select>
				),
			},
			{
				title: 'Template Name',
				dataIndex: ['MenuTemplate', 'label'],
				key: 'menuTemplateLabel',
				search: false,
			},
			{
				title: 'Sales Channel',
				dataIndex: ['Platform', 'label'],
				key: 'platformLabel',
				renderFormItem: () => (
					<Select placeholder='Please select'>
						{
							platformList.map(platform => (
								<Option
									key={platform.id}
									value={platform.label}>{platform.label}</Option>
							))
						}
					</Select>
				),
			},
			{
				title: 'Scheduled At',
				dataIndex: 'scheduledAt',
				key: 'scheduledAt',
				render: scheduledAt => (
					<div>{dateFormat(scheduledAt)}</div>
				),
				renderFormItem: () => (
					<RangePicker
						ranges={{
							Today: [moment(), moment()],
							'This Month': [moment().startOf('month'), moment().endOf('month')],
						}}
						placeholder={['From', 'Until']}
					/>
				),
			},
			{
				title: 'Template ID',
				dataIndex: ['MenuTemplate', 'id'],
				key: 'menuTemplateId',
				render: templateId => templateId === '-' ? <span>-</span> : (
					<Paragraph
						className='m-0'
						copyable>{templateId}</Paragraph>
				),
			},
			{
				title: 'Sync Status',
				dataIndex: 'status',
				key: 'status',
				width: 150,
				renderFormItem: () => (
					<Select
						allowClear
						className='capitalize'
						placeholder='Please select'>
						{
							Object.values(PROMO_STATUS).map(status => (
								<Option
									key={status}
									value={status}
									className="capitalize">{status}</Option>
							))
						}
					</Select>
				),
				render: status => decideSyncStatusColor(status),
			},
			{
				title: 'Action',
				align: 'center',
				key: 'action',
				search: false,
				fixed: 'right',
				render: (_, record) => (
					[PROMO_STATUS.SCHEDULED, PROMO_STATUS.FAILED].some(status => status === record.status)
						? (
							<div className='flex items-center justify-center gap-4'>
								{record.status === PROMO_STATUS.FAILED && (
									<div
										onClick={() => handleResync(record.id)}
										className='text-antd-blue-6 cursor-pointer'>Re-Sync</div>
								)}
								<Popconfirm
									title="Are you sure want to cancel this sync request?"
									onConfirm={() => handleCancelSync(record.id)}
									okText="Yes"
									cancelText="No"
									placement="topRight"
								>
									<div
										className='text-antd-blue-6 cursor-pointer'>Cancel</div>
								</Popconfirm>
							</div>
						)
						: <div>-</div>
				),
			},
		];

		if (!authChecker({
			auth,
			requiredRolesArr: EDITOR_ROLES,
			children: true,
		})) defaultColumns.pop();

		return defaultColumns;
	};

	const handleBatchAction = async (getter, setter, actionFn) => {
		try {
			setLoading(true);
			const tempValue = [...getter];
			await actionFn(getter);
			setter([]);
			setSelected(state => state.filter(item => !tempValue.includes(item)));
			// To clean up resyncable that has been cancel
			setResyncable(state => state.filter(item => !tempValue.includes(item)));
		} catch (error) {
			errorNotificationHandler(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSelectAll = (selected, _, changeRows) => {
		if (selected) {
			const tempSelected = [];
			const tempCancelable = [];
			const tempResyncable = [];

			changeRows.forEach(item => {
				if (item.status === PROMO_STATUS.FAILED) tempResyncable.push(item.id);
				if ([PROMO_STATUS.FAILED, PROMO_STATUS.SCHEDULED].some(el => el === item.status)) tempCancelable.push(item.id);
				tempSelected.push(item.id);
			});

			setCancelable(state => state.concat(tempCancelable));
			setResyncable(state => state.concat(tempResyncable));
			setSelected(state => state.concat(tempSelected));
		} else {
			const idOfChangeRows = changeRows.map(item => item.id);
			setSelected(state => state.filter(item => !idOfChangeRows.includes(item)));
			setCancelable(state => state.filter(item => !idOfChangeRows.includes(item)));
			setResyncable(state => state.filter(item => !idOfChangeRows.includes(item)));
		}
	};

	const handleOnSelect = (record, selected) => {
		if (selected) { // Check
			if (record.status === PROMO_STATUS.FAILED) setResyncable(state => state.concat(record.id));
			if ([PROMO_STATUS.FAILED, PROMO_STATUS.SCHEDULED].some(el => el === record.status)) setCancelable(state => state.concat(record.id));
			setSelected(state => state.concat(record.id));
		} else { // Uncheck
			setSelected(state => state.filter(item => item !== record.id));
			setCancelable(state => state.filter(item => item !== record.id));
			setResyncable(state => state.filter(item => item !== record.id));
		}
	};

	const onSelectAllData = async () => {
		try {
			setLoading(true);
			const response = await getSyncStatusRequest({...queryParams, limit: syncStatusData.count, page: 0, idOnly: true});
			if (response.rows.length) {
				const filteredRows = response.rows.filter(item => [PROMO_STATUS.FAILED, PROMO_STATUS.SCHEDULED].some(el => el === item.status));

				const tempSelected = [];
				const tempCancelable = [];
				const tempResyncable = [];

				filteredRows.forEach(item => {
					if (item.status === PROMO_STATUS.FAILED) tempResyncable.push(item.id);
					if ([PROMO_STATUS.FAILED, PROMO_STATUS.SCHEDULED].some(el => el === item.status)) tempCancelable.push(item.id);
					tempSelected.push(item.id);
				});

				setCancelable(tempCancelable);
				setResyncable(tempResyncable);
				setSelected(tempSelected);
			}
		} catch (error) {
			errorNotificationHandler(error);
		} finally {
			setLoading(false);
		}
	};

	const handleClearSelection = () => {
		setSelected([]);
		setCancelable([]);
		setResyncable([]);
	};

	const disable = r => ![PROMO_STATUS.FAILED, PROMO_STATUS.SCHEDULED].some(status => status === r.status);

	const rowSelection = {
		selectedRowKeys: selected,
		onSelectAll: handleSelectAll,
		onSelect: handleOnSelect,
		getCheckboxProps: record => ({
			disabled: disable(record),
			name: record.name,
		}),
	};

	const renderTableAlert = ({selectedRowKeys}) =>
		selectedRowKeys.length > 0 && (
			<div className='flex gap-3 items-center'>
				<InfoCircleFilled className='text-antd-blue-6' />
				<div>
					<span className='font-semibold'>{selectedRowKeys.length}</span> items selected
				</div>
				<div>·</div>
				<a
					className='text-antd-blue-6'
					onClick={onSelectAllData} >
					Select all {syncStatusData.count || 0} items
				</a>
			</div>
		);

	const renderTableAlertOption = () => (
		<div className='flex gap-2 items-center'>
			<div
				onClick={handleClearSelection}
				className='text-antd-blue-6 px-2 cursor-pointer'>Clear</div>
			<Popconfirm
				title={`Are you sure want to resync all ${resyncable.length} item?`}
				onConfirm={() => handleBatchAction(resyncable, setResyncable, handleResync)}
				okText="Yes"
				cancelText="No"
			>
				<Button
					type="primary"
					disabled={loading || resyncable.length === 0}
				>
					Resync selected ({resyncable.length})
				</Button>
			</Popconfirm>
			<Popconfirm
				title={`Are you sure want to cancel all ${cancelable.length} item?`}
				disabled={loading || cancelable.length === 0}
				onConfirm={() => handleBatchAction(cancelable, (arrayValue = []) => {
					setResyncable(arrayValue);
					setCancelable(arrayValue);
				}, handleCancelSync)}
				okText="Yes"
				cancelText="No"
			>
				<Button
					disabled={loading || cancelable.length === 0}
					type="primary"
					danger
				>
					Cancel selected ({cancelable.length})
				</Button>
			</Popconfirm>
		</div>
	);

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);

				const platformListResponse = await getPlatformList();
				if (platformListResponse?.data?.rows?.length) {
					setPlatformList(platformListResponse.data.rows);
				}

				const brandListResponse = await getBrandList();
				if (brandListResponse?.data?.rows?.length) {
					setBrandList(brandListResponse.data.rows);
				}
			} catch (error) {
				errorNotificationHandler(error);
			} finally {
				setLoading(false);
			}
		})();
	}, []);


	useEffect(() => {
		if (resetRefFlag.current) return;
		(async () => await fetchTableData())();
	}, [search, page, sortOrder, limit]);

	return (
		<div>
			<ConfigProvider locale={enUS}>
				<ProTable
					rowKey='id'
					id='sync-status-table'
					dataSource={syncStatusData?.rows}
					loading={loading}
					scroll={{x: 'max-content'}}
					pagination={{
						defaultPageSize: limit,
						current: page + 1,
						total: syncStatusData?.count,
						showSizeChanger: true,
						pageSizeOptions: ['5', '10', '20', '50', '100'],
						showQuickJumper: true,
					}}
					onChange={handleChange}
					onSubmit={params => {
						Object.keys(params).forEach(k => !params[k] && delete params[k]);
						if (params?.scheduledAt?.length) {
							params.scheduledAt.forEach((date, index) => {
								const parsedDate = date.split(' ')[0];
								if (index === 0) {
									params.scheduledAtFrom = parsedDate;
								} else if (index === 1) {
									params.scheduledAtTo = parsedDate;
								}
							});
							delete params.scheduledAt;
						}
						const queryParamsTemp = cloneDeep(defaultQueryParams);
						queryParamsTemp.search = {...defaultSearch, ...params};
						queryParamsTemp.limit = queryParams.limit;
						setQueryParams(queryParamsTemp);
					}}
					onReset={() => fetchTableData({reset: true})}
					options={false}
					search={{
						layout: 'vertical',
					}}
					columns={getColumns()}
					expandable={{
						expandedRowRender: record => (
							<div className='flex p-4'>
								<Steps
									status={record.status === 'failed' ? 'error' : 'process'}
									size='small'
									current={((record.status === 'success' || record.status === 'failed' || record.status === 'cancelled') ? 2 : 1) + (isEmpty(record.resyncAt) ? 0 : 1)}
								>
									<Steps.Step
										title='Approved'
										subTitle={`by ${record.approvedBy.username}`}
										description={`at ${dateFormat(record.approvedAt)}`}
									/>
									<Steps.Step
										title="Scheduled"
										description={`at ${dateFormat(record.scheduledAt)}`}
									/>
									{
										isEmpty(record.resyncAt)
											? null
											: (
												<Steps.Step
													title='Resync'
													subTitle={`by ${record.resyncBy.username}`}
													description={`at ${dateFormat(record.resyncAt)}`}
												/>
											)
									}

									<Steps.Step
										title={(
											<div className='capitalize'>{
												record.status === 'success'
													? record.status
													: record.status === 'failed'
														? 'Error'
														: record.status === 'cancelled'
															? 'Cancelled'
															: 'Waiting'
											}</div>
										)}
										subTitle={record.status === 'cancelled' && `by ${record.cancelledBy?.username}`}
										description={
											(record.status === 'success' || record.status === 'cancelled')
												? `at ${dateFormat(record.successAt || record.cancelledAt)}`
												: record.status === 'failed'
													? (
														<div>
															<div>at {dateFormat(record.failedAt)}</div>
															{
																record.errors?.length
																	?
																	(
																		<div
																			onClick={() => {
																				Modal.warning({
																					title: 'Error message',
																					maskClosable: true,
																					width: 600,
																					content: (
																						<div className='flex flex-col gap-2'>
																							{record.errors.map((error, index) => (
																								<div key={index} >{`${index + 1}. ${error}`}</div>
																							))}
																						</div>
																					),
																				});
																			}}
																			className='cursor-pointer text-antd-blue-6'>See error message</div>
																	)
																	: null
															}
														</div>
													)
													: null
										}
									/>
								</Steps>
							</div>
						),
					}}
					tableAlertRender={(renderTableAlert)}
					tableAlertOptionRender={(renderTableAlertOption)}
					rowSelection={
						authChecker({
							auth,
							requiredRolesArr: EDITOR_ROLES,
							children: true,
						})
							? rowSelection
							: false
					}
					className='transition-colors duration-1000'
				/>
			</ConfigProvider>
		</div>
	);
};

export default SyncStatusTable;
