import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import {ProFormText, QueryFilter, ProFormSelect} from '@ant-design/pro-form';

import {getOutletData} from 'utils/request/outlet';
import {OUTLET_TYPE} from 'utils/constants';
import {handleErrorFetch} from 'utils/utils';

const Filter = forwardRef(({tableFormRef, brandList}, ref) => {
	const queryRef = useRef();
	const [locationList, setLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

  	// Methods
	const refetchData = () => {
		tableFormRef.current?.reload?.();
	};
	const resetForm = () => {
		queryRef?.current?.resetFields?.();
		tableFormRef.current?.reloadAndRest();
	};
	const getFilterPayload = () => {
		return queryRef.current?.getFieldsValue?.();
	};

	useImperativeHandle(ref, () => ({
		getMetricFilter() {
			return getFilterPayload();
		},
	}));

	const getLocation = async outletLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	return (
		<div className='px-6'>
			<QueryFilter
				formRef={queryRef}
				className="p-6 pb-0 mb-4 border border-antd-netural-4 bg-antd-netural-2 rounded-md"
				layout="vertical"
				onFinish={() => refetchData()}
				onReset={() => resetForm()}
			>
				<ProFormText
					name="promoId"
					label="Promo ID"
				/>
				<ProFormText
					name="menuLabel"
					label="Menu Name"
				/>
				<ProFormSelect
					name="locationId"
					label="Outlet"
					showSearch
					fieldProps={{
						onSearch: debounceFn,
						loading: locationLoading,
					}}
					options={locationList.map(location => {
						return {
							label: location.label,
							value: location.id,
						};
					})}
				/>
				<ProFormSelect
					name="brandId"
					label="Brand"
					options={brandList}
				/>
				<ProFormText
					name="promoName"
					label="Promo Name"
				/>
			</QueryFilter>
		</div>
	);
});

Filter.defaultProps = {
	tableFormRef: {},
	brandList: [],
};

Filter.propTypes = {
	tableFormRef: PropTypes.object,
	brandList: PropTypes.array,
};

export default Filter;