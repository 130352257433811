import React from 'react';

import ProTable from '@ant-design/pro-table';

import LocalPageHeader from 'components/LocalPageHeader';

import {handleErrorFetch, momentDateFormat} from 'utils/utils';
import {getMasterlistData} from 'utils/request/masterlist';

import localization from 'localization';
const locale = localization.MegaMenuTemplate;

const MegaMenuTemplate = () => {

	const goToDetail = data => {
		window.open(`/sales-channel/mega-menu-template/${data.id}`, '_blank');
	};

	const columns = [
		{
			width: 206,
			key: 'id',
			dataIndex: 'id',
			title: locale.megaMenuTemplateId,
		},
		{
			key: 'label',
			dataIndex: 'label',
			title: locale.megaMenuTemplateLabel,
		},
		{
			key: 'createdBy',
			dataIndex: ['creator', 'username'],
			title: locale.createdBy,
		},
		{
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: locale.createdAt,
			search: false,
			sorter: true,
			defaultSortOrder: 'descend',
			render: createdAt => momentDateFormat({dateString: createdAt, format: 'DD MMM YYYY - HH:mm'}),
		},
		{
			key: 'action',
			title: locale.action,
			search: false,
			render: (_, record) => (
				<a
					className='text-antd-blue-6'
					onClick={() => goToDetail(record)}>{locale.detail}</a>
			),
		},
	];

	const sortDirectionConverter = direction => {
		switch (direction) {
		case 'ascend':
			return 'asc';
		case 'descend':
			return 'desc';
		default:
			return 'desc';
		}
	};

	const fetchTableData = async (params, sorter) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				page: params?.current - 1,
				type: 'complex',
				sortBy: 'createdAt',
				sortOrder: sortDirectionConverter(sorter.createdAt),
				search: {
					id: params?.id,
					label: params?.label,
					createdBy: params?.createdBy,
				},
			};
			const response = await getMasterlistData(payload, 'menu-template');
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<div
			className="bg-white h-full overflow-auto">
			<LocalPageHeader title={locale.title} />
			<ProTable
				columns={columns}
				options={false}
				search={{
					layout: 'vertical',
				}}
				scroll={{x: 'max-content'}}
				request={fetchTableData}
			/>
		</div>
	);
};

export default MegaMenuTemplate;