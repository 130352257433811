import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import localization from 'localization';
import {Input, Modal, Typography} from 'antd';

const SaveConfirmationModal = ({open, onSave, loadingSave, onClose, isEdit, currentMenuCombinationName}) => {
	const locale = isEdit
		? localization.InternalOrdering.MenuCreatorForm.EditConfirmation
		: localization.InternalOrdering.MenuCreatorForm.CreateConfirmation;

	const [menuCombinationName, setMenuCombinationName] = useState('');

	useEffect(() => {
		if (open) setMenuCombinationName(isEdit ? currentMenuCombinationName || '' : '');
	}, [open]);

	return (
		<Modal
			closable={false}
			open={open}
			centered
			width={396}
			destroyOnClose
			onCancel={() => !loadingSave && onClose()}
			okText={locale.okText}
			onOk={() => onSave(menuCombinationName)}
			cancelText={locale.cancelText}
			okButtonProps={{
				disabled: !menuCombinationName.length,
				loading: loadingSave,
			}}
			cancelButtonProps={{
				loading: loadingSave,
			}}
		>
			<div>
				<Typography.Title level={5}>{locale.title}</Typography.Title>
				<div>{locale.subtitle}</div>
				<Input
					disabled={loadingSave}
					value={menuCombinationName}
					onChange={e => setMenuCombinationName(e.target.value)}
					className='mt-4'
					placeholder={locale.inputPlaceholder} />
			</div>
		</Modal>
	);
};

SaveConfirmationModal.defaultProps = {
	open: false,
	onSave: () => null,
	loadingSave: false,
	onClose: () => null,
	isEdit: false,
	currentMenuCombinationName: null,
};

SaveConfirmationModal.propTypes = {
	open: PropTypes.bool,
	onSave: PropTypes.func,
	loadingSave: PropTypes.bool,
	onClose: PropTypes.func,
	isEdit: PropTypes.bool,
	currentMenuCombinationName: PropTypes.string,
};

export default SaveConfirmationModal;