import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {downloadMenuAvailabilityReport, getInactiveReason} from 'utils/request/watchtower';
import {handleErrorFetch} from 'utils/utils';

import Header from './Header';
import TableMenuList from './TableMenuList';
import ModalDownloadReport from '../components/ModalDownloadReport';

import {Tabs} from 'antd';

// Local styles
import './watchTower.less';

import localization from 'localization';
const locale = localization.WatchTower.WatchTowerMenu;

const WatchtowerMenu = ({isHistory}) => {
	const [activeTab, setActiveTab] = useState('menu');
	const [menuAvailabilityReason, setMenuAvailabilityReason] = useState([]);

	const downloadReportModalRef = useRef(null);

	const tabs = [
		{key: 'menu', label: locale.tabs.menu},
		{key: 'childMenu', label: locale.tabs.childMenu},
	];

	const initialFetch = async () => {
		try {
			const response = await getInactiveReason();
			if (response?.data?.length) {
				setMenuAvailabilityReason(response.data.map(reason => reason.label));
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const downloadFn = async ({createdAtFrom, createdAtTo}) => {
		return await downloadMenuAvailabilityReport({
			createdAtFrom,
			createdAtTo,
			isHistory,
		});
	};

	useEffect(() => {
		initialFetch();
	}, []);

	return (
		<>
			<ModalDownloadReport
				isHistory={isHistory}
				ref={downloadReportModalRef}
				downloadFn={downloadFn}
			/>
			<div className='bg-white h-full overflow-auto'>
				<Header
					isHistory={isHistory}
					customBreadcrumbLabel={isHistory && locale.header.breadcrumbHistory}
					headerLabel={isHistory ? locale.header.history : locale.header.watchtower}
					openDownloadReportModal={() => downloadReportModalRef.current?.open()}
				/>
				<div className="WatchTowerTabsContainer">
					<Tabs onChange={setActiveTab}>
						{tabs.map(each => (
							<Tabs.TabPane
								key={each.key}
								tab={each.label}>
								<TableMenuList
									menuAvailabilityReason={menuAvailabilityReason}
									isFocus={each.key === activeTab}
									isHistory={isHistory}
									isChildMenu={each.key === 'childMenu'}
								/>
							</Tabs.TabPane>
						))}
					</Tabs>
				</div>
			</div>
		</>
	);
};

WatchtowerMenu.defaultProps = {
	isHistory: false,
};

WatchtowerMenu.propTypes = {
	isHistory: PropTypes.bool,
};

export default WatchtowerMenu;
