import {IMAGE_SIZE} from './constants';
import crashLogger from 'utils/logger';

const BASE_URL = 'https://api.cloudinary.com/v1_1/dgsgylfvr';

export async function uploadImage(file, dir) {
	try {
		// Remove special characters as required by cloudinary public id
		const pattern = /[^a-zA-Z0-9 ]/g;

		const formData = new FormData();
		const fileName = `${new Date().valueOf()}_${file.name.split('.')[0].replace(pattern, '')}`;

		// NOTES: we have put validator on the upload component handler but we still need this one just in case.
		if (file.size > IMAGE_SIZE.MAX_IMAGE_SIZE) {
			crashLogger.error('File size is exceeding the max size limit');
			throw new Error('File size is exceeding the max size limit');
		}

		// eslint-disable-next-line no-undef
		formData.append('upload_preset', 'orderhub');
		formData.append('public_id', `${dir}/${fileName}`);
		formData.append('file', file);

		const response = await fetch(`${BASE_URL}/image/upload`, {
			method: 'POST',
			body: formData,
		}).then(res => res.json());

		return response;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
}
