import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Button, Form, Input, Radio, Select, notification} from 'antd';

import {getStationLabel, handleErrorFetch} from 'utils/utils';
import {createMasterlistItem, editMasterlistItem} from 'utils/request/masterlist';
import {MASTERLIST_TYPE, MENU_STATION} from 'utils/constants';

import localization from 'localization';
const locale = localization.MasterlistPage.MasterlistForm.MenuReminderForm;
const actionLocale = localization.MasterlistPage.MasterlistForm.action;

const MenuReminderForm = ({isEdit, itemRecord}) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async e => {
		try {
			setIsLoading(true);
			const fetchFn = isEdit ? editMasterlistItem : createMasterlistItem;
			if (isEdit) e.id = itemRecord.id;
			const response = await fetchFn(e, MASTERLIST_TYPE.REMINDER);
			if (response.success) {
				const notificationLocale = isEdit ? actionLocale.EditSuccess : actionLocale.CreateSuccess;
				navigate('/masterlist/menu-reminder');
				notification.open({
					message: notificationLocale.title,
					description: notificationLocale.description.replace('{{type}}', 'menu reminder'),
					type: 'success',
				});
			}		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isEdit) {
			form.setFieldsValue({
				isActive: itemRecord.isActive,
				name: itemRecord.name,
				tags: itemRecord.tags,
				station: itemRecord.station,
			});
		}},
	[]);

	return (
		<Form
			form={form}
			layout="vertical"
			name="menuForm"
			scrollToFirstError
			onFinish={e => !isLoading && handleSubmit(e)}
			onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
			requiredMark={false}
			initialValues={{
				isActive: 1,
				station: '',
			}}
		>
			<Form.Item
				label={locale.nameLabel}
				name="name"
				rules={[{required: true, message: locale.nameLabelRequired}]}
			>
				<Input
					className='w-96'
					placeholder={locale.nameLabelPlaceholder}
					showCount
					maxLength={30}
				/>
			</Form.Item>
			<Form.Item
				label={locale.stationLabel}
				name="station"
			>
				<Select
					className='w-96'
					allowClear
				>
					{Object.values(MENU_STATION).map(station => (
						<Select.Option
							key={station}
							label={station}
							value={station}
						>
							{getStationLabel(station)}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label={locale.tagsLabel}
				name="tags">
				<Select
					placeholder={locale.tagsLabelPlaceholder}
					className='w-96'
					open={false}
					mode='tags'
					tokenSeparators={[',']}
				/>
			</Form.Item>
			<Form.Item
				label={locale.statusLabel}
				name="isActive">
				<Radio.Group>
					<Radio
						key='active'
						value={true}>Yes</Radio>
					<Radio
						key='inactive'
						value={false}>No</Radio>
				</Radio.Group>
			</Form.Item>
			<div className='flex gap-2 justify-end'>
				<Button
					onClick={() => navigate('/masterlist/menu-reminder')}
					disabled={isLoading}>
					{actionLocale.cancel}
				</Button>
				<Button
					type="primary"
					htmlType="submit"
					loading={isLoading}>
					{actionLocale.submit}
				</Button>
			</div>
		</Form>
	);
};

MenuReminderForm.defaultProps = {
	isEdit: false,
	itemRecord: {},
};

MenuReminderForm.propTypes = {
	isEdit: PropTypes.bool,
	itemRecord: PropTypes.object,
};

export default MenuReminderForm;