import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const getSettlement = async ({params, platform, isExport}) => {
	try {
		// const {limit, page, search, sortBy, sortOrder} = params;
		let platformEndpoint = '';
		switch (platform) {
		case 'grab':
		case 'gojek':
		case 'shopee':
			platformEndpoint = `settlement/${platform}`;
			break;
		case 'others':
			platformEndpoint = 'others';
			break;
		default:
			platformEndpoint = `settlement/${platform}`;
			break;
		}
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/finance/transaction/${platformEndpoint}${isExport ? '/export' : ''}`,
			params,
		});
		if (isExport) {
			if (data.success) {
				const blobResponse = await (await fetch(data.data.url)).blob();
				return blobResponse;
			}
		} else {
			return data;
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getTransaction = async ({params, isExport}) => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/finance/transaction${isExport ? '/export' : ''}`,
			params,
		});
		if (isExport) {
			if (data.success) {
				const blobResponse = await (await fetch(data.data.url)).blob();
				return blobResponse;
			}
		} else {
			return data;
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getFinalTax = async ({params, isExport}) => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/finance/transaction/tax${isExport ? '/export' : ''}`,
			params,
		});
		if (isExport) {
			if (data.success) {
				const blobResponse = await (await fetch(data.data.url)).blob();
				return blobResponse;
			}
		} else {
			return data;
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getSalesFileList = async ({fileType, params}) => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/finance/transaction/${fileType}/upload`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const deleteSettlementFile = async settlementId => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/finance/transaction/settlement/upload`,
			data: {settlementId},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getCheckerData = async ({params, platform}) => {
	try {
		// const {limit, page, search, sortBy, sortOrder} = params;
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/finance/transaction/settlement/${platform}/checker`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getDropdownOption = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/finance/transaction/dropdown-options`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};