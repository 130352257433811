import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {ProFormText, QueryFilter, ProFormDateRangePicker, ProFormDigit} from '@ant-design/pro-form';
import {Card, Typography} from 'antd';

const MetricFilter = forwardRef(({tableFormRef}, ref) => {
	const queryRef = useRef();

	// eslint-disable-next-line no-unused-vars
	const [metricData, setMetricData] = useState({
		totalCheck: 0,
		averageOff: 0,
	});

	const metricCards = [
		{label: 'Total Check', value: metricData.totalCheck},
		{label: 'Average Promo Off', value: metricData.averageOff, inPercent: true},
	];

	// Methods
	const refetchData = () => {
		tableFormRef.current?.reload?.();
	};
	const resetForm = () => {
		queryRef?.current?.resetFields?.();
		tableFormRef.current?.reloadAndRest();
	};
	const getFilterPayload = () => {
		return queryRef.current?.getFieldsValue?.();
	};

	const getDefaultDate = () => {
		const today = moment();
		return [today.startOf('day'), today.endOf('day')];
	};

	useImperativeHandle(ref, () => ({
		getMetricFilter() {
			return getFilterPayload();
		},
		updateMetricData(data) {
			setMetricData(data);
		},
	}));

	return (
		<div>
			<QueryFilter
				formRef={queryRef}
				className="p-6 pb-0 mb-4 border border-antd-netural-4 bg-antd-netural-2 rounded-md"
				layout="vertical"
				onFinish={() => refetchData()}
				onReset={() => resetForm()}
				initialValues={{
					dateRange: getDefaultDate(),
				}}
			>
				<ProFormDateRangePicker
					name="dateRange"
					label="Date Range"
					format='DD MMM YYYY'
				/>
				<ProFormDigit
					allowClear
					name="id"
					label="Promo Check ID"
				/>
				<ProFormText
					name="createdBy"
					label="Check By"
				/>
			</QueryFilter>
			<div>
				<div className='grid grid-cols-2 lg:grid-cols-3 gap-6'>
					{metricCards.map(metricData => (
						<div
							className='flex-1'
							key={metricData.label}>
							<Card className="metricCard rounded-md">
								<Typography.Text
									className='text-sm'
									type="secondary">
									{metricData.label}
								</Typography.Text>
								<div className="h-2" />
								<Typography.Text className='text-2xl'>
									{`${metricData.value}${metricData.inPercent ? '%' : ''}`}
								</Typography.Text>
							</Card>
						</div>
					))}
				</div>
			</div>
		</div>
	);
});

MetricFilter.defaultProps = {
	tableFormRef: {},
};

MetricFilter.propTypes = {
	tableFormRef: PropTypes.object,
};

export default MetricFilter;