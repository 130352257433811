import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import './MappingModal.css';

import {ConfigProvider, Modal, Select, Tag} from 'antd';
import enUS from 'antd/lib/locale/en_US';
import ProTable from '@ant-design/pro-table';

import {handleErrorFetch, moneyFormat} from 'utils/utils';
import {getMasterlistData} from 'utils/request/masterlist';
import {MASTERLIST_TYPE} from 'utils/constants';
import cloneDeep from 'lodash/cloneDeep';
import {InfoCircleFilled} from '@ant-design/icons';

const defaultSearch = {
	menuLabel: null,
	menuCode: null,
	menuId: null,
	tags: null,
	isActive: true,
};

const defaultQueryParams = {
	search: defaultSearch,
	page: 0,
	sortBy: 'updatedAt',
	sortOrder: 'desc',
	limit: 5,
};

const MappingModal = ({visible, close, type, formSettedMenu, saveMapping}) => {
	const resetRefFlag = useRef(false);

	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState({});

	const [selectedMenu, setSelectedMenu] = useState([]);

	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const {search, page, sortBy, sortOrder, limit} = queryParams;

	const isChild = type === MASTERLIST_TYPE.CHILD_MENU;

	const getColumns = () => {
		const output = [
			{
				title: isChild ? 'Child Menu Name' : 'Menu Name',
				dataIndex: 'menuLabel',
				key: 'menuLabel',
			},
			{
				title: type === MASTERLIST_TYPE.CHILD_MENU ? 'Child ID' : 'Menu ID',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'Tags',
				dataIndex: 'tags',
				key: 'tags',
				renderFormItem: () => (
					<Select
						open={false}
						mode='tags'
						tokenSeparators={[',']}
					/>
				),
				render: tags => (Array.isArray(tags) && tags.length) ? (
					<div className='flex flex-wrap gap-y-2'>
						{tags.map((tag, index) => (
							<Tag
								color='blue'
								className='uppercase'
								key={index}>{tag}
							</Tag>
						))}
					</div>
				) : '-',
			},
			{
				title: 'Price',
				dataIndex: 'price',
				key: 'price',
				search: false,
				render: price => (
					<div>{moneyFormat({value: price})}</div>
				),
			},
		];

		return output;
	};

	const fetchTableData = async (config = {
		reset: false,
	}) => {
		try {
			const {reset} = config;
			setLoading(true);

			let response = null;

			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit});
				response = await getMasterlistData({...defaultQueryParams, limit}, type);
			} else {
				response = await getMasterlistData(queryParams, type);
			}

			if (response.success) {
				setTableData(response.data);
			} else throw {};

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
			resetRefFlag.current = false;
		}
	};

	const handleChange = pagers => {
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams({...queryParams, ...variables});
	};

	const handleSelectAll = (selected, _, changeRows) => {
		if (selected) {
			const tempSelectedMenu = [];

			changeRows.forEach(item => {
				tempSelectedMenu.push(item);
			});

			setSelectedMenu(state => state.concat(tempSelectedMenu));
		} else {
			const idOfChangeRows = changeRows.map(item => item.id);
			setSelectedMenu(state => state.filter(item => !idOfChangeRows.includes(item.id)));
		}
	};

	const handleOnSelect = (record, selected) => {
		if (selected) { // Check
			setSelectedMenu(state => state.concat(record));
		} else { // Uncheck
			setSelectedMenu(state => state.filter(item => item.id !== record.id));
		}
	};

	const onSelectAllData = async () => {
		try {
			setLoading(true);
			const response = await getMasterlistData({...queryParams, limit: tableData.count, page: 0}, type);
			if (response.success) {

				const tempSelected = [];

				response.data.rows.forEach(item => {
					tempSelected.push(item);
				});

				setSelectedMenu(tempSelected);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleClearSelection = () => {
		setSelectedMenu([]);
	};

	const renderTableAlert = ({selectedRowKeys}) =>
		selectedRowKeys.length > 0 && (
			<div className='flex gap-3 items-center'>
				<InfoCircleFilled className='text-antd-blue-6' />
				<div>
					<span className='font-semibold'>{selectedRowKeys.length}</span> items selected
				</div>
				<div>·</div>
				<a
					onClick={onSelectAllData}
					className='text-antd-blue-6' >
					Select all {tableData.count || 0} items
				</a>
			</div>
		);

	const renderTableAlertOption = () => (
		<div
			onClick={handleClearSelection}
			className='text-antd-blue-6 px-2 cursor-pointer'>Clear</div>
	);

	const rowSelection = {
		selectedRowKeys: selectedMenu.map(menu => menu.id),
		onSelectAll: handleSelectAll,
		onSelect: handleOnSelect,
		getCheckboxProps: record => ({
			name: record.name,
		}),
	};

	const handleOk = () => {
		// Save selected menu to form and close the modal
		saveMapping(selectedMenu);
	};

	useEffect(() => {
		if (!visible) return;
		setSelectedMenu(formSettedMenu || []);
		fetchTableData({reset: true});
	}, [visible]);

	useEffect(() => {
		// To handle query changes
		if (resetRefFlag.current) return;
		(async () => await fetchTableData())();
	}, [search, page, sortBy, sortOrder, limit]);

	return (
		<Modal
			destroyOnClose={true}
			className='MappingModal'
			title={(
				<span className='leading-6'>
					{`Select ${type === MASTERLIST_TYPE.CHILD_MENU ? 'Child' : 'Parent' }`}
				</span>
			)}
			visible={visible}
			width={1022}
			centered
			onOk={handleOk}
			onCancel={close}
		>
			<ConfigProvider locale={enUS}>
				<ProTable
					rowKey='id'
					id='material-mapping-table'
					dataSource={tableData?.rows}
					loading={loading}
					pagination={{
						defaultPageSize: limit,
						current: page + 1,
						total: tableData?.count,
						showSizeChanger: true,
						pageSizeOptions: ['5', '10', '20', '50', '100'],
						showQuickJumper: true,
					}}
					onChange={handleChange}
					onSubmit={params => {
						Object.keys(params).forEach(k => !params[k] && delete params[k]);
						if (Array.isArray(params.tags)) {
							params.tags = params.tags.join(',').toLowerCase();
						}
						const queryParamsTemp = cloneDeep(defaultQueryParams);
						queryParamsTemp.search = {...defaultSearch, ...params};
						queryParamsTemp.limit = queryParams.limit;
						setQueryParams(queryParamsTemp);
					}}
					onReset={() => fetchTableData({reset: true})}
					scroll={{y: 250}}
					options={false}
					search={{
						layout: 'vertical',
					}}
					tableAlertRender={(renderTableAlert)}
					tableAlertOptionRender={(renderTableAlertOption)}
					rowSelection={rowSelection}
					columns={getColumns()}
				/>
			</ConfigProvider>
		</Modal>
	);
};

MappingModal.defaultProps = {
	visible: false,
	close: () => null,
	type: MASTERLIST_TYPE.MENU,
	saveMapping: () => null,
	formSettedMenu: [],
};

MappingModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	type: PropTypes.oneOf([MASTERLIST_TYPE.MENU, MASTERLIST_TYPE.CHILD_MENU]),
	saveMapping: PropTypes.func,
	formSettedMenu: PropTypes.array,
};

export default MappingModal;