import React, {useRef} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';

import UserActivationHeader from './UserActivationHeader';
import UserActivationBody from './UserActivationBody';
import UserActionModal from './UserActionModal';
import UserEditModal from './UserEditModal';

import {OUTLET_USER_MODAL_ACTION_TYPE} from 'utils/constants';

const userActionModalInitialState = {
	visible: false,
	data: {},
	isReset: false,
};

const UserActivation = ({isFresto}) => {
	const [actionModalVisible, setActionModalVisible] = useState(false);
	const [actionModalType, setActionModalType] = useState(OUTLET_USER_MODAL_ACTION_TYPE.CREATE);
	const [users, setUsers] = useState([]);
	const [userActionModal, setUserActionModal] = useState(userActionModalInitialState);
	const refetchTableRef = useRef(null);

	const openActionModal = (option = {
		type: OUTLET_USER_MODAL_ACTION_TYPE.CREATE,
		users: [],
	}) => {
		setActionModalType(option.type);
		if (option.type === OUTLET_USER_MODAL_ACTION_TYPE.RESET) setUsers(option.users);
		setActionModalVisible(true);
	};

	const closeActionModal = () => {
		setUsers([]);
		setActionModalVisible(false);
	};

	const openUserActionModal = (data = {}, isReset) => {
		setUserActionModal({
			visible: true,
			data,
			isReset,
		});
	};

	const closeUserActionModal = () => {
		setUserActionModal(userActionModalInitialState);
	};

	return (
		<div
			className="bg-white h-full overflow-auto">
			<UserActionModal
				type={actionModalType}
				visible={actionModalVisible}
				onClose={closeActionModal}
				usersToReset={users}
				refetchTableRef={refetchTableRef}
			/>
			<UserEditModal
				isReset={userActionModal.isReset}
				visible={userActionModal.visible}
				data={userActionModal.data}
				close={closeUserActionModal}
				refetchTableRef={refetchTableRef}
			/>
			<UserActivationHeader
				openActionModal={openUserActionModal}
				refetchTableRef={refetchTableRef}
			/>
			<UserActivationBody
				refetchTableRef={refetchTableRef}
				openActionModal={openActionModal}
				openUserActionModal={openUserActionModal}
				isFresto={isFresto}
			/>
		</div>
	);
};

UserActivation.defaultProps = {
	isFresto: false,
};

UserActivation.propTypes = {
	isFresto: PropTypes.bool,
};

export default UserActivation;