import React, {useState} from 'react';

import {Card, Select, Typography} from 'antd';
import {ProFormDateRangePicker, ProFormSelect, QueryFilter} from '@ant-design/pro-form';

import moment from 'moment';
import {numberFormat} from 'utils/utils';

import localization from 'localization';
const locale = localization.Promo.PromoUsage.Internal.Metric;

const PromoUsageMetric = () => {
	const [location] = useState([]);
	const [metricData] = useState({
		failedPercentage: 0,
		successTotal: 0,
		total: 0,
	});

	const metricCards = [
		{label: locale.Card.totalUsage, value: numberFormat(metricData.total), showPercent: false},
		{label: locale.Card.totalPurchase, value: numberFormat(metricData.successTotal), showPercent: false},
		{label: locale.Card.averageAov, value: metricData.failedPercentage, showPercent: false},
	];

	return (
		<>
			<QueryFilter
				// onReset={resetForm}
				// formRef={queryRef}
				className="mx-6 p-6 pb-0 mb-4 border border-antd-netural-4 bg-antd-netural-2"
				labelWidth="auto"
				layout="vertical"
				lang="en"
				// submitter={{
				// 	submitButtonProps: {
				// 		loading,
				// 	},
				// }}
				initialValues={{
					orderDate: [moment().startOf('day'), moment().endOf('day')],
				}}
				// onFinish={() => refetchData()}
			>
				<ProFormDateRangePicker
					allowClear={false}
					label={locale.Filter.dateLabel}
					name="promoDate"
					ranges={{
						Today: [moment().startOf('day'), moment().endOf('day')],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
					}}
					placeholder={[locale.Filter.datePlaceholderStart, locale.Filter.datePlaceholderEnd]}
				/>
				<div className='flex flex-1 flex-col'>
					<div>{locale.Filter.promoIdLabel}</div>
					<Select
						allowClear
						showSearch
						// loading={loading}
						// onSearch={debounceFn}
						// value={selectedLocationId}
						// onChange={value => setSelectedLocationId(value)}
						className='w-full pt-2'
						filterOption={((_, option) => option)}
						placeholder={locale.Filter.promoIdPlaceholder}>
						{location.map(location => (
							<Select.Option
								key={location.id}
								value={location.id}>{location.label}</Select.Option>
						))}
					</Select>
				</div>
				<ProFormSelect
					label={locale.Filter.outletLabel}
					placeholder={locale.Filter.outletPlaceholder}
					name="locationId"
					// options={brandList.map(brand => ({
					// 	label: brand.label,
					// 	value: brand.id,
					// }))}
				/>
			</QueryFilter>
			<div className="px-6">
				<div className='flex gap-6'>
					{metricCards.map(({label, value, showPercent}) => (
						<div
							className='flex-1'
							key={label}>
							<Card className="metricCard">
								<Typography.Text
									className='text-sm'
									type="secondary">
									{label}
								</Typography.Text>
								<div className="h-2" />
								<Typography.Text className='text-2xl'>
									{showPercent ? `${value}%` : value}
								</Typography.Text>
							</Card>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default PromoUsageMetric;