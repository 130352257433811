import isEmpty from 'lodash/isEmpty';
import {PACKAGING_OPTIONS} from './constants';
import {get} from 'lodash';
import {handleFillDummy} from './utils';

export const getItemStructure = ({isParent}) => {
	return {
		menuType: isParent
			? 'parent'
			: 'child',
		menuId: '',
		menuLabel: '',
		displayName: '',
		menuCode: '',
	};
};

export const optionGroupConverter = ({optionGroups, selectedOptionGroupId, mappedData, fillDummy}) => {
	const convertedOptionGroups = {};
	const mappedDataExist = !isEmpty(mappedData);

	// Loop through the original optionGroups array
	optionGroups.forEach(group => {
		// Extract the optionGroupId and menus from each group object
		const {optionGroupId, menus} = group;
		const isParent = selectedOptionGroupId == optionGroupId;

		// Create an object to store the childMenus
		const childMenus = {};

		// Loop through the menus array to add each menuId to the childMenus object
		menus.forEach(menu => {
			const {menuId} = menu;
			if (mappedDataExist) {
				const childMenuExistInMapping = mappedData[optionGroupId]?.childMenus[menuId]?.length;
				if (childMenuExistInMapping) {
					childMenus[menuId] = mappedData[optionGroupId].childMenus[menuId]?.map((childMenu, index) => {
						if (index === 0) {
							if (!childMenu.menuId && fillDummy) return handleFillDummy(isParent ? 'parent' : 'child');
							return {
								...childMenu,
								menuType: isParent ? 'parent' : 'child',
							};
						} else return childMenu;
					});
				} else {
					childMenus[menuId] = [getItemStructure({isParent})];
				}
			} else {
				childMenus[menuId] = [getItemStructure({isParent})];
			}
		});

		// Create the final object for the current optionGroupId
		convertedOptionGroups[optionGroupId] = {
			isParent: isParent,
			childMenus,
		};

		let optionGroupEntry = {};

		if (isEmpty(mappedData)) {
			optionGroupEntry = optionGroups?.find?.(item => item.optionGroupId == optionGroupId);
		} else {
			optionGroupEntry = get(mappedData, [optionGroupId]);
		}

		const packagingData = optionGroupEntry?.packaging;
		if (!isEmpty(packagingData)) convertedOptionGroups[optionGroupId].packaging = packagingData;
		else convertedOptionGroups[optionGroupId].packaging = {type: PACKAGING_OPTIONS.DEFAULT};
	});

	return convertedOptionGroups;
};

export const getValueByPath = (sourceObject, pathArray) => {
	let value = sourceObject;

	for (const pathElement of pathArray) {
		value = value[pathElement];
		if (value === undefined) {
			return undefined;
		}
	}

	return value;
};

export const setValueByPath = (sourceObject, pathArray, newValue) => {
	let obj = sourceObject;

	for (let i = 0; i < pathArray.length - 1; i++) {
		const pathElement = pathArray[i];
		if (!obj[pathElement] || typeof obj[pathElement] !== 'object') {
			obj[pathElement] = {};
		}
		obj = obj[pathElement];
	}

	const lastPathElement = pathArray[pathArray.length - 1];
	obj[lastPathElement] = newValue;
};