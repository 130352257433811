import {Badge} from 'antd';
import localization from 'localization';
import PropTypes from 'prop-types';

import {POS_ORDER_STATUS} from 'utils/constants';

const OrderStatus = ({status}) => {
	const locale = localization.WatchTower.WatchTowerOrder.orderStatus;
	let badgeStatus, label;
	switch (status) {
	case 'done':
	case POS_ORDER_STATUS.DONE:
		badgeStatus = 'success';
		label = locale.done;
		break;
	case POS_ORDER_STATUS.CANCEL:
		badgeStatus = 'error';
		label = locale.cancel;
		break;
	case POS_ORDER_STATUS.VOID:
		badgeStatus = 'warning';
		label = locale.void;
		break;
	case 'on_progress':
	case POS_ORDER_STATUS.PROCESSED:
		badgeStatus = 'processing';
		label = locale.onProcess;
		break;
	case 'pending':
	case POS_ORDER_STATUS.PENDING:
	default:
		badgeStatus = 'default';
		label = locale.pending;
		break;
	}
	// Render
	return (
		<div><Badge status={badgeStatus} /> {label}</div>
	);
};

OrderStatus.propTypes = {
	status: PropTypes.string,
};

export default OrderStatus;
