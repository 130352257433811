import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {useLocation} from 'react-router-dom';

import useUnload from 'hooks/useUnload';
import {STORAGE_KEY} from 'utils/constants';

import OutletBrandFormHeader from './OutletBrandFormHeader';
import OutletBrandFormBody from './OutletBrandFormBody';

const OutletBrandForm = ({isEdit}) => {
	const {state} = useLocation();
	const itemRecord = state?.itemRecord || JSON.parse(sessionStorage.getItem(STORAGE_KEY.OUTLET_ITEM_DETAIL));

	useUnload(() => {
		sessionStorage.removeItem(STORAGE_KEY.MASTERLIST_ITEM_DETAIL);
	});

	useEffect(() => {
		return () => sessionStorage.removeItem(STORAGE_KEY.MASTERLIST_ITEM_DETAIL);
	}, []);

	return (
		<div className="bg-white h-full overflow-auto">
			<OutletBrandFormHeader
				isEdit={isEdit}
				itemRecord={itemRecord}
			/>
			<OutletBrandFormBody
				isEdit={isEdit}
				itemRecord={itemRecord}
			/>
		</div>
	);
};

OutletBrandForm.defaultProps = {
	isEdit: false,
};

OutletBrandForm.propTypes = {
	isEdit: PropTypes.bool,
};

export default OutletBrandForm;