import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import startCase from 'lodash/startCase';

import ProTable from '@ant-design/pro-table';

import {getTransaction} from 'utils/request/sales';
import {ellipsisItem, handleErrorFetch, momentDateFormat, localeCurrencyFormat, salesPayloadHandler} from 'utils/utils';

const FrestoTable = forwardRef(({metricFormRef, viewOrderDetail}, ref) => {
	const columns = [
		{
			width: 111,
			key: 'sales_date',
			dataIndex: 'sales_date',
			title: ellipsisItem('Sales Date'),
			render: sales_date => ellipsisItem(momentDateFormat({dateString: sales_date})),
		},
		{
			width: 111,
			key: 'created_at',
			dataIndex: 'created_at',
			title: ellipsisItem('Fresto Accepted Date'),
			render: created_at => ellipsisItem(momentDateFormat({dateString: created_at})),
		},
		{
			width: 126,
			key: 'sales_number',
			dataIndex: 'sales_number',
			title: ellipsisItem('Sales Number'),
			render: sales_number => ellipsisItem(sales_number),
		},
		{
			width: 124,
			key: 'bill_number',
			dataIndex: 'bill_number',
			title: ellipsisItem('Bill Number'),
			render: bill_number => ellipsisItem(bill_number),
		},
		{
			width: 124,
			key: 'payment_method',
			dataIndex: 'payment_method',
			title: ellipsisItem('Payment Method'),
			render: payment_method => ellipsisItem(payment_method),
		},
		{
			width: 127,
			key: 'branch',
			dataIndex: 'branch',
			title: ellipsisItem('Branch'),
			render: branch => ellipsisItem(branch),
		},
		{
			width: 140,
			key: 'payment_notes',
			dataIndex: 'payment_notes',
			title: ellipsisItem('Payment Notes'),
			render: payment_notes => ellipsisItem(payment_notes),
		},
		{
			width: 101,
			key: 'platform',
			dataIndex: 'platform',
			title: ellipsisItem('Platform'),
			render: platform => ellipsisItem(platform),
		},
		{
			width: 101,
			key: 'brand',
			dataIndex: 'brand',
			title: ellipsisItem('Brand'),
			render: brand => ellipsisItem(brand),
		},
		{
			width: 136,
			key: 'external_long_id',
			dataIndex: 'external_long_id',
			title: ellipsisItem('Long ID'),
			render: external_long_id => ellipsisItem(external_long_id),
		},
		{
			width: 103,
			key: 'external_short_id',
			dataIndex: 'external_short_id',
			title: ellipsisItem('Short ID'),
			render: external_short_id => ellipsisItem(external_short_id),
		},
		{
			width: 129,
			key: 'esb_subtotal_with_pb1',
			dataIndex: 'esb_subtotal_with_pb1',
			title: ellipsisItem('ESB Subtotal With PB1'),
			render: esb_subtotal_with_pb1 => ellipsisItem(localeCurrencyFormat({value: esb_subtotal_with_pb1})),
		},
		{
			width: 129,
			key: 'esb_order_fee',
			dataIndex: 'esb_order_fee',
			title: ellipsisItem('ESB Order Fee'),
			render: esb_order_fee => ellipsisItem(localeCurrencyFormat({value: esb_order_fee})),
		},
		{
			width: 129,
			key: 'esb_grand_total',
			dataIndex: 'esb_grand_total',
			title: ellipsisItem('ESB Grand Total'),
			render: esb_grand_total => ellipsisItem(localeCurrencyFormat({value: esb_grand_total})),
		},
		{
			width: 129,
			key: 'master_order_fee',
			dataIndex: 'master_order_fee',
			title: ellipsisItem('Master Order Fee'),
			render: master_order_fee => ellipsisItem(localeCurrencyFormat({value: master_order_fee})),
		},
		{
			width: 119,
			key: 'finc_grand_total_with_order_fee',
			dataIndex: 'finc_grand_total_with_order_fee',
			title: ellipsisItem('Finc Grand Total With order fee'),
			render: finc_grand_total_with_order_fee => ellipsisItem(localeCurrencyFormat({value: finc_grand_total_with_order_fee})),
		},
		{
			width: 100,
			key: 'platform_subtotal_with_pb1',
			dataIndex: 'platform_subtotal_with_pb1',
			title: ellipsisItem('Platform Subtotal With PB1'),
			render: platform_subtotal_with_pb1 => ellipsisItem(localeCurrencyFormat({value: platform_subtotal_with_pb1})),
		},
		{
			width: 141,
			key: 'platform_order_fee',
			dataIndex: 'platform_order_fee',
			title: ellipsisItem('Platform Order Fee'),
			render: platform_order_fee => ellipsisItem(localeCurrencyFormat({value: platform_order_fee})),
		},
		{
			width: 154,
			key: 'platform_grand_total',
			dataIndex: 'platform_grand_total',
			title: ellipsisItem('Platform Grand Total'),
			render: platform_grand_total => ellipsisItem(localeCurrencyFormat({value: platform_grand_total})),
		},
		{
			width: 135,
			key: 'platform_and_esb_grand_total_diff',
			dataIndex: 'platform_and_esb_grand_total_diff',
			title: ellipsisItem('Platform and Esb Grand Total Diff'),
			render: platform_and_esb_grand_total_diff => ellipsisItem(localeCurrencyFormat({value: platform_and_esb_grand_total_diff})),
		},
		{
			width: 129,
			key: 'platform_and_master_order_fee_diff',
			dataIndex: 'platform_and_master_order_fee_diff',
			title: ellipsisItem('Platform and Master Order Fee Diff'),
			render: platform_and_master_order_fee_diff => ellipsisItem(localeCurrencyFormat({value: platform_and_master_order_fee_diff})),
		},
		{
			width: 152,
			key: 'sales_status',
			dataIndex: 'sales_status',
			title: ellipsisItem('Sales Status'),
			render: sales_status => ellipsisItem(sales_status),
		},
		{
			width: 132,
			key: 'inject_status',
			dataIndex: 'inject_status',
			title: ellipsisItem('Inject Status'),
			render: inject_status => ellipsisItem(inject_status),
		},
		{
			width: 133,
			key: 'platform_order_status',
			dataIndex: 'platform_order_status',
			title: ellipsisItem('Platform Order Status'),
			render: platform_order_status => ellipsisItem(startCase(platform_order_status)),
		},
		{
			width: 116,
			key: 'linking_method',
			dataIndex: 'linking_method',
			title: ellipsisItem('Linking Method'),
			render: linking_method => ellipsisItem(linking_method),
		},
		{
			key: 'is_recon',
			dataIndex: 'is_recon',
			title: ellipsisItem('Is Recon'),
			render: is_recon => ellipsisItem(is_recon ? 'True' : 'False'),
		},
		{
			width: 116,
			key: 'bulk_file_id',
			dataIndex: 'bulk_file_id',
			title: ellipsisItem('Bulk File ID'),
			render: bulk_file_id => ellipsisItem(bulk_file_id),
		},
		{
			key: 'action',
			title: 'Action',
			align: 'center',
			fixed: 'right',
			width: 126,
			render: (_, record) => (
				<span
					className='cursor-pointer text-antd-blue-6'
					onClick={() => viewOrderDetail({
						orderItems: record?.order_items,
						posPayload: record?.pos_payload,
						external_short_id: record?.external_short_id,
						external_long_id: record?.external_long_id,
					})}>Detail</span>
			),
		},
	];

	const fetchTableData = async params => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */
			const metricPayload = salesPayloadHandler(metricFormRef.current?.getMetricFilter?.());

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				...metricPayload,
			};

			const response = await getTransaction(({params: payload}));
			return {
				data: response.data.transactions,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<ProTable
			actionRef={ref}
			className='SettlementTable py-4'
			columns={columns}
			options={false}
			search={false}
			scroll={{x: 'max-content'}}
			request={fetchTableData}
		/>
	);
});

FrestoTable.defaultProps = {
	metricFormRef: {},
	viewOrderDetail: () => null,
};

FrestoTable.propTypes = {
	metricFormRef: PropTypes.object,
	viewOrderDetail: PropTypes.func,
};

export default FrestoTable;