import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSort, faSortDown, faSortUp} from '@fortawesome/pro-solid-svg-icons';

import {Button, ConfigProvider, Divider, Modal, notification, Select, Tag} from 'antd';
import {ExclamationCircleOutlined, InfoCircleFilled} from '@ant-design/icons';
import ProTable from '@ant-design/pro-table';
import enUS from 'antd/lib/locale/en_US';
const {Option} = Select;

import {bulkResetRestoUserPin, getPosUsers, editPosUser, getUsers, updateUserDetails} from 'utils/request/user';
import {OUTLET_USER_MODAL_ACTION_TYPE} from 'utils/constants';

import cloneDeep from 'lodash/cloneDeep';
import clsx from 'clsx';

import localization from 'localization';
import {dateFormat, handleErrorFetch} from 'utils/utils';
const locale = localization.Outlet.UserActivation.UserActivationTable;

const defaultSearch = {
	menuLabel: null,
	menuCode: null,
	menuId: null,
	tags: null,
	createdBy: null,
	updatedBy: null,
	isActive: null,
};

const defaultQueryParams = {
	search: defaultSearch,
	page: 0,
	limit: 50,
	sortBy: 'updatedAt',
	sortOrder: 'desc',
};

const SINGLE_ACTION_TYPE = {
	RESET: 'reset_pin',
	LOCK: 'lock',
	UNLOCK: 'unlock',
};

const USER_STATUS = {
	ACTIVE: 'Active',
	LOCKED: 'Locked',
};

const UserActivationBody = ({openActionModal, openUserActionModal, refetchTableRef, isFresto}) => {
	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const resetRefFlag = useRef(false);
	const tableRef = useRef(null);

	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);

	const [selected, setSelected] = useState([]);
	const [lockable, setLockable] = useState([]);
	const [unlockable, setUnlockable] = useState([]);

	const {search, page, limit} = queryParams;

	// Function

	const handleSort = dataIndex => {
		const queryParamsTemp = cloneDeep(queryParams);

		let sortOrderTemp = '';
		if (queryParamsTemp.sortBy === dataIndex) {
			switch (queryParams.sortOrder) {
			case null:
				sortOrderTemp = 'asc';
				break;
			case 'asc':
				sortOrderTemp = 'desc';
				break;
			case 'desc':
				if (dataIndex === 'updatedAt') {
					sortOrderTemp = 'asc';
				} else {
					sortOrderTemp = null;
				}
				break;
			default:
				sortOrderTemp = null;
				break;
			}
		} else {
			sortOrderTemp = 'asc';
		}

		queryParamsTemp.page = 0;
		queryParamsTemp.sortOrder = sortOrderTemp;
		queryParamsTemp.sortBy = sortOrderTemp ? dataIndex : 'updatedAt';

		setQueryParams(queryParamsTemp);
	};

	const sortTableHeader = (text, dataIndex) => (
		<div className='flex items-center justify-between'>
			<div>{text}</div>
			<FontAwesomeIcon
				onClick={() => handleSort(dataIndex)}
				className='cursor-pointer text-custom-grey-light hover:text-aqua-dark transition-colors duration-300'
				icon={
					dataIndex === queryParams.sortBy
						? queryParams.sortOrder === 'asc' ? faSortUp : faSortDown
						: faSort
				} />
		</div>
	);

	const fetchTableData = async (config = {
		reset: false,
		clearSelected: false,
	}) => {
		try {
			setLoading(true);
			const {reset, clearSelected} = config;
			let tableDataResponse;
			const fetchFn = isFresto ? getPosUsers : getUsers;
			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit});
				tableDataResponse = await fetchFn({...defaultQueryParams, limit}, 'resto');
			} else {
				tableDataResponse = await fetchFn(queryParams);
			}
			if (clearSelected) {
				setSelected([]);
				setLockable([]);
				setUnlockable([]);
			}
			if (tableDataResponse.success) setTableData(tableDataResponse.data);
			else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			resetRefFlag.current = false;
			setLoading(false);
		}
	};

	const handleOnSelect = (record, selected) => {
		if (selected) { // Check
			setSelected(state => state.concat(record));
			if (!record.isActive) setUnlockable(state => state.concat(record.id));
			else setLockable(state => state.concat(record.id));
		} else { // Uncheck
			setSelected(state => state.filter(item => item.id !== record.id));
			if (!record.isActive) setUnlockable(state => state.filter(item => item !== record.id));
			else setLockable(state => state.filter(item => item !== record.id));
		}
	};

	const handleSelectAll = (selected, _, changeRows) => {
		if (selected) {
			const tempSelected = [];
			const tempUnlockable = [];
			const tempLockable = [];

			changeRows.forEach(item => {
				tempSelected.push(item);
				if (!item.isActive) tempUnlockable.push(item.id);
				else tempLockable.push(item.id);
			});

			setSelected(state => state.concat(tempSelected));
			setLockable(state => state.concat(tempLockable));
			setUnlockable(state => state.concat(tempUnlockable));
		} else {
			const idOfChangeRows = changeRows.map(item => item.id);
			setSelected(state => state.filter(item => !idOfChangeRows.includes(item.id)));
			setUnlockable(state => state.filter(item => !idOfChangeRows.includes(item)));
			setLockable(state => state.filter(item => !idOfChangeRows.includes(item)));
		}
	};

	const handleClearSelection = () => {
		setSelected([]);
		setLockable([]);
		setUnlockable([]);
	};

	const handleSingleUserAction = (args = {
		userData: {},
		actionType: SINGLE_ACTION_TYPE.RESET,
	}) => {
		const {userData, actionType} = args;
		if (!actionType) return;

		let actionLocale = null;

		switch (actionType) {
		case SINGLE_ACTION_TYPE.RESET:
			actionLocale = locale.Actions.Reset;
			break;
		case SINGLE_ACTION_TYPE.LOCK:
			actionLocale = locale.Actions.Lock;
			break;
		case SINGLE_ACTION_TYPE.UNLOCK:
			actionLocale = locale.Actions.Unlock;
			break;
		default:
			actionLocale = locale.Actions.Reset;
			break;
		}

		Modal.confirm({
			title: actionLocale.title,
			icon: <ExclamationCircleOutlined />,
			content: actionLocale.Single.description.replace('{{username}}', userData.username),
			centered: true,
			okText: actionLocale.ok,
			onOk: async () => {
				const fetchFn = async () => {
					try {
						setLoading(true);
						const isReset = actionType === SINGLE_ACTION_TYPE.RESET;

						let payload = isReset
							? {userId: [userData.id]}
							: {id: userData.id};

						const fetchCall = isReset ? bulkResetRestoUserPin : isFresto ? editPosUser : updateUserDetails;

						if (!isReset) {
							if (actionType === SINGLE_ACTION_TYPE.LOCK) {
								if (isFresto) payload.isActive = false;
								else payload.isLocked = true;
							} else {
								if (isFresto) payload.isActive = true;
								else payload.isLocked = false;
							}
						}

						const response = await fetchCall(payload);

						if (response.success) {
							fetchTableData();
							notification.success({
								message: actionLocale.Single.success.replace('{{username}}', userData.username),
							});
						} else {
							throw {};
						}
					} catch (error) {
						notification.error({
							message: actionLocale.Single.failed.replace('{{username}}', userData.username),
						});
					} finally {
						setLoading(false);
					}
				};
				await fetchFn();
			},
			okButtonProps: {danger: true},
			cancelText: actionLocale.cancel,
		});
	};

	const tableAlertRender = () => (
		<div className='flex gap-3 items-center'>
			<InfoCircleFilled className='text-antd-blue-6' />
			{locale.TableAlert.info.replace('{{userCount}}', selected.length)}
		</div>
	);

	const handleChange = pagers => {
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams({...queryParams, ...variables});
	};

	const handleBulkAction = (args = {type: SINGLE_ACTION_TYPE.LOCK}) => {
		const isLock = args.type === SINGLE_ACTION_TYPE.LOCK;
		const actionLocale = isLock ? locale.Actions.Lock : locale.Actions.Unlock;
		Modal.confirm({
			title: actionLocale.title,
			icon: <ExclamationCircleOutlined />,
			content: actionLocale.Bulk.description,
			centered: true,
			cancelText: actionLocale.cancel,
			onOk: async () => {
				const arrayToMap = isLock ? lockable : unlockable;
				const payloads = arrayToMap.map(userId => {
					return {
						id: userId,
						isActive: isLock ? false : true,
					};
				});

				const fetchFn = async () => {
					setLoading(true);
					payloads.forEach(async payload => {
						try {
							await editPosUser(payload);
						} catch (error) {
							handleErrorFetch(error);
						}
					});
					setLoading(false);

					await new Promise(resolve => {
						setTimeout(resolve, 100);
					});
					fetchTableData({clearSelected: true});
				};

				await fetchFn();
			},
		  });
	};

	const tableAlertOptionRender = () => (
		<div className='flex gap-2 items-center'>
			<div
				onClick={handleClearSelection}
				className='text-antd-blue-6 px-2 cursor-pointer'>{locale.TableAlert.clear}</div>
			{
				isFresto
					? (
						<>
							<Button
								disabled={lockable.length <= 0}
								danger
								onClick={() => handleBulkAction({type: SINGLE_ACTION_TYPE.LOCK})}
								type='primary'
								size='small'>{locale.TableAlert.lock.replace('{{userCount}}', lockable.length)}</Button>
							<Button
								onClick={() => handleBulkAction({type: SINGLE_ACTION_TYPE.UNLOCK})}
								disabled={unlockable.length <= 0}
								size='small'>{locale.TableAlert.unlock.replace('{{userCount}}', unlockable.length)}</Button>
						</>
					)
					: (
						<Button
							onClick={() => openActionModal({type: OUTLET_USER_MODAL_ACTION_TYPE.RESET, users: selected})}
							type='primary'
							size='small'>
							{locale.TableAlert.resetPin.replace('{{userCount}}', selected.length)}
						</Button>
					)
			}
		</div>
	);

	// Config

	const tableColumn = [
		{
			title: 'Username',
			dataIndex: 'username',
			// dataIndex: ['user', 'username'],
			key: 'username',
			// render: username => username?.split(':').pop(),
		},
		{
			title: 'POS Role',
			dataIndex: ['roles'],
			key: 'roles',
			render: (_, record) => <div>{isFresto ? record?.roles?.join?.(', ') : record?.details?.roles?.join?.(', ')}</div>,
		},
		{
			title: 'Location',
			dataIndex: ['Location', 'label'],
			key: 'locationLabel',
		},
		{
			title: 'Updated By',
			dataIndex: ['updater', 'username'],
			key: 'updatedBy',
		},
		{
			// title: 'Last Update',
			title: () => sortTableHeader('Updated At', 'updatedAt'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			search: false,
			width: 160,
			render: item => <div>{dateFormat(item)}</div>,
		},
		{
			// title: 'Last Login',
			title: () => sortTableHeader('Last Login', 'lastLoginAt'),
			dataIndex: 'lastLoginAt',
			key: 'lastLoginAt',
			search: false,
			width: 160,
			render: item => <div>{item === '-' ? '-' : dateFormat(item)}</div>,
		},
		{
			title: 'Status',
			key: 'status',
			width: 100,
			renderFormItem: () => (
				<Select placeholder='Please select'>
					{
						Object.values(USER_STATUS).map(status => (
							<Option
								key={status}
								value={status}>{status}</Option>
						))
					}
				</Select>
			),
			render: (_, item) => (
				<div>
					{
						item.isActive
							?
							item.isLocked
								? <Tag color="red">Locked</Tag>
								: <Tag color="green">Active</Tag>
							: <Tag>Inactive</Tag>
					}
				</div>
			),
		},
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			fixed: 'right',
			search: false,
			width: 197,
			render: (_, item) => (
				<div className='flex items-center justify-start gap-2'>
					{
						<>
							{
								isFresto && (
									<>
										<div
											onClick={() => openUserActionModal({
												id: item.id,
												username: item?.username,
												roles: item?.roles,
												locations: [{id: item?.locationId, label: item?.Location?.label}],
											})}
											className='cursor-pointer text-antd-blue-6'>Edit</div>
										<Divider
											className='m-0'
											type='vertical' />
										<div
											onClick={() => openUserActionModal({
												id: item.id,
												username: item?.username,
												roles: item?.roles,
												locations: [{id: item?.locationId, label: item?.Location?.label}],
											}, true)}
											className='cursor-pointer text-antd-blue-6 flex-nowrap'>Reset Pin</div>
										<Divider
											className='m-0'
											type='vertical' />
									</>
								)
							}
							<div
								onClick={() => handleSingleUserAction(
									{userData: item,
										actionType: isFresto ? !item.isActive : item.isLocked
											? SINGLE_ACTION_TYPE.UNLOCK
											: SINGLE_ACTION_TYPE.LOCK},
								)}
								className={clsx('cursor-pointer',
									isFresto
										? !item.isActive ? 'text-antd-blue-6' : 'text-antd-red-6'
										: item.isLocked ? 'text-antd-blue-6' : 'text-antd-red-6')}>
								{
									isFresto
										? !item.isActive
											? 'Unlock'
											: 'Lock'
										: item.isLocked
											? 'Unlock'
											: 'Lock'
								}
							</div>
						</>
					}
				</div>
			),
		},
	];

	const rowSelection = {
		selectedRowKeys: selected.map(user => user.id),
		onSelectAll: handleSelectAll,
		onSelect: handleOnSelect,
		getCheckboxProps: record => ({
			// disabled: record.isActive === false,
			name: record.username,
		}),
	};

	// Lifecycle

	useEffect(() => {
		if (resetRefFlag.current) return;
		refetchTableRef.current = fetchTableData;
		(async () => await fetchTableData())();
	}, [search, page, limit]);

	useEffect(() => {
		setQueryParams(defaultQueryParams);
		tableRef?.current?.reset();
		(async () => await fetchTableData())();
	}, [isFresto]);

	return (
		<div>
			<ConfigProvider locale={enUS}>
				<ProTable
					actionRef={tableRef}
					rowKey='id'
					id='outlet-user-table'
					loading={loading}
					pagination={{
						defaultPageSize: limit,
						current: page + 1,
						total: tableData?.count,
						showSizeChanger: true,
						pageSizeOptions: ['5', '10', '20', '50', '100'],
						showQuickJumper: true,
					}}
					onChange={handleChange}
					onSubmit={params => {
						Object.keys(params).forEach(k => !params[k] && delete params[k]);

						if (params.status === USER_STATUS.ACTIVE) params.isActive = true;
						if (params.status === USER_STATUS.LOCKED) params.isLocked = true;
						delete params.status;

						const queryParamsTemp = cloneDeep(defaultQueryParams);
						queryParamsTemp.search = {...defaultSearch, ...params};
						queryParamsTemp.limit = queryParams.limit;
						setQueryParams(queryParamsTemp);
					}}
					options={false}
					dataSource={tableData?.rows}
					columns={tableColumn}
					rowSelection={rowSelection}
					tableAlertRender={tableAlertRender}
					tableAlertOptionRender={tableAlertOptionRender}
					onReset={() => fetchTableData({reset: true})}
				/>
			</ConfigProvider>
		</div>
	);
};

UserActivationBody.defaultProps = {
	openActionModal: () => null,
	refetchTableRef: {},
	openUserActionModal: () => null,
	isFresto: false,
};

UserActivationBody.propTypes = {
	openActionModal: PropTypes.func,
	refetchTableRef: PropTypes.object,
	openUserActionModal: PropTypes.func,
	isFresto: PropTypes.bool,
};

export default UserActivationBody;