import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import moment from 'moment';

import {Card, Typography} from 'antd';
import {ProFormText, QueryFilter, ProFormDateRangePicker, ProFormSelect} from '@ant-design/pro-form';

import {handleErrorFetch, localeCurrencyFormat} from 'utils/utils';
import {getOutletData} from 'utils/request/outlet';
import {OUTLET_TYPE} from 'utils/constants';

const Metric = forwardRef(({tableFormRef}, ref) => {
	const queryRef = useRef();
	const salesDropdownOption = useSelector(state => state.salesDropdownOption);

	const [locationList, setLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

	const [metricData, setMetricData] = useState({
		sum_subtotal: 0,
		sum_total_normal_price: 0,
		sum_pricecut: 0,
		sum_sales_before_tax: 0,
	});

	const metricCards = [
		{label: 'Subtotal', value: localeCurrencyFormat({value: metricData.sum_subtotal, withDecimal: true})},
		{label: 'Total normal price', value: localeCurrencyFormat({value: metricData.sum_total_normal_price, withDecimal: true})},
		{label: 'Pricecut', value: localeCurrencyFormat({value: metricData.sum_pricecut, withDecimal: true})},
		{label: 'Sales Before Tax', value: localeCurrencyFormat({value: metricData.sum_sales_before_tax, withDecimal: true})},
	];

	// Methods
	const refetchData = () => {
		tableFormRef.current?.reload?.();
	};
	const resetForm = () => {
		queryRef?.current?.resetFields?.();
		tableFormRef.current?.reloadAndRest();
	};
	const getFilterPayload = () => {
		return queryRef.current?.getFieldsValue?.();
	};

	const getLocation = async outletLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	const getDefaultDate = () => {
		const today = moment();
		const yesterday = moment(today).subtract(1, 'day');
		return [yesterday.startOf('day'), yesterday.endOf('day')];
	};

	useImperativeHandle(ref, () => ({
		getMetricFilter() {
			return getFilterPayload();
		},
		updateMetricData(data) {
			setMetricData(data);
		},
	}));

	return (
		<div>
			<QueryFilter
				formRef={queryRef}
				className="p-6 pb-0 my-4 border border-antd-netural-4 bg-antd-netural-2 rounded-md"
				layout="vertical"
				onFinish={() => refetchData()}
				onReset={() => resetForm()}
				initialValues={{
					sales_date: getDefaultDate(),
				}}
			>
				<ProFormDateRangePicker
					name="sales_date"
					label="Sales Date"
				/>
				<ProFormSelect
					mode='multiple'
					name="branch"
					label="Branch"
					showSearch
					fieldProps={{
						onSearch: debounceFn,
						loading: locationLoading,
						showArrow: true,
					}}
					options={locationList.map(location => location.label)}
				/>
				<ProFormText
					name="sales_number"
					label="Sales Number"
				/>
				<ProFormSelect
					mode='multiple'
					name="payment_method"
					label="Payment Method"
					fieldProps={{showArrow: true}}
					options={salesDropdownOption?.payment_method_others?.sort?.()}
				/>
			</QueryFilter>
			<div>
				<div className='flex gap-6'>
					{metricCards.map(({label, value}) => (
						<div
							className='flex-1'
							key={label}>
							<Card className="metricCard rounded-md">
								<Typography.Text
									className='text-sm'
									type="secondary">
									{label}
								</Typography.Text>
								<div className="h-2" />
								<Typography.Text className='text-2xl'>
									{value}
								</Typography.Text>
							</Card>
						</div>
					))}
				</div>
			</div>
		</div>
	);
});

Metric.defaultProps = {
	tableFormRef: {},
};

Metric.propTypes = {
	tableFormRef: PropTypes.object,
};

export default Metric;