import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Modal, notification} from 'antd';

import UploadProgress from 'components/UploadProgress';
import UploadResults from './UploadResults';

import {getUploadFileSummary, getUploadSignedURL, submitToOrderhub, updateMasterlist, uploadMasterMenu} from 'utils/request/masterlist';
import {getLabel, getSignedURLUploadType, handleErrorFetch} from 'utils/utils';

import localization from 'localization';
const locale = localization.Global.ImportCSVOverlay;

const ImportCSVOverlay = ({
	visible, close, uploadedFile, handleFileUpload, refetchTable, masterlistType, importType, isChild, uploadParams, fileType,
}) => {
	const [importPhase, setImportPhase] = useState(1);

	const [uploadId, setUploadId] = useState(null);
	const [uploadSuccess, setUploadSuccess] = useState(null);
	const [uploadData, setUploadData] = useState({
		rows: [],
		errorRows: [],
	});

	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const isTax = ['pos', 'settlement'].some(type => type === masterlistType);

	const handleUpload = async () => {
		try {
			setImportPhase(1);

			const signedUrlPayload = {
				uploadType: getSignedURLUploadType(masterlistType, importType),
				filename: uploadedFile.name,
			};
			if (isTax) signedUrlPayload.fileType = 'xlsx';
			if (fileType) signedUrlPayload.fileType = fileType;

			const {signedURL, uploadId} = await getUploadSignedURL(signedUrlPayload);

			setUploadId(uploadId);

			await uploadMasterMenu(signedURL, uploadedFile);

			const uploadCall = importType === 'add' ? getUploadFileSummary : updateMasterlist;

			const {success, data, error} = await uploadCall({uploadId, masterlistType, isChild, importType, uploadParams});

			if (success) {
				if (isTax) {
					notification.success({
						message: `${masterlistType === 'pos' ? 'Pairing' : 'Settlement'} file has been successfully imported`,
					});
					beforeClose();
				} else {
					setUploadSuccess(data?.errorRows?.length ? false : true);
					setUploadData(data);
					setImportPhase(2);
				}
			} else {
				throw error;
			}
		} catch (error) {
			handleErrorFetch(error, {
				message: locale.Notification.failedMessage,
				description: error,
			});
			beforeClose();
		}
	};

	useEffect(async() => {
		if (!visible) return;
		handleUpload();
	}, [visible]);

	const beforeClose = () => {
		close();
		setUploadId(null);
		setUploadSuccess(null);
		setUploadData({
			rows: [],
			errorRows: [],
		});
	};

	const handleSubmit = async (labelName = null) => {
		try {
			setLoadingSubmit(true);
			const response = await submitToOrderhub(uploadId, masterlistType, importType, labelName, isChild);
			if (response) {
				refetchTable();
				notification.open({
					message: locale.Notification.successMessage,
					description: `${uploadData.rows.length} ${getLabel(masterlistType)}${uploadData.rows.length > 1 ? 's' : ''} ${locale.Notification.successDescription}`,
					type: 'success',
				});
				beforeClose();
			}
		} catch (error) {
			notification.open({
				message: locale.Notification.failedMessage,
				description: locale.Notification.failedDescription,
				type: 'warning',
			});
		} finally {
			setLoadingSubmit(false);
		}
	};

	const initialState = () => (
		<div className='flex items-center justify-center animate-pulse title-bold-16 text-gray-700'>{locale.preparing}</div>
	);

	const renderContent = () => {
		switch (importPhase) {
		case 0:
			return initialState();
		case 1:
			return (<UploadProgress />);
		case 2:
			return (
				<UploadResults
					masterlistType={masterlistType}
					importType={importType}
					handleFileUpload={handleFileUpload}
					success={uploadSuccess}
					uploadData={uploadData}
					loading={loadingSubmit}
					submit={handleSubmit}
					close={beforeClose}
					displayLabelInput={masterlistType === 'menu-template'}
				/>
			);
		default:
			return (<UploadProgress />);
		}
	};

	return (
		<Modal
			visible={visible}
			title=''
			footer={null}
			closable={false}
			centered
			width={importPhase === 2 ? 856 : 566}
		>
			{renderContent()}
		</Modal>
	);
};

ImportCSVOverlay.defaultProps = {
	visible: false,
	close: () => null,
	uploadedFile: {},
	handleFileUpload: () => null,
	refetchTable: () => null,
	masterlistType: 'menu',
	importType: 'export',
	isChild: false,
	uploadParams: {},
	fileType: undefined,
};

ImportCSVOverlay.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	uploadedFile: PropTypes.object,
	handleFileUpload: PropTypes.func,
	refetchTable: PropTypes.func,
	masterlistType: PropTypes.string,
	importType: PropTypes.string,
	isChild: PropTypes.bool,
	uploadParams: PropTypes.object,
	fileType: PropTypes.string,
};

export default ImportCSVOverlay;
