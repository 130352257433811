import React, {useRef} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {Badge, Divider, Modal, notification} from 'antd';
import ProTable from '@ant-design/pro-table';

import {handleErrorFetch} from 'utils/utils';
import {deleteTrainingSession, getTrainingList} from 'utils/request/outlet';

import './TrainingSessionList.less';

import localization from 'localization';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
const locale = localization.Outlet.Training.List.Table;

const Table = ({outletList, debounceFn, outletListLoading}) => {
	const tableRef = useRef();

	const goToSessionPage = sessionId => {
		window.open(`/outlet/training-session/${sessionId}`, '_blank');
	};

	const sortDirectionConverter = direction => {
		switch (direction) {
		case 'ascend':
			return 'asc';
		case 'descend':
			return 'desc';
		default:
			return 'desc';
		}
	};

	const showDelete = sessionId => {
		Modal.confirm({
			centered: true,
			title: locale.DeleteSession.title,
			icon: <ExclamationCircleOutlined />,
			content: locale.DeleteSession.message,
			okButtonProps: {
				danger: true,
				icon: <DeleteOutlined />,
			},
			onOk () {
				const apiCall = async () => {
					try {
						const response = await deleteTrainingSession({id: String(sessionId)});
						if (response.success) {
							tableRef?.current?.reload?.();
							notification.success({
								message: locale.DeleteSession.successTitle,
							});
						}
					} catch (error) {
						handleErrorFetch(error);
					}
				};

				apiCall();
			},
			okText: locale.DeleteSession.okText,
			cancelText: locale.DeleteSession.cancelText,
		});
	};

	const columns = [
		{
			title: locale.sessionId,
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: locale.location,
			dataIndex: ['Location', 'label'],
			key: 'locationId',
			valueType: 'select',
			fieldProps: {
				showSearch: true,
				loading: outletListLoading,
				onSearch: debounceFn,
				options: outletList.map(outlet => {
					return {
						label: outlet.label,
						value: outlet.id,
					};
				}),
			},
		},
		{
			title: locale.createdBy,
			dataIndex: ['creator', 'username'],
			key: 'createdBy',
		},
		{
			title: locale.createdAt,
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (_, {createdAt}) => moment(createdAt).format('DD MMM YYYY - HH:mm'),
			valueType: 'dateRange',
			sorter: true,
		},
		{
			title: locale.completedAt,
			dataIndex: 'completedAt',
			key: 'completedAt',
			search: false,
			render: completedAt => (completedAt === null || completedAt === '-')
				? '-'
				: moment(completedAt).format('DD MMM YYYY - HH:mm'),
			sorter: true,
		},
		{
			title: locale.status,
			dataIndex: 'isCompleted',
			key: 'isCompleted',
			search: false,
			render: isCompleted => (
				<span>
					<Badge status={isCompleted ? 'success' : 'error'} />
					{isCompleted ? locale.complete : locale.incomplete}
				</span>
			),
			filterMultiple: false,
			filters: [
				{
					text: locale.complete,
					value: true,
				},
				{
					text: locale.incomplete,
					value: false,
				},
			],
		},
		{
			title: locale.action,
			key: 'action',
			search: false,
			align: 'center',
			render: (_, record) => (
				<div
					className='flex items-center justify-center'>
					<span
						onClick={() => goToSessionPage(record.id)}
						className='text-antd-blue-6 cursor-pointer'>{locale.detail}</span>
					{
						record.isCompleted ? null : (
							<>
								<Divider type='vertical' />
								<span
									onClick={() => showDelete(record.id)}
									className='text-antd-red-6 cursor-pointer'>{locale.delete}</span>
							</>
						)
					}
				</div>
			),
		},
	];

	const fetchTableData = async (params, sorter, filters) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				sortBy: Object.keys(sorter)?.[0] || 'createdAt',
				sortOrder: sortDirectionConverter(Object.values(sorter)?.[0] || 'desc'),
				isCompleted: filters?.isCompleted?.join?.(),
				createdAtFrom: params?.createdAt?.[0],
				createdAtTo: params?.createdAt?.[1],
				createdBy: params?.createdBy,
				id: params?.id,
				locationId: params?.locationId,
			};
			const response = await getTrainingList(payload);

			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<div className='px-6'>
			<ProTable
				actionRef={tableRef}
				className='TrainingSessionList'
				rowKey='id'
				columns={columns}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</div>
	);
};

Table.defaultProps = {
	outletList: [],
	debounceFn: () => null,
	outletListLoading: false,
};

Table.propTypes = {
	outletList: PropTypes.array,
	debounceFn: PropTypes.func,
	outletListLoading: PropTypes.bool,
};

export default Table;