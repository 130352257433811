import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {getMasterlistItem} from 'utils/request/masterlist';
import {handleErrorFetch, moneyFormat} from 'utils/utils';

import debounce from 'lodash/debounce';

import {Button, Input, Modal, Popconfirm, Table, Tag} from 'antd';
const {Search} = Input;

import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm.ManageCategory.SelectMenuModal;

const TABLE_DATA_COUNT = 20;

const SelectMenuModal = ({visible, onClose, handleManageMenu, isEdit}) => {
	const [menus, setMenus] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tablePage, setTablePage] = useState(0); //starts from 0
	const [menuLabel, setMenuLabel] = useState(null);

	const fetchMenus = async () => {
		try {
			setLoading(true);
			const payload = {
				limit: TABLE_DATA_COUNT,
				sortBy: 'updatedAt',
				sortOrder: 'desc',
				isActive: true,
				offset: tablePage * TABLE_DATA_COUNT,
				searchString: menuLabel,
			};
			const response = await getMasterlistItem(payload, 'menu');
			if (response.success) {
				setMenus(response.data);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const SpanWrapper = props => (
		<span
			// eslint-disable-next-line react/prop-types
			onClick={() => props?.clickAction?.()}
			{...props} />
	);

	const PopconfirmWrapper = props => (
		<Popconfirm
			title={(<div className='w-56'>{locale.changeConfirmation}</div>)}
			// eslint-disable-next-line react/prop-types
			onConfirm={() => props?.clickAction?.()}
			{...props} />
	);

	const ActionWrapper = isEdit ? PopconfirmWrapper : SpanWrapper;

	const beforeSelectMenu = record => {
		handleManageMenu(record);
		onClose();
	};

	const debouncedChangeHandler = useCallback(
		debounce(inputtedLabel => {
			setTablePage(0);
			setMenuLabel(inputtedLabel);
		}, 500)
		, []);

	const columns = [
		{
			title: 'Menu ID',
			dataIndex: 'id',
			key: 'id',
			width: 100,
		},
		{
			title: 'Menu Name',
			dataIndex: 'menuLabel',
			key: 'menuLabel',
		},
		{
			title: 'Tags',
			dataIndex: 'tags',
			key: 'tags',
			render: tags => (Array.isArray(tags) && tags.length) ? (
				<div className='flex flex-wrap gap-y-2'>
					{tags.map((tag, index) => (
						<Tag
							color='blue'
							className='uppercase'
							key={index}>{tag}
						</Tag>
					))}
				</div>
			) : '-',
		},
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
			render: price => (
				<div>{moneyFormat({value: price})}</div>
			),
		},
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			width: 120,
			render: record => (
				<div className="text-blue-400">
					<ActionWrapper clickAction={() => beforeSelectMenu(record)}>
						<span className='cursor-pointer'>{locale.select}</span>
					</ActionWrapper>
				</div>
			),
		},
	];

	const handleTableChange = ({current}) => {
		setTablePage(current - 1);
	};

	useEffect(() => {
		if (!visible) return;
		setMenuLabel('');
		setTablePage(0);
	}, [visible]);

	useEffect(() => {
		if (!visible) return;
		fetchMenus();
	}, [tablePage, menuLabel]);

	return (
		<Modal
			destroyOnClose={true}
			visible={visible}
			onCancel={onClose}
			title={locale.title}
			footer={null}
			centered
			width={965}
		>
			<div className='flex flex-col gap-4'>
				<Search
					loading={loading}
					placeholder={locale.searchPlaceholder}
					onChange={e => debouncedChangeHandler(e.target.value)}
					onSearch={debouncedChangeHandler}
					style={{width: 264}} />
				<Table
					loading={loading}
					columns={columns}
					dataSource={menus?.rows}
					scroll={{y: 400}}
					pagination={{
						defaultPageSize: 20,
						total: menus?.count,
						showSizeChanger: false,
						current: tablePage + 1,
					}}
					onChange={handleTableChange}
				/>
				<div className='flex justify-end'>
					<Button onClick={onClose}>{locale.cancel}</Button>
				</div>
			</div>
		</Modal>
	);
};

SelectMenuModal.defaultProps = {
	visible: false,
	onClose: () => null,
	handleManageMenu: () => null,
	isEdit: false,
};

SelectMenuModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	handleManageMenu: PropTypes.func,
	isEdit: PropTypes.bool,
};

export default SelectMenuModal;