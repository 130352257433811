import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {Divider, Drawer} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import Context from '../../Context';

const MenuOutletList = ({open, onClose, title, locationIds}) => {
	const {locationList} = useContext(Context);

	return (
		<Drawer
			open={open}
			onClose={onClose}
			maskClosable={true}
			title={(
				<div className='flex justify-between'>
					<div>{title}</div>
					<CloseOutlined
						className='cursor-pointer'
						onClick={onClose} />
				</div>
			)}
			closable={false}
			bodyStyle={{
				padding: 0,
			}}
		>
			<div className='flex flex-col gap-3'>
				{locationIds.map(locationId => (
					<React.Fragment
						key={locationId}>
						<div className='px-6'>{locationList.find(location => location.id == locationId)?.label}</div>
						<Divider className='m-0' />
					</React.Fragment>
				))}
			</div>
		</Drawer>
	);
};

MenuOutletList.defaultProps = {
	open: false,
	onClose: () => null,
	title: null,
	locationIds: [],
};

MenuOutletList.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string,
	locationIds: PropTypes.array,
};

export default MenuOutletList;