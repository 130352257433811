import React, {useCallback, useEffect, useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {createMasterlistItem, editMasterlistItem, getMasterlistItem} from 'utils/request/masterlist';
import {handleErrorFetch, inputNumberCurrencyFormatter, moneyFormat} from 'utils/utils';
import {PACKAGING_OPTIONS} from 'utils/constants';

import {DeleteOutlined, MenuOutlined} from '@ant-design/icons';
import {Button, Form, Input, InputNumber, notification, Radio, Select, Typography} from 'antd';
const {Option} = Select;

import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';

import localization from 'localization';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
const locale = localization.MasterlistPage.MasterlistForm.OptionGroupForm;
const actionLocale = localization.MasterlistPage.MasterlistForm.action;

const MASTERLIST_PACKAGING_OPTION = [PACKAGING_OPTIONS.DEFAULT, PACKAGING_OPTIONS.SEPARATE, PACKAGING_OPTIONS.HIDE];

const OptionGroupForm = ({isEdit, itemRecord}) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const childMenuWatcher = Form.useWatch('details', form);

	const [isLoading, setIsLoading] = useState(false);
	const [menuList, setMenuList] = useState([]);

	const [isFetching, setIsFetching] = useState(false);
	const labelRef = useRef(null);

	const [hasMore, setHasMore] = useState(false);

	const handleSubmit = async e => {
		try {
			setIsLoading(true);
			e.details = e.details.filter(menu => menu.id);
			e.details = e.details.map(menu => {
				return {
					menuId: Number(menu.id),
					menuPrice: menu.price,
					menuLabel: menu.menuLabel,
				};
			});

			const payload = {
				label: e.label,
				tags: e.tags,
				description: e.description,
				minSelection: e.minSelection,
				maxSelection: e.maxSelection,
				isActive: e.isActive === 1 ? true : false,
				details: {
					menus: e.details,
					packaging: {
						type: e.packagingType || PACKAGING_OPTIONS.DEFAULT,
					},
				},
			};

			const fetchFn = isEdit ? editMasterlistItem : createMasterlistItem;
			if (isEdit) payload.optionGroupId = itemRecord.id;
			const response = await fetchFn(payload, 'option-group');
			if (response.success) {
				const notificationLocale = isEdit ? actionLocale.EditSuccess : actionLocale.CreateSuccess;
				navigate('/masterlist/option-group');
				notification.open({
					message: notificationLocale.title,
					description: notificationLocale.description.replace('{{type}}', 'option group'),
					type: 'success',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}

	};

	const searchMenu = async (loadMore = false) => {
		try {
			setIsFetching(true);
			const FETCH_LIMIT = 10;
			const payload ={
				sortBy: 'updatedAt',
				sortOrder: 'desc',
				isActive: true,
				isChildMenu: true,
				searchString: labelRef?.current || '',
				limit: FETCH_LIMIT,
			};
			if (loadMore) payload.offset = menuList.length ? menuList.length : 0;
			const response = await getMasterlistItem(payload, 'menu');
			if (response.success) {
				let hasMoreTemp = true;

				if (loadMore) {
					const tempMenuList = cloneDeep(menuList);
					setMenuList(tempMenuList.concat(response.data.rows));
					if (tempMenuList.concat(response.data.rows).length >= response.data.count) hasMoreTemp = false;

				} else {

					setMenuList(response.data.rows);
					if (response.data.count <= 10) hasMoreTemp = false;
				}

				setHasMore(hasMoreTemp);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsFetching(false);
		}
	};

	const debouncedChangeHandler = useCallback(
		debounce(inputtedLabel => {
			/**
			 * 1. labelRef used to capture search value to be use in search and load more on scroll
			 * 2. labelRef value dont need to be clear because when the option dropdown is close, onSearch prop on Select component
			 * 		which bind to this function will be trigger and have empty string as the value.
			 */
			labelRef.current = inputtedLabel;
			searchMenu();
		}, 500)
		, []);

	const handleMenuChange = (value, index) => {
		const fields = form.getFieldsValue();
		const {details} = fields;
		details[index] = menuList.find(menu => menu.id === value);
		form.setFieldsValue({details});
	};

	const filterMenu = menuArr => {
		if (menuArr?.length) {
			const fields = form.getFieldsValue();
			const output = menuArr.filter(item1 => !fields.details.some(item2 => item2?.id === item1?.id));
			return output;
		} else {
			return [];
		}
	};

	const handleLoadMore = e => {
		// Detect if its not loading and scroll on bottom
		if (
			hasMore &&
			!isFetching &&
			e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight
		) searchMenu(true);
	};

	const handleDragEnd = result => {
		if (!result.destination) return;

		const fields = form.getFieldsValue();
		const {details: detailsTemp} = fields;

		const [reorderedItem] = detailsTemp.splice(result.source.index, 1);
		detailsTemp.splice(result.destination.index, 0, reorderedItem);

		form.setFieldsValue({
			details: detailsTemp,
		});
	};

	useEffect(() => {
		searchMenu();
		if (isEdit) {
			form.setFieldsValue({
				isActive: itemRecord.isActive ? 1 : 0,
				label: itemRecord.label,
				tags: itemRecord.tags || [],
				minSelection: itemRecord.minSelection,
				maxSelection: itemRecord.maxSelection,
				packagingType: itemRecord?.details?.packaging?.type,
				details: itemRecord?.details?.menus?.map(item => {
					return {
						id: Number(item.menuId),
						price: item.price || item.menuPrice || 0,
						menuLabel: item.menuLabel,
					};
				}) || [],
			});
		}
	}, []);

	return (
		<Form
			form={form}
			layout="vertical"
			name="optionGroupForm"
			scrollToFirstError
			onFinish={e => !isLoading && handleSubmit(e)}
			requiredMark={false}
			initialValues={{
				isActive: 1,
			}}
		>
			<Form.Item
				label={locale.optionLabel}
				name="label"
				rules={[{required: true, message: locale.optionLabelRequired}]}
			>
				<Input
					className='w-96'
					placeholder={locale.optionLabelPlaceholder}
				/>
			</Form.Item>
			<Form.Item
				label={locale.tagsLabel}
				name="tags">
				<Select
					placeholder={locale.tagsLabelPlaceholder}
					className='w-96'
					open={false}
					mode='tags'
					tokenSeparators={[',']}
				/>
			</Form.Item>
			<Form.List
				name="details"
				rules={[
					{
						required: true,
						validator: async (_, childMenu) => {
							if (!childMenu || !childMenu.length)
								return Promise.reject('Add Child Menu');
							return Promise.resolve();
						},
					},
				]}
			>
				{(fields, {add, remove}, {errors}) => (
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId='childsMenu'>
							{provided => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									className='flex flex-col'>
									{
										fields.map(({name, key}, index) => (
											<Draggable
												isDragDisabled={childMenuWatcher[index] ? false : true}
												key={`${index}`}
												draggableId={`${index}`}
												index={`${index}`}>
												{
													provided => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															dragHandleProps={provided.dragHandleProps}
															key={index}
															className='flex flex-1'>
															<div
																{...provided.dragHandleProps}
																className={`${childMenuWatcher[index] ? 'cursor-pointer' : 'cursor-not-allowed opacity-20'} flex justify-center items-center pl-2 pr-6`}>
																<MenuOutlined />
															</div>
															<div
																className="flex flex-1 gap-6 max-w-4xl">
																<Form.Item
																	label={locale.ChildMenuRow.name.replace('{{order}}', index + 1)}
																	name={[name, 'menuLabel']}
																	className='flex-1'
																>
																	<Select
																		showSearch
																		loading={isFetching}
																		onDropdownVisibleChange={open => open && searchMenu()}
																		onSearch={debouncedChangeHandler}
																		placeholder={locale.ChildMenuRow.namePlaceholder}
																		filterOption={((_, option) => option)}
																		onChange={value => handleMenuChange(value, index)}
																		onPopupScroll={handleLoadMore}
																	>
																		{
																			filterMenu(menuList).map(menu => (
																				<Option
																					key={menu.id}
																					value={menu.id}
																				>
																					<Typography.Text
																						ellipsis={{tooltip: `${menu.id} - ${menu.menuLabel} - ${moneyFormat({value: menu.price})} - [${menu.tags.join(', ')?.toUpperCase?.()}]`}}
																					>{menu.id} -{menu.menuLabel} - {moneyFormat({value: menu.price})} - [{menu.tags.join(', ')?.toUpperCase?.()}]</Typography.Text>
																				</Option>
																			))
																		}
																	</Select>
																</Form.Item>
																<Form.Item
																	label={locale.ChildMenuRow.price.replace('{{order}}', index + 1)}
																	name={[name, 'price']}
																	fieldKey={[key, 'price']}
																	className='flex-1'
																>
																	<InputNumber
																		disabled
																		formatter={inputNumberCurrencyFormatter}
																		type='tel'
																		className='w-full'
																		addonBefore='Rp'
																	/>
																</Form.Item>
																<Form.Item
																	label=' '
																>
																	<Button
																		danger
																		icon={<DeleteOutlined />}
																		onClick={() => remove(name)}
																	>{locale.ChildMenuRow.delete}</Button>
																</Form.Item>
															</div>
														</div>
													)
												}
											</Draggable>
										))
									}
									{provided.placeholder}
									<div className='mb-6'>
										<Button
											onClick={() => add()}
											type='primary'
											ghost
										>{locale.ChildMenuRow.add}</Button>
										<Form.ErrorList errors={errors} />
									</div>
								</div>
							)}
						</Droppable>
					</DragDropContext>
				)}
			</Form.List>
			<Form.Item
				label={locale.minOptionLabel}
				name="minSelection"
				rules={[
					{required: true, message: locale.minOptionRequired},
					({getFieldValue}) => ({
					  validator(_, value) {
							if (value > getFieldValue('maxSelection')) {
								return Promise.reject(new Error(locale.minOptionMoreThanMax));
							}
							if (value > getFieldValue('details')?.length) {
								return Promise.reject(new Error(locale.minOptionMoreThanChild));
							}
							  return Promise.resolve();
					  },
					}),
				  ]}
			>
				<InputNumber
					min={0}
					className='w-96'
					placeholder={locale.minOptionPlaceholder}
				/>
			</Form.Item>
			<Form.Item
				label={locale.maxOptionLabel}
				name="maxSelection"
				rules={[
					{required: true, message: locale.minOptionRequired},
					({getFieldValue}) => ({
					  validator(_, value) {
							if (value > getFieldValue('details')?.length) {
								return Promise.reject(new Error(locale.maxOptionMoreThanChild));
							}
							  return Promise.resolve();
					  },
					}),
				  ]}
			>
				<InputNumber
					min={1}
					className='w-96'
					placeholder={locale.maxOptionPlaceholder}
				/>
			</Form.Item>
			<Form.Item
				label={locale.packagingLabel}
				name="packagingType"
			>
				<Select
					className='w-96'
					defaultValue={PACKAGING_OPTIONS.DEFAULT}
				>
					{MASTERLIST_PACKAGING_OPTION.map(option => (
						<Select.Option
							key={option}
							label={option}
							value={option}
						>
							{locale[option]}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label={locale.statusLabel}
				name="isActive">
				<Radio.Group>
					<Radio
						key='active'
						value={1}>Yes</Radio>
					<Radio
						key='inactive'
						value={0}>No</Radio>
				</Radio.Group>
			</Form.Item>
			<Form.Item>
				<div className='flex gap-2 justify-end'>
					<Button
						onClick={() => navigate(-1)}
						disabled={isLoading}>
						{actionLocale.cancel}
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}>
						{actionLocale.submit}
					</Button>
				</div>
			</Form.Item>
		</Form>
	);
};

OptionGroupForm.defaultProps = {
	isEdit: false,
	itemRecord: {},
};

OptionGroupForm.propTypes = {
	isEdit: PropTypes.bool,
	itemRecord: PropTypes.object,
};

export default OptionGroupForm;