import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {getStationLabel, handleErrorFetch, moneyFormat} from 'utils/utils';
import {getMasterlistItem} from 'utils/request/masterlist';

import InfiniteScroll from 'react-infinite-scroller';

import {CloseOutlined} from '@ant-design/icons';
import {Button, Checkbox, Drawer, Input, Tag, Typography, Select, Spin} from 'antd';
const {Text} = Typography;

import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm.ManageCategory.OptionGroupDrawer;

const ITEM_LIMIT_PER_FETCH = 20;

const OptionGroupDrawer = ({visible, onClose, handleChangeOptionGroup, menuOptionGroup}) => {
	const containerRef = useRef(null);

	const [optionGroupSourceRaw, setOptionGroupSourceRaw] = useState([]);
	const [optionGroupsSource, setOptionGroupsSource] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedOptionGroups, setSelectedOptionGroups] = useState(menuOptionGroup || []);

	const [inputLabel, setInputLabel] = useState(null);
	const [inputTags, setInputTags] = useState([]);

	const [hasMore, setHasMore] = useState(true);
	const [enableScrollLoad, setEnableScrollLoad] = useState(false);

	const addToMenu = () => {
		handleChangeOptionGroup(selectedOptionGroups);
		onClose();
	};

	const fetchOptionGroup = async () => {
		try {
			setLoading(true);
			const payload = {
				limit: ITEM_LIMIT_PER_FETCH,
				sortBy: 'updatedAt',
				sortOrder: 'desc',
				isActive: true,
			};

			if (inputLabel) payload.searchString = inputLabel;
			if (inputTags.length) payload.tags = inputTags.join(',');

			const response = await getMasterlistItem(payload, 'option group');
			if (response.success) {

				if (response.data.rows.length === response.data.count) {
					setHasMore(false);
				} else {
					if (!hasMore) setHasMore(true);
				}

				setOptionGroupSourceRaw(response.data.rows);
				// If linked option group is exist, display it on top
				if (menuOptionGroup.length) {
					// Filter BE response to exclude already linked option
					// Then concat linked option group with filtered response so linked option group with appear on top
					const filteredOptionGroup = response.data.rows.filter(optionGroup => !menuOptionGroup.some(el => {
						const elId = el.id || el.optionGroupId;
						return elId === optionGroup.id;
					}));
					setOptionGroupsSource(menuOptionGroup.concat(filteredOptionGroup));
				} else {
					setOptionGroupsSource(response.data.rows);
				}
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
			if (!enableScrollLoad) setEnableScrollLoad(true);
		}
	};

	const handleLoadMore = async () => {
		try {
			if (!enableScrollLoad) return;
			setLoading(true);
			const payload = {
				limit: ITEM_LIMIT_PER_FETCH,
				sortBy: 'updatedAt',
				sortOrder: 'desc',
				isActive: true,
				offset: optionGroupSourceRaw.length,
			};

			if (inputLabel) payload.label = inputLabel;
			if (inputTags.length) payload.tags = inputTags.join(',');

			const response = await getMasterlistItem(payload, 'option group');

			if (response.success) {
				if (response.data.count <= optionGroupSourceRaw.length + response.data.rows.length) setHasMore(false);

				setOptionGroupSourceRaw(state => state.concat(...response.data.rows));

				const filteredOptionGroup = response.data.rows.filter(optionGroup => !menuOptionGroup.some(el => {
					const elId = el.id || el.optionGroupId;
					return elId === optionGroup.id;
				}));

				setOptionGroupsSource(state => state.concat(filteredOptionGroup));
			} else throw {};

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleCheck = clickedOptionGroup => {
		const menuToOptionGroupFormat = {
			tags: clickedOptionGroup.tags,
			menus: clickedOptionGroup?.details?.menus || clickedOptionGroup?.menus,
			maxSelection: clickedOptionGroup.maxSelection,
			minSelection: clickedOptionGroup.minSelection,
			optionGroupId: clickedOptionGroup.id || clickedOptionGroup.optionGroupId,
			optionGroupLabel: clickedOptionGroup.label || clickedOptionGroup.optionGroupLabel,
		};

		if (clickedOptionGroup?.details?.packaging?.type) {
			menuToOptionGroupFormat.packaging = clickedOptionGroup?.details?.packaging;
		}

		selectedOptionGroups.some(item => item.optionGroupId === menuToOptionGroupFormat.optionGroupId)
			? setSelectedOptionGroups(selectedOptionGroups.filter(item => item.optionGroupId !== menuToOptionGroupFormat.optionGroupId))
			: setSelectedOptionGroups(state => state.concat(menuToOptionGroupFormat));
	};

	useEffect(() => {
		if (!visible) return;
		setSelectedOptionGroups(menuOptionGroup);
		fetchOptionGroup();
		return () => {
			setInputLabel(null);
			setInputTags([]);
			setSelectedOptionGroups([]);
			setEnableScrollLoad(false);
		};
	}, [visible]);

	return (
		<Drawer
			title={locale.title}
			visible={visible}
			destroyOnClose
			onClose={onClose}
			closable={false}
			extra={(
				<CloseOutlined
					onClick={onClose}
					className='cursor-pointer text-antd-netural-4' />
			)}
			width={515}
		>
			<div className='flex flex-col h-full 2'>
				<div className='flex-1 flex flex-col 2 overflow-auto'>
					<div className='flex flex-col gap-4'>
						<div className='flex gap-2'>
							<Input
								onPressEnter={fetchOptionGroup}
								disabled={loading}
								onChange={e => setInputLabel(e.target.value)}
								placeholder={locale.optionGroupSearchPlaceholder}
							/>
							<Button
								type='primary'
								onClick={fetchOptionGroup}>{locale.search}</Button>
						</div>
						<div className='flex gap-4'>
							<Select
								onEnter
								disabled={loading}
								onChange={e => setInputTags(e)}
								placeholder={locale.tagsSearchPlaceholder}
								className='flex-1'
								open={false}
								mode='tags'
								tokenSeparators={[',']}
							/>
						</div>
					</div>
					<div
						ref={containerRef}
						className='flex flex-col flex-1 pt-2 overflow-auto divide-y'>
						<InfiniteScroll
							pageStart={0}
							threshold={0}
							loadMore={handleLoadMore}
							hasMore={!loading && hasMore}
							loader={(
								<div
									className="text-center mt-6"
									key={0}><Spin size='large' /></div>
							)}
							getScrollParent={() => containerRef.current}
							useWindow={false}
						>
							{
								optionGroupsSource.map(optionGroup => (
									<div
										key={optionGroup.id || optionGroup.optionGroupId}
										className="flex gap-4 items-start py-4">
										<Checkbox
											checked={selectedOptionGroups.some(item => {
												const itemId = optionGroup.id || optionGroup.optionGroupId;
												return item.optionGroupId === itemId;
											})}
											onClick={() => handleCheck(optionGroup)}
										/>
										<div>
											<div><Text type="secondary">{`[${optionGroup.id || optionGroup.optionGroupId}]`}</Text> <Text strong>{optionGroup.label || optionGroup.optionGroupLabel}</Text> <Text type="secondary">(Min {optionGroup.minSelection}, Max {optionGroup.maxSelection})</Text></div>
											<div className='pt-1 flex flex-col gap-3'>
												<div className='flex flex-col'>
													{
														(optionGroup?.details?.menus || optionGroup?.menus).map(el => (
															<Text
																type="secondary"
																key={el.menuId}>{`[${el.menuId}] `}{el.menuStation ? `[${getStationLabel(el.menuStation)?.split?.(' ')?.[0]}] - ` : ''}{el.menuLabel} - {moneyFormat({value: el.menuPrice})}</Text>
														))
													}
												</div>
												{
													optionGroup.tags?.length ? (
														<div className='flex flex-wrap gap-y-2'>
															{optionGroup.tags.map((tag, index) => (
																<Tag
																	className='uppercase'
																	key={index}
																	color="blue">{tag}</Tag>
															))}
														</div>
													) : null
												}
											</div>
										</div>
									</div>
								))
							}
						</InfiniteScroll>
					</div>
				</div>
				<div className='pt-6'>
					<Button
						onClick={addToMenu}
						className='w-full'
						type='primary'
					>{locale.addOptionGroupCta}</Button>
					<div></div>
				</div>
			</div>
		</Drawer>
	);
};

OptionGroupDrawer.defaultProps = {
	visible: false,
	onClose: () => null,
	handleChangeOptionGroup: () => null,
	menuOptionGroup: [],
};

OptionGroupDrawer.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	handleChangeOptionGroup: PropTypes.func,
	menuOptionGroup: PropTypes.array,
};

export default OptionGroupDrawer;