import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {getMasterlistItem} from 'utils/request/masterlist';
import {handleErrorFetch} from 'utils/utils';

import {Button, Card, Transfer, Typography} from 'antd';
const {Text, Title} = Typography;

import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm.ChooseCategory;

const ChooseCategory = ({nextStep, menuTemplateDraftObj, updateMenuTemplate, closeConfirmation}) => {
	const [categories, setcategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedCategories, setSelectedCategories] = useState(
		menuTemplateDraftObj.length ? menuTemplateDraftObj.map(menu => Number(menu.categoryId)) : [],
	);

	const beforeNextStep = () => {
		const menuDraftTemp = [];
		selectedCategories.forEach(id => menuDraftTemp.push(categories.find(category => category.id === id)));

		updateMenuTemplate(menuDraftTemp.map(item => {
			const categoryExistIndex = menuTemplateDraftObj.findIndex(categoryArray => categoryArray.categoryId == item.id);
			let menus = [];
			let locationFilter = {
				type: 'default',
				locationIds: [-1],
			};
			if (categoryExistIndex >= 0) {
				menus = menuTemplateDraftObj[categoryExistIndex].menus;
				locationFilter = menuTemplateDraftObj[categoryExistIndex].locationFilter;
			}
			return {
				menus,
				locationFilter,
				categoryId: item.id,
				categoryDays: item.days,
				categoryLabel: item.label,
				posName: item.posName,
			};
		}));

		nextStep();
	};

	const handleCategoryLabel = category => (
		<>
			<Typography.Text>{category.label} {category.posName ? `· ${category.posName}` : ''} <Typography.Text
				className='text-xs uppercase'
				type='secondary'>({category.tags.join(', ')})</Typography.Text></Typography.Text>
		</>
	);

	const fetchCategoryList = async () => {
		try {
			setLoading(true);
			const response = await getMasterlistItem({limit: 99999999,
				sortBy: 'updatedAt',
				sortOrder: 'desc',
				isActive: true},
			'category');
			if (response?.data?.rows?.length) {
				setcategories(response.data.rows);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCategoryList();
	}, []);

	return (
		<Card
			title={(
				<div className='flex flex-col'>
					<Title
						className='font-bold'
						level={5}
					>{locale.title}</Title>
					<Text
						className='text-sm font-normal'
						type="secondary"
					>{locale.description}</Text>
				</div>
			)}
		>
			<Transfer
				disabled={loading}
				listStyle={{flex: '1', height: 380}}
				titles={[locale.allCategory, locale.selectedCategory]}
				dataSource={categories}
				render={handleCategoryLabel}
				targetKeys={selectedCategories}
				rowKey={record => record.id}
				onChange={(selectedKeys, direction) => {
					let tempArray = [...selectedCategories];
					if (direction === 'right') {
						selectedKeys.forEach(merchantId => {
							if (!tempArray.includes(merchantId)) {
								tempArray.push(merchantId);
							}
						});
					} else {
						tempArray = selectedKeys;
					}
					setSelectedCategories(tempArray);
				}}
				filterOption={(input, option) => {
					const formattedCategoryString = `${option.label}${option.posName ? ` · ${option.posName}` : ''} (${option.tags.join(', ')})`;
					return formattedCategoryString.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
				}}
				showSearch
				locale={{
					searchPlaceholder: locale.searchPlaceholder,
				}}
			/>
			<div className='flex justify-end gap-2 mt-6'>
				<Button
					onClick={closeConfirmation}
					disabled={loading}>{locale.cancel}</Button>
				<Button
					disabled={!selectedCategories.length || loading}
					onClick={beforeNextStep}
					type='primary'
				>{locale.apply}</Button>
			</div>
		</Card>
	);
};

ChooseCategory.defaultProps = {
	nextStep: () => null,
	menuTemplateDraftObj: [],
	updateMenuTemplate: () => null,
	closeConfirmation: () => null,
};

ChooseCategory.propTypes = {
	nextStep: PropTypes.func,
	menuTemplateDraftObj: PropTypes.array,
	updateMenuTemplate: PropTypes.func,
	closeConfirmation: PropTypes.func,
};

export default ChooseCategory;