import React, {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import localization from 'localization';

import {DatePicker, Modal, Typography, notification} from 'antd';
import ProTable from '@ant-design/pro-table';
import moment from 'moment';

import {deleteReleaseSchedule, getReleaseSchedule} from 'utils/request/admin';
import {handleErrorFetch, sortDirectionConverter} from 'utils/utils';
import OutletListDrawer from 'components/OutletListDrawer';

const outletListDrawerInitialConfig = {
	visible: false,
	outletList: [],
	versionId: null,
};

const ReleaseSchedulerBody = () => {
	const locale = localization.Admin.ReleaseScheduler.List;
	const tableRef = useRef(null);
	const navigate = useNavigate();

	const [loadingDelete, setLoadingDelete] = useState(false);
	const [outletListDrawerConfig, setOutletListDrawerConfig] = useState(outletListDrawerInitialConfig);

	const openOutletList = (outlets, versionId) => {
		setOutletListDrawerConfig({
			visible: true,
			outletList: outlets,
			versionId,
		});
	};

	const closeOutletList = () => setOutletListDrawerConfig(outletListDrawerInitialConfig);

	const handleDelete = async record => {
		try {
			setLoadingDelete(true);
			const response = await deleteReleaseSchedule({id: record.id});
			if (response.success) {
				tableRef?.current?.reset();
				notification.success({
					message: locale.DeleteSuccess.message,
					description: locale.DeleteSuccess.description,
				});
			}
		} catch (error) {
			notification.error({
				message: locale.DeleteFailed.message,
				description: locale.DeleteFailed.description,
			});
		} finally {
			setLoadingDelete(false);
		}
	};

	const deleteConfirmation = record => {
		Modal.confirm({
			title: locale.DeleteConfirmation.message,
			content: locale.DeleteConfirmation.description
				.replace(
					'{{schedule}}',
					moment(record.releaseDate)
						.format('DD MMM YYYY HH:mm'),
				),
			okText: locale.DeleteConfirmation.okText,
			cancelText: locale.DeleteConfirmation.cancelText,
			centered: true,
			okButtonProps:{loading: loadingDelete, danger: true},
			onOk: async () => {
				await handleDelete(record);
			},
		});
	};

	const columns = [
		{
			width: 200,
			key: 'releaseDate',
			dataIndex: 'releaseDate',
			title: locale.date,
			render: releaseDate => moment(releaseDate).format('DD MMM YYYY HH:mm'),
			renderFormItem: () => (
				<DatePicker.RangePicker
					format={'DD MMM YYYY'}
					ranges={{
						Today: [moment(), moment()],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
					}}
					placeholder={['From', 'Until']}
				/>
			),
		},
		{
			width: 155,
			key: 'posVersionId',
			dataIndex: 'posVersionId',
			title: locale.version,
		},
		{
			width: 142,
			key: 'channel',
			dataIndex: ['PosVersion', 'channel'],
			title: locale.channel,
			search: false,
			render: channel => <span className='capitalize'>{channel}</span>,
			filterMultiple: false,
			filters: [
				{
					text: locale.stable,
					value: 'stable',
				},
				{
					text: locale.beta,
					value: 'beta',
				},
			],
		},
		{
			width: 143,
			key: 'locations',
			dataIndex: 'locations',
			title: locale.locations,
			search: false,
			render: (locations, record) => (
				<div>
					{
						locations.length
							? (
								<span
									className='text-antd-blue-6 cursor-pointer'
									onClick={() => {
										openOutletList(locations, record.posVersionId);
									}}>{locale.locationsCount.replace('{{count}}', locations.length)}</span>
							)
							: '-'
					}
				</div>
			),
		},
		{
			width: 142,
			key: 'forceUpdate',
			dataIndex: 'forceUpdate',
			title: locale.forceUpdate,
			search: false,
			filterMultiple: false,
			filters: [
				{
					text: locale.yes,
					value: true,
				},
				{
					text: locale.no,
					value: false,
				},
			],
			render: forceUpdate => (
				<Typography.Text
					className={`${forceUpdate ? 'text-antd-green-6' : 'text-antd-red-6'}`}>
					{forceUpdate ? locale.yes : locale.no}
				</Typography.Text>
			),
		},
		{
			width: 110,
			key: 'createdBy',
			dataIndex: ['creator', 'username'],
			title: locale.createdBy,
			render: creator => (
				<div>{creator}</div>
			),
		},
		{
			width: 171,
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: locale.createdAt,
			search: false,
			sorter: true,
			defaultSortOrder: 'descend',
			render: createdAt => (
				<div>{moment(createdAt).format('DD MMM YYYY - HH:mm')}</div>
			),
		},
		{
			key: 'action',
			title: 'Action',
			fixed: 'right',
			align: 'center',
			search: false,
			render: (_, record) => (
				<div className='text-antd-blue-6 flex justify-center gap-4'>
					<div
						className='cursor-pointer'
						onClick={() => navigate(`/admin/version-settings/release-scheduler/${record.id}/detail`)}>{locale.detail}</div>
					{
						moment(record.releaseDate).isAfter() && (
							<div
								className='cursor-pointer text-antd-red-6'
								onClick={() => deleteConfirmation(record)}>{locale.delete}
							</div>
						)
					}
				</div>
			),
		},
	];

	const fetchTableData = async (params, sorter, filters) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				page: params.current - 1,
				sortBy: Object.keys(sorter)?.[0] || 'createdAt',
				sortOrder: sortDirectionConverter(Object.values(sorter)?.[0] || 'desc'),
				search: {
					forceUpdate: filters?.forceUpdate?.[0] || null,
					channel: filters?.channel?.[0] || null,
					posVersionId: params?.posVersionId || null,
					releaseDateFrom: params?.releaseDate?.[0] || null,
					releaseDateTo: params?.releaseDate?.[1] || null,
					createdBy: params?.createdBy || null,
				},
			};
			const response = await getReleaseSchedule(payload);
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<div>
			<OutletListDrawer
				title={locale.LocationDrawer.title.replace('{{id}}', outletListDrawerConfig.versionId)}
				placeholder={locale.LocationDrawer.placeholder}
				visible={outletListDrawerConfig.visible}
				outletList={outletListDrawerConfig.outletList}
				onClose={closeOutletList}
				locationOnly
			/>
			<ProTable
				actionRef={tableRef}
				className="mt-4 ResetSearchTableMargin px-6"
				rowKey='id'
				columns={columns}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				onReset={() => tableRef?.current?.reset()}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</div>
	);
};

export default ReleaseSchedulerBody;