import {
	SecretsManagerClient,
	GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager';
import {get} from 'lodash';

const client = new SecretsManagerClient({
	region: 'ap-southeast-1',
	credentials: {
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
	},
});

export const getSecretValue = async key => {
	// eslint-disable-next-line no-useless-catch
	try {
		const response = await client.send(
			new GetSecretValueCommand({
				SecretId: 'frontend',
				VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
			}),
		);
		return get(JSON.parse(response.SecretString), key);
	} catch (e) {
		throw e;
	}
};
