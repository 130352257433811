import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import LocalPageHeader from 'components/LocalPageHeader';
import InternalPromoForm from './InternalPromoForm';
import ExternalPromoForm from './ExternalPromoForm';

import {Button} from 'antd';
import {EditOutlined} from '@ant-design/icons';

import {PROMO_CATEGORY} from 'utils/constants';

import localization from 'localization';
const locale = localization.Promo;

const CreatePromoRequest = ({promoCategory}) => {
	const location = useLocation();
	const action = location.state?.action;

	const [isEditing, setIsEditing] = useState(false);

	const renderForm = () => {
		switch (promoCategory) {
		case PROMO_CATEGORY.INTERNAL:
			return <InternalPromoForm />;
		case PROMO_CATEGORY.EXTERNAL:
			return <ExternalPromoForm isEditing={isEditing} />;
		default:
			return <ExternalPromoForm isEditing={isEditing} />;
		}
	};

	return (
		<div className="bg-white h-full overflow-auto pb-10">
			<LocalPageHeader
				headerTitle={
					locale.Header.new
						.replace('{{promoCategory}}', promoCategory === PROMO_CATEGORY.INTERNAL ? 'Free Item' : 'Price Cut')
				}
				showBackButton
				extra={action === 'detail' && (
					<Button
						onClick={() => setIsEditing(!isEditing)}
						icon={<EditOutlined />}
					>{locale.Header.edit}</Button>
				)}
			/>
			<div className='px-6'>
				{renderForm()}
			</div>
		</div>
	);
};

CreatePromoRequest.defaultProps = {
	promoCategory: null,
};

CreatePromoRequest.propTypes = {
	promoCategory: PropTypes.string,
};

export default CreatePromoRequest;
