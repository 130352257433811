import React, {useCallback, useEffect, useRef, useState} from 'react';

import {OUTLET_TYPE} from 'utils/constants';
import {getBrandList, getPlatformList} from 'utils/request/global';
import {exportOutletData, getOutletData} from 'utils/request/outlet';
import {exportToTsvFn, generateOutletCSVTemplate, handleErrorFetch, momentDateFormat} from 'utils/utils';

import debounce from 'lodash/debounce';

import OutletTable from '../components/Table';
import OutletCompanyHeader from './OutletCompanyHeader';

import localization from 'localization';
const locale = localization.Outlet.Company.Table;

const Company = () => {
	const refetchTableRef = useRef(null);

	const [loading, setLoading] = useState(false);
	const [brandList, setBrandList] = useState([]);
	const [platformList, setPlatformList] = useState([]);
	const [exportQuery, setExportQuery] = useState(null);
	const [outletList, setOutletList] = useState([]);
	const [outletListLoading, setOutletlistLoading] = useState(false);

	const getLocation = async outletLabel => {
		try {
			setOutletlistLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setOutletList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setOutletlistLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	const tableColumn = [
		{
			title: locale.locationLabel,
			dataIndex: ['Merchant', 'Location', 'label'],
			key: 'locationId',
			valueType: 'select',
			fieldProps: {
				showSearch: true,
				loading: outletListLoading,
				onSearch: debounceFn,
				options: outletList.map(outlet => {
					return {
						label: outlet.label,
						value: outlet.id,
					};
				}),
			},
		},
		{
			title: locale.platform,
			dataIndex: ['Merchant', 'Platform', 'label'],
			key: 'platformId',
			valueType: 'select',
			fieldProps: {
				showSearch: true,
				options: platformList.map(paltform => {
					return {
						label: paltform.label,
						value: paltform.id,
					};
				}),
			},
		},
		{
			title: locale.brand,
			dataIndex: ['Merchant', 'Brand', 'label'],
			key: 'brandId',
			valueType: 'select',
			fieldProps: {
				showSearch: true,
				options: brandList.map(brand => {
					return {
						label: brand.label,
						value: brand.id,
					};
				}),
			},
		},
		{
			title: locale.companyName,
			dataIndex: 'companyName',
			key: 'companyName',
		},
		{
			title: locale.pb1,
			dataIndex: 'printTax',
			key: 'printTax',
			width: 100,
			valueEnum: {
				all: {text: 'All', status: 'Default'},
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={`font-semibold ${record.printTax ? 'text-green-400' : 'text-red-400'}`}>
					{record.printTax ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: locale.effectiveStartDate,
			dataIndex: 'effectiveDate',
			key: 'effectiveDate',
			valueType: 'dateRange',
			render: (_, {effectiveDate}) => momentDateFormat({dateString: effectiveDate}),
		},
		{
			title: locale.submittedBy,
			dataIndex: ['creator', 'username'],
			key: 'createdBy',
		},
	];

	const handleExport = async ({fileName, type = 'template'}) => {
		await exportToTsvFn({
			fileName,
			actionType: type,
			exportCall: () => exportOutletData(exportQuery, OUTLET_TYPE.COMPANY),
			tsvTemplate: generateOutletCSVTemplate(OUTLET_TYPE.COMPANY),
		});
	};

	const initialFetch = async () => {
		try {
			setLoading(true);
			const platformResponse = await getPlatformList();
			const brandResponse = await getBrandList();
			if (platformResponse.success) setPlatformList(platformResponse.data.rows);
			if (brandResponse.success) setBrandList(brandResponse.data.rows);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		initialFetch();
	}, []);

	return (
		<div
			className="bg-white h-full overflow-auto">
			<OutletCompanyHeader
				refetchTableRef={refetchTableRef}
				handleExport={handleExport}
			/>
			<OutletTable
				parentLoading={loading}
				tableColumn={tableColumn}
				fetchFn={getOutletData}
				tableType={OUTLET_TYPE.COMPANY}
				refetchTableRef={refetchTableRef}
				setExportQuery={setExportQuery}
			/>
		</div>
	);
};

export default Company;