import React from 'react';

import {Button, Dropdown} from 'antd';
import {FormOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

import LocalPageHeader from 'components/LocalPageHeader';
import OrderTemplateTable from './OrderTemplateTable';

import {INTERNAL_ORDERING_TYPE, USER_ROLES} from 'utils/constants';

import localization from 'localization';
import {authChecker} from 'utils/utils';
import {useSelector} from 'react-redux';
const locale = localization.InternalOrdering.MenuCreator;

const MenuCreator = () => {
	const navigate = useNavigate();
	const auth = useSelector(state => state.user);

	const getAvailableOptions = () => {
		const output = [];

		if (authChecker({
			auth,
			requiredRolesArr: [USER_ROLES.INTERNAL_ORDERING.INTERNAL_ORDER_CAMPAIGN_EDITOR_KOL],
			children: true,
		})) {
			output.push(INTERNAL_ORDERING_TYPE.kol);
		}

		if (authChecker({
			auth,
			requiredRolesArr: [USER_ROLES.INTERNAL_ORDERING.INTERNAL_ORDER_CAMPAIGN_EDITOR_BULK],
			children: true,
		})) {
			output.push(INTERNAL_ORDERING_TYPE.bulk);
		}

		if (authChecker({
			auth,
			requiredRolesArr: [USER_ROLES.INTERNAL_ORDERING.INTERNAL_ORDER_CAMPAIGN_EDITOR_REPLACEMENT],
			children: true,
		})) {
			output.push(INTERNAL_ORDERING_TYPE.replacement);
		}

		return output;
	};

	const dropdownItems = getAvailableOptions().map(orderType => {
		return {
			key: orderType.key,
			label: orderType.label,
			onClick: () => navigate(`/internal-ordering/menu-combination/${orderType.key}/new`, {
				state: {
					requestType: orderType.key,
				},
			}),
		};
	});

	return (
		<div className='bg-white h-full overflow-auto'>
			<LocalPageHeader
				headerTitle={locale.title}
				extra={[
					<Dropdown
						trigger={['click']}
						menu={{items: dropdownItems}}
						key='create'>
						<Button
							icon={<FormOutlined />}
							type='primary'
						>{locale.createNew}
						</Button>
					</Dropdown>,
				]}
			/>
			<OrderTemplateTable />
		</div>
	);
};

export default MenuCreator;