import React from 'react';
import {useNavigate} from 'react-router-dom';

import OutletTable from '../components/Table';
import OutletBranchHeader from './OutletBranchHeader';

import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';

const OutletBranch = () => {
	const navigate = useNavigate();

	const goToEditPage = payload => {
		navigate('/outlet/branch/edit', {
			state: {itemRecord: payload},
		});
	};

	const tableColumn = [
		{
			title: 'Branch ID',
			dataIndex: 'id',
			key: 'id',
			fixed: 'left',
		},
		{
			title: 'Location ID',
			dataIndex: ['Location', 'id'],
			key: 'locationId',
		},
		{
			title: 'Location Name',
			dataIndex: ['Location', 'label'],
			key: 'locationLabel',
		},
		// {
		// 	title: 'Tags',
		// 	dataIndex: 'tags',
		// 	key: 'tags',
		// },
		// {
		// 	title: 'Active',
		// 	dataIndex: 'isActive',
		// 	key: 'isActive',
		// },
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			fixed: 'right',
			search: false,
			render: (_, record) => (
				<span
					onClick={() => goToEditPage(record)}
					className='cursor-pointer text-blue-400'
				>
					Edit
				</span>
			),
		},
	];

	return (
		<div
			className="bg-white h-full overflow-auto">
			<OutletBranchHeader />
			<OutletTable
				tableColumn={tableColumn}
				fetchFn={getOutletData}
				tableType={OUTLET_TYPE.BRANCH}
			/>
		</div>
	);
};

export default OutletBranch;