import React, {useCallback, useRef, useState} from 'react';

import ProTable from '@ant-design/pro-table';
import moment from 'moment';
import {debounce} from 'lodash';

import {getVersionHistory, getVersionTracker} from 'utils/request/admin';
import {handleErrorFetch, sortDirectionConverter} from 'utils/utils';
import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';
import VersionTrackerModal from './VersionTrackerModal';

const versionTrackerModalInitialState = {
	visible: false,
	data: {},
	history: [],
};

import localization from 'localization';
const locale = localization.Admin.VersionTracker.List;

const VersionTrackerBody = () => {
	const tableRef = useRef(null);

	const [tableLoading, setTableLoading] = useState(false);
	const [locationList, setLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);
	const [versionTrackerModalConfig, setVersionTrackerModalConfig] = useState(versionTrackerModalInitialState);

	const closeVersionTrackerModal = () => setVersionTrackerModalConfig(versionTrackerModalInitialState);

	const openVersionTrackerModal = async record => {
		try {
			setTableLoading(true);
			const response = await getVersionHistory({search: {locationId: record.id}});
			setVersionTrackerModalConfig({
				visible: true,
				data: record,
				history: response.data || [],
			});
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setTableLoading(false);
		}
	};

	const getLocation = async outletLabel => {
		try {
			if (outletLabel.length === 0 && locationList.length !== 0) {
				return;
			}
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	const columns = [
		{
			width: 184,
			key: 'locationId',
			dataIndex: 'label',
			title: locale.location,
			valueType: 'select',
			fieldProps: {
				onSearch: debounceFn,
				loading: locationLoading,
				showSearch: true,
				options: locationList.map(location => {
					return {
						label: location.label,
						value: location.id,
					};
				}),
			},
		},
		{
			width: 188,
			key: 'locationGroupName',
			dataIndex: ['locationGroup', 'name'],
			title: locale.locationGroup,
		},
		{
			width: 150,
			key: 'currentVersionId',
			dataIndex: ['latestVersionUpdate', 'posVersionId'],
			title: locale.currentVer,
		},
		{
			width: 113,
			key: 'currentChannel',
			dataIndex: ['latestVersionUpdate', 'PosVersion', 'channel'],
			title: locale.channel,
			search: false,
			render: currentChannel => <span className='capitalize'>{currentChannel}</span>,
		},
		{
			width: 210,
			key: 'lastUpdate',
			dataIndex: ['latestVersionUpdate', 'updatedAt'],
			title: locale.lastUpdate,
			search: false,
			render: lastUpdate => lastUpdate === '-' ? '-' : moment(lastUpdate).format('DD MMM YYYY - HH:mm'),
		},
		{
			width: 210,
			key: 'nextReleaseDate',
			dataIndex: ['latestVersionSchedule', 'releaseDate'],
			title: locale.newReleaseDate,
			search: false,
			render: nextReleaseDate => nextReleaseDate === '-' ? '-' : moment(nextReleaseDate).format('DD MMM YYYY - HH:mm'),
		},
		{
			width: 150,
			key: 'nextVersionId',
			dataIndex: ['latestVersionSchedule', 'posVersionId'],
			title: locale.newestVer,
			search: false,
		},
		{
			width: 113,
			key: 'nextChannel',
			dataIndex: ['latestVersionSchedule', 'PosVersion', 'channel'],
			title: locale.channel,
			search: false,
			render: nextChannel => <span className='capitalize'>{nextChannel}</span>,
		},
		{
			key: 'action',
			title: 'Action',
			fixed: 'right',
			align: 'center',
			search: false,
			width: 118,
			render: (_, record) => (
				<div className='text-antd-blue-6 flex justify-center gap-4'>
					<div
						className='cursor-pointer'
						onClick={() => openVersionTrackerModal(record)}>{locale.detail}</div>
				</div>
			),
		},
	];

	const fetchTableData = async (params, sorter, filters) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				page: params.current - 1,
				sortBy: Object.keys(sorter)?.[0] || 'label',
				sortOrder: sortDirectionConverter(Object.values(sorter)?.[0] || null),
				search: {
					locationId: params?.locationId || null,
					locationGroupName: params?.locationGroupName || null,
					currentVersionId: params?.currentVersionId || null,
					currentChannel: filters?.currentChannel?.[0] || null,
					nextChannel: filters?.nextChannel?.[0] || null,
				},
			};
			const response = await getVersionTracker(payload);
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<div>
			<VersionTrackerModal
				visible={versionTrackerModalConfig.visible}
				data={versionTrackerModalConfig.data}
				versionHistory={versionTrackerModalConfig.history}
				close={closeVersionTrackerModal}
			/>
			<ProTable
				loading={tableLoading}
				actionRef={tableRef}
				className="mt-4 ResetSearchTableMargin px-6"
				rowKey='id'
				columns={columns}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				onReset={() => tableRef?.current?.reset()}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</div>
	);
};

export default VersionTrackerBody;