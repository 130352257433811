import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Input, Modal, Popconfirm, Table, Tag} from 'antd';
const {Search} = Input;

import {INTERNAL_PROMO_MENU_PICKER_MODAL_TYPE} from 'utils/constants';
import {getMasterlistItem} from 'utils/request/masterlist';
import {handleErrorFetch} from 'utils/utils';

import {debounce} from 'lodash';

import localization from 'localization';
const locale = localization.Promo.CreateNewPromo.Internal.MenuPickerModal;

const TABLE_DATA_COUNT = 20;

const MenuPickerModal = ({visible, onClose, actionFn, type}) => {
	const [menus, setMenus] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tablePage, setTablePage] = useState(0); //starts from 0
	const [menuLabel, setMenuLabel] = useState(null);

	const isFreeItem = type === INTERNAL_PROMO_MENU_PICKER_MODAL_TYPE.FREE_ITEM;

	const columns = [
		{
			title: locale.menuId,
			dataIndex: 'id',
			key: 'id',
			width: 105,
		},
		{
			title: locale.menuLabel,
			dataIndex: 'menuLabel',
			key: 'menuLabel',
		},
		{
			title: locale.tags,
			dataIndex: 'tags',
			key: 'tags',
			render: tags => (Array.isArray(tags) && tags.length) ? (
				<div className='flex flex-wrap gap-y-2'>
					{tags.map((tag, index) => (
						<Tag
							color='blue'
							className='uppercase'
							key={index}>{tag}
						</Tag>
					))}
				</div>
			) : '-',
		},
		// {
		// 	title: 'Price',
		// 	dataIndex: 'price',
		// 	key: 'price',
		// 	render: price => (
		// 		<div>{moneyFormat({value: price})}</div>
		// 	),
		// },
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			width: 120,
			render: record => (
				<div className="text-blue-400">
					<Popconfirm
						placement='topRight'
						title={locale.selectConfirmation}
						cancelText={locale.cancel}
						okText={locale.select}
						onConfirm={() => {
							actionFn(record);
							onClose();
						}}
					>
						<span className='cursor-pointer'>{locale.selectAction}</span>
					</Popconfirm>
				</div>
			),
		},
	];

	const debouncedChangeHandler = useCallback(
		debounce(inputtedLabel => {
			setTablePage(0);
			setMenuLabel(inputtedLabel);
		}, 500)
		, []);

	const fetchMenus = async () => {
		try {
			setLoading(true);
			const payload = {
				limit: TABLE_DATA_COUNT,
				sortBy: 'updatedAt',
				sortOrder: 'desc',
				isActive: true,
				isChildMenu: false,
				isFreeItem,
				offset: tablePage * TABLE_DATA_COUNT,
				menuLabel,
			};

			const response = await getMasterlistItem(payload, 'menu');
			if (response.success) {
				setMenus(response.data);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange = ({current}) => {
		setTablePage(current - 1);
	};

	useEffect(() => {
		if (!visible) return;
		fetchMenus();
	}, [visible, tablePage, menuLabel]);

	return (
		<Modal
			destroyOnClose={true}
			width={870}
			visible={visible}
			onCancel={onClose}
			title={
				isFreeItem ? locale.freeItemTitle : locale.defaultTitle
			}
			footer={null}
			centered
		>
			<div className='flex flex-col gap-4'>
				<Search
					loading={loading}
					placeholder={locale.searchPlaceholder}
					// onChange={e => debouncedChangeHandler(e.target.value)}
					onSearch={debouncedChangeHandler}
					style={{width: 264}} />
				<Table
					loading={loading}
					columns={columns}
					dataSource={menus?.rows}
					scroll={{y: 400}}
					pagination={{
						defaultPageSize: 20,
						total: menus?.count,
						showSizeChanger: false,
						current: tablePage + 1,
					}}
					onChange={handleTableChange}
				/>
				<div className='flex justify-end'>
					<Button onClick={onClose}>{locale.cancel}</Button>
				</div>
			</div>
		</Modal>
	);
};

MenuPickerModal.defaultProps = {
	visible: false,
	onClose: () => null,
	actionFn: () => null,
	type: null,
};

MenuPickerModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	actionFn: PropTypes.func,
	type: PropTypes.oneOf(
		[
			INTERNAL_PROMO_MENU_PICKER_MODAL_TYPE.SKU,
			INTERNAL_PROMO_MENU_PICKER_MODAL_TYPE.FREE_ITEM,
		],
	),
};

export default MenuPickerModal;