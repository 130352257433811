import {USER_ROLES} from 'utils/constants';

// Source of truth https://docs.google.com/spreadsheets/d/1MWT79KtZUf_ZC1fcIEu2mz2gnYcX6q_EYrSKsBay95E
export default [
	{
		label: 'Product Ops',
		addonBefore: 'po_',
		value: 'po',
		scope: [
			USER_ROLES.OUTLET.ADMIN,
			USER_ROLES.OUTLET.VIEWER,
			USER_ROLES.OUTLET.EDITOR,
			USER_ROLES.SYNC_PROMO.EDITOR,
			USER_ROLES.SYNC_PROMO.VIEWER,
			USER_ROLES.SYNC_MENU.EDITOR,
			USER_ROLES.SYNC_MENU.VIEWER,
			USER_ROLES.MENU_TEMPLATE.ADMIN,
			USER_ROLES.MENU_TEMPLATE.VIEWER,
			USER_ROLES.MENU_TEMPLATE.EDITOR,
			USER_ROLES.MASTERLIST.ADMIN,
			USER_ROLES.MASTERLIST.VIEWER,
			USER_ROLES.MASTERLIST.EDITOR,
			USER_ROLES.USER.ADMIN,
			USER_ROLES.WATCHTOWER.ADMIN,
			USER_ROLES.RESTOADMIN,
		],
	},
	{
		label: 'Marketing',
		addonBefore: 'mkt_',
		value: 'mkt',
		scope: [
			USER_ROLES.SYNC_PROMO.APPROVER,
			USER_ROLES.SYNC_PROMO.VIEWER,
			USER_ROLES.SYNC_MENU.VIEWER,
			USER_ROLES.MENU_TEMPLATE.VIEWER,
			USER_ROLES.WATCHTOWER.PROMO_ADMIN,
			USER_ROLES.MARKETING.EDITOR,
			USER_ROLES.INTERNAL_ORDERING.INTERNAL_ORDER_CAMPAIGN_EDITOR_KOL,
		],
	},
	{
		label: 'PM',
		addonBefore: 'pm_',
		value: 'pm',
		scope: [
			USER_ROLES.SYNC_PROMO.VIEWER,
			USER_ROLES.SYNC_MENU.APPROVER,
			USER_ROLES.SYNC_MENU.VIEWER,
			USER_ROLES.MENU_TEMPLATE.VIEWER,
			USER_ROLES.WATCHTOWER.MENU_ADMIN,
		],
	},
	{
		label: 'Outlet Support',
		addonBefore: 'os_',
		value: 'os',
		scope: [
			USER_ROLES.MASTERLIST.VIEWER,
			USER_ROLES.OUTLET.VIEWER,
			USER_ROLES.SYNC_PROMO.VIEWER,
			USER_ROLES.SYNC_MENU.APPROVER,
			USER_ROLES.SYNC_MENU.VIEWER,
			USER_ROLES.MENU_TEMPLATE.VIEWER,
			USER_ROLES.WATCHTOWER.MENU_ADMIN,
			USER_ROLES.WATCHTOWER.ORDER_ADMIN,
			USER_ROLES.WATCHTOWER.ADMIN,
			USER_ROLES.RESTOADMIN,
		],
	},
	{
		label: 'Trainer',
		addonBefore: 'tr_',
		value: 'tr',
		scope: [
			USER_ROLES.TRAINER.ADMIN,
		],
	},
	{
		label: 'CE',
		addonBefore: 'ce_',
		value: 'ce',
		scope: [
			USER_ROLES.WATCHTOWER.ORDER_ADMIN,
			USER_ROLES.MASTERLIST.VIEWER,
			USER_ROLES.MASTERLIST.EDITOR,
			USER_ROLES.INTERNAL_ORDERING.INTERNAL_ORDER_CAMPAIGN_EDITOR_BULK,
			USER_ROLES.INTERNAL_ORDERING.INTERNAL_ORDER_CAMPAIGN_EDITOR_REPLACEMENT,
		],
	},
];