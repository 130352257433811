import React from 'react';
import {Divider} from 'antd';

const ReceiptDivider = () => {
	return (
		<div className='px-6'>
			<Divider className='m-0' />
		</div>
	);
};

export default ReceiptDivider;