import {ACTIONS} from 'store/actions/actionKeys';

const initialState = {
	username: false,
	roles: [],
	userId: null,
	loggedIn: false,
};

export default function user(state = initialState, {type, payload}) {
	switch (type) {
	case ACTIONS.SET_USER_DATA:
		return {
			...state,
			...payload,
		};
	default:
		return state;
	}
}