import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import LocationGroupFormHeader from './LocationGroupFormHeader';
import LocationGroupFormBody from './LocationGroupFormBody';
import {useNavigate, useParams} from 'react-router-dom';
import {handleErrorFetch} from 'utils/utils';
import {getLocationGroup} from 'utils/request/admin';
import {Spin} from 'antd';

const LocationGroupForm = ({isEdit}) => {
	const navigate = useNavigate();
	const params = useParams();

	const [locationGroupData, setLocationGroupData] = useState({});
	const [loadingData, setLoadingData] = useState(isEdit ? true : false);

	const navigateBack = () => navigate('/admin/version-settings/location-group');

	const fetchLocationGroup = async () => {
		try {
			setLoadingData(true);
			const response = await getLocationGroup({search: {id: params.id}});
			if (response.success && response?.data?.rows?.length) {
				setLocationGroupData(response.data.rows[0]);
			} else throw {};
		} catch (error) {
			handleErrorFetch();
			navigateBack();
		} finally {
			setLoadingData(false);
		}
	};

	useEffect(() => {
		if (isEdit) {
			if (params?.id) {
				fetchLocationGroup();
			} else {
				navigateBack();
			}
		}
	}, []);

	return (
		<div className='bg-white h-full overflow-auto'>
			<LocationGroupFormHeader
				isEdit={isEdit}
				locationGroupData={locationGroupData} />
			{
				loadingData ? <Spin /> : (
					<LocationGroupFormBody
						isEdit={isEdit}
						locationGroupData={locationGroupData}
					/>
				)
			}
		</div>
	);
};

LocationGroupForm.defaultProps = {
	isEdit: false,
};

LocationGroupForm.propTypes = {
	isEdit: PropTypes.bool,
};

export default LocationGroupForm;