import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {moneyFormat} from 'utils/utils';
import {AVAILABLE_POS, PARENT_MAPPING_OPTIONS} from 'utils/constants';
import {getValueByPath, setValueByPath} from 'utils/mapping';

import DebounceSelect from './DebounceSelect';

import cloneDeep from 'lodash/cloneDeep';
import unset from 'lodash/unset';

import {Button, Divider, Form, InputNumber, Select, Typography} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';

import localization from 'localization';
import {get} from 'lodash';
const locale = localization.MasterlistPage.MasterlistForm.MenuStructureForm.ManageMapping;

const MenuItemComponent = ({
	section, menuItem = {}, optionGroupId,
	ignored, getMappingValue, showDivider,
	form, isBundle, isEdit, overridePercentageList,
	childMenuId, unmatchOGPercentage, preventChangeMenuPrice,
}) => {
	const {posId} = useParams();
	const [entrySection, setEntrySection] = useState([]);

	const isOdoo = posId == AVAILABLE_POS.ODOO.id;
	const itemLabel = menuItem.menuLabel || menuItem.optionGroupLabel;
	const itemPrice = menuItem?.menuPrice;
	const mappingEntry = section === PARENT_MAPPING_OPTIONS.MENU ? 'items' : 'childMenus';

	const overridingPercentage = overridePercentageList.includes(optionGroupId);

	const updateEntrySection = () => {
		if (section == PARENT_MAPPING_OPTIONS.MENU) {
			setEntrySection([PARENT_MAPPING_OPTIONS.MENU, mappingEntry]);
		}

		if (section == PARENT_MAPPING_OPTIONS.OPTION_GROUP) {
			setEntrySection([PARENT_MAPPING_OPTIONS.OPTION_GROUP, optionGroupId, mappingEntry, menuItem?.menuId]);
		}

		if (section == PARENT_MAPPING_OPTIONS.COMPLEX_RULE) {
			const complexEntry = menuItem.map(item => item.menuId.toString());
			setEntrySection([PARENT_MAPPING_OPTIONS.COMPLEX_RULE, 'items', ...complexEntry]);
		}
	};

	const getMenuLabel = () => {
		// Used for error message
		if (section == PARENT_MAPPING_OPTIONS.COMPLEX_RULE) {
			return `${menuItem[0].menuLabel} - ${menuItem[1].menuLabel}`;
		} else {
			return menuItem?.menuLabel;
		}
	};

	const extractBOMCode = str => {
		const match = str.match(/\[BOM:\s*(.*?)\]/);
		return match ? match[1] : null;
	};

	const overrideFieldName = overridingPercentage
		? 'pricePercentage'
		: 'menuPrice';

	const calculateCurrentPercentage = () => {
		let output = 0;

		const childMappingEntry = get(getMappingValue(), ['optionGroups', optionGroupId, 'childMenus', childMenuId], []);
		childMappingEntry.forEach((childMapping => {
			output += childMapping?.pricePercentage || 0;
		}));

		return moneyFormat({value: output, includeCurrency: false});
	};

	useEffect(() => {
		updateEntrySection();
	}, []);

	return (
		<>
			{/* // Left Section (Menu Label) */}
			<div className='flex flex-col lg:flex-row lg:gap-10'>
				<div className='flex flex-shrink-0 w-80'>
					<div className='flex flex-col gap-2'>
						<Typography.Paragraph>
							<ul>
								{
									section === PARENT_MAPPING_OPTIONS.COMPLEX_RULE
										? menuItem.map?.(item => (
											<li key={item.menuId}>{`${item?.menuLabel} ${`- ${moneyFormat({value: item?.menuPrice || 0})}`}`}</li>
										))
										: <li>{`${itemLabel} ${`- ${moneyFormat({value: itemPrice || 0})}`}`}</li>
								}
							</ul>
						</Typography.Paragraph>
					</div>
				</div>

				{/* Right Section (Mapping) */}
				{
					ignored
						? (
							<div className='flex flex-1 flex-col gap-4'>
								<Select
									disabled={true}
									className='w-full'
									placeholder={locale.ignorePlaceholder}
								/>
							</div>
						) : (
							<Form.List
								name={entrySection}
							>
								{(fields, {add, remove}) => (
									<div className='flex flex-1 flex-col gap-4'>
										{
											fields.map((field, index) => (
												<div
													key={index}
													className='flex flex-wrap'>
													<div className='flex flex-1 items-center gap-3'>

														{/* Select Section */}
														<div className='flex flex-1'>
															<div className='bg-antd-netural-2 border border-antd-netural-5 rounded-l-sm px-3 flex items-center capitalize'>
																{getValueByPath(getMappingValue(), [...entrySection, field.name])?.menuType}
															</div>
															<Form.Item
																className='flex-1'
																{...field}
																noStyle
																name={[field.name, 'displayName']}
																normalize={e => e || null}
																messageVariables={{
																	order: index + 1,
																	menuLabel: getMenuLabel(),
																}}
																rules={[{
																	required: true,
																	message: '${menuLabel} missing mapping [${order}]',
																	warningOnly: isOdoo ? true : false,
																}]}
															>
																<DebounceSelect
																	isBundle={isBundle}
																	onChange={e => {
																		const mappingTargetId = e ? e.split(' - ')[0] : '';
																		const mappingMenuCode = e ? e.split(' - ')[1] : '';
																		const lastItem = e ? e.split(' - ').pop() : null;
																		const tempFormVal = cloneDeep(getMappingValue());
																		const isReminder = getValueByPath(getMappingValue(), [...entrySection, field.name])?.menuType == 'reminder';

																		setValueByPath(
																			tempFormVal,
																			[...entrySection, field.name, 'menuId'],
																			mappingTargetId,
																		);

																		if (!isReminder) {
																			// If not reminder

																			const mappingMenuCodeVal = mappingMenuCode && mappingMenuCode !== 'NO_CODE'
																				? mappingMenuCode
																				: null;

																			setValueByPath(
																				tempFormVal,
																				[...entrySection, field.name, 'menuCode'],
																				mappingMenuCodeVal,
																			);
																			if (lastItem && lastItem.includes('[BOM:')) {
																				setValueByPath(
																					tempFormVal,
																					[...entrySection, field.name, 'bomName'],
																					extractBOMCode(lastItem),
																				);
																			} else {
																				unset(
																					tempFormVal,
																					[...entrySection, field.name, 'bomName'],
																				);
																			}
																		}

																		setValueByPath(
																			tempFormVal,
																			[...entrySection, field.name, 'menuLabel'],
																			e ? e.split(' - ')[isReminder ? 1 : 2] : null,
																		);

																		form.setFieldsValue({
																			[section]: tempFormVal[section],
																		});
																	}}
																	isReminder={
																		getValueByPath(getMappingValue(), [...entrySection, field.name])?.menuType == 'reminder'
																	}
																	className='w-full'
																/>
															</Form.Item>
														</div>
														<div
															style={{width: 200, display: 'grid', gridTemplateColumns: 'auto auto', gap: '12px'}}>
															{
																['parent', 'child'].some(item => item === getValueByPath(getMappingValue(), [...entrySection, field.name, 'menuType']))
																	? (
																		<div
																			className='w-40'
																		>
																			{
																				[undefined, null].some(item => item === getValueByPath(getMappingValue(), [...entrySection, field.name, overrideFieldName]))
																					? (
																						<Button
																							onClick={() => {
																								const tempFormVal = cloneDeep(getMappingValue());

																								setValueByPath(
																									tempFormVal,
																									[...entrySection, field.name, overrideFieldName],
																									'',
																								);

																								form.setFieldsValue({
																									[section]: tempFormVal[section],
																								});
																							}}
																							className='w-full'>
																							Override Price
																						</Button>
																					)
																					: (
																						<Form.Item
																							key={index}
																							noStyle
																							name={[field.name, overrideFieldName]}
																						>
																							{/* <Input /> */}
																							<InputNumber
																								disabled={
																									section === PARENT_MAPPING_OPTIONS.MENU
																										? preventChangeMenuPrice
																											? true
																											: isEdit
																												? isBundle
																													? true
																													: false
																												: false
																										: false
																								}
																								parser={value => {
																									if (overridingPercentage) {
																										// Remove non-numeric characters except a single decimal point, limit to two decimals
																										return value.replace(/[^\d.]/g, '').replace(/^(\d+)\.(\d{0,2}).*$/, '$1.$2');
																									} else {
																										// Remove all non-numeric characters for currency
																										return value.replace(/\D/g, '');
																									}
																								}}
																								formatter={value => {
																									if (!value) return value;

																									if (overridingPercentage) {
																										// Allow decimals, format integer part with commas, limit decimal to 2 digits
																										const [integerPart, decimalPart] = value.split('.');
																										const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
																										return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
																									} else {
																										// Format integer part with commas only, no decimals
																										return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
																									}
																								}}
																								precision={2}
																								placeholder={overridingPercentage ? 'Input percentage' : 'Input price'}
																								controls={false}
																								addonBefore={overridingPercentage ? null : 'Rp'} // Show 'Rp' prefix only for currency
																								addonAfter={overridingPercentage ? '%' : null} // Show 'Rp' prefix only for currency
																							/>
																						</Form.Item>
																					)
																			}
																		</div>
																	)
																	: null
															}
															{
																(
																	<Button
																		className={index == 0 && 'invisible'}
																		onClick={() => remove(field.name)}
																		danger
																		icon={<DeleteOutlined />}
																	/>
																)
															}
														</div>
													</div>
												</div>
											))
										}
										{
											!ignored && (
												<div className='flex items-center justify-between'>
													<div className='flex gap-2'>
														{
															isOdoo && (
																<div
																	onClick={() => {
																		const outputTemp = {
																			menuType: 'reminder',
																			menuId: null,
																			menuLabel: null,
																			displayName: null,
																			menuCode: null,
																		};

																		if (isBundle) outputTemp.menuPrice = 0;

																		add(outputTemp);
																	}}
																	className='flex items-center gap-2 text-antd-blue-6 cursor-pointer max-w-max'>
																	<PlusOutlined />
																	{locale.addReminder}
																</div>
															)
														}
														<div
															onClick={() => {
																const outputTemp = {
																	menuType: 'child',
																	menuId: null,
																	menuLabel: null,
																	displayName: null,
																	menuCode: null,
																};

																if (isBundle) outputTemp.menuPrice = 0;

																add(outputTemp);
															}}
															className='flex items-center gap-2 text-antd-blue-6 cursor-pointer max-w-max'>
															<PlusOutlined />
															{locale.addChild}
														</div>
													</div>
													{
														overridingPercentage && (
															<Typography.Text
																className={unmatchOGPercentage.includes(optionGroupId) && 'text-antd-red-6'}
																type='secondary'
															>{`Total Percentage: ${calculateCurrentPercentage()}%`}</Typography.Text>
														)}
												</div>
											)
										}
									</div>
								)}
							</Form.List>
						)}
			</div>
			{
				showDivider && <Divider dashed />
			}
		</>
	);
};

MenuItemComponent.defaultProps = {
	section: null,
	menuItem: {},
	optionGroupId: undefined,
	ignored: false,
	getMappingValue: () => null,
	form: () => null,
	showDivider: false,
	isBundle: false,
	isEdit: false,
	overridePercentageList: [],
	childMenuId: null,
	unmatchOGPercentage: [],
	preventChangeMenuPrice: null,
};

MenuItemComponent.propTypes = {
	section: PropTypes.string,
	menuItem: PropTypes.object,
	optionGroupId: PropTypes.number,
	ignored: PropTypes.bool,
	getMappingValue: PropTypes.func,
	form: PropTypes.func,
	showDivider: PropTypes.bool,
	isBundle: PropTypes.bool,
	isEdit: PropTypes.bool,
	overridePercentageList: PropTypes.array,
	childMenuId: PropTypes.number,
	unmatchOGPercentage: PropTypes.array,
	preventChangeMenuPrice: PropTypes.number,
};

export default MenuItemComponent;