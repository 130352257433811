import localization from 'localization';

// Global components
import LocalPageHeader from 'components/LocalPageHeader';

const locale = localization.WatchTower.WatchTowerOrder.header;

const Header = () => {
	// Render
	return (
		<LocalPageHeader
			borderBottom
			headerTitle={locale.watchtower} />
	);
};

export default Header;
