import React from 'react';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';
import {Button} from 'antd';

import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm.Header;

const MenuTemplateFormHeader = ({
	menuTemplateLabel, saveToDraft, saveLoading, closeConfirmation,
}) => {
	return (
		<LocalPageHeader
			headerTitle={menuTemplateLabel}
			capitalize={false}
			showBackButton
			backButtonAction={closeConfirmation}
			extra={(
				<Button
					disabled={saveLoading}
					onClick={saveToDraft}
					type='primary'>
					{locale.save}
				</Button>
			)}
		/>
	);
};

MenuTemplateFormHeader.defaultProps = {
	menuTemplateLabel: null,
	saveToDraft: () => null,
	saveLoading: false,
	closeConfirmation: () => null,
};

MenuTemplateFormHeader.propTypes = {
	menuTemplateLabel: PropTypes.string,
	saveToDraft: PropTypes.func,
	saveLoading: PropTypes.bool,
	closeConfirmation: PropTypes.func,
};

export default MenuTemplateFormHeader;