import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {Provider} from 'react-redux';
import store from 'store';

import Bugsnag from '@bugsnag/js';
import crashLogger from 'utils/logger';
import {BrowserRouter} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import enUS from 'antd/es/locale/en_US';

async function initializeApp() {
	// Perform any asynchronous operations here
	await crashLogger.initialize();

	const ErrorBoundary = crashLogger.bugsnagEnabled
		? Bugsnag.getPlugin('react').createErrorBoundary(React)
		: React.Fragment;


	// Once the async operation is complete, call ReactDOM.render
	ReactDOM.render(
	// <React.StrictMode>
		<ErrorBoundary>
			<Provider store={store}>
				<BrowserRouter>
					<ConfigProvider locale={enUS}>
						<App />
					</ConfigProvider>
				</BrowserRouter>
			</Provider>
		</ErrorBoundary>,
		// </React.StrictMode>,
		document.getElementById('root'),
	);}

// Call the initializeApp function to start the initialization process
initializeApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
