import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import get from 'lodash/get';
import {version} from '../../package.json';
import {RELEASE_STAGE} from './constants';
import base64 from 'base-64';

import store from 'store';
import {getSecretValue} from './secrets';

class BugsnagClass {
	constructor() {
		this.bugsnagEnabled = process.env.REACT_APP_RELEASE_STAGE === 'production';
	}
	setBugsnagObjectMetadata(report, errObj) {
		Object.keys(errObj).forEach(key => {
			report.addMetadata('metadata', key, errObj[key]);
		});
	}

	async initialize() {
		try {
			if (!this.bugsnagEnabled) return;
			const envData = await getSecretValue(
				RELEASE_STAGE === 'production'
					? 'HANGRY_ORDERHUB_PRODUCTION'
					: 'HANGRY_ORDERHUB_DEVELOPMENT',
			);

			const parsedEnvData = JSON.parse(base64.decode(envData));
			const BUGSNAG_API_KEY = RELEASE_STAGE === 'production' && get(parsedEnvData, 'BUGSNAG_API_KEY');

			if (this.bugsnagEnabled && BUGSNAG_API_KEY) {
				this.Bugsnag = Bugsnag.start({
					apiKey: BUGSNAG_API_KEY,
					autoBreadcrumbs: true,
					plugins: [new BugsnagPluginReact()],
					releaseStage: RELEASE_STAGE,
					appVersion: version,
					onError: async event => {
						const {username, userId} =
							store.getState().user || {};

						(username && userId) && event.setUser(userId, null, username);
					},
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	error(err) {
		if (this.Bugsnag) {
			Bugsnag.notify(err, report => {
				if (get(err, 'response.config')) {
					const {headers, method, params, data, url} = err.response.config;
					this.setBugsnagObjectMetadata(report, {
						headers: JSON.stringify(headers),
						params: JSON.stringify(params),
						requestData: JSON.stringify(data),
						responseData: JSON.stringify(err.response.data),
						status: err.response.status,
						method: method,
						url: url,
					});
				} else if (err.graphql) {
					this.setBugsnagObjectMetadata(report, err.graphql);
				} else {
					report.addMetadata('metadata', 'json', err);
				}

				if (get(err, 'message')?.includes?.('ResizeObserver loop completed with undelivered notifications')) {
					report.severity = 'warning';
				}
			});
		}
	}
}

export default new BugsnagClass();
