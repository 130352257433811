import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import capitalize from 'lodash/capitalize';

import LocalPageHeader from 'components/LocalPageHeader';

import {Button} from 'antd';
import {FormOutlined} from '@ant-design/icons';

import {authChecker} from 'utils/utils';
import {PROMO_CATEGORY, USER_ROLES} from 'utils/constants';

import localization from 'localization';
const locale = localization.Promo.Header;

const PromoRequestHeader = ({promoCategory}) => {
	const navigate = useNavigate();
	const auth = useSelector(state => state.user);

	const goToCreatePage = () => {
		switch (promoCategory) {
		case PROMO_CATEGORY.INTERNAL:
			navigate('/promo/promo-request/free-item/new');
			break;
		case PROMO_CATEGORY.EXTERNAL:
			navigate('/promo/promo-request/price-cut/new');
			break;
		default:
			break;
		}
	};

	return (
		<div>
			<LocalPageHeader
				headerTitle={capitalize(`Promo Request - ${promoCategory === PROMO_CATEGORY.EXTERNAL ? 'Price Cut' : 'Free Item'}`)}
				extra={authChecker({
					auth,
					requiredRolesArr: [USER_ROLES.SYNC_PROMO.ADMIN, USER_ROLES.SYNC_PROMO.EDITOR],
					children: [
						<Button
							key='new'
							onClick={() => goToCreatePage()}
							icon={<FormOutlined />}
							type='primary'>
							{locale.new.replace('{{promoCategory}}', promoCategory === PROMO_CATEGORY.EXTERNAL ? 'Price Cut' : 'Free Item')}
						</Button>,
					],
				})}
			/>
		</div>
	);
};

PromoRequestHeader.defaultProps = {
	promoCategory: null,
};

PromoRequestHeader.propTypes = {
	promoCategory: PropTypes.string,
};

export default PromoRequestHeader;
