import {useEffect, useRef, useState} from 'react';
import {getBrandList, getPlatformList} from 'utils/request/global';
import {handleErrorFetch} from 'utils/utils';
import PropTypes from 'prop-types';

// Local components
import Header from './Header';
import MetricCards from './MetricCards';
import OrderDetailModal from './OrderDetailModal';
import TableOrderList from './TableOrderList';
import VoidModal from './VoidModal';
import EditPairDrawer from './EditPairDrawer';

// Local styles
import './watchTower.less';

const initialEditPairState = {visible: false, selectedTrx: {}};
const initialOrderDetailState = {visible: false, data: {}};
const initialVoidModalState = {visible: false, data: {}};

const WatchTowerMenu = ({isPos}) => {
	const metricFormRef = useRef();
	const tableFormRef = useRef();

	const [editPairDrawer, setEditPairDrawer] = useState(initialEditPairState);
	const [orderDetailModal, setOrderDetailModal] = useState(initialOrderDetailState);
	const [voidModal, setVoidModal] = useState(initialVoidModalState);
	const [platformList, setPlatformList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [dataCount, setDataCount] = useState({});

	useEffect(async () => {
		try {
			const platformReponse = await getPlatformList();
			const brandResponse = await getBrandList();

			if (platformReponse.success) setPlatformList(platformReponse.data.rows?.sort?.((a, b) => a.label.localeCompare(b.label)));
			if (brandResponse.success) setBrandList(brandResponse.data.rows?.sort?.((a, b) => a.label.localeCompare(b.label)));
		} catch (error) {
			handleErrorFetch(error);
		}
	}, []);

	// Render
	return (
		<div className="bg-white h-full overflow-auto WatchTowerOrder">
			<VoidModal
				open={voidModal.visible}
				orderData={voidModal.data}
				onClose={() => setVoidModal(initialVoidModalState)}
				tableFormRef={tableFormRef}
			/>
			<EditPairDrawer
				visible={editPairDrawer.visible}
				selectedTrx={editPairDrawer.selectedTrx}
				close={() => setEditPairDrawer(initialEditPairState)}
			/>
			<Header />
			<MetricCards
				ref={metricFormRef}
				tableFormRef={tableFormRef}
				brandList={brandList}
				setDataCount={setDataCount}
				isPos={isPos}
			/>
			<TableOrderList
				isPos={isPos}
				dataCount={dataCount}
				ref={tableFormRef}
				metricFormRef={metricFormRef}
				onClickDetail={data => setOrderDetailModal({visible: true, data})}
				onClickEditPair={selectedTrx => setEditPairDrawer({visible: true, selectedTrx})}
				onClickVoid={data => setVoidModal({visible: true, data})}
				platformList={platformList}
			/>
			<OrderDetailModal
				isPos={isPos}
				withoutModal={false}
				visible={orderDetailModal.visible}
				data={orderDetailModal.data}
				onClose={() => setOrderDetailModal(initialOrderDetailState)} />
		</div>
	);
};

WatchTowerMenu.defaultProps = {
	isPos: false,
};

WatchTowerMenu.propTypes = {
	isPos: PropTypes.bool,
};

export default WatchTowerMenu;
