import React, {forwardRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import ProTable from '@ant-design/pro-table';

import {handleErrorFetch, momentDateFormat} from 'utils/utils';
import {getCheckerData} from 'utils/request/sales';

const Table = forwardRef(({platform, metricFormRef}, ref) => {

	const columns = [
		{
			key: 'sales_date',
			dataIndex: 'sales_date',
			title: 'Sales Date',
			render: createdAt => momentDateFormat({dateString: createdAt}),
		},
		{
			key: 'external_id',
			dataIndex: 'external_id',
			title: 'Long ID',
		},
		{
			key: 'brand',
			dataIndex: 'brand',
			title: 'Brand',
		},
		{
			key: 'branch_name',
			dataIndex: 'branch_name',
			title: 'Branch',
		},
		{
			key: 'count',
			dataIndex: 'count',
			title: 'Record Count',
		},
	];

	const fetchTableData = async params => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */


			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
			};

			const response = await getCheckerData({params: payload, platform});

			metricFormRef?.current?.updateMetricData?.({
				external_id_count: response.data.externalIdChecker.count,
				duplicate_sales_number_count: response.data.salesNumberChecker.rows.length,
				duplicate_sales_number_data: response.data.salesNumberChecker.rows,
			});

			return {
				data: response.data.externalIdChecker.rows,
				success: response.success,
				total: response.data.externalIdChecker.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		ref?.current?.reset?.();
	}, [platform]);

	return (
		<ProTable
			actionRef={ref}
			columns={columns}
			search={false}
			options={false}
			scroll={{x: 'max-content'}}
			request={fetchTableData}
		/>
	);
});

Table.defaultProps = {
	platform: null,
	metricFormRef: {},
};

Table.propTypes = {
	platform: PropTypes.string,
	metricFormRef: PropTypes.object,
};

export default Table;