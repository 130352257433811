import React from 'react';
import PropTypes from 'prop-types';

import {Button, Modal} from 'antd';
import ProTable from '@ant-design/pro-table';

import {handleErrorFetch, momentDateFormat} from 'utils/utils';
import {getMenuSyncRequest, getMenuTemplateDetail} from 'utils/request/salesChannel';

import localization from 'localization';
const locale = localization.MegaMenuTemplate;

const PreviousMegaTemplateModal = ({visible, onClose, onSelectTemplate}) => {

	const beforeSelectTemplate = async requestData => {
		try {
			const menuTemplateresponse = await getMenuTemplateDetail({id: requestData.menuTemplateId});
			if (menuTemplateresponse.success) {
				onSelectTemplate({
					requestData,
					templateData: menuTemplateresponse?.data?.details,
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const columns = [
		{
			width: 206,
			key: 'menuTemplateId',
			dataIndex: 'menuTemplateId',
			title: locale.megaMenuTemplateId,
		},
		{
			key: 'menuTemplateLabel',
			dataIndex: ['MenuTemplate', 'label'],
			title: locale.megaMenuTemplateLabel,
		},
		{
			key: 'requestedBy',
			dataIndex: ['requester', 'username'],
			title: locale.requestedBy,
			search: false,
		},
		{
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: locale.createdAt,
			search: false,
			sorter: true,
			defaultSortOrder: 'descend',
			render: createdAt => momentDateFormat({dateString: createdAt, format: 'DD MMM YYYY - HH:mm'}),
		},
		{
			key: 'action',
			title: locale.action,
			search: false,
			align: 'center',
			render: (_, record) => (
				<a
					className='text-antd-blue-6'
					onClick={() => beforeSelectTemplate(record)}>{'Select'}</a>
			),
		},
	];

	const sortDirectionConverter = direction => {
		switch (direction) {
		case 'ascend':
			return 'asc';
		case 'descend':
			return 'desc';
		default:
			return 'desc';
		}
	};

	const fetchTableData = async (params, sorter) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				type: 'internal',
				sortBy: 'createdAt',
				sortOrder: sortDirectionConverter(sorter.createdAt),
				menuTemplateId: params?.menuTemplateId || null,
				menuTemplateLabel: params?.menuTemplateLabel,
				requestedBy: params?.requestedBy,
			};
			const response = await getMenuSyncRequest(payload);
			return {
				data: response.rows,
				success: response.count,
				total: response.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<Modal
			destroyOnClose={true}
			visible={visible}
			onCancel={onClose}
			title={'Select Mega Menu Template'}
			footer={null}
			centered
			width={965}
		>
			<ProTable
				className='ResetSearchTableMargin SettlementTable'
				columns={columns}
				options={false}
				search={{
					layout: 'vertical',
				}}
				scroll={{x: 'max-content'}}
				pagination={{
					defaultPageSize: 5,
				}}
				request={fetchTableData}
			/>
			<div className='flex justify-end'>
				<Button onClick={onClose}>{'Cancel'}</Button>
			</div>
		</Modal>
	);
};

PreviousMegaTemplateModal.defaultProps = {
	visible: false,
	onClose: () => null,
	onSelectTemplate: () => null,
};

PreviousMegaTemplateModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	onSelectTemplate: PropTypes.func,
};

export default PreviousMegaTemplateModal;