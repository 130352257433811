import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate, useParams} from 'react-router-dom';

// Local components
import LocalPageHeader from 'components/LocalPageHeader';

import startCase from 'lodash/startCase';
import {Button, Card, Form, Input, Modal, Radio, Select, Transfer, notification} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';

import {handleErrorFetch} from 'utils/utils';
import {PAYMENT_METHOD_ACTION_TYPE} from 'utils/constants';
import {createPosPayment, editPosPayment, getPaymentGroup, getPaymentType, getPosPaymentDetail, getSalesChannel} from 'utils/request/posSetting';

import localization from 'localization';
const locale = localization.POSSetting.Payment.Form;

const PaymentForm = ({isEdit, isDetail}) => {
	const params = useParams();
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const salesChannelValue = Form.useWatch('salesChannel', form); // Used to provide value for targetKeys
	const applyToAll = Form.useWatch('includeAllChannels', form); // Used to conditionally render transfer

	const [loading, setLoading] = useState(false);
	const [salesChannels, setSalesChannels] = useState([]);
	const [paymentTypes, setPaymentTypes] = useState([]);
	const [paymentGroups, setPaymentGroups] = useState([]);
	const [previousValue, setPreviousValue] = useState({}); // Used for existing payment method

	const goBack = () => navigate('/pos-settings/payment');

	const hasSameValues = (array1, array2) => {
		if (array1.length !== array2.length) {
			return false; // Different lengths, can't have the same values
		}

		const set1 = new Set(array1);
		const set2 = new Set(array2);

		if (set1.size !== set2.size) {
			return false; // Different number of unique values
		}

		for (const value of set1) {
			if (!set2.has(value)) {
				return false; // Value not found in the other set
			}
		}

		return true; // All values match
	};

	const handleSubmit = async e => {
		try {
			setLoading(true);
			const payload = {
				name: e.name,
				type: e.type,
				groupId: e.groupId,
				isActive: e.isActive,
				includeAllChannels: e.includeAllChannels,
				salesChannelIds: e.includeAllChannels === true ? salesChannels.map(channel => channel.id) : e.salesChannel,
				actionType: e.actionType,
			};

			const submitFn = isEdit ? editPosPayment : createPosPayment;
			if (isEdit) payload.id = previousValue.id;

			const response = await submitFn(payload);

			if (response.success) {
				const notificationLocale = isEdit ? locale.SuccessEdit : locale.SuccessCreate;
				notification.success({
					message: notificationLocale.message,
					description: notificationLocale.description,
				});
				goBack();
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const beforeSubmit = async e => {
		if (isEdit) {
			const previouslyAllSalesChannel = previousValue.includeAllChannels;
			if (previouslyAllSalesChannel === false && e.includeAllChannels === true) {
				Modal.confirm({
					centered: true,
					title: locale.ChangeDefaultPayment.message,
					icon: <ExclamationCircleOutlined />,
					content: locale.ChangeDefaultPayment.description,
					okText: locale.ChangeDefaultPayment.ok,
					cancelText: locale.ChangeDefaultPayment.cancel,
					onOk() {
						handleSubmit(e);
					},
					okButtonProps: {
						danger: true,
					},
				});
			} else {
				handleSubmit(e);
			}
		} else {
			handleSubmit(e);
		}
	};

	const initialFetch = async () => {
		try {

			const typeResponse = await getPaymentType();
			const groupResponse = await getPaymentGroup();
			const salesChannelResponse = await getSalesChannel(({params: {}}));

			if (typeResponse.success && groupResponse.success && salesChannelResponse.success) {
				setPaymentTypes(typeResponse.data);
				setSalesChannels(salesChannelResponse.data.rows);
				setPaymentGroups(groupResponse.data);
			}

			if (isEdit || isDetail) {
				const previousValueResponse = await getPosPaymentDetail(params?.id);
				if (previousValueResponse.success) {
					setPreviousValue(previousValueResponse.data);
				} else {
					return goBack();
				}

				const flattenPreviousValueSalesChannel = previousValueResponse.data.salesChannels.map(item => item.id);
				const flattenSalesChannels = salesChannelResponse.data.rows.map(item => item.id);

				const isApplyToAllSalesChannel = hasSameValues(
					flattenPreviousValueSalesChannel,
					flattenSalesChannels,
				);

				form.setFieldsValue({
					isActive: previousValueResponse.data.isActive,
					name: previousValueResponse.data.name,
					type: startCase(previousValueResponse.data.type),
					groupId: previousValueResponse.data.group?.id,
					includeAllChannels: previousValueResponse.data.includeAllChannels,
					salesChannel: isApplyToAllSalesChannel ? flattenSalesChannels : flattenPreviousValueSalesChannel,
					actionType: previousValueResponse.data.actionType,
				});
			}

		} catch (error) {
			handleErrorFetch(error);
			goBack();
		}
	};

	useEffect(() => {
		initialFetch();
	}, []);

	return (
		<div className="bg-white h-full overflow-auto">
			<LocalPageHeader
				headerTitle={isDetail ? previousValue.name : isEdit ? locale.titleEdit : locale.titleNew}
				newType={'Payment'}
				showBackButton={true}
				backButtonAction={goBack}
			/>
			<Form
				disabled={isDetail}
				form={form}
				layout="vertical"
				name="adminUserForm"
				scrollToFirstError
				onFinish={e => !loading && beforeSubmit(e)}
				onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
				requiredMark={false}
			>
				<Card className='mx-6 mb-6 flex flex-col gap-6'>

					{/* Payment Name Field */}
					<Form.Item
						className='w-96'
						label={locale.paymentName}
						name="name"
						rules={[{required: true}]}
					>
						<Input placeholder={locale.paymentNamePlaceholder} />
					</Form.Item>

					{/* Payment Type Field */}
					<Form.Item
						className='w-96'
						label={locale.paymentType}
						name="type"
						rules={[{required: true}]}
					>
						<Select
							placeholder={locale.paymentTypePlaceholder}
							allowClear
							options={paymentTypes.map(type => {
								return {
									value: type,
									label: type,
								};
							})}
						/>
					</Form.Item>

					{/* Payment Group Field */}
					<Form.Item
						className='w-96'
						label={locale.paymentGroup}
						name="groupId"
						rules={[{required: true}]}
					>
						<Select
							placeholder={locale.paymentGroupPlaceholder}
							allowClear
							options={paymentGroups.map(group => {
								return {
									value: group.id,
									label: group.name,
								};
							})}
						/>
					</Form.Item>

					{/* Payment Action Type Field */}
					<Form.Item
						className='w-96'
						label={locale.actionTypeLabel}
						name="actionType"
						rules={[{required: true}]}
					>
						<Select
							placeholder={locale.actionTypePlaceholder}
							allowClear
							options={PAYMENT_METHOD_ACTION_TYPE.map(actionType => {
								return {
									value: actionType.value,
									label: actionType.label,
								};
							})}
						/>
					</Form.Item>

					{/* isActive Field */}
					<Form.Item
						className='w-96'
						label={locale.isActive}
						name="isActive"
						rules={[{required: true}]}
						initialValue={1}
					>
						<Radio.Group>
							<Radio
								key={'yes'}
								value={true}>{locale.yes}</Radio>
							<Radio
								key={'no'}
								value={false}>{locale.no}</Radio>
						</Radio.Group>
					</Form.Item>

					{/* Sales Channel Field */}
					<Form.Item
						className='w-96'
						label={locale.applyToAllSalesChannel}
						name="includeAllChannels"
						rules={[{required: true}]}
						initialValue={1}
					>
						<Radio.Group>
							<Radio
								key={'yes'}
								value={true}>{locale.yes}</Radio>
							<Radio
								key={'no'}
								value={false}>{locale.no}</Radio>
						</Radio.Group>
					</Form.Item>

					{!applyToAll && (
						<Form.Item
							label={locale.salesChannel}
							name="salesChannel"
							rules={[{required: true}]}
						>
							<Transfer
								disabled={isDetail}
								listStyle={{flexGrow: '1', height: 350}}
								dataSource={salesChannels.map(e => ({
									...e,
									key: e.id,
								}))}
								targetKeys={salesChannelValue}
								titles={[locale.allSalesChannel, locale.selectedSalesChannel]}
								render={item => item.name}
								showSearch
								filterOption={(input, option) => {
									return option.name.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
								}}
							/>
						</Form.Item>
					)}

					{/* Action */}
					{
						!isDetail && (
							<div className='flex gap-2 justify-end'>
								<Button
									onClick={goBack}
									loading={loading}>
									{locale.cancel}
								</Button>
								<Button
									loading={loading}
									type="primary"
									htmlType="submit">
									{locale.save}
								</Button>
							</div>
						)
					}
				</Card>
			</Form>
		</div>
	);
};

PaymentForm.defaultProps = {
	isEdit: false,
	isDetail: false,
};

PaymentForm.propTypes = {
	isEdit: PropTypes.bool,
	isDetail: PropTypes.bool,
};

export default PaymentForm;