import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input, notification, Typography} from 'antd';
const {Title} = Typography;

import FRESTOBLACKLOGO from 'assets/images/fresto-black-logo.svg';

import {useDispatch} from 'react-redux';
import jwt_decode from 'jwt-decode';
import {setUserData} from 'store/actions/user';

import {userLogin} from 'utils/request/global';
import {AUTH, STORAGE_KEY} from 'utils/constants';
import axios from 'utils/axios';

import localization from 'localization';
const locale = localization.LoginPage;

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const [loading, setLoading] = useState(false);

	const handleLogin = async () => {
		try {
			setLoading(true);
			const response = await userLogin({username, password});
			if (response?.token) {
				localStorage.setItem(AUTH.ACCESS_TOKEN, response.token);
				axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(AUTH.ACCESS_TOKEN)}`;
				const decodedToken = jwt_decode(response.token);
				if (decodedToken.session) {
					dispatch(setUserData({
						...decodedToken.session,
						loggedIn: true,
					}));

					const firstLoginEntry = sessionStorage.getItem(STORAGE_KEY.REDIRECT_TO_PATH) || '/';
					navigate(firstLoginEntry, {replace: true});

					sessionStorage.removeItem(STORAGE_KEY.REDIRECT_TO_PATH);
				}
			}
		} catch (error) {
			notification.open({
				message: 'Error',
				description: error?.response?.data?.message || error?.message ||'Please Contact Tech Team', // TODO
				type: 'warning',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className='flex flex-col items-center justify-center w-screen h-screen bg-antd-page-background'>

			<div className='flex flex-col gap-8'>
				<div>
					<img
						src={FRESTOBLACKLOGO} />
				</div>
				<div className='bg-white p-10 rounded-md flex flex-col gap-5'>
					<div>
						<Title level={5} >{locale.signIn}</Title>
						<div className='text-overlay-40'>{locale.signInDescription}</div>
					</div>

					<Form
						name="basic"
						initialValues={{remember: true}}
						requiredMark={false}
						layout='vertical'
						onFinish={handleLogin}
						autoComplete="off"
					>
						<Form.Item
							className='w-full md:w-96'
							label={locale.usernameLabel}
							name="username"
							rules={[{required: true, message: locale.usernameInputError}]}
						>
							<Input
								prefix={<UserOutlined className='text-antd-blue-6' />}
								onChange={e => setUsername(e.target.value)}
								placeholder={locale.usernamePlaceholder} />
						</Form.Item>

						<Form.Item
							className='w-full md:w-96'
							label={locale.passwordLabel}
							name="password"
							rules={[{required: true, message: locale.passwordInputError}]}
						>
							<Input.Password
								prefix={<LockOutlined className='text-antd-blue-6' />}
								onChange={e => setPassword(e.target.value)}
								placeholder={locale.passwordPlaceholder} />
						</Form.Item>

						<Form.Item>
							<Button
								loading={loading}
								type="primary"
								htmlType="submit">
								{locale.signIn}
							</Button>
						</Form.Item>
					</Form>

				</div>
			</div>

		</div>
	);
};

export default Login;
