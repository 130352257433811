import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Button} from 'antd';
import {EditOutlined} from '@ant-design/icons';

import LocalPageHeader from 'components/LocalPageHeader';

import localization from 'localization';
const locale = localization.Admin.LocationGroup.Header;

const LocationGroupHeader = () => {
	const navigate = useNavigate();

	return (
		<div>
			<LocalPageHeader
				headerTitle={locale.title}
				extra={(
					<Button
						onClick={() => navigate('/admin/version-settings/location-group/new')}
						type='primary'
						icon={<EditOutlined />}
					>{locale.add}</Button>
				)}
			/>
		</div>
	);
};

export default LocationGroupHeader;