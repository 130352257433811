import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {Tabs} from 'antd';

import './trainingSession.less';
import Header from './Header';
import OrderTemplate from './OrderTemplate';
import OrderHistory from './OrderHistory';

import {handleErrorFetch} from 'utils/utils';
import {getPlatformList} from 'utils/request/global';
import {getTrainingOrderList} from 'utils/request/outlet';

import localization from 'localization';
const locale = localization.Outlet.Training.Session;

const TrainingSession = () => {
	const {trainingId} = useParams();
	const orderHistoryRef = useRef(null);

	const [sessionData, setSessionData] = useState({});
	const [orderTemplate, setOrderTemplate] = useState([]);
	const [platformList, setPlatformList] = useState([]);

	const tabs = [
		{key: 'orderTemplate', label: locale.OrderTemplate.title, element: <OrderTemplate
			sessionData={sessionData}
			template={orderTemplate}
			refetchData={() => fetchData()}
			platformList={platformList}
		/>},
		{key: 'orderHistory', label: locale.OrderHistory.title, element: <OrderHistory
			sessionData={sessionData}
			platformList={platformList}
			ref={orderHistoryRef}
		/>},
	];

	const fetchData = async () => {
		try {
			// Update order template & session
			const response = await getTrainingOrderList(trainingId);
			if (isEmpty(response.training)) {
				// Training Session doesnt exist
			} else {
				setSessionData(response.training);
				setOrderTemplate(response.trainingOrders);
			}

			// Update order history
			orderHistoryRef?.current?.reload?.();
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			// resetInterval();
		}
	};

	const initialFetch = async () => {
		try {
			const response = await getPlatformList();
			if (response?.data?.rows?.length) {
				const platforms = response.data.rows;
				setPlatformList(platforms);
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		initialFetch();
		fetchData();
	}, []);

	return (
		<div className='TrainingSession bg-white h-full overflow-auto'>
			<Header
				sessionData={sessionData}
				refetchData={() => fetchData()}
			/>
			<Tabs>
				{tabs.map(each => (
					<Tabs.TabPane
						key={each.key}
						tab={each.label}>
						<div className='px-4'>
							{each.element}
						</div>
					</Tabs.TabPane>
				))}
			</Tabs>
		</div>
	);
};

export default TrainingSession;