import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// Local components
import PairCard from './PairCard';

import {Button, Divider, Drawer, Modal, Typography, notification} from 'antd';

import localization from 'localization';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {handleErrorFetch} from 'utils/utils';
import {editPairing, getPairingList} from 'utils/request/watchtower';
import {POS_PAIR_METHOD, POS_PAIR_STATUS} from 'utils/constants';
import moment from 'moment';

const EditPairDrawer = ({visible, selectedTrx, close}) => {
	const locale = localization.WatchTower.WatchTowerOrder.editPairDrawer;

	const [pairingList, setPairingList] = useState([]);
	const [selectedPairItem, setSelectedPairItem] = useState(null);

	const confirmEdit = async () => {
		Modal.confirm({
			title: locale.EditConfirmation.title,
			icon: <ExclamationCircleOutlined />,
			content: locale.EditConfirmation.subtitle,
			centered: true,
			cancelText: locale.cancel,
			okText: locale.editPairing,
			onOk: async () => {
				try {
					const response = await editPairing({
						manualOrderId: selectedTrx.id,
						merchantOrderId: selectedPairItem,
					});
					if (response.success) {
						close();
						notification.success({
							message: locale.Notification.Success.message,
							description: locale.Notification.Success.description,
						});
					}
				} catch (error) {
					handleErrorFetch(error, {
						message: locale.Notification.Failed.message,
						description: locale.Notification.Failed.description,
					});
				} finally {
					// setLoading(false);
				}
			},
		  });
	};

	const initialFetch = async () => {
		try {
			const response = await getPairingList({
				sortOrder: 'desc',
				sortBy: 'createdAt',
				locationId: selectedTrx?.Merchant?.Location?.id,
				brandId: selectedTrx?.Merchant?.Brand?.id,
				platformId: selectedTrx?.Merchant?.Platform?.id,
				pairStatus: [POS_PAIR_STATUS.MERGED, POS_PAIR_STATUS.PENDING, POS_PAIR_STATUS.PENDING_LATER],
				pairMethod: [POS_PAIR_METHOD.MANUAL],
				acceptedAtFrom: moment(selectedTrx?.acceptedAt).format('YYYY-MM-DD'),
				acceptedAtTo: moment(selectedTrx?.acceptedAt).format('YYYY-MM-DD'),
			});
			if (response.success) {
				const excludedIds = [selectedTrx?.id, selectedTrx?.merchantOrder?.id];
				setPairingList(response.data.rows.filter(item => !excludedIds.includes(item.id)));
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		if (visible) {
			initialFetch();
			setSelectedPairItem(null);
		}
	}, [visible]);

	return (
		<Drawer
			visible={visible}
			width={515}
			closable={false}
			onClose={close}
			bodyStyle={{
				padding: 0,
				display: 'flex',
			}}
			title={(
				<div className='flex flex-col'>
					<Typography.Title
						level={5}
						className='m-0'>{locale.title}</Typography.Title>
					<Typography.Text type='secondary'>{locale.subtitle}</Typography.Text>
				</div>
			)}
		>
			<div className='flex flex-1 flex-col'>
				<div className='px-6 pb-6 pt-4'>
					<PairCard
						isExisting
						data={selectedTrx.merchantOrder}
					/>
				</div>
				<Divider className='m-0' />
				<div className='flex-1 flex flex-col overflow-auto p-6 gap-2'>
					{
						pairingList.map(pairItem => (
							<PairCard
								key={pairItem.id}
								data={pairItem}
								checked={selectedPairItem == pairItem.id}
								onCheck={() => setSelectedPairItem(pairItem.id)}
							/>
						))
					}
				</div>
				<div className='p-6 gap-4 flex border-t'>
					<Button
						onClick={close}
						style={{width: 171}}>{locale.cancel}</Button>
					<Button
						disabled={!selectedPairItem}
						onClick={confirmEdit}
						type='primary'
						className='flex-1'>{locale.editPairing}</Button>
				</div>
			</div>
		</Drawer>
	);
};

EditPairDrawer.defaultProps = {
	visible: false,
	selectedTrx: {},
	close: () => null,
};

EditPairDrawer.propTypes = {
	visible: PropTypes.bool,
	selectedTrx: PropTypes.object,
	close: PropTypes.func,
};

export default EditPairDrawer;