import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {getMenuSyncRequest} from 'utils/request/salesChannel';
import {handleErrorFetch} from 'utils/utils';

import {Input, Modal, Table} from 'antd';
const {Search} = Input;

const queryParams = {
	limit: 5,
	status: 'processed, approved',
};

const ApprovalIdModal = ({visible, onClose, localeConfig, handleSelectItem}) => {
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState([]);

	const searchApproval = async approvalId => {
		try {
			setLoading(true);
			queryParams.id = approvalId;
			const templateListResponse = await getMenuSyncRequest(queryParams);
			setTableData(templateListResponse.rows);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: 'Approval ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Menu Template Name',
			dataIndex: ['MenuTemplate', 'label'],
			key: 'menuTemplateLabel',
			ellipsis: true,
		},
		{
			title: 'Platform',
			dataIndex: ['Platform', 'label'],
			key: 'platformLabel',
		},
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			render: item => (
				<a
					className='text-antd-blue-6'
					onClick={() => handleSelectItem(item)}>
					{localeConfig.selectTableData}
				</a >
			),
		},
	];

	useEffect(() => {
		if (!visible) return;
		searchApproval();
	}, [visible]);

	return (
		<Modal
			visible={visible}
			title={localeConfig.modalTitle}
			footer={null}
			onCancel={onClose}
			width={870}
			centered
		>
			<div className='flex flex-col gap-4'>
				<Search
					loading={loading}
					onSearch={value => searchApproval(value)}
					placeholder={localeConfig.searchPlaceholder}
					className='w-1/3'
				/>

				<Table
					loading={loading}
					dataSource={tableData}
					columns={columns}
					pagination={false}
				/>

			</div>
		</Modal>
	);
};

ApprovalIdModal.defaultProps = {
	visible: false,
	onClose: () => null,
	localeConfig: {
		modalTitle: '',
		searchPlaceholder: '',
		selectTableData: '',
	},
	handleSelectItem: () => null,
};

ApprovalIdModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	localeConfig: PropTypes.object,
	handleSelectItem: PropTypes.func,
};

export default ApprovalIdModal;