import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router';

import MasterlistTable from 'pages/Masterlist/MasterlistTable';
import MasterlistHeader from './MasterlistHeader';

import {exportMasterlist} from 'utils/request/masterlist';
import {exportToTsvFn, generateMasterlistCSVTemplate} from 'utils/utils';
import {MASTERLIST_TYPE} from 'utils/constants';
import MenuStructureTabs from './MenuStructureTabs';

const Masterlist = () => {
	const {masterlistType} = useParams();
	const [latestUpdateInfo, setLatestUpdateInfo] = useState({
		updatedAt: null,
		updater: '',
	});
	const [exportQuery, setExportQuery] = useState(null);

	const containerRef = useRef(null);
	const refetchTableRef = useRef(null);

	useEffect(() => {
		Object.values(MASTERLIST_TYPE).some(item => item === masterlistType)
			? null
			: window.location.replace(`${location.origin}/masterlist/menu`);
	}, []);

	const handleExport = async ({fileName, type = 'template'}) => {
		await exportToTsvFn({
			fileName,
			actionType: type,
			exportCall: () => exportMasterlist(exportQuery, masterlistType),
			tsvTemplate: generateMasterlistCSVTemplate(masterlistType),
		});
	};

	const scrollToTop = () => {
		if (containerRef.current) {
			containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
		}
	};

	return (
		<div
			ref={containerRef}
			className="bg-white h-full overflow-auto"
		>
			<MasterlistHeader
				latestUpdateInfo={latestUpdateInfo}
				masterlistType={masterlistType}
				refetchTableRef={refetchTableRef}
				handleExport={handleExport} />
			{
				masterlistType === MASTERLIST_TYPE.MENU_STRUCTURE
					? (
						<MenuStructureTabs />
					) : (
						<MasterlistTable
							setLatestUpdateInfo={setLatestUpdateInfo}
							masterlistType={masterlistType}
							refetchTableRef={refetchTableRef}
							scrollToTop={scrollToTop}
							setExportQuery={setExportQuery} />
					)
			}
		</div>
	);
};

export default Masterlist;
