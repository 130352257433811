import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Divider, Drawer, Modal, notification} from 'antd';
import localization from 'localization';
import {CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, ShoppingCartOutlined} from '@ant-design/icons';
import {calculateCartMenuprice, cartCompressHandler, moneyFormat} from 'utils/utils';
import {getPrintItems} from 'utils/request/salesChannel';
import {CO_LABEL_PAYLOAD} from 'utils/constants';
import OrderMenuDrawer from 'pages/SalesChannel/MenuTemplateForm/MenuTemplateFormBody/ManageCategory/OrderMenuDrawer';
import {cloneDeep, isFunction, set} from 'lodash';

const INITIAL_ORDER_DRAWER_DATA = {
	visible: false,
	cartItem: {},
	itemIndex: null,
};

const MenuTemplateCart = forwardRef(({cart, setCart, customFooter, title, viewOnly, hideButton}, ref) => {
	const locale = localization.MenuTemplate.MenuTemplateCart;

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [loadingPreview, setLoadingPreview] = useState(false);

	const [editDrawerConfig, setEditDrawerConfig] = useState(INITIAL_ORDER_DRAWER_DATA);

	const openDrawer = () => setDrawerOpen(true);
	const closeDrawer = () => setDrawerOpen(false);

	const flattenOption = orderItem => {
		let output = [];
		orderItem.optionGroups?.forEach(optionGroup => {
			output = output.concat(optionGroup.options);
		});
		return output;
	};

	const getMenuQty = () => {
		let qtyCount = 0;
		cart.forEach(item => qtyCount+= item.menuItem.quantity);
		return qtyCount;
	};

	const calcualteTotalCart = () => {
		let cartPrice = 0;
		cart.forEach(item => cartPrice+= calculateCartMenuprice(item, item.menuItem.quantity));
		return cartPrice;
	};

	const handlePreview = async () => {
		try {
			const payload = {
				items: cart.map(item => {
					return {
						menuItem: item.menuItem,
						mappingHash: item.mappingHash,
					};
				}),
			};

			const response = await getPrintItems(payload);
			if (response.success) {
				localStorage.setItem(CO_LABEL_PAYLOAD, JSON.stringify(response.data));
				window.open('/preview-co-label', '_blank');
			}
		} catch (error) {
		} finally {
			setLoadingPreview(false);
		}
	};

	const handleDelete = itemIndex => {
		Modal.confirm({
			title: locale.DeleteConfirmation.title,
			content: locale.DeleteConfirmation.description,
			icon: <ExclamationCircleOutlined />,
			centered: true,
			cancelText: locale.DeleteConfirmation.cancel,
			okText: locale.DeleteConfirmation.delete,
			okButtonProps: {danger: true},
			maskClosable: true,
			onOk: () => {
				const tempCart = cart.filter((_, i) => i !== itemIndex);
				setCart(tempCart);
				if (tempCart.length == 0) closeDrawer();
				notification.success({
					message: locale.DeleteSuccess.message,
					description: locale.DeleteSuccess.description,
				});
			},
		  });
	};

	const onClickEdit = (cartItem, itemIndex) => {
		setEditDrawerConfig({
			visible: true,
			cartItem,
			itemIndex,
		});
	};

	const handleSaveEdit = editedItem => {
		const cartTemp = cloneDeep(cart);
		set(cartTemp, editDrawerConfig.itemIndex, editedItem);
		const compressedCart = cartCompressHandler(cartTemp);
		setCart(compressedCart);
	};

	useImperativeHandle(ref, () => ({
		openDrawer() {
			openDrawer();
		},
	}));

	return (
		<>
			<Drawer
				destroyOnClose
				width={514}
				title={(
					<div className='flex justify-between'>
						<div>{title ? title : locale.title}</div>
						<CloseOutlined
							className='cursor-pointer'
							onClick={closeDrawer}
						/>
					</div>
				)}
				open={drawerOpen}
				onClose={closeDrawer}
				closable={false}
				headerStyle={{
					paddingInline: '24px',
					paddingBlock: '10px',
				}}
				bodyStyle={{
					padding: 0,
				}}
			>
				<OrderMenuDrawer
					visible={editDrawerConfig.visible}
					menuData={editDrawerConfig.cartItem?.dataOrigin}
					menuItem={editDrawerConfig.cartItem?.menuItem}
					onClose={() => setEditDrawerConfig(INITIAL_ORDER_DRAWER_DATA)}
					onSave={editedItem => handleSaveEdit(editedItem)}
				/>
				<div className='flex flex-col h-full'>
					<div className='flex-1 overflow-auto p-6'>
						{cart?.map?.((order, index) => {
							const orderEntry = order.menuItem;
							return (
								<div
									key={index}
									className='flex flex-col gap-4'>
									<div className='flex flex-col gap-6'>
										<div className='flex gap-3'>
											<div className='flex flex-shrink-0 font-bold'>{`x${orderEntry.quantity}`}</div>
											<div className='flex flex-col gap-3 flex-1'>
												<div className='font-bold'>{orderEntry.menuLabel}</div>
												{flattenOption(orderEntry).length
													? (
														<div className='flex flex-col gap-1'>
															{flattenOption(orderEntry).map(option => (
																<div key={option.menuId}>
																	{`x${orderEntry.quantity} ${option.menuLabel}`}
																</div>
															))}
														</div>
													)
													: null}
												{orderEntry.notes && <div>{`*${orderEntry.notes}`}</div>}
											</div>
											<div className='flex flex-shrink-0'>{moneyFormat({value: calculateCartMenuprice(order, orderEntry.quantity)})}</div>
										</div>
										{
											!viewOnly && (
												<div className='flex justify-end gap-3'>
													<Button
														onClick={() => onClickEdit(order, index)}
														icon={<EditOutlined />}
														size='small'>{locale.edit}</Button>
													<Button
														danger
														onClick={() => handleDelete(index)}
														icon={<DeleteOutlined />}
														size='small'>{locale.delete}</Button>
												</div>
											)
										}
									</div>
									<Divider />
								</div>
							);
						})}
					</div>
					<div className='p-6 border-t'>
						{
							viewOnly
								? (
									<div className='flex flex-col gap-2'>
										<div className='flex justify-between'>
											<div>{locale.subtotal}</div>
											<div>{moneyFormat({value: calcualteTotalCart()})}</div>
										</div>
										<Divider className='m-0' />
										<div className='flex justify-between'>
											<div>{locale.total}</div>
											<div className='font-bold'>{moneyFormat({value: calcualteTotalCart()})}</div>
										</div>
									</div>
								)
								: isFunction(customFooter)
									? customFooter()
									: (
										<Button
											loading={loadingPreview}
											onClick={handlePreview}
											disabled={!cart.length}
											type='primary'
											className='w-full'>
											{locale.preview}
										</Button>
									)
						}
					</div>
				</div>
			</Drawer>
			{
				!hideButton && (
					<Button
						type='primary'
						icon={<ShoppingCartOutlined />}
						onClick={openDrawer}>
						{
							locale.cartInfo
								.replace('{{cartPrice}}', moneyFormat({value: calcualteTotalCart()}))
								.replace('{{menuCount}}', getMenuQty())
						}
					</Button>
				)
			}
		</>
	);
});

MenuTemplateCart.defaultProps = {
	cart: [],
	setCart: () => null,
	customFooter: null,
	viewOnly: false,
	title: null,
	hideButton: false,
};

MenuTemplateCart.propTypes = {
	cart: PropTypes.array,
	setCart: PropTypes.func,
	customFooter: PropTypes.func,
	viewOnly: PropTypes.bool,
	title: PropTypes.string,
	hideButton: PropTypes.bool,
};

export default MenuTemplateCart;