import React, {useCallback} from 'react';
import {Form, Input, Select} from 'antd';
import PropTypes from 'prop-types';

import {OUTLET_TYPE} from 'utils/constants';
import debounce from 'lodash/debounce';

import localization from 'localization';
const locale = localization.Outlet.UserActivation.UserEditModal;

const DefaultForm = ({posRoles, isEdit, isLoading, searchOutletData, locationList}) => {

	const debounceFn = useCallback(debounce(searchOutletData, 1000), []);

	return (
		<>
			{
				!isEdit && (
					<Form.Item
						label={locale.nameLabel}
						name="username"
						rules={[{required: true, message: locale.nameLabelRequired}]}
					>
						<Input placeholder={locale.nameLabelPlaceholder} />
					</Form.Item>
				)
			}
			<Form.Item
				label={locale.posRoleLabel}
				name="rolesPreset"
				rules={[{required: true}]}
			>
				<Select placeholder={locale.posRoleLabelPlaceholder}>
					{
						posRoles.map(role => (
							<Select.Option
								key={role.roleName}
								value={role.roleName}
							>
								{role.roleName}
							</Select.Option>
						))
					}
				</Select>
			</Form.Item>
			{
				!isEdit && (
					<Form.Item
						label={locale.pinLabel}
						name="pin"
						rules={[
							{required: true},
							{min: 6, message: locale.pinLabelInfo},
						]}
					>
						<Input.Password
							placeholder={locale.pinLabelPlaceholder}
						/>
					</Form.Item>
				)
			}
			{
				!isEdit && (
					<Form.Item
						label={locale.locationLabel}
						name="locationId"
						rules={[{required: true}]}
					>
						<Select
							showSearch
							loading={isLoading}
							onSearch={value => debounceFn({value, type: OUTLET_TYPE.LOCATION})}
							placeholder={locale.locationLabelPlaceholder}
							filterOption={((_, option) => option)}
						>
							{
								locationList.map(location => (
									<Select.Option
										key={location.id}
										value={location.id}>{location.id} - {location.label}</Select.Option>
								))
							}
						</Select>
					</Form.Item>
				)
			}
		</>
	);
};

DefaultForm.defaultProps = {
	posRoles: [],
	locationList: [],
	isEdit: false,
	isLoading: false,
	searchOutletData: () => null,
};

DefaultForm.propTypes = {
	posRoles: PropTypes.array,
	locationList: PropTypes.array,
	isEdit: PropTypes.bool,
	isLoading: PropTypes.bool,
	searchOutletData: PropTypes.func,
};

export default DefaultForm;