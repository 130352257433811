import {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import {DatePicker, Select} from 'antd';
import moment from 'moment';
import debounce from 'lodash/debounce';

import {getLinkedMenuRequest, getMenuAvailability, getMenuAvailabilityHistory} from 'utils/request/watchtower';
import {dateFormat, handleErrorFetch} from 'utils/utils';
import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';
import {getBrandList, getPlatformList} from 'utils/request/global';

// Local components
import TemplateTable from '../../components/TemplateTable';
import ModalRelatedMenu from '../ModalRelatedMenu';
import ModalChangeReason from '../ModalChangeReason';


import localization from 'localization';
const locale = localization.WatchTower.WatchTowerMenu;

const defaultSearch = {
	menuLabel: null,
	createdBy: null,
	parentMenu: null,
	platformLabel: null,
	outletLabel: null,
	brandLabel: null,
	notes: null,
};

const TableMenuList = ({isFocus, isHistory, isChildMenu, menuAvailabilityReason}) => {
	const [menuListData, setData] = useState({});
	const [loading, setLoading] = useState(false);

	const [brandList, setBrandList] = useState([]);
	const [platformList, setPlatformList] = useState([]);
	const [location, setLocation] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);

	const [relatedParent, setRelatedParent] = useState({
		visible: false,
		data: {},
		parentMenuLabel: '',
	});

	const resetRefFlag = useRef(false);
	const changeReasonModalRef = useRef(null);

	const defaultQueryParams = {
		search: defaultSearch,
		page: 0,
		limit: 25,
		isChildMenu: isChildMenu,
		sortBy: 'updatedAt',
		sortOrder: 'desc',
	};

	const [queryParams, setQueryParams] = useState(defaultQueryParams);

	const {page, sortBy, sortOrder, limit, search} = queryParams;

	// Methods
	const getLocation = async locationLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: locationLabel},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocation(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 1000), []);

	const handleCheckLinkedMenu = async ({menuAvailabilityId, locationId, parentMenuLabel}) => {
		try {
			setLoading(true);
			const response = await getLinkedMenuRequest({menuAvailabilityId, locationId});
			if (response.success) {
				setRelatedParent({
					visible: true,
					data: response.data,
					parentMenuLabel: parentMenuLabel,
				});
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const closeRelatedParentMenu = () => {
		setRelatedParent({
			visible: false,
			data: {},
			parentMenuLabel: '',
		});
	};

	const timeConverter = seconds => {
		const day = 86400;
		const getHours = seconds => Math.ceil(seconds / 3600);
		if (seconds / day > 1) { // Above 1 day
			return `${(seconds / day).toFixed(0)} days` +
        (seconds % day ? ` ${getHours(seconds % day)} hours` : null);
		} else if (seconds > 3600) {
			return `${getHours(seconds)} hours`;
		} else {
			return `${Math.floor(seconds / 60)} minutes`;
		}
	};

	const isChild = defaultQueryParams.isChildMenu;
	const defaultColumns = [
		{
			key: 'menuLabel',
			dataIndex: 'menuLabel',
			title: isChild ? locale.childMenuList.childMenuName : locale.menuList.menuName,
		},
		{
			key: 'platformId',
			dataIndex: 'platformLabel',
			title: locale.menuList.platform,
			renderFormItem: () => (
				<Select
					allowClear
					placeholder={locale.menuList.selectPlaceholder}>
					{
						platformList.map(platform => (
							<Select.Option
								key={platform.id}
								value={platform.id}>{platform.label}</Select.Option>
						))
					}
				</Select>
			),
		},
		{
			key: 'locationId',
			dataIndex: 'outletLabel',
			title: locale.menuList.location,
			renderFormItem: () => (
				<Select
					allowClear
					showSearch
					loading={locationLoading}
					onSearch={debounceFn}
					placeholder={locale.menuList.selectPlaceholder}
					filterOption={((_, option) => option)}
				>
					{location.map(location => (
						<Select.Option
							key={location.id}
							value={location.id}>{location.label}</Select.Option>
					))}
				</Select>
			),
		},
		{
			key: 'brandId',
			dataIndex: 'brandLabel',
			title: locale.menuList.brand,
			renderFormItem: () => (
				<Select
					allowClear
					placeholder={locale.menuList.selectPlaceholder}>
					{
						brandList.map(brand => (
							<Select.Option
								key={brand.id}
								value={brand.id}>{brand.label}</Select.Option>
						))
					}
				</Select>
			),
		},
		{
			key: 'notes',
			dataIndex: 'notes',
			title: locale.menuList.inactiveReason,
			renderFormItem: () => (
				<Select
					allowClear
					placeholder={locale.menuList.selectPlaceholder}>
					{
						menuAvailabilityReason.map(notes => (
							<Select.Option
								key={notes}
								value={notes}>{notes}</Select.Option>
						))
					}
				</Select>
			),
		},
		{
			key: 'inactiveByMaterial',
			dataIndex: 'inactiveByMaterial',
			title: locale.menuList.inactiveByMaterial,
			search: false,
			render: (_, record) => record?.flagByMaterial === 'yes'
				? (
					<CheckCircleFilled
						style={{fontSize: 19.25}}
						className='text-antd-green-6' />
				)
				: (
					<CloseCircleFilled
						style={{fontSize: 19.25}}
						className='text-antd-red-5' />
				),
		},
		{
			key: 'materialLabel',
			dataIndex: 'materialLabel',
			title: locale.menuList.materialLabel,
		},
		{
			key: 'createdBy',
			dataIndex: 'createdBy',
			title: locale.menuList.createdBy,
			search: false,
		},
		{
			key: 'lastSync',
			dataIndex: 'lastSync',
			title: locale.menuList.lastSync,
			search: false,
			render: (_, record) => dateFormat(record.menuLastSyncAt),
		},
		{
			key: 'inactiveTime',
			dataIndex: 'deactivatedAt',
			title: locale.menuList.inactiveTime,
			search: isHistory ? false : true, // Inactive time only can be filter for inactive menu
			renderFormItem: () => (
				<DatePicker.RangePicker
					allowClear
					placeholder={[locale.menuList.startDate, locale.menuList.endDate]}
					ranges={{
						Today: [moment(), moment()],
					}}
					format='DD MMM Y'
				 />
			),
			render: deactivatedAt => (
				<div>{deactivatedAt !== '-' ? dateFormat(deactivatedAt) : '-'}</div>
			),
		},
		{
			key: 'downtime',
			title: locale.menuList.downtime,
			search: false,
			render: (_, record) => {
				let output = '-';
				if (record.deactivatedAt) {
					const startTime = new Date(record.deactivatedAt);
					const endTime = isHistory ? new Date(record.reactivatedAt) : new Date();
					const seconds = (endTime.getTime() - startTime.getTime()) / 1000;
					output = timeConverter(seconds);
				}
				return (
					<div>
						{output}
					</div>
				);
			},
		},
		{
			key: 'action',
			title: locale.menuList.action,
			align: 'center',
			fixed: 'right',
			search: false,
			width: 150,
			render: (_, record) => (
				<a
					className='text-antd-blue-6'
					onClick={() => changeReasonModalRef.current?.open(record.requestId)}>
					{locale.menuList.editReason}
				</a>
			),
		},
	];

	const addColumns = [
		isChildMenu && {
			index: 1,
			key: 'parentMenuRelated',
			title: locale.childMenuList.parentMenuRelated,
			width: 150,
			renderFormItem: (item, config) => {
				item.originProps.title = 'Parent Menu';
				return config.defaultRender(item);
			},
			render: (_, record) => (
				<span
					className='text-antd-blue-6 cursor-pointer'
					onClick={() => handleCheckLinkedMenu(
						{
							menuAvailabilityId: record.menuAvailabilityId,
							locationId: record.locationId,
							parentMenuLabel: record.menuLabel,
						},
					)}
				>{locale.childMenuList.seeRelatedMenu}</span>
			),
		},
		isHistory && {
			index: isChildMenu ? 9 : 8,
			key: 'reactiveTime',
			dataIndex: 'reactivatedAt',
			title: locale.menuList.reactivateTime,
			renderFormItem: () => (
				<DatePicker.RangePicker
					allowClear
					placeholder={[locale.menuList.startDate, locale.menuList.endDate]}
					ranges={{
						Today: [moment(), moment()],
					}}
					format='DD MMM Y'
			 />
			),
			render: deactivatedAt => (
				<div>{deactivatedAt !== '-' ? dateFormat(deactivatedAt) : '-'}</div>
			),
		},
	].filter(Boolean);

	const fetchMenuList = async (config = {
		reset: false,
	}) => {
		try {
			const {reset} = config;
			setLoading(true);
			let fetchResponse;
			let fetchFn = isHistory ? getMenuAvailabilityHistory : getMenuAvailability;

			if (reset) {
				resetRefFlag.current = true;
				setQueryParams({...defaultQueryParams, limit});
				fetchResponse = await fetchFn({...defaultQueryParams, limit});
			} else {
				fetchResponse = await fetchFn(queryParams);
			}

			if (fetchResponse.success) {
				setData(fetchResponse.data);
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			resetRefFlag.current = false;
			setLoading(false);
		}
	};

	const initialLoad = async () => {
		try {
			const brandResponse = await getBrandList();
			const platformResponse = await getPlatformList();

			setBrandList(brandResponse.data.rows);
			setPlatformList(platformResponse.data.rows);
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setTableLoading(false);
		}
	};


	// Hooks
	useEffect(() => {
		// To handle reset query when table is change
		if (!isFocus || resetRefFlag.current) return;
		fetchMenuList({reset: true});
	}, [isFocus, isHistory]);
	useEffect(() => {
		// To handle query on table
		if (!isFocus || resetRefFlag.current || loading) return;
		fetchMenuList();
	}, [page, sortBy, sortOrder, limit, search]);
	useEffect(() => {
		initialLoad();
	}, []);
	// Render
	return (
		<>
			<ModalRelatedMenu
				visible={relatedParent.visible}
				data={relatedParent.data}
				parentMenuLabel={relatedParent.parentMenuLabel}
				close={closeRelatedParentMenu}
			/>
			<ModalChangeReason
				fetchData={fetchMenuList}
				ref={changeReasonModalRef}
				menuAvailabilityReason={menuAvailabilityReason}
			/>
			<TemplateTable
				defaultColumns={defaultColumns}
				addColumns={addColumns}
				loading={loading || tableLoading}
				rows={menuListData.rows}
				total={menuListData.count}
				setQueryParams={setQueryParams}
				defaultQueryParams={defaultQueryParams}
				fetchData={fetchMenuList}
				queryParams={queryParams}
				isHistory={isHistory}
				menuAvailabilityReason={menuAvailabilityReason}
				defaultSearch={defaultSearch}
			/>
		</>
	);
};

TableMenuList.propTypes = {
	isFocus: PropTypes.bool,
	isHistory: PropTypes.bool,
	isChildMenu: PropTypes.bool,
	menuAvailabilityReason: PropTypes.array,
};

export default TableMenuList;
