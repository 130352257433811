import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import ReleaseSchedulerFormHeader from './ReleaseSchedulerFormHeader';
import ReleaseSchedulerFormBody from './ReleaseSchedulerFormBody';
import {useNavigate, useParams} from 'react-router-dom';
import {getReleaseSchedule} from 'utils/request/admin';
import {handleErrorFetch} from 'utils/utils';

const ReleaseSchedulerForm = ({isDetail}) => {
	const params = useParams();
	const navigate = useNavigate();

	const [scheduleData, setScheduleData] = useState({});

	const navigateBack = () => navigate('/admin/version-settings/release-scheduler');

	const getVersionData = async () => {
		try {
			const response = await getReleaseSchedule({search: {id: params.id}});
			if (response.success && response?.data?.rows?.length) {
				setScheduleData(response.data.rows[0]);
			} else throw {};
		} catch (error) {
			handleErrorFetch();
			navigateBack();
		}
	};

	useEffect(() => {
		if (isDetail) {
			if (params?.id) {
				getVersionData();
			} else {
				navigateBack();
			}
		}
	}, []);

	return (
		<div className='bg-white h-full overflow-auto'>
			<ReleaseSchedulerFormHeader
				isDetail={isDetail}
				scheduleData={scheduleData}
			/>
			<ReleaseSchedulerFormBody
				isDetail={isDetail}
				scheduleData={scheduleData}
			/>
		</div>
	);
};

ReleaseSchedulerForm.defaultProps = {
	isDetail: false,
};

ReleaseSchedulerForm.propTypes = {
	isDetail: PropTypes.bool,
};

export default ReleaseSchedulerForm;