import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';

import {CheckCircleFilled, ClockCircleOutlined, CloseCircleFilled} from '@ant-design/icons';
import {Descriptions, Timeline, Typography} from 'antd';
const {Item} = Descriptions;

import localization from 'localization';

// Local styles
import './orderDetailModal.less';

const ORDER_TYPE = {
	DELIVERY: 'delivery',
	TAKEAWAY: 'takeaway',
};

const MERCHANT_TIMELINE = [
	{value: 'acceptedAt', label: 'Order Accepted'},
	{value: 'driverFoundAt', label: 'Driver Found'},
	{value: 'driverArrivedAt', label: 'Driver Arrived'},
	{value: 'pickUpReadyAt', label: 'Order Ready to Pick Up', hAppOnly: true},
	{value: 'collectedAt', label: 'Order Picked Up'},
	{value: 'deliveredAt', label: 'Delivered'},
	{value: 'cancelledAt', label: 'Order Cancelled'},
	{value: 'failedAt', label: 'Failed'},
];

/**
 * Nots
 * Hangry App Special Case
 * 1. Have pickUpReadyAt timestamp
 * 2. Time Elapse end time is from pickUpReadyAt
 * 3. Show Ready to Pick Up instead of Picked Up in description
 */

const MerchantStatus = ({data, isPos}) => {
	const [orderTimeline, setOrderTimeline] = useState([]);
	const locale = localization.WatchTower.WatchTowerOrder.orderDetailModal.merchantStatus;
	const {Merchant, collectedAt} = data;

	const statusSource = isPos ? data?.Order || {} : data;

	const isHangryApp = data?.Merchant?.Platform?.label === 'Hangry App';
	const isTakeaway = data.type === ORDER_TYPE.TAKEAWAY;
	const isPickedUp = isHangryApp
		? data.statusSource ?? false
			? true
			: false
		: isTakeaway
			? data.statusSource ?? false ? true : false
			: collectedAt ?? false ? true : false;

	// Methods
	const getElapsedTime = () => {
		const startTime = moment(orderTimeline[0]?.value);
		const endTime = orderTimeline.length > 1
			? moment((isHangryApp && statusSource?.pickUpReadyAt)
				? statusSource.pickUpReadyAt
				: orderTimeline[orderTimeline.length - 1]?.value)
			: moment();

		const diffInSeconds = endTime.diff(startTime, 'seconds');
		return `${diffInSeconds >= 60 ? `${Math.floor(diffInSeconds / 60)} min ` : ''}${diffInSeconds % 60} sec`;
	};
	const getTimelineStyle = timelineKey => {
		const TimelineStyle = {
			dot: <ClockCircleOutlined />,
			color: null,
		};
		switch (timelineKey) {
		case 'collectedAt':
		case 'deliveredAt':
		case 'pickUpReadyAt':
			TimelineStyle.dot = <CheckCircleFilled />;
			TimelineStyle.color = 'green';
			if (timelineKey === 'collectedAt' && isHangryApp) TimelineStyle.color = 'blue';
			break;
		case 'cancelledAt':
		case 'failedAt':
			TimelineStyle.dot = <CloseCircleFilled />;
			TimelineStyle.color = 'red';
			break;
		}
		return TimelineStyle;
	};
	const getTimelineLabel = timeline => {
		const itemVal = timeline?.key || timeline?.val;
		switch (itemVal) {
		case 'cancelledAt':
			return statusSource?.cancelledReason ? `${timeline.label}: ${statusSource?.cancelledReason}` : timeline.label;
		case 'driverFoundAt': {
			let outputLabel = timeline.label;
			if (statusSource?.driver?.name) outputLabel += `: ${statusSource?.driver?.name}`;
			if (statusSource?.driver?.phone) outputLabel += ` - ${statusSource?.driver?.phone}`;
			return outputLabel;
		}
		default:
			return timeline.label;
		}
	};
	const getSortedTimestamps = timeline => {
		// Extract the keys that have timestamp values
		const merchantTimelineKeys = MERCHANT_TIMELINE.map(merchantTimeline => merchantTimeline.value);

		// Filter out null values and sort the timestamps
		const sortedTimestamps = merchantTimelineKeys
		  .map(key => ({key, value: timeline[key], label:
			getTimelineLabel(MERCHANT_TIMELINE.find(merchantTimeline => merchantTimeline.value == key))}))
		  .filter(item => item.value !== null)
		  .sort((a, b) => new Date(a.value) - new Date(b.value));

		return sortedTimestamps;
	  };
	// Lifecycle
	useEffect(() => {
		if (isEmpty(statusSource)) return;

		// Sorted by latest
		const orderTimelineTemp = getSortedTimestamps(statusSource)
			.filter(item => item.value);
		if ((isTakeaway && orderTimelineTemp[0].key === 'deliveredAt')) orderTimelineTemp[0].label = 'Picked Up';
		setOrderTimeline(orderTimelineTemp);
	}, []);
	// Render
	return (
		<Descriptions
			bordered
			className="WatchTowerOrderInfo">
			<Item label={locale.merchant}>
				{Merchant?.Platform?.label}
			</Item>
			<Item label={isHangryApp ? locale.readyToPickUp : locale.pickedUp}>
				{
					data?.cancelledAt
						? (<CloseCircleFilled className='text-2xl text-antd-red-5' />)
						: isPickedUp
							?	(<CheckCircleFilled className='text-2xl text-antd-green-6' />)
							: '-'
				}
			</Item>
			<Item label={locale.timeElapsed}>
				{getElapsedTime()}
			</Item>
			<Item
				label={locale.timeline}
				span={3}>
				<Timeline
					reverse
				>
					{
						orderTimeline?.length ? (
							orderTimeline.map(timeline => (
								<Timeline.Item
									key={timeline?.value}
									{...getTimelineStyle(timeline.key)}
								>
									<div>
										<div>
											<Typography.Text className='font-medium'>{timeline.label}</Typography.Text>
										</div>
										<div>
											<Typography.Text type='secondary'>
												{
													moment(timeline?.value).format('YYYY-MM-DD HH:mm:ss')
												}
											</Typography.Text>
										</div>
									</div>
								</Timeline.Item>
							),
							)
						) : null
					}
				</Timeline>
			</Item>
		</Descriptions>
	);
};
MerchantStatus.defaultProps = {
	data: {},
	isPos: false,
};

MerchantStatus.propTypes = {
	data: PropTypes.object,
	isPos: PropTypes.bool,
};

export default MerchantStatus;
