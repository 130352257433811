import React from 'react';
import PropTypes from 'prop-types';

import {Typography} from 'antd';
import clsx from 'clsx';

import {moneyFormat} from 'utils/utils';

import localization from 'localization';
const locale = localization.Sales.TaxDashboard.ReceiptDrawer;

const OrderItems = ({itemsData}) => {

	const ItemWrapper = (params = {
		isParent: false,
		quantity: 1,
		parentQuantity: 1,
		menuLabel: '',
		menuPrice: 0,
	}) => (
		<div className={clsx('flex gap-4 justify-between', params.isParent && 'font-bold text-overlay-80')}>
			<div className='flex gap-4 max-w-xs break-all'>
				<div>{params.quantity}</div>
				<div>{params.menuLabel}</div>
			</div>
			<div>{moneyFormat({value: ((params.isParent ? 1 : params.parentQuantity) * params.quantity * params.menuPrice), includeCurrency: false})}</div>
		</div>
	);

	const renderSubmenusText = (optionGroups = [], parentQuantity = 1) => {
		const flattenOptions = optionGroups.reduce((acc, group) => acc.concat(group?.options || []), []);
		if (flattenOptions.length) {
			return flattenOptions.map((option, i) => (
				<div
					className='pl-6'
					key={i}>
					{ItemWrapper({
						quantity: option.quantity,
						menuLabel: option.menuLabel,
						menuPrice: option.menuPrice,
						parentQuantity: parentQuantity,
					})}
				</div>
			));
		}
	};

	const renderSubMenuAndNotes = (optionGroups, notes, parentQuantity) => {
		return (
			<React.Fragment>
				{renderSubmenusText(optionGroups, parentQuantity)}
				{notes ? (
					<div className='pl-6 flex flex-col'>
						<Typography.Text type='secondary'>{locale.notes}</Typography.Text>
						{notes}
					</div>
				) : null}
			</React.Fragment>
		);
	};

	return (
		<div className='p-6 flex flex-col gap-4'>
			{itemsData?.items?.map?.(({menuItem}) => (
				<div key={menuItem.menuId}>
					<div className='flex flex-col gap-4'>
						{ItemWrapper({
							isParent: true,
							quantity: menuItem.quantity,
							menuLabel: menuItem.menuLabel,
							menuPrice: menuItem.price,
						})}
						{renderSubMenuAndNotes(menuItem.optionGroups, menuItem.notes, menuItem.quantity)}
					</div>
				</div>
			))}
		</div>
	);
};

OrderItems.defaultProps = {
	itemsData: {},
};

OrderItems.propTypes = {
	itemsData: PropTypes.object,
};

export default OrderItems;