import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate, useParams} from 'react-router-dom';

import VersionUploaderFormHeader from './VersionUploaderFormHeader';
import VersionUploaderFormBody from './VersionUploaderFormBody';
import VersionUploaderFormLoading from './VersionUploaderFormLoading';
import {handleErrorFetch} from 'utils/utils';
import {getUploadedVersion} from 'utils/request/admin';

const uploadModalInitialState = {
	visible: false,
	uploadingTitle: null,
	completeTitle: null,
};

const VersionUploaderForm = ({isEdit, isDetail}) => {
	const params = useParams();
	const navigate = useNavigate();

	const [versionData, setversionData] = useState({});
	const [loadingData, setLoadingData] = useState((isEdit || isDetail) ? true : false);
	const [uploadModalConfig, setUploadModalConfig] = useState(uploadModalInitialState);

	const openProgressModal = ({uploadingTitle, completeTitle}) => {
		setUploadModalConfig({
			visible: true,
			uploadingTitle,
			completeTitle,
		});
	};

	const closeProgressModal = () => setUploadModalConfig(uploadModalInitialState);

	const navigateBack = () => navigate('/admin/version-settings/version-uploader');

	const getVersionData = async () => {
		try {
			setLoadingData(true);
			const response = await getUploadedVersion({search: {id: params.id}});
			if (response.success && response?.data?.rows?.length) {
				setversionData(response.data.rows[0]);
			} else throw {};
		} catch (error) {
			handleErrorFetch();
			navigateBack();
		} finally {
			setLoadingData(false);
		}
	};

	useEffect(() => {
		if (isEdit || isDetail) {
			if (params?.id) {
				getVersionData();
			} else {
				navigateBack();
			}
		}
	}, []);

	return (
		<>
			<VersionUploaderFormLoading
				visible={uploadModalConfig.visible}
				uploadingTitle={uploadModalConfig.uploadingTitle}
				completeTitle={uploadModalConfig.completeTitle}
			/>
			<div className='bg-white h-full overflow-auto'>
				<VersionUploaderFormHeader
					versionData={versionData}
					isEdit={isEdit}
					isDetail={isDetail}
				/>
				{
					!loadingData && (
						<VersionUploaderFormBody
							openProgressModal={openProgressModal}
							closeProgressModal={closeProgressModal}
							versionData={versionData}
							isEdit={isEdit}
							isDetail={isDetail}
						/>
					)
				}
			</div>
		</>
	);
};

VersionUploaderForm.defaultProps = {
	isEdit: false,
	isDetail: false,
};

VersionUploaderForm.propTypes = {
	isEdit: PropTypes.bool,
	isDetail: PropTypes.bool,
};

export default VersionUploaderForm;