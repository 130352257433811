import React from 'react';
import SyncStatusHeader from './SyncStatusHeader';
import SyncStatusTable from './SyncStatusTable';

const SyncStatus = () => {
	return (
		<div className="bg-white h-full overflow-auto">
			<SyncStatusHeader />
			<SyncStatusTable />
		</div>
	);
};

export default SyncStatus;
