import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Modal, Select} from 'antd';

import SettingSelect from '../SettingSelect';

import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm.ManageCategory.OutletSettingDrawer;

const initialSetting = {
	type: 'default',
	locationIds: [-1],
};

const BulkSettingModal = ({visible, close, checkedMenuIndex, onOk, menus}) => {
	const [setting, setSetting] = useState(initialSetting);
	const [availableSettings, setAvailableSettings] = useState([]);

	const Header = () => (
		<div>
			<div className='font-medium'>{locale.SettingModal.title.replace('{{qty}}', checkedMenuIndex.length)}</div>
			<div className='text-overlay-40 font-normal'>{locale.SettingModal.description}</div>
		</div>
	);

	const handleChange = ({value, field}) => {
		const newSetting = {};

		if (field === 'locationIds') {
			newSetting.locationIds = value;
		}

		if (field === 'type') {
			newSetting.type = value;
			if (value === 'default') newSetting.locationIds = [-1];
		}

		setSetting(state => ({
			...state,
			...newSetting,
		}));
	};

	const handleUseExistSetting = menuId => {
		if (!menuId) return;
		const settingSource = availableSettings.find(menu => menu.menuId === menuId);
		setSetting({
			type: settingSource.locationFilter.type,
			locationIds: settingSource.locationFilter.locationIds,
		});
	};

	const handleClearSelect = () => {
		setSetting(initialSetting);
	};

	useEffect(() => {
		if (!visible) return;
		const tempAvailableSettings = menus.filter(menu => ['include', 'exclude'].includes(menu.locationFilter.type));
		setAvailableSettings(tempAvailableSettings);
		setSetting(initialSetting);
	}, [visible]);

	return (
		<Modal
			centered
			title={Header()}
			visible={visible}
			width={638}
			closable={false}
			cancelText={locale.cancel}
			okText={locale.save}
			onOk={() => onOk(setting)}
			onCancel={close}
			destroyOnClose
		>
			<div className='flex flex-col gap-4'>
				<div className='flex flex-col gap-2'>
					<div>{locale.SettingModal.selectFromExistingLabel}</div>
					<Select
						onClear={handleClearSelect}
						allowClear
						className='w-full'
						placeholder={locale.SettingModal.selectFromExistingPlaceholder}
						disabled={availableSettings.length === 0}
						onChange={e => handleUseExistSetting(e)}
					>
						{availableSettings.map(menu => (
							<Select.Option
								label={menu.menuLabel}
								value={menu.menuId}
								key={menu.menuId}>
								{menu.menuLabel}
							</Select.Option>
						))}
					</Select>
				</div>
				<SettingSelect
					onChange={handleChange}
					typeValue={setting.type}
					locationValue={setting.locationIds}
				/>
			</div>
		</Modal>
	);
};

BulkSettingModal.defaultProps = {
	visible: false,
	close: () => null,
	checkedMenuIndex: [],
	onOk: () => null,
	menus: [],
};

BulkSettingModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	checkedMenuIndex: PropTypes.array,
	onOk: PropTypes.func,
	menus: PropTypes.array,
};

export default BulkSettingModal;