import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import MetricFilter from './MetricFilter';
import Table from './Table';

const PromoCheck = ({setMetricValue, isActive, promoCheckTableRef}) => {
	const metricFormRef = useRef();
	const tableFormRef = useRef();

	useEffect(() => {
		if (isActive) {
			tableFormRef.current?.reload?.();
			promoCheckTableRef.current = tableFormRef.current;
		}
	}, [isActive]);

	return (
		<div className='px-6'>
			<MetricFilter
				ref={metricFormRef}
				tableFormRef={tableFormRef}
			/>
			<Table
				ref={tableFormRef}
				metricFormRef={metricFormRef}
				setMetricValue={setMetricValue}
			/>
		</div>
	);
};

PromoCheck.defaultProps = {
	setMetricValue: () => null,
	isActive: false,
	promoCheckTableRef: {},
};

PromoCheck.propTypes = {
	setMetricValue: PropTypes.func,
	isActive: PropTypes.func,
	promoCheckTableRef: PropTypes.object,
};

export default PromoCheck;