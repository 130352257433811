import React from 'react';
import PropTypes from 'prop-types';

import {Card, Typography} from 'antd';

const Metric = ({metricValue}) => {
	const metricCards = [
		{label: `Last Success Job ID · By ${metricValue.lastCheckBy || '-'}`, value: metricValue.lastCheckJobId || '-'},
		{label: 'Last Success Time Check', value: metricValue.lastCheckTime || '-'},
		{label: 'Last Total Promo Off', value: metricValue.lastTotalPromoOff || '-'},
	];

	return (
		<div className='flex gap-6 mb-4'>
			{metricCards.map(metricData => (
				<div
					className='flex-1'
					key={metricData.label}>
					<Card className="metricCard rounded-md">
						<Typography.Text
							className='text-sm'
							type="secondary">
							{metricData.label}
						</Typography.Text>
						<div className="h-2" />
						<Typography.Text className='text-2xl'>
							{metricData.value}
						</Typography.Text>
					</Card>
				</div>
			))}
		</div>
	);
};

Metric.defaultProps = {
	metricValue: {},
};

Metric.propTypes = {
	metricValue: PropTypes.object,
};

export default Metric;