import React from 'react';

import VersionTrackerHeader from './VersionTrackerHeader';
import VersionTrackerBody from './VersionTrackerBody';

const VersionTracker = () => {
	return (
		<div className='bg-white h-full overflow-auto'>
			<VersionTrackerHeader />
			<VersionTrackerBody />
		</div>
	);
};

export default VersionTracker;