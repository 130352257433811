import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

// Global Component
import ImportCSVOverlay from 'components/Overlay/ImportCSVOverlay';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';

// Antd
import {Button, Dropdown, Menu} from 'antd';
import {DownloadOutlined, EditOutlined, ExportOutlined, ImportOutlined} from '@ant-design/icons';

import {getLabel} from 'utils/utils';
import LocalPageHeader from 'components/LocalPageHeader';

import localization from 'localization';
import {MASTERLIST_TYPE, USER_ROLES} from 'utils/constants';
const locale = localization.MasterlistPage.Header;

const MasterlistHeader = ({handleExport, refetchTableRef, masterlistType, latestUpdateInfo}) => {
	const navigate = useNavigate();
	const auth = useSelector(state => state.user);

	const isMenuStructure = masterlistType === MASTERLIST_TYPE.MENU_STRUCTURE;

	const [uploadedFile, setUploadedFile] = useState(null);
	const [importCSV, setImportCSV] = useState({visible: false, type: 'add'});
	const [getCSV, setGetCSV] = useState({visible: false, type: 'export'});

	const handleFileUpload = async (e, type = 'add') => {
		if (!e.target.files.length) return;

		const uploadedFileTemp = e.target.files[0];
		e.target.value = null;

		setUploadedFile(uploadedFileTemp);
		openImportCSVOverlay(type);
	};

	const openGetCSVOverlay = type => {
		setGetCSV({visible: true, type});
	};

	const closeGetCSVOverlay = () => {
		setGetCSV({...getCSV, visible: false});
	};

	const openImportCSVOverlay = type => {
		setImportCSV({visible: true, type});
	};

	const closeImportCSVOverlay = () => {
		setUploadedFile(null);
		setImportCSV({...importCSV, visible: false});
	};

	const gotoCreatePage = () => {
		navigate(`/masterlist/${masterlistType}/new`);
	};

	const importMenu = (
		<Menu>
			<Menu.Item key='add'>
				<label className='cursor-pointer'>
					{locale.add}
					<input
						className="hidden"
						type="file"
						accept=".tsv"
						onChange={e => handleFileUpload(e, 'add')} />
				</label>
			</Menu.Item>
			{
				masterlistType === MASTERLIST_TYPE.MATERIAL
					? (
						<Menu.SubMenu
							key='update'
							title={locale.update}>
							<Menu.Item>
								<label className='cursor-pointer'>
									{locale.material}
									<input
										className="hidden"
										type="file"
										accept=".tsv"
										onChange={e => handleFileUpload(e, 'update')} />
								</label>
							</Menu.Item>
							<Menu.Item>
								<label className='cursor-pointer'>
									{locale.menu}
									<input
										className="hidden"
										type="file"
										accept=".tsv"
										onChange={e => handleFileUpload(e, 'update_menu')} />
								</label>
							</Menu.Item>
						</Menu.SubMenu>
					)
					: (
						<Menu.Item key='update'>
							<label className='cursor-pointer'>
								{locale.update}
								<input
									className="hidden"
									type="file"
									accept=".tsv"
									onChange={e => handleFileUpload(e, 'update')} />
							</label>
						</Menu.Item>
					)
			}
		</Menu>
	);

	const headerButtons = () => {
		const isEditor = auth?.roles?.some(role => [USER_ROLES.MASTERLIST.ADMIN, USER_ROLES.MASTERLIST.EDITOR].includes(role)) || false;
		const DownloadBtn = (
			<Button
				key='download-template'
				onClick={() => openGetCSVOverlay('download-template')}
				icon={<DownloadOutlined />}>
				{locale.downloadTemplate}
			</Button>
		);
		return isEditor ? [
			// Option Group dont use TSV
			...(masterlistType !== MASTERLIST_TYPE.OPTION_GROUP
				? [
					DownloadBtn,
					<Button
						key='export'
						onClick={() => openGetCSVOverlay('export')}
						icon={<ExportOutlined />}>
						{locale.export}
					</Button>,
					<Dropdown
						key='import'
						overlay={importMenu}
						placement="bottomLeft"
					>
						<Button
							type="primary"
							icon={<ImportOutlined />}>
							{locale.import}
						</Button>
					</Dropdown>,
				]
				: []),
			<Button
				key='create'
				type="primary"
				onClick={gotoCreatePage}
				icon={<EditOutlined />}>
				{locale.create}
			</Button>,
		] : DownloadBtn;
	};

	return (
		<div>
			<ImportCSVOverlay
				isChild={masterlistType === MASTERLIST_TYPE.CHILD_MENU ? true : false}
				masterlistType={masterlistType}
				refetchTable={() => refetchTableRef?.current?.({reset: true, highlightNewData: true})}
				handleFileUpload={handleFileUpload}
				uploadedFile={uploadedFile}
				visible={importCSV.visible}
				importType={importCSV.type}
				close={closeImportCSVOverlay}
			/>
			<ExportCSVOverlay
				masterlistType={masterlistType}
				handleExport={handleExport}
				visible={getCSV.visible}
				close={closeGetCSVOverlay}
				type={getCSV.type}
			/>
			<LocalPageHeader
				latestUpdateInfo={isMenuStructure ? null : latestUpdateInfo}
				headerTitle={getLabel(masterlistType)}
				extra={isMenuStructure ? [] : headerButtons()}
			/>
		</div>
	);
};

MasterlistHeader.defaultProps = {
	handleExport: () => null,
	refetchTableRef: {},
	masterlistType: 'menu',
	latestUpdateInfo: {},
};

MasterlistHeader.propTypes = {
	handleExport: PropTypes.func,
	refetchTableRef: PropTypes.object,
	masterlistType: PropTypes.string,
	latestUpdateInfo: PropTypes.object,
};

export default MasterlistHeader;
