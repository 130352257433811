import React from 'react';
import PropTypes from 'prop-types';
import './MenuDetail.css';

import {Descriptions, Tag, Image} from 'antd';

import {dateFormat, getStationLabel, moneyFormat} from 'utils/utils';

import HangryPlaceholderPimageSVG from 'assets/images/hangry-placeholder-image.svg';

const MenuDetail = ({content}) => {
	return (
		<Descriptions bordered>
			<Descriptions.Item label="Menu Name">{content.menuLabel}</Descriptions.Item>
			<Descriptions.Item label="Menu Code">{content.menuCode || ''}</Descriptions.Item>
			<Descriptions.Item label="Menu ID">{content.id}</Descriptions.Item>

			<Descriptions.Item label="KDS Name">{content.kdsName}</Descriptions.Item>
			<Descriptions.Item
				label="Station"
				span={2}>
				{getStationLabel(content.station)}
			</Descriptions.Item>

			<Descriptions.Item label="Price">{moneyFormat({value: content.price})}</Descriptions.Item>
			<Descriptions.Item
				label="Tags"
				span={2}>
				{content?.tags?.map((tag, index) => (
					<Tag
						color='blue'
						className='uppercase'
						key={index}>{tag}
					</Tag>
				))}
			</Descriptions.Item>

			<Descriptions.Item
				label="Active"
				span={3}>
				<div className={
        `font-semibold ${content.isActive ? 'text-green-400' : 'text-red-400'}`
				}>{content.isActive ? 'Yes' : 'No'}</div>
			</Descriptions.Item>

			<Descriptions.Item label="Created By">{content?.creator?.username}</Descriptions.Item>
			<Descriptions.Item
				label="Created At"
				span={2}>
				{dateFormat(content.createdAt)}
			</Descriptions.Item>

			<Descriptions.Item label="Updated By">{content?.updater?.username || '-'}</Descriptions.Item>
			<Descriptions.Item
				label="Updated At"
				span={2}>
				{dateFormat(content.updatedAt)}
			</Descriptions.Item>

			<Descriptions.Item
				label="Description"
				span={3}>
				{content.description}
			</Descriptions.Item>

			<Descriptions.Item
				label="Image"
				span={3}>
				<Image
					width={168}
					height={168}
					src={content.imageUrl || 'error'}
					fallback={HangryPlaceholderPimageSVG}
				/>
			</Descriptions.Item>

		</Descriptions>
	);
};

MenuDetail.defaultProps = {
	content: {},
};

MenuDetail.propTypes = {
	content: PropTypes.object,
};

export default MenuDetail;