import React from 'react';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';

import {Button, Typography} from 'antd';
import {EyeOutlined, ShopOutlined} from '@ant-design/icons';

import localization from 'localization';
const locale = localization.MenuTemplate.ReviewTemplate.Header;

const ReviewTemplateHeader = ({
	menuTemplateLabel, menuTemplateId, menuSyncDetail, viewOutletList, previewInGrab, loadingPreview,
}) => {
	return (
		<div>
			<LocalPageHeader
				headerTitle={(
					<div className='flex gap-1 items-center'>
						<Typography.Text>{menuTemplateLabel}</Typography.Text>
						{
							menuTemplateId && (<Typography.Text type='secondary'>{`[${menuTemplateId}]`}</Typography.Text>)
						}
					</div>
				)}
				capitalize={false}
				showBackButton
				extra={[
					...(menuSyncDetail?.Outlets?.length ? [
						<Button
							onClick={viewOutletList}
							icon={<ShopOutlined />}
							key="outlet">
							{locale.outletList.replace('{{count}}', menuSyncDetail?.Outlets?.length)}
						</Button>,
					] : []),
					menuSyncDetail?.Platform?.label === 'Grab' && (
						<Button
							loading={loadingPreview}
							onClick={previewInGrab}
							icon={<EyeOutlined />}
							key="outlet">{locale.grabPreview}</Button>
					),
				]}
			/>
		</div>
	);
};

ReviewTemplateHeader.defaultProps = {
	menuTemplateLabel: null,
	menuTemplateId: null,
	menuSyncDetail: {},
	viewOutletList: () => null,
	previewInGrab: () => null,
	loadingPreview: false,
};

ReviewTemplateHeader.propTypes = {
	menuTemplateLabel: PropTypes.string,
	menuTemplateId: PropTypes.string,
	menuSyncDetail: PropTypes.object,
	viewOutletList: PropTypes.func,
	previewInGrab: PropTypes.func,
	loadingPreview: PropTypes.bool,
};

export default ReviewTemplateHeader;