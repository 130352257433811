import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Divider, Radio, Typography} from 'antd';

import {PartitionOutlined} from '@ant-design/icons';

import localization from 'localization';
import {moneyFormat} from 'utils/utils';

const PairCard = ({checked, data, isExisting, onCheck}) => {
	const locale = localization.WatchTower.WatchTowerOrder.editPairDrawer;

	return (
		<div
			onClick={() => !isExisting && onCheck()}
			className={clsx('p-4 border border-antd-netural-5 rounded-md', !isExisting && 'cursor-pointer')}>
			{
				isExisting ? (
					<div className='flex flex-col gap-2'>
						<div className='flex gap-2 items-center'>
							<PartitionOutlined />
							<Typography.Text>{locale.existingPairing}</Typography.Text>
						</div>
						<Divider className='m-0 mb-2' />
					</div>
				) : null
			}
			<div className='flex justify-between items-center'>
				<div className='flex flex-col gap-1'>
					<Typography.Text strong>
						{
							locale.pairInfo
								.replace('{{orderId}}', data?.merchantOrderId)
								.replace('{{price}}', moneyFormat({value: data?.grandTotal}))
						}
					</Typography.Text>
					<div
						onClick={e => {
							e.stopPropagation();
							window.open(`${window.location.origin}/watchtower/order/pos/${data?.id}`);
						}}
						className='text-antd-blue-6 cursor-pointer'>
						{locale.detailOrder}
					</div>
				</div>
				{
					!isExisting && (
						<Radio
							checked={checked}
						/>
					)
				}
			</div>
		</div>
	);
};

PairCard.defaultProps = {
	checked: false,
	data: {},
	isExisting: false,
	onCheck: () => null,
};

PairCard.propTypes = {
	checked: PropTypes.bool,
	data: PropTypes.object,
	isExisting: PropTypes.bool,
	onCheck: PropTypes.func,
};

export default PairCard;