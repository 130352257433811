import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {rejectSyncRequest} from 'utils/request/salesChannel';
import {rejectPromoRequest} from 'utils/request/promo';
import {PROMO_CATEGORY} from 'utils/constants';

import {Button, Input, Modal, notification, Typography} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';

const {Title} = Typography;
const {TextArea} = Input;

import localization from 'localization';
const locale = localization.SyncRequest.RejectApprovalOverlay;

const RejectApprovalOverlay = ({visible, close, approvalId, refetchCards, requestType, promoCategory}) => {

	const [isConfirmed, setIsConfirmed] = useState(false);
	const [notes, setNotes] = useState('');

	const handleReject = async () => {
		try {
			const fetchAction = requestType === 'sync' ? rejectSyncRequest : rejectPromoRequest;
			const response = await fetchAction(approvalId, notes, promoCategory);
			if (response?.success) {
				notification.open({
					message: locale.Notification.Success.message,
					description: locale.Notification.Success.description,
					type: 'success',
				});
				refetchCards();
			}
		} catch (error) {
			notification.open({
				message: locale.Notification.Failed.message,
				description: locale.Notification.Failed.description,
				type: 'warning',
			});
		} finally {
			close();
		}
	};

	useEffect(() => {
		if (visible) {
			setIsConfirmed(false);
			setNotes('');
		}
	}, [visible]);

	const getContent = () => {
		return isConfirmed
			? (
				<div className='flex flex-col'>
					<TextArea
						showCount
						maxLength={100}
						value={notes}
						onChange={e => setNotes(e.target.value)}
						placeholder={locale.RejectedReasons.inputPlaceholder}
					/>
					<div className='pt-3 flex justify-end'>
						<Button
							disabled={!notes.trim().length}
							onClick={handleReject}
							type='primary'
						>{locale.RejectedReasons.submit}</Button>
					</div>
				</div>
			)
			: (
				<div>
					<div className='flex gap-4'>
						<ExclamationCircleOutlined className='text-antd-gold-6 text-2xl' />
						<div className='flex flex-col'>
							<Title
								className='font-bold'
								level={5}>{locale.Confirmation.title}</Title>
							<div>
								{locale.Confirmation.description}
							</div>
						</div>
					</div>
					<div className='flex justify-end gap-2 pt-6'>
						<Button
							onClick={close}
						>{locale.Confirmation.cancel}</Button>
						<Button
							type='primary'
							onClick={() => setIsConfirmed(true)}
						>{locale.Confirmation.confirm}</Button>
					</div>
				</div>
			);
	};

	return (
		<Modal
			visible={visible}
			title={isConfirmed ? locale.RejectedReasons.title : null}
			footer={null}
			closable={isConfirmed}
			centered
			onCancel={close}
		>
			{getContent()}
		</Modal>
	);
};

RejectApprovalOverlay.defaultProps = {
	visible: false,
	close: () => null,
	approvalId: null,
	refetchCards: () => null,
	requestType: null,
	promoCategory: null,
};

RejectApprovalOverlay.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	approvalId: PropTypes.number,
	refetchCards: PropTypes.func,
	requestType: PropTypes.oneOf(['sync', 'promo']),
	promoCategory: PropTypes.oneOf([PROMO_CATEGORY.INTERNAL, PROMO_CATEGORY.EXTERNAL]),
};

export default RejectApprovalOverlay;
