import {forwardRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Modal, Typography, Input, DatePicker} from 'antd';

// Global utils
import localization from 'localization';

import moment from 'moment';

// Local styles
import './modalDownloadReport.less';
import {exportToTsvFn, handleErrorFetch} from 'utils/utils';

const ModalDownloadReport = forwardRef((props, ref) => {
	const locale = localization.WatchTower.WatchTowerMenu.downloadReportModal;
	const [isModalVisible, setModalVisibility] = useState(false);
	const [fileName, setFileName] = useState('watchtower-data.tsv');
	const [loading, setLoading] = useState(false);
	const [selectedDate, setSelectedDate] = useState({
		date: [moment(), moment()],
		dateStrings: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
	});
	// Event handler methods
	const trimFileName = fileName => {
		const splittedFileName = fileName.split('.');
		const fileFormat = splittedFileName.pop();
		if (fileFormat === 'tsv') {
			return `${splittedFileName.join('.')}.tsv`;
		} else {
			return `${splittedFileName.join('.')}.${fileFormat}.tsv`;
		}
	};

	const onClickButtonDownload = async () => {
		try {
			setLoading(true);
			await exportToTsvFn({
				fileName: trimFileName(fileName),
				actionType: 'export',
				exportCall: () => props.downloadFn({
					createdAtFrom: selectedDate.dateStrings[0],
					createdAtTo: selectedDate.dateStrings[1],
				}),
			});
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};
	// Methods
	const setObjectRef = () => {
		const refObject = {
			open: () => setModalVisibility(true),
			close: () => setModalVisibility(false),
		};
		if (ref && ref.hasOwnProperty('current')) {
			ref.current = refObject;
		} else if (typeof ref === 'function') {
			ref(refObject);
		}
	};
	// Hooks
	useEffect(() => setObjectRef(), []);
	// Render
	return (
		<>
			<Modal
				title={locale.title}
				visible={isModalVisible}
				okText={locale.downloadNow}
				onOk={onClickButtonDownload}
				className="ModalDownloadReport"
				width={615}
				centered
				okButtonProps={{
					disabled: fileName === '' || !selectedDate,
					loading,
				}}
				onCancel={() => setModalVisibility(false)}>
				<Typography.Text className="subtitle">{locale.subtitle}</Typography.Text>
				<div className='flex flex-col gap-2'>
					{locale.reportDateLabel}
					<DatePicker.RangePicker
						disabledDate={current => current && current > moment().endOf('day')}
						ranges={{
							Today: [moment(), moment()],
							'This Month': [moment().startOf('month'), moment().endOf('day')],
						}}
						value={selectedDate?.date}
						onChange={(date, dateStrings) => setSelectedDate({date, dateStrings})}
					/>
				</div>
				<Input
					className='pt-4'
					value={fileName}
					addonBefore={locale.inputFileLabel}
					onChange={event => setFileName(event.target.value)} />
			</Modal>
		</>
	);
});


ModalDownloadReport.defaultProps = {
	downloadFn: () => null,
};

ModalDownloadReport.propTypes = {
	downloadFn: PropTypes.func,
};

export default ModalDownloadReport;
