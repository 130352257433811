import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import MenuForm from './MenuForm';
import CategoryForm from './CategoryForm';
import MaterialForm from './MaterialForm';
import OptionGroupForm from './OptionGroupForm';
import MenuReminderForm from './MenuReminderForm';
import MenuStructureForm from './MenuStructureForm';

import {MASTERLIST_TYPE} from 'utils/constants';

import {Card, Spin} from 'antd';

const MasterlistFormBody = ({masterlistType, isEdit, itemRecord, previewLabelRef, autoFillDummyRef}) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const goBack = () => navigate(`/masterlist/${masterlistType}`);

	useEffect(() => {
		if (isEdit && !itemRecord) goBack();
		setLoading(false);
	}, []);

	// eslint-disable-next-line react/prop-types
	const DefaultWrapper = ({children}) => (
		<Card
			title={<span className='capitalize'>{`New ${masterlistType}`}</span>}
		>
			{loading
				? (
					<div className='text-center'>
						<Spin size="large" />
					</div>
				)
				: children
			}
		</Card>
	);

	const renderForm = () => {
		switch (masterlistType) {
		case MASTERLIST_TYPE.CATEGORY:
			return (
				<DefaultWrapper>
					<CategoryForm
						isEdit={isEdit}
						itemRecord={itemRecord}
					/>
				</DefaultWrapper>
			);
		case MASTERLIST_TYPE.MENU:
		case MASTERLIST_TYPE.CHILD_MENU:
			return (
				<DefaultWrapper>
					<MenuForm
						isEdit={isEdit}
						itemRecord={itemRecord}
						isChildMenu={masterlistType === MASTERLIST_TYPE.CHILD_MENU}
					/>
				</DefaultWrapper>
			);
		case MASTERLIST_TYPE.OPTION_GROUP:
			return (
				<DefaultWrapper>
					<OptionGroupForm
						isEdit={isEdit}
						itemRecord={itemRecord}
					/>
				</DefaultWrapper>
			);
		case MASTERLIST_TYPE.REMINDER:
			return (
				<DefaultWrapper>
					<MenuReminderForm
						isEdit={isEdit}
						itemRecord={itemRecord}
					/>
				</DefaultWrapper>
			);
		case MASTERLIST_TYPE.MATERIAL:
			return (
				// Material Form no need wrapper as it handle differently
				<MaterialForm
					isEdit={isEdit}
					itemRecord={itemRecord}
				/>
			);
		case MASTERLIST_TYPE.MENU_STRUCTURE:
			return (
			// Material Form no need wrapper as it handle differently
				<MenuStructureForm
					isEdit={isEdit}
					itemRecord={itemRecord}
					goBack={goBack}
					previewLabelRef={previewLabelRef}
					autoFillDummyRef={autoFillDummyRef}
				/>
			);
		default:
			return (
				<CategoryForm
					isEdit={isEdit}
					itemRecord={itemRecord}
				/>
			);
		}
	};

	return (
		<div className='px-6'>
			{
				renderForm()
			}
		</div>
	);
};

MasterlistFormBody.defaultProps = {
	masterlistType: null,
	isEdit: false,
	itemRecord: {},
	previewLabelRef: {},
	autoFillDummyRef: {},
};

MasterlistFormBody.propTypes = {
	masterlistType: PropTypes.string,
	isEdit: PropTypes.bool,
	itemRecord: PropTypes.object,
	previewLabelRef: PropTypes.object,
	autoFillDummyRef: PropTypes.object,
};

export default MasterlistFormBody;