import React from 'react';
import PropTypes from 'prop-types';

import ReceiptDivider from '../ReceiptDivider';
import {moneyFormat} from 'utils/utils';

import localization from 'localization';
const locale = localization.Sales.TaxDashboard.ReceiptDrawer;

const OrderSummary = ({orderData}) => {

	const orderItems = orderData?.order_items;

	const getOrderQuantity = () => {
		let quantity = 0;

		orderItems?.items?.forEach?.(item => {
			quantity += item.menuItem.quantity;
		});
		return quantity;
	};

	return (
		<>
			<div className='p-6'>
				<div className='flex justify-between'>
					<div>{locale.itemQty.replace('{{qty}}', getOrderQuantity())}</div>
					<div className='flex flex-col gap-4'>
						<div className='flex gap-2'>
							<div className='ml-auto'>{locale.subtotal}</div>
							<div className='w-24 text-right'>{moneyFormat({value: orderData?.net_sales_before_order_fee, includeCurrency: false})}</div>
						</div>
						<div className='flex gap-2'>
							<div className='ml-auto'>{locale.voucherDiscount}</div>
							<div className='w-24 text-right'>{orderData?.promo_by_hangry ? `-${moneyFormat({value: orderData?.promo_by_hangry?.toFixed?.(), includeCurrency: false})}` : '0'}</div>
						</div>
					</div>
				</div>
			</div>
			<ReceiptDivider />
			<div className='p-6'>
				<div className='flex justify-between font-bold'>
					<div>{locale.total}</div>
					<div>{moneyFormat({value: orderData?.sales_after_tax, includeCurrency: false})}</div>
				</div>
			</div>
		</>
	);
};

OrderSummary.defaultProps = {
	orderData: {},
};

OrderSummary.propTypes = {
	orderData: PropTypes.object,
};

export default OrderSummary;