import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Form, Modal, notification} from 'antd';
import {upperCase, isEmpty} from 'lodash';

import {getPosUserPreset, handleErrorFetch} from 'utils/utils';

import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';
import {createPosUser, editPosUser} from 'utils/request/user';

// Local components
import DefaultForm from './DefaultForm';
import ResetPinForm from './ResetPinForm';

import localization from 'localization';
const locale = localization.Outlet.UserActivation.UserEditModal;

const UserEditModal = ({visible, data, close, refetchTableRef, isReset}) => {
	const [form] = Form.useForm();

	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [locationList, setLocationList] = useState([]);

	const isEdit = !isEmpty(data);

	const posRoles = getPosUserPreset();

	const handleSubmit = async e => {
		try {
			setLoadingSubmit(true);

			let payload = {};
			let fetchFn = () => null;

			if (isReset) {
				fetchFn = editPosUser;
				payload = {
					pinNumber: e.newPin,
				};
			} else {
				fetchFn = isEdit ? editPosUser : createPosUser;
				const foundRole = posRoles.find(role => role.roleName === e.rolesPreset);

				posRoles[upperCase(e.rolesPreset)];
				payload = {
					username: e.username,
					pinNumber: e.pin,
					roles: foundRole?.roles,
					locationId: e.locationId,
				};

				if (!isEdit) payload.pinNumber = e.pin;
			}

			if (isReset || isEdit) payload.id = data.id;

			const response = await fetchFn(payload);
			if (response.success) {
				refetchTableRef?.current?.();
				close();
				notification.success({
					message: isReset
						? ''
						: isEdit
							? locale.Notification.Success.messageEdit
							: locale.Notification.Success.message,
					description: isReset
						? locale.Notification.Success.descriptionReset
							.replace('{{username}}', data?.username)
							.replace('{{location}}', data?.locations?.[0]?.label)
						: isEdit
							? locale.Notification.Success.descriptionEdit
							: locale.Notification.Success.description,
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingSubmit(false);
		}
	};

	const searchOutletData = async ({value, type}) => {
		try {
			setIsLoading(true);
			const response = await getOutletData({limit: 5, page: 0, search: {label: value}}, type);
			if (response.success) {
				const setterFn = type === OUTLET_TYPE.LOCATION ? setLocationList : null;
				setterFn(response.data.rows);
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}
	};

	const renderForm = () => {
		return isReset
			? <ResetPinForm form={form} />
			: (
				<DefaultForm
					posRoles={posRoles}
					isEdit={isEdit}
					searchOutletData={searchOutletData}
					isLoading={isLoading}
					locationList={locationList}
				/>
			);
	};

	useEffect(() => {
		if (!visible) return;

		if (data?.locations?.length) {
			setLocationList(data?.locations);
		}
		const rolePreset = posRoles.find(role => data?.roles?.some(userRole => role.roles.includes(userRole)));
		form.setFieldsValue({
			username: data?.username || null,
			rolesPreset: rolePreset?.roleName || null,
			locationId: data?.locations?.[0]?.id || null,
			pin: null,
			newPin: null,
			confirmNewPin: null,
		});
	}, [visible]);

	return (
		<Modal
			title={
				isEdit
					? locale.titleEdit
						.replace('{{action}}', isReset ? locale.resetPin : locale.edit)
						.replace('{{username}}', data?.username)
						.replace('{{location}}', data?.locations?.[0]?.label)
					: locale.title
			}
			closable={false}
			visible={visible}
			width={572}
			centered
			destroyOnClose
			cancelText={locale.cancel}
			okText={isEdit
				? isReset
					? locale.reset
					: locale.save
				: locale.create}
			onCancel={close}
			onOk={() => 	form.submit()}
			okButtonProps={{loading: loadingSubmit}}
		>
			<Form
				loading={loadingSubmit}
				form={form}
				layout="vertical"
				name="editOutletUserForm"
				scrollToFirstError
				onFinish={e => handleSubmit(e)}
				onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
				requiredMark={false}
			>
				{renderForm()}
			</Form>
		</Modal>
	);
};

UserEditModal.defaultProps = {
	visible: false,
	data: {},
	close: () => null,
	refetchTableRef: {},
	isReset: false,
};

UserEditModal.propTypes = {
	visible: PropTypes.bool,
	data: PropTypes.object,
	close: PropTypes.func,
	refetchTableRef: PropTypes.object,
	isReset: PropTypes.bool,
};

export default UserEditModal;