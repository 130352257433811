import React, {useEffect, useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import RequestCard from 'components/RequestCard';

import {cancelPromoRequest, getPromoRequest} from 'utils/request/promo';
import {handleErrorFetch, requestCardPayloadConverter} from 'utils/utils';
import {PROMO_CATEGORY} from 'utils/constants';

import InfiniteScroll from 'react-infinite-scroller';
import {Empty, Skeleton, Tabs, Spin, Input, notification} from 'antd';
const {Search} = Input;
const {TabPane} = Tabs;

const TABS_CONFIG = [
	{id: 'pending', label: 'Waiting Approval'},
	{id: 'approved', label: 'Approved'},
	{id: 'rejected', label: 'Rejected'},
];

import localization from 'localization';
const locale = localization.Promo.Request;

const PromoRequestBody = ({containerRef, promoCategory}) => {
	const navigate = useNavigate();

	const [activeTabs, setActiveTabs] = useState('pending');
	const [loading, setLoading] = useState(false);

	const [pendingReq, setPendingReq] = useState([]);
	const [pendingReqCount, setPendingReqCount] = useState(0);
	const [pendingReqHasMore, setPendingReqHasMore] = useState(true);

	const [approvedReq, setApprovedReq] = useState([]);
	const [approvedReqCount, setApprovedReqCount] = useState(0);
	const [approvedReqHasMore, setApprovedReqHasMore] = useState(true);

	const [rejectedReq, setRejectedReq] = useState([]);
	const [rejectedReqCount, setRejectedReqCount] = useState(0);
	const [rejectedReqHasMore, setRejectedReqHasMore] = useState(true);

	const [promoLabel, setPromoLabel] = useState(null);

	const initialFetch = useRef(null);

	const sortOrder = activeTabs === 'pending' ? 'asc' : 'desc';

	const fetchCards = async () => {
		try {
			setLoading(true);
			const pendingItem = await getPromoRequest({
				label: initialFetch.current ? '' : promoLabel,
				status: 'pending',
				sortOrder,
			}, promoCategory);
			const approvedItem = await getPromoRequest({
				label: initialFetch.current ? '' : promoLabel,
				status: 'processed,approved',
			}, promoCategory);
			const rejectedItem = await getPromoRequest({
				label: initialFetch.current ? '' : promoLabel,
				status: 'rejected',
			}, promoCategory);

			setPendingReqCount(pendingItem?.data?.count);
			setApprovedReqCount(approvedItem?.data?.count);
			setRejectedReqCount(rejectedItem?.data?.count);

			setPendingReq(pendingItem?.data?.rows);
			setApprovedReq(approvedItem?.data?.rows);
			setRejectedReq(rejectedItem?.data?.rows);

			let pendingHasMore = true;
			let approvedHasMore = true;
			let rejectedHasMore = true;

			if (pendingItem?.data?.count === pendingItem?.data?.rows.length) {
				pendingHasMore = false;
			}

			if (approvedItem?.data?.count === approvedItem?.data?.rows.length) {
				approvedHasMore = false;
			}

			if (rejectedItem?.data?.count === rejectedItem?.data?.rows.length) {
				rejectedHasMore = false;
			}

			setPendingReqHasMore(pendingHasMore);
			setApprovedReqHasMore(approvedHasMore);
			setRejectedReqHasMore(rejectedHasMore);

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			initialFetch.current = false;
			setLoading(false);
		}
	};

	const getCardState = tabId => {
		switch (tabId) {
		case TABS_CONFIG[0].id:
			return pendingReq;
		case TABS_CONFIG[1].id:
			return approvedReq;
		case TABS_CONFIG[2].id:
			return rejectedReq;
		default:
			return pendingReq;
		}
	};

	const getCardCountFromConfig = index => {
		switch (index) {
		case 0:
			return pendingReqCount;
		case 1:
			return approvedReqCount;
		case 2:
			return rejectedReqCount;
		default:
			return pendingReqCount;
		}
	};

	const getStateFromTabId = tabId => {
		switch (tabId) {
		case 'pending':
			return {
				item: {
					state: pendingReq,
					setter: setPendingReq,
				},
				count: {
					state: pendingReqCount,
					setter: setPendingReqCount,
				},
				hasMore: {
					state: pendingReqHasMore,
					setter: setPendingReqHasMore,
				},
			};
		case 'approved':
			return {
				item: {
					state: approvedReq,
					setter: setApprovedReq,
				},
				count: {
					state: approvedReqCount,
					setter: setApprovedReqCount,
				},
				hasMore: {
					state: approvedReqHasMore,
					setter: setApprovedReqHasMore,
				},
			};
		case 'rejected':
			return {
				item: {
					state: rejectedReq,
					setter: setRejectedReq,
				},
				count: {
					state: rejectedReqCount,
					setter: setRejectedReqCount,
				},
				hasMore: {
					state: rejectedReqHasMore,
					setter: setRejectedReqHasMore,
				},
			};
		default:
			return {
				item: {
					state: pendingReq,
					setter: setPendingReq,
				},
				count: {
					state: pendingReqCount,
					setter: setPendingReqCount,
				},
				hasMore: {
					state: pendingReqHasMore,
					setter: setPendingReqHasMore,
				},
			};
		}
	};

	const handleLoadMore = async tabId => {
		try {
			if (tabId !== activeTabs) return;
			const {item, count, hasMore} = getStateFromTabId(tabId);
			if (['approved', 'processed'].some(item => item === tabId)) tabId = 'processed,approved';

			const payload = {label: promoLabel, status: tabId, offset: item.state.length, sortOrder};
			const response = await getPromoRequest(payload, promoCategory);

			if (response.success) {
				const tempReqValue = item.state.concat(response.data.rows);

				if (tempReqValue.length >= count.state) {
					hasMore.setter(false);
				}

				item.setter(tempReqValue);
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const getHasMoreInfo = tabId => {
		switch (tabId) {
		case 'pending':
			return pendingReqHasMore;
		case 'approved':
			return approvedReqHasMore;
		case 'rejected':
			return rejectedReqHasMore;
		default:
			return pendingReqHasMore;
		}
	};

	const handleCancel = async promoId => {
		try {
			const response = await cancelPromoRequest(promoId, promoCategory);
			if (response.success) {
				notification.open({
					message: locale.Notification.Cancel.Success.message,
					description: locale.Notification.Cancel.Success.description,
					type: 'success',
				});
			}
			fetchCards();
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const handleReview = cardData => {
		navigate('/promo/promo-detail', {state: {
			promoType: cardData.templateType, // single / multiple / flash
			action: 'detail',
			rawCardData: cardData,
			viewOnly: true,
			promoCategory,
		}});
		// Unused Edit Call
		// if (cardData?.status !== 'pending') {
		// 	history.push('/promo/promo-detail', {
		// 		promoType: cardData.templateType, // Single or Multiple
		// 		action: 'detail',
		// 		rawCardData: cardData,
		// 		viewOnly: true,
		// 	});
		// } else {
		// 	const isSingle = cardData.campaignDetails.campaigns.length > 1 ? false : true;

		// 	history.push('/promo/promo-request/new', {
		// 		promoType: isSingle ? 'single' : 'multiple', // Single or Multiple
		// 		action: 'detail',
		// 		rawCardData: cardData,
		// 	});
		// }
	};

	const setToInitialState = () => {
		setPendingReq([]);
		setPendingReqCount(0);
		setPendingReqHasMore(true);

		setApprovedReq([]);
		setApprovedReqCount(0);
		setApprovedReqHasMore(true);

		setRejectedReq([]);
		setRejectedReqCount(0);
		setRejectedReqHasMore(true);
	};

	const handleSearchByLabel = async () => {
		try {
			if (initialFetch.current) return;
			await fetchCards();
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useEffect(() => {
		(async () => {
			initialFetch.current = true;
			setPromoLabel('');
			setActiveTabs('pending');
			await fetchCards();
		})();
		return () => {
			// Clean up when promo category is changed
			setToInitialState();
		};
	}, [promoCategory]);

	return (
		<div className='px-6 pb-20 flex-1'>
			<Tabs
				tabBarExtraContent={(
					<Search
						placeholder="Search by Promo Label"
						allowClear
						value={promoLabel}
						onChange={e => setPromoLabel(e.target.value)}
						onSearch={() => handleSearchByLabel()}
					/>
				)}
				onTabClick={key => {
					switch (key) {
					case 'pending':
						setActiveTabs('pending');
						break;
					case 'approved':
						setActiveTabs('approved');
						break;
					case 'rejected':
						setActiveTabs('rejected');
						break;
					default:
						setActiveTabs('pending');
						break;
					}
				}}
				activeKey={activeTabs}
			>

				{TABS_CONFIG.map((tab, index) => (
					<TabPane
						tab={(
							<div className='flex gap-2'>
								<div>{tab.label}</div>
								<span className="inline-flex items-center justify-center px-2 py-1 text-xs font-medium leading-none bg-antd-blue-6 text-blue-50 rounded-full">{getCardCountFromConfig(index)}</span>
							</div>
						)}
						key={tab.id}
					>
						{
							loading
								? (
									<div className='grid grid-cols-2 gap-6 pb-4'>
										<div className='border pt-6 px-8 pb-8 rounded-md overflow-hidden shadow-md'>
											<Skeleton
												active
												paragraph={{rows: 5}} />
										</div>
									</div>
								)
								:
								getCardState(tab.id).length
									?
									(
										<InfiniteScroll
											pageStart={0}
											threshold={0}
											loadMore={() => handleLoadMore(tab.id)}
											hasMore={getHasMoreInfo(tab.id)}
											loader={(
												<div
													className="text-center mt-6"
													key={0}><Spin size='large' /></div>
											)}
											getScrollParent={() => containerRef.current}
											useWindow={false}
										>
											<div className='grid grid-cols-2 gap-6 pb-4'>
												{
													getCardState(tab.id).map((card,index) => (
														<RequestCard
															key={index}
															isInternal={promoCategory === PROMO_CATEGORY.INTERNAL}
															cancelAction={() => handleCancel(card.id)}
															reviewAction={() => handleReview(card)}
															cardData={requestCardPayloadConverter(card)}
															localeConfig={{
																idType: locale.idType,
																actionType: locale.actionType,
																itemType: locale.itemType,
															}}
														/>
													))
												}
											</div>
										</InfiniteScroll>
									)
									: <div className='pt-12'><Empty /></div>
						}
					</TabPane>
				))}

			</Tabs>
		</div>
	);
};

PromoRequestBody.defaultProps = {
	containerRef: {},
	promoCategory: null,
};

PromoRequestBody.propTypes = {
	containerRef: PropTypes.object,
	promoCategory: PropTypes.string,
};

export default PromoRequestBody;
