import React from 'react';
import PropTypes from 'prop-types';
import localization from 'localization';

import {Button, DatePicker, Select, Typography, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';

const ChooseFile = ({activeTab, uploadedFile, setUploadedFile, selectedPlatform, setSelectedPlatform, setSelectedDate}) => {
	const locale = localization.Sales.FileUpload.ImportModal;

	const isSettlement = activeTab === 'settlement';
	const actionLocale = isSettlement ? locale.Settlement : locale.Pairing;

	const handleFileUpload = async e => {
		if (!e.fileList.length) return;

		const uploadedFileTemp = e.fileList[0].originFileObj;

		setUploadedFile(uploadedFileTemp);
	};

	return (
		<div>
			<Typography.Text type='secondary'>{actionLocale.description}</Typography.Text>
			<div className='flex flex-col pt-3 gap-3'>
				{
					isSettlement
						? (
							<>
								<div>
									<div className='pb-2'>{locale.platformLabel}</div>
									<Select
										onChange={val => setSelectedPlatform(val)}
										value={selectedPlatform}
										options={[
											{
												value: 'gojek',
												label: 'Gojek',
											},
											{
												value: 'grab',
												label: 'Grab',
											},
											{
												value: 'shopee',
												label: 'Shopee',
											}]}
										placeholder={locale.platformPlaceholder}
										className='w-full' />
								</div>
								<div>
									<div className='pb-2'>{locale.reportDateLabel}</div>
									<DatePicker
										onChange={e => {
											if (e !== null) {
												setSelectedDate(e.format('YYYY-MM-DD'));
											} else {
												setSelectedDate(null);
											}
										}}
										className='w-full' />
								</div>
							</>
						)
						: null
				}
				<div>
					<div className='pb-2'>{locale.uploadFileLabel}</div>
					<Upload
						accept=".xls,.xlsx,.csv"
						beforeUpload={() => false}
						onRemove={() => setUploadedFile(null)}
						maxCount={1}
						onChange={e => handleFileUpload(e)}
						fileList={uploadedFile === null ? [] : [{
							uid: uploadedFile.uid,
							name: uploadedFile.name,
							status: 'done',
						}]}
					>
						<Button icon={<UploadOutlined />}>{locale.uploadFileAction}</Button>
					</Upload>
					<Typography.Text
						className='block'
						type='secondary'>{locale.uploadFileInfo}</Typography.Text>
				</div>
			</div>
		</div>
	);
};

ChooseFile.defaultProps = {
	activeTab: null,
	setUploadedFile: () => null,
	uploadedFile: null,
	selectedPlatform: null,
	setSelectedPlatform: () => null,
	setSelectedDate: () => null,
};

ChooseFile.propTypes = {
	activeTab: PropTypes.string,
	setUploadedFile: PropTypes.func,
	uploadedFile: PropTypes.object,
	selectedPlatform: PropTypes.string,
	setSelectedPlatform: PropTypes.func,
	setSelectedDate: PropTypes.func,
};

export default ChooseFile;