import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {Select, Typography} from 'antd';

import Context from '../../../Context';
import isEqual from 'lodash/isEqual';

import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm.ManageCategory.OutletSettingDrawer.SettingSelect;

const SettingSelect = ({typeValue, locationValue, onChange}) => {
	const {locationList, viewOnly} = useContext(Context);

	return (
		<div className='flex flex-col w-full gap-4'>
			<div>
				<div className='pb-2'>{locale.typeLabel}</div>
				<Select
					disabled={viewOnly}
					value={typeValue}
					onChange={e => onChange({value: e, field: 'type'})}
					className='w-full'>
					<Select.Option value='default'>{locale.Type.default}</Select.Option>
					<Select.Option value='include'>{locale.Type.include}</Select.Option>
					<Select.Option value='exclude'>{locale.Type.exclude}</Select.Option>
				</Select>
				<div><Typography.Text type='secondary'>
					{locale.TypeInfo[typeValue]}
				</Typography.Text></div>
			</div>
			{
				['exclude', 'include'].some(valueType => valueType === typeValue)
					? (
						<div>
							<div className='pb-2'>{locale.outletLabel}</div>
							<Select
								disabled={viewOnly}
								mode='multiple'
								placeholder={locale.outletPlaceholder}
								value={isEqual(locationValue, [-1]) ? [] : locationValue}
								onChange={e => onChange({value: e, field: 'locationIds'})}
								optionLabelProp="label"
								className='w-full'
								filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLocaleLowerCase())}
							>
								{locationList.map(location => (
									<Select.Option
										label={location.label}
										value={location.id}
										key={location.id}>{location.label}</Select.Option>
								))}
							</Select>
							<div><Typography.Text type='secondary'>{locale.outletInfo}</Typography.Text></div>
						</div>
					)
					: null
			}
		</div>
	);
};

SettingSelect.defaultProps = {
	typeValue: null,
	onChange: () => null,
	locationValue: [],
};

SettingSelect.propTypes = {
	typeValue: PropTypes.string,
	onChange: PropTypes.func,
	locationValue: PropTypes.array,
};

export default SettingSelect;