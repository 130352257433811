import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import LocalPageHeader from 'components/LocalPageHeader';

import {Button, Card, Input, notification} from 'antd';

import {handleErrorFetch} from 'utils/utils';
import {getPlatformList} from 'utils/request/global';
import {createInternalPlatform, editInternalPlatform} from 'utils/request/internalPlatform';

import localization from 'localization';
const locale = localization.POSSetting.InternalPlatform.Form;

const InternalPlatformForm = () => {
	const navigate = useNavigate();

	const {id} = useParams();

	const isEdit = id;

	const [loading, setLoading] = useState(false);
	const [platformLabel, setPlatformLabel] = useState(null);

	const goBack = () => navigate('/pos-settings/platform-internal');

	const handleSubmit = async () => {
		try {
			setLoading(true);

			const payload = {
				label: platformLabel,
			};

			if (isEdit) payload.id = id;

			const fetchFn = isEdit ? editInternalPlatform : createInternalPlatform;
			const response = await fetchFn(payload);

			if (response.success) {
				const notificationLocale = isEdit ? locale.SuccessEdit : locale.SuccessCreate;
				notification.success({
					message: notificationLocale.message,
					description: notificationLocale.description,
				});
				goBack();
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchPlatformData = async () => {
		try {
			setLoading(true);
			const response = await getPlatformList({id});

			if (response.success && response.data.rows.length) {
				const platformData = response.data.rows[0];

				if (platformData.type !== 'internal') goBack();
				else setPlatformLabel(platformData.label);
			} else {
				goBack();
			}

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (isEdit) {
			fetchPlatformData();
		}
	}, [isEdit]);

	return (
		<div className='bg-white h-full overflow-auto'>
			<LocalPageHeader
				headerTitle={locale.title}
				showBackButton
				backButtonAction={goBack}
			/>
			<Card
				title={locale.cardTitle}
				className='mx-6 mb-12'
			>
				<div className='flex flex-col gap-2'>
					<span>{locale.platformLabel}</span>
					<Input
						value={platformLabel}
						onChange={e => setPlatformLabel(e.target.value)}
						className='max-w-sm'
						placeholder={locale.platformLabelPlaceholder}
					/>
				</div>
				<div className='pt-6 flex justify-end gap-2'>
					<Button
						loading={loading}
						onClick={goBack}>
						{locale.cancel}
					</Button>
					<Button
						loading={loading}
						type='primary'
						disabled={!platformLabel}
						onClick={() => handleSubmit()}
					>
						{locale.submit}
					</Button>
				</div>
			</Card>
		</div>
	);
};

export default InternalPlatformForm;