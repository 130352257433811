import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import SettlementTable from '../SettlementTable';
import Metric from './Metric';

import {ellipsisItem, momentDateFormat, localeCurrencyFormat} from 'utils/utils';

const GrabSettlement = ({inView, filterRef}) => {
	const metricFormRef = useRef();
	const tableFormRef = useRef();

	const columns = [
		{
			width: 111,
			key: 'sales_date',
			dataIndex: 'sales_date',
			title: ellipsisItem('Sales Date'),
			render: sales_date => ellipsisItem(momentDateFormat({dateString: sales_date})),
		},
		{
			width: 111,
			key: 'updated_on',
			dataIndex: 'updated_on',
			title: ellipsisItem('Update On'),
			render: updated_on => ellipsisItem(momentDateFormat({dateString: updated_on})),
		},
		{
			width: 126,
			key: 'sales_number',
			dataIndex: 'sales_number',
			title: ellipsisItem('Sales Number'),
			render: sales_number => ellipsisItem(sales_number),
		},
		{
			width: 124,
			key: 'bill_number',
			dataIndex: 'bill_number',
			title: ellipsisItem('Bill Number'),
			render: bill_number => ellipsisItem(bill_number),
		},
		{
			width: 136,
			key: 'external_long_id',
			dataIndex: 'external_long_id',
			title: ellipsisItem('Long ID'),
			render: external_long_id => ellipsisItem(external_long_id),
		},
		{
			width: 103,
			key: 'external_short_id',
			dataIndex: 'external_short_id',
			title: ellipsisItem('Short ID'),
			render: external_short_id => ellipsisItem(external_short_id),
		},
		{
			width: 129,
			key: 'settlement_id',
			dataIndex: 'settlement_id',
			title: 'Settlement ID',
			render: settlement_id => ellipsisItem(settlement_id),
		},
		{
			width: 101,
			key: 'brand_label',
			dataIndex: 'brand_label',
			title: ellipsisItem('Brand'),
			render: brand_label => ellipsisItem(brand_label),
		},
		{
			width: 127,
			key: 'branch',
			dataIndex: 'branch',
			title: ellipsisItem('Branch'),
			render: branch => ellipsisItem(branch),
		},
		{
			width: 119,
			key: 'total_normal_price',
			dataIndex: 'total_normal_price',
			title: ellipsisItem('Total Normal Price'),
			render: total_normal_price => ellipsisItem(localeCurrencyFormat({value: total_normal_price})),
		},
		{
			width: 100,
			key: 'price_cut',
			dataIndex: 'price_cut',
			title: ellipsisItem('Price Cut'),
			render: price_cut => ellipsisItem(localeCurrencyFormat({value: price_cut})),
		},
		{
			width: 141,
			key: 'esb_grand_total',
			dataIndex: 'esb_grand_total',
			title: ellipsisItem('ESB Grand Total'),
			render: esb_grand_total => ellipsisItem(localeCurrencyFormat({value: esb_grand_total})),
		},
		{
			key: 'platform_grand_total',
			dataIndex: 'platform_grand_total',
			title: ellipsisItem('Platform Grand Total'),
			render: platform_grand_total => ellipsisItem(localeCurrencyFormat({value: platform_grand_total})),
		},
		{
			width: 119,
			key: 'net_sales',
			dataIndex: 'net_sales',
			title: ellipsisItem('Net Sales'),
			render: net_sales => ellipsisItem(localeCurrencyFormat({value: net_sales})),
		},
		{
			width: 129,
			key: 'order_fee',
			dataIndex: 'order_fee',
			title: ellipsisItem('Order Fee'),
			render: order_fee => ellipsisItem(localeCurrencyFormat({value: order_fee})),
		},
		{
			width: 152,
			key: 'platform_subtotal_with_pb1',
			dataIndex: 'platform_subtotal_with_pb1',
			title: ellipsisItem('Platform Subtotal With PB1'),
			render: platform_subtotal_with_pb1 => ellipsisItem(localeCurrencyFormat({value: platform_subtotal_with_pb1})),
		},
		{
			width: 132,
			key: 'net_sales_before_order_fee',
			dataIndex: 'net_sales_before_order_fee',
			title: ellipsisItem('Net Sales Before Order Fee'),
			render: net_sales_before_order_fee => ellipsisItem(localeCurrencyFormat({value: net_sales_before_order_fee})),
		},
		{
			width: 129,
			key: 'cart_discount',
			dataIndex: 'cart_discount',
			title: ellipsisItem('Cart Discount'),
			render: cart_discount => ellipsisItem(localeCurrencyFormat({value: cart_discount})),
		},
		{
			width: 129,
			key: 'mdr_fees',
			dataIndex: 'mdr_fees',
			title: ellipsisItem('Mdr Fees'),
			render: mdr_fees => ellipsisItem(localeCurrencyFormat({value: mdr_fees})),
		},
		{
			width: 133,
			key: 'marketing_success_fee',
			dataIndex: 'marketing_success_fee',
			title: ellipsisItem('Marketing Success Fee'),
			render: marketing_success_fee => ellipsisItem(localeCurrencyFormat({value: marketing_success_fee})),
		},
		{
			width: 133,
			key: 'cofounding',
			dataIndex: 'cofounding',
			title: ellipsisItem('Cofunding'),
			render: cofounding => ellipsisItem(localeCurrencyFormat({value: cofounding})),
		},
		{
			width: 132,
			key: 'promo_by_hangry',
			dataIndex: 'promo_by_hangry',
			title: ellipsisItem('Promo By Hangry'),
			render: promo_by_hangry => ellipsisItem(localeCurrencyFormat({value: promo_by_hangry})),
		},
		{
			width: 133,
			key: 'promo_by_platform',
			dataIndex: 'promo_by_platform',
			title: ellipsisItem('Promo By Grab'),
			render: promo_by_platform => ellipsisItem(localeCurrencyFormat({value: promo_by_platform})),
		},
		{
			width: 137,
			key: 'sales_after_tax',
			dataIndex: 'sales_after_tax',
			title: ellipsisItem('Sales After Tax'),
			render: sales_after_tax => ellipsisItem(localeCurrencyFormat({value: sales_after_tax})),
		},
		{
			width: 144,
			key: 'sales_before_tax',
			dataIndex: 'sales_before_tax',
			title: ellipsisItem('Sales Before Tax'),
			render: sales_before_tax => ellipsisItem(localeCurrencyFormat({value: sales_before_tax})),
		},
		{
			width: 122,
			key: 'pb1',
			dataIndex: 'pb1',
			title: ellipsisItem('PB1'),
			render: pb1 => ellipsisItem(localeCurrencyFormat({value: pb1})),
		},
		{
			width: 100,
			key: 'linking_method',
			dataIndex: 'linking_method',
			title: ellipsisItem('Linking Method'),
			render: linking_method => ellipsisItem(linking_method),
		},
		{
			width: 91,
			key: 'is_recon',
			dataIndex: 'is_recon',
			title: ellipsisItem('Is Recon'),
			render: is_recon => ellipsisItem(is_recon ? 'True' : 'False'),
		},
	];

	useEffect(() => {
		if (inView) {
			filterRef.current = metricFormRef;
		}
	}, [inView]);

	return (
		<div className='px-6'>
			<Metric
				tableFormRef={tableFormRef}
				ref={metricFormRef} />
			<SettlementTable
				ref={tableFormRef}
				platform='grab'
				metricFormRef={metricFormRef}
				columns={columns}
			/>
		</div>
	);
};

GrabSettlement.defaultProps = {
	inView: false,
	filterRef: {},
};

GrabSettlement.propTypes = {
	inView: PropTypes.bool,
	filterRef: PropTypes.object,
};

export default GrabSettlement;