import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {useNavigate} from 'react-router-dom';

import MappingModal from './MappingModal';

import {Button, Card, Form, Input, Modal, notification, Popconfirm, Select, Table, Tag} from 'antd';

import {createMasterlistItem, editMasterlistItem} from 'utils/request/masterlist';
import {handleErrorFetch, moneyFormat} from 'utils/utils';
import {MASTERLIST_TYPE} from 'utils/constants';

import localization from 'localization';
const locale = localization.MasterlistPage.MasterlistForm.MaterialForm;
const actionLocale = localization.MasterlistPage.MasterlistForm.action;

const MaterialForm = ({itemRecord, viewOnly, isEdit}) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState(false);

	const [selectedParents, setSelectedParents] = useState([]);
	const [selectedChilds, setSelectedChilds] = useState([]);

	// Mapping modal visible and type
	const [mappingModalConfig, setMappingModalConfig] = useState({
		visible: false,
		type: MASTERLIST_TYPE.MENU,
		formSettedMenu: [],
	});

	const openMappingModal = type => {
		setMappingModalConfig({
			visible: true,
			formSettedMenu: type === MASTERLIST_TYPE.MENU ? selectedParents : selectedChilds,
			type,
		});
	};

	const closeMappingModal = () => {
		setMappingModalConfig({...mappingModalConfig, visible: false});
	};

	const saveMapping = mapping => {
		const setterFn = mappingModalConfig.type === MASTERLIST_TYPE.MENU ? setSelectedParents : setSelectedChilds;
		setterFn(mapping);
		closeMappingModal();
	};

	const handleDeleteMapping = (itemId, type) => {
		const setterFn = type === MASTERLIST_TYPE.MENU ? setSelectedParents : setSelectedChilds;
		setterFn(state => state.filter(mapping => mapping.id !== itemId));
	};

	const handleSubmit = async e => {
		try {
			const payload = {
				...e,
				parentMasterMenuIds: selectedParents.map(parent => parent.id),
				childMasterMenuIds: selectedChilds.map(child => child.id),
			};

			const fetchFn = isEdit ? editMasterlistItem : createMasterlistItem;
			if (isEdit) payload.id = itemRecord.id;

			const response = await fetchFn(payload, 'material');
			if (response.success) {
				const notificationLocale = isEdit ? actionLocale.EditSuccess : actionLocale.CreateSuccess;
				navigate('/masterlist/material');
				notification.open({
					message: notificationLocale.title,
					description: notificationLocale.description.replace('{{type}}', 'menu'),
					type: 'success',
				});
			}

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}
	};

	const getColumns = type => {
		const output = [
			{
				title: type === MASTERLIST_TYPE.CHILD_MENU ? 'Child Menu Name' : 'Menu Name',
				dataIndex: 'menuLabel',
				key: 'menuLabel',
			},
			{
				title: type === MASTERLIST_TYPE.CHILD_MENU ? 'Child ID' : 'Menu ID',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'Tags',
				dataIndex: 'tags',
				key: 'tags',
				renderFormItem: () => (
					<Select
						open={false}
						mode='tags'
						tokenSeparators={[',']}
					/>
				),
				render: tags => (Array.isArray(tags) && tags.length) ? (
					<div className='flex flex-wrap gap-y-2'>
						{tags.map((tag, index) => (
							<Tag
								color='blue'
								className='uppercase'
								key={index}>{tag}
							</Tag>
						))}
					</div>
				) : '-',
			},
			{
				title: 'Price',
				dataIndex: 'price',
				key: 'price',
				render: price => (
					<div>{moneyFormat({value: price})}</div>
				),
			},
			...(
				viewOnly
					? []
					: [{
						title: 'Action',
						key: 'Action',
						width: 120,
						render: (_, record) => (
							<div className='flex'>
								<Popconfirm
									placement="topRight"
									title={locale.deleteMappingConfirmation}
									onConfirm={() => handleDeleteMapping(record.id, type)}
									okText={locale.delete}
								>
									<div
										className='text-antd-blue-6 cursor-pointer'>Delete</div>
								</Popconfirm>
							</div>
						),
					}]),
		];

		return output;
	};

	useEffect(() => {
		if (isEdit || viewOnly) {
			form.setFieldsValue({
				materialLabel: itemRecord?.materialLabel,
				materialCode: itemRecord?.materialCode,
			});
			const tempSelectedParents = [];
			const tempSelectedChilds = [];

			itemRecord?.MasterMenus?.forEach?.(menu => {
				if (menu.isChildMenu) tempSelectedChilds.push(menu);
				else tempSelectedParents.push(menu);
			});

			setSelectedParents(tempSelectedParents);
			setSelectedChilds(tempSelectedChilds);
		}
	}, [itemRecord]);

	return (
		<>
			<MappingModal
				visible={mappingModalConfig.visible}
				type={mappingModalConfig.type}
				formSettedMenu={mappingModalConfig.formSettedMenu}
				close={closeMappingModal}
				saveMapping={saveMapping}
			/>
			<Form
				form={form}
				layout="vertical"
				name="materialForm"
				scrollToFirstError
				onFinish={e => !isLoading && handleSubmit(e)}
				onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
				requiredMark={false}
			>
				<div className='flex flex-col gap-6 pb-12'>

					{/* Material Card */}
					<Card
						title={locale.materialTitle}
					>
						<Form.Item
							label={locale.materialLabel}
							name="materialLabel"
							rules={[{required: true, message: locale.materialLabelRequired}]}
						>
							<Input
								disabled={viewOnly}
								className='w-96'
								placeholder={locale.materialLabelPlaceholder}
							/>
						</Form.Item>
						<Form.Item
							label={locale.materialCodeLabel}
							name="materialCode"
							rules={[{required: true, message: locale.materialCodeRequired}]}
						>
							<Input
								disabled={viewOnly}
								className='w-96'
								placeholder={locale.materialCodePlaceholder}
							/>
						</Form.Item>
					</Card>
					{/* End of Material Card */}

					{/* Parent Card */}
					<Card
						title={locale.parentTitle}
					>
						{
							selectedParents.length
								? (
									<Table
										className='pb-6'
										rowKey='id'
										id='material-parent-mapping-table'
										dataSource={selectedParents}
										pagination={false}
										columns={getColumns(MASTERLIST_TYPE.MENU)}
									/>
								)
								: null
						}
						{
							!viewOnly && (
								<Button
									ghost
									type='primary'
									onClick={() => openMappingModal(MASTERLIST_TYPE.MENU)} >{locale.addParent}</Button>
							)
						}
					</Card>
					{/* End of Parent Card */}

					{/* Child Card */}
					<Card
						title={locale.childTitle}
					>
						{
							selectedChilds.length
								? (
									<Table
										className='pb-6'
										rowKey='id'
										id='material-child-mapping-table'
										dataSource={selectedChilds}
										pagination={false}
										columns={getColumns(MASTERLIST_TYPE.CHILD_MENU)}
									/>
								)
								: null
						}
						{
							!viewOnly && (
								<Button
									ghost
									type='primary'
									onClick={() => openMappingModal(MASTERLIST_TYPE.CHILD_MENU)}>{locale.addChild}</Button>
							)
						}
					</Card>
					{/* End of Child Card */}

					{
						!viewOnly && (
							<div className='flex justify-end gap-2'>
								<Button onClick={() => {
									const {materialLabel, materialCode} = form.getFieldsValue();
									if (materialLabel?.length || materialCode?.length || selectedParents.length || selectedChilds.length) {
										Modal.confirm({
											title: locale.CancelPrompt.title,
											centered: true,
											content: locale.CancelPrompt.content,
											okText: locale.CancelPrompt.okText,
											okButtonProps: {danger: true},
											onOk() {
												navigate('/masterlist/material');
											},
										});
									} else {
										navigate('/masterlist/material');
									}
								}} >{locale.cancel}</Button>
								<Button
									disabled={!(selectedParents.length || selectedChilds.length)}
									htmlType="submit"
									type='primary'
									loading={isLoading}
								>{locale.save}</Button>
							</div>
						)
					}

				</div>
			</Form>
		</>
	);
};

MaterialForm.defaultProps = {
	itemRecord: {},
	viewOnly: false,
	isEdit: false,
};

MaterialForm.propTypes = {
	itemRecord: PropTypes.object,
	viewOnly: PropTypes.bool,
	isEdit: PropTypes.bool,
};


export default MaterialForm;