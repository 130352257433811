import React from 'react';
import localization from 'localization';
import PropTypes from 'prop-types';

// Global components
import LocalPageHeader from 'components/LocalPageHeader';

const VersionUploaderFormHeader = ({isEdit, isDetail, versionData}) => {
	const locale = localization.Admin.VersionUploader.Form;

	return (
		<div>
			<LocalPageHeader
				headerTitle={(isEdit || isDetail) ? versionData.id : locale.header}
				showBackButton
			/>
		</div>
	);
};

VersionUploaderFormHeader.defaultProps = {
	isEdit: false,
	isDetail: false,
	versionData: {},
};

VersionUploaderFormHeader.propTypes = {
	isEdit: PropTypes.bool,
	isDetail: PropTypes.bool,
	versionData: PropTypes.object,
};

export default VersionUploaderFormHeader;