import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ReviewTemplateHeader from './ReviewTemplateHeader';
import MenuTemplateFormBody from 'pages/SalesChannel/MenuTemplateForm/MenuTemplateFormBody';

import RejectApprovalOverlay from 'components/RejectApprovalOverlay';
import OutletListDrawer from 'components/OutletListDrawer';
import GrabMockupPreview from 'components/Overlay/GrabMockupPreview';
import ProtectedComponent from 'components/ProtectedComponent';

import {Button, notification, Spin, Modal} from 'antd';
const {confirm} = Modal;

import {cartCompressHandler, handleErrorFetch, menuTemplateBodyFormatter} from 'utils/utils';
import {STORAGE_KEY, USER_ROLES} from 'utils/constants';
import {approveSyncRequest, getMenuTemplateDetail, getMenuTemplateJSON} from 'utils/request/salesChannel';

import useUnload from 'hooks/useUnload';

import localization from 'localization';
import MenuTemplateCart from 'components/MenuTemplateCart';
const locale = localization.MenuTemplate.ReviewTemplate;

const ReviewTemplate = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [menuSyncDetail, setMenuSyncDetail] = useState({});
	const [menuTemplateLabel, setMenuTemplateLabel] = useState('');
	const [menuTemplateObj, setMenuTemplateObj] = useState({});
	const [providedLocation, setProvidedLocation] = useState([]);

	const [approvalIdToReject, setApprovalIdToReject] = useState(null);
	const [showRejectModal, setShowRejectModal] = useState(false);

	const [outletDrawerActive, setOutletDrawerActive] = useState(false);

	const [mockUpJSON, setMockUpJSON] = useState({});
	const [loadingPreview, setLoadingPreview] = useState(false);
	const [showMockUpPreviewGuide, setShowMockUpPreviewGuide] = useState(false);

	const [previewAsWatcher, setPreviewAsWatcher] = useState(false);

	const [cart, setCart] = useState([]);

	const cartSetter = item => {
		const cartTemp = JSON.parse(JSON.stringify(cart));
		const compressedCart = cartCompressHandler(cartTemp.concat(item));
		setCart(compressedCart);
	};

	const groupLocation = merchants => {
		if (merchants?.length) {
			const uniqueLocations = Array.from(new Set(merchants.map(item => item.Location.id)))
				.map(locationId => merchants.find(item => item.Location.id === locationId).Location);

			setProvidedLocation(uniqueLocations);
		}
	};

	const initialFetch = async () => {
		try {
			setLoading(true);
			const requestDetail = JSON.parse(sessionStorage.getItem(STORAGE_KEY.MASTERLIST_MENU_REQUEST_DETAIL));
			setMenuSyncDetail(requestDetail);
			if (!requestDetail.menuTemplateId) throw {};
			const response = await getMenuTemplateDetail({id: requestDetail.menuTemplateId});
			if (response?.success) {
				setMenuTemplateLabel(response.data.details.label);

				let tempMenuTemplateObj = response.data.details.body;
				tempMenuTemplateObj = menuTemplateBodyFormatter(tempMenuTemplateObj);
				setMenuTemplateObj(tempMenuTemplateObj);
				groupLocation(requestDetail?.Outlets);
			} else {
				throw {};
			}
		} catch (error) {
			notification.open({
				message: 'Error',
				description: 'Failed to load menu template data',
				type: 'warning',
			});
			navigate('/sales-channel/sync-request');
		} finally {
			setLoading(false);
		}
	};

	const goBack = () => {
		navigate('/sales-channel/sync-request');
	};

	const handleApprove = async () => {
		try {
			setLoading(true);
			const response = await approveSyncRequest(menuSyncDetail.id);
			if (response.success) {
				notification.open({
					message: locale.Approve.Success.message,
					description: locale.Approve.Success.description.replace('{{id}}', menuSyncDetail.id),
					type: 'success',
				});
				goBack();
			} else {
				notification.open({
					message: locale.Approve.Failed.message,
					description: response.error || locale.Approve.Failed.description,
					type: 'warning',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const openRejectOverlay = () => {
		setApprovalIdToReject(menuSyncDetail.id);
		setShowRejectModal(true);
	};

	const viewOutletList = () => {
		setOutletDrawerActive(true);
	};

	const previewInGrab = async () => {
		try {
			setLoadingPreview(true);
			const response = await getMenuTemplateJSON(menuSyncDetail?.MenuTemplate?.id);
			if (response.success) {
				setMockUpJSON(response.data);
				setShowMockUpPreviewGuide(true);
			}
		} catch (error) {
			notification.open({
				message: locale?.Preview.Failed.message,
				description: locale?.Preview.Failed.description,
				type: 'warning',
			});
		} finally {
			setLoadingPreview(false);
		}
	};

	useUnload(() => {
		// sessionStorage.removeItem(STORAGE_KEY.MASTERLIST_MENU_REQUEST_DETAIL);
	});

	useEffect(() => {
		initialFetch();
		// return () => sessionStorage.removeItem(STORAGE_KEY.MASTERLIST_MENU_REQUEST_DETAIL);
	}, []);

	return (
		<div className="bg-white flex-1 overflow-auto flex flex-col">
			<OutletListDrawer
				visible={outletDrawerActive}
				outletList={menuSyncDetail?.Outlets}
				onClose={() =>setOutletDrawerActive(false)}
			/>
			<GrabMockupPreview
				visible={showMockUpPreviewGuide}
				close={() => setShowMockUpPreviewGuide(false)}
				jsonCode={mockUpJSON}
			/>
			<RejectApprovalOverlay
				visible={showRejectModal}
				approvalId={approvalIdToReject}
				refetchCards={goBack}
				requestType='sync'
				close={() => {
					setShowRejectModal(false);
					setApprovalIdToReject(null);
				}}
			/>
			{
				loading
					? (
						<div className='h-full flex items-center justify-center'>
							<Spin size="large" />
						</div>
					)
					: (
						<div className='flex flex-col flex-1'>
							<ReviewTemplateHeader
								viewOutletList={viewOutletList}
								previewInGrab={previewInGrab}
								loadingPreview={loadingPreview}
								menuSyncDetail={menuSyncDetail}
								menuTemplateLabel={menuTemplateLabel}
								menuTemplateId={menuSyncDetail?.MenuTemplate?.id}
							/>
							<MenuTemplateFormBody
								menuTemplateLabel={menuTemplateLabel}
								menuTemplateObj={menuTemplateObj}
								providedLocation={providedLocation}
								previewAsDep={setPreviewAsWatcher}
								viewOnly
								cart={cart}
								setCart={cartSetter}
							/>
							<div className='flex justify-end px-6 pb-6 gap-2'>
								<Button onClick={goBack}>{locale.Actions.close}</Button>
								<MenuTemplateCart
									cart={cart}
									setCart={setCart}
								/>
								{
									menuSyncDetail.status !== 'pending'
										? null
										: (
											<>
												<ProtectedComponent
													when={u => u.roles.some(
														role => [USER_ROLES.SYNC_MENU.ADMIN, USER_ROLES.SYNC_MENU.APPROVER].includes(role),
													)}
												>
													<Button
														disabled={previewAsWatcher}
														onClick={openRejectOverlay}
														danger>{locale.Actions.reject}</Button>
													<Button
														disabled={previewAsWatcher}
														onClick={() =>
															confirm({
																title: locale.ApproveConfirmation.title,
																content: locale.ApproveConfirmation.content,
																okText: locale.ApproveConfirmation.ok,
																onOk: () => handleApprove(),
																centered: true,
																maskClosable: true,
															})
														}
														type='primary'>{locale.Actions.approve}</Button>
												</ProtectedComponent>
											</>
										)
								}
							</div>
						</div>
					)
			}
		</div>
	);
};

export default ReviewTemplate;