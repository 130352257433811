import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import {PageHeader, Typography} from 'antd';

import Breadcrumb from 'components/Breadcrumb';
import {dateFormat} from 'utils/utils';
const {Title} = Typography;

import localization from 'localization';
import clsx from 'clsx';
const locale = localization.Global.LocalPageHeader;

const LocalPageHeader = ({
	latestUpdateInfo, subtitle, headerTitle,
	extra, showBackButton, backButtonAction,
	capitalize, borderBottom, customBreadcrumbLabel,
	newType,
}) => {
	const navigate = useNavigate();
	return (
		<div>
			<div className='px-6 pt-6'>
				<Breadcrumb
					newType={newType}
					customLabel={customBreadcrumbLabel} />
			</div>
			<PageHeader
				onBack={
					showBackButton
						? backButtonAction
							? () => backButtonAction?.()
							: () => navigate(-1)
						: null}
				title={(
					<Title
						className={clsx(
							'm-auto flex font-medium',
							capitalize && 'capitalize',
						)}
						level={4}>{headerTitle}</Title>
				)}
				subTitle={subtitle ? subtitle : isEmpty(latestUpdateInfo)
					? null
					: `${locale.lastUpdate} ${dateFormat(latestUpdateInfo.updatedAt)} ${locale.by} ${latestUpdateInfo.updater || ''}`
				}
				extra={extra}
				style={{
					borderBottom: borderBottom ? '1px solid' : 'none',
					marginBottom: borderBottom ? 16 : '0',
				}}
				className="border-gray-100"
			/>
		</div>
	);
};

LocalPageHeader.defaultProps = {
	latestUpdateInfo: {},
	headerTitle: '',
	subtitle: '',
	extra: [],
	showBackButton: false,
	capitalize: true,
	borderBottom: false,
	backButtonAction: null,
	newType: 'Request',
};

LocalPageHeader.propTypes = {
	latestUpdateInfo: PropTypes.object,
	headerTitle: PropTypes.string,
	subtitle: PropTypes.string,
	extra: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	showBackButton: PropTypes.bool,
	capitalize: PropTypes.bool,
	borderBottom: PropTypes.bool,
	backButtonAction: PropTypes.func,
	customBreadcrumbLabel: PropTypes.string,
	newType: PropTypes.string,
};

export default LocalPageHeader;
