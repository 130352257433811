import React from 'react';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';

import localization from 'localization';
const locale = localization.Outlet.BrandForm.Header;

const OutletBrandFormHeader = ({isEdit}) => {
	return (
		<div>
			<LocalPageHeader
				headerTitle={isEdit ? locale.editTitle : locale.defaultTitle}
				showBackButton
			/>
		</div>
	);};

OutletBrandFormHeader.defaultProps = {
	isEdit: false,
};

OutletBrandFormHeader.propTypes = {
	isEdit: PropTypes.bool,
};

export default OutletBrandFormHeader;