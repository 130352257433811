import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import localization from 'localization';
import {useNavigate} from 'react-router-dom';

import {debounce, get, isEmpty} from 'lodash';

import {Button, Card, Form, Input, Modal, Select, Upload, notification} from 'antd';
const {TextArea} = Input;
import {UploadOutlined} from '@ant-design/icons';

// Request
import {editVersion, getUploadedVersion, uploadNewVersion} from 'utils/request/admin';
import {getUploadSignedURL, uploadMasterMenu} from 'utils/request/masterlist';
import {handleErrorFetch} from 'utils/utils';

const semVerRegex = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][\dA-Za-z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][\dA-Za-z-]*))*))?(?:\+([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?$/;
const fileNameFormat = 'FrestoPOS Setup ';

const VersionUploaderFormBody = ({
	isEdit, isDetail,
	versionData, openProgressModal, closeProgressModal,
}) => {
	const locale = localization.Admin.VersionUploader.Form;
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const generateUniqueId = () => {
		// Current timestamp as base
		let timestamp = new Date().getTime();

		// Generate a random number and convert it to base 36
		let random = (Math.random() * 10000000000000000).toString(36);

		// Concatenate timestamp and random number
		let uniqueId = timestamp.toString(36) + random;

		return uniqueId;
	};

	const getDefaultFileList = fileType => {
		if (isEmpty(versionData)) return;
		if (isDetail || isEdit ) {
			return [{
				uid: generateUniqueId(),
				name: `${fileNameFormat}${versionData.id}.${fileType}`,
				status: 'done',
				url: '',
			}];
		} else return [{}];
	};

	const handleUpload = async (fileName, uploadType, fileType) => {
		try {
			const {signedURL: exeSignedUrl} = await getUploadSignedURL({
				uploadType,
				filename: fileName,
				allowPublic: false,
				fixedName: true,
				fileType,
			});

			return exeSignedUrl;
		} catch (error) {
			handleErrorFetch();
		}
	};

	const handleSubmit = async formData => {
		try {
			setLoading(true);

			const fileName = `${fileNameFormat}${formData.id}`;
			const exeFile = get(formData, ['exe', 0, 'originFileObj'], {});
			const blockmapFile = get(formData, ['blockmap', 0, 'originFileObj'], {});

			if (!isEmpty(exeFile)) {
				openProgressModal({
					uploadingTitle: 'Uploading .exe',
					completeTitle: '.exe file Uploaded',
				});
				const exeSignedUrl = await handleUpload(fileName, 'pos_installer', 'exe');
				await uploadMasterMenu(exeSignedUrl, exeFile);
			}

			if (!isEmpty(blockmapFile)) {
				openProgressModal({
					uploadingTitle: 'Uploading .exe.blockmap',
					completeTitle: '.exe.blockmap file Uploaded',
				});
				const blockmapSignedUrl = await handleUpload(fileName, 'pos_installer_blockmap', 'exe.blockmap');
				await uploadMasterMenu(blockmapSignedUrl, blockmapFile);
			}

			if (!isEmpty(exeFile) || !isEmpty(blockmapFile)) closeProgressModal();

			const payload = {
				channel: formData.channel,
				changelog: formData.changelog,
				fileName,
			};

			if (isEdit) {
				payload.id = versionData.id;
				payload.newId = formData.id;
			} else {
				payload.id = formData.id;
			}

			const callFn = isEdit ? editVersion : uploadNewVersion;

			const response = await callFn(payload);
			if (response.success) {
				const notificationLocale = isEdit ? locale.EditSuccess : locale.Success;
				notification.open({
					type: 'success',
					message: notificationLocale.message,
					description: notificationLocale.description,
				});
				navigate('/admin/version-settings/version-uploader');
			}
		} catch (error) {
			const notificationLocale = isEdit ? locale.EditFailed : locale.Failed;
			notification.open({
				type: 'error',
				message: notificationLocale.message,
				description: notificationLocale.description,
			});
		} finally {
			setLoading(false);
		}
	};

	const beforeSubmit = e => {
		const confirmationLocale = isEdit ? locale.EditConfirmation : locale.SubmitConfirmation;
		Modal.confirm({
			centered: true,
			title: confirmationLocale.message,
			content: confirmationLocale.description,
			okText: confirmationLocale.ok,
			cancelText: confirmationLocale.cancel,
			onOk() {
				handleSubmit(e);
			},
		});
	};

	const normFile = e => {
		// Use to validate file when remove
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	const getCardTitle = () => {
		if (isDetail) return locale.detailTitle;
		if (isEdit) return locale.editTitle;
		return locale.title;
	};

	const debouncedValidateVersion = debounce(async (id, callback) => {
		try {
			await getUploadedVersion({search: {id}});

			const response = await getUploadedVersion({search: {id}});

			if (response.data.count) return isEdit
				? Promise.resolve()
				:  callback(locale.versionAlreadyExist);
			else return Promise.resolve();

		} catch (error) {
			return Promise.resolve();
		}
	}, 1000);

	const handleActionOnPressKey = (e, insertion) => {
		e.preventDefault();
		const {value, selectionStart, selectionEnd} = e.target;
		const newValue = `${value.substring(0, selectionStart)}${insertion}${value.substring(selectionEnd)}`;
		e.target.value = newValue;
		const newSelectionStart = selectionStart + 1; // Move cursor after the inserted tab
		e.target.selectionStart = e.target.selectionEnd = newSelectionStart;
		e.target.dispatchEvent(new Event('input', {bubbles: true}));
	};

	useEffect(() => {
		if (!isEmpty(versionData) && (isEdit || isDetail)) {
			form.setFieldsValue({
				id: versionData.id,
				channel: versionData.channel,
				changelog: versionData.changelog,
			});
		}
	}, [versionData]);

	return (
		<div className='px-6 pb-12'>
			<Card
				title={getCardTitle()}
			>
				<Form
					disabled={loading}
					form={form}
					layout="vertical"
					name="adminUserForm"
					scrollToFirstError
					onFinish={e => !loading && beforeSubmit(e)}
					onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
					requiredMark={false}
				>
					<div className='max-w-md'>
						<Form.Item
							label={locale.versionName}
							name="id"
							rules={[
								{
									required: true,
									validator: (_, val, callback) => {
										if (!val) return Promise.reject(locale.versionNameRequired);

										const validFormat = semVerRegex.test(val);

										if (validFormat) return debouncedValidateVersion(val, callback);
										else return Promise.reject(locale.invalidVersionName);
									},
								},

							]}
						>
							<Input
								disabled={isDetail}
								placeholder={locale.versionNamePlaceholder} />
						</Form.Item>
						<Form.Item
							label={locale.channel}
							name="channel"
							rules={[{required: true, message: locale.channelRequired}]}
						>
							<Select
								disabled={isDetail}
								placeholder={locale.channelPlaceholder}
								options={[
									{label: 'Stable', value: 'stable'},
									{label: 'Beta', value: 'beta'},
								]}
							/>
						</Form.Item>
						<Form.Item
							label={locale.changelog}
							name="changelog"
							rules={[{required: true, message: locale.changelogRequired}]}
						>
							<TextArea
								disabled={isDetail}
								rows={4}
								placeholder={locale.changelogPlaceholder}
								onKeyDown={e => {
									if (e.key === 'Tab') {
										handleActionOnPressKey(e, '\t');
									}
								}}
								onPressEnter={e => {
									handleActionOnPressKey(e, '\n');
								}}
							/>
						</Form.Item>
						<Form.Item
							label={locale.exeLabel}
							name="exe"
							rules={[{required: isEdit ? false : true, message: locale.exeRequired}]}
							getValueFromEvent={normFile}
						>
							<Upload
								defaultFileList={getDefaultFileList('exe')}
								disabled={isDetail}
								accept='.exe'
								multiple={false}
								maxCount={1}
								beforeUpload={() => false}
							>
								<Button
									disabled={isDetail}
									icon={<UploadOutlined />}>{locale.clickToUpload}</Button>
							</Upload>
						</Form.Item>
						<Form.Item
							label={locale.blockmapLabel}
							name="blockmap"
							rules={[{required: isEdit ? false : true, message: locale.blockmapRequired}]}
							getValueFromEvent={normFile}
						>
							<Upload
								defaultFileList={getDefaultFileList('exe.blockmap')}
								disabled={isDetail}
								accept='.blockmap'
								multiple={false}
								maxCount={1}
								beforeUpload={() => false}
							>
								<Button
									disabled={isDetail}
									icon={<UploadOutlined />}>{locale.clickToUpload}</Button>
							</Upload>
						</Form.Item>
					</div>
					{
						!isDetail && (
							<div className='flex gap-2 justify-end'>
								<Button
									onClick={() => navigate('/admin/version-settings/version-uploader')}
								>
									{locale.cancel}
								</Button>
								<Button
									type="primary"
									htmlType='submit'
								>
									{isEdit ? locale.save : locale.submit}
								</Button>
							</div>
						)
					}
				</Form>
			</Card>
		</div>
	);
};

VersionUploaderFormBody.defaultProps = {
	isEdit: false,
	isDetail: false,
	versionData: {},
	openProgressModal: () => null,
	closeProgressModal: () => null,
};

VersionUploaderFormBody.propTypes = {
	isEdit: PropTypes.bool,
	isDetail: PropTypes.bool,
	versionData: PropTypes.object,
	openProgressModal: PropTypes.func,
	closeProgressModal: PropTypes.func,
};

export default VersionUploaderFormBody;