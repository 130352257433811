import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Drawer, Input, List} from 'antd';
const {Search} = Input;

import localization from 'localization';
import {transferOutletFormatter} from 'utils/utils';
const locale = localization.Global.OutletListDrawer;

const OutletListDrawer = ({
	visible, onClose, outletList, locationOnly,
	title, placeholder,
}) => {

	const [searchLabel, setSearchLabel] = useState(null);
	const [matchOutletList, setMatchOutletList] = useState([]);

	const generateItemLabel = item => {
		return locationOnly ? item.label : transferOutletFormatter(item);
	};

	const onSearch = value => {
		if (!value) {
			setMatchOutletList(outletList);
		} else {
			setMatchOutletList(outletList.filter(item => generateItemLabel(item).toLowerCase().includes(value.toLowerCase())));
		}
	};

	useEffect(() => {
		setMatchOutletList(outletList);
	}, [outletList]);

	useEffect(() => {
		onSearch(searchLabel);
	}, [searchLabel]);

	useEffect(() => {
		if (visible) setSearchLabel(null);
	}, [visible]);

	return (
		<Drawer
			title={title || locale.title}
			placement="right"
			onClose={onClose}
			visible={visible}>
			<div>
				<List
					header={(
						<Search
							value={searchLabel}
							allowClear
							placeholder={placeholder || locale.inputPlaceholder}
							onChange={e => setSearchLabel(e.target.value)}
						/>
					)}
					dataSource={matchOutletList}
					renderItem={item => (
						<List.Item>
							{generateItemLabel(item)}
						</List.Item>
					)}
				/>
			</div>
		</Drawer>
	);
};

OutletListDrawer.defaultProps = {
	visible: false,
	onClose: () => null,
	outletList: [],
	locationOnly: false,
	title: null,
	placeholder: null,
};

OutletListDrawer.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	outletList: PropTypes.array,
	locationOnly: PropTypes.bool,
	title: PropTypes.string,
	placeholder: PropTypes.string,
};

export default OutletListDrawer;
