import axios from 'utils/axios';
import crashLogger from 'utils/logger';
import {PROMO_CATEGORY, URLS} from 'utils/constants';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const getMenuDetailsFromMenuTemplate = async id => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/menu-template/menu-details`,
			params: {
				id,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createNewPromo = async (payload, promoCategory) => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/promo/${promoCategory === PROMO_CATEGORY.INTERNAL ? 'internal': 'request'}`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createNewFlashSalePromo = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/promo/flash-sale/request`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPromoRequest = async (query, promoCategory) => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/promo/${promoCategory === PROMO_CATEGORY.INTERNAL ? 'internal/': ''}request`,
			params: {
				sortBy: 'id',
				sortOrder: 'desc',
				limit: 4,
				...query,
			},
		});

		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const rejectPromoRequest = async (requestId, notes, promoCategory) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/promo/${promoCategory === PROMO_CATEGORY.INTERNAL ? 'internal/' : ''}request/reject`,
			data: {
				requestId,
				notes,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const approvePromoRequest = async (requestId, promoCategory) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/promo/${promoCategory === PROMO_CATEGORY.INTERNAL ? 'internal/' : ''}request/approve`,
			data: {
				requestId,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const cancelPromoRequest = async (approvalId, promoCategory) => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/cms/${promoCategory === PROMO_CATEGORY.INTERNAL ? 'promo' : 'campaign'}-request`,
			data: {
				id: approvalId,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const cancelPromoSyncRequest = async (promoId, promoCategory) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/promo/${promoCategory === PROMO_CATEGORY.INTERNAL ? 'internal/' : ''}update/terminate`,
			data: {
				updateId: promoId,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const resyncPromo = async promoId => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/promo/update/resync`,
			data: {
				updateId: promoId,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const cancelPromo = async promoIds => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/promo/update/cancel`,
			data: {
				updateId: promoIds,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const editPromo = async payload => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/promo/request`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPromoStatusRequest = async (query, type) => {
	try {
		const {limit, page, search, sortBy, sortOrder, idOnly} = query;
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/promo/${type === PROMO_CATEGORY.INTERNAL ? 'internal/': ''}request/status`,
			params: {
				limit,
				offset: Number((page) * limit) || null,
				...search,
				sortBy,
				sortOrder,
				idOnly,
			},
		});
		if (data.success) return data.data;
		else throw {};
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const refreshPromoStatus = async () => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/promo/refresh/status`,
		});
		if (data.success) return data.data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPromoDetailReview = async campaignId => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/promo/request/review`,
			params: {
				campaignId,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const toggleInternalPromo = async ({updateId, updateTo, notes}) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/promo/internal/update/status`,
			data: {
				updateId,
				updateTo,
				notes: notes || null,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getInternalPromousage = async params => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/promo/internal/request/status/usage`,
			params,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};