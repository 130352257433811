import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {Button, Card, Divider, Form, Input, InputNumber, Radio, Select, Transfer, notification} from 'antd';

import LocalPageHeader from 'components/LocalPageHeader';

import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';
import {getPlatformList} from 'utils/request/global';
import {createInternalPlatform} from 'utils/request/internalPlatform';
import {handleErrorFetch, inputNumberCurrencyFormatter} from 'utils/utils';
import {createSalesChannel, editSalesChannel, getSalesChannelDetail} from 'utils/request/posSetting';

import {PlusOutlined} from '@ant-design/icons';
import localization from 'localization';
const locale = localization.POSSetting.SalesChannel.Form;

const SalesChannelForm = ({isEdit, isDetail}) => {
	const navigate = useNavigate();
	const params = useParams();
	const [form] = Form.useForm();
	const skipPlatformValidation = useRef(null);

	const locationIdsValue = Form.useWatch('locationIds', form); // Used to provide value for targetKeys
	const salesChannelNameValue = Form.useWatch('name', form); // Used to provide value for targetKeys
	const platformTypeValue = Form.useWatch('platformType', form); // Used to provide value for targetKeys

	const goBack = () => navigate('/pos-settings/sales-channel');

	const [loading, setLoading] = useState(false);
	const [loadingNewPlatform, setLoadingNewPlatform] = useState(false);
	const [locationLoading, setLocationLoading] = useState(false);
	const [locationList, setLocationList] = useState([]);
	const [platformList, setPlatformList] = useState([]);
	const [newPlatformLabel, setNewPlatformLabel] = useState(null);

	const handleAddPlatform = async () => {
		try {
			setLoadingNewPlatform(true);
			const response = await createInternalPlatform({label: newPlatformLabel});
			if (response.success) {
				setNewPlatformLabel(null);
				getPlatform();
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingNewPlatform(false);
		}
	};

	const handleSubmit = async e => {
		try {
			setLoading(true);

			const submitFn = isEdit ? editSalesChannel : createSalesChannel;

			if (isEdit) e.id = params?.id;
			delete e.platformType;

			if (platformTypeValue === 'external') e.salesType = 'delivery';

			const response = await submitFn(e);

			if (response.success) {
				const notificationLocale = isEdit ? locale.SuccessEdit : locale.SuccessCreate;
				notification.success({
					message: notificationLocale.message,
					description: notificationLocale.description,
				});
				goBack();
			}

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const getLocation = async outletLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const getPlatform = async () => {
		try {
			const response = await getPlatformList({type: platformTypeValue});
			if (response.success) {
				setPlatformList(response.data.rows.map(platform => {
					return {
						label: platform.label,
						value: platform.id,
					};
				}));
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const initialFetch = async () => {
		try {
			skipPlatformValidation.current = true;
			getLocation();
			if (isEdit || isDetail) {
				const response = await getSalesChannelDetail(params?.id);
				if (response.success) {
					form.setFieldsValue({
						isActive: response.data.isActive,
						name: response.data.name,
						additionalFee: response.data.additionalFee,
						type: response.data.type,
						locationIds: response.data.locations.map(location => location.id),
						platformType: response.data?.Platform?.type,
						platformId: response.data?.Platform?.id,
					});
				}
			}
		} catch (error) {
			handleErrorFetch(error);
			goBack();
		} finally {
			skipPlatformValidation.current = false;
		}
	};

	useEffect(() => {
		initialFetch();
	}, []);

	useEffect(() => {
		if (platformTypeValue) getPlatform();

		if (skipPlatformValidation.current !== true) {
			form.setFieldsValue({
				platformId: null,
			});
		}
	}, [platformTypeValue]);

	return (
		<div className="bg-white h-full overflow-auto">
			<LocalPageHeader
				headerTitle={isDetail ? salesChannelNameValue : isEdit ? locale.titleEdit : locale.titleNew}
				newType={'Sales Channel'}
				showBackButton={true}
				backButtonAction={goBack}
			/>
			<Form
				disabled={isDetail}
				form={form}
				layout="vertical"
				name="adminUserForm"
				scrollToFirstError
				onFinish={e => !loading && handleSubmit(e)}
				onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
				requiredMark={false}
				initialValues={{
					salesType: 'takeaway',
				}}
			>
				<Card className='mx-6 mb-6 flex flex-col gap-6'>

					{/* Payment Name Field */}
					<Form.Item
						className='w-96'
						label={locale.salesChannelName}
						name="name"
						rules={[{required: true}]}
					>
						<Input placeholder={locale.salesChannelNamePlaceholder} />
					</Form.Item>

					{/* Sales Channel Type Field */}
					<Form.Item
						className='w-96'
						label={locale.salesChannelTypeLabel}
						name="type"
						rules={[{required: true}]}
					>
						<Radio.Group>
							<Radio
								key={'sales'}
								value={'sales'}>{locale.sales}</Radio>
							<Radio
								key={'nonSales'}
								value={'non_sales'}>{locale.nonSales}</Radio>
							<Radio
								key={'training'}
								value={'training'}>{locale.training}</Radio>
						</Radio.Group>
					</Form.Item>

					{/* Additional Fee Field */}
					<Form.Item
						className='w-96'
						label={locale.additionalFee}
						name="additionalFee"
					>
						<InputNumber
							formatter={inputNumberCurrencyFormatter}
							addonBefore='Rp'
							max={999999999999}
							type='tel'
							className='w-full'
							placeholder={locale.additionalFeePlaceholder} />
					</Form.Item>

					{/* Platform Type Field */}
					<Form.Item
						className='w-96'
						label={locale.platformType}
						name="platformType"
						rules={[{required: true}]}
					>
						<Radio.Group>
							<Radio
								key={'internal'}
								value={'internal'}>{locale.internal}</Radio>
							<Radio
								key={'external'}
								value={'external'}>{locale.external}</Radio>
						</Radio.Group>
					</Form.Item>

					{/* Sales Type Field */}
					{
						platformTypeValue === 'internal' && (
							<Form.Item
								className='w-96'
								label={locale.orderType}
								name="salesType"
								rules={[{required: true}]}
							>
								<Radio.Group>
									<Radio
										key={'takeaway'}
										value={'takeaway'}>{locale.takeaway}</Radio>
									<Radio
										key={'dinein'}
										value={'dinein'}>{locale.dinein}</Radio>
								</Radio.Group>
							</Form.Item>
						)
					}

					{
						platformTypeValue && (
							<>
								{/* Selected Platform Field */}
								<Form.Item
									className='w-96'
									label={locale.selectPlatform}
									name="platformId"
									rules={[{required: true}]}
								>
									<Select
										showSearch
										filterOption={(input, option) => {
											return option.label.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
										}}
										loading={loadingNewPlatform}
										placeholder={locale.selectPlatformPlaceholder}
										dropdownRender={menu => (
											<div className='flex flex-col'>
												{menu}
												{
													platformTypeValue === 'internal' && (
														<>
															<Divider className='m-0' />
															<div className='px-3 py-2 flex gap-6 justify-between items-center'>
																<Input
																	placeholder={locale.enterNewPlatformPlaceholder}
																	disabled={loadingNewPlatform}
																	onChange={e => setNewPlatformLabel(e.target.value)}
																	value={newPlatformLabel}
																	className='flex-1' />
																<div
																	onClick={() => (!loadingNewPlatform && newPlatformLabel) && handleAddPlatform()}
																	className={clsx(
																		loadingNewPlatform || !newPlatformLabel ? 'cursor-not-allowed' : 'cursor-pointer',
																		'flex items-center gap-2 p-1 rounded-md hover:bg-gray-100 transition-colors duration-100',
																	)}
																>
																	<PlusOutlined />
																	<span>{locale.addPlatform}</span>
																</div>
															</div>
														</>
													)
												}
											</div>
										)}
										options={platformList}
									/>
								</Form.Item>
							</>
						)
					}

					{/* isActive Field */}
					<Form.Item
						className='w-96'
						label={locale.isActive}
						name="isActive"
						rules={[{required: true}]}
						initialValue={true}
					>
						<Radio.Group>
							<Radio
								key={'1'}
								value={true}>{locale.yes}</Radio>
							<Radio
								key={'0'}
								value={false}>{locale.no}</Radio>
						</Radio.Group>
					</Form.Item>

					{/* Outlet Field */}
					<Form.Item
						label={locale.selectLocation}
						name="locationIds"
						rules={[{required: true, message: locale.selectLocationRequired}]}
					>
						<Transfer
							disabled={isDetail}
							listStyle={{flexGrow: '1', height: 350}}
							loading={locationLoading}
							dataSource={locationList.map(e => ({
								...e,
								key: e.id,
							}))}
							targetKeys={locationIdsValue}
							titles={[locale.allLocation, locale.selectedLocation]}
							render={item => item.label}
							showSearch
							filterOption={(input, option) => {
								return option.label.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
							}}
						/>
					</Form.Item>

					{/* Action */}
					{
						!isDetail && (
							<div className='flex gap-2 justify-end'>
								<Button
									onClick={goBack}
									loading={loading}>
									{locale.cancel}
								</Button>
								<Button
									loading={loading}
									type="primary"
									htmlType="submit">
									{locale.save}
								</Button>
							</div>
						)
					}

				</Card>
			</Form>
		</div>
	);
};

SalesChannelForm.defaultProps = {
	isEdit: false,
	isDetail: false,
};

SalesChannelForm.propTypes = {
	isEdit: PropTypes.bool,
	isDetail: PropTypes.bool,
};

export default SalesChannelForm;