import React from 'react';
import PropTypes from 'prop-types';

import {Dropdown, Form, Switch, Tooltip, Typography} from 'antd';
import {DownOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import localization from 'localization';

import {PACKAGING_OPTIONS} from 'utils/constants';
import {cloneDeep, get, isEmpty, set} from 'lodash';

const MenuItemTitle = ({currentOptionGroup, optionGroupList, currentOptionGroupId,
	form, separatable, canOverridePercentage, getMappingValue, setOverridePercentageList,
	overridePercentageList}) => {
	const locale = localization.MasterlistPage.MasterlistForm.MenuStructureForm.ManageMapping.packagingOptions;
	const optionGroupsWatch = Form.useWatch('optionGroups', form);

	const handleToggleSwitch = isChecked => {
		if (isChecked) {
			setOverridePercentageList(state => state.concat(currentOptionGroupId));
		} else setOverridePercentageList(state => state.filter(item => item !== currentOptionGroupId));
		const tempFormVal = cloneDeep(getMappingValue());

		const currentOptionGroupMappingEntry = get(tempFormVal, ['optionGroups', currentOptionGroupId, 'childMenus']);

		for (const childMenuId in currentOptionGroupMappingEntry) {
			const mappingTargets = currentOptionGroupMappingEntry[childMenuId];
			mappingTargets.forEach((mappingTarget => {
				const overrideToDelete = isChecked ? 'menuPrice' : 'pricePercentage';
				delete mappingTarget[overrideToDelete];
			}));
		}

		form.setFieldsValue(tempFormVal);
	};

	const checkComplexDepedency = () => {
		if (isEmpty(optionGroupsWatch)) return [];
		const depedencyIds = Object.keys(optionGroupsWatch)
			.filter(id => optionGroupsWatch[id]?.packaging?.refId == currentOptionGroupId);

		return depedencyIds || [];
	};

	const hasDepedency = checkComplexDepedency()?.length;

	const getOptionGroupLabel = id => {
		const foundOptionGroup = optionGroupList.find(item => item.optionGroupId == id);
		return foundOptionGroup?.optionGroupLabel || '';
	};

	const getSeparateComplex = () => {
		if (isEmpty(optionGroupsWatch)) return [];
		const separatePackagingIds = Object.keys(optionGroupsWatch)
			.filter(id => optionGroupsWatch[id]?.packaging?.type === PACKAGING_OPTIONS.SEPARATE) // Get all id with separate packaging type
			.filter(id => id !== String(currentOptionGroupId)) // Exclude self from target link if self is separate
			.filter(id => (optionGroupList.find(item => item.optionGroupId == id) || {}).minSelection >= 1); // item must be choosen (min selection 1)

		return separatePackagingIds || [];
	};

	const getCurrentSelectedOption = () => {
		const packagingOption = get(optionGroupsWatch, [currentOptionGroupId, 'packaging']);

		let output = '';
		switch (packagingOption?.type) {
		case PACKAGING_OPTIONS.DEFAULT:
		case PACKAGING_OPTIONS.SEPARATE:
		case PACKAGING_OPTIONS.HIDE:
			output = locale[packagingOption?.type];
			break;
		case PACKAGING_OPTIONS.LINK:
			if (packagingOption?.refId) {
				output = locale.linkTo
					.replace(
						'{{optionGroupTarget}}',
						getOptionGroupLabel(packagingOption?.refId),
					);
			}
			break;
		default:
			break;
		}

		return output || 'Kosong';
	};

	const handleChangePackaging = (choosenOption, refId) => {
		const output = {};
		switch (choosenOption) {
		case PACKAGING_OPTIONS.DEFAULT:
		case PACKAGING_OPTIONS.SEPARATE:
		case PACKAGING_OPTIONS.HIDE:
			output.type = choosenOption;
			output.refId = undefined;
			break;
		case PACKAGING_OPTIONS.LINK:
			if (refId) {
				output.type = choosenOption,
				output.refId = String(refId);
			}
			break;
		default:
			break;
		}

		const tempFormVal = cloneDeep(optionGroupsWatch);
		set(tempFormVal, [currentOptionGroupId, 'packaging'], output);

		form.setFieldsValue({
			optionGroups: tempFormVal,
		});
	};

	const dropdownItems = [
		{
			key: PACKAGING_OPTIONS.DEFAULT,
			label: locale.setAs.replace('{{option}}', locale.child),
			onClick: () => handleChangePackaging(PACKAGING_OPTIONS.DEFAULT),
			disabled: hasDepedency,
		},
		{
			key: PACKAGING_OPTIONS.SEPARATE,
			label: locale.setAs.replace('{{option}}', locale.separate),
			onClick: () => handleChangePackaging(PACKAGING_OPTIONS.SEPARATE),
			disabled: hasDepedency,
		},
		{
			key: PACKAGING_OPTIONS.HIDE,
			label: locale.hide,
			onClick: () => handleChangePackaging(PACKAGING_OPTIONS.HIDE),
			disabled: hasDepedency,
		},
		{type: 'divider'},
		{key: PACKAGING_OPTIONS.LINK,
			label: locale.link,
			disabled: !getSeparateComplex().length ||
			getCurrentSelectedOption() === PACKAGING_OPTIONS.SEPARATE ||
			hasDepedency,
			children: getSeparateComplex().map(optionGroupId => {
				return ({
					key: optionGroupId,
					label: getOptionGroupLabel(optionGroupId),
					onClick: () => handleChangePackaging(PACKAGING_OPTIONS.LINK, optionGroupId),
				});
			})},
		...(hasDepedency ? [
			{type: 'divider'},
			{
				key: 'alert',
				type: 'group',
				label:
			<Tooltip
				title={(
					<div>
						<div>{locale.linkedTo}</div>
						{
							checkComplexDepedency()?.map((depedencyId, index) => (
								<div key={depedencyId}>{`${index+ 1}. ${getOptionGroupLabel(depedencyId)}`}</div>
							))
						}
					</div>
				)}>
				<span className='text-antd-red-6 italic flex gap-1'><ExclamationCircleOutlined />{locale.unlinkAlert}</span>
			</Tooltip>,
			},
		] : []),
	];

	return (
		<div className='flex items-center justify-between'>
			<div className='flex gap-1'>
				<Typography.Title
					className='font-medium m-0'
					level={5}>
					{currentOptionGroup.optionGroupLabel}
				</Typography.Title>
				<Typography.Text
					type='secondary'
				>
					{
						locale.optionGroupMinMax
							.replace('{{minCount}}', currentOptionGroup.minSelection)
							.replace('{{maxCount}}', currentOptionGroup.maxSelection)
					}
				</Typography.Text>
				{
					separatable && (
						<>
							{' · '}
							<Dropdown
								trigger={['click']}
								menu={{items: dropdownItems}}
							>
								<a
									className='flex gap-1 items-center text-antd-blue-6'
									onClick={e => e.preventDefault()}>
									{getCurrentSelectedOption()}
									<DownOutlined />
								</a>
							</Dropdown>
						</>
					)
				}
			</div>
			{
				canOverridePercentage && (
					<div className='flex gap-2 items-center'>
						<Typography.Text>Override Price in Percent (%)</Typography.Text>
						<Switch
							checked={overridePercentageList.includes(currentOptionGroupId)}
							onChange={handleToggleSwitch} />
					</div>
				)
			}
		</div>
	);
};

MenuItemTitle.defaultProps = {
	currentOptionGroup: {},
	optionGroupList: [],
	form: {},
	currentOptionGroupId: null,
	separatable: false,
	canOverridePercentage: false,
	getMappingValue: () => null,
	setOverridePercentageList: () => null,
	overridePercentageList: [],
};

MenuItemTitle.propTypes = {
	currentOptionGroup: PropTypes.object,
	optionGroupList: PropTypes.array,
	form: PropTypes.object,
	currentOptionGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	separatable: PropTypes.bool,
	canOverridePercentage: PropTypes.bool,
	getMappingValue: PropTypes.func,
	setOverridePercentageList: PropTypes.func,
	overridePercentageList: PropTypes.array,
};

export default MenuItemTitle;