import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const getMenuSyncRequest = async query => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/sync/template/request`,
			params: {
				sortBy: 'id',
				sortOrder: 'desc',
				limit: 4,
				...query,
			},
		});
		if (data.success) {
			return data.data;
		} else {
			throw {};
		}
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getMenuTemplateJSON = async id => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/menu-template/grab/json`,
			params: {id},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getMenuTemplateDetail = async ({id, useLatest}) => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/menu-template/${id}/details`,
			params: {
				useLatest,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createNewRequest = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/sync/request`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const approveSyncRequest = async requestId => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/sync/approve`,
			data: {
				requestId,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const rejectSyncRequest = async (requestId, notes) => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/sync/reject`,
			data: {
				requestId,
				notes,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getSyncStatusRequest = async query => {
	try {
		const {limit, page, search, sortBy, sortOrder, idOnly} = query;
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/sync/request`,
			params: {
				limit,
				offset: Number((page) * limit) || null,
				...search,
				sortBy,
				sortOrder,
				idOnly,
			},
		});
		if (data.success) return data.data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const cancelApprovalRequest = async approvalId => {
	try {
		const {data} = await axios({
			method: 'DELETE',
			url: `${baseURL}/cms/sync-request`,
			data: {
				id: approvalId,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const resyncTemplate = async id => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/sync/update/resync`,
			data: {
				updateId: id,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const reSyncApproval = async id => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/sync/update/resync`,
			data: {
				updateId: id,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const cancelMenuSync = async id => {
	try {
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/sync/update/cancel`,
			data: {
				updateId: id,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createMenuTemplateDraft = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/menu-template/create`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const saveMenuTemplateDraft = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/menu-template/save`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const submitMenuTemplate = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/cms/menu-template/submit`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const createInternalRequest = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/sync/request/internal`,
			data: {...payload},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getMenuTags = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/menu-tags`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPrintItems = async payload => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/order/label-items`,
			data: payload,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};