import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import Metric from './Metric';
import Table from './Table';

const CheckerBody = ({platform}) => {
	const metricFormRef = useRef();
	const tableFormRef = useRef();

	return (
		<div className='flex flex-col gap-4'>
			<Metric
				ref={metricFormRef}
				tableFormRef={tableFormRef}
			/>
			<Table
				ref={tableFormRef}
				metricFormRef={metricFormRef}
				platform={platform}
			/>
		</div>
	);
};

CheckerBody.defaultProps = {
	platform: null,
};

CheckerBody.propTypes = {
	platform: PropTypes.string,
};


export default CheckerBody;