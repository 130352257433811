import React from 'react';
import PropTypes from 'prop-types';

import {Button, Drawer} from 'antd';

// Local styles
import './receiptDrawer.less';

import OrderInfo from './OrderInfo';
import OrderItems from './OrderItems';
import OrderSummary from './OrderSummary';
import ReceiptDivider from './ReceiptDivider';

import {CloseOutlined} from '@ant-design/icons';

import localization from 'localization';
const locale = localization.Sales.TaxDashboard.ReceiptDrawer;

const ReceiptDrawer = ({visible, drawerData, close}) => {

	return (
		<Drawer
			title={(
				<div className='flex justify-between'>
					<div>{locale.title}</div>
					<CloseOutlined onClick={close} />
				</div>
			)}
			onClose={close}
			closeIcon={false}
			width={520}
			className='ReceiptDrawer'
			visible={visible}>
			<div className='h-full flex flex-col'>
				<div className='flex-1 overflow-auto border-t border-antd-netural-4'>
					<OrderInfo infoData={drawerData} />
					<ReceiptDivider />
					<OrderItems itemsData={drawerData.order_items} />
					<ReceiptDivider />
					<OrderSummary orderData={drawerData} />
				</div>
				<div className='mt-auto p-6 border-t border-antd-netural-2 shadow-2xl'>
					<Button
						onClick={close}
						className='w-full'>{locale.close}</Button>
				</div>
			</div>
		</Drawer>
	);
};

ReceiptDrawer.defaultProps = {
	visible: false,
	drawerData: {},
	close: () => null,
};

ReceiptDrawer.propTypes = {
	visible: PropTypes.bool,
	drawerData: PropTypes.object,
	close: PropTypes.func,
};

export default ReceiptDrawer;