import React from 'react';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';

import moment from 'moment';

import localization from 'localization';
const locale = localization.Admin.ReleaseScheduler.Form;

const ReleaseSchedulerFormHeader = ({isDetail, scheduleData}) => {

	return (
		<div>
			<LocalPageHeader
				headerTitle={
					isDetail
						? moment(scheduleData.releaseDate)
							.format('DD MMM YYYY - HH:mm')
						: locale.header
				}
				showBackButton
			/>
		</div>
	);
};

ReleaseSchedulerFormHeader.defaultProps = {
	isDetail: false,
	scheduleData: {},
};

ReleaseSchedulerFormHeader.propTypes = {
	isDetail: PropTypes.bool,
	scheduleData: PropTypes.object,
};

export default ReleaseSchedulerFormHeader;