import React from 'react';

import LocationGroupHeader from './LocationGroupHeader';
import LocationGroupBody from './LocationGroupBody';

const LocationGroup = () => {
	return (
		<div className='bg-white h-full overflow-auto'>
			<LocationGroupHeader />
			<LocationGroupBody />
		</div>
	);
};

export default LocationGroup;