import React from 'react';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';

import {PROMO_CATEGORY} from 'utils/constants';

import {Button} from 'antd';
import {EyeOutlined, ShopOutlined} from '@ant-design/icons';

import localization from 'localization';
const locale = localization.Promo.Review.Header;

const PromoDetailHeader = ({
	promoLabel, viewOutletList, previewPromo, loadingPreview, outletCount, promoCategory,
}) => {
	return (
		<div>
			<LocalPageHeader
				headerTitle={promoLabel}
				capitalize={false}
				showBackButton
				extra={[
					<Button
						onClick={viewOutletList}
						icon={<ShopOutlined />}
						key="outlet">
						{locale.outletList.replace('{{count}}', outletCount)}
					</Button>,
					...(promoCategory === PROMO_CATEGORY.INTERNAL ? [] : [
						<Button
							loading={loadingPreview}
							onClick={previewPromo}
							icon={<EyeOutlined />}
							key="outlet">{locale.promoPreview}</Button>,
					]),
				]}
			/>
		</div>
	);
};

PromoDetailHeader.defaultProps = {
	promoLabel: null,
	outletCount: 0,
	viewOutletList: () => null,
	previewPromo: () => null,
	loadingPreview: false,
	promoCategory: null,
};

PromoDetailHeader.propTypes = {
	promoLabel: PropTypes.string,
	outletCount: PropTypes.number,
	viewOutletList: PropTypes.func,
	previewPromo: PropTypes.func,
	loadingPreview: PropTypes.bool,
	promoCategory: PropTypes.string,
};

export default PromoDetailHeader;
