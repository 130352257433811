import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import ProTable from '@ant-design/pro-table';

import {salesPayloadHandler, handleErrorFetch} from 'utils/utils';
import {getSettlement} from 'utils/request/sales';

const Table = forwardRef(({metricFormRef, columns}, ref) => {

	const fetchTableData = async params => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const metricPayload = salesPayloadHandler(metricFormRef.current?.getMetricFilter?.());

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				...metricPayload,
			};

			const response = await getSettlement({params: payload, platform: 'others'});

			metricFormRef?.current?.updateMetricData?.({
				sum_subtotal: response.data.summary.sum_subtotal,
				sum_total_normal_price: response.data.summary.sum_total_normal_price,
				sum_pricecut: response.data.summary.sum_pricecut || 0,
				sum_sales_before_tax: response.data.summary.sum_sales_before_tax,
			});

			return {
				data: response.data.transactions,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<ProTable
			actionRef={ref}
			className='SettlementTable py-4'
			columns={columns}
			search={false}
			options={false}
			scroll={{x: 'max-content'}}
			request={fetchTableData}
		/>
	);
});

Table.defaultProps = {
	metricFormRef: {},
	columns: [],
};

Table.propTypes = {
	metricFormRef: PropTypes.object,
	columns: PropTypes.func,
};

export default Table;