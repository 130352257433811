import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {notification} from 'antd';

import OutletListDrawer from 'components/OutletListDrawer';
import RejectApprovalOverlay from 'components/RejectApprovalOverlay';

import PromoForm from '../CreatePromoRequest/ExternalPromoForm/PromoForm';
import InternalPromoForm from '../CreatePromoRequest/InternalPromoForm';
import PromoDetailHeader from './PromoDetailHeader';
import PromoReviewModal from './PromoReviewModal';

import isEmpty from 'lodash/isEmpty';

import {approvePromoRequest, getPromoDetailReview} from 'utils/request/promo';
import {handleErrorFetch} from 'utils/utils';
import {PROMO_CATEGORY} from 'utils/constants';

import localization from 'localization';
const locale = localization.Promo.Review;

const PromoDetail = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const rawCardData = location.state?.rawCardData;
	const initialPromoType = location.state?.promoType;
	const promoCategory = location.state?.promoCategory;

	const [promoType, setPromoType] = useState(initialPromoType || null);
	const [providedLocation, setProvidedLocation] = useState([]);

	const [outletDrawerActive, setOutletDrawerActive] = useState(false);
	const [loadingPreview, setLoadingPreview] = useState(false);

	const [promoReviewModalActive, setPromoReviewModalActive] = useState(false);
	const [promoReviewData, setPromoReviewData] = useState({});

	const [showRejectModal, setShowRejectModal] = useState(false);

	const [loadingApprove, setLoadingApprove] = useState(false);

	const viewOutletList = () => {
		setOutletDrawerActive(true);
	};

	const previewPromo = async () => {
		// If promo data is empty, fetch else (data exist) show modal
		if (isEmpty(promoReviewData)) {
			try {
				setLoadingPreview(true);
				const response = await getPromoDetailReview(rawCardData.id);
				if (response.success) {
					setPromoReviewData(response.data);
					setPromoReviewModalActive(true);
				} else {
					throw {};
				}
			} catch (error) {
				handleErrorFetch(error);
			} finally {
				setLoadingPreview(false);
			}
		} else {
			setPromoReviewModalActive(true);
		}
	};

	const handleApprove = async () => {
		try {
			setLoadingApprove(true);

			const response = await approvePromoRequest(rawCardData.id, promoCategory);
			if (response.success) {
				notification.open({
					message: locale.Notification.Approve.Success.message,
					description: locale.Notification.Approve.Success.description.replace('{{id}}', rawCardData.id),
					type: 'success',
				});
				if (promoCategory === PROMO_CATEGORY.EXTERNAL) navigate('/promo/promo-request/price-cut');
				else navigate('/promo/promo-request/free-item');
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingApprove(false);
		}
	};

	const groupLocation = merchants => {
		if (merchants?.length) {
			const uniqueLocations = Array.from(new Set(merchants.map(item => item.Location.id)))
				.map(locationId => merchants.find(item => item.Location.id === locationId).Location);

			setProvidedLocation(uniqueLocations);
		}
	};

	useEffect(() => {
		if (isEmpty(rawCardData)) {
			navigate('/promo/promo-status', {replace: true});
		} else {
			groupLocation(rawCardData?.Outlets);
			setPromoType(rawCardData.templateType);
		}
	}, []);

	return (
		<div className="bg-white h-full overflow-auto">
			<RejectApprovalOverlay
				visible={showRejectModal}
				approvalId={rawCardData.id}
				promoCategory={promoCategory}
				refetchCards={() => {
					if (promoCategory === PROMO_CATEGORY.INTERNAL) navigate('/promo/promo-request/free-item');
					else navigate('/promo/promo-request/price-cut');
				}}
				requestType='promo'
				close={() => {
					setShowRejectModal(false);
				}}
			/>
			<OutletListDrawer
				visible={outletDrawerActive}
				outletList={rawCardData?.Outlets}
				onClose={() =>setOutletDrawerActive(false)}
			/>
			<PromoReviewModal
				visible={promoReviewModalActive}
				promoReviewData={promoReviewData}
				onClose={() =>setPromoReviewModalActive(false)}
				menuTemplateLabel={rawCardData?.MenuTemplate?.label}
				providedLocation={providedLocation}
			/>
			<PromoDetailHeader
				promoCategory={promoCategory}
				promoLabel={rawCardData?.label}
				outletCount={rawCardData?.Outlets?.length}
				viewOutletList={viewOutletList}
				previewPromo={previewPromo}
				loadingPreview={loadingPreview}
			/>
			<div className='px-6 pb-20'>
				{
					promoCategory === PROMO_CATEGORY.INTERNAL
						? (
							<InternalPromoForm
								showRejectModal={() => setShowRejectModal(true)}
								handleApprove={handleApprove}
								loadingApprove={loadingApprove}
							/>
						)
						: (
							<PromoForm
								promoType={promoType}
								showRejectModal={() => setShowRejectModal(true)}
								handleApprove={handleApprove}
								loadingApprove={loadingApprove}
							/>
						)
				}
			</div>
		</div>
	);
};

export default PromoDetail;
