import React from 'react';

import VersionUploaderHeader from './VersionUploaderHeader';
import VersionUploaderBody from './VersionUploaderBody';

const VersionUploader = () => {
	return (
		<div className='bg-white h-full overflow-auto'>
			<VersionUploaderHeader />
			<VersionUploaderBody />
		</div>
	);
};

export default VersionUploader;