import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Button, Card, Radio, Select} from 'antd';
const {Option} = Select;

import {PARENT_MAPPING_OPTIONS} from 'utils/constants';

import localization from 'localization';
import MenuStructureModal from './MenuStructureModal';
const locale = localization.MasterlistPage.MasterlistForm.MenuStructureForm.ManageCondition;

const ManageCondition = ({menuData, nextPhase, handlePopulateData, goBack}) => {
	const [selectedParentMenu, setselectedParentMenu] = useState(null);
	const [complexRuleForChild, setComplexRuleForChild] = useState(null);

	const [validComplexRule, setValidComplexRule] = useState([]);

	const [selectedOptionGroupId, setSelectedOptionGroupId] = useState(null); // For Option Group
	const [selectedOptionGroupsId, setSelectedOptionGroupsId] = useState([]); // For complex

	const [menuStructureModalVisible, setMenuStructureModalVisible] = useState(false);

	const handleNextPhase = () => {
		const payload = {selectedParentMenu};

		switch (selectedParentMenu) {
		case PARENT_MAPPING_OPTIONS.MENU:
		case PARENT_MAPPING_OPTIONS.OPTION_GROUP:
			if (complexRuleForChild === 1) {
				payload.complexRuleForChild = true;
				payload.selectedOptionGroupsId = selectedOptionGroupsId;
			} else {
				payload.complexRuleForChild = false;
			}
			if (selectedParentMenu === PARENT_MAPPING_OPTIONS.OPTION_GROUP) payload.selectedOptionGroupId = selectedOptionGroupId;
			break;
		case PARENT_MAPPING_OPTIONS.COMPLEX_RULE:
			payload.selectedOptionGroupsId = selectedOptionGroupsId;
			break;
		default:
			break;
		}

		nextPhase(payload);
	};

	const getParentLabel = parentType => {
		switch (parentType) {
		case PARENT_MAPPING_OPTIONS.MENU:
			return locale.ParentMenuOptions.menu;
		case PARENT_MAPPING_OPTIONS.COMPLEX_RULE:
			return locale.ParentMenuOptions.complexRule;
		case PARENT_MAPPING_OPTIONS.OPTION_GROUP:
			return locale.ParentMenuOptions.optionGroup;
		case PARENT_MAPPING_OPTIONS.NONE:
			return locale.ParentMenuOptions.none;
		default:
			break;
		}
	};

	const filterSelectItem = (itemOptions, itemToExclude) => {
		// itemOptions in optionGroup object format
		// itemToExclude is array of id

		return itemOptions.filter(o => !itemToExclude.includes(o.optionGroupId));
	};

	useEffect(() => {
		// Only option group with min selection 1

		const tempValidComplexRule = [];
		menuData?.optionGroups?.forEach(optionGroup => {
			if (optionGroup.minSelection === 1) tempValidComplexRule.push(optionGroup);
		});

		setValidComplexRule(tempValidComplexRule);
	}, []);

	useEffect(() => {
		setComplexRuleForChild(null);
		setSelectedOptionGroupId(null);
		setSelectedOptionGroupsId([]);
	}, [selectedParentMenu]);

	return (
		<Card title={locale.title}>
			<MenuStructureModal
				visible={menuStructureModalVisible}
				close={() => setMenuStructureModalVisible(false)}
				menuData={menuData}
				handlePopulateData={handlePopulateData}
			/>
			<div className='flex flex-col gap-6'>

				{/* Parent Selection Section */}
				<div className='flex flex-col gap-2'>
					<div>{locale.parentMenuSelectionLabel}</div>
					<Radio.Group
						onChange={e => {
							setselectedParentMenu(e.target.value);
						}}
						value={selectedParentMenu}>
						{
							Object.values(PARENT_MAPPING_OPTIONS).map(parent => (
								<Radio
									value={parent}
									key={parent}>{getParentLabel(parent)}</Radio>
							))
						}
					</Radio.Group>
				</div>
				{/* End of Parent Selection Section */}


				{/* Option Group Section */}
				{
					selectedParentMenu === PARENT_MAPPING_OPTIONS.OPTION_GROUP && (
						<div className='flex flex-col gap-2'>
							<div>{locale.parentOptionGroupLabel}</div>
							<Select
								className='w-96'
								placeholder={locale.parentOptionGroupPlaceholder}
								value={selectedOptionGroupId}
								onChange={val => setSelectedOptionGroupId(val)}>
								{
									filterSelectItem(validComplexRule, selectedOptionGroupsId).map(optionGroup => (
										<Option
											value={optionGroup.optionGroupId}
											key={optionGroup.optionGroupId}
											label={optionGroup.optionGroupLabel}
										>{optionGroup.optionGroupLabel}</Option>
									))
								}
							</Select>
						</div>
					)
				}
				{/* End of Option Group Section */}

				{/* Parent Selection Section */}
				{
					[PARENT_MAPPING_OPTIONS.MENU, PARENT_MAPPING_OPTIONS.OPTION_GROUP].includes(selectedParentMenu) && (
						<div className='flex flex-col gap-2'>
							<div>{locale.childMenuComplexRuleLabel}</div>
							<Radio.Group
								onChange={e => setComplexRuleForChild(e.target.value)}
								value={complexRuleForChild}>
								<Radio value={1}>{locale.yes}</Radio>
								<Radio value={0}>{locale.no}</Radio>
							</Radio.Group>
						</div>
					)
				}
				{/* End of Parent Selection Section */}

				{/* Complex Rule Section */}
				{
					(
						selectedParentMenu === PARENT_MAPPING_OPTIONS.COMPLEX_RULE ||
						[PARENT_MAPPING_OPTIONS.MENU, PARENT_MAPPING_OPTIONS.OPTION_GROUP].includes(selectedParentMenu) && complexRuleForChild
					)
						? (
							<div className='flex flex-col gap-2'>
								<div>{locale.parentComplexRuleLabel}</div>
								<Select
									mode="multiple"
									className='w-96'
									placeholder={locale.parentComplexRulePlaceholder}
									value={selectedOptionGroupsId}
									onChange={val => setSelectedOptionGroupsId(val)}>
									{
										// Exclude selected option group in complex rule
										(
											selectedParentMenu === PARENT_MAPPING_OPTIONS.OPTION_GROUP
												? filterSelectItem(validComplexRule, [selectedOptionGroupId])
												: validComplexRule
										).map(optionGroup => (
											<Option
												value={optionGroup.optionGroupId}
												key={optionGroup.optionGroupId}
												label={optionGroup.optionGroupLabel}
											>{optionGroup.optionGroupLabel}</Option>
										))
									}
								</Select>
							</div>
						)
						: null
				}
				{/* End of Complex Rule Section */}

				{/* Action Section */}
				<div className='flex justify-end gap-2'>
					<Button onClick={() => setMenuStructureModalVisible(true)} >{locale.useSimilarMapping}</Button>
					<Button onClick={goBack} >{locale.cancel}</Button>
					<Button
						onClick={handleNextPhase}
						disabled={
							!selectedParentMenu ||
							(selectedParentMenu === PARENT_MAPPING_OPTIONS.MENU && (complexRuleForChild === 1 && selectedOptionGroupsId.length < 2 || complexRuleForChild === null)) ||
							(selectedParentMenu === PARENT_MAPPING_OPTIONS.COMPLEX_RULE && selectedOptionGroupsId.length < 2) ||
							(selectedParentMenu === PARENT_MAPPING_OPTIONS.OPTION_GROUP && (!selectedOptionGroupId || (complexRuleForChild === null || complexRuleForChild === 1 && selectedOptionGroupsId.length < 2 )))
						}
						type='primary'>{locale.next}</Button>
				</div>
				{/* End of Action Section */}

			</div>
		</Card>
	);
};

ManageCondition.defaultProps = {
	menuData: {},
	nextPhase: () => null,
	handlePopulateData: () => null,
	goBack: () => null,
};

ManageCondition.propTypes = {
	menuData: PropTypes.object,
	nextPhase: PropTypes.func,
	handlePopulateData: PropTypes.func,
	goBack: PropTypes.func,
};

export default ManageCondition;