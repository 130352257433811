import React from 'react';

import ProTable from '@ant-design/pro-table';

import {handleErrorFetch, momentDateFormat, sortDirectionConverter} from 'utils/utils';
import {getPlatformList} from 'utils/request/global';

import localization from 'localization';
const locale = localization.POSSetting.InternalPlatform.Table;

const InternalPlatformTable = () => {

	const columns = [
		{
			key: 'id',
			dataIndex: 'id',
			title: locale.platformId,
			width: 160,
		},
		{
			key: 'label',
			dataIndex: 'label',
			title: locale.platformLabel,
			width: 320,
		},
		{
			key: 'createdBy',
			dataIndex: ['creator', 'username'],
			title: locale.createdBy,
			width: 257,
		},
		{
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: locale.createdAt,
			width: 256,
			search: false,
			render: createdAt => momentDateFormat({dateString: createdAt, format: 'DD MMM YYYY - HH:mm'}),
			sorter: true,
			defaultSortOrder: 'descend',
		},
		{
			key: 'action',
			dataIndex: 'id',
			title: locale.action,
			fixed: 'right',
			align: 'center',
			search: false,
			render : platformId => (
				<a
					className='text-antd-blue-6'
					href={`/pos-settings/platform-internal/${platformId}/edit`}>{locale.edit}</a>
			),
		},
	];

	const fetchTableData = async (params, sorter) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				sortBy: 'createdAt',
				sortOrder: sortDirectionConverter(Object.values(sorter)?.[0] || 'desc'),
				label: params.label,
				id: params.id,
				createdBy: params.createdBy,
				type: 'internal',
			};

			Object.keys(payload).forEach(k => !payload[k] && delete payload[k]);

			const response = await getPlatformList(payload);
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<ProTable
			className='ResetSearchTableMargin px-6'
			rowKey='id'
			columns={columns}
			request={fetchTableData}
			toolBarRender={false}
			scroll={{x: 'max-content'}}
			search={{
				layout: 'vertical',
			}}
			pagination={{
				defaultPageSize: 25,
				showSizeChanger: true,
				showQuickJumper: true,
				pageSizeOptions: ['10', '25', '50', '75', '100'],
			}}
		/>
	);
};

export default InternalPlatformTable;