import React, {useState} from 'react';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';

import {DownloadOutlined, ExportOutlined, ImportOutlined} from '@ant-design/icons';
import {Button, Dropdown, Menu} from 'antd';
import ImportCSVOverlay from 'components/Overlay/ImportCSVOverlay';
import ExportCSVOverlay from 'components/Overlay/ExportCSVOverlay';
import {OUTLET_TYPE, USER_ROLES} from 'utils/constants';

import localization from 'localization';
import ProtectedComponent from 'components/ProtectedComponent';
const locale = localization.Outlet.Activation.Header;

const OutletActivationHeader = ({handleExport, refetchTableRef}) => {
	const [uploadedFile, setUploadedFile] = useState(null);
	const [importCSV, setImportCSV] = useState({visible: false, type: 'add'});
	const [getCSV, setGetCSV] = useState({visible: false, type: 'export'});

	const openGetCSVOverlay = type => {
		setGetCSV({visible: true, type});
	};

	const closeGetCSVOverlay = () => {
		setGetCSV({...getCSV, visible: false});
	};

	const openImportCSVOverlay = type => {
		setImportCSV({visible: true, type});
	};

	const closeImportCSVOverlay = () => {
		setUploadedFile(null);
		setImportCSV({...importCSV, visible: false});
	};

	const handleFileUpload = async (e, type = 'add') => {
		if (!e.target.files.length) return;

		const uploadedFileTemp = e.target.files[0];
		e.target.value = null;

		setUploadedFile(uploadedFileTemp);
		openImportCSVOverlay(type);
	};

	const importMenu = (
		<Menu>
			<Menu.Item key='add'>
				<label className='cursor-pointer'>
					{locale.add}
					<input
						className="hidden"
						type="file"
						accept=".tsv"
						onChange={e => handleFileUpload(e, 'add')} />
				</label>
			</Menu.Item>
			<Menu.Item key='update'>
				<label className='cursor-pointer'>
					{locale.update}
					<input
						className="hidden"
						type="file"
						accept=".tsv"
						onChange={e => handleFileUpload(e, 'update')} />
				</label>
			</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<ImportCSVOverlay
				masterlistType={OUTLET_TYPE.MERCHANT}
				refetchTable={() => refetchTableRef?.current?.({reset: true, highlightNewData: true})}
				handleFileUpload={handleFileUpload}
				uploadedFile={uploadedFile}
				visible={importCSV.visible}
				importType={importCSV.type}
				close={closeImportCSVOverlay}
			/>
			<ExportCSVOverlay
				masterlistType={OUTLET_TYPE.MERCHANT}
				handleExport={handleExport}
				visible={getCSV.visible}
				close={closeGetCSVOverlay}
				type={getCSV.type}
			/>
			<LocalPageHeader
				headerTitle={locale.title}
				extra={(
					<div className='flex gap-2'>
						<ProtectedComponent when={
							u => u.roles.some(role => [USER_ROLES.SUPERADMIN, USER_ROLES.OUTLET.ADMIN, USER_ROLES.OUTLET.EDITOR, USER_ROLES.TRAINER.ADMIN,
								USER_ROLES.MARKETING.EDITOR, USER_ROLES.FINANCE.VIEWER, USER_ROLES.FINANCE.EDITOR].includes(role))
						}>
							<Button
								onClick={() => openGetCSVOverlay('download-template')}
								icon={<DownloadOutlined />}
							>{locale.downloadTemplate}</Button>
							<Button
								onClick={() => openGetCSVOverlay('export')}
								icon={<ExportOutlined />}
							>{locale.export}</Button>
							<Dropdown
								key='import'
								overlay={importMenu}
								placement="bottomLeft"
							>
								<Button
									type="primary"
									icon={<ImportOutlined />}>
									{locale.import}
								</Button>
							</Dropdown>
						</ProtectedComponent>
					</div>
				)}
			/>
		</div>
	);
};

OutletActivationHeader.defaultProps = {
	handleExport: () => null,
	refetchTableRef: {},
};

OutletActivationHeader.propTypes = {
	handleExport: PropTypes.func,
	refetchTableRef: PropTypes.object,
};

export default OutletActivationHeader;