import React from 'react';
import localization from 'localization';
import PropTypes from 'prop-types';

import LocalPageHeader from 'components/LocalPageHeader';

const LocationGroupFormHeader = ({isEdit, locationGroupData}) => {
	const locale = localization.Admin.LocationGroup.Form;

	return (
		<div>
			<LocalPageHeader
				headerTitle={isEdit ? locationGroupData.name : locale.header}
				showBackButton
			/>
		</div>
	);
};

LocationGroupFormHeader.defaultProps = {
	isEdit: false,
	locationGroupData: PropTypes.object,
};

LocationGroupFormHeader.propTypes = {
	isEdit: PropTypes.bool,
	locationGroupData: PropTypes.object,
};

export default LocationGroupFormHeader;