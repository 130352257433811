import React, {useRef} from 'react';

import PromoUsageHeader from './PromoUsageHeader';
import PromoUsageMetric from './PromoUsageMetric';
import PromoUsageBody from './PromoUsageBody';

// Local styles
import './promoUsage.less';

const PromoUsage = () => {
	const metricFormRef = useRef();
	const tableFormRef = useRef();

	return (
		<div className="bg-white h-full overflow-auto PromoUsage">
			<PromoUsageHeader />
			<PromoUsageMetric
				ref={metricFormRef}
				tableFormRef={tableFormRef}
				// brandList={brandList}
				// setDataCount={setDataCount}
			/>
			<PromoUsageBody />
		</div>
	);
};

export default PromoUsage;