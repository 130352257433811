import React, {forwardRef, useCallback, useState} from 'react';
import debounce from 'lodash/debounce';

import {Divider, Select} from 'antd';
import ProTable from '@ant-design/pro-table';

import LocationsModal from './LocationsModal';

import {OUTLET_TYPE} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';
import {getSalesChannel} from 'utils/request/posSetting';
import {handleErrorFetch, localeCurrencyFormat, momentDateFormat, removeEmptyFields} from 'utils/utils';

import localization from 'localization';
const locale = localization.POSSetting.SalesChannel.Table;

const locationModalConfigInitialState = {
	visible: false,
	data: {},
};

const SalesChannelTable = forwardRef((_, ref) => {
	const [locationModalConfig, setLocationModalConfig] = useState(locationModalConfigInitialState);

	const [location, setLocation] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);
	const closeLocationModal = () => setLocationModalConfig(locationModalConfigInitialState);
	const openLocationModal = salesChannelData => setLocationModalConfig({
		visible: true,
		data: salesChannelData,
	});

	const sortDirectionConverter = direction => {
		switch (direction) {
		case 'ascend':
			return 'asc';
		case 'descend':
			return 'desc';
		default:
			return 'desc';
		}
	};

	const columns = [
		{
			key: 'name',
			dataIndex: 'name',
			title: locale.salesChannelName,
			width: 175,
		},
		{
			key: 'type',
			dataIndex: 'type',
			title: locale.salesChannelType,
			width: 175,
			search: false,
			render: type => <div className='capitalize'>{type ? type : '-'}</div>,
		},
		{
			key: 'additionalFee',
			dataIndex: 'additionalFee',
			title: locale.additionalFee,
			width: 148,
			search: false,
			render: additionalFee => localeCurrencyFormat({value: Number(additionalFee) ? additionalFee : 0}),
		},
		{
			key: 'platformType',
			dataIndex: ['Platform', 'type'],
			title: locale.platformType,
			width: 175,
			render: platformType => <span className='capitalize'>{platformType}</span>,
		},
		{
			key: 'salesType',
			dataIndex: 'salesType',
			title: locale.orderType,
			width: 175,
			render: salesType => <span className='capitalize'>{salesType || '-'}</span>,
		},
		{
			key: 'platformLabel',
			dataIndex: ['Platform', 'label'],
			title: locale.platformLabel,
			width: 175,
			render: platformLabel => <span className='capitalize'>{platformLabel}</span>,
		},
		{
			key: 'locations',
			dataIndex: 'locations',
			title: locale.locationName,
			width: 148,
			render: (_, record) => record.locations.length ? (
				<div>
					{
						record.locations.length > 1
							? (
								<span
									onClick={() => openLocationModal(record)}
									className='text-antd-blue-6 cursor-pointer'>
									{locale.locationCount.replace('{{qty}}', record.locations.length)}
								</span>
							)
							: record.locations[0].label
					}
				</div>
			) : '-',
			renderFormItem: () => (
				<Select
					allowClear
					showSearch
					loading={locationLoading}
					onSearch={debounceFn}
					filterOption={((_, option) => option)}
				>
					{location.map(location => (
						<Select.Option
							key={location.id}
							value={location.id}>{location.label}</Select.Option>
					))}
				</Select>
			),
		},

		{
			key: 'isActive',
			dataIndex: 'isActive',
			title: locale.active,
			width: 109,
			valueEnum: {
				default: {text: 'All', status: 'Default'},
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isActive ? 'text-green-400' : 'text-red-400'}`
				}>{record.isActive ? 'Yes' : 'No'}</div>
			),
		},
		{
			key: 'createdBy',
			dataIndex: 'createdBy',
			title: locale.createdBy,
			width: 155,
		},
		{
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: locale.createdAt,
			width: 195,
			search: false,
			render: createdAt => momentDateFormat({dateString: createdAt, format: 'DD MMM YYYY - HH:mm'}),
			sorter: true,
			defaultSortOrder: 'descend',
		},
		{
			key: 'editedBy',
			dataIndex: 'editedBy',
			title: locale.editedBy,
			width: 155,
		},
		{
			key: 'editedAt',
			dataIndex: 'editedAt',
			title: locale.editedAt,
			width: 195,
			search: false,
			render: editedAt => momentDateFormat({dateString: editedAt, format: 'DD MMM YYYY - HH:mm'}),
			sorter: true,
		},
		{
			key: 'action',
			title: locale.action,
			fixed: 'right',
			align: 'center',
			search: false,
			render: (_, record) => (
				<div className="text-center flex gap-2 items-center justify-center text-antd-blue-6">
					<a
						href={`/pos-settings/sales-channel/${record.id}/edit`}
					>
						{locale.edit}
					</a>
					<Divider type='vertical' />
					<a
						href={`/pos-settings/sales-channel/${record.id}/detail`}
					>
						{locale.detail}
					</a>
				</div>
			),
		},
	];

	const getLocation = async locationLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: locationLabel},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocation(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};
	const debounceFn = useCallback(debounce(getLocation, 1000), []);


	const fetchTableData = async (params, sorter) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				sortBy: Object.keys(sorter)?.[0] || 'createdAt',
				sortOrder: sortDirectionConverter(Object.values(sorter)?.[0] || 'desc'),
				locationId: params.locations,
				name: params.name,
				createdBy: params.createdBy,
				editedBy: params.editedBy,
			};

			if (params.isActive === 'active') {
				payload.isActive = true;
			} else if (params.isActive === 'inactive') {
				payload.isActive = false;
			}

			removeEmptyFields(payload);

			const response = await getSalesChannel(({params: payload}));
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<>
			<LocationsModal
				visible={locationModalConfig.visible}
				salesChannelData={locationModalConfig.data}
				close={closeLocationModal}
			/>
			<ProTable
				actionRef={ref}
				className='ResetSearchTableMargin px-6'
				rowKey='id'
				columns={columns}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</>
	);
});

export default SalesChannelTable;