import React from 'react';

import LocalPageHeader from 'components/LocalPageHeader';

import localization from 'localization';
const locale = localization.Promo.PromoUsage.Internal;

const PromoUsageHeader = () => {
	return (
		<LocalPageHeader
			borderBottom
			headerTitle={locale.title} />
	);
};

export default PromoUsageHeader;