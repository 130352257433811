import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {handleErrorFetch} from 'utils/utils';
import {updateUserProfile} from 'utils/request/user';

import {Input, Modal, notification} from 'antd';

import localization from 'localization';
const locale = localization.Global.GlobalHeader.ChangePasswordModal;

const ChangePasswordModal = ({visible, onClose}) => {
	const [loading, setLoading] = useState(false);

	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');

	const beforeClose = () => {
		setLoading(false);

		setOldPassword('');
		setNewPassword('');
		setConfirmNewPassword('');

		onClose();
	};

	return (
		<Modal
			title={locale.title}
			visible={visible}
			centered
			closable={false}
			maskClosable={false}
			cancelText={locale.cancel}
			onCancel={beforeClose}
			cancelButtonProps={{loading}}
			okText={locale.ok}
			okButtonProps={{loading,
				disabled:
        // Every field cant be empty
        !(oldPassword.length && newPassword.length && confirmNewPassword.length) ||

        // Old password cant be the same with new password
        // and new password must be the same with confirm new password
        !(oldPassword !== newPassword && newPassword === confirmNewPassword)}}
			onOk={async () => {
				const fetchFn = async () => {
					try {
						setLoading(true);
						const payload = {
							currentPassword: oldPassword,
							newPassword,
						};

						const response = await updateUserProfile(payload);

						if (response.success) {
							beforeClose();
							notification.success({
								message: locale.Notification.Success.message,
								description: locale.Notification.Success.description,
							});
						} else {
							throw {};
						}
					} catch (error) {
						handleErrorFetch(error, {
							message: locale.Notification.Failed.message,
							description: locale.Notification.Failed.description,
						});
					} finally {
						setLoading(false);
					}
				};
				await fetchFn();
			}}
		>
			<div className='flex flex-col gap-4'>
				<div className='flex flex-col gap-2'>
					<div className='body-regular-14'>{locale.oldPasswordLabel}</div>
					<Input.Password
						value={oldPassword}
						onChange={e => setOldPassword(e.target.value.trim())}
						placeholder={locale.oldPasswordPlaceholder} />
				</div>
				<div className='flex flex-col gap-2'>
					<div className='body-regular-14'>{locale.newPasswordLabel}</div>
					<Input.Password
						value={newPassword}
						onChange={e => setNewPassword(e.target.value.trim())}
						placeholder={locale.newPasswordPlaceholder} />
				</div>
				<div className='flex flex-col gap-2'>
					<div className='body-regular-14'>{locale.confirmNewPasswordLabel}</div>
					<Input.Password
						value={confirmNewPassword}
						onChange={e => setConfirmNewPassword(e.target.value.trim())}
						placeholder={locale.confirmNewPasswordPlaceholder} />
				</div>
			</div>
		</Modal>
	);
};

ChangePasswordModal.defaultProps = {
	visible: false,
	onClose: () => null,
};

ChangePasswordModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
};

export default ChangePasswordModal;