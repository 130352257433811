import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import LocalPageHeader from 'components/LocalPageHeader';
import ImportCSVOverlay from 'components/Overlay/ImportCSVOverlay';

import {Button, Dropdown, Menu, Upload} from 'antd';
import {FormOutlined, ImportOutlined} from '@ant-design/icons';

import {authChecker} from 'utils/utils';
import {STORAGE_KEY, USER_ROLES} from 'utils/constants';

import localization from 'localization';
const locale = localization.SyncRequest.Header;

const SyncRequestHeader = ({refetchRequestRef}) => {
	const navigate = useNavigate();
	const auth = useSelector(state => state.user);

	const [uploadedFile, setUploadedFile] = useState(null);
	const [importCSVVisible, setImportCSVVisible] = useState(false);

	const handleFileUpload = async e => {
		if (!e.fileList.length) return;

		const uploadedFileTemp = e.fileList[0].originFileObj;

		setUploadedFile(uploadedFileTemp);
		setImportCSVVisible(true);
	};

	return (
		<div>
			<ImportCSVOverlay
				masterlistType={'sync-request'}
				refetchTable={() => refetchRequestRef?.current?.()}
				handleFileUpload={handleFileUpload}
				uploadedFile={uploadedFile}
				visible={importCSVVisible}
				importType={'add'}
				close={() => setImportCSVVisible(false)}
			/>
			<LocalPageHeader
				headerTitle='Sync Request'
				extra={authChecker({
					auth,
					requiredRolesArr: [USER_ROLES.SYNC_MENU.ADMIN, USER_ROLES.SYNC_MENU.EDITOR],
					children: [
						<Upload
							key="import"
							accept=".tsv"
							className='inline-block'
							showUploadList={false}
							beforeUpload={() => false}
							maxCount={1}
							onChange={e => handleFileUpload(e)}
						>
							<Button
								type="primary"
								icon={<ImportOutlined />}
							>
								{locale.import}
							</Button>
						</Upload>,
						<Dropdown
							trigger={['click']}
							key='new'
							overlay={(
								<Menu>
									<Menu.Item
										onClick={() => {
											navigate('/sales-channel/internal-sync-request/new');
										}}
									>
										{locale.internalRequest}
									</Menu.Item>
									<Menu.Item
										onClick={() => {
											sessionStorage.removeItem(STORAGE_KEY.MASTERLIST_MENU_REQUEST_DETAIL); // Remove sync request data if any
											navigate('/sales-channel/sync-request/new');
										}}
									>
										{locale.externalRequest}
									</Menu.Item>
								</Menu>
							)}
							placement="bottomRight"
						>
							<Button
								icon={<FormOutlined />}
								type='primary'>
								{locale.new}
							</Button>
						</Dropdown>,
					],
				})}
			/>
		</div>
	);
};

SyncRequestHeader.defaultProps = {
	refetchRequestRef: {},
};

SyncRequestHeader.propTypes = {
	refetchRequestRef: PropTypes.object,
};

export default SyncRequestHeader;
