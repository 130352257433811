import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import OutletListDrawer from 'components/OutletListDrawer';
import LocalPageHeader from 'components/LocalPageHeader';
import RejectApprovalOverlay from 'components/RejectApprovalOverlay';
import ProtectedComponent from 'components/ProtectedComponent';

import {approveSyncRequest, getMenuSyncRequest, getMenuTemplateDetail} from 'utils/request/salesChannel';
import {cartCompressHandler, handleErrorFetch} from 'utils/utils';
import {USER_ROLES} from 'utils/constants';

import {Button, Modal, Spin, Tabs, notification} from 'antd';
import {ShopOutlined} from '@ant-design/icons';

import localization from 'localization';
import {get} from 'lodash';
import MenuTemplateWrapper from './MenuTemplateWrapper';
import MenuTemplateCart from 'components/MenuTemplateCart';
const locale = localization.MenuTemplate.ReviewTemplate;

const MegaMenuTemplateDetail = () => {
	const {templateId, requestId} = useParams();
	const navigate = useNavigate();

	const [requestDetail, setRequestDetail] = useState({});
	const [templateDetail, setTemplateDetail] = useState({});
	const [outletDrawerActive, setOutletDrawerActive] = useState(false);
	const [loading, setLoading] = useState(false);
	const [approvalIdToReject, setApprovalIdToReject] = useState(null);
	const [showRejectModal, setShowRejectModal] = useState(false);

	const [cart, setCart] = useState([]);

	const [brandTabs, setBrandTabs] = useState([]);

	const isReview = requestId;

	const viewOutletList = () => {
		setOutletDrawerActive(true);
	};

	const cartSetter = item => {
		const cartTemp = JSON.parse(JSON.stringify(cart));
		const compressedCart = cartCompressHandler(cartTemp.concat(item));
		setCart(compressedCart);
	};

	const handleSetBrands = callResponse => {
		setBrandTabs(get(callResponse, ['data', 'details', 'groupings'], []));
	};

	const fetchByTemplateId = async () => {
		try {
			setLoading(true);

			const menuTemplateresponse = await getMenuTemplateDetail({id: templateId});
			if (menuTemplateresponse.success) {
				setTemplateDetail(menuTemplateresponse?.data?.details);
				handleSetBrands(menuTemplateresponse);
			}

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchByRequestId = async () => {
		try {
			setLoading(true);
			let syncDetailData = null;

			const syncRequestResponse = await getMenuSyncRequest({id: requestId});
			if (syncRequestResponse?.rows?.length) {
				syncDetailData = syncRequestResponse?.rows[0];
				setRequestDetail(syncDetailData);
			}

			const menuTemplateresponse = await getMenuTemplateDetail({id: syncDetailData.menuTemplateId});
			if (menuTemplateresponse.success) {
				setTemplateDetail(menuTemplateresponse?.data?.details);
				handleSetBrands(menuTemplateresponse);
			}

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const initialFetch = () => {
		if (requestId) fetchByRequestId();
		else fetchByTemplateId();
	};

	const openRejectOverlay = () => {
		setApprovalIdToReject(requestId);
		setShowRejectModal(true);
	};

	const goBack = () => navigate('/sales-channel/sync-request');

	const handleApprove = async () => {
		try {
			setLoading(true);
			const response = await approveSyncRequest(requestId);
			if (response.success) {
				notification.open({
					message: locale.Approve.Success.message,
					description: locale.Approve.Success.description.replace('{{id}}', requestId),
					type: 'success',
				});
				goBack();
			} else {
				notification.open({
					message: locale.Approve.Failed.message,
					description: response.error || locale.Approve.Failed.description,
					type: 'warning',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		initialFetch();
	}, []);

	return (
		<div className="bg-white flex-1 overflow-auto flex flex-col">
			{
				loading ? (
					<div className='h-full flex justify-center items-center'>
						<Spin size='large' />
					</div>
				) : (
					<>
						<RejectApprovalOverlay
							visible={showRejectModal}
							approvalId={approvalIdToReject}
							refetchCards={goBack}
							requestType='sync'
							close={() => {
								setShowRejectModal(false);
								setApprovalIdToReject(null);
							}}
						/>
						<OutletListDrawer
							visible={outletDrawerActive}
							outletList={requestDetail?.Outlets}
							onClose={() =>setOutletDrawerActive(false)}
						/>
						<LocalPageHeader
							headerTitle={templateDetail?.label}
							extra={isReview ? [
								<Button
									onClick={viewOutletList}
									icon={<ShopOutlined />}
									key="outlet">
									{locale.Header.outletList.replace('{{count}}', requestDetail?.Outlets?.length)}
								</Button>,
							] : []}
						/>
						<div>
							{
								brandTabs.length
									? (
										<Tabs
											tabBarStyle={{paddingInline: '24px'}}
										>
											{
												brandTabs.map(menuTemplate => (
													<Tabs.TabPane
														key={menuTemplate.brandId}
														tab={menuTemplate.Brand.label}>
														<MenuTemplateWrapper
															menuTemplateId={menuTemplate.menuTemplateId}
															cart={cart}
															setCart={item => cartSetter(item)}
														/>
													</Tabs.TabPane>
												))
											}
										</Tabs>
									)
									: null
							}
							<div className='flex justify-end gap-4 pb-6 px-6'>
								<MenuTemplateCart
									cart={cart}
									setCart={setCart}
								/>
								{
									requestDetail?.status === 'pending' && (
										<>

											<ProtectedComponent
												when={u => u.roles.some(
													role => [USER_ROLES.SYNC_MENU.ADMIN, USER_ROLES.SYNC_MENU.APPROVER].includes(role),
												)}
											>
												<Button
													onClick={openRejectOverlay}
													danger>{locale.Actions.reject}</Button>
												<Button
													onClick={() =>
														Modal.confirm({
															title: locale.ApproveConfirmation.title,
															content: locale.ApproveConfirmation.content,
															okText: locale.ApproveConfirmation.ok,
															onOk: () => handleApprove(),
															centered: true,
															maskClosable: true,
														})
													}
													type='primary'>{locale.Actions.approve}</Button>
											</ProtectedComponent>
										</>
									)
								}
							</div>
						</div>
					</>
				)
			}
		</div>
	);
};

export default MegaMenuTemplateDetail;