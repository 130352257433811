import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import startCase from 'lodash/startCase';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import moment from 'moment';

import {ProFormText, QueryFilter, ProFormDateRangePicker, ProFormDatePicker, ProFormSelect} from '@ant-design/pro-form';

import {handleErrorFetch} from 'utils/utils';
import {getOutletData} from 'utils/request/outlet';
import {OUTLET_TYPE} from 'utils/constants';

const Metric = forwardRef(({tableFormRef}, ref) => {
	const salesDropdownOption = useSelector(state => state.salesDropdownOption);

	const [brandList, setBrandList] = useState([]);
	const [locationList, setLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

	const queryRef = useRef();

	// Methods
	const refetchData = () => {
		tableFormRef.current?.reload?.();
	};
	const resetForm = () => {
		queryRef?.current?.resetFields?.();
		tableFormRef.current?.reloadAndRest();
	};
	const getFilterPayload = () => {
		return queryRef.current?.getFieldsValue?.();
	};

	const getDefaultDate = () => {
		const today = moment();
		const yesterday = moment(today).subtract(1, 'day');
		return [yesterday.startOf('day'), yesterday.endOf('day')];
	};

	const getLocation = async outletLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)));
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	const fetchBrand = async () => {
		try {
			const response = await getOutletData({
				search: {limit: 0},
			}, OUTLET_TYPE.BRAND);
			if (response.success) {
				setBrandList(response.data.rows.sort((a, b) => a.label.localeCompare(b.label)).map(brand => ({
					label: brand.label,
					value: brand.label,
				})));
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	useImperativeHandle(ref, () => ({
		getMetricFilter() {
			return getFilterPayload();
		},
	}));

	useEffect(() => {
		fetchBrand();
	}, []);

	return (
		<div>
			<QueryFilter
				formRef={queryRef}
				className="p-6 pb-0 my-4 border border-antd-netural-4 bg-antd-netural-2 rounded-md"
				layout="vertical"
				onFinish={() => refetchData()}
				onReset={() => resetForm()}
				initialValues={{
					sales_date: getDefaultDate(),
					platform_order_status: [
						'accepted',
						'driverFound',
						'driverArrived',
						'collected',
						'delivered',
						'failed',
						'NULL',
					],
				}}
			>
				<ProFormDateRangePicker
					name="sales_date"
					label="Sales Date"
				/>
				<ProFormDatePicker
					name="created_at"
					label="Fresto Accepted Date"
				/>
				<ProFormSelect
					showSearch
					name="payment_method"
					label="Payment Method"
					mode='multiple'
					fieldProps={{showArrow: true}}
					options={salesDropdownOption?.payment_method_fresto?.sort?.()}
				/>
				<ProFormSelect
					name="branch"
					label="Branch"
					showSearch
					mode='multiple'
					fieldProps={{
						onSearch: debounceFn,
						loading: locationLoading,
						showArrow: true,
					}}
					options={locationList.map(location => location.label)}
				/>
				<ProFormSelect
					showSearch
					name="brand"
					label="Brand"
					mode='multiple'
					fieldProps={{showArrow: true}}
					options={brandList}
				/>
				<ProFormSelect
					name="inject_status"
					label="Inject Status"
					mode='multiple'
					fieldProps={{showArrow: true}}
					options={salesDropdownOption?.inject_status}
				/>
				<ProFormText
					name="sales_number"
					label="Sales Number"
				/>
				<ProFormText
					name="external_long_id"
					label="Long ID"
				/>
				<ProFormSelect
					mode='multiple'
					fieldProps={{showArrow: true}}
					name="platform_order_status"
					label="Platform Order Status"
					options={salesDropdownOption?.platform_order_status?.map(orderStatus => ({
						value: orderStatus,
						label: startCase(orderStatus),
					}))}
				/>
				<ProFormSelect
					mode='multiple'
					fieldProps={{showArrow: true}}
					name="platform"
					label="Platform"
					options={['Grab', 'Gojek', 'Shopee'].map(platform => {
						return {
							label: platform,
							value: platform,
						};
					})}
				/>
				<ProFormSelect
					mode='multiple'
					fieldProps={{showArrow: true}}
					name="linking_method"
					label="Linking Method"
					options={salesDropdownOption?.linking_method}
				/>
			</QueryFilter>
		</div>
	);
});

Metric.defaultProps = {
	tableFormRef: {},
};

Metric.propTypes = {
	tableFormRef: PropTypes.object,
};

export default Metric;