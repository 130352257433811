import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Form, Input, Modal, Typography} from 'antd';

import localization from 'localization';
import {generateMappingPayload, getMappingConfig, getMappingRawConfig, handleErrorFetch} from 'utils/utils';
import {getMappings} from 'utils/request/mapping';
import {find, get, isEqual, set, sortBy} from 'lodash';
import {AVAILABLE_POS, PARENT_MAPPING_OPTIONS} from 'utils/constants';

const locale = localization.MasterlistPage.MasterlistForm.MenuStructureForm.ManageCondition.SelectMenuStructureModal;

const INVALID_FORMAT = 'invalidFormat';
const HASH_NOT_FOUND = 'hashNotFound';

const MenuStructureModal = ({visible, close, menuData, handlePopulateData}) => {
	const [form] = Form.useForm();
	const {posId} = useParams();

	const [loading, setLoading] = useState(false);

	const clearFieldErrors = (_changedValues, allValues) => {
		const updatedFields = Object.keys(allValues)
			.filter(name => form.getFieldError(name).length)
			.map(name => ({name, errors: []}));
		form.setFields(updatedFields);
	};

	const flattenOptionGroupChildMapping = optionGroupMapping => {
		const output = [];
		for (const i in optionGroupMapping) {
			const childsEntry = optionGroupMapping[i].childMenus;
			for (const j in childsEntry) {
				output.push({
					childId: j,
					childData: childsEntry[j],
				});
			}
		}

		return output;
	};

	const handleSearchHash = async e => {
		let hashProblematicAlert = null;
		try {
			const {hash} = e;
			setLoading(true);
			const response = await getMappings({search: {hash: hash?.trim()}, posId});
			if (response.success) {
				if (response.data.count) {

					const foundHash = response.data.rows[0];
					const foundHashIds = {
						menuId: Number(foundHash.menuDetails.menuId),
						optGroupsId: foundHash.menuDetails.optionGroups.map(optionGroup => Number(optionGroup.optionGroupId)),
					};

					const currentHashIds = {
						menuId: Number(menuData.menuId),
						optGroupsId: menuData.optionGroups.map(optionGroup => Number(optionGroup.optionGroupId)),
					};

					if (foundHashIds.menuId == currentHashIds.menuId) {
						const {
							selectedOptionGroupsId,
							selectedOptionGroupId,
							selectedParentMenu,
						} = getMappingConfig(foundHash.mappingDetails);

						// Parent validation
						const menuDataOg = get(menuData, ['optionGroups'], []);

						if (selectedParentMenu === PARENT_MAPPING_OPTIONS.OPTION_GROUP) {
							const ogExist = menuDataOg.some(og => og.optionGroupId == selectedOptionGroupId);
							if (!ogExist) throw INVALID_FORMAT;
						}

						if (selectedParentMenu === PARENT_MAPPING_OPTIONS.COMPLEX_RULE) {
							const ogExist = menuDataOg.some(og => selectedOptionGroupsId.includes(og.optionGroupId));
							if (!ogExist) throw INVALID_FORMAT;
						}

						const {asComplexRule, asOptionGroup} = getMappingRawConfig(
							{
								selectedOptionGroupId,
								selectedOptionGroupsId,
								selectedParentMenu,
							},
							menuData,
						);

						const {
							formPayload: tempMappingDetails,
						} = generateMappingPayload({
							selectedParentMenu,
							selectedOptionGroupId,
							asComplexRule,
							asOptionGroup,
						});

						if (selectedParentMenu == PARENT_MAPPING_OPTIONS.COMPLEX_RULE && asComplexRule.length <= 1) return INVALID_FORMAT;

						// Handle Menu
						set(tempMappingDetails, ['menu'], get(foundHash, ['mappingDetails', 'menu'], get(tempMappingDetails, ['menu'])));

						// Handle Option Group
						const foundHashOptionGroups = foundHash.mappingDetails.optionGroups;
						const flattenFoundChildMenu = flattenOptionGroupChildMapping(foundHashOptionGroups);

						const updatePackagingSettings = (source, data) => Object
							.fromEntries(Object.entries(data).map(([key, value]) => [key, source[key]?.packaging
								? {...value, packaging: source[key].packaging}
								: value]),
							);

						if (posId == AVAILABLE_POS.ODOO.id) {
							tempMappingDetails.optionGroups = updatePackagingSettings(foundHash.mappingDetails.optionGroups, tempMappingDetails.optionGroups);
						}

						flattenFoundChildMenu.forEach(item => {
							for (let parentKey in tempMappingDetails.optionGroups) {
								// Handle copy child
								let childMenus = tempMappingDetails.optionGroups[parentKey].childMenus;
								if (childMenus.hasOwnProperty(item.childId)) {
									childMenus[item.childId] = item.childData;
								}
							}
						});

						// Handle complex rule
						if (asComplexRule.length) {
							const complexRuleValid = asComplexRule.every(checkGroup => {
								const foundGroup = find(menuData.optionGroups, {optionGroupId: checkGroup.optionGroupId});
								if (!foundGroup) return false;
								return isEqual(
									sortBy(foundGroup.menus, 'menuId'),
									sortBy(checkGroup.menus, 'menuId'),
								);
							});

							if (complexRuleValid) set(tempMappingDetails, ['complexMenu'], get(foundHash, ['mappingDetails', 'complexMenu']));
							else throw INVALID_FORMAT;
						}

						handlePopulateData({
							mappingDetails: tempMappingDetails,
						});
					} throw HASH_NOT_FOUND;
				} else {
					throw HASH_NOT_FOUND;
				}
			} else throw {};
		} catch (error) {
			if (error === INVALID_FORMAT) {
				hashProblematicAlert = locale.hashNotMatch;
			} else if (error === HASH_NOT_FOUND) {
				hashProblematicAlert = locale.hashNotFound;
			} else {
				handleErrorFetch(error);
			}
		} finally {
			setLoading(false);
			if (hashProblematicAlert) {
				form.setFields([
					{
						name: 'hash',
						errors: [hashProblematicAlert],
					},
				]);
			}
		}
	};

	return (
		<Modal
			title={locale.title}
			width={572}
			centered
			visible={visible}
			onOk={() => form.submit()}
			onCancel={close}
			okText={locale.okText}
			okButtonProps={{loading}}
			cancelButtonProps={{loading}}
			cancelText={locale.cancelText}
			onClose={close}
		>
			<Typography.Text type='secondary'>{locale.subtitle}</Typography.Text>
			<Form
				className='pt-4'
				onFinish={handleSearchHash}
				form={form}
				layout="vertical"
				requiredMark={false}
				onValuesChange={clearFieldErrors}
				name="selectMenuStructureForm">
				<Form.Item
					name="hash"
					label={locale.inputLabel}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input placeholder={locale.inputPlaceholder} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

MenuStructureModal.defaultProps = {
	visible: false,
	close: () => null,
	menuData: {},
	handlePopulateData: () => null,
};

MenuStructureModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	menuData: PropTypes.object,
	handlePopulateData: PropTypes.func,
};

export default MenuStructureModal;