import React from 'react';

import ReleaseSchedulerHeader from './ReleaseSchedulerHeader';
import ReleaseSchedulerBody from './ReleaseSchedulerBody';

const ReleaseScheduler = () => {
	return (
		<div className='bg-white h-full overflow-auto'>
			<ReleaseSchedulerHeader />
			<ReleaseSchedulerBody />
		</div>
	);
};

export default ReleaseScheduler;