import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Drawer, notification} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import FooterAction from './FooterAction';
import BodyAction from './BodyAction';
import localization from 'localization';
import {isEmpty} from 'lodash';

const OrderMenuDrawer = ({visible, menuData, menuItem, categoryData, onClose, onSave}) => {
	const locale = localization.MenuTemplate.MenuTemplateForm.OrderMenuDrawer;
	const isEdit = !isEmpty(menuItem);

	const [cartItemData, setCartItemData] = useState({});

	const handleSave = () => {
		const notificationLocale = isEdit ? locale.EditSuccess : locale.AddSuccess;
		notification.success({
			message: notificationLocale.message,
			description: notificationLocale.description,
		});
		onSave(cartItemData);
		onClose();
	};

	useEffect(() => {
		if (visible) {
			setCartItemData({
				mappingHash: menuData?.mappingHash,
				dataOrigin: menuData,
				menuItem: !isEmpty(menuItem)
					? menuItem
					: {
						menuId: menuData?.menuId,
						menuLabel: menuData?.menuLabel,
						menuPrice: menuData?.menuPrice || 0,
						quantity: 1,
						notes: null,
						optionGroups: menuData?.optionGroups?.map(option => {
							return {
								optionGroupId: JSON.stringify(option.optionGroupId),
								optionGroupLabel: option.optionGroupLabel,
								options: [],
							};
						}),
					},
			});
		}
	}, [visible]);

	return (
		<Drawer
			destroyOnClose
			width={514}
			title={(
				<div className='flex justify-between'>
					<div>{locale.title}</div>
					<CloseOutlined
						className='cursor-pointer'
						onClick={onClose}
					/>
				</div>
			)}
			open={visible}
			onClose={onClose}
			closable={false}
			headerStyle={{
				paddingInline: '24px',
				paddingBlock: '10px',
			}}
			bodyStyle={{
				padding: 0,
			}}
		>
			<div className='flex flex-col h-full select-none'>
				<BodyAction
					categoryData={categoryData}
					cartItemData={cartItemData}
					setCartItemData={setCartItemData}
				/>
				<FooterAction
					cartItemData={cartItemData}
					setCartItemData={setCartItemData}
					onSave={handleSave}
					isEdit={isEdit}
				/>
			</div>
		</Drawer>
	);
};

OrderMenuDrawer.defaultProps = {
	visible: false,
	menuData: {},
	categoryData: {},
	onClose: () => null,
	onSave: () => null,
	menuItem: {},
};

OrderMenuDrawer.propTypes = {
	visible: PropTypes.bool,
	menuData: PropTypes.object,
	categoryData: PropTypes.object,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	menuItem: PropTypes.menuItem,
};

export default OrderMenuDrawer;