import React, {useState, useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import {Button, Card, Form, Input, notification, Select} from 'antd';
const {Option} = Select;

import {createNewOutletItem, editOutletItem, getOutletData} from 'utils/request/outlet';
import {handleErrorFetch} from 'utils/utils';
import {OUTLET_TYPE} from 'utils/constants';

import localization from 'localization';
const locale = localization.Outlet.BranchForm.Form;

const OutletBranchFormBody = ({isEdit, itemRecord}) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const [locationLoading, setLocationLoading] = useState(false);

	const [locationList, setLocationList] = useState([]);

	const handleSubmit = async e => {
		try {
			setIsLoading(true);

			const payload = {
				locationId: e.locationId,
				posId: 1,
				details: {
					branchID: e.branchID,
				},
			};

			const fetchFn = isEdit ? editOutletItem : createNewOutletItem;
			if (isEdit) payload.id = itemRecord.id;
			const response = await fetchFn(payload, OUTLET_TYPE.BRANCH);
			if (response.success) {
				const notificationLocale = isEdit ? locale.EditSuccess : locale.CreateSuccess;
				navigate('/outlet/branch');
				notification.open({
					message: notificationLocale.title,
					description: notificationLocale.description,
					type: 'success',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}
	};

	const getLocation = async outletLabel => {
		try {
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 1000), []);

	useEffect(() => {
		if (!isEmpty (itemRecord)) {
			setLocationList([{...itemRecord?.Location}]);
			form.setFieldsValue({
				branchID: itemRecord?.details?.branchID,
				locationId: itemRecord?.locationId,
			});
		}
	}, []);

	return (
		<div className='px-6 pb-6'>
			<Card
				title={isEdit ? locale.editTitle : locale.defaultTitle}
			>
				<div>
					<Form
						form={form}
						layout="vertical"
						name="brandForm"
						scrollToFirstError
						onFinish={e => !isLoading && handleSubmit(e)}
						onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
						requiredMark={false}
						initialValues={{
							// isActive: 1,
							pos: 'ESB',
						}}
					>
						{/* POS */}
						<Form.Item
							label={locale.posLabel}
							name="pos"
						>
							<Input
								disabled
								className='w-96'
							/>
						</Form.Item>

						{/* Branch ID */}
						<Form.Item
							label={locale.branchIdLabel}
							name="branchID"
							rules={[{required: true, message: locale.branchIdRequired}]}
						>
							<Input
								className='w-96'
								placeholder={locale.branchIdPlaceholder}
							/>
						</Form.Item>

						{/* Location */}
						<Form.Item
							label={locale.locationLabel}
							name="locationId"
							rules={[{required: true, message: locale.locationRequired}]}
						>
							<Select
								showSearch
								loading={locationLoading}
								onSearch={debounceFn}
								className='w-96'
								placeholder={locale.locationPlaceholder}
								filterOption={((_, option) => option)}
							>
								{locationList.map(location => (
									<Option
										key={location.id}
										value={location.id}>{`${location.id} - ${location.label}`}</Option>
								))}
							</Select>

						</Form.Item>

						{/* Tags */}
						{/* <Form.Item
							label={locale.tagsLabel}
							name="tags">
							<Select
								placeholder={locale.tagsPlaceholder}
								className='w-96'
								open={false}
								mode='tags'
								tokenSeparators={[',']}
							/>
						</Form.Item> */}

						{/* Active */}
						{/* <Form.Item
							label={locale.statusLabel}
							name="isActive">
							<Radio.Group>
								<Radio
									key='active'
									value={1}>Yes</Radio>
								<Radio
									key='inactive'
									value={0}>No</Radio>
							</Radio.Group>
						</Form.Item> */}

						{/* Action */}
						<div className='flex gap-2 justify-end'>
							<Button
								onClick={() => navigate('/outlet/branch')}
								disabled={isLoading}>
								{locale.cancel}
							</Button>
							<Button
								type="primary"
								htmlType="submit"
								loading={isLoading}>
								{isEdit ? locale.save : locale.submit}
							</Button>
						</div>

					</Form>
				</div>
			</Card>
		</div>
	);
};

OutletBranchFormBody.defaultProps = {
	isEdit: false,
	itemRecord: {},
};

OutletBranchFormBody.propTypes = {
	isEdit: PropTypes.bool,
	itemRecord: PropTypes.object,
};

export default OutletBranchFormBody;